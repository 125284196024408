import React from 'react';
import isEmpty from 'lodash/isEmpty';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Box, InputLabel, Stack } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { IconButton, Tooltip, Typography } from '@mui/material';

interface ISectionProps {
  label: string;
  labelSx?: object;
  containerSx?: object;
  value: string;
  onChange?(newValue: string): void;
  message?: string;
  placeholder?: string;
  direction?: 'column' | 'row';
  allowCopy?: boolean;
  required?: boolean;
  disabled?: boolean;
}

const TextEditor: React.FC<ISectionProps> = ({
  label,
  containerSx,
  value,
  onChange,
  message = '',
  placeholder = '',
  direction = 'column',
  allowCopy = false,
  required = false,
  disabled = false,
}) => {
  const handleEditorChange = (
    content: any,
    _delta: any,
    _source: any,
    editor: any
  ) => {
    let resolveValue = content;
    if (
      editor
        .getText()
        .replace(/<(.|\n)*?>/g, '')
        .trim().length === 0
    ) {
      resolveValue = '';
    } else resolveValue = content;

    if (onChange && !disabled) onChange(resolveValue);
  };

  return (
    <Box
      className={!isEmpty(message) ? 'required' : ''}
      component="div"
      sx={{
        display: 'flex',
        flexDirection: direction,
        ...containerSx,
        '.quill': {
          minHeight: '300px',
          boxSizing: 'border-box',
          //resolve hover
          '&:hover .ql-toolbar.ql-snow, &:hover .ql-container.ql-snow': {
            borderColor: message ? '#d32f2f' : 'black',
          },
          //resolve focus
          '&:focus-within .ql-toolbar.ql-snow': {
            outline: `2px solid ${message ? '#d32f2f' : '#3498db'}  `, // primary.main
            outlineOffset: '-2px',
          },
          '&:focus-within .ql-container.ql-snow': {
            outline: 'none',
            borderWidth: '2px',
            borderColor: message ? '#d32f2f' : '#3498db',
          },
        },
        '.ql-toolbar.ql-snow': {
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          borderColor: message ? '#d32f2f' : '#ccc',
        },
        '.ql-picker': {
          color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'black',
        },
        '.ql-stroke': {
          stroke: disabled ? 'rgba(0, 0, 0, 0.38)' : 'black',
        },
        '.ql-container.ql-snow': {
          height: '300px',
          position: 'sticky',
          top: '0',
          width: '100%',
          wordBreak: 'break-word',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          borderColor: message ? '#d32f2f' : '#ccc',
        },
      }}
    >
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}{' '}
          {required ? (
            <Typography component="span" color="error">
              (*)
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      <Box
        component="div"
        sx={{
          pointerEvents: disabled ? 'none' : 'unset',
          color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'black',
          flex: 1,
        }}
      >
        <ReactQuill
          style={{ flex: 1 }}
          theme="snow"
          value={value}
          onChange={handleEditorChange}
          onKeyDown={(e: any) => disabled && e.preventDefault()}
          placeholder={placeholder}
        />
        {message && (
          <Typography ml={2} mt={0.5} sx={{ fontSize: '13px' }} color="error">
            {message}
          </Typography>
        )}
      </Box>

      {allowCopy && (
        <Stack direction="column">
          <Tooltip title="Click to copy">
            <IconButton>
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </Box>
  );
};

export default TextEditor;
