import React, { useEffect } from 'react';
// import _ from 'lodash';
import { Outlet, useLocation } from 'react-router';
import { Box } from '@mui/material';
import Utils from '@utils';
import UserAppbar from '../LayoutPart/UserAppbar';
import FooterHomePage from '../LayoutPart/FooterHomePage';
import { TransitionUserLayer } from '../Common';
// import AppbarMobile from '../LayoutPart/Appbar/AppbarMobile';
// import CONSTANTS from '@/Constants';
// import { CookieConsentDialog } from '@/Components/LayoutPart';
// import Utils from '@utils';
// const { ROUTERS } = CONSTANTS;
interface ISectionProps {
  content?: React.JSX.Element;
}

// Declare utils
// const { checkAppMode } = Utils;
// eslint-disable-next-line react/function-component-definition
const UserLayout: React.FC<ISectionProps> = () => {
  // Constructors
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // navigate(0);
  }, [pathname]);

  useEffect(() => {
    const token = Utils.getSavedToken();
    if (!token) Utils.clearAllSavedData();
  }, []);

  const renderLayout = () => (
    <TransitionUserLayer>
      <Outlet />
    </TransitionUserLayer>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        minHeight: '100vh',
        justifyContent: 'space-between',
        // width: '100vw',
      }}
    >
      <UserAppbar />
      <Box
        sx={{
          width: 1,
          mt: '64px',
        }}
      >
        {renderLayout()}
      </Box>
      <FooterHomePage />
    </Box>
  );
};
export default UserLayout;
