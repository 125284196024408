import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Container,
  Pagination as TablePagination,
  MenuItem,
  CircularProgress,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CountUp from 'react-countup';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCreative } from 'swiper/modules';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ProgressiveImage from 'react-progressive-graceful-image';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';
import { motion } from 'framer-motion';

import {
  mainWrapper,
  bannerWrapper,
  projectWrapper,
  // categoryCard,
} from './Portfolio.styles';
import { CommonColors } from '@/Themes';
import { RootState, useTypedDispatch } from '@/store';
import { PorfolioActions } from '@/Actions';
import CONSTANTS from '@/Constants';

const { ROUTERS, PORTFOLIO_CAT, TECH_LIST, ENUMS } = CONSTANTS;
const { fetchUserPorfolios } = PorfolioActions;

const Portfolio: React.FC = () => {
  const dispatch = useTypedDispatch();
  const searchParams = useLocation().search;
  const isLoading = useSelector((state: RootState) =>
    _.get(state.PORTFOLIO, 'requestIsLoading')
  );
  const payload =
    useSelector((state: RootState) => _.get(state.PORTFOLIO, 'payload')) || [];
  const meta =
    useSelector((state: RootState) => _.get(state.PORTFOLIO, 'meta')) || [];
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
    category: '',
    status: ENUMS.NewsStatus.PUBLISH,
  });

  useEffect(() => {
    const category = (searchParams.split('?category=')[1] || '').replaceAll(
      '%20',
      ' '
    );
    dispatch(fetchUserPorfolios({ ...paginate, category }));
    setPaginate({ ...paginate, category });
  }, []);

  const _renderBanner = () => (
    <Box sx={bannerWrapper}>
      <Container component="main" maxWidth="lg" sx={{ height: 300 }}>
        <Grid
          container
          sx={{ height: '100%' }}
          position="relative"
          justifyContent="space-between"
        >
          <Grid item xs={5} className="banner-content">
            <Fade right cascade>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '2rem', md: '4rem' },
                  color: CommonColors.burntSienna,
                }}
              >
                Portfolio
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: '0.55rem', md: '0.85rem' },
                  color: 'lightgray',
                  fontFamily: 'sans-serif',
                }}
              >
                FLOW-IDRA offers full-scale market research services
              </Typography>
            </Fade>
          </Grid>
          <Grid
            item
            xs={5}
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <Typography
              sx={{
                fontSize: 60,
                // color: '#ACE98A',
                fontWeight: 300,
                backgroundImage:
                  'linear-gradient(-225deg,#231557 0%, #44107a 29%,   #ff1361 67%, #fff800 100%)',
                backgroundSize: '200% auto',
                color: ' #fff',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                animation: 'textclip 2s linear infinite',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '@keyframes textclip': {
                  to: {
                    backgroundPosition: '200% center',
                  },
                },
              }}
              component="div"
            >
              <Box
                sx={{
                  border: '2px solid',
                  p: 2,
                  borderImageSlice: 1,
                  borderImageSource:
                    'linear-gradient(-225deg,#231557 0%, #44107a 29%,   #ff1361 67%, #fff800 100%)',
                  borderRadius: '50%',
                }}
              >
                <CountUp end={meta?.totalItems || 0} />+
              </Box>
              <Typography
                component="span"
                sx={{
                  fontSize: 30,
                  fontWeight: 500,
                  ml: 2,
                }}
              >
                Projects
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const _renderProjectImages = (item: any) => (
    <Grid item xs={12} md={6}>
      <Swiper
        grabCursor={true}
        effect="creative"
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }}
        modules={[EffectCreative]}
      >
        {_.map(item?.image || [], (img: any) => (
          <SwiperSlide key={img?.id}>
            <ProgressiveImage
              src={_.get(img, 'file.path')}
              placeholder={_.get(img, 'file.thumbnail')}
            >
              {(src: string, loading: boolean | undefined): any => (
                <img
                  className={`image${
                    loading ? ' loading-image' : ' loaded-image'
                  }`}
                  src={src}
                  alt="sea beach"
                  style={{ width: '100%', objectFit: 'contain' }}
                />
              )}
            </ProgressiveImage>
          </SwiperSlide>
        ))}
      </Swiper>
    </Grid>
  );

  const _renderProjectDetail = (item: any) => (
    <Grid item xs={12} md={6} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 700,
              mb: 2,
              color: CommonColors.shuttleGray,
              fontFamily: 'sans-serif',
            }}
          >
            Name
          </Typography>
          <Typography
            sx={{
              fontSize: '0.9rem',
              fontWeight: 400,
            }}
          >
            {item?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 700,
              mb: 2,
              color: CommonColors.shuttleGray,
              fontFamily: 'sans-serif',
            }}
          >
            URL
          </Typography>
          <Typography
            component="a"
            sx={{
              fontSize: '0.9rem',
              fontWeight: 400,
              color: CommonColors.scienceBlue,
              '&:hover': { textDecoration: 'underline' },
            }}
            href={item?.url}
            target="_blank"
          >
            {item?.url}
          </Typography>
        </Grid>
        {!_.isEmpty(item?.technology || []) && (
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 700,
                mb: 2,
                color: CommonColors.shuttleGray,
                fontFamily: 'sans-serif',
              }}
            >
              Technologies
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                mt: 'auto',
              }}
            >
              {_.map(item?.technology, (tech: string) => {
                const findTech = _.find(TECH_LIST, ['label', tech]);
                return (
                  <LazyLoadImage
                    key={`${item?.id}${tech}`}
                    src={findTech?.icon}
                    alt="appIcon"
                    loading="lazy"
                    style={{ width: 30, height: 30 }}
                  />
                );
              })}
            </Box>
          </Grid>
        )}

        {item?.content?.general && (
          <Grid item xs={12} md={8}>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 700,
                mb: 2,
                color: CommonColors.shuttleGray,
                fontFamily: 'sans-serif',
              }}
            >
              About
            </Typography>
            <Typography
              sx={{
                fontSize: '0.9rem',
                fontWeight: 400,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '-webkit-line-clamp': '3',
                '-webkit-box-orient': 'vertical',
                display: '-webkit-box',
              }}
            >
              {item?.content?.general.replace(/<[^>]*>/g, '')}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={6}
          sm={4}
          ml="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <motion.div
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
            style={{ width: '100%' }}
          >
            <Button
              variant="contained"
              fullWidth
              endIcon={<OpenInNewIcon />}
              sx={{ fontWeight: 700 }}
              onClick={() =>
                window.open(`${ROUTERS.PORTFOLIO}/${item?.id}`, '_blank')
              }
            >
              More Detail
            </Button>
          </motion.div>
        </Grid>
      </Grid>
    </Grid>
  );

  const _renderProjects = () => (
    <Container component="main">
      <Grid container rowGap={2}>
        {_.map(payload, (item: any) => (
          <Grid item xs={12} key={item?.id}>
            <Fade bottom>
              <Paper elevation={0} sx={projectWrapper}>
                <Grid container justifyContent="center" alignItems="center">
                  {_renderProjectImages(item)}
                  {_renderProjectDetail(item)}
                </Grid>
              </Paper>
            </Fade>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ my: 2 }}>
        <TablePagination
          sx={{ '& .MuiPagination-ul': { justifyContent: 'end' } }}
          count={meta?.totalPages || 1}
          shape="rounded"
          color="primary"
          page={paginate.page}
          onChange={(_e, page) => {
            setPaginate({ ...paginate, page });
            dispatch(fetchUserPorfolios({ ...paginate, page }));
          }}
        />
      </Box>
    </Container>
  );

  const renderMain = () => {
    return (
      <Box sx={mainWrapper}>
        <Helmet>
          <title>Portfolio</title>
        </Helmet>
        {_renderBanner()}
        <Container component="main" sx={{ p: 3 }}>
          <TextField
            select
            label={paginate.category || 'Category'}
            value={paginate.category}
            onChange={(event) => {
              setPaginate({
                ...paginate,
                category: event.target.value,
                page: 1,
              });
              dispatch(
                fetchUserPorfolios({
                  ...paginate,
                  category: event.target.value,
                  page: 1,
                })
              );
            }}
            disabled={isLoading}
            fullWidth
          >
            <MenuItem value="">All</MenuItem>
            {_.map(PORTFOLIO_CAT, (cat: string) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </TextField>
        </Container>

        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            _renderProjects()
          )}
        </motion.div>
      </Box>
    );
  };
  return renderMain();
};
export default Portfolio;
