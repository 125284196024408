import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import dayjs from 'dayjs';
import { useTranslation } from 'react-multi-lang';

import Utils from '@/Utils';
import Constants from '@/Constants';

import { ChipStatus, StickyHeadTable } from '@/Components/Common';
import { IProjectResponseStructure } from '@/Interfaces/Project.interface';
import { IDailyReport } from '@/Interfaces/DailyReport.interface';

import { Tooltip, IconButton } from '@mui/material';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

interface ISectionProps {
  payload: IProjectResponseStructure[];
  onRowAction(Project: any, action: 'report'): void;
  isLoading?: boolean;
}

interface IColumnStructure {
  id: 'no' | 'name' | 'start_date' | 'status' | 'action';
  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'name', label: 'projectName', minWidth: 100 },
  { id: 'start_date', label: 'startDate', minWidth: 80, align: 'center' },
  { id: 'status', label: 'status', minWidth: 50, align: 'center' },
  { id: 'action', label: 'action', minWidth: 50, align: 'center' },
];

const createDataTable = (
  no: string,
  name: string,
  start_date: string,
  status: JSX.Element,
  action: JSX.Element
): any => {
  return {
    no,
    name,
    start_date,
    status,
    action,
  };
};

const { MODULE_API } = Constants;

const ProjectListDataTable: React.FC<ISectionProps> = ({
  payload = [],
  onRowAction,
  isLoading = false,
}) => {
  const isAcceptSendReport: boolean = Utils.isValidPermission(
    MODULE_API.DAILY_REPORT.CREATE
  );
  const t = useTranslation();
  const [rows, setRows] = useState<any[]>([]);

  React.useEffect(() => {
    const resolveRows: any = [];
    if (!isEmpty(payload)) {
      forEach(payload, (item: IProjectResponseStructure, index: number) => {
        const { name, status, startDate, dailyReport } = item;

        const startTime = dayjs(startDate);
        const resolveRowIndex = index + 1;

        const currentDate = dayjs().format('YYYY-MM-DD');
        const isReported = dailyReport.find(
          (report: IDailyReport) =>
            dayjs(report.date).format('YYYY-MM-DD') === currentDate
        );

        resolveRows.push(
          createDataTable(
            `${resolveRowIndex}`,
            name,
            startTime.isValid()
              ? startTime.format('DD-MM-YYYY')
              : t('table.noInformation'),
            <ChipStatus label={status} />,
            <Tooltip
              title={
                isAcceptSendReport
                  ? t('tooltip.submitReport')
                  : t('tooltip.notAuthorize')
              }
            >
              <span>
                <IconButton
                  onClick={() =>
                    isAcceptSendReport && onRowAction(item, 'report')
                  }
                  disabled={!isAcceptSendReport}
                >
                  {isReported ? (
                    <EventAvailableIcon fontSize="small" color="success" />
                  ) : (
                    <EventNoteOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable columns={COLUMNS} rows={rows} isLoading={isLoading} />
  );
};

export default ProjectListDataTable;
