import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const initialMeta = {
  currentPage: 1,
  itemCount: 1,
  itemsPerPage: 25,
  totalItems: 1,
  totalPages: 1,
};

const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  payload: [],
  details: {},
  meta: initialMeta,
};

const ConfigSystemReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.CLEAR_CONFIG_SYSTEM:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        payload: [],
        meta: initialMeta,
        details: {},
      };
    case ACTION_TYPES.SET_META_CONFIG_SYSTEM:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.CONFIG_SYSTEM_IS_REQUEST:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.FETCH_CONFIG_SYSTEMS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: true,
        requestHasError: false,
        payload,
      };
    case ACTION_TYPES.FETCH_CONFIG_SYSTEMS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: false,
        requestHasError: true,
        payload: [],
      };

    case ACTION_TYPES.GET_CONFIG_SYSTEM_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: true,
        requestHasError: false,
        details: payload,
      };
    case ACTION_TYPES.GET_CONFIG_SYSTEM_BY_ID_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: false,
        requestHasError: true,
        details: {},
      };

    case ACTION_TYPES.CREATE_CONFIG_SYSTEM_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: true,
        requestHasError: false,
      };
    case ACTION_TYPES.CREATE_CONFIG_SYSTEM_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: false,
        requestHasError: true,
      };

    case ACTION_TYPES.UPDATE_CONFIG_SYSTEM_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: true,
        requestHasError: false,
      };
    case ACTION_TYPES.UPDATE_CONFIG_SYSTEM_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: false,
        requestHasError: true,
      };

    case ACTION_TYPES.UPDATE_DOCUMENTATION_CONFIG_SYSTEM_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: true,
        requestHasError: false,
      };
    case ACTION_TYPES.UPDATE_DOCUMENTATION_CONFIG_SYSTEM_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: false,
        requestHasError: true,
      };

    case ACTION_TYPES.DELETE_CONFIG_SYSTEM_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: true,
        requestHasError: false,
      };
    case ACTION_TYPES.DELETE_CONFIG_SYSTEM_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: false,
        requestHasError: true,
      };

    default:
      return state;
  }
};

export default ConfigSystemReducer;
