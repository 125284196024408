import { SxProps, Theme } from '@mui/system';
import { CommonColors } from '@/Themes';

export const mainWrapper: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};
export const bannerWrapper: SxProps<Theme> = {
  background:
    'linear-gradient(to bottom, rgba(22, 28, 36, 0.8), rgba(22, 28, 36, 0.8)),url(https://zone-ui.vercel.app/assets/images/home/advertisement.jpg)',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  '& .banner-content': {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: 2,
  },
};

export const projectWrapper: SxProps<Theme> = {
  boxShadow: '-24px 24px 72px -8px rgba(145, 158, 171, 0.24)',
  width: 1,
  '& .swiper': {
    width: 350,
    margin: '1em auto',
  },
  '& .swiper-slide': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // borderRadius: 3,
    img: {
      display: 'block',
      width: 1,
      objectFit: 'contain',
    },
  },
};

export const projectSliderWrapper: SxProps<Theme> = {
  '& .swiper': {
    width: 1,
    height: 1,
  },
  '& .swiper-slide': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    img: {
      display: 'block',
      width: 1,
      height: 1,
      objectFit: 'cover',
    },
  },
};

export const projectLabel: SxProps<Theme> = {
  fontSize: '1rem',
  fontWeight: 700,
  mb: 2,
  color: CommonColors.shuttleGray,
  fontFamily: 'sans-serif',
};

export const categoryCard: SxProps<Theme> = {
  width: 1,
  textAlign: 'center',
  transition: '.4s transform linear, .2s box-shadow linear',
  ':hover': {
    boxShadow: '0px 8px 14px -4px rgba(0,0,0,0.2)',
    transform: 'translate(0px, -3px)',
    background: 'white',
    color: 'rgb(250, 84, 28)',
  },
  background: 'white',
  p: 1,
  cursor: 'pointer',
  height: '100%',
  border: '1px solid lightgray',
  borderRadius: 2,
  '& .pr-title': {
    fontWeight: 600,
    fontFamily: 'sans-serif',
  },
  '&.active': {
    background: 'white',
    color: 'rgb(250, 84, 28)',
  },
};
