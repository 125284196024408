import CONSTANTS from '@/Constants';
import { AuthContainer } from '@containers';
import { AuthenticationLayout } from '@/Components/DefaultLayout';
import PreLoading from './PreLoading';

const { ROUTERS } = CONSTANTS;

const AuthRouter = {
  element: (
    <PreLoading>
      <AuthenticationLayout />
    </PreLoading>
  ),
  children: [
    {
      path: ROUTERS.AUTH,
      element: <AuthContainer.Auth />,
    },
    {
      path: ROUTERS.CHANGE_PASSWORD,
      element: <AuthContainer.ChangePassword />,
    },
  ],
};

export default AuthRouter;
