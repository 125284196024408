import * as React from 'react';
import Box from '@mui/material/Box';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface ISectionProps {
  progress: number;
}

const CircularPercent: React.FC<ISectionProps> = ({ progress }) => {
  return (
    <Box
      component="div"
      sx={{
        width: '60px',
        height: '60px',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <CircularProgressbar
        value={progress}
        text={`${progress}%`}
        styles={buildStyles({
          textColor: '#274C77',
          pathColor: '#274C77',
        })}
      />
    </Box>
  );
};

export default CircularPercent;
