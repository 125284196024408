import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@utils';
import { toast } from 'react-toastify';
import { IDocumentation } from '../Interfaces/Documentation.interface';

const { ACTION_TYPES, ROUTERS } = CONSTANTS;

// SINGLE_ACTIONS
const resetWikiStatus = () => {
  return {
    type: ACTION_TYPES.RESET_WIKI_STATUS,
  };
};

const setDefaultReducerWiki = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_REDUCER_WIKI,
  };
};

const wikiIsLoading = () => {
  return {
    type: ACTION_TYPES.WIKI_IS_LOADING,
  };
};

// ASYNC_ACTIONS
const fetchWikiSuccess = (payload: IDocumentation[]) => {
  return {
    type: ACTION_TYPES.FETCH_WIKI_SUCCESS,
    payload,
  };
};

const fetchWikiFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_WIKI_FAILURE,
  };
};

const fetchWiki = (filterParams?: any) => {
  return async (dispatch: any) => {
    dispatch(wikiIsLoading());
    await API.fetchWiki(filterParams)
      .then(async (response: any) => {
        const results = await Utils.resolveResponse(response, true);
        if (!results) await dispatch(fetchWikiFailure());
        else {
          const resolveResult: IDocumentation[] = results as IDocumentation[];
          await dispatch(fetchWikiSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(fetchWikiFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createWikiSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_WIKI_SUCCESS,
  };
};

const createWikiFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_WIKI_FAILURE,
  };
};

const createWiki = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(wikiIsLoading());
    await API.createWiki(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createWikiFailure());
        } else {
          await dispatch(createWikiSuccess());
          toast.success(message);
          Utils.redirect(ROUTERS.WIKI);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createWikiFailure());
      });
  };
};

const updateWikiSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_WIKI_SUCCESS,
  };
};

const updateWikiFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_WIKI_FAILURE,
  };
};

const updateWiki = (payload: any, id: string) => {
  return async (dispatch: any) => {
    dispatch(wikiIsLoading());
    await API.updateWiki(id, payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateWikiFailure());
        } else {
          await dispatch(updateWikiSuccess());
          toast.success(message);
          Utils.redirect(ROUTERS.WIKI);
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateWikiFailure());
      });
  };
};

const getWikiByIdSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_WIKI_BY_ID_SUCCESS,
    payload,
  };
};

const getWikiByIdFailure = () => {
  return {
    type: ACTION_TYPES.GET_WIKI_BY_ID_FAILURE,
  };
};

const getWikiById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(wikiIsLoading());
    await API.getWikiById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getWikiByIdFailure());
        else {
          const resolveResult: any = result as any;
          dispatch(getWikiByIdSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(getWikiByIdFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const updateWikiAttachmentSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_ATTACHMENT_WIKI_SUCCESS,
    payload,
  };
};

const updateWikiAttachmentFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_ATTACHMENT_WIKI_FAILURE,
  };
};

const updateWikiAttachment = (id: string, payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(wikiIsLoading());
    await API.updateWikiAttachment(id, payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(updateWikiAttachmentFailure());
        else {
          const resolveResult: any = result as any;
          dispatch(updateWikiAttachmentSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(updateWikiAttachmentFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const deleteWikiSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_WIKI_SUCCESS,
  };
};

const deleteWikiFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_WIKI_FAILURE,
  };
};

const deleteWiki = (id: string) => {
  return async (dispatch: any) => {
    dispatch(wikiIsLoading());
    await API.deleteWiki(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteWikiFailure());
          toast.error(message);
        } else {
          dispatch(deleteWikiSuccess());
          dispatch(fetchWiki());
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(deleteWikiFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  resetWikiStatus,
  setDefaultReducerWiki,
  fetchWiki,
  createWiki,
  updateWiki,
  getWikiById,
  deleteWiki,
  updateWikiAttachment,
};
