export default {
  AUTH: {
    LOGIN: 'auth/login',
    CHANGE_PASSWORD: 'auth/changePassword',
    FORGOT_PASSWORD: 'auth/forgot-password',
    RESET_PASSWORD: 'auth/reset-password',
    ENTER_PASSWORD: 'auth/enter-password',
  },
  ROLES: {
    BASIC: 'roles',
    PERMISSIONS: 'roles/permissions',
    REMOVE_USER: 'roles/remove-user',
    OPTIONS: 'roles/options',
  },
  USER: {
    BASIC: 'users',
    WORK_HISTORY: 'users/work-history',
    PROJECTS: 'users/projects',
    DOCUMENTS: 'users/documents',
    RESET_PASSWORD: 'users/reset-password',
    CHANGE_PASSWORD: 'users/change-password',
    UPDATE_SIGNATURE: 'users/signature',
    EXPORT: 'users/export',
    ACTIVATE: 'users/activate',
    DEACTIVATE: 'users/deactivate',
    VIEW_SALARY: 'users/view-salary',
    UPDATE_AVATAR: 'users/avatar',
    UPDATE_PROFILE: 'users/profile',
    UPDATE_AVATAR_PROFILE: 'users/profile/avatar',
    UNASSIGNED_ROLE: 'users/unassigned-role',
    ASSIGN_ROLE: 'users/assign-role',
  },
  NOTIFICATION: {
    BASIC: 'notifications',
  },
  PROJECT: {
    BASIC: 'projects',
    NOT_CONFIG: 'projects/not-config',
    DOCUMENTATION: 'projects/documentation',
  },
  DAILY_REPORT: {
    BASIC: 'daily-report',
    PROJECT_REPORT: 'daily-report/projects',
    BY_PROJECT_ID: 'daily-report/project',
    BY_DATE_AND_PROJECT: 'daily-report/details',
  },
  FOLDERS: {
    BASIC: 'folders',
    ROOT: 'folders/root',
    CHILD: 'folders/child',
  },
  MAIL: {
    BASIC: 'mails',
    REPLY: 'mails/reply',
  },
  LOGS: {
    BASIC: 'logs',
  },
  PERFORMANCE_REVIEW: {
    BASIC: 'performance-review',
    EXPORT: 'performance-review/export',
    COMMENT: 'performance-review/comments',
    FINAL_DECISION: 'performance-review/final-decision',
    RESULT: 'performance-review/result',
  },
  MEDIA: {
    BASIC: 'files',
    DOWNLOAD: 'files/download',
  },
  LEAVE_APPLICATION: {
    BASIC: 'leave-application',
    FORWARD: 'leave-application/forward',
  },
  USER_DOCUMENT: {
    BASIC: 'user-documents',
  },
  USER_PROJECT: {
    BASIC: 'user-projects',
  },
  WORK_HISTORY: {
    BASIC: 'work-history',
  },
  WIKI: {
    BASIC: 'wiki',
    UPDATE_ATTACHMENT: 'wiki/attachments',
  },
  EVENT: {
    BASIC: 'events',
    NEXT_EVENTS: 'events/next',
  },
  ATTENDANCE: {
    BASIC: 'attendances',
    EXPORT: 'attendances/export',
  },
  PROJECT_MEMBER: {
    BASIC: 'project-members',
    UNASSIGNED_MEMBERS: 'project-members/unassigned',
  },
  ANNOUNCEMENT: {
    BASIC: 'announcements',
    FOR_USERS: 'announcements/forUser',
    UPDATE_TYPE: 'announcements/updateType',
    ALL_FOR_USERS: 'announcements/allForUser',
    READ_ANNOUNCEMENT: 'announcements/read',
  },
  TAGS: {
    BASIC: 'tags',
  },
  CRAWLING_SYSTEM: {
    BASIC: 'crawl-project',
    GET_BY_ID: 'crawl-project/get-by-id',
    ACTIVATE: 'crawl-project/activate',
    DEACTIVATE: 'crawl-project/deactivate',
    CAPTURE_IMAGE: 'crawl-project/capture-project',
  },
  FEEDBACK: {
    BASIC: 'feedbacks',
    GET_ALL_FOR_USER: 'feedbacks/forUser',
    THREAD: '/feedbacks/thread',
    ASSIGN_HANDLER: '/feedbacks/assign',
    UPDATE_FEEDBACK_STATUS: '/feedbacks/status',
    GET_FOR_DASHBOARD: '/feedbacks/dashboard',
  },
  FEEDBACK_GUEST: {
    BASIC: 'feedback-guest',
  },
  CONFIG_SYSTEM: {
    BASIC: 'config-systems',
    DOCUMENTATION: 'config-systems/documentation',
  },
  FILE_TEMPLATE: {
    BASIC: 'file-templates',
  },
  WORK_PROGRESS: {
    BASIC: 'work-progress',
    UPDATE_WORK_PROGRESS_STATUS: '/work-progress/update-status',
    STEP: 'work-progress/step-progress',
  },
  TOOL: {
    BASIC: 'tools',
  },
  NEWS: {
    BASIC: 'news',
    GET_BY_ID: 'news/get-by-id',
    ACTIVATE: 'news/activate',
    DEACTIVATE: 'news/deactivate',
    PUBLISH: 'news/publish',
  },
  DAILY_REPORT_TASK: {
    BASIC: 'daily-task',
    ME: 'daily-task/me',
  },
  PORTFOLIO: {
    BASIC: 'portfolio',
    PUBLISH: 'portfolio/publish',
    ACTIVATE: '/portfolio/activate',
    DEACTIVATE: '/portfolio/deactivate',
    DASHBOARD: '/portfolio/get-dashboard',
  },
};
