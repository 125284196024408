import {
  Checkbox,
  FormHelperText,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { map } from 'lodash';
import React from 'react';

interface IOption {
  label: string | JSX.Element;
  value: string;
}

interface IProps {
  label?: string;
  required?: boolean;
  onChange?(newChecked: string[]): void;
  selected: string[];
  message?: string;
  options: IOption[];
  sx?: object;
  helperText?: string;
  disabledFields?: string[];
}

const CheckboxGroup: React.FC<IProps> = ({
  label = '',
  required = false,
  onChange,
  selected = [],
  message = '',
  options = [],
  sx = {},
  helperText = '',
  disabledFields = [],
}) => {
  const onCheck = (value: string, event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const isExist = selected.includes(value);
    let resolveSelected = [...selected, value];
    if (isExist)
      resolveSelected = selected.filter((item: string) => item !== value);
    if (onChange) onChange(resolveSelected);
  };

  const _renderCheckboxes = React.useMemo(
    () =>
      map(options, (option: IOption) => {
        const isDisabled = disabledFields.includes(option.value);
        return (
          <Stack
            key={`menu-${option.value}`}
            onClick={(event: React.MouseEvent<HTMLDivElement>) =>
              !isDisabled && onCheck(option.value, event)
            }
            direction="row"
            sx={{
              alignItems: 'center',
              userSelect: 'none',
              ':hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Checkbox
              disabled={isDisabled}
              checked={selected.includes(option.value)}
              size="small"
            />
            {option.label}
          </Stack>
        );
      }),
    [selected]
  );

  return (
    <Stack direction="column" sx={{ flex: 1, ...sx }}>
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}{' '}
          {required ? (
            <Typography component="span" color="error">
              (*)
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      {helperText ? (
        <Typography sx={{ fontSize: '14px' }}>*{helperText}</Typography>
      ) : null}
      <Stack direction="column">{_renderCheckboxes}</Stack>
      {message ? (
        <FormHelperText error={Boolean(message)}>{message}</FormHelperText>
      ) : null}
    </Stack>
  );
};

export default CheckboxGroup;
