import { Skeleton, Stack } from '@mui/material';
import { RoundedContainer } from '../Containers';

const AnnouncementDetailsSkeleton = () => {
  const _renderRowSkeleton = () => (
    <Stack direction="row" mt={1}>
      <Stack flex={1}>
        <Skeleton width={120} height={30} />
        <Skeleton height={45} />
      </Stack>
    </Stack>
  );

  return (
    <Stack direction="column">
      <Skeleton width={200} height={40} />
      <RoundedContainer mt={3}>
        {_renderRowSkeleton()}

        <Stack direction="row" mt={1}>
          <Stack flex={1}>
            <Skeleton width={120} height={30} />
            <Skeleton variant="rectangular" height={150} />
          </Stack>
        </Stack>

        <Stack direction="column" mt={1}>
          <Skeleton width={120} height={30} />
          <Stack direction="row">
            <Skeleton
              variant="rectangular"
              height={300}
              sx={{ flex: 1, mr: 0.5 }}
            />
            <Skeleton variant="rectangular" height={300} sx={{ flex: 1 }} />
          </Stack>
        </Stack>
      </RoundedContainer>
    </Stack>
  );
};

export default AnnouncementDetailsSkeleton;
