import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
import {
  ICreateWorkProgress,
  IWorkProgressFilter,
  ICreateStepWorkProgress,
  IUpdateStepWorkProgress,
  IUpdateWorkProgress,
} from '@/Interfaces/WorkProgress.interface';

const { WORK_PROGRESS } = CONSTANTS.API;

export const fetchWorkProgress = (payload: IWorkProgressFilter) => {
  return sendRequest(WORK_PROGRESS.BASIC, 'GET', payload);
};

export const createWorkProgress = (payload: ICreateWorkProgress) => {
  return sendRequest(WORK_PROGRESS.BASIC, 'POST', payload);
};

export const getWorkProgressById = (id: string) => {
  return sendRequest(`${WORK_PROGRESS.BASIC}/${id}`, 'GET');
};

export const updateWorkProgress = (
  payload: IUpdateWorkProgress,
  id: string
) => {
  return sendRequest(`${WORK_PROGRESS.BASIC}/${id}`, 'PUT', payload);
};

export const updateStatusWorkProgress = (payload: string, id: string) => {
  return sendRequest(
    `${WORK_PROGRESS.UPDATE_WORK_PROGRESS_STATUS}/${id}`,
    'PUT',
    payload
  );
};

export const deleteWorkprogress = (id: string) => {
  return sendRequest(`${WORK_PROGRESS.BASIC}/${id}`, 'DEL');
};

export const createStepWorkProgress = (payload: ICreateStepWorkProgress) => {
  return sendRequest(WORK_PROGRESS.STEP, 'POST', payload);
};

export const updateStepWorkProgress = (
  payload: IUpdateStepWorkProgress,
  id: string
) => {
  return sendRequest(`${WORK_PROGRESS.STEP}/${id}`, 'PUT', payload);
};

export const getStepWorkProgressById = (id: string) => {
  return sendRequest(`${WORK_PROGRESS.STEP}/${id}`, 'GET');
};

export const deleteStepWorkProgress = (id: string) => {
  return sendRequest(`${WORK_PROGRESS.STEP}/${id}`, 'DEL');
};
