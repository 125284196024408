import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { Box, Stack, Button } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import CONSTANTS from '@/Constants';
import { ProjectActions } from '@/Actions';
import { Alert } from '@/Widgets';

import { RoundedContainer, MarkdownEditor } from '@/Components/Common';

import { IProjectResponseStructure } from '@/Interfaces/Project.interface';

const { ROUTERS, MODULE_API } = CONSTANTS;

const { updateProjectDocumentation, setDefaultProjectStatus } = ProjectActions;

const ProjectDocumentation: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const details: IProjectResponseStructure = useSelector((state: RootState) =>
    get(state.PROJECT, 'details')
  );
  const updateDocumentationSuccess: boolean = useSelector((state: RootState) =>
    get(state.PROJECT, 'updateDocumentationSuccess')
  );

  const schema = yup
    .object()
    .shape({
      note: yup.string().trim(),
    })
    .required();

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      status: '',
      name: '',
      description: '',
      startDate: '',
      endDate: '',
      isWorkingCurrently: false,
      documentation: '',
    },
  });

  const [isEditable, setIsEditable] = React.useState<boolean>(false);

  React.useEffect(() => {
    const isAcceptUpdate = Utils.isValidPermission(
      MODULE_API.PROJECT.UPDATE_DOCUMENTATION
    );
    if (!isAcceptUpdate) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForUpdateProjectDocumentation'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    }
  }, []);

  React.useEffect(() => {
    if (details?.id) setValue('documentation', details.documentation);
  }, [details]);

  React.useEffect(() => {
    if (updateDocumentationSuccess) {
      setIsEditable(false);
      dispatch(setDefaultProjectStatus());
    }
  }, [updateDocumentationSuccess]);

  const onSubmit = (data: {
    status: string;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    isWorkingCurrently: boolean;
    documentation: string;
  }) => {
    const resolvePayload = {
      ...details,
      documentation: data.documentation,
    };
    dispatch(updateProjectDocumentation(resolvePayload));
  };

  const onDiscard = async () => {
    const isAgree = await Alert({
      type: 'WARNING',
      message: t('popup.warningDiscardProjectDocumentation'),
    });
    if (isAgree === 'ok') setValue('documentation', details.documentation);
  };

  const onCancel = () => {
    setValue('documentation', details.documentation);
    setIsEditable(false);
  };

  const _renderForm = () => (
    <Controller
      name="documentation"
      control={control}
      render={({ field }) => (
        <MarkdownEditor
          value={field.value}
          onChange={(e: any) => field.onChange(e)}
          onlyMarkdown={!isEditable}
          emptyPlaceholder={t('tooltip.noDocumentationForThisProject')}
        />
      )}
    />
  );

  const _renderButton = () => {
    const isAcceptUpdateDocumentation = Utils.isValidPermission(
      MODULE_API.PROJECT.UPDATE_DOCUMENTATION
    );

    if (!isAcceptUpdateDocumentation) return null;

    return (
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: '50px' }}>
        {isEditable ? (
          <>
            <Button
              disabled={false}
              sx={{ mr: 1 }}
              onClick={onCancel}
              variant="outlined"
            >
              {t('button.cancel')}
            </Button>
            <Button
              disabled={false}
              sx={{ mr: 1 }}
              onClick={onDiscard}
              variant="outlined"
            >
              {t('button.discard')}
            </Button>
            <Button type="submit" variant="contained">
              {t('button.save')}
            </Button>
          </>
        ) : null}
        {!isEditable ? (
          <Button variant="contained" onClick={() => setIsEditable(true)}>
            {t('button.edit')}
          </Button>
        ) : null}
      </Stack>
    );
  };

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          {_renderForm()}
          {_renderButton()}
        </Box>
      </RoundedContainer>
    );
  };

  return _renderBottomSection();
};

export default ProjectDocumentation;
