import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  submitFormIsSuccess: false,
  payload: [],
  nextEvents: [],
};

const EventReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.CLEAR_EVENT_STATUS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.SET_DEFAULT_EVENT_REDUCERS:
      return {
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        submitFormIsSuccess: false,
        payload: [],
        nextEvents: [],
      };
    case ACTION_TYPES.FOLDERS_IS_REQUEST:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
        submitFormIsSuccess: false,
      };

    case ACTION_TYPES.FETCH_EVENTS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };
    case ACTION_TYPES.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload,
      };

    case ACTION_TYPES.FETCH_NEXT_EVENTS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        nextEvents: [],
      };
    case ACTION_TYPES.FETCH_NEXT_EVENTS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        nextEvents: payload,
      };

    case ACTION_TYPES.CREATE_EVENT_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        submitFormIsSuccess: false,
      };
    case ACTION_TYPES.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        submitFormIsSuccess: true,
      };
    case ACTION_TYPES.UPDATE_EVENT_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        submitFormIsSuccess: false,
      };
    case ACTION_TYPES.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        submitFormIsSuccess: true,
      };
    case ACTION_TYPES.DELETE_EVENT_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    default:
      return state;
  }
};

export default EventReducer;
