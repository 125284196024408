import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';
import { toast } from 'react-toastify';
import { IEvent } from '../Interfaces/Event.interface';

const { ACTION_TYPES } = CONSTANTS;

// SINGLE ACTIONS
const clearEventStatus = () => {
  return {
    type: ACTION_TYPES.CLEAR_EVENT_STATUS,
  };
};

const eventIsRequest = () => {
  return {
    type: ACTION_TYPES.FOLDERS_IS_REQUEST,
  };
};

const setDefaultEventReducer = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_EVENT_REDUCERS,
  };
};

// ASYNC ACTIONS
const fetchEventsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_EVENTS_FAILURE,
  };
};

const fetchEventsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_EVENTS_SUCCESS,
    payload,
  };
};

const fetchEvents = (payload?: { year: number }) => {
  return async (dispatch: any) => {
    dispatch(eventIsRequest());
    await API.fetchEvents(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchEventsFailure());
        else {
          const resolveResult: any[] = result as any[];
          dispatch(fetchEventsSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
      });
  };
};

const fetchNextEventsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_NEXT_EVENTS_FAILURE,
  };
};

const fetchNextEventsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_NEXT_EVENTS_SUCCESS,
    payload,
  };
};

const fetchNextEvents = (payload: { day: number }) => {
  return async (dispatch: any) => {
    dispatch(eventIsRequest());
    await API.fetchNextEvents(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchNextEventsFailure());
        else {
          const resolveResult: any[] = result as any[];
          dispatch(fetchNextEventsSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createEventFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_EVENT_FAILURE,
  };
};

const createEventSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_EVENT_SUCCESS,
  };
};

const createEvent = (payload: Omit<IEvent, 'id'>) => {
  return async (dispatch: any) => {
    dispatch(eventIsRequest());
    await API.createEvent(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response.data.message;
        if (!result) {
          toast.error(message);
          await dispatch(createEventFailure());
        } else {
          toast.success(message);
          // dispatch(fetchEvents());
          dispatch(createEventSuccess());
        }

        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        dispatch(createEventFailure());
      });
  };
};

const updateEventFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_EVENT_FAILURE,
  };
};

const updateEventSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_EVENT_SUCCESS,
  };
};

const updateEvent = (payload: IEvent) => {
  return async (dispatch: any) => {
    dispatch(eventIsRequest());
    await API.updateEvent(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response.data.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateEventFailure());
        } else {
          toast.success(message);
          // dispatch(fetchEvents());
          dispatch(updateEventSuccess());
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        dispatch(updateEventFailure());
      });
  };
};

const deleteEventFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_EVENT_FAILURE,
  };
};

const deleteEventSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_EVENT_SUCCESS,
  };
};

const deleteEvent = (id: string) => {
  return async (dispatch: any) => {
    dispatch(eventIsRequest());
    await API.deleteEvent(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response.data.message;
        if (!result) {
          toast.error(message);
          await dispatch(deleteEventFailure());
        } else {
          dispatch(fetchEvents());
          toast.success(message);
          dispatch(deleteEventSuccess());
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        dispatch(deleteEventFailure());
      });
  };
};

export default {
  clearEventStatus,
  setDefaultEventReducer,
  fetchEvents,
  fetchNextEvents,
  createEvent,
  updateEvent,
  deleteEvent,
};
