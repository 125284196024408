import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-multi-lang';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { RoundedContainer } from '@/Components/Common';

import { RootState, useTypedDispatch } from '@/store';
import { FeedbackGuestsActions } from '@/Actions';
import Utils from '@/Utils';
import CONSTANTS from '@/Constants';
import { EventSkeleton } from '@/Components/Common/Skeleton';
import { CommonColors } from '@/Themes';

import {
  IFeedbackGuestStructure,
  IFeedbackGuestFilter,
} from '@/Interfaces/FeedbackGuests.interface';

const { ENUMS, MODULE_API } = CONSTANTS;
const { fetchFeedbackGuests } = FeedbackGuestsActions;

const initialFilter: IFeedbackGuestFilter = {
  status: ENUMS.Type.ALL_TYPE,
  keyword: '',
  page: 1,
  limit: 10,
};

const FeedbackGuest: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const isAcceptFetch = Utils.isValidPermission(
    MODULE_API.FEEDBACK_GUESTS.FETCH_FEEDBACK_GUESTS
  );
  const [dataFeedbacks, setDataFeedbacks] = useState<IFeedbackGuestStructure[]>(
    []
  );

  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues: initialFilter,
  });

  const payload: any = useSelector((state: RootState) =>
    _.get(state.FEEDBACK_GUEST, 'payload')
  );
  const meta: any = useSelector((state: RootState) =>
    _.get(state.FEEDBACK_GUEST, 'meta')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    _.get(state.FEEDBACK_GUEST, 'requestIsLoading')
  );
  const count =
    Math.ceil((meta?.totalItems as number) / (meta?.itemsPerPage as number)) ||
    0;

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChangeExpanded =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const fetchPayload = (filterParams: any) => {
    const resolverFilter = Utils.validateFilters(filterParams);
    dispatch(fetchFeedbackGuests(resolverFilter));
  };

  const onSearch = (data: any) => {
    if (isAcceptFetch) fetchPayload(data);
  };

  const onReset = () => {
    reset();
    if (isAcceptFetch) fetchPayload(initialFilter);
  };

  useEffect(() => {
    if (isAcceptFetch) setDataFeedbacks(payload);
  }, [payload]);

  React.useEffect(() => {
    if (isAcceptFetch) fetchPayload(initialFilter);
  }, []);

  const handleChange = (_e: any, newPage: number) => {
    const currentFilter = getValues();
    if (isAcceptFetch) fetchPayload({ ...currentFilter, page: newPage });
  };

  const _renderTopSection = () => {
    return (
      <Stack>
        <Typography variant="h2">{t('title.feedbackGuests')}</Typography>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row">
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  size="small"
                  onChange={(e: any) => field.onChange(e.target.value)}
                  placeholder={t('placeholder.keyword')}
                  sx={{ mr: 1, width: '250px' }}
                />
              )}
            />
            <Button
              sx={{ mr: 1 }}
              onClick={handleSubmit(onSearch)}
              variant="contained"
            >
              {t('button.filter')}
            </Button>
            <Button onClick={() => onReset()} variant="outlined">
              {t('button.reset')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const _renderbodySection = () => {
    if (dataFeedbacks.length === 0)
      return (
        <RoundedContainer sx={{ mt: 2, maxHeight: 150 }}>
          <Typography>
            {t('tooltip.noDocumentationFoundForThisFeedback')}
          </Typography>
        </RoundedContainer>
      );
    return (
      <Stack sx={{ mt: 2 }}>
        {_.map(dataFeedbacks, (item: any, index: number) => {
          return (
            <Accordion
              key={index}
              sx={{ mb: 2, p: 2 }}
              expanded={expanded === item.id}
              onChange={handleChangeExpanded(item.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Grid container direction="row">
                  <Grid item xs={8}>
                    <Box display="flex">
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: 16,
                        }}
                      >
                        {`${t('label.title')}:`}{' '}
                      </Typography>
                      <Tooltip title={item.subject}>
                        <Typography
                          variant="h6"
                          sx={{
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            maxWidth: 550,
                            textOverflow: 'ellipsis',
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {item.subject}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box mt={2} display="flex">
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: 16,
                        }}
                      >
                        {t('label.createdAt')}:{' '}
                      </Typography>
                      <Typography
                        sx={{ color: CommonColors.shuttleGray, fontSize: 16 }}
                      >
                        {item.updatedAt
                          ? dayjs(item.updatedAt).format('HH:mm, DD-MM-YYYY')
                          : 'N/I'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex">
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: 16,
                        }}
                      >
                        {t('label.firstName')}:{' '}
                      </Typography>
                      <Tooltip title={item.firstName}>
                        <Typography
                          sx={{
                            color: '#000000de',
                            fontSize: 16,
                            minWidth: 70,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            maxWidth: 200,
                          }}
                        >
                          {item.firstName}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box display="flex">
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: 16,
                        }}
                      >
                        {t('label.lastName')}:
                      </Typography>
                      <Tooltip title={item.lastName}>
                        <Typography
                          sx={{
                            color: '#000000de',
                            fontSize: 16,
                            minWidth: 70,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            maxWidth: 200,
                          }}
                        >
                          {item.lastName}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: 16,
                        }}
                      >
                        {`${t('label.email')}:`}
                      </Typography>
                      <Tooltip title={item.email}>
                        <Typography
                          sx={{
                            fontSize: 16,
                            color: '#000000de',
                            minWidth: 70,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            maxWidth: 200,
                          }}
                        >
                          {item?.email}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails sx={{ display: 'flex', gap: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: 16,
                  }}
                >
                  {t('label.message')}:
                </Typography>
                <Typography>{item.message}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Stack>
    );
  };

  const _renderPagination = () => {
    return (
      <Grid item xs={12} display="flex" justifyContent="center" my={4}>
        <Pagination
          count={count}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Grid>
    );
  };

  const _renderContent = () => (
    <>
      {_renderTopSection()}
      {isLoading ? <EventSkeleton /> : <Box> {_renderbodySection()}</Box>}
      {_renderPagination()}
    </>
  );

  const renderMain = () => {
    return (
      <Stack flex={1} direction="column">
        {_renderContent()}
      </Stack>
    );
  };

  return renderMain();
};

export default FeedbackGuest;
