import React from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { filter, map, findIndex } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { IFileDetailsStructure } from '../../../Interfaces/File.interface';

interface IProps {
  payload: IFileDetailsStructure[];
  selectedImage: string;
  onClose(): void;
}

const imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'tiff', 'pdf', 'svg'];
const videoExtensions = [
  'webm',
  'mpg',
  'mp2',
  'mpeg',
  'mpe',
  'mpv',
  'ogg',
  'mp4',
  'm4p',
  'm4v',
  'avi',
  'mov',
  'wmv',
  'flv',
];

const PreviewImages: React.FC<IProps> = ({
  payload,
  selectedImage = '',
  onClose,
}) => {
  const [activeImage, setActiveImage] = React.useState<string>(selectedImage);
  const activeImageRef = React.useRef<string | null>(null);
  activeImageRef.current = activeImage;

  const onKeyup = (event: KeyboardEvent) => {
    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      const selectedIndex = findIndex(
        payload,
        (item: IFileDetailsStructure) => item.path === activeImageRef.current
      );
      let newIndex = selectedIndex - 1;
      if (event.key === 'ArrowRight') newIndex = selectedIndex + 1;
      const newItem = payload[newIndex];
      if (newItem) setActiveImage(newItem.path);
    }
  };

  React.useEffect(() => {
    window.addEventListener('keyup', onKeyup);

    return () => {
      window.removeEventListener('keyup', onKeyup);
    };
  }, []);

  const onClickLayer = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const element = event.target as HTMLElement;
    const isImage = element?.classList.contains('img');
    const isVideo = element?.classList.contains('video');
    const isArrowButton = element?.classList.contains('arrow-btn');
    if (!isImage && !isVideo && !isArrowButton) onClose();
  };

  const onArrow = (index: number, direction: 'left' | 'right') => {
    let newIndex = index - 1;
    if (direction === 'right') newIndex = index + 1;
    const newItem = payload[newIndex];
    if (newItem) setActiveImage(newItem.path);
  };

  const _renderPreviewBar = () => {
    const filterPayload = filter(payload, (item: IFileDetailsStructure) => {
      const extFile = item.originalName.split('.').pop();
      if (
        extFile &&
        (imageExtensions.includes(extFile) || videoExtensions.includes(extFile))
      )
        return item;
      return null;
    });

    const elements = map(filterPayload, (item: IFileDetailsStructure) => {
      const isActive = activeImage === item.path;
      const extFile = item.path.split('.').pop();
      const isImage = extFile ? imageExtensions.includes(extFile) : false;
      return (
        <Box
          sx={{
            minWidth: '60px',
            width: '60px',
            height: '60px',
            position: 'relative',
            borderRadius: '5px',
            m: '0 4px',
            borderWidth: '2px ',
            boxSizing: 'border-box',
            borderStyle: 'solid',
            borderColor: isActive ? 'white' : 'transparent',
            overflow: 'hidden',
            ':hover': {
              cursor: 'pointer',
              '*': {
                background: 'transparent',
              },
            },
          }}
          key={`small-image-${item.id}`}
          onClick={() => setActiveImage(item.path)}
        >
          <Box
            component="div"
            className={isImage ? 'img' : 'video'}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: isActive ? 'transparent' : 'rgba(0,0,0,0.5)',
            }}
          />
          {isImage ? (
            <Box
              component="img"
              src={item.thumbnail}
              className="img"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          ) : (
            <Box
              component="video"
              className="video"
              src={item.path}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          )}
        </Box>
      );
    });
    return (
      <Stack
        direction="row"
        sx={{
          width: 'auto',
          maxWidth: '100vw',
          height: '150px',
          alignItems: 'center',
          overflowX: 'auto',
          overflowY: 'hidden',
          margin: '0 auto',
        }}
      >
        {elements}
      </Stack>
    );
  };
  const _renderSelectedImage = () => {
    const selectedIndex = findIndex(
      payload,
      (item: IFileDetailsStructure) => item.path === activeImage
    );

    const isDisabledLeftBtn = selectedIndex === 0;
    const isDisabledRightBtn = selectedIndex === payload.length - 1;

    const extFile = activeImage.split('.').pop();
    const isImage = extFile ? imageExtensions.includes(extFile) : false;

    return (
      <Stack
        sx={{
          width: '100%',
          height: 'calc(100% - 150px)',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          position: 'relative',
        }}
      >
        {!isDisabledLeftBtn ? (
          <Box
            component="div"
            sx={{
              position: 'absolute',
              top: `calc(50%)`,
              left: 25,
              zIndex: 99999,
            }}
          >
            <IconButton
              color="primary"
              onClick={() =>
                !isDisabledLeftBtn && onArrow(selectedIndex, 'left')
              }
              sx={{
                backgroundColor: '#ccc',
                ':hover': {
                  backgroundColor: '#ccc',
                },
              }}
            >
              <KeyboardArrowLeftOutlinedIcon className="arrow-btn" />
            </IconButton>
          </Box>
        ) : null}
        <Box
          component="div"
          sx={{
            width: '100%',
            maxWidth: '900px',
            height: '100%',
            // maxHeight: '600px',
            background: 'rgba(255,255,255,0.2)',
          }}
        >
          {isImage ? (
            <Box
              component="img"
              className="img"
              src={activeImage}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          ) : (
            <Box
              component="video"
              className="video"
              controls
              src={activeImage}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          )}
        </Box>
        {!isDisabledRightBtn ? (
          <Box
            component="div"
            sx={{ position: 'absolute', top: `calc(50%)`, right: 25 }}
          >
            <IconButton
              disabled={isDisabledRightBtn}
              color="primary"
              onClick={() =>
                !isDisabledRightBtn && onArrow(selectedIndex, 'right')
              }
              sx={{
                backgroundColor: '#ccc',
                ':hover': {
                  backgroundColor: '#ccc',
                },
              }}
            >
              <KeyboardArrowRightOutlinedIcon className="arrow-btn" />
            </IconButton>
          </Box>
        ) : null}
      </Stack>
    );
  };

  const _renderCloseButton = () => (
    <Box
      component="div"
      sx={{ position: 'absolute', top: 25, right: 25, zIndex: 1 }}
    >
      <IconButton
        onClick={onClose}
        color="primary"
        sx={{
          backgroundColor: '#ccc',
          ':hover': {
            backgroundColor: '#ccc',
            cursor: 'pointer',
          },
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );

  return (
    <Stack
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(0,0,0,0.7)',
        zIndex: 9999,
        display: 'flex',
        flexDirection: 'column',
      }}
      onClick={onClickLayer}
    >
      {_renderCloseButton()}
      {_renderSelectedImage()}
      {_renderPreviewBar()}
    </Stack>
  );
};

export default PreviewImages;
