import * as React from 'react';
import { get, forEach } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-multi-lang';
import { LoadingButton } from '@mui/lab';
import { Stack, Box } from '@mui/material';
import { useSelector } from 'react-redux';

import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import { Dropdown, TextField } from '@/Components/Common';

import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import { Alert } from '@/Widgets';
import { IRoleDetailsStructure } from '@/Interfaces/Role.interface';

import { UserActions, PermissionActions } from '@/Actions';
import PopupLayout from '../PopupLayout';

interface SectionProps {
  open: boolean;
  onClose(): void;
}

interface IOption {
  label: string;
  value: string;
}

const { fetchRoleOptions, setDefaultReducerPermission } = PermissionActions;
const { createUser, clearStatusUserDetails } = UserActions;

const AddNewUser: React.FC<SectionProps> = ({ open, onClose }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const isCreateUserSuccess = useSelector((state: RootState) =>
    get(state.USER, 'createUserIsSuccess')
  );
  const isLoading = useSelector((state: RootState) =>
    get(state.USER, 'requestIsLoading')
  );

  const roles = useSelector((state: RootState) => get(state.ROLE, 'options'));

  const schema = yup
    .object({
      email: yup.string().email().required(t('message.emailRequired')),
      fullName: yup.string().trim().required(t('message.fullNameRequired')),
      role: yup.string().required(t('message.roleRequired')),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      fullName: '',
      role: '',
    },
  });

  React.useEffect(() => {
    dispatch(fetchRoleOptions());
    return () => {
      dispatch(setDefaultReducerPermission());
    };
  }, []);

  React.useEffect(() => {
    if (open) {
      const isAcceptApi = Utils.isValidPermission(
        ModuleApiConstant.USER.CREATE_USER
      );
      if (!isAcceptApi) {
        onClose();
        Alert({
          type: 'ERROR',
          message: t('message.notAuthorizeForCreateUser'),
        });
      }
    } else reset();
  }, [open]);

  const onCancel = () => {
    dispatch(clearStatusUserDetails());
    reset();
    onClose();
  };

  React.useEffect(() => {
    if (isCreateUserSuccess) onCancel();
  }, [isCreateUserSuccess]);

  const roleOptions = React.useMemo(() => {
    const resolveOptions: IOption[] = [];
    forEach(roles, (role: IRoleDetailsStructure) =>
      resolveOptions.push({ label: role.name, value: role.id })
    );
    return resolveOptions;
  }, [roles]);

  const onSubmit = (data: { fullName: string; email: string; role: string }) =>
    dispatch(createUser(data));

  const _renderForm = () => (
    <Box component="form" sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          name="fullName"
          control={control}
          render={({ field }) => (
            <TextField
              label={t('label.fullName')}
              placeholder="John Doe"
              value={field.value}
              message={errors.fullName?.message}
              onChange={(e: any) => field.onChange(e)}
              required
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              label={t('label.email')}
              placeholder="example@flow-idra.com"
              value={field.value}
              message={errors.email?.message}
              onChange={(e: any) => field.onChange(e)}
              required
            />
          )}
        />
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <Dropdown
              options={roleOptions}
              sx={{ display: 'flex' }}
              label={t('label.role')}
              required
              message={errors.role?.message}
              value={field.value}
              onChange={(e: any) => field.onChange(e.target.value)}
            />
          )}
        />
      </Box>
      <Stack sx={{ float: 'right', marginTop: 5 }} direction="row">
        <LoadingButton sx={{ mr: 1 }} onClick={onCancel} variant="outlined">
          {t('button.cancel')}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {t('button.save')}
        </LoadingButton>
      </Stack>
    </Box>
  );

  return (
    <PopupLayout
      title={t('title.addUser')}
      content={_renderForm()}
      open={open}
      onClose={onClose}
    />
  );
};

export default AddNewUser;
