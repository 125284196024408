import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';

const { ATTENDANCE } = CONSTANTS.API;

export const fetchAttendances = async (payload: any) => {
  return sendRequest(ATTENDANCE.BASIC, 'GET', payload);
};

export const updateAttendance = async (payload: any) => {
  return sendRequest(ATTENDANCE.BASIC, 'PUT', payload);
};

export const exportAttendance = async (payload: any) => {
  return sendRequest(ATTENDANCE.EXPORT, 'GET_BLOB', payload);
};
