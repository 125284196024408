import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const initialState = {
  selectedTool: '',
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_SELECTED_TOOL:
      return {
        ...state,
        selectedTool: payload,
      };
    default:
      return state;
  }
};
