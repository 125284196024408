import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { t } from 'react-multi-lang';
import { useSelector } from 'react-redux';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Skeleton,
} from '@mui/material';

import Utils from '@/Utils';
import { RootState } from '@/store';
import CONSTANTS from '@/Constants';
import NoteReportAttendance from '@/Containers/MainContainer/Attendances/NoteReportAttendance';
import { DropDownAttendances, StickyHeadTable } from '@/Components/Common';

const { ENUMS, MODULE_API } = CONSTANTS;

interface ISectionProps {
  payload: any;
  head: any[];
  isLoading: boolean;
  onRowAction(
    item: any,
    value: string,
    date: string,
    attendanceId?: string
  ): void;
}

const attendanceOptions = [
  { value: ENUMS.Attendances.X, label: 'X' },
  { value: ENUMS.Attendances.P, label: 'P' },
  { value: ENUMS.Attendances.KL, label: 'KL' },
  { value: ENUMS.Attendances.OM, label: 'OM' },
  { value: ENUMS.Attendances.NV, label: 'NV' },
  { value: ENUMS.Attendances.CD, label: 'CD' },
  { value: ENUMS.Attendances.X2, label: 'X/2' },
  { value: ENUMS.Attendances.P2, label: 'P/2' },
  { value: ENUMS.Attendances.K2, label: 'K/2' },
  { value: ENUMS.Attendances.CO, label: 'CO' },
  { value: ENUMS.Attendances.T, label: 'T' },
];

// report attendance table
interface IDataReportRowStructure {
  fullName: string;
  totalAttendance: number;
  holidayAndLeave: number;
  paidLeave: number;
  totalSalary: number;
}

interface IMetaStructure {
  monthAndYear: string;
  keyword: string;
}

interface IReportColumnStructure {
  id:
    | 'fullName'
    | 'totalAttendance'
    | 'holidayAndLeave'
    | 'paidLeave'
    | 'totalSalary';
  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  wordWrap?: string;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

const REPORT_COLUMNS: IReportColumnStructure[] = [
  {
    id: 'fullName',
    label: 'fullName',
    maxWidth: 100,
    align: 'left',
  },
  {
    id: 'totalAttendance',
    label: 'totalAttendance',
    maxWidth: 50,
    align: 'center',
  },
  {
    id: 'holidayAndLeave',
    label: 'onLeave&holiday',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'paidLeave',
    label: 'paidLeave',
    minWidth: 50,
    align: 'center',
  },
  { id: 'totalSalary', label: 'totalSalary', minWidth: 50, align: 'center' },
];

const createDataReportTable = (
  fullName: string,
  totalAttendance: number,
  holidayAndLeave: number,
  paidLeave: number,
  totalSalary: number
): IDataReportRowStructure => {
  return {
    fullName,
    totalAttendance,
    holidayAndLeave,
    paidLeave,
    totalSalary,
  };
};

const AttendancesDataTable: React.FC<ISectionProps> = ({
  payload,
  head,
  onRowAction,
  isLoading,
}) => {
  const isAcceptEdit = Utils.isValidPermission(MODULE_API.ATTENDANCE.UPDATE);
  const [reportRows, setReportRows] = React.useState<IDataReportRowStructure[]>(
    []
  );

  const [currentPayload, setCurrentPayload] = React.useState<any[]>(payload);
  const meta: IMetaStructure = useSelector((state: RootState) =>
    _.get(state.ATTENDANCE, 'meta')
  );

  React.useEffect(() => {
    const resolveRows: IDataReportRowStructure[] = [];
    let totalMathTotalAttendance = 0;
    let totalMathHolidayAndLeave = 0;
    let totalMathPaidLeave = 0;

    if (!_.isEmpty(currentPayload)) {
      _.forEach(currentPayload, (item: any) => {
        let mathAttendance = 0;
        let mathHolidayAndLeave = 0;
        let mathPaidLeave = 0;
        _.map(item.attendances, (itemAttendances: any) => {
          switch (itemAttendances.typeAttendances) {
            case ENUMS.Attendances.P:
              mathHolidayAndLeave += 1;
              break;
            case ENUMS.Attendances.P2:
              mathHolidayAndLeave += 0.5;
              mathAttendance += 0.5;
              break;
            case ENUMS.Attendances.K2:
              mathHolidayAndLeave += 0.5;
              break;
            case ENUMS.Attendances.X:
              mathAttendance += 1;
              break;
            case ENUMS.Attendances.X2:
              mathAttendance += 0.5;
              break;
            case ENUMS.Attendances.CD:
              mathPaidLeave += 1;
              break;
            default:
              break;
          }
        });
        totalMathTotalAttendance += mathAttendance;
        totalMathHolidayAndLeave += mathHolidayAndLeave;
        totalMathPaidLeave += mathPaidLeave;
        resolveRows.push(
          createDataReportTable(
            item.userData.fullName,
            mathAttendance,
            mathHolidayAndLeave,
            mathPaidLeave,
            mathAttendance + mathHolidayAndLeave + mathPaidLeave
          )
        );
      });
    }

    resolveRows.push({
      fullName: t('table.total'),
      totalAttendance: totalMathTotalAttendance,
      holidayAndLeave: totalMathHolidayAndLeave,
      paidLeave: totalMathPaidLeave,
      totalSalary:
        totalMathTotalAttendance +
        totalMathHolidayAndLeave +
        totalMathPaidLeave,
    });
    setReportRows(resolveRows);
  }, [currentPayload]);

  const addOrUpdateAttendance = (
    item: { id: string; attendances: any[] },
    value: string,
    date: string,
    attendanceId?: string
  ) => {
    setCurrentPayload(
      currentPayload.map((child) => {
        if (child.id !== item.id) {
          return { ...child };
        }

        const attendances = [..._.get(child, 'attendances', [])];
        const existingAttendance = attendanceId
          ? attendances.find((data) => data.id === attendanceId)
          : attendances.find((data) => data.date === date);

        if (existingAttendance) {
          return {
            ...child,
            attendances: attendances.map((data) => {
              if (data === existingAttendance) {
                return { ...data, typeAttendances: value };
              } else {
                return { ...data };
              }
            }),
          };
        } else {
          return {
            ...child,
            attendances: [...attendances, { date, typeAttendances: value }],
          };
        }
      })
    );

    onRowAction(item, value, date, attendanceId);
  };

  const getDateInMonth = (month: number, year: number) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const createDataTable = (): any => {
    const d = meta.monthAndYear ? new Date(meta.monthAndYear) : new Date();
    const dateInmonth = getDateInMonth(d.getMonth(), d.getFullYear());
    return _.map(currentPayload, (item: any, index: number) => {
      const { userData, attendances } = item;
      const currentData = [];
      currentData.push(
        <Typography sx={{ p: '0 5px', textAlign: 'center' }}>
          {index + 1}
        </Typography>
      );
      currentData.push(
        <Typography sx={{ p: '0 10px' }}>
          {_.get(userData, 'fullName')}
        </Typography>
      );
      if (_.isEmpty(attendances)) {
        _.forEach(dateInmonth, (date: any) => {
          const day = date.toLocaleString('en-us', { weekday: 'short' });
          const currentDate = dayjs(date).format('MM/DD/YYYY');
          if (isAcceptEdit)
            currentData.push(
              <DropDownAttendances
                value=""
                onChange={(e: any) =>
                  addOrUpdateAttendance(item, e.target.value, currentDate)
                }
                options={attendanceOptions}
                sx={{
                  '.MuiSelect-select': {
                    background: (day === 'Sat' || day === 'Sun') && '#c7c7c72f',
                  },
                }}
              />
            );
          else
            currentData.push(
              <Stack
                sx={{
                  width: 1,
                  height: 1,
                  padding: '5px 0',
                  background:
                    day === 'Sat' || day === 'Sun' ? '#c7c7c72f' : 'none',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '0.9rem',
                    minWidth: '50px',
                    height: '22px',
                  }}
                />
              </Stack>
            );
        });
      } else {
        _.forEach(dateInmonth, (date: any) => {
          const day = date.toLocaleString('en-us', { weekday: 'short' });
          const currentDate = dayjs(date).format('MM/DD/YYYY');
          const arrayDate: any = [];
          _.forEach(attendances, (attendance: any) => {
            const attendanceDate = dayjs(attendance.date).format('MM/DD/YYYY');
            if (attendanceDate === currentDate) {
              arrayDate.push(attendanceDate);
            }
          });
          if (!arrayDate.includes(currentDate)) {
            if (isAcceptEdit)
              currentData.push(
                <DropDownAttendances
                  value=""
                  onChange={(e: any) =>
                    addOrUpdateAttendance(item, e.target.value, currentDate)
                  }
                  options={attendanceOptions}
                  sx={{
                    '.MuiSelect-select': {
                      background:
                        (day === 'Sat' || day === 'Sun') && '#c7c7c72f',
                    },
                  }}
                />
              );
            else
              currentData.push(
                <Stack
                  sx={{
                    width: 1,
                    height: 1,
                    background:
                      day === 'Sat' || day === 'Sun' ? '#c7c7c72f' : 'none',
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '0.9rem',
                      minWidth: '50px',
                      height: '22px',
                    }}
                  >
                    {attendances.typeAttendances}
                  </Typography>
                </Stack>
              );
          } else {
            _.forEach(attendances, (attendance: any) => {
              const attendanceDate = dayjs(attendance.date).format(
                'MM/DD/YYYY'
              );
              if (attendanceDate === currentDate) {
                if (isAcceptEdit)
                  currentData.push(
                    <DropDownAttendances
                      sx={{
                        '.MuiSelect-select': {
                          background:
                            (day === 'Sat' || day === 'Sun') && '#c7c7c72f',
                        },
                      }}
                      value={attendance.typeAttendances || ''}
                      onChange={(e: any) =>
                        addOrUpdateAttendance(
                          item,
                          e.target.value,
                          currentDate,
                          attendance.id
                        )
                      }
                      options={attendanceOptions}
                    />
                  );
                else
                  currentData.push(
                    <Stack
                      direction="column"
                      sx={{
                        padding: '5px 0',
                        background:
                          day === 'Sat' || day === 'Sun' ? '#c7c7c72f' : 'none',
                        flex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontSize: '0.9rem',
                          minWidth: '50px',
                          height: '22px',
                        }}
                      >
                        {attendance.typeAttendances}
                      </Typography>
                    </Stack>
                  );
              }
            });
          }
        });
      }
      return currentData;
    });
  };

  const _renderCellData = () => {
    const currentData = createDataTable();
    const render = _.map(currentData, (row: any, index: number) => {
      return (
        <TableRow key={index}>
          {_.map(row, (value: any, key: number) => {
            return (
              <TableCell
                key={key}
                style={{
                  width: 40,
                  height: 10,
                  padding: isAcceptEdit ? '5px 0px' : '0px 0px',
                  border: '1px solid rgba(224, 224, 224, 1)',
                }}
              >
                {value}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
    return render;
  };

  const _renderTable = () => {
    return (
      <TableContainer
        style={{
          width: '100%',
          maxWidth: '1300px',
        }}
        sx={{
          overflowY: 'hidden',
        }}
      >
        {!isLoading ? (
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ m: '10px 0', borderCollapse: 'collapse' }}
          >
            <TableHead>
              <TableRow>
                {head.map((column, key) => (
                  <TableCell
                    key={key}
                    align="left"
                    style={{
                      minWidth: column.minWidth,
                      width: column.width || 'auto',
                      flex: column.flex || 'unset',
                      padding: '0 10px',
                      border: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    {column !== 'no' || column !== 'fullName'
                      ? column.label
                      : t(`table.${column.label}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(_renderCellData()) ? (
                _renderCellData()
              ) : (
                <TableRow>
                  <TableCell colSpan={33}>
                    <Typography sx={{ p: 0, fontSize: 14, fontWeight: 600 }}>
                      {t(`table.noDataWereFound`)}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        ) : (
          <Table stickyHeader aria-label="sticky table" sx={{ m: '10px 0' }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={20}>
                  <Skeleton variant="rectangular" width="100%" height={10} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={20}>
                  <Skeleton variant="rectangular" width="100%" height={10} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={20}>
                  <Skeleton variant="rectangular" width="100%" height={10} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={20}>
                  <Skeleton variant="rectangular" width="100%" height={10} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={20}>
                  <Skeleton variant="rectangular" width="100%" height={10} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={20}>
                  <Skeleton variant="rectangular" width="100%" height={10} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={20}>
                  <Skeleton variant="rectangular" width="100%" height={10} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={20}>
                  <Skeleton variant="rectangular" width="100%" height={10} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={20}>
                  <Skeleton variant="rectangular" width="100%" height={10} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={20}>
                  <Skeleton variant="rectangular" width="100%" height={10} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={20}>
                  <Skeleton variant="rectangular" width="100%" height={10} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={20}>
                  <Skeleton variant="rectangular" width="100%" height={10} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
    );
  };

  const _renderReportTable = () => {
    return (
      <Stack direction="row">
        <Stack
          sx={{
            '& .MuiTableRow-root:last-child': {
              background: '#7777771d',
            },
          }}
        >
          <Typography fontSize="18px" fontWeight="410" mb={1} mt={2}>
            {t('label.reportTimesheet')}
          </Typography>
          <StickyHeadTable
            columns={REPORT_COLUMNS}
            rows={reportRows}
            sx={{
              maxHeight: '100%',
            }}
          />
        </Stack>
        <Stack>
          <NoteReportAttendance />
        </Stack>
      </Stack>
    );
  };

  return (
    <div>
      {_renderTable()}
      {_renderReportTable()}
    </div>
  );
};

export default AttendancesDataTable;
