import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Grid,
  Link,
  Typography,
  AppBar,
  Toolbar,
  LinearProgress,
  Button,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemText,
  Container,
  IconButton,
  Drawer,
  ListItemButton,
  Divider,
  ListItemIcon,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ProgressiveImage from 'react-progressive-graceful-image';
import _ from 'lodash';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CallIcon from '@mui/icons-material/Call';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import InfoIcon from '@mui/icons-material/Info';
import CONSTANTS from '@/Constants';
import {
  projectInfor,
  projectWrapper,
  projectLink,
  newProjectBadge,
} from './Appbar.Styles';
import { Images, CommonColors } from '@/Themes';
import { RootState, useTypedDispatch } from '@/store';
import { PorfolioActions } from '@/Actions';

const { LIST, ROUTERS, ENUMS } = CONSTANTS;

const { MENU_LIST } = LIST;

interface ISectionProps {
  isScroll?: boolean;
}
const { getFeaturePorfolios } = PorfolioActions;

const AppbarDesktop: React.FC<ISectionProps> = () => {
  // const isBreakLine = useMediaQuery('(max-width:1192px)');
  const dispatch = useTypedDispatch();
  const pathName = useLocation().pathname;
  const navigate = useNavigate();
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuRef = useRef(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const portfolioFeatured = useSelector((state: RootState) =>
    _.get(state.PORTFOLIO, 'featured')
  );

  const calculateScrollPercentage = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight - windowHeight;
    const scrolled = window.scrollY;
    const percentage = (scrolled / documentHeight) * 100;

    setScrollPercentage(percentage);
  };

  useEffect(() => {
    dispatch(
      getFeaturePorfolios({
        featured: true,
        page: 1,
        limit: 10,
        status: ENUMS.NewsStatus.PUBLISH,
      })
    );
    window.addEventListener('scroll', calculateScrollPercentage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', calculateScrollPercentage);
    };
  }, []);

  const handleClick = () => {
    if (menuRef) setAnchorEl(menuRef.current);
  };

  const _renderImageProjects = () => (
    <Grid
      item
      xs={10}
      sx={{
        background: 'rgb(244,246,248)',
        p: '40px',
      }}
    >
      <Box sx={projectWrapper}>
        {_.map(_.slice(portfolioFeatured, 0, 5), (item: any) => (
          <Box
            key={item?.id}
            component="a"
            href={`${ROUTERS.PORTFOLIO}/${item?.id}`}
          >
            <Paper sx={projectInfor} elevation={0}>
              <ProgressiveImage
                src={_.get(item?.image[0], 'file.path')}
                placeholder={_.get(item?.image[0], 'file.thumbnail')}
              >
                {(src: string, loading: boolean | undefined): any => (
                  <img
                    className={`image${
                      loading ? ' loading-image' : ' loaded-image'
                    } pr-img`}
                    src={src}
                    alt="sea beach"
                  />
                )}
              </ProgressiveImage>
              <Typography className="pr-title">{item?.name}</Typography>
              <Box sx={newProjectBadge}>New</Box>
            </Paper>
          </Box>
        ))}
      </Box>
    </Grid>
  );

  const _renderListProjects = () => (
    <Grid item xs={2} sx={{ p: '40px', background: 'white' }}>
      <List dense>
        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontWeight: 600, fontSize: '1.125rem' }}>
                Featured
              </Typography>
            }
          />
        </ListItem>
        {_.map(_.slice(portfolioFeatured, 4, 9), (item: any) => (
          <ListItem key={item?.id}>
            <ListItemText
              primary={
                <Link
                  sx={projectLink}
                  href={`${ROUTERS.PORTFOLIO}/${item?.id}`}
                  underline="none"
                >
                  {item?.name}
                </Link>
              }
            />
          </ListItem>
        ))}
      </List>
    </Grid>
  );

  const _renderPopper = () => (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      onMouseLeave={() => setAnchorEl(null)}
      placement="bottom"
      sx={{
        width: 1,
        // p: 2,
        mt: 1,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 999,
      }}
    >
      <Grid container>
        {_renderImageProjects()}
        {_renderListProjects()}
      </Grid>
      <Box sx={{ mt: 2, textAlign: 'right' }}>
        <Button
          variant="contained"
          color="ebonyClay"
          sx={{ fontWeight: 700, fontSize: '0.875rem' }}
          endIcon={<OpenInNewIcon />}
          onClick={() => navigate(ROUTERS.PORTFOLIO)}
        >
          See more our products
        </Button>
      </Box>
    </Popper>
  );

  const _renderMenu = useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          columnGap: 2,
        }}
      >
        {_.map(MENU_LIST.USER_MENU, (item: any, index: number) => {
          const isActive = pathName === item?.value;
          const isRenderPopover = item?.value === ROUTERS.PORTFOLIO;
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                '&:hover': { '& .hovered-dot': { opacity: 1 } },
              }}
              onClick={() => {
                if (!isRenderPopover) navigate(item?.value);
                else handleClick();
              }}
              onMouseEnter={() => {
                if (isRenderPopover) handleClick();
              }}
            >
              <FiberManualRecordIcon
                sx={{ fontSize: 10, opacity: isActive ? 1 : 0 }}
                color="warning"
                className="hovered-dot"
              />
              <Typography
                sx={{
                  fontWeight: isActive ? 600 : 500,
                  fontSize: 15,
                  mx: 0.5,
                }}
              >
                {item?.label}
              </Typography>
              {isRenderPopover && (
                <KeyboardArrowDownIcon sx={{ fontSize: 20 }} />
              )}
            </Box>
          );
        })}
      </Box>
    ),
    [pathName, anchorEl]
  );

  const _renderDrawer = () => (
    <Drawer
      anchor="top"
      open={isOpenDrawer}
      onClose={() => setIsOpenDrawer(false)}
    >
      <List>
        {MENU_LIST.USER_MENU.map((item: any, index) => {
          const isActive = pathName === item?.value;
          return (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={() => navigate(item?.value)}>
                <ListItemIcon>
                  {item?.value === ROUTERS.HOME && <HomeIcon />}
                  {item?.value === ROUTERS.PORTFOLIO && <NewspaperIcon />}
                  {item?.value === ROUTERS.ABOUT_US && <InfoIcon />}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontWeight: isActive ? 600 : 500,
                        color: isActive ? CommonColors.burntSienna : 'black',
                        fontSize: 15,
                        mx: 0.5,
                      }}
                    >
                      {item?.label}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
        <Divider />
        <ListItem>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', width: 1 }}
          >
            <LazyLoadImage
              src={Images.logo}
              alt="logo"
              style={{ cursor: 'pointer' }}
              loading="lazy"
              onClick={() => navigate(ROUTERS.HOME)}
            />
            <Button
              variant="contained"
              color="ebonyClay"
              sx={{
                fontWeight: 700,
                fontSize: '0.875rem',
              }}
              startIcon={<CallIcon />}
              onClick={() => navigate(ROUTERS.CONTACT)}
            >
              Contact Us
            </Button>
          </Box>
        </ListItem>
      </List>
    </Drawer>
  );

  return (
    <AppBar
      component="nav"
      sx={{
        background: 'transparent',
        color: 'black',
        boxShadow: 0,
      }}
      ref={menuRef}
    >
      <LinearProgress
        variant="determinate"
        value={scrollPercentage}
        color="warning"
        sx={{ backgroundColor: 'transparent' }}
      />
      <Toolbar
        sx={{
          background: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(6px)',
          transition:
            'height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        }}
      >
        {_renderDrawer()}
        <Container maxWidth="lg">
          <Grid
            container
            sx={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Grid sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton onClick={() => setIsOpenDrawer(!isOpenDrawer)}>
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={5} md={3}>
              <LazyLoadImage
                src={Images.logo}
                alt="logo"
                style={{ cursor: 'pointer' }}
                loading="lazy"
                onClick={() => navigate(ROUTERS.HOME)}
              />
            </Grid>
            <Grid item md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
              {_renderMenu}
              {_renderPopper()}
            </Grid>
            <Grid
              item
              md={3}
              sx={{ display: { xs: 'none', md: 'block' } }}
              textAlign="right"
            >
              <Button
                variant="contained"
                color="ebonyClay"
                sx={{
                  fontWeight: 700,
                  fontSize: '0.875rem',
                }}
                startIcon={<CallIcon />}
                onClick={() => navigate(ROUTERS.CONTACT)}
              >
                Contact Us
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default AppbarDesktop;
