import CONSTANTS from '@/Constants';
import { UserContainer } from '@/Containers';
import PreLoading from './UserPreLoading';
import { UserLayout } from '@/Components/DefaultLayout';

const { ROUTERS } = CONSTANTS;

const HomeRouter = {
  // path: ROUTERS.HOME,
  element: (
    <PreLoading>
      <UserLayout />
    </PreLoading>
  ),
  children: [
    {
      path: ROUTERS.HOME,
      element: <UserContainer.HomePage />,
    },
    {
      path: ROUTERS.ABOUT_US,
      element: <UserContainer.About />,
    },
    {
      path: ROUTERS.PORTFOLIO,
      element: <UserContainer.Portfolio />,
    },
    {
      path: ROUTERS.OUR_SERVICES,
      element: <UserContainer.OurServices />,
    },
    {
      path: ROUTERS.NEWS,
      element: <UserContainer.NewsList />,
    },
    {
      path: ROUTERS.NEWS_DETAIL,
      element: <UserContainer.NewsDetail />,
    },
    {
      path: ROUTERS.CONTACT,
      element: <UserContainer.Contact />,
    },
    {
      path: ROUTERS.PORTFOLIO_DETAIL,
      element: <UserContainer.PortfolioDetail />,
    },
  ],
};

export default HomeRouter;
