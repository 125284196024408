/* eslint-disable indent */
import React, { useState } from 'react';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-multi-lang';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Autocomplete,
} from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';

import {
  RoundedContainer,
  TextField,
  TextEditor,
  UploadFileInput,
} from '@/Components/Common';

import { RootState, useTypedDispatch } from '@/store';
import Constants from '@/Constants';
import Utils from '@/Utils';
import { PorfolioActions } from '@/Actions';

const { ROUTERS, TECH_LIST, PORTFOLIO_CAT } = Constants;
const { createPorfolio } = PorfolioActions;

const CreatePortfolio: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const isLoading: boolean = useSelector((state: RootState) =>
    _.get(state.ADMIN_PORFOLIO, 'requestIsLoading')
  );
  const [selectedTechnology, setSelectedTechnology] = useState<any>([]);
  const [selectCategory, setSelectCategory] = useState<string>(
    'Media / Entertainment'
  );
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  const schema = yup
    .object({
      name: yup.string().trim().required(t('message.titleRequired')),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      general: '',
      assignment: '',
      solution: '',
      tag: '',
      client: '',
      date: '',
      url: '',
      featured: false,
    },
  });

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('content[general]', data.general);
    formData.append('content[assignment]', data.assignment);
    formData.append('content[solution]', data.solution);
    formData.append('client', data.client);
    formData.append('category', selectCategory);
    formData.append('date', data.date);
    formData.append('url', data.url);
    formData.append('featured', data.featured);
    _.forEach(selectedTechnology, (item: string) => {
      formData.append('technology[]', item);
    });
    if (data.tag) {
      _.forEach(_.compact(data.tag.split(';')), (tag: string) => {
        formData.append('tag[]', tag);
      });
    }
    _.forEach(selectedImages, (item: File) => {
      formData.append('images', item);
    });
    dispatch(createPorfolio(formData));
  };

  const _renderTopSection = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Typography fontSize="20px" fontWeight="500">
        Create Porfolio
      </Typography>
    </Stack>
  );

  const _renderBottomSection = () => (
    <RoundedContainer>
      <Grid
        container
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={10}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label={t('label.name')}
                placeholder={t('placeholder.titlePost')}
                message={errors.name?.message}
                required
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="featured"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={field.value}
                    disabled={isLoading}
                    onChange={(event) => field.onChange(event.target.checked)}
                  />
                }
                label="Featured"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ width: 1 }}>
            <Typography
              sx={{
                mb: 1,
                fontWeight: 400,
                fontFamily: 'sans-serif',
                color: 'black',
              }}
            >
              Category
            </Typography>
            <Select
              value={selectCategory}
              onChange={(event) => setSelectCategory(event.target.value)}
              disabled={isLoading}
              sx={{ width: 1 }}
            >
              {_.map(PORTFOLIO_CAT.sort(), (cat: string) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box sx={{ width: 1 }}>
            <Typography
              sx={{
                mb: 2,
                fontWeight: 400,
                fontFamily: 'sans-serif',
                color: 'black',
              }}
            >
              Technology
            </Typography>
            <Autocomplete
              onChange={(_event, options) => {
                setSelectedTechnology(_.map(options, (el: any) => el?.label));
              }}
              disableCloseOnSelect
              disabled={isLoading}
              options={_.orderBy(TECH_LIST, ['label'], ['asc'])}
              multiple
              sx={{ width: 1 }}
              getOptionLabel={(option: any) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  message={errors.client?.message}
                  disabled={isLoading}
                />
              )}
              // value={getDefaultValue()}
              renderOption={(props, option: any) => {
                return (
                  <Box component="li" {...props}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: 2,
                      }}
                    >
                      <LazyLoadImage
                        src={option?.icon}
                        alt="clientLogo"
                        loading="lazy"
                        style={{
                          width: 20,
                          objectFit: 'contain',
                          height: 20,
                          marginRight: '5px',
                        }}
                      />
                      {option.label}
                    </Box>
                  </Box>
                );
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="tag"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label="Tag"
                message={errors.tag?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="client"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label="Client"
                message={errors.client?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadFileInput
            type="IMAGE"
            isMultiple
            label="Images"
            containerSx={{ mb: 2 }}
            files={selectedImages}
            onFileChange={setSelectedImages}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label="Year"
                message={errors.date?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label="URL"
                message={errors.url?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="general"
            control={control}
            render={({ field }) => (
              <TextEditor
                value={field.value}
                onChange={(value: any) => field.onChange(value)}
                label="General"
                message={errors.general?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="assignment"
            control={control}
            render={({ field }) => (
              <TextEditor
                value={field.value}
                onChange={(value: any) => field.onChange(value)}
                label="Assignment"
                message={errors.assignment?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="solution"
            control={control}
            render={({ field }) => (
              <TextEditor
                value={field.value}
                onChange={(value: any) => field.onChange(value)}
                label="Solution"
                message={errors.solution?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            mt={2}
          >
            <Button
              variant="outlined"
              onClick={() => Utils.redirect(ROUTERS.ADMIN_PORTFOLIO)}
            >
              {t('button.cancel')}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ ml: 1 }}
              loading={isLoading}
            >
              {t('button.create')}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </RoundedContainer>
  );

  const _renderContent = () => (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );

  return <Stack>{_renderContent()}</Stack>;
};

export default CreatePortfolio;
