import React from 'react';
import { useTranslation } from 'react-multi-lang';
import { isEmpty, forEach, get } from 'lodash';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Tooltip, IconButton, Typography, Stack } from '@mui/material';
import { StickyHeadTable } from '@/Components/Common';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import DOMPurify from 'dompurify';
import dayjs from 'dayjs';
import { IDailyReport } from '@/Interfaces/DailyReport.interface';
import Utils from '@/Utils';
import Constants from '../../../Constants';

interface IDataRowStructure {
  no: number;
  createdBy: string;
  date: string;
  description: JSX.Element;
  action: JSX.Element;
}

interface IColumnStructure {
  id: 'no' | 'createdBy' | 'date' | 'description' | 'action';

  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'createdBy', label: 'createdBy', minWidth: 100 },
  { id: 'date', label: 'date', minWidth: 100, align: 'center' },
  { id: 'description', label: 'description', minWidth: 100 },
  { id: 'action', label: 'action', minWidth: 100, align: 'center' },
];

const createDataTable = (
  no: number,
  createdBy: string,
  date: string,
  description: JSX.Element,
  action: JSX.Element
): IDataRowStructure => {
  return {
    no,
    createdBy,
    date,
    description,
    action,
  };
};

interface ISectionProps {
  meta: IMetaStructure;
  payload: IDailyReport[];
  onRowAction(content: IDailyReport, action: 'edit' | 'delete'): void;
  onPageAction(value: number, type: 'limit' | 'page'): void;
}

const { MODULE_API } = Constants;

const MeetingNoteDataTable: React.FC<ISectionProps> = ({
  meta,
  payload,
  onPageAction,
  onRowAction,
}) => {
  // Declare localization
  const t = useTranslation();
  const [rows, setRows] = React.useState<IDataRowStructure[]>([]);
  const isAcceptEdit = Utils.isValidPermission(MODULE_API.DAILY_REPORT.UPDATE);
  const isAcceptDelete = Utils.isValidPermission(
    MODULE_API.DAILY_REPORT.REMOVE
  );

  React.useEffect(() => {
    const resolveRows: IDataRowStructure[] = [];
    if (!isEmpty(payload)) {
      let index = 0;

      forEach(payload, (item: IDailyReport) => {
        const { createdBy, description, date } = item;

        const currentPage = get(meta, 'currentPage');
        index = index + 1 + (currentPage - 1) * 10;
        resolveRows.push(
          createDataTable(
            index,
            createdBy ? createdBy?.email : t('table.noInformation'),
            date
              ? `${dayjs(date).format('DD-MM-YYYY')}`
              : t('table.noInformation'),

            <Typography
              dangerouslySetInnerHTML={{
                __html:
                  DOMPurify.sanitize(description, {
                    FORBID_TAGS: ['style'],
                  }) || t('table.noInformation'),
              }}
              sx={{
                fontSize: '14px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '100%',
                maxWidth: '300px',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                whiteSpace: 'nowrap',
                WebkitLineClamp: 1,
                '*': {
                  margin: 0,
                  fontWeight: 400,
                  fontSize: '14px',
                },
              }}
            />,

            <Stack direction="row" justifyContent="center">
              <Tooltip title={t('tooltip.clickToEditReport')}>
                <span>
                  {' '}
                  <IconButton
                    disabled={!isAcceptEdit}
                    onClick={() => isAcceptEdit && onRowAction(item, 'edit')}
                  >
                    <EditOutlinedIcon fontSize="medium" />
                  </IconButton>
                </span>
              </Tooltip>
              {isAcceptDelete ? (
                <Tooltip title={t('tooltip.clickToDeleteReport')}>
                  <span>
                    <IconButton
                      disabled={!isAcceptDelete}
                      onClick={() =>
                        isAcceptDelete && onRowAction(item, 'delete')
                      }
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : null}
            </Stack>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      onAction={onPageAction}
      paginate={meta}
    />
  );
};

export default MeetingNoteDataTable;
