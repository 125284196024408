import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';

import { Stack, Typography } from '@mui/material';

import Constants from '@/Constants';

const { ROUTERS } = Constants;

const NotFound: React.FC = () => {
  const t = useTranslation();
  return (
    <Stack direction="column" alignItems="center">
      <Typography sx={{ fontWeight: '500', fontSize: '24px' }}>
        404 {t('title.notFound')}
      </Typography>
      <Link to={ROUTERS.HOME} style={{ color: 'black' }}>
        {t('title.backToHome')}
      </Link>
    </Stack>
  );
};

export default NotFound;
