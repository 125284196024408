import React, { useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, Stack, TextField, Typography } from '@mui/material';

import { RoundedContainer, Dropdown } from '@/Components/Common';
import NewsDataTable from '@/Components/LayoutPart/DataTable/NewsDataTable';

import Constants from '@/Constants';
import Utils from '@/Utils';
import { NewsActions } from '@/Actions';
import { Alert } from '@/Widgets';

import { RootState, useTypedDispatch } from '@/store';

import { IUserDetailsStructure } from '@/Interfaces/User.interface';
import { INewsStructure, INewsFilter } from '@/Interfaces/News.interface';

const { ROUTERS, MODULE_API, ENUMS } = Constants;

const { fetchNews, deletePost, updateStatusPost } = NewsActions;

const initialFilter = {
  keyword: '',
  page: 1,
  limit: 10,
  orderBy: 'createdAt',
  status: ENUMS.NewsStatus.ALL_TYPE,
};

const STATUS_DROPDOWN = [
  { value: ENUMS.NewsStatus.ALL_TYPE, label: 'allType' },
  { value: ENUMS.NewsStatus.PUBLISH, label: 'publish' },
  { value: ENUMS.NewsStatus.ACTIVE, label: 'active' },
  { value: ENUMS.NewsStatus.INACTIVE, label: 'inactive' },
];

interface IOption {
  label: string;
  value: string;
}

const NewsManager = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const payload = useSelector((state: RootState) =>
    _.get(state.NEWS, 'payload')
  );
  const isLoading = useSelector((state: RootState) =>
    _.get(state.NEWS, 'requestIsLoading')
  );
  const pagination = useSelector((state: RootState) =>
    _.get(state.NEWS, 'pagination')
  );
  const meta = useSelector((state: RootState) => _.get(state.NEWS, 'meta'));

  const { control, setValue, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialFilter,
  });

  const userData: IUserDetailsStructure = Utils.getSavedUserData();
  const isAcceptFetch = Utils.isValidPermission(MODULE_API.NEWS.FETCH_NEWS);
  const isAcceptCreate = Utils.isValidPermission(MODULE_API.NEWS.CREATE_POST);
  const isAcceptEdit =
    Utils.isValidPermission(MODULE_API.NEWS.UPDATE_POST) ||
    userData?.role?.roleCode === ENUMS.Role.ADMIN;
  const isAcceptDelete =
    Utils.isValidPermission(MODULE_API.NEWS.DELETE_POST) ||
    userData?.role?.roleCode === ENUMS.Role.ADMIN;

  useEffect(() => {
    if (isAcceptFetch) dispatch(fetchNews(pagination));
  }, []);

  const statusOptions = React.useMemo(() => {
    const options: IOption[] = [];
    _.forEach(STATUS_DROPDOWN, (option: IOption) =>
      options.push({ label: t(`menu.${option.label}`), value: option.value })
    );
    return options;
  }, [STATUS_DROPDOWN]);

  const onReset = () => {
    reset();
    dispatch(fetchNews(_.omit(initialFilter, ['status'])));
  };

  const onSearch = (data: INewsFilter) =>
    dispatch(
      fetchNews({
        ...data,
        status: data.status === 'allType' ? '' : data.status,
      })
    );

  const onPageAction = async (value: number, type: 'limit' | 'page') => {
    const currentFilter = getValues();
    const newFilter = {
      ...currentFilter,
      [type]: value,
      status: currentFilter.status === 'allType' ? '' : currentFilter.status,
    };

    setValue(type, value);
    if (type === 'limit') {
      setValue('page', 1);
      Object.assign(newFilter, { page: 1 });
    }
    dispatch(fetchNews(newFilter));
  };

  const onRowAction = async (
    post: INewsStructure,
    action: 'edit' | 'status' | 'delete' | 'active' | 'inactive' | 'publish'
  ) => {
    const { id, title } = post;
    if (action === 'active') {
      const isAcceptUpdate = Utils.isValidPermission(
        MODULE_API.NEWS.UPDATE_POST
      );

      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.confirmChangePostStatusToActive'),
      });
      if (isAgree === 'ok') {
        const currentFilter = getValues();
        const resolveFilter = Utils.validateFilters(currentFilter);
        if (id) {
          if (!isAcceptUpdate) {
            Alert({
              type: 'ERROR',
              message: t('popup.notAuthorizeForUpdatePost'),
            });
          } else {
            dispatch(updateStatusPost(action, id, resolveFilter));
          }
        }
      }
    }
    if (action === 'inactive') {
      const isAcceptUpdate = Utils.isValidPermission(
        MODULE_API.NEWS.UPDATE_POST
      );
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.confirmChangePostStatusToInactive'),
      });
      if (isAgree === 'ok') {
        const currentFilter = getValues();
        const resolveFilter = Utils.validateFilters(currentFilter);
        if (id) {
          if (!isAcceptUpdate) {
            Alert({
              type: 'ERROR',
              message: t('popup.notAuthorizeForUpdatePost'),
            });
          } else {
            dispatch(updateStatusPost(action, id, resolveFilter));
          }
        }
      }
    }
    if (action === 'publish') {
      const isAcceptUpdate = Utils.isValidPermission(
        MODULE_API.NEWS.UPDATE_POST
      );
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.confirmChangePostStatusToPublish'),
      });
      if (isAgree === 'ok') {
        const currentFilter = getValues();
        const resolveFilter = Utils.validateFilters(currentFilter);
        if (id) {
          if (!isAcceptUpdate) {
            Alert({
              type: 'ERROR',
              message: t('popup.notAuthorizeForUpdatePost'),
            });
          } else {
            dispatch(updateStatusPost(action, id, resolveFilter));
          }
        }
      }
    }

    if (action === 'edit' && isAcceptEdit)
      Utils.redirect({ pathname: ROUTERS.UPDATE_POST, id });
    if (action === 'delete' && isAcceptDelete) {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.confirmDeletePost', { title }),
      });
      if (isAgree === 'ok') {
        const currentPage = getValues('page');
        const currentFilter = getValues();
        const resolveFilter = Utils.validateFilters(currentFilter);
        if (payload.length === 1 && currentPage !== 1) {
          setValue('page', 1);
          resolveFilter.page = 1;
        }
        dispatch(deletePost(id, resolveFilter));
      }
    }
  };

  const _renderTopSection = () => {
    return (
      <Stack direction="column" mb={2}>
        <Typography variant="h2">{t('title.news')}</Typography>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  placeholder={t('placeholder.keyword')}
                  sx={{ mr: 1, maxWidth: '190px' }}
                  value={field.value}
                  onChange={(event) => field.onChange(event.target.value)}
                />
              )}
            />

            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Dropdown
                  sx={{ mr: 1, width: '190px' }}
                  value={field.value || ''}
                  placeholder="To"
                  options={statusOptions}
                  onChange={(e: any) => field.onChange(e)}
                />
              )}
            />

            <Button
              onClick={handleSubmit(onSearch)}
              variant="contained"
              sx={{ mr: 1 }}
            >
              {t('button.filter')}
            </Button>
            <Button onClick={onReset} variant="outlined">
              {t('button.reset')}
            </Button>
          </Stack>
          {isAcceptCreate ? (
            <Button
              variant="contained"
              onClick={() => Utils.redirect(ROUTERS.CREATE_POST)}
            >
              {t('button.create')}
            </Button>
          ) : null}
        </Stack>
      </Stack>
    );
  };

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        <NewsDataTable
          meta={meta}
          payload={payload}
          onPageAction={onPageAction}
          onRowAction={onRowAction}
          isLoading={isLoading}
        />
      </RoundedContainer>
    );
  };

  const renderMain = () => {
    return (
      <Stack>
        {_renderTopSection()}
        {_renderBottomSection()}
      </Stack>
    );
  };
  return renderMain();
};
export default NewsManager;
