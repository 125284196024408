import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import GroupsIcon from '@mui/icons-material/GroupsOutlined';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ListAltIcon from '@mui/icons-material/ListAlt';

import CONSTANTS from '@/Constants';
import { ModuleKeys } from '../../Constants/Enums';

const { ROUTERS, ENUMS } = CONSTANTS;

const DEFAULT_MENU = [
  {
    icon: <DashboardOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'dashboard',
    to: `${ROUTERS.DASHBOARD}`,
    key: ModuleKeys.DASHBOARD,
  },
  {
    icon: <PersonOutlineOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'users',
    to: `${ROUTERS.USERS}`,
    key: ModuleKeys.USERS,
  },
  {
    icon: <GroupsIcon sx={{ color: 'inherit' }} />,
    title: 'attendances',
    to: `${ROUTERS.ATTENDANCE}`,
    key: ModuleKeys.ATTENDANCES,
  },
  {
    icon: <BallotOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'projects',
    to: `${ROUTERS.PROJECT_LIST}`,
    key: ModuleKeys.PROJECTS,
  },
  {
    icon: <EventNoteOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'projectReports',
    to: `${ROUTERS.PROJECT_REPORT}`,
    key: ModuleKeys.PROJECT_REPORTS,
  },
  {
    icon: <AccountTreeOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'roles',
    to: `${ROUTERS.ROLE_LIST}`,
    key: ModuleKeys.ROLES,
  },
  {
    icon: <CloudSyncOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'files',
    to: `${ROUTERS.FILE}`,
    key: ModuleKeys.FILES,
  },
  {
    icon: <TextSnippetOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'wiki',
    to: `${ROUTERS.WIKI}`,
    key: ModuleKeys.WIKI,
  },
  {
    icon: <HistoryOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'logs',
    to: `${ROUTERS.LOGS}`,
    key: ModuleKeys.LOGS,
  },
  {
    icon: <CalendarMonthOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'events',
    to: `${ROUTERS.EVENT}`,
    key: ModuleKeys.EVENTS,
  },
  {
    icon: <AnnouncementOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'announcements',
    to: `${ROUTERS.ANNOUNCEMENT}`,
    key: ModuleKeys.ANNOUNCEMENTS,
  },
  {
    icon: <ScreenshotMonitorIcon sx={{ color: 'inherit' }} />,
    title: 'crawlingSystem',
    to: `${ROUTERS.CRAWLING_SYSTEM}`,
    key: ModuleKeys.CRAWLING_SYSTEM,
  },
  {
    icon: <ChatOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'feedbacks',
    to: `${ROUTERS.FEEDBACK}`,
    key: ModuleKeys.FEEDBACKS,
  },
  {
    icon: <ChatOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'feedbackGuests',
    to: `${ROUTERS.FEEDBACK_GUEST}`,
    key: ModuleKeys.FEEDBACK_GUESTS,
  },
  {
    icon: <DnsOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'configSystem',
    to: `${ROUTERS.CONFIG_SYSTEM}`,
    key: ModuleKeys.CONFIG_SYSTEM,
  },
  {
    icon: <DocumentScannerOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'fileTemplate',
    to: `${ROUTERS.FILE_TEMPLATES}`,
    key: ModuleKeys.FILE_TEMPLATE,
  },
  {
    icon: <ScreenshotMonitorIcon sx={{ color: 'inherit' }} />,
    title: 'workProgress',
    to: `${ROUTERS.WORK_PROGRESS}`,
    key: ModuleKeys.WORK_PROGRESS,
  },
  {
    icon: <BuildOutlinedIcon sx={{ color: 'inherit' }} />,
    title: 'tools',
    to: `${ROUTERS.TOOLS}`,
    key: ModuleKeys.TOOL,
  },
  {
    icon: <NewspaperIcon sx={{ color: 'inherit' }} />,
    title: 'news',
    to: `${ROUTERS.NEWS_MANAGER}`,
    key: ModuleKeys.NEWS,
  },
  {
    icon: <ListAltIcon sx={{ color: 'inherit' }} />,
    title: 'dailyTask',
    to: `${ROUTERS.DAILY_TASK}`,
    key: ModuleKeys.DAILY_TASK,
  },
  {
    icon: <NewspaperIcon sx={{ color: 'inherit' }} />,
    title: 'Portfolio',
    to: `${ROUTERS.ADMIN_PORTFOLIO}`,
    key: ModuleKeys.PORTFOLIO,
  },
];

const MENU_OPTIONS = {
  BASED_ON_ROLE: {
    [ENUMS.Role.ADMIN]: [
      {
        icon: <DashboardOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'dashboard',
        items: [],
        to: `${ROUTERS.DASHBOARD}`,
      },
      {
        icon: <PersonOutlineOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'users',
        items: [],
        to: `${ROUTERS.USERS}`,
      },
      {
        icon: <GroupsIcon sx={{ color: 'inherit' }} />,
        title: 'attendances',
        items: [],
        to: `${ROUTERS.ATTENDANCE}`,
      },
      {
        icon: <BallotOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'projects',
        items: [],
        to: `${ROUTERS.PROJECT_LIST}`,
      },
      {
        icon: <EventNoteOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'projectReports',
        items: [],
        to: `${ROUTERS.PROJECT_REPORT}`,
      },
      {
        icon: <AccountTreeOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'roles',
        items: [],
        to: `${ROUTERS.ROLE_LIST}`,
      },
      {
        icon: <CloudSyncOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'files',
        items: [],
        to: `${ROUTERS.FILE}`,
      },
      {
        icon: <TextSnippetOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'wiki',
        items: [],
        to: `${ROUTERS.WIKI}`,
      },
      {
        icon: <HistoryOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'logs',
        items: [],
        to: `${ROUTERS.LOGS}`,
      },
      {
        icon: <CalendarMonthOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'events',
        items: [],
        to: `${ROUTERS.EVENT}`,
      },
      {
        icon: <AnnouncementOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'announcement',
        items: [],
        to: `${ROUTERS.ANNOUNCEMENT}`,
      },
      {
        icon: <ScreenshotMonitorIcon sx={{ color: 'inherit' }} />,
        title: 'crawlingSystem',
        items: [],
        to: `${ROUTERS.CRAWLING_SYSTEM}`,
      },
      {
        icon: <ScreenshotMonitorIcon sx={{ color: 'inherit' }} />,
        title: 'workProgress',
        items: [],
        to: `${ROUTERS.WORK_PROGRESS}`,
      },
      {
        icon: <NewspaperIcon sx={{ color: 'inherit' }} />,
        title: 'news',
        to: `${ROUTERS.NEWS_MANAGER}`,
        key: ModuleKeys.NEWS,
      },
    ],
    [ENUMS.Role.LEADER]: [
      {
        icon: <DashboardOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'dashboard',
        items: [],
        to: `${ROUTERS.DASHBOARD}`,
      },
      {
        icon: <GroupsIcon sx={{ color: 'inherit' }} />,
        title: 'attendances',
        items: [],
        to: `${ROUTERS.ATTENDANCE}`,
      },
      {
        icon: <BallotOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'projects',
        items: [],
        to: `${ROUTERS.PROJECT_LIST}`,
      },
      {
        icon: <EventNoteOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'projectReports',
        items: [],
        to: `${ROUTERS.PROJECT_REPORT}`,
      },
      {
        icon: <TextSnippetOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'wiki',
        items: [],
        to: `${ROUTERS.WIKI}`,
      },
      {
        icon: <CloudSyncOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'files',
        items: [],
        to: `${ROUTERS.FILE}`,
      },
      {
        icon: <CalendarMonthOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'events',
        items: [],
        to: `${ROUTERS.EVENT}`,
      },
      {
        icon: <ScreenshotMonitorIcon sx={{ color: 'inherit' }} />,
        title: 'crawlingSystem',
        items: [],
        to: `${ROUTERS.CRAWLING_SYSTEM}`,
      },
    ],
    [ENUMS.Role.EMPLOYEE]: [
      {
        icon: <DashboardOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'dashboard',
        items: [],
        to: `${ROUTERS.DASHBOARD}`,
      },
      {
        icon: <GroupsIcon sx={{ color: 'inherit' }} />,
        title: 'attendances',
        items: [],
        to: `${ROUTERS.ATTENDANCE}`,
      },
      {
        icon: <TextSnippetOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'wiki',
        items: [],
        to: `${ROUTERS.WIKI}`,
      },
      {
        icon: <CloudSyncOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'files',
        items: [],
        to: `${ROUTERS.FILE}`,
      },
      {
        icon: <CalendarMonthOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'events',
        items: [],
        to: `${ROUTERS.EVENT}`,
      },
    ],
    [ENUMS.Role.HUMAN_RESOURCES]: [
      {
        icon: <DashboardOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'dashboard',
        items: [],
        to: `${ROUTERS.DASHBOARD}`,
      },
      {
        icon: <GroupsIcon sx={{ color: 'inherit' }} />,
        title: 'attendances',
        items: [],
        to: `${ROUTERS.ATTENDANCE}`,
      },
      {
        icon: <PersonOutlineOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'users',
        items: [],
        to: `${ROUTERS.USERS}`,
      },
      {
        icon: <TextSnippetOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'wiki',
        items: [],
        to: `${ROUTERS.WIKI}`,
      },
      {
        icon: <CloudSyncOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'files',
        items: [],
        to: `${ROUTERS.FILE}`,
      },
      {
        icon: <CalendarMonthOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'events',
        items: [],
        to: `${ROUTERS.EVENT}`,
      },
    ],
    [ENUMS.Role.OFFICE_MANAGER]: [
      {
        icon: <DashboardOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'dashboard',
        items: [],
        to: `${ROUTERS.DASHBOARD}`,
      },
      {
        icon: <GroupsIcon sx={{ color: 'inherit' }} />,
        title: 'attendances',
        items: [],
        to: `${ROUTERS.ATTENDANCE}`,
      },
      {
        icon: <PersonOutlineOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'users',
        items: [],
        to: `${ROUTERS.USERS}`,
      },
      {
        icon: <TextSnippetOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'wiki',
        items: [],
        to: `${ROUTERS.WIKI}`,
      },
      {
        icon: <CloudSyncOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'files',
        items: [],
        to: `${ROUTERS.FILE}`,
      },
      {
        icon: <CalendarMonthOutlinedIcon sx={{ color: 'inherit' }} />,
        title: 'events',
        items: [],
        to: `${ROUTERS.EVENT}`,
      },
    ],
  },
  GENERAL: [
    {
      icon: <CloudSyncOutlinedIcon sx={{ color: 'inherit' }} />,
      title: 'files',
      items: [],
      to: `${ROUTERS.FILE}`,
    },
    {
      icon: <ScreenshotMonitorIcon sx={{ color: 'inherit' }} />,
      title: 'crawlingSystem',
      items: [],
      to: `${ROUTERS.CRAWLING_SYSTEM}`,
    },
  ],
};

export { DEFAULT_MENU };

export default MENU_OPTIONS;
