import * as React from 'react';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Stack, Box } from '@mui/material';
import { useTranslation } from 'react-multi-lang';
import { LoadingButton } from '@mui/lab';

import { useSelector } from 'react-redux';
import { RootState, useTypedDispatch } from '@/store';
import { ColorDropdown, TextField } from '@/Components/Common';

import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import { Alert } from '@/Widgets';

import DatePick from '../../Common/DatePicker';
import { EventColors } from '@/Constants/Enums';
import PopupLayout from '../PopupLayout';
import { EventActions } from '@/Actions';
import Utils from '@/Utils';

type dataKeys = 'nameEN' | 'nameVI' | 'startDate' | 'endDate' | 'id';

interface IPayload {
  id?: string;
  nameEN: string;
  nameVI: string;
  startDate: string;
  endDate: string;
  color: EventColors;
}

interface IProps {
  open: boolean;
  onClose(isFetch?: boolean): void;
  payload: IPayload | null;
}

const colorOptions = [
  {
    label: EventColors.TOMATO_RED,
    value: EventColors.TOMATO_RED,
  },
  {
    label: EventColors.PINK,
    value: EventColors.PINK,
  },
  {
    label: EventColors.ORANGE,
    value: EventColors.ORANGE,
  },
  {
    label: EventColors.BANANA,
    value: EventColors.BANANA,
  },
  {
    label: EventColors.LIGHT_GREEN,
    value: EventColors.LIGHT_GREEN,
  },
  {
    label: EventColors.BASIL,
    value: EventColors.BASIL,
  },
  {
    label: EventColors.BLUE,
    value: EventColors.BLUE,
  },
  {
    label: EventColors.BLUEBERRY,
    value: EventColors.BLUEBERRY,
  },
  {
    label: EventColors.LAVENDER,
    value: EventColors.LAVENDER,
  },
  {
    label: EventColors.GRAPE,
    value: EventColors.GRAPE,
  },
  {
    label: EventColors.SMOKE,
    value: EventColors.SMOKE,
  },
];

const { createEvent, updateEvent, clearEventStatus } = EventActions;

const EventDetails: React.FC<IProps> = ({ open, onClose, payload }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const isSubmitSuccess = useSelector((state: RootState) =>
    get(state.EVENT, 'submitFormIsSuccess')
  );
  const isLoading = useSelector((state: RootState) =>
    get(state.EVENT, 'requestIsLoading')
  );

  const schema = yup.object().shape(
    {
      nameEN: yup.string().trim().required(t('message.nameENRequired')),
      nameVI: yup.string().trim().required(t('message.nameVIRequired')),
      startDate: yup.string().when(['endDate'], ([endDate]) => {
        const isValid = dayjs(endDate).isValid();
        if (endDate && isValid) {
          return yup
            .date()
            .max(endDate, t('message.startDateMustLessThanEndDate'))
            .typeError(t('message.startDateInvalidFormat'))
            .required(t('message.startDateRequired'));
        }
        return yup
          .date()
          .typeError(t('message.startDateInvalidFormat'))
          .required(t('message.startDateRequired'));
      }),
      endDate: yup.string().when('startDate', ([startDate]) => {
        const isValid = dayjs(startDate).isValid();
        if (startDate && isValid)
          return yup
            .date()
            .min(startDate, t('message.endDateMustBeGreaterThanStartDate'))
            .typeError(t('message.endDateInvalidFormat'))
            .required(t('message.endDateRequired'));
        return yup
          .date()
          .typeError(t('message.endDateInvalidFormat'))
          .required(t('message.endDateRequired'));
      }),
      color: yup.string().required(t('message.eventColorRequired')),
    },
    [
      ['startDate', 'endDate'],
      ['endDate', 'startDate'],
    ]
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      nameEN: '',
      nameVI: '',
      startDate: '',
      endDate: '',
      color: EventColors.BLUE,
    },
  });

  React.useEffect(() => {
    if (open) {
      const isAcceptApi = Utils.isValidPermission(
        ModuleApiConstant.EVENT.CREATE_EVENT
      );
      if (!isAcceptApi) {
        onClose();
        Alert({
          type: 'ERROR',
          message: t('popup.notAuthorizeForAddEvent'),
        });
      } else if (payload) {
        for (const key in payload) {
          const resolveKey: dataKeys = key as dataKeys;
          const value = payload[resolveKey];
          if (value && resolveKey !== 'id') setValue(resolveKey, value);
        }
      }
    }
  }, [open]);

  React.useEffect(() => {
    if (isSubmitSuccess) {
      dispatch(clearEventStatus());
      reset({ nameEN: '', nameVI: '' });
      onClose(true);
    }
  }, [isSubmitSuccess]);

  const onSubmit = (data: {
    nameEN: string;
    nameVI: string;
    startDate: string;
    endDate: string;
    color: EventColors;
  }) => {
    if (payload?.id) dispatch(updateEvent({ id: payload.id, ...data }));
    else dispatch(createEvent(data));
  };

  const onCancel = () => {
    dispatch(clearEventStatus());
    reset({ nameEN: '', nameVI: '' });
    onClose();
  };

  const _renderForm = () => (
    <Box component="form" sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          name="nameEN"
          control={control}
          render={({ field }) => (
            <TextField
              label={t('label.nameEN')}
              placeholder="Lunar New Year"
              value={field.value}
              message={errors.nameEN?.message}
              onChange={(e: any) => field.onChange(e)}
              required
            />
          )}
        />
        <Controller
          name="nameVI"
          control={control}
          render={({ field }) => (
            <TextField
              label={t('label.nameVI')}
              placeholder="Tết Nguyên Đán"
              value={field.value}
              message={errors.nameVI?.message}
              onChange={(e: any) => field.onChange(e)}
              required
            />
          )}
        />
        <Stack direction="row" mb={1}>
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <DatePick
                label={t('label.startDate')}
                placeholder="dd-mm-yyyy"
                value={field.value}
                message={errors.startDate?.message}
                onChange={(e: any) => field.onChange(e)}
                required
                sx={{ mr: 2 }}
              />
            )}
          />
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <DatePick
                label={t('label.endDate')}
                placeholder="dd-mm-yyyy"
                value={field.value}
                message={errors.endDate?.message}
                onChange={(e: any) => field.onChange(e)}
                required
              />
            )}
          />
        </Stack>
        <Controller
          name="color"
          control={control}
          render={({ field }) => (
            <ColorDropdown
              label={t('label.color')}
              value={field.value}
              message={errors.color?.message}
              onChange={(e: any) => field.onChange(e)}
              required
              options={colorOptions}
            />
          )}
        />
      </Box>
    </Box>
  );

  const _renderMain = () => {
    return (
      <>
        {_renderForm()}
        <Stack sx={{ float: 'right', marginTop: 5 }} direction="row">
          <LoadingButton variant="outlined" sx={{ mr: 1 }} onClick={onCancel}>
            {t('button.cancel')}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {payload?.id ? t('button.save') : t('button.create')}
          </LoadingButton>
        </Stack>
      </>
    );
  };

  return (
    <PopupLayout
      title={payload?.id ? t('title.editEvent') : t('title.addEvent')}
      content={_renderMain()}
      open={open}
      onClose={onClose}
    />
  );
};

export default EventDetails;
