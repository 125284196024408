import React from 'react';
import { Stack, Box, Typography, Button, Divider } from '@mui/material';
import { get, forEach, trim } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { validate as uuidValidate } from 'uuid';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router';
import { useTranslation } from 'react-multi-lang';

import {
  DatePicker,
  UploadImageInput,
  Textarea,
  TextField,
  RoundedContainer,
  Select,
} from '@/Components/Common';

import Constants from '@/Constants';
import Utils from '@/Utils';
import { Alert } from '@/Widgets';
import { UserActions, PermissionActions } from '@/Actions';

import { useTypedDispatch, RootState } from '@/store';

import { IRoleDetailsStructure } from '@/Interfaces/Role.interface';

import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';

import { EmployeeStatus } from '@/Constants/Enums';
import { IUserDetailsStructure } from '@/Interfaces/User.interface';
import BANK from '@/Constants/Lists/Bank.list';
import { UserDetailsSkeleton } from '../../../Components/Common/Skeleton';

const { ENUMS, ROUTERS } = Constants;
const { fetchRoleOptions, setDefaultReducerPermission } = PermissionActions;
const {
  clearStatusUserDetails,
  getUserById,
  updateUser,
  updateAvatarUser,
  resetUserReducer,
} = UserActions;

const { Gender } = ENUMS;

type dataTypes =
  | 'fullName'
  | 'staffCode'
  | 'role'
  | 'birthday'
  | 'gender'
  | 'permanentAddress'
  | 'currentAddress'
  | 'identifyCardNumber'
  | 'issuedOn'
  | 'placeOfIssue'
  | 'bankName'
  | 'bankBranch'
  | 'bankAccountNumber'
  | 'socialInsuranceNumber'
  | 'personalIncomeTaxCode'
  | 'onBoardDate'
  | 'email'
  | 'phoneNumber'
  | 'position'
  | 'workStatus';

const dataKeys = [
  'fullName',
  'staffCode',
  'role',
  'birthday',
  'gender',
  'permanentAddress',
  'currentAddress',
  'identifyCardNumber',
  'issuedOn',
  'placeOfIssue',
  'bankName',
  'bankBranch',
  'bankAccountNumber',
  'socialInsuranceNumber',
  'personalIncomeTaxCode',
  'onBoardDate',
  'email',
  'phoneNumber',
  'position',
  'workStatus',
];

interface IOption {
  label: string;
  value: string;
}

const gender = [
  { label: 'male', value: Gender.MALE },
  { label: 'female', value: Gender.FEMALE },
  { label: 'other', value: Gender.OTHER },
];

const workStatus = [
  { label: 'intern', value: EmployeeStatus.INTERN },
  { label: 'apprenticeship', value: EmployeeStatus.APPRENTICESHIP },
  { label: 'probationary', value: EmployeeStatus.PROBATIONARY },
  { label: 'officialStaff', value: EmployeeStatus.OFFICIAL_STAFF },
];

const UserDetails: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const { id } = useParams();

  const roles: IRoleDetailsStructure[] = useSelector((state: RootState) =>
    get(state.ROLE, 'options')
  );
  const userIsLoading: boolean = useSelector((state: RootState) =>
    get(state.USER, 'requestIsLoading')
  );
  const details: IUserDetailsStructure = useSelector((state: RootState) =>
    get(state.USER, 'details')
  );

  const [avatar, setAvatar] = React.useState<File[]>([]);
  const [contentIsLoaded, setContentIsLoaded] = React.useState<boolean>(false);

  const schema = yup.object().shape(
    {
      staffCode: yup.string().required(t('message.staffCodeRequired')),
      email: yup
        .string()
        .email(t('message.emailInvalidFormat'))
        .required(t('message.emailRequired')),
      fullName: yup.string().trim().required(t('message.fullNameRequired')),
      birthday: yup
        .date()
        .typeError(t('message.birthdayInvalidFormat'))
        .nullable(),
      gender: yup.string(),
      phoneNumber: yup.string().when('phoneNumber', (phoneNumber) => {
        if (phoneNumber && trim(phoneNumber).length > 0)
          return yup
            .string()
            .matches(
              /^\d{10}$|^\d{11}$/,
              t('message.phoneNumberInvalidFormat')
            );
        return yup.string();
      }),
      permanentAddress: yup.string(),
      currentAddress: yup.string(),
      identifyCardNumber: yup
        .string()
        .when('identifyCardNumber', (identifyCardNumber) => {
          if (identifyCardNumber && trim(identifyCardNumber))
            return yup
              .string()
              .matches(
                /^\d{9}$|^\d{12}$/,
                t('message.identifyNumberCardInvalidFormat')
              )
              .required(t('message.identifyNumberCardRequired'));
          return yup.string().required(t('message.identifyNumberCardRequired'));
        }),
      issuedOn: yup.string().when('issuedOn', (issuedOn) => {
        const isValid =
          issuedOn && typeof issuedOn === 'string' && dayjs(issuedOn).isValid();
        if (isValid)
          return yup
            .date()
            .typeError(t('message.issuedOnInvalidFormat'))
            .required(t('message.issuedOnRequired'));
        return yup.string().required(t('message.issuedOnRequired'));
      }),

      placeOfIssue: yup
        .string()
        .trim()
        .required(t('message.placeOfIssueRequired')),
      bankName: yup.string(),
      bankBranch: yup.string(),
      bankAccountNumber: yup
        .string()
        .when('bankAccountNumber', (bankAccountNumber) => {
          if (bankAccountNumber && trim(bankAccountNumber).length > 0)
            return yup
              .string()
              .matches(
                /^\d{9,14}$/,
                t('message.bankAccountNumberInvalidFormat')
              );
          return yup.string();
        }),
      socialInsuranceNumber: yup
        .string()
        .when('socialInsuranceNumber', (socialInsuranceNumber) => {
          if (socialInsuranceNumber && trim(socialInsuranceNumber).length > 0)
            return yup
              .string()
              .matches(
                /^\d{10}$/,
                t('message.socialInsuranceNumberInvalidFormat')
              );
          return yup.string();
        }),
      personalIncomeTaxCode: yup
        .string()
        .when('personalIncomeTaxCode', (personalIncomeTaxCode) => {
          if (personalIncomeTaxCode && trim(personalIncomeTaxCode).length > 0)
            return yup
              .string()
              .matches(/^\d{10}$/, t('message.personalIncomeTaxCode'));
          return yup.string();
        }),
      onBoardDate: yup
        .date()
        .typeError(t('message.onBoardDateInvalidFormat'))
        .nullable(),
      position: yup.string(),
      workStatus: yup.string(),
    },
    [
      ['phoneNumber', 'phoneNumber'],
      ['identifyCardNumber', 'identifyCardNumber'],
      ['socialInsuranceNumber', 'socialInsuranceNumber'],
      ['bankAccountNumber', 'bankAccountNumber'],
      ['personalIncomeTaxCode', 'personalIncomeTaxCode'],
      ['issuedOn', 'issuedOn'],
    ]
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: '',
      staffCode: '',
      role: '',
      birthday: null,
      gender: '',
      permanentAddress: '',
      currentAddress: '',
      identifyCardNumber: '',
      issuedOn: null,
      placeOfIssue: '',
      bankName: '',
      bankBranch: '',
      bankAccountNumber: '',
      socialInsuranceNumber: '',
      personalIncomeTaxCode: '',
      onBoardDate: null,
      email: '',
      phoneNumber: '',
      position: '',
      workStatus: '',
    },
  });

  React.useEffect(() => {
    const isAcceptApi = Utils.isValidPermission(
      ModuleApiConstant.USER.GET_USER_BY_ID
    );

    if (!isAcceptApi) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForViewUserDetails'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else if (id && uuidValidate(id)) {
      dispatch(getUserById(id));
      dispatch(fetchRoleOptions());
    }
    return () => {
      setAvatar([]);
      dispatch(setDefaultReducerPermission());
      dispatch(resetUserReducer());
    };
  }, []);

  React.useEffect(() => {
    if (details?.id) {
      const joinUserData = {
        ...details,
        ...details?.userData,
        role: details.role.id,
        staffCode: details.staffCode?.code || '',
      };
      for (const key of dataKeys) {
        const resolveKey: dataTypes = key as dataTypes;
        const currentValue = joinUserData[resolveKey];
        if (currentValue) setValue(resolveKey, currentValue);
      }
    }
  }, [details]);

  const bankOptions = React.useMemo(() => {
    const resolveOptions: IOption[] = [];
    forEach(BANK, (item: { name: string; code: string; shortName: string }) =>
      resolveOptions.push({
        label: `${item.code} - ${item.name}`,
        value: item.shortName,
      })
    );
    return resolveOptions;
  }, [BANK]);

  const roleOptions = React.useMemo(() => {
    const resolveOptions: IOption[] = [];
    forEach(roles, (role: IRoleDetailsStructure) =>
      resolveOptions.push({ label: role.name, value: role.id })
    );
    return resolveOptions;
  }, [roles]);

  const handleCancel = () => {
    dispatch(clearStatusUserDetails());
    Utils.redirect(ROUTERS.USERS);
  };

  const genderOptions = React.useMemo(() => {
    const options: IOption[] = [];
    forEach(gender, (item: IOption) =>
      options.push({ label: t(`menu.${item.label}`), value: item.value })
    );
    return options;
  }, []);

  const workStatusOptions = React.useMemo(() => {
    const options: IOption[] = [];
    forEach(workStatus, (item: IOption) =>
      options.push({ label: t(`menu.${item.label}`), value: item.value })
    );
    return options;
  }, []);

  const onSubmit = () => {
    const filledData = getValues();
    const resolveData = {};
    for (const key in filledData) {
      const resolveKey: dataTypes = key as dataTypes;
      const currentValue = filledData[resolveKey];
      if (currentValue) Object.assign(resolveData, { [key]: currentValue });
    }
    if (id && uuidValidate(id)) dispatch(updateUser(id, resolveData));
  };

  const onChangeAvatar = (files: File[]) => {
    if (files.length > 0 && id) {
      const formData = new FormData();
      formData.append('avatar', files[0]);
      dispatch(updateAvatarUser(id, formData));
    }
  };

  const _renderPersonalInformation = () => {
    return (
      <RoundedContainer mb={2}>
        <Typography variant="h3" sx={{ marginBottom: 0 }}>
          {t('title.personalInformation')}
        </Typography>
        <Divider sx={{ marginBottom: '10px' }} />
        <Stack direction="row" mb={1}>
          <Controller
            name="fullName"
            control={control}
            render={({ field }) => (
              <TextField
                label={t('label.fullName')}
                placeholder="John Doe"
                value={field.value}
                message={errors.fullName?.message}
                onChange={(e: any) => field.onChange(e)}
                required
                style={{ mr: 2 }}
                disabled={userIsLoading}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                label={t('label.email')}
                placeholder="johndoe@flow-idra.com"
                value={field.value}
                message={errors.email?.message}
                onChange={(e: any) => field.onChange(e)}
                required
                disabled={userIsLoading}
              />
            )}
          />
        </Stack>
        <Stack direction="row" mb={2}>
          <Controller
            name="birthday"
            control={control}
            render={({ field }) => (
              <DatePicker
                value={field.value}
                onChange={(e: any) => field.onChange(e)}
                label={t('label.birthday')}
                sx={{ mr: 2 }}
                message={errors.birthday?.message}
                disableFuture
                disabled={userIsLoading}
              />
            )}
          />
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <Select
                label={t('label.gender')}
                value={field.value}
                onChange={(e: any) => field.onChange(e)}
                options={genderOptions}
                message={errors.gender?.message}
                disabled={userIsLoading}
              />
            )}
          />
        </Stack>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <TextField
              label={t('label.phoneNumber')}
              placeholder="0xxx xxx xxx"
              value={field.value}
              message={errors.phoneNumber?.message}
              onChange={(e: any) => field.onChange(e)}
              disabled={userIsLoading}
            />
          )}
        />
        <Stack direction="row">
          <Controller
            name="permanentAddress"
            control={control}
            render={({ field }) => (
              <Textarea
                label={t('label.permanentAddress')}
                placeholder="Hue, Vietnam"
                rows={4}
                sx={{ mr: 2 }}
                value={field.value}
                onChange={(e: any) => field.onChange(e.target.value)}
                message={errors.permanentAddress?.message}
                disabled={userIsLoading}
              />
            )}
          />
          <Controller
            name="currentAddress"
            control={control}
            render={({ field }) => (
              <Textarea
                label={t('label.currentAddress')}
                placeholder="Hue, Vietnam"
                rows={4}
                value={field.value}
                onChange={(e: any) => field.onChange(e.target.value)}
                message={errors.currentAddress?.message}
                disabled={userIsLoading}
              />
            )}
          />
        </Stack>
        <Stack direction="row">
          <Controller
            name="identifyCardNumber"
            control={control}
            render={({ field }) => (
              <TextField
                label={t('label.identifyCardNumber')}
                placeholder="xxxx xxxx xxxx"
                value={field.value}
                message={errors.identifyCardNumber?.message}
                onChange={(e: any) => field.onChange(e)}
                required
                style={{ mr: 2 }}
                disabled={userIsLoading}
              />
            )}
          />
          <Controller
            name="issuedOn"
            control={control}
            render={({ field }) => (
              <DatePicker
                value={field.value}
                onChange={(e: any) => field.onChange(e)}
                label={t('label.issuedOn')}
                sx={{ width: '100%', mb: 1 }}
                message={errors.issuedOn?.message}
                minDate={
                  getValues('birthday')
                    ? dayjs(getValues('birthday')).format('YYYY-MM-DD')
                    : null
                }
                disableFuture
                required
                disabled={userIsLoading}
              />
            )}
          />
        </Stack>
        <Controller
          name="placeOfIssue"
          control={control}
          render={({ field }) => (
            <TextField
              label={t('label.placeOfIssue')}
              placeholder={t('placeholder.placeOfIssue')}
              value={field.value}
              message={errors.placeOfIssue?.message}
              onChange={(e: any) => field.onChange(e)}
              required
              disabled={userIsLoading}
            />
          )}
        />
      </RoundedContainer>
    );
  };

  const _renderWorkInformation = () => {
    return (
      <RoundedContainer mb={2}>
        <Typography variant="h3" sx={{ marginBottom: 0 }}>
          {t('title.workInformation')}
        </Typography>
        <Divider sx={{ marginBottom: '10px' }} />
        <Controller
          name="staffCode"
          control={control}
          render={({ field }) => (
            <TextField
              label={t('label.staffCode')}
              placeholder="SCxxxxxx"
              value={field.value}
              disabled
            />
          )}
        />
        <Stack direction="row">
          <Controller
            name="position"
            control={control}
            render={({ field }) => (
              <TextField
                label={t('label.position')}
                value={field.value}
                onChange={(e: any) => field.onChange(e)}
                style={{ mr: 2 }}
                message={errors.position?.message}
                disabled={userIsLoading}
              />
            )}
          />
          <Controller
            name="onBoardDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                value={field.value}
                onChange={(e: any) => field.onChange(e)}
                label={t('label.onBoardDate')}
                sx={{ width: 'unset', flex: 1, mb: 1 }}
                message={errors.onBoardDate?.message}
                minDate={
                  getValues('birthday')
                    ? dayjs(getValues('birthday')).format('YYYY-MM-DD')
                    : null
                }
                disableFuture
                disabled={userIsLoading}
              />
            )}
          />
        </Stack>
        <Stack direction="row" mb={1}>
          <Controller
            name="workStatus"
            control={control}
            render={({ field }) => (
              <Select
                label={t('label.workStatus')}
                options={workStatusOptions}
                sx={{ mr: 2 }}
                value={field.value}
                onChange={(e: any) => field.onChange(e)}
                message={errors.workStatus?.message}
                disabled={userIsLoading}
              />
            )}
          />
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Select
                label={t('label.role')}
                value={field.value}
                options={roleOptions}
                onChange={(e: any) => field.onChange(e)}
                message={errors.role?.message}
                disabled={userIsLoading}
              />
            )}
          />
        </Stack>
      </RoundedContainer>
    );
  };

  const _renderBankInformation = () => {
    return (
      <RoundedContainer mb={2}>
        <Typography variant="h3" sx={{ marginBottom: 0 }}>
          {t('title.bankInformation')}
        </Typography>
        <Divider sx={{ marginBottom: '10px' }} />
        <Controller
          name="bankName"
          control={control}
          render={({ field }) => (
            <Select
              label={t('label.bankName')}
              value={field.value}
              onChange={(e: any) => field.onChange(e)}
              sx={{ mb: 1, width: '100%' }}
              options={bankOptions}
              message={errors.bankName?.message}
              disabled={userIsLoading}
            />
          )}
        />
        <Stack direction="row">
          <Controller
            name="bankBranch"
            control={control}
            render={({ field }) => (
              <TextField
                label={t('label.bankBranch')}
                placeholder="TP Hue"
                value={field.value}
                message={errors.bankBranch?.message}
                onChange={(e: any) => field.onChange(e)}
                style={{ mr: 2 }}
                disabled={userIsLoading}
              />
            )}
          />
          <Controller
            name="bankAccountNumber"
            control={control}
            render={({ field }) => (
              <TextField
                label={t('label.bankAccountNumber')}
                placeholder="XXX XXX XXX | XXX XXXX XXX XXXX"
                value={field.value}
                message={errors.bankAccountNumber?.message}
                onChange={(e: any) => field.onChange(e)}
                disabled={userIsLoading}
              />
            )}
          />
        </Stack>
      </RoundedContainer>
    );
  };

  const _renderInsuranceInformation = () => {
    return (
      <RoundedContainer>
        <Typography variant="h3" sx={{ marginBottom: 0 }}>
          {t('title.insuranceInformation')}
        </Typography>
        <Divider sx={{ marginBottom: '10px' }} />
        <Stack direction="row">
          <Controller
            name="personalIncomeTaxCode"
            control={control}
            render={({ field }) => (
              <TextField
                label={t('label.personalIncomeTaxCode')}
                placeholder="XXXX XXX XXX"
                value={field.value}
                message={errors.personalIncomeTaxCode?.message}
                onChange={(e: any) => field.onChange(e)}
                style={{ mr: 2 }}
                disabled={userIsLoading}
              />
            )}
          />
          <Controller
            name="socialInsuranceNumber"
            control={control}
            render={({ field }) => (
              <TextField
                label={t('label.socialInsuranceNumber')}
                placeholder="XXXX XXX XXX"
                value={field.value}
                message={errors.socialInsuranceNumber?.message}
                onChange={(e: any) => field.onChange(e)}
                disabled={userIsLoading}
              />
            )}
          />
        </Stack>
      </RoundedContainer>
    );
  };

  const _renderContent = () => {
    return (
      <Box onLoad={() => setContentIsLoaded(true)}>
        <Typography variant="h3">{t('title.userInformation')}</Typography>
        <Box component="form">
          <UploadImageInput
            direction="row"
            labelSx={{ minWidth: '250px' }}
            containerSx={{ mb: 2 }}
            files={avatar}
            onFileChange={onChangeAvatar}
            preview={details.userData?.avatar?.path}
          />
          {_renderPersonalInformation()}
          {_renderWorkInformation()}
          {_renderBankInformation()}
          {_renderInsuranceInformation()}
        </Box>
        <Box
          component="div"
          sx={{ display: 'flex', mt: 3, justifyContent: 'flex-end' }}
        >
          <Button
            size="small"
            sx={{ mr: 1 }}
            onClick={() => handleCancel()}
            variant="outlined"
          >
            {t('button.back')}
          </Button>
          <LoadingButton
            loading={userIsLoading}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            {t('button.save')}
          </LoadingButton>
        </Box>
      </Box>
    );
  };

  const _renderSkeleton = () => <UserDetailsSkeleton />;

  const renderMain = () => {
    return (
      <Stack
        flex={1}
        direction="column"
        sx={{ height: 'max-content', p: 2 }}
        key="user_details"
      >
        {userIsLoading && !contentIsLoaded
          ? _renderSkeleton()
          : _renderContent()}
      </Stack>
    );
  };

  return renderMain();
};

export default UserDetails;
