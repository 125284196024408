// import React from 'react';
import { ForwardRefRenderFunction } from 'react';
import {
  InputLabel,
  Box,
  TextField,
  Typography,
  Stack,
  StandardTextFieldProps,
  FormHelperText,
} from '@mui/material';

interface IRoundInputProps extends StandardTextFieldProps {
  value: string;
  placeholder?: string;
  label: string;
  onChange?(e: any): void;
  required?: boolean;
  disabled?: boolean;
  message?: string;
  rows?: number;
  allowCopy?: boolean;
  viewMore?: boolean;
  tooltipViewMore?: string;
  maxLength?: number;
  sx?: object;
}

const Textarea: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  IRoundInputProps
> = ({
  value = '',
  placeholder,
  label,
  onChange,
  required = false,
  disabled = false,
  rows = 10,
  message = '',
  maxLength = 300,
  sx = {},
}) => {
  return (
    <Stack direction="column" sx={{ mb: 1, flex: 1, ...sx }}>
      <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
        {label}{' '}
        {required ? (
          <Typography component="span" color="error">
            (*)
          </Typography>
        ) : null}
      </InputLabel>
      <Box component="div" sx={{ flex: 1 }}>
        <TextField
          className={message ? 'required' : ''}
          multiline
          error={Boolean(message)}
          rows={rows}
          placeholder={placeholder}
          onChange={(e: any) => onChange && onChange(e)}
          value={value}
          disabled={disabled}
          fullWidth
          sx={{
            flex: 1,
            '.MuiInputBase-root': {
              padding: '10px 14px',
            },
            textarea: {
              height: '38px',
              boxSizing: 'border-box',
              fontSize: '15px',
            },
          }}
          inputProps={{ maxLength }}
        />
      </Box>

      {message ? (
        <FormHelperText error={Boolean(message)}>{message}</FormHelperText>
      ) : null}
    </Stack>
  );
};

export default Textarea;
