import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
import { ICreateProject, IUpdateProject } from '@/Interfaces/Project.interface';

const { PROJECT } = CONSTANTS.API;

export const fetchProjects = async (payload: any) => {
  return sendRequest(PROJECT.BASIC, 'GET', payload);
};

export const fetchProjectsNotConfig = async (projectId?: string) => {
  return sendRequest(`${PROJECT.NOT_CONFIG}`, 'GET', {
    projectId,
  });
};

export const getProjectById = async (id: string) => {
  return sendRequest(`${PROJECT.BASIC}/${id}`, `GET`);
};

export const createProject = async (payload: ICreateProject) => {
  return sendRequest(PROJECT.BASIC, 'POST', payload);
};

export const updateProject = async (payload: IUpdateProject) => {
  const { id } = payload;
  return sendRequest(`${PROJECT.BASIC}/${id}`, 'PUT', payload);
};

export const updateProjectDocumentation = async (payload: IUpdateProject) => {
  const { id } = payload;
  return sendRequest(`${PROJECT.DOCUMENTATION}/${id}`, 'PUT', payload);
};

export const DeleteProject = async (id: string) => {
  return sendRequest(`${PROJECT.BASIC}/${id}`, `DEL`);
};
