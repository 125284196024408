import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const initialState = {
  isLogged: false,
  requestHasError: false,
  requestIsSuccess: false,
  requestIsLoading: false,
  keepLoggin: false,
  role: '',
  authMessage: '',
};

export default (
  state = initialState,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.CLEAR_AUTH_MESSAGE:
      return {
        ...state,
        authMessage: '',
      };
    case ACTION_TYPES.AUTH_IS_REQUEST:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: true,
      };

    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        isLogged: true,
        role: payload,
      };
    case ACTION_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        isLogged: false,
        authMessage: payload,
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...state,
        isLogged: false,
        requestHasError: false,
        requestIsSuccess: false,
        requestIsLoading: false,
      };
    case ACTION_TYPES.SEND_RECOVERY_LINK_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        authMessage: payload,
      };
    case ACTION_TYPES.SEND_RECOVERY_LINK_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        authMessage: payload,
      };

    case ACTION_TYPES.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        authMessage: payload,
      };
    case ACTION_TYPES.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        authMessage: payload,
      };
    default:
      return state;
  }
};
