import * as React from 'react';
import { get, forEach } from 'lodash';
import { validate as uuidValidate } from 'uuid';
import { Stack, Box, Avatar } from '@mui/material';
import { useTranslation } from 'react-multi-lang';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { ProjectMemberActions } from '@/Actions';
import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import { DatePicker, Select, Textarea, TextField } from '@/Components/Common';

import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import { Alert } from '@/Widgets';

import { IProjectMember } from '@/Interfaces/ProjectMember.interface';
import { IUserDetailsStructure } from '@/Interfaces/User.interface';
import PopupLayout from '../PopupLayout';

interface SectionProps {
  onClose(): void;
  payload: IProjectMember | null;
  currentUser?: IUserDetailsStructure | null;
}

interface IOption {
  label: string;
  value: string;
  startEl: React.JSX.Element;
}

const {
  createProjectMember,
  updateProjectMember,
  fetchUnassignedProjectMembers,
} = ProjectMemberActions;

const MemberDetailsPopup: React.FC<SectionProps> = ({
  payload,
  onClose,
  currentUser,
}) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const open = Boolean(payload);

  const popupSubmitSuccess = useSelector((state: RootState) =>
    get(state.PROJECT_MEMBER, 'popupSubmitSuccess')
  );
  const isLoading = useSelector((state: RootState) =>
    get(state.PROJECT_MEMBER, 'requestIsLoading')
  );
  const members = useSelector((state: RootState) =>
    get(state.PROJECT_MEMBER, 'unassignedMembers')
  );

  const schema = yup
    .object({
      user: yup
        .string()
        .uuid(t('message.userRequired'))
        .required(t('message.userRequired')),
      project: yup.string().uuid(t('message.projectRequired')).required(),
      dateJoined: yup
        .date()
        .typeError(t('message.joinedDateInvalidFormat'))
        .required(t('message.joinedDateRequired'))
        .max(dayjs(), t('message.joinedDateNotGreaterNow')),
      position: yup.string().trim().required(t('message.positionRequired')),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      user: '',
      project: '',
      dateJoined: '',
      position: '',
    },
  });
  const handleClose = () => {
    reset();
    onClose();
  };

  React.useEffect(() => {
    if (open) {
      let isAcceptApi = true;
      if (payload?.id)
        isAcceptApi = Utils.isValidPermission(
          ModuleApiConstant.PROJECT_MEMBER.UPDATE
        );
      else
        isAcceptApi = Utils.isValidPermission(
          ModuleApiConstant.PROJECT_MEMBER.CREATE
        );

      if (!isAcceptApi) {
        handleClose();
        Alert({
          type: 'ERROR',
          message: t('message.notAuthorizeToDoThisAction'),
        });
      } else if (payload && payload.project && uuidValidate(payload.project)) {
        setValue('project', payload.project);
        if (payload?.id) {
          setValue('user', payload.user);
          setValue('dateJoined', payload.dateJoined);
          setValue('position', payload.position);
        }
        dispatch(fetchUnassignedProjectMembers(payload.project));
      }
    }
  }, [open]);

  React.useEffect(() => {
    if (popupSubmitSuccess) handleClose();
  }, [popupSubmitSuccess]);

  const membersOptions = React.useMemo(() => {
    const resolveOptions: IOption[] = [];
    forEach(members, (user: IUserDetailsStructure) =>
      resolveOptions.push({
        label: user?.userData.fullName,
        value: user.id,
        startEl: user.userData?.avatar?.path ? (
          <Avatar src={user.userData?.avatar?.path} />
        ) : (
          <Avatar sx={{ backgroundColor: '#42526e' }}>
            {user?.userData?.fullName[0]}
          </Avatar>
        ),
      })
    );
    return resolveOptions;
  }, [members]);

  const onSubmit = (data: {
    user: string;
    project: string;
    dateJoined: string;
    position: string;
  }) => {
    const resolvePayload = {
      ...data,
    };
    if (payload?.id) {
      Object.assign(resolvePayload, { id: payload.id });
      dispatch(updateProjectMember(resolvePayload));
    } else dispatch(createProjectMember(data));
  };

  const _renderForm = () => (
    <Box component="form" sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {currentUser ? (
          <TextField
            label={t('label.user')}
            placeholder="Le Viet Dat"
            value={currentUser.userData.fullName}
            message={errors.user?.message}
            required
            sx={{ mb: 1 }}
            disabled
          />
        ) : (
          <Controller
            name="user"
            control={control}
            render={({ field }) => (
              <Select
                options={membersOptions}
                label={t('label.user')}
                placeholder="Le Viet Dat"
                value={field.value}
                message={errors.user?.message}
                onChange={(e: any) => field.onChange(e)}
                required
                sx={{ mb: 1 }}
              />
            )}
          />
        )}
        <Controller
          name="dateJoined"
          control={control}
          render={({ field }) => (
            <DatePicker
              label={t('label.dateJoined')}
              placeholder="dd/mm/yyyy"
              value={field.value}
              message={errors.dateJoined?.message}
              onChange={(e: any) => field.onChange(e)}
              sx={{ mb: 1 }}
              required
              disableFuture
            />
          )}
        />
        <Controller
          name="position"
          control={control}
          render={({ field }) => (
            <Textarea
              label={t('label.position')}
              placeholder="Position"
              value={field.value}
              message={errors.position?.message}
              onChange={(e: any) => field.onChange(e)}
              required
              rows={5}
              sx={{ mb: 1 }}
            />
          )}
        />
      </Box>
      <Stack sx={{ justifyContent: 'end' }} direction="row">
        <LoadingButton sx={{ mr: 1 }} onClick={handleClose} variant="outlined">
          {t('button.cancel')}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {t('button.save')}
        </LoadingButton>
      </Stack>
    </Box>
  );

  return (
    <PopupLayout
      title={payload?.id ? t('title.editMember') : t('title.addMember')}
      content={_renderForm()}
      open={open}
      onClose={onClose}
    />
  );
};

export default MemberDetailsPopup;
