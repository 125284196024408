export default [
  'Advertising / Public Relations',
  'Confectionery',
  'Financial Services/Banking',
  'IT / Software / Hardware',
  'Petrol / Oil / Gas',
  'Retail / Wholesale',
  'Tobacco /Cigarettes',
  'Cosmetics / Hygiene / Detergents',
  'Energy / Utilities',
  'Healthcare / Pharmaceutical',
  'Media / Entertainment',
  'Public Sector / Government',
  'Travel / Tourism / Sport / Leisure',
  'GIS',
  'Portfolio',
];
