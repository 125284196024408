/* eslint-disable indent */
import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang';
import { validate as uuidValidate, v4 as uuidv4 } from 'uuid';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Stack,
  Divider,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import {
  Loading,
  RoundedContainer,
  Dropdown,
  TextField,
} from '@/Components/Common';
import { RootState, useTypedDispatch } from '@/store';
import Constants from '@/Constants';
// import { Alert } from '@/Widgets';
import { CrawlingSystemActions } from '@/Actions';
import Utils from '@/Utils';
import {
  ICrawlingSystemStructure,
  IConditionStructure,
} from '@/Interfaces/CrawlingProjects.interface';
import { ProjectTypes } from '@/Constants/Enums';

const {
  getCrawlProjectById,
  updateCrawlProject,
  setDefaultReducerCrawlProject,
} = CrawlingSystemActions;

const { ROUTERS, ENUMS } = Constants;

const projectTypes = [
  { value: ProjectTypes.WEBAPP, label: 'webApp' },
  { value: ProjectTypes.WEBSERVICE, label: 'webService' },
  { value: ProjectTypes.OTHER, label: 'other' },
];
// eslint-disable-next-line react/function-component-definition
const UpdateCrawlingProject: React.FC = () => {
  const t = useTranslation();
  const { id } = useParams();
  const isLoading: boolean = useSelector((state: RootState) =>
    _.get(state.CRAWLING_SYSTEM, 'requestIsLoading')
  );

  const dispatch = useTypedDispatch();

  const details: ICrawlingSystemStructure = useSelector((state: RootState) =>
    _.get(state.CRAWLING_SYSTEM, 'details')
  );

  const schema = yup
    .object({
      name: yup.string().trim().required(t('message.projectNameRequired')),
      type: yup.string().trim(),
      link: yup
        .string()
        .trim()
        .required(t('message.linkRequired'))
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          t('message.linkInvalid')
        ),
      condition: yup.lazy((value) =>
        value.length
          ? yup.array().of(
              yup.object({
                id: yup.string().notRequired(),
                key: yup.string().required(),
                value: yup.string().required(),
                description: yup.string().notRequired(),
              })
            )
          : yup.array().notRequired()
      ),
      description: yup.string().trim().notRequired(),
    })
    .required();

  const defaultValues: ICrawlingSystemStructure = {
    name: '',
    link: '',
    type: ENUMS.ProjectTypes.OTHER as string,
    description: '',
    condition: [],
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'all',
    shouldUnregister: false,
  });

  // get field conditions
  const {
    fields,
    remove: onRemoveCondition,
    append,
  } = useFieldArray({
    name: 'condition',
    control,
  } as never);

  const watchCondition = watch('condition', []);

  useEffect(() => {
    if (id && uuidValidate(id)) dispatch(getCrawlProjectById(id));
    return () => {
      dispatch(setDefaultReducerCrawlProject());
    };
  }, []);

  useEffect(() => {
    if (details?.id) {
      const { name, type, link, description, condition } = details;
      setValue('name', `${name}`);
      setValue('type', `${type}`);
      setValue('link', `${link}`);
      setValue('description', `${description || ''}`);
      const resolveCondition =
        condition &&
        _.map(condition, (item: IConditionStructure) => {
          return { ...item, uuId: uuidv4() };
        });
      setValue('condition', resolveCondition);
    }
  }, [details]);

  const handleAddCondition = useCallback(() => {
    append({ key: '', value: '', description: '', uuId: uuidv4() });
  }, [append]);

  const handleConditionChange = useCallback(
    (index: number, field: 'key' | 'value' | 'description', value: string) => {
      setValue(`condition.${index}.${field}`, value);
    },
    [setValue]
  );

  const onSubmit = (data: ICrawlingSystemStructure) => {
    const resolveCondition =
      data.condition &&
      _.map(data.condition, (condition: IConditionStructure) =>
        _.omit(condition, ['uuId'])
      );
    // table 1: new conditions
    const hashTable1 = resolveCondition.reduce(
      (
        acc: { [key: string]: IConditionStructure },
        element: IConditionStructure
      ) => {
        if (element?.id !== undefined) {
          acc[element.id] = element;
        }
        return acc;
      },
      {}
    );
    // table 2: current conditions
    const hashTable2 = resolveCondition.reduce(
      (
        acc: { [key: string]: IConditionStructure },
        element: IConditionStructure
      ) => {
        if (element?.id !== undefined) {
          acc[element.id] = element;
        }
        return acc;
      },
      {}
    );

    const deletedElements = resolveCondition
      .filter(
        (element2: IConditionStructure) =>
          element2?.id &&
          element2.id !== 'undefined' &&
          !hashTable1[element2.id]
      )
      .map((element2: IConditionStructure) => element2.id);
    const addedElements = resolveCondition.filter(
      (element1: IConditionStructure) =>
        element1?.id && element1.id !== 'undefined' && !hashTable2[element1.id]
    );
    const modifiedElements = resolveCondition.filter(
      (element1: IConditionStructure) => {
        const element2 =
          element1?.id &&
          element1.id !== 'undefined' &&
          hashTable2[element1?.id];
        return element2 && element2.value !== element1.value;
      }
    );

    const resolveData = _.omit(data, ['condition']);
    dispatch(
      updateCrawlProject({
        ...resolveData,
        newConditions: addedElements,
        updateConditions: modifiedElements,
        removeCondition: deletedElements,
        id,
      })
    );
  };

  const onChangeType = (type: string) => setValue('type', `${type}`);

  const _renderTopSection = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Typography fontSize="20px" fontWeight="500">
        {t('title.projectDetails')}
      </Typography>
    </Stack>
  );

  const _renderBottomSection = () => (
    <RoundedContainer>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              value={field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.value)
              }
              label={t('label.projectName')}
              placeholder="IDRA Website"
              message={errors.name?.message}
              required
            />
          )}
        />
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={t('label.type')}
              value={field.value}
              message={errors.type?.message}
              options={projectTypes}
              onChange={(e: any) => {
                onChangeType(e);
                field.onChange(e);
              }}
              required
              isTranslate
            />
          )}
        />
        <Controller
          name="link"
          control={control}
          render={({ field }) => (
            <TextField
              value={field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.value)
              }
              label={t('label.link')}
              placeholder="https://www.idracompany.com/"
              message={errors.link?.message}
              required
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              value={field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.value)
              }
              label={t('label.description')}
              placeholder={t('placeholder.descriptionProject')}
              rows={4}
              multiline
            />
          )}
        />
        <Box>
          <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="selector-check"
            >
              <Typography fontSize="15px" fontWeight="500">
                {t('label.conditions')}
              </Typography>
            </AccordionSummary>
            <Typography
              variant="subtitle1"
              sx={{ ml: 2, textAlign: 'center', fontSize: '13px' }}
            >
              {`* ${t('label.key')}: ${t('label.subKey')}`}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ ml: 2, textAlign: 'center', fontSize: '13px' }}
            >
              {`* ${t('label.value')}: ${t('label.subValue')}`}
            </Typography>
            <Divider sx={{ mt: 2 }} />
            <AccordionDetails>
              {_.map(
                fields,
                (_condition: IConditionStructure, index: number) => {
                  return (
                    <Box
                      key={_condition.uuId}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        my: 2,
                        p: 2,
                        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
                      }}
                    >
                      <AcUnitIcon sx={{ alignSelf: 'flex-start' }} />
                      <Box
                        sx={{
                          flex: 1,
                          display: 'flex',
                          flexFlow: 'wrap',
                          gap: 2,
                        }}
                      >
                        <Box sx={{ flex: 1 }}>
                          <Controller
                            name={`condition.${index}.key`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                label={t('label.key')}
                                placeholder={t('placeholder.key')}
                                value={field.value}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleConditionChange(
                                    index,
                                    'key',
                                    e.target.value
                                  )
                                }
                                message={
                                  _.isEmpty(watchCondition?.[index]?.key) &&
                                  errors.condition?.[index]?.key &&
                                  'Key is a required field'
                                }
                                required
                              />
                            )}
                          />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Controller
                            name={`condition.${index}.value`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                label={t('label.value')}
                                value={field.value}
                                placeholder={t('placeholder.value')}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleConditionChange(
                                    index,
                                    'value',
                                    e.target.value
                                  )
                                }
                                message={
                                  _.isEmpty(watchCondition?.[index]?.value) &&
                                  errors.condition?.[index]?.value &&
                                  'Value is a required field'
                                }
                                required
                              />
                            )}
                          />
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: '15px',
                            }}
                          >
                            {t('label.description')}
                          </Typography>
                          <Controller
                            name={`condition.${index}.description`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                value={field.value}
                                placeholder={t('placeholder.description')}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleConditionChange(
                                    index,
                                    'description',
                                    e.target.value
                                  )
                                }
                                multiline
                                rows={2}
                                required
                              />
                            )}
                          />
                        </Box>
                        {_.size(fields) - 1 === index && (
                          <Box
                            sx={{
                              width: '100%',
                              textAlign: ' center',
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                width: 'max-content',
                                textAlign: 'center',
                              }}
                              startIcon={<AddIcon />}
                              onClick={handleAddCondition}
                            >
                              {t('button.selector')}
                            </Button>
                          </Box>
                        )}
                      </Box>
                      <IconButton
                        onClick={() => onRemoveCondition(index)}
                        sx={{
                          marginBottom:
                            _.isEmpty(watchCondition?.[index]?.value) ||
                            _.isEmpty(watchCondition?.[index]?.key)
                              ? '50px'
                              : '55px',
                        }}
                      >
                        <RemoveCircleOutlineIcon color="error" />
                      </IconButton>
                    </Box>
                  );
                }
              )}
              {_.isEmpty(fields) && (
                <Box
                  sx={{
                    width: '100%',
                    textAlign: ' center',
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ width: 'max-content', textAlign: 'center' }}
                    startIcon={<AddIcon />}
                    onClick={handleAddCondition}
                  >
                    {t('button.selector')}
                  </Button>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mt={2}
        >
          <Button
            sx={{ mr: 1 }}
            variant="outlined"
            onClick={() => Utils.redirect(ROUTERS.CRAWLING_PROJECT)}
          >
            {t('button.cancel')}
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            {t('button.save')}
          </LoadingButton>
        </Stack>
      </Box>
    </RoundedContainer>
  );

  const _renderContent = () => (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );

  return (
    <>
      {isLoading ? <Loading /> : null}
      {!isLoading ? _renderContent() : null}
    </>
  );
};

export default UpdateCrawlingProject;
