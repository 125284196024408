import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
import { IFilterProjectReports } from '../Interfaces/DailyReport.interface';

const { DAILY_REPORT } = CONSTANTS.API;

export const fetchDailyReports = async () => {
  return sendRequest(DAILY_REPORT.BASIC, 'GET');
};

export const fetchProjectReports = async (payload: IFilterProjectReports) => {
  return sendRequest(DAILY_REPORT.PROJECT_REPORT, 'GET', payload);
};

export const fetchDailyReportByProjectId = async (
  projectId: string,
  payload: any
) => {
  return sendRequest(
    `${DAILY_REPORT.BY_PROJECT_ID}/${projectId}`,
    'GET',
    payload
  );
};

export const getDailyReportById = async (id: string) => {
  return sendRequest(`${DAILY_REPORT.BASIC}/${id}`, `GET`);
};

export const createDailyReport = async (payload: FormData) => {
  return sendRequest(DAILY_REPORT.BASIC, 'POST_FORM_DATA', payload);
};

export const updateDailyReport = async (payload: FormData, id: string) => {
  return sendRequest(`${DAILY_REPORT.BASIC}/${id}`, 'PUT_FORM_DATA', payload);
};

export const deleteDailyReport = async (id: string) => {
  return sendRequest(`${DAILY_REPORT.BASIC}/${id}`, `DEL`);
};

export const getReportDetailsByDateAndProject = async (payload: {
  date: string;
  project: string;
}) => {
  return sendRequest(`${DAILY_REPORT.BY_DATE_AND_PROJECT}`, `GET`, payload);
};
