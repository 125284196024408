import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { Box, Button, Stack, Typography } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { RootState, useTypedDispatch } from '@/store';
import { ToolActions } from '@/Actions';
import Constants from '@/Constants';
import Utils from '@/Utils';

import { RoundedContainer, TextField, UploadFile } from '@/Components/Common';

import { Alert } from '@/Widgets';
import { ToolDetailsSkeleton } from '@/Components/Common/Skeleton';

const { ROUTERS, MODULE_API } = Constants;
const { createTool } = ToolActions;

const CreateTool: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.TOOL, 'requestIsLoading')
  );

  const isAcceptCreateTool = Utils.isValidPermission(
    MODULE_API.TOOL.CREATE_TOOL
  );

  const [preview, setPreview] = React.useState<string>('');
  const [filename, setFilename] = React.useState<string>('');

  const schema = yup
    .object({
      title: yup.string().trim().required(t('message.titleRequired')),
      description: yup
        .string()
        .trim()
        .required(t('message.descriptionRequired')),
      url: yup
        .string()
        .url(t('message.urlInvalidFormat'))
        .required(t('message.urlRequired')),
      thumbnail: yup.mixed().required(t('message.thumbnailRequired')),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      description: '',
      url: '',
      thumbnail: null,
    },
  });

  React.useEffect(() => {
    if (!isAcceptCreateTool) {
      Alert({ type: 'ERROR', message: t('popup.notAuthorizedToAddTool') });
      Utils.redirect(ROUTERS.TOOLS);
    }
  }, []);

  const onSubmit = (data: {
    title: string;
    description: string;
    url: string;
    thumbnail: File | null;
  }) => {
    const { title, description, url, thumbnail } = data;
    const formData = new FormData();
    formData.append('title', title.trim());
    formData.append('description', description.trim());
    formData.append('url', url.trim());
    if (thumbnail) formData.append('thumbnail', thumbnail);

    dispatch(createTool(formData));
  };

  const _renderTopSection = () => (
    <Typography variant="h2">{t('title.addTool')}</Typography>
  );

  const _renderBottomSection = () => (
    <RoundedContainer>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Stack direction="row">
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label={t('label.title')}
                placeholder="Postman"
                message={errors.title?.message}
                required
                style={{ flex: 1, mr: 1 }}
              />
            )}
          />
          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label={t('label.url')}
                placeholder="https://www.postman.com/"
                message={errors.url?.message}
                required
                style={{ flex: 1 }}
              />
            )}
          />
        </Stack>

        <Controller
          name="thumbnail"
          control={control}
          render={({ field }) => (
            <UploadFile
              label={t('label.thumbnail')}
              onChange={(file: File | null) => {
                field.onChange(file);
                setError('thumbnail', { message: '' });
                if (file) {
                  const url = URL.createObjectURL(file);
                  setPreview(url);
                  setFilename(file.name);
                } else {
                  setPreview('');
                  setFilename('');
                }
              }}
              required
              preview={preview}
              isShowPreview
              filename={filename}
              message={errors.thumbnail?.message}
              type="image"
            />
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              value={field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.value)
              }
              label={t('label.description')}
              placeholder="Postman is an API platform for building and using APIs. Postman simplifies each step of the API lifecycle and streamlines collaboration so you can create better APIs—faster."
              rows={6}
              multiline
              message={errors.description?.message}
              style={{ mt: 1 }}
              required
            />
          )}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mt={2}
        >
          <Button
            onClick={() => Utils.redirect(ROUTERS.TOOLS)}
            variant="outlined"
          >
            {t('button.cancel')}
          </Button>
          {isAcceptCreateTool ? (
            <Button type="submit" variant="contained" sx={{ ml: 1 }}>
              {t('button.create')}
            </Button>
          ) : null}
        </Stack>
      </Box>
    </RoundedContainer>
  );

  const _renderContent = () => (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
  if (isLoading)
    return (
      <RoundedContainer>
        <ToolDetailsSkeleton />
      </RoundedContainer>
    );
  return _renderContent();
};

export default CreateTool;
