import * as AppServiceUtils from './AppServices.utils';
import * as ResolveResponse from './ResolveResponse.utils';
import * as CookieUtils from './Cookie.utils';
import * as TableUtils from './TableServices.utils';
import useCopyToClipboard from './useCopyToClipboard';

export default {
  ...AppServiceUtils,
  ...ResolveResponse,
  ...CookieUtils,
  ...TableUtils,
  useCopyToClipboard,
};
