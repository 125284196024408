import React from 'react';
import has from 'lodash/has';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { validate as uuidValidate } from 'uuid';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography, Box } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CONSTANTS from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import { PermissionActions } from '@/Actions';
import { Alert } from '@/Widgets';
import Utils from '@/Utils';
import {
  CheckboxGroup,
  RoundedContainer,
  Textarea,
  TextField,
} from '@/Components/Common';
import {
  IPermissionStructure,
  IRoleDetailsStructure,
} from '@/Interfaces/Role.interface';

import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import { PermissionsDataTable } from '@/Components/LayoutPart/DataTable';
import { RoleDetailsSkeleton } from '@/Components/Common/Skeleton';
import { DEFAULT_MENU } from '@/Components/DefaultLayout/MenuOptions';
import { ModuleKeys } from '@/Constants/Enums';

interface IMenu {
  icon: React.JSX.Element;
  title: string;
  to: string;
  key: ModuleKeys;
}

const { ROUTERS, ENUMS } = CONSTANTS;
const {
  getRoleById,
  updateRole,
  fetchPermissions,
  setDefaultReducerPermission,
} = PermissionActions;

const disableEditable = [
  ENUMS.Role.ADMIN,
  ENUMS.Role.HUMAN_RESOURCES,
  ENUMS.Role.OFFICE_MANAGER,
];

const Details: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const { id } = useParams();

  const schema = yup
    .object({
      name: yup.string().trim().required(t('message.roleNameRequired')),
      roleCode: yup.string().trim().required(t('message.roleCodeRequired')),
      description: yup
        .string()
        .trim()
        .required(t('message.descriptionRequired')),
      acceptApi: yup.array().min(1, t('message.permissionLeastAtOneItem')),
      menu: yup.array().min(1, t('message.menuLeastAtOneItem')),
    })
    .required();

  const roleDetails: IRoleDetailsStructure = useSelector((state: RootState) =>
    get(state.ROLE, 'details')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.ROLE, 'requestIsLoading')
  );
  const permissionsPayload: IPermissionStructure[] = useSelector(
    (state: RootState) => get(state.ROLE, 'permissions')
  );
  const userRoleCode = Utils.getSavedUserData()?.role?.roleCode;

  const [contentIsLoaded, setContentIsLoaded] = React.useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: roleDetails,
  });

  React.useEffect(() => {
    const isAcceptApi = Utils.isValidPermission(
      ModuleApiConstant.ROLE_PERMISSION.UPDATE_ROLE
    );
    if (!isAcceptApi) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForEditRole'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else if (id && uuidValidate(id)) {
      dispatch(fetchPermissions());
      dispatch(getRoleById(id));
    }
    return () => {
      dispatch(setDefaultReducerPermission());
    };
  }, []);

  React.useEffect(() => {
    if (has(roleDetails, 'id')) {
      const { acceptApi, description, name, roleCode, menu } = roleDetails;
      setValue('name', name);
      setValue('description', description);
      setValue('roleCode', roleCode);
      setValue('acceptApi', acceptApi);
      setValue('menu', menu);
    }
  }, [roleDetails]);

  const menuOptions = React.useMemo(() => {
    const result: any[] = [];
    forEach(DEFAULT_MENU, (item: IMenu) =>
      result.push({
        label: (
          <Stack direction="row" alignItems="center">
            {item.icon}{' '}
            <Typography sx={{ fontSize: '14px', fontWeight: 500, ml: 0.5 }}>
              {t(`menu.${item.title}`)}
            </Typography>
          </Stack>
        ),
        value: item.key,
      })
    );
    return result;
  }, []);

  const onSubmit = (data: {
    name: string;
    roleCode: string;
    description: string;
    acceptApi: string[];
  }) => {
    dispatch(updateRole({ ...data, id }));
  };

  const onCancel = () => Utils.redirect(ROUTERS.ROLE_LIST);

  // Render layout
  const _renderBottomSection = () => {
    const isAcceptApi = Utils.isValidPermission(
      ModuleApiConstant.ROLE_PERMISSION.UPDATE_ROLE
    );
    return (
      <RoundedContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Stack>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={(e: any) => field.onChange(e.target.value)}
                  label={t('label.roleName')}
                  placeholder="Human Resources"
                  message={errors.name?.message}
                  required
                  disabled={disableEditable.includes(userRoleCode)}
                />
              )}
            />
            <Controller
              name="roleCode"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={(e: any) => field.onChange(e.target.value)}
                  label={t('label.roleCode')}
                  placeholder="humanResource"
                  message={errors.roleCode?.message}
                  required
                  disabled={disableEditable.includes(userRoleCode)}
                />
              )}
            />
          </Stack>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Textarea
                label={t('label.description')}
                placeholder={t('label.description')}
                sx={{ mb: 1 }}
                value={field.value}
                onChange={(e: any) => field.onChange(e.target.value)}
                required
                message={errors.description?.message}
                disabled={disableEditable.includes(userRoleCode)}
              />
            )}
          />
          <Controller
            name="acceptApi"
            control={control}
            render={({ field }) => (
              <PermissionsDataTable
                label={t('label.permissions')}
                required
                message={errors.acceptApi?.message}
                payload={permissionsPayload}
                acceptApi={field.value}
                callback={(checkedPermission: string[]) =>
                  field.onChange(checkedPermission)
                }
                sx={{ mb: 1 }}
                disabledRows={
                  disableEditable.includes(userRoleCode)
                    ? [ENUMS.PermissionKeys.ROLES]
                    : []
                }
              />
            )}
          />
          <Controller
            name="menu"
            control={control}
            render={({ field }) => (
              <CheckboxGroup
                options={menuOptions}
                selected={field.value}
                onChange={field.onChange}
                label={t('label.menu')}
                sx={{ mb: 1 }}
                message={errors.menu?.message}
                required
                helperText={t('tooltip.ensurePermissionInMenuOfRole')}
                disabledFields={
                  disableEditable.includes(userRoleCode)
                    ? [ENUMS.ModuleKeys.ROLES]
                    : []
                }
              />
            )}
          />
          <Stack direction="row" mt={3} justifyContent="flex-end">
            <LoadingButton variant="outlined" sx={{ mr: 1 }} onClick={onCancel}>
              {t('button.cancel')}
            </LoadingButton>

            {isAcceptApi && (
              <LoadingButton
                variant="contained"
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t('button.save')}
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </RoundedContainer>
    );
  };

  const _renderContent = () => (
    <Box onLoad={() => setContentIsLoaded(true)}>{_renderBottomSection()}</Box>
  );

  const _renderSkeleton = () => <RoleDetailsSkeleton />;

  const renderMain = () => {
    return (
      <Stack
        flex={1}
        direction="column"
        sx={{ height: 'max-content' }}
        key="user_details"
      >
        {isLoading && !contentIsLoaded ? _renderSkeleton() : _renderContent()}
      </Stack>
    );
  };

  return renderMain();
};

export default Details;
