import React from 'react';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import get from 'lodash/get';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import AdjustIcon from '@mui/icons-material/Adjust';
import MessageIcon from '@mui/icons-material/Message';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { validate as uuidValidate } from 'uuid';
import HistoryIcon from '@mui/icons-material/History';
import { useTranslation } from 'react-multi-lang';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { useParams } from 'react-router';
import { RootState, useTypedDispatch } from '@/store';
import { Alert } from '@/Widgets';
import {
  ICreateTheards,
  IDataFeedbacks,
  IThreads,
} from '@/Interfaces/Feedback.interface';
import { FeedbacksActions, UserActions } from '@/Actions';
import { EventSkeleton } from '@/Components/Common/Skeleton';
import { CommonColors } from '@/Themes';
import replyStyles from './Feedbacks.Styles';
import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import Utils from '@/Utils';
import CONSTANTS from '@/Constants';
import {
  AutocompleteInput,
  GroupButton,
  RoundedContainer,
  TextEditor,
  TextField,
} from '@/Components/Common';

const { ROUTERS } = CONSTANTS;
const {
  getFeedbackById,
  createThreads,
  deleteThread,
  updateThread,
  updateStatus,
  assignHandler,
  updateFeedbacks,
} = FeedbacksActions;
const { fetchUser } = UserActions;

const initialStatusOptions = [
  { labelEN: 'Open', labelVI: 'Mở', value: 'open' },
  { labelEN: 'Close', labelVI: 'Đóng', value: 'close' },
];

const UpdateFeedback: React.FC = () => {
  const { id } = useParams();
  const userData = Utils.getSavedUserData();
  const t = useTranslation();
  const language = Utils.getSavedLocale();
  const dispatch = useTypedDispatch();
  const [updateReply, setUpdateReply] = React.useState<any>({
    id: '',
    message: '',
  });
  const [messageReply, setMessageReply] = React.useState<string>();
  const [lastEditedMessage, setLastEditedMessage] = React.useState<any>();
  const [dataContent, setDataContent] = React.useState<string>('');
  const [dataTitle, setDataTitle] = React.useState<string>('');
  const [openMessage, setOpenMessage] = React.useState<boolean>(false);
  const [openTitle, setOpenTitle] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<any>({
    title: '',
    content: '',
  });
  const [labelButton, setLabelButton] = React.useState<any>({
    label: '',
    value: '',
  });

  const users: any = useSelector((state: RootState) =>
    get(state.USER, 'payload')
  );
  const schema = yup
    .object()
    .shape({
      message: yup
        .string()
        .trim()
        .required(t('message.feedbackDescriptionRequired')),
    })
    .required();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      feedbackId: '',
      message: '',
    },
  });
  const details: IDataFeedbacks = useSelector((state: RootState) =>
    get(state.FEEDBACK, 'details')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.FEEDBACK, 'requestIsLoading')
  );
  const createMarkup = (html: any) => {
    return { __html: html };
  };
  const isAcceptAssign = Utils.isValidPermission(
    ModuleApiConstant.FEEDBACKS.ASSIGN_HANDLER
  );
  const isAcceptReply = Utils.isValidPermission(
    ModuleApiConstant.FEEDBACKS.CREATE_THREAD
  );
  const isAcceptUpdateFeedbacks = Utils.isValidPermission(
    ModuleApiConstant.FEEDBACKS.UPDATE_FEEDBACK
  );
  const isAcceptUpdateThread = Utils.isValidPermission(
    ModuleApiConstant.FEEDBACKS.UPDATE_THREAD
  );
  const isAcceptDeleteThread = Utils.isValidPermission(
    ModuleApiConstant.FEEDBACKS.DELETE_THREAD
  );
  const isAcceptUser = Utils.isValidPermission(
    ModuleApiConstant.USER.FETCH_USERS
  );
  const isAcceptStatus = Utils.isValidPermission(
    ModuleApiConstant.FEEDBACKS.UPDATE_FEEDBACK_STATUS
  );
  const formatDate = (value: any, typeFormat?: string) => {
    if (value) {
      const getValue =
        typeof value === 'string' ? value.replaceAll(',', '') : value;
      return dayjs(getValue).format(typeFormat || 'HH:mm, DD-MM-YYYY');
    }
    return 'No Date';
  };

  const onSubmit = (data: ICreateTheards) => {
    dispatch(createThreads({ ...data, feedbackId: id }));
    reset();
  };

  React.useEffect(() => {
    const isAcceptGetApi = Utils.isValidPermission(
      ModuleApiConstant.FEEDBACKS.GET_FEEDBACK_BY_ID
    );
    if (!isAcceptGetApi) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForFetchFeedbacks'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else if (id && uuidValidate(id)) dispatch(getFeedbackById(id));
    if (isAcceptUser)
      dispatch(fetchUser({ page: 1, limit: 0, status: 'active' }));
  }, [id]);

  const handleUpdateThread = (idThreads: string, value: string) => {
    setUpdateReply({ id: idThreads, message: value });
  };

  const handleUpdateReply = () => {
    if (updateReply.message) {
      dispatch(updateThread(updateReply));
      setUpdateReply({ id: '', message: '' });
    } else setMessageReply(t('message.feedbackDescriptionRequired'));
  };

  const handleOnchangeStatus = (statusId: string, statusFeedback: string) => {
    if (statusFeedback !== details.status) {
      dispatch(updateStatus({ id: statusId, status: statusFeedback }));
    }
  };

  const handleAssign = (value: string) => {
    if (value) {
      dispatch(
        assignHandler(details.id, {
          assigneeId: value,
        })
      );
    }
  };

  const handleSave = (idMessage: string, type: string) => {
    if (type === 'title') {
      if (!_.trim(dataTitle))
        setMessage({ title: t('message.feedbackTitleRequired') });
      else {
        dispatch(
          updateFeedbacks({
            id: idMessage,
            title: dataTitle,
            content: details.content,
          })
        );
        setOpenTitle(false);
      }
    } else if (!_.trim(dataContent))
      setMessage({ content: t('message.feedbackDescriptionRequired') });
    else {
      dispatch(
        updateFeedbacks({
          id: idMessage,
          title: details.title,
          content: dataContent,
        })
      );
      setOpenMessage(false);
    }
  };

  const usersOptions = React.useMemo(() => {
    const resolveOptions: { label: string; value: string }[] = [];
    if (users && users.length > 0)
      users.forEach((item: any) =>
        resolveOptions.push({
          label: item.userData.fullName,
          value: item.id,
        })
      );
    return resolveOptions;
  }, [details, users]);

  const statusOptions = React.useMemo(() => {
    const resolveOptions: any[] = [];
    const isEnglish = language === 'en';
    for (const option of initialStatusOptions) {
      const resolveLabel = isEnglish ? option.labelEN : option.labelVI;
      resolveOptions.push({
        label: resolveLabel,
        value: option.value,
      });
    }
    return resolveOptions;
  }, [initialStatusOptions, language]);

  React.useEffect(() => {
    const isEnglish = language === 'en';
    if (details.status === 'open') {
      const resolveLabel = isEnglish ? 'Open' : 'Đang mở';
      setLabelButton({ label: resolveLabel, value: 'open' });
    } else {
      const resolveLabel = isEnglish ? 'Close' : 'Đã đóng';
      setLabelButton({ label: resolveLabel, value: 'close' });
    }
  }, [details, language]);

  const handleHistory = (index: number) => {
    if (
      (lastEditedMessage && lastEditedMessage === index) ||
      (lastEditedMessage === 0 && lastEditedMessage === index)
    )
      setLastEditedMessage('');
    else setLastEditedMessage(index);
  };

  const handleDeleteThread = async (action: string, idRemove: string) => {
    if (action === 'remove') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningDeleteMessage'),
      });
      if (isAgree === 'ok') {
        dispatch(deleteThread(idRemove));
      }
    }
  };

  const _renderTopSection = () => {
    const checkCreateBy = _.get(userData, 'id') === details.createdBy?.id;
    const status = details.status === 'open';
    return (
      <Box sx={{ mb: 2 }}>
        <Box>
          {openTitle ? (
            <Box display="flex">
              <TextField
                rows={3}
                defaultValue={dataTitle}
                placeholder="IDRA Website"
                message={message?.title}
                onChange={(e) => {
                  setMessage({ ...message, title: '' });
                  setDataTitle(e.target.value);
                }}
                style={{ width: '100%' }}
                label=""
                multiline
              />
              <Tooltip title={t('button.close')}>
                <CloseIcon
                  sx={{
                    ...replyStyles.buttonCloseStyles,
                  }}
                  onClick={() => setOpenTitle(false)}
                />
              </Tooltip>
              <Tooltip title={t('button.save')}>
                <SaveIcon
                  sx={{
                    ...replyStyles.buttonSaveStyles,
                  }}
                  onClick={() => handleSave(details.id, 'title')}
                />
              </Tooltip>
            </Box>
          ) : (
            <Box display="flex">
              <Typography fontSize="20px" fontWeight="500">
                {details?.title}
              </Typography>
              {checkCreateBy && status && isAcceptUpdateFeedbacks && (
                <Tooltip title={t('tooltip.editTitleMessage')}>
                  <IconButton
                    sx={{ mt: -1.5 }}
                    onClick={() => {
                      setDataTitle(details.title);
                      setDataContent(details.content);
                      setOpenTitle(true);
                    }}
                  >
                    <EditIcon
                      sx={{
                        ...replyStyles.iconButtonStyles,
                        fontSize: 16,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
        </Box>
        <Box>
          <Typography sx={{ color: CommonColors.shuttleGray, fontSize: 12 }}>
            {t('label.opened')}{' '}
            {details?.updatedAt ? formatDate(details?.updatedAt) : 'N/I'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box display="flex">
            {details?.status === 'open' ? (
              <Box display="flex">
                <AdjustIcon
                  sx={{
                    ...replyStyles.AdjustIconStyles,
                    color: CommonColors.jewel,
                  }}
                />
                <Typography
                  sx={{ color: CommonColors.shuttleGray, fontSize: 16 }}
                >
                  {t('label.open')}
                </Typography>
              </Box>
            ) : (
              <Box display="flex">
                <AdjustIcon
                  sx={{
                    ...replyStyles.AdjustIconStyles,
                    color: 'red',
                  }}
                />
                <Typography
                  sx={{ color: CommonColors.shuttleGray, fontSize: 16 }}
                >
                  {t('label.close')}
                </Typography>
              </Box>
            )}
            <Box ml={4} display="flex">
              <MessageIcon
                sx={{
                  ...replyStyles.messageIconStyles,
                  mt: 0.5,
                }}
              />
              <Typography sx={{ ...replyStyles.titleStyles }}>
                {details?.threads?.length}
              </Typography>
            </Box>
            <Box>
              <Box sx={{ ml: 3 }} display="flex">
                <Typography sx={{ ...replyStyles.titleStyles }}>
                  {t('label.sender')}: {details?.createdBy?.userData?.fullName}
                </Typography>
                {details?.assignee && (
                  <Typography
                    sx={{
                      ...replyStyles.titleStyles,
                      ml: 3,
                    }}
                  >
                    {t('label.responder')}:{' '}
                    {details?.assignee?.userData?.fullName}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ mr: 2 }}>
              {details.status === 'open' && isAcceptAssign && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box>
                    <AutocompleteInput
                      placeholder={t('label.unassigned')}
                      value={details?.assignee?.id}
                      options={usersOptions}
                      onChange={(newValue: string) => handleAssign(newValue)}
                      containerSx={{ height: 1 }}
                      label={t('label.assignee')}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            {isAcceptStatus && (
              <GroupButton
                options={statusOptions}
                defaultValue={labelButton}
                onChange={(e: any) => handleOnchangeStatus(details.id, e)}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const _renderBottomSection = () => {
    const checkCreateBy = _.get(userData, 'id') === details.createdBy?.id;
    const status = details.status === 'open';
    return (
      <Box sx={{ mx: 4, mb: 4 }}>
        {!openMessage ? (
          <Box textAlign="end">
            {checkCreateBy && status && isAcceptUpdateFeedbacks && (
              <Tooltip title={t('tooltip.editMessage')}>
                <IconButton
                  sx={{ mt: -1.5 }}
                  onClick={() => {
                    setOpenMessage(true);
                    setDataTitle(details.title);
                    setDataContent(details.content);
                  }}
                >
                  <EditIcon
                    sx={{
                      ...replyStyles.iconButtonStyles,
                      fontSize: 16,
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Tooltip title={t('button.close')}>
              <CloseIcon
                sx={{
                  ...replyStyles.buttonCloseStyles,
                }}
                onClick={() => setOpenMessage(false)}
              />
            </Tooltip>
            <Tooltip title={t('button.save')}>
              <SaveIcon
                onClick={() => handleSave(details.id, 'content')}
                sx={{
                  ...replyStyles.buttonSaveStyles,
                }}
              />
            </Tooltip>
          </Box>
        )}
        <Box>
          {openMessage ? (
            <Box>
              <TextEditor
                value={dataContent}
                message={message?.content}
                containerSx={{ mb: 2 }}
                onChange={(e: any) => {
                  setMessage({ ...message, content: '' });
                  setDataContent(e);
                }}
                required
                label=""
              />
            </Box>
          ) : (
            <Box
              sx={{
                ...replyStyles.detailStyles,
              }}
              component="div"
              dangerouslySetInnerHTML={createMarkup(details?.content)}
            />
          )}
        </Box>
        <Divider
          variant="inset"
          sx={{
            width: 0.8,
          }}
        />
      </Box>
    );
  };

  const _renderThreads = () => {
    const threads = details?.threads;
    const status = details.status === 'open';
    return (
      <Box sx={{ mt: 2 }}>
        {details?.threads && (
          <Box>
            {_.map(threads, (item: IThreads, index: number) => {
              const lastMessage = item?.lastEditedMessages || [];
              const updatedAt = formatDate(item.updatedAt);
              const checkSender = _.get(userData, 'id') === item?.sender?.id;
              return (
                <Box key={index} sx={{ display: 'flex', mt: 2 }}>
                  {_.get(userData, 'id') === item?.sender?.id ? (
                    <Box title={item.sender.userData.fullName}>
                      {item.sender.userData?.avatar ? (
                        <Box>
                          <Avatar
                            alt={item.sender.userData.fullName.slice(0, 1)}
                            sx={{
                              ...replyStyles.avatarStyles,
                              mr: 2,
                            }}
                            style={{ fontSize: 15, border: 'red' }}
                            src={item.sender.userData.avatar.path}
                          />
                        </Box>
                      ) : (
                        <Box>
                          <Avatar
                            sx={{
                              ...replyStyles.avatarStyles,
                              mr: 2,
                            }}
                            style={{ fontSize: 15 }}
                          >
                            {item.sender.userData.fullName.slice(0, 1)}
                          </Avatar>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Box width={42} />
                  )}
                  <RoundedContainer sx={{ width: 1, minHeight: 70, ml: 1 }}>
                    <Box>
                      <Box>
                        <Box
                          className={
                            _.get(userData, 'id') === item?.sender?.id
                              ? 'title'
                              : ''
                          }
                          sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            '&.title': {
                              display: 'flex',
                              justifyContent: 'space-between',
                            },
                          }}
                        >
                          <Box>
                            {_.get(userData, 'id') === item?.sender?.id ? (
                              <Breadcrumbs
                                separator="|"
                                aria-label="breadcrumb"
                              >
                                <Typography
                                  sx={{
                                    ...replyStyles.fullNameStyles,
                                  }}
                                >
                                  {item.sender.userData.fullName}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: CommonColors.shuttleGray,
                                    fontSize: 12,
                                  }}
                                >
                                  {item?.updatedAt ? updatedAt : 'N/I'}
                                </Typography>
                              </Breadcrumbs>
                            ) : (
                              <Breadcrumbs
                                separator="|"
                                aria-label="breadcrumb"
                              >
                                <Typography
                                  sx={{
                                    color: CommonColors.shuttleGray,
                                    fontSize: 12,
                                  }}
                                >
                                  {item?.updatedAt ? updatedAt : 'N/I'}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    ...replyStyles.fullNameStyles,
                                  }}
                                >
                                  {item.sender.userData.fullName}
                                </Typography>
                              </Breadcrumbs>
                            )}
                          </Box>
                          {checkSender && status && (
                            <Box display="flex">
                              {isAcceptUpdateThread && (
                                <Tooltip title={t('tooltip.editMessage')}>
                                  <IconButton
                                    onClick={() =>
                                      handleUpdateThread(item.id, item.message)
                                    }
                                  >
                                    <EditIcon
                                      sx={{
                                        ...replyStyles.iconButtonStyles,
                                        fontSize: 16,
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {isAcceptDeleteThread && (
                                <Tooltip title={t('tooltip.deleteMessage')}>
                                  <IconButton
                                    onClick={() =>
                                      handleDeleteThread('remove', item.id)
                                    }
                                  >
                                    <DeleteIcon
                                      sx={{
                                        ...replyStyles.buttonCloseStyles,
                                        fontSize: 16,
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          ...replyStyles.detailStyles,
                        }}
                        component="div"
                        dangerouslySetInnerHTML={createMarkup(item.message)}
                      />
                    </Box>
                    {item?.lastEditedMessages?.length > 0 && (
                      <Box textAlign="end">
                        <Typography onClick={() => handleHistory(index)}>
                          <Tooltip title={t('tooltip.messageEditHistory')}>
                            <IconButton>
                              <HistoryIcon
                                sx={{
                                  '&:hover': {
                                    cursor: 'pointer',
                                  },
                                  color: CommonColors.AazureRadiance,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </Box>
                    )}
                    <Collapse in={lastEditedMessage === index}>
                      <Paper
                        sx={{ m: 1, p: 1, borderRadius: '10px' }}
                        elevation={4}
                      >
                        {_.map(lastMessage, (inner: any, innerKey: number) => {
                          const createdAt = inner?.createdAt;
                          return (
                            <Box key={innerKey}>
                              <Box
                                sx={{
                                  ...replyStyles.messageStyles,
                                }}
                                component="div"
                                dangerouslySetInnerHTML={createMarkup(
                                  inner?.message
                                )}
                              />
                              <Typography
                                sx={{
                                  color: CommonColors.shuttleGray,
                                  fontSize: 12,
                                  textAlign: 'end',
                                  mr: 2,
                                }}
                              >
                                {createdAt ? formatDate(createdAt) : 'N/I'}
                              </Typography>
                              {innerKey + 1 < lastMessage.length && <Divider />}
                            </Box>
                          );
                        })}
                      </Paper>
                    </Collapse>
                    {updateReply.id === item.id && (
                      <Box>
                        <TextEditor
                          value={updateReply.message}
                          message={messageReply}
                          label={t('label.editedReply')}
                          containerSx={{ mb: 2 }}
                          onChange={(e: any) => {
                            setUpdateReply({
                              ...updateReply,
                              message: e,
                            });
                            setMessageReply('');
                          }}
                          required
                        />
                        <Box textAlign="end">
                          <LoadingButton
                            sx={{ mr: 1 }}
                            loading={isLoading}
                            onClick={() =>
                              setUpdateReply({ id: '', messageReply: '' })
                            }
                          >
                            {t('button.close')}
                          </LoadingButton>
                          <LoadingButton
                            variant="contained"
                            loading={isLoading}
                            onClick={() => handleUpdateReply()}
                          >
                            {t('button.save')}
                          </LoadingButton>
                        </Box>
                      </Box>
                    )}
                  </RoundedContainer>
                  {_.get(userData, 'id') !== item?.sender?.id ? (
                    <Box title={item.sender.userData.fullName}>
                      {item.sender.userData?.avatar ? (
                        <Box>
                          <Avatar
                            alt={item.sender.userData.fullName.slice(0, 1)}
                            sx={{
                              ...replyStyles.avatarStyles,
                              ml: 2,
                            }}
                            style={{ fontSize: 15, border: 'red' }}
                            src={item.sender.userData.avatar.path}
                          />
                        </Box>
                      ) : (
                        <Box>
                          <Avatar
                            sx={{
                              ...replyStyles.avatarStyles,
                              ml: 2,
                            }}
                            style={{ fontSize: 15 }}
                          >
                            {item.sender.userData.fullName.slice(0, 1)}
                          </Avatar>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Box width={42} ml={2} />
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    );
  };

  const _renderReply = () => {
    return (
      <Box sx={{ mt: 2, display: 'flex' }}>
        <Avatar
          alt="5"
          sx={{
            ...replyStyles.avatarStyles,
            mr: '2px',
          }}
          style={{ fontSize: 15, border: 'red' }}
        />
        <RoundedContainer sx={{ width: 1, minHeight: 100, ml: 1, mr: 7 }}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Stack direction="column" mb={1}>
              <Stack mt={1}>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <TextEditor
                      value={field.value}
                      message={errors.message?.message}
                      label={t('label.reply')}
                      containerSx={{ mb: 2 }}
                      onChange={(e: any) => field.onChange(e)}
                      required
                    />
                  )}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: '50px' }}
              >
                <LoadingButton
                  onClick={() => Utils.redirect(ROUTERS.FEEDBACK)}
                  sx={{ mr: 1 }}
                  variant="outlined"
                  loading={isLoading}
                >
                  {t('button.back')}
                </LoadingButton>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  {t('button.submit')}
                </LoadingButton>
              </Stack>
            </Stack>
          </Box>
        </RoundedContainer>
      </Box>
    );
  };

  const _renderContent = () => (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
      {_renderThreads()}
      <Box>
        {details.status === 'open' && isAcceptReply ? (
          <Box> {_renderReply()}</Box>
        ) : (
          <Box textAlign="end" mt={2}>
            <LoadingButton
              onClick={() => Utils.redirect(ROUTERS.FEEDBACK)}
              sx={{ mr: 1 }}
              variant="outlined"
              loading={isLoading}
            >
              {t('button.back')}
            </LoadingButton>
          </Box>
        )}
      </Box>
    </>
  );

  const renderMain = () => {
    return (
      <Stack flex={1} direction="column">
        {isLoading ? (
          <EventSkeleton />
        ) : (
          <Box>{!_.isEmpty(details) && <Box>{_renderContent()}</Box>}</Box>
        )}
      </Stack>
    );
  };

  return renderMain();
};

export default UpdateFeedback;
