import { toast } from 'react-toastify';

import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';
import { INewsStructure } from '@/Interfaces/News.interface';

const { ACTION_TYPES, ROUTERS } = CONSTANTS;

// SINGLE ACTIONS
const newsIsRequest = () => {
  return {
    type: ACTION_TYPES.NEWS_IS_REQUEST,
  };
};

const setNewsMeta = (payload: any) => {
  return {
    type: ACTION_TYPES.SET_NEWS_META,
    payload,
  };
};

const setDefaultNewsReducer = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_NEWS_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchNewsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_NEWS_SUCCESS,
    payload,
  };
};

const fetchNewsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_NEWS_FAILURE,
  };
};

const fetchNews = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(newsIsRequest());
    await API.fetchNews(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchNewsFailure());
        else {
          const resolveResult: { items: any; meta: any } = result as {
            items: any;
            meta: any;
          };
          dispatch(setNewsMeta(resolveResult.meta));
          dispatch(fetchNewsSuccess(resolveResult.items));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(fetchNewsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createPostSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_POST_SUCCESS,
  };
};

const createPostFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_POST_FAILURE,
  };
};

const createPost = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(newsIsRequest());
    await API.createPost(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(createPostFailure());
        else {
          dispatch(createPostSuccess());
          await Utils.redirect({
            pathname: ROUTERS.NEWS_MANAGER,
          });
          await toast.success(response?.data?.message);
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(createPostFailure());
        await toast.error(error?.message);
        await Utils.resolveFailureResponse(error);
      });
  };
};

const getPostByIdSuccess = (payload: INewsStructure) => {
  return {
    type: ACTION_TYPES.GET_POST_BY_ID_SUCCESS,
    payload,
  };
};

const getPostByIdFailure = () => {
  return {
    type: ACTION_TYPES.GET_POST_BY_ID_FAILURE,
  };
};

const getPostById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(newsIsRequest());
    await API.getPostById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getPostByIdFailure());
        else {
          const resolveResult: INewsStructure = result as INewsStructure;
          dispatch(getPostByIdSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(getPostByIdFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const updatePostSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_POST_SUCCESS,
  };
};

const updatePostFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_POST_FAILURE,
  };
};

const updatePost = (payload: any, id: string) => {
  return async (dispatch: any) => {
    dispatch(newsIsRequest());
    await API.updatePost(payload, id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updatePostFailure());
        } else {
          await dispatch(updatePostSuccess());
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updatePostFailure());
      });
  };
};

const updateStatusPostSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_STATUS_POST_SUCCESS,
  };
};

const updateStatusPostFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_STATUS_POST_FAILURE,
  };
};

const updateStatusPost = (status: string, id: string, filters: any) => {
  return async (dispatch: any) => {
    dispatch(newsIsRequest());
    await API.updateStatusPost(status, id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(updateStatusPostFailure());
        else {
          dispatch(updateStatusPostSuccess());
          dispatch(fetchNews(filters));
          toast.success(response?.data?.message);
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(updateStatusPostFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const deletePostSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_STATUS_POST_SUCCESS,
  };
};

const deletePostFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_STATUS_POST_FAILURE,
  };
};

const deletePost = (id: string, filters: any) => {
  return async (dispatch: any) => {
    dispatch(newsIsRequest());
    await API.deletePost(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(deletePostFailure());
        else {
          dispatch(deletePostSuccess());
          dispatch(fetchNews(filters));
          toast.success(response?.data?.message);
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(deletePostFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  fetchNews,
  createPost,
  deletePost,
  getPostById,
  updatePost,
  updateStatusPost,
  setDefaultNewsReducer,
};
