import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@utils';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { toast } from 'react-toastify';
import { IFileTemplate } from '../Interfaces/FileTemplate.interface';

const { ACTION_TYPES, ROUTERS } = CONSTANTS;

// SINGLE_ACTIONS

const clearFileTemplate = () => {
  return {
    type: ACTION_TYPES.CLEAR_FILE_TEMPLATE,
  };
};

const setMetaPagination = (payload: IMetaStructure) => {
  return {
    type: ACTION_TYPES.SET_META_FILE_TEMPLATE,
    payload,
  };
};

const fileTemplateIsLoading = () => {
  return {
    type: ACTION_TYPES.FILE_TEMPLATE_IS_REQUEST,
  };
};

// ASYNC_ACTIONS
const fetchFileTemplatesSuccess = (payload: IFileTemplate[]) => {
  return {
    type: ACTION_TYPES.FETCH_FILE_TEMPLATES_SUCCESS,
    payload,
  };
};

const fetchFileTemplatesFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_FILE_TEMPLATES_FAILURE,
  };
};

const fetchFileTemplates = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(fileTemplateIsLoading());
    await API.fetchFileTemplates(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFileTemplatesFailure());
        else {
          const resolveResult: {
            items: IFileTemplate[];
            meta: IMetaStructure;
          } = result as { items: IFileTemplate[]; meta: IMetaStructure };

          dispatch(fetchFileTemplatesSuccess(resolveResult.items));
          dispatch(setMetaPagination(resolveResult.meta));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchFileTemplatesFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createFileTemplateSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_FILE_TEMPLATE_SUCCESS,
  };
};

const createFileTemplateFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_FILE_TEMPLATE_FAILURE,
  };
};

const createFileTemplate = (
  payload: Omit<IFileTemplate, 'createdBy' | 'id'>
) => {
  return async (dispatch: any) => {
    await API.createFileTemplate(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createFileTemplateFailure());
        } else {
          dispatch(createFileTemplateSuccess());
          Utils.redirect(ROUTERS.FILE_TEMPLATES);
          toast.success(message);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFileTemplateFailure());
      });
  };
};

const updateFileTemplateSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_FILE_TEMPLATE_SUCCESS,
  };
};

const updateFileTemplateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_FILE_TEMPLATE_FAILURE,
  };
};

const updateFileTemplate = (payload: Omit<IFileTemplate, 'createdBy'>) => {
  return async (dispatch: any) => {
    await API.updateFileTemplate(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateFileTemplateFail());
          toast.error(message);
        } else {
          dispatch(updateFileTemplateSuccess());
          Utils.redirect(ROUTERS.FILE_TEMPLATES);
          toast.success(message);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFileTemplateFail());
      });
  };
};

const getFileTemplateByIDSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_FILE_TEMPLATE_BY_ID_SUCCESS,
    payload,
  };
};

const getFileTemplateByIDFail = () => {
  return {
    type: ACTION_TYPES.GET_FILE_TEMPLATE_BY_ID_FAILURE,
  };
};

const getFileTemplateByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(fileTemplateIsLoading());
    await API.getFileTemplateByID(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getFileTemplateByIDFail());
        else {
          dispatch(getFileTemplateByIDSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getFileTemplateByIDFail());
      });
  };
};

const deleteFileTemplateSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_FILE_TEMPLATE_SUCCESS,
  };
};

const deleteFileTemplateFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_FILE_TEMPLATE_FAILURE,
  };
};

const deleteFileTemplate = (id: string, resolveFilter: any) => {
  return async (dispatch: any) => {
    dispatch(fileTemplateIsLoading());
    await API.deleteFileTemplate(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteFileTemplateFailure());
          toast.error(message);
        } else {
          dispatch(fetchFileTemplates(resolveFilter));
          dispatch(deleteFileTemplateSuccess());
          toast.success(message);
        }
      })
      .catch(async (error: any) => {
        await dispatch(deleteFileTemplateFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  clearFileTemplate,
  fetchFileTemplates,
  getFileTemplateByID,
  createFileTemplate,
  updateFileTemplate,
  deleteFileTemplate,
};
