import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
import {
  ICreateAnnouncement,
  IUpdateAnnouncement,
} from '@/Interfaces/Announcement.interface';

const { ANNOUNCEMENT } = CONSTANTS.API;

export const fetchAnnouncements = async (payload: any) => {
  return sendRequest(ANNOUNCEMENT.BASIC, 'GET', payload);
};

export const getAnnouncementsById = async (id: string) => {
  return sendRequest(`${ANNOUNCEMENT.BASIC}/${id}`, `GET`);
};

export const createAnnouncements = async (payload: ICreateAnnouncement) => {
  return sendRequest(ANNOUNCEMENT.BASIC, 'POST', payload);
};

export const updateAnnouncements = async (payload: IUpdateAnnouncement) => {
  const { id } = payload;
  return sendRequest(`${ANNOUNCEMENT.BASIC}/${id}`, 'PUT', payload);
};

export const getForUserAnnouncements = async () => {
  return sendRequest(ANNOUNCEMENT.FOR_USERS, 'GET');
};

export const updateAnnouncementsType = async (id: string, type: string) => {
  return sendRequest(`${ANNOUNCEMENT.UPDATE_TYPE}/${id}`, 'PUT', { type });
};

export const deleteAnnouncements = async (id: string) => {
  return sendRequest(`${ANNOUNCEMENT.BASIC}/${id}`, `DEL`);
};
