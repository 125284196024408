import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang';

import Utils from '@/Utils';
import CONSTANTS from '@/Constants';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { IWorkProgressResponseStructure } from '@/Interfaces/WorkProgress.interface';
import { ChipStatus, StickyHeadTable } from '@/Components/Common';

import { Box, IconButton, Tooltip, Avatar, Typography } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import dayjs from 'dayjs';
import { getSavedUserData } from '@/Utils/Cookie.utils';

interface IColumnStructure {
  id:
    | 'no'
    | 'name'
    | 'created_at'
    | 'created_by'
    | 'assigned_by'
    | 'latest_step'
    | 'status'
    | 'action';
  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

interface IDataTableStructure {
  no: number;
  name: JSX.Element;
  created_at: string;
  created_by: string;
  assigned_by: JSX.Element;
  latest_step: JSX.Element;
  status: JSX.Element;
  action: JSX.Element;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'name', label: 'workProgress', minWidth: 200, align: 'left' },
  { id: 'created_at', label: 'createdAt', minWidth: 100, align: 'center' },
  { id: 'created_by', label: 'createdBy', minWidth: 150, align: 'left' },
  {
    id: 'assigned_by',
    label: 'assignedBy',
    minWidth: 150,
    align: 'center',
  },
  { id: 'latest_step', label: 'latestStep', minWidth: 200, align: 'left' },
  { id: 'status', label: 'status', minWidth: 50, align: 'center' },
  { id: 'action', label: 'action', minWidth: 5, align: 'center' },
];

const createDataTable = (
  no: number,
  name: JSX.Element,
  created_at: string,
  created_by: string,
  assigned_by: JSX.Element,
  latest_step: JSX.Element,
  status: JSX.Element,
  action: JSX.Element
): IDataTableStructure => {
  return {
    no,
    name,
    created_at,
    created_by,
    assigned_by,
    latest_step,
    status,
    action,
  };
};

interface ISectionProps {
  meta: IMetaStructure;
  payload: IWorkProgressResponseStructure[];
  onPageAction(value: number, type: 'limit' | 'page'): void;
  onRowAction(workProgress: any, action: 'edit' | 'status' | 'delete'): void;
  isLoading?: boolean;
}
const { MODULE_API } = CONSTANTS;

const WorkProgressDataTable: React.FC<ISectionProps> = ({
  meta,
  payload,
  isLoading = false,
  onPageAction,
  onRowAction,
}) => {
  const [rows, setRows] = React.useState<IDataTableStructure[]>([]);
  const t = useTranslation();
  const isAcceptDelete = Utils.isValidPermission(
    MODULE_API.WORK_PROGRESS.DELETE
  );
  const isAcceptViewDetails = Utils.isValidPermission(
    MODULE_API.WORK_PROGRESS.GET_BY_ID
  );

  React.useEffect(() => {
    const resolveRows: IDataTableStructure[] = [];
    if (!_.isEmpty(payload)) {
      _.forEach(
        payload,
        (item: IWorkProgressResponseStructure, index: number) => {
          const { name, createdAt, createdBy, wasAssigned, status } = item;
          const resolveCreateAt = dayjs(createdAt).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          const resolveLatestStep = item?.listStep[0]?.content;
          const currentPage = _.get(meta, 'currentPage');
          const limit = _.get(meta, 'itemsPerPage');
          const resolveRowIndex = index + 1 + (currentPage - 1) * limit;

          const resolveStatus = status === 'done' ? 'processing' : 'done';
          const userData = getSavedUserData();
          let checkIsAssigned = false;
          _.map(item.wasAssigned, (items: any) => {
            if (items.email === userData.email) {
              checkIsAssigned = true;
            }
          });
          const renderWasAssigned = () => {
            return (
              <Box display={'flex'} justifyContent="left">
                {_.map(
                  wasAssigned,
                  (wasAssignedItem: any, innerKey: number) => {
                    return (
                      innerKey < 5 && (
                        <Box
                          sx={{ display: 'flex' }}
                          title={wasAssignedItem?.email}
                          key={innerKey}
                        >
                          {wasAssignedItem?.userData?.avatar ? (
                            <Box>
                              <Avatar
                                alt={wasAssignedItem.userData.fullName.slice(
                                  0,
                                  1
                                )}
                                sx={{
                                  bgcolor: deepPurple[200],
                                  fontSize: 8,
                                  textAlign: 'center',
                                  mr: '2px',
                                  width: 30,
                                  height: 30,
                                }}
                                style={{ fontSize: 15, border: 'red' }}
                                src={wasAssignedItem.userData.avatar.path}
                              />
                            </Box>
                          ) : (
                            <Box>
                              <Avatar
                                sx={{
                                  bgcolor: deepPurple[200],
                                  fontSize: 8,
                                  textAlign: 'center',
                                  mr: '2px',
                                  width: 30,
                                  height: 30,
                                }}
                                style={{ fontSize: 15 }}
                              >
                                {wasAssignedItem?.userData?.fullName.slice(
                                  0,
                                  1
                                )}
                              </Avatar>
                            </Box>
                          )}
                        </Box>
                      )
                    );
                  }
                )}
                <Box>
                  {wasAssigned.length > 5 && (
                    <Box
                      sx={{
                        border: '1px',
                        borderRadius: '20px',
                        width: 30,
                        height: 30,
                        background: '#EEEEEE',
                        position: 'relative',
                      }}
                    >
                      <Typography
                        sx={{
                          margin: 0,
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        +{wasAssigned.length - 5}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            );
          };

          resolveRows.push(
            createDataTable(
              resolveRowIndex,
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  // display: '-webkit-box',
                  // WebkitLineClamp: 3,
                  // WebkitBoxOrient: 'vertical',
                  height: 1,
                  maxWidth: 200,
                }}
              >
                {name}
              </Typography>,
              resolveCreateAt,
              createdBy?.userData?.fullName,
              renderWasAssigned(),
              <Typography
                sx={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  height: 1,
                  maxWidth: { xs: 250, xl: 350 },
                }}
              >
                {resolveLatestStep}
              </Typography>,
              <Tooltip
                title={t('tooltip.clickToChangeStatus', {
                  status: resolveStatus,
                })}
              >
                <Box
                  component="div"
                  onClick={() => {
                    onRowAction(item, 'status');
                  }}
                  sx={{
                    '* :hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <ChipStatus label={status} />
                </Box>
              </Tooltip>,
              <Box>
                <Tooltip title={t('tooltip.viewDetails')}>
                  <IconButton
                    onClick={() => {
                      onRowAction(item, 'edit');
                    }}
                    disabled={
                      isAcceptViewDetails
                        ? !isAcceptViewDetails
                        : !checkIsAssigned
                    }
                  >
                    <VisibilityOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                {isAcceptDelete && (
                  <Tooltip title={t('tooltip.clickToDeleteWorkProgress')}>
                    <IconButton onClick={() => onRowAction(item, 'delete')}>
                      <DeleteOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )
          );
        }
      );
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      onAction={onPageAction}
      paginate={meta}
      isLoading={isLoading}
    />
  );
};

export default WorkProgressDataTable;
