/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router';
import { useTranslation } from 'react-multi-lang';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Autocomplete,
} from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
  RoundedContainer,
  TextField,
  UploadFileInput,
  TextEditor,
} from '@/Components/Common';

import { RootState, useTypedDispatch } from '@/store';
import Constants from '@/Constants';
import Utils from '@/Utils';
import { PorfolioActions } from '@/Actions';

const { ROUTERS, TECH_LIST, PORTFOLIO_CAT } = Constants;
const { getPorfolioById, updatePorfolio, setDefaultPorfoliosReducer } =
  PorfolioActions;

// eslint-disable-next-line react/function-component-definition
const UpdateProfolio: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const isLoading: boolean = useSelector((state: RootState) =>
    _.get(state.ADMIN_PORFOLIO, 'requestIsLoading')
  );

  const detailsStore: any = useSelector((state: RootState) =>
    _.get(state.ADMIN_PORFOLIO, 'details')
  );
  const [selectedTechnology, setSelectedTechnology] = useState<any>([]);
  const [selectCategory, setSelectCategory] = useState<string>('');
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [removeImages, setRemoveImages] = useState<string[]>([]);
  const [originalImages, setOriginalImages] = useState<File[]>([]);
  const [details, setDetails] = useState<any>({});

  const { id } = useParams();

  const schema = yup
    .object({
      name: yup.string().trim().required(t('message.titleRequired')),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      general: '',
      assignment: '',
      solution: '',
      category: '',
      tag: '',
      client: '',
      date: '',
      url: '',
      featured: false,
    },
  });

  useEffect(() => {
    if (id) dispatch(getPorfolioById(id));
    return () => {
      dispatch(setDefaultPorfoliosReducer());
    };
  }, [id]);

  useEffect(() => {
    if (detailsStore?.id === id) setDetails(detailsStore);
  }, [detailsStore]);

  const generateDetails = async () => {
    if (!_.isEmpty(details)) {
      setValue('name', details.name);
      setValue('general', _.get(details, 'content[general]'));
      setValue('assignment', _.get(details, 'content[assignment]'));
      setValue('solution', _.get(details, 'content[solution]'));
      setValue('client', details.client);
      setValue('category', details.category);
      setValue('date', details.date);
      setValue('url', details.url);
      setValue('tag', _.join(details.tag || [], ';'));
      setValue('featured', details.featured);
      setSelectedTechnology(details.technology || []);
      setSelectCategory(details.category);
      // const blob = await fetch(details?.image.path).then((r) => r.blob());
      // const file = new File([blob], details?.image?.originalName);
      // const resolveFile: File[] = [file];
      // setValue('image', resolveFile as any);
      if (!_.isEmpty(details.image)) {
        const imagePayload = _.map(details.image, (img: any) => {
          return {
            id: img?.id,
            img: img?.file?.thumbnail,
          };
        });
        setOriginalImages(imagePayload);
      }
    }
  };

  useEffect(() => {
    generateDetails();
  }, [details]);

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('content[general]', data.general);
    formData.append('content[assignment]', data.assignment);
    formData.append('content[solution]', data.solution);
    formData.append('client', data.client);
    formData.append('category', selectCategory);
    formData.append('date', data.date);
    formData.append('url', data.url);
    formData.append('featured', data.featured);
    _.forEach(selectedTechnology, (item: string) => {
      formData.append('technology[]', item);
    });
    if (data.tag) {
      _.forEach(data.tag.split(';'), (tag: string) => {
        formData.append('tag[]', tag);
      });
    }
    _.forEach(selectedImages, (item: File) => {
      formData.append('images', item);
    });
    _.forEach(removeImages, (item: File) => {
      formData.append('removeImages[]', item);
    });
    if (id) dispatch(updatePorfolio(formData, id));
  };

  const _renderTopSection = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Typography fontSize="20px" fontWeight="500">
        Update Portfolio
      </Typography>
    </Stack>
  );

  const getDefaultValue = (): any => {
    const results: any[] = [];
    _.forEach(TECH_LIST, (option: any) => {
      const findData = _.includes(selectedTechnology, option.label);
      if (findData) results.push(option);
    });
    return results;
  };
  console.log(selectedTechnology);

  const _renderBottomSection = () => (
    <RoundedContainer>
      <Grid
        container
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={10}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label={t('label.name')}
                placeholder={t('placeholder.titlePost')}
                message={errors.name?.message}
                required
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="featured"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={field.value}
                    disabled={isLoading}
                    onChange={(event) => field.onChange(event.target.checked)}
                  />
                }
                label="Featured"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ width: 1 }}>
            <Typography
              sx={{
                mb: 1,
                fontWeight: 400,
                fontFamily: 'sans-serif',
                color: 'black',
              }}
            >
              Category
            </Typography>
            <Select
              value={selectCategory}
              onChange={(event) => setSelectCategory(event.target.value)}
              disabled={isLoading}
              sx={{ width: 1 }}
            >
              {_.map(PORTFOLIO_CAT.sort(), (cat: string) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box sx={{ width: 1 }}>
            <Typography
              sx={{
                mb: 2,
                fontWeight: 400,
                fontFamily: 'sans-serif',
                color: 'black',
              }}
            >
              Technology
            </Typography>
            <Autocomplete
              onChange={(_event, options) => {
                setSelectedTechnology(_.map(options, (el: any) => el?.label));
              }}
              disabled={isLoading}
              options={_.orderBy(TECH_LIST, ['label'], ['asc'])}
              multiple
              sx={{ width: 1 }}
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  message={errors.client?.message}
                  disabled={isLoading}
                />
              )}
              value={getDefaultValue()}
              renderOption={(props, option: any) => {
                return (
                  <Box component="li" {...props}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: 2,
                      }}
                    >
                      <LazyLoadImage
                        src={option?.icon}
                        alt="clientLogo"
                        loading="lazy"
                        style={{
                          width: 20,
                          objectFit: 'contain',
                          height: 20,
                          marginRight: '5px',
                        }}
                      />
                      {option.label}
                    </Box>
                  </Box>
                );
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="tag"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label="Tag"
                message={errors.tag?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="client"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label="Client"
                message={errors.client?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label="Year"
                message={errors.date?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label="URL"
                message={errors.url?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={8}>
          <UploadFileInput
            type="IMAGE"
            isMultiple
            label="Images"
            containerSx={{ mb: 2 }}
            files={selectedImages}
            onFileChange={setSelectedImages}
          />
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', gap: 2 }}>
          {_.map(originalImages, (oImg: any) => {
            if (_.includes(removeImages, oImg?.id)) return null;
            return (
              <Box
                key={oImg?.id}
                sx={{ cursor: 'pointer', position: 'relative' }}
              >
                <HighlightOffIcon
                  sx={{
                    position: 'absolute',
                    top: -15,
                    right: -15,
                  }}
                  onClick={() => setRemoveImages([...removeImages, oImg?.id])}
                />
                <LazyLoadImage
                  src={oImg?.img}
                  alt="img"
                  style={{ width: 100, objectFit: 'contain' }}
                />
              </Box>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="general"
            control={control}
            render={({ field }) => (
              <TextEditor
                value={field.value}
                onChange={(value: any) => field.onChange(value)}
                label="General"
                message={errors.general?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="assignment"
            control={control}
            render={({ field }) => (
              <TextEditor
                value={field.value}
                onChange={(value: any) => field.onChange(value)}
                label="Assignment"
                message={errors.assignment?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="solution"
            control={control}
            render={({ field }) => (
              <TextEditor
                value={field.value}
                onChange={(value: any) => field.onChange(value)}
                label="Solution"
                message={errors.solution?.message}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            mt={2}
          >
            <Button
              variant="outlined"
              onClick={() => Utils.redirect(ROUTERS.ADMIN_PORTFOLIO)}
            >
              {t('button.cancel')}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ ml: 1 }}
              loading={isLoading}
            >
              {t('button.save')}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </RoundedContainer>
  );

  const _renderContent = () => (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );

  return <Stack>{_renderContent()}</Stack>;
};

export default UpdateProfolio;
