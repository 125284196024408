import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import { FormHelperText, InputLabel, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-multi-lang';

interface IProps {
  label?: string;
  value: string;
  required?: boolean;
  message?: string;
  onChange?(newValue?: string): void;
  onlyMarkdown?: boolean;
  emptyPlaceholder?: string;
  sx?: object;
  disabled?: boolean;
}

const MarkdownEditor: React.FC<IProps> = ({
  value,
  label = '',
  message = '',
  onChange,
  required = false,
  onlyMarkdown = false,
  emptyPlaceholder = '',
  sx = {},
  disabled = false,
}) => {
  const t = useTranslation();
  const renderContent = () => {
    if (onlyMarkdown && value)
      return (
        <MDEditor.Markdown
          source={value}
          style={{ whiteSpace: 'pre-wrap', colorScheme: 'light' }}
        />
      );
    if (onlyMarkdown && !value)
      return (
        <Typography>
          {emptyPlaceholder ? emptyPlaceholder : t('tooltip.noDataWereFound')}
        </Typography>
      );
    if (!onlyMarkdown && !disabled)
      return (
        <Stack
          sx={{
            border: `2px solid ${Boolean(message) ? '#d32f2f' : 'transparent'}`,
            borderRadius: '5px',
          }}
        >
          <MDEditor
            value={value}
            onChange={onChange && onChange}
            minHeight={1200}
            style={{
              width: '100%',
              height: '100%',
              fontSize: '16px',
              maxHeight: '500px',
              overflow: 'auto',
              whiteSpace: 'pre-wrap',
            }}
            height={500}
          />
        </Stack>
      );
    else
      return (
        <MDEditor.Markdown
          source={value || t('tooltip.noInformation')}
          style={{ whiteSpace: 'pre-wrap', colorScheme: 'light' }}
        />
      );
  };

  return (
    <Stack
      direction="column"
      flex={1}
      sx={{
        width: '100%',
        height: '100%',
        ...sx,
      }}
    >
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}{' '}
          {required ? (
            <Typography component="span" color="error">
              (*)
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      {renderContent()}
      {message ? (
        <FormHelperText error={Boolean(message)}>{message}</FormHelperText>
      ) : null}
    </Stack>
  );
};

export default MarkdownEditor;
