import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { animated, useSpring } from '@react-spring/web';
import { Box, Stack } from '@mui/material';
import { Helmet } from 'react-helmet';

import { RootState, useTypedDispatch } from '@/store';
import { Images } from '@/Themes';
import { CommonActions } from '@/Actions';

interface IProps {
  children: React.JSX.Element;
}

const { contentIsLoaded } = CommonActions;

const PreLoading: React.FC<IProps> = ({ children }) => {
  const containerSprings = useSpring({
    delay: 2500,
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
  });
  const logoSprings = useSpring({
    delay: 500,
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });
  const nameSprings = useSpring({
    delay: 1000,
    from: {
      overflow: 'hidden',
      width: '0px',
      opacity: 0,
    },
    to: {
      overflow: 'hidden',
      width: '180px',
      opacity: 1,
    },
  });
  const dispatch = useTypedDispatch();

  const isLoaded: boolean = useSelector((state: RootState) =>
    get(state.COMMON, 'contentIsLoaded')
  );

  React.useEffect(() => {
    const timeout = setTimeout(() => dispatch(contentIsLoaded()), 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!isLoaded)
    return (
      <animated.div style={containerSprings}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ width: '100vw', height: '100vh' }}
        >
          <Helmet>
            <title>FLOW IDRA</title>
          </Helmet>
          <animated.div style={logoSprings}>
            <Box component="img" src={Images.preLogo} />
          </animated.div>
          <animated.div style={nameSprings}>
            <Box component="img" src={Images.preName} sx={{ ml: 1.5 }} />
          </animated.div>
        </Stack>
      </animated.div>
    );

  return children;
};

export default PreLoading;
