import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';

import CONSTANTS from '@/Constants';
import Utils from '@/Utils';
import dayjs from 'dayjs';

import { Box, Tooltip, IconButton, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ChipStatus, StickyHeadTable } from '@/Components/Common';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { IProjectResponseStructure } from '@/Interfaces/Project.interface';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { IDailyReport } from '@/Interfaces/DailyReport.interface';

const { MODULE_API } = CONSTANTS;
interface ISectionProps {
  meta: IMetaStructure;
  payload: any[];
  onRowAction(Project: any, action: 'edit' | 'remove' | 'report'): void;
  onPageAction(value: number, type: 'limit' | 'page'): void;
  isLoading?: boolean;
}

interface IColumnStructure {
  id:
    | 'no'
    | 'name'
    | 'start_date'
    | 'end_date'
    | 'updated_at'
    | 'status'
    | 'action';
  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'name', label: 'projectName', minWidth: 100 },
  { id: 'start_date', label: 'startDate', minWidth: 80, align: 'center' },
  { id: 'end_date', label: 'endDate', minWidth: 80, align: 'center' },
  { id: 'updated_at', label: 'updatedAt', minWidth: 100, align: 'center' },
  { id: 'status', label: 'status', minWidth: 50, align: 'center' },
  { id: 'action', label: 'action', minWidth: 50, align: 'center' },
];

const createDataTable = (
  no: string,
  name: JSX.Element,
  start_date: string,
  end_date: string,
  updated_at: string,
  status: JSX.Element,
  action: JSX.Element
): any => {
  return {
    no,
    name,
    start_date,
    end_date,
    updated_at,
    status,
    action,
  };
};

const ProjectListDataTable: React.FC<ISectionProps> = ({
  payload,
  meta,
  onPageAction,
  onRowAction,
  isLoading = false,
}) => {
  const t = useTranslation();
  const [rows, setRows] = useState<any[]>([]);

  const isAcceptViewDetails = Utils.isValidPermission(
    MODULE_API.PROJECT.GET_PROJECT_BY_ID
  );
  const isAcceptDelete = Utils.isValidPermission(
    MODULE_API.PROJECT.DELETE_PROJECT
  );
  const isAcceptReport = Utils.isValidPermission(
    MODULE_API.DAILY_REPORT.CREATE
  );

  React.useEffect(() => {
    const resolveRows: any = [];
    if (!isEmpty(payload)) {
      forEach(payload, (item: IProjectResponseStructure, index: number) => {
        const {
          name,
          status,
          updatedAt,
          startDate,
          endDate,
          isWorkingCurrently,
          dailyReport,
        } = item;

        const updatedTime = dayjs(updatedAt);
        const startTime = dayjs(startDate);
        const endTime = endDate ? dayjs(endDate) : dayjs();

        const currentPage = get(meta, 'currentPage');
        const limit = get(meta, 'itemsPerPage');
        const resolveRowIndex = index + 1 + (currentPage - 1) * limit;

        const resolveEndTime = endTime.isValid()
          ? endTime.format('DD-MM-YYYY')
          : 'N/I';

        const currentDate = dayjs().format('YYYY-MM-DD');
        const isReported = dailyReport.find(
          (report: IDailyReport) =>
            dayjs(report.date).format('YYYY-MM-DD') === currentDate
        );

        resolveRows.push(
          createDataTable(
            `${resolveRowIndex}`,
            <Typography
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                height: 1,
                maxWidth: { xs: 300, md: 400, lg: 500, xl: '90%' },
              }}
            >
              {name}
            </Typography>,
            startTime.isValid() ? startTime.format('DD-MM-YYYY') : 'N/I',
            isWorkingCurrently ? t('table.noInformation') : resolveEndTime,
            updatedTime ? updatedTime.format('HH:mm, DD-MM-YYYY') : 'N/I',
            <ChipStatus label={status} />,
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <Tooltip title={t('tooltip.clickToEditProject')}>
                <span>
                  <IconButton
                    onClick={() =>
                      isAcceptViewDetails && onRowAction(item, 'edit')
                    }
                    disabled={!isAcceptViewDetails}
                  >
                    <EditOutlinedIcon fontSize="medium" />
                  </IconButton>
                </span>
              </Tooltip>
              {isAcceptReport ? (
                <Tooltip title={t('tooltip.clickToSubmitReport')}>
                  <span>
                    <IconButton onClick={() => onRowAction(item, 'report')}>
                      {isReported ? (
                        <EventAvailableIcon fontSize="small" color="success" />
                      ) : (
                        <EventNoteOutlinedIcon fontSize="small" />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              ) : null}

              {isAcceptDelete && (
                <Tooltip title={t('tooltip.clickToDeleteProject')}>
                  <IconButton onClick={() => onRowAction(item, 'remove')}>
                    <DeleteOutlineOutlinedIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      onAction={onPageAction}
      paginate={meta}
      isLoading={isLoading}
    />
  );
};

export default ProjectListDataTable;
