import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang';
import * as io from 'socket.io-client';
import { useSelector } from 'react-redux';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { RootState, useTypedDispatch } from '@/store';
import { FoldersAction, MediaActions } from '@/Actions';
import Utils from '@/Utils';
import { Alert } from '@/Widgets';
import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import Constants from '@/Constants';

import FolderDetails from '../../../Components/Popup/FolderDetails';
import {
  BreadcrumbSkeleton,
  ChildFolderSkeleton,
} from '../../../Components/Common/Skeleton';

import FolderList from './RootFolder';
import FileList from './FileList';
import ChildFolder from './ChildFolder';
import PreviewImages from './PreviewImages';

const { ROUTERS } = Constants;
const { fetchRootFolders, fetchChildFolders } = FoldersAction;
const { fetchMedia, uploadMedia } = MediaActions;

interface IFolder {
  id: string;
  createdAt: Date;
  deleteAt: Date;
  name: string;
  updatedAt: Date;
  parentId: string;
}

interface IFilterStructure {
  folderId: string;
  keyword: string;
  page: number;
  limit: number;
}

const DEFAULT_FILTER = {
  folderId: '',
  keyword: '',
  page: 1,
  limit: 10,
};

const MediaManagement = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const rootFolders: IFolder[] = useSelector((state: RootState) => {
    return _.get(state.FOLDERS, 'root');
  });
  const folderIsLoading: boolean = useSelector((state: RootState) => {
    return _.get(state.FOLDERS, 'requestIsLoading');
  });
  const childFolders: IFolder[] = useSelector((state: RootState) => {
    return _.get(state.FOLDERS, 'child');
  });
  const media: any[] = useSelector((state: RootState) => {
    return _.get(state.MEDIA, 'payload');
  });
  const fileIsLoading: boolean = useSelector((state: RootState) => {
    return _.get(state.MEDIA, 'requestIsLoading');
  });

  const [filterParams, setFilterParams] =
    useState<IFilterStructure>(DEFAULT_FILTER);
  const [selectedFolderId, setSelectedFolderId] = React.useState<string>('');
  const selectedFolderRef = React.useRef<any>(null);
  selectedFolderRef.current = selectedFolderId;

  const [contentLoaded, setContentLoaded] = React.useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = React.useState<IFolder[]>([]);
  const [isShowFolderPopup, setIsShowFolderPopup] =
    React.useState<boolean>(false);
  const [filesUploading, setFilesUploading] = React.useState<File[]>([]);
  const [filesUploaded, setFilesUploaded] = React.useState<string[]>([]);
  const [filesUploadFail, setFilesUploadFail] = React.useState<string[]>([]);
  const [previewImages, setPreviewImage] = React.useState<string>('');
  const [isCollapseUploadProcess, setIsCollapseUploadProcess] =
    React.useState<boolean>(false);
  const [isHideCollapseUploadProcess, setIsHideCollapseUploadProcess] =
    React.useState<boolean>(true);

  const isAcceptFetchFile = Utils.isValidPermission(
    ModuleApiConstant.FILE.FETCH_FILES
  );
  const isAcceptFetchFolder = Utils.isValidPermission(
    ModuleApiConstant.FOLDER.FETCH_FOLDERS
  );

  const fetchPayload = (filterPayload: IFilterStructure) => {
    const resolveFilter = Utils.validateFilters(filterPayload);
    dispatch(fetchMedia(resolveFilter));
    dispatch(fetchChildFolders(filterPayload.folderId));
  };

  React.useEffect(() => {
    if (filesUploading.length > 0) {
      const socket = io.connect(import.meta.env.VITE_BE_URL);
      socket.on('uploadSuccess', (data: any) => {
        setFilesUploaded((prev) => [...prev, data]);
      });
      socket.on('uploadFail', (data: any) => {
        setFilesUploadFail((prev) => [...prev, data]);
      });
    }
  }, [filesUploading]);

  React.useEffect(() => {
    if (!isAcceptFetchFolder || !isAcceptFetchFile) {
      Utils.redirect(ROUTERS.DASHBOARD);
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForFetchFile'),
      });
    } else {
      dispatch(fetchRootFolders());
    }
  }, []);

  React.useEffect(() => {
    if (selectedFolderId) {
      const newSelectedFolders = _.map(selectedFolder, (sFolder: any) => {
        const findInRoot = _.find(rootFolders, ['id', _.get(sFolder, 'id')]);
        if (findInRoot) return findInRoot;
        else return sFolder;
      });
      setSelectedFolder(newSelectedFolders);
    }
  }, [rootFolders]);

  // React.useEffect(() => {
  //   if (folderRequestIsSuccess && !contentLoaded) setContentLoaded(true);
  // }, [folderRequestIsSuccess]);

  const onUpload = async (files: File[]) => {
    if (files.length > 0 && selectedFolderRef.current) {
      const formData = new FormData();
      setFilesUploading(files);
      setIsHideCollapseUploadProcess(false);
      formData.append('folderId', selectedFolderRef.current);
      for (const file of files) {
        formData.append('files', file);
      }
      dispatch(uploadMedia(formData));
    }
  };

  const onActiveFolder = (id: string) => {
    const newFilter = {
      ...filterParams,
      folderId: id,
    };
    const findFolder = _.find(
      rootFolders,
      (folder: IFolder) => folder.id === id
    );
    if (findFolder) setSelectedFolder([findFolder]);
    setFilterParams(newFilter);
    fetchPayload(newFilter);
    setSelectedFolderId(id);
  };

  const onSelectChildFolder = (id: string) => {
    const newFilter = {
      ...filterParams,
      folderId: id,
    };
    const findFolder = _.find(
      childFolders,
      (folder: IFolder) => folder.id === id
    );
    if (findFolder) setSelectedFolder([...selectedFolder, findFolder]);
    setFilterParams(newFilter);
    fetchPayload(newFilter);
    setSelectedFolderId(id);
  };

  const onClickBreadcrumb = (id: string) => {
    const newFilter = {
      ...filterParams,
      folderId: id,
    };
    const resolveNewBreadcrumb = [];
    for (const folder of selectedFolder) {
      resolveNewBreadcrumb.push(folder);
      if (folder.id === id) break;
    }
    setSelectedFolder(resolveNewBreadcrumb);
    setFilterParams(newFilter);
    fetchPayload(newFilter);
    setSelectedFolderId(id);
  };

  const onHideUploadProcess = async () => {
    setFilesUploadFail([]);
    setFilesUploaded([]);
    setFilesUploading([]);
    setIsHideCollapseUploadProcess(true);
  };

  const _renderTopSection = () => (
    <Typography variant="h2"> {t('title.files')}</Typography>
  );

  const _renderBreadCrumb = () => {
    const breadcrumb = _.map(
      selectedFolder,
      (folder: IFolder, index: number) => {
        const isLastItem = selectedFolder.length - 1 === index;
        return (
          <React.Fragment key={`breadcrumb-${folder.id}`}>
            {index > 0 ? <Typography sx={{ m: '0 5px' }}>/</Typography> : null}
            <Stack
              sx={{
                fontSize: '15px',
                fontWeight: '500',
                opacity: isLastItem ? 0.6 : 1,
                ':hover': {
                  cursor: isLastItem ? 'not-allowed' : 'pointer',
                  textDecoration: isLastItem ? 'unset' : 'underline',
                },
              }}
              onClick={() => !isLastItem && onClickBreadcrumb(folder.id)}
            >
              {folder.name}
            </Stack>
          </React.Fragment>
        );
      }
    );

    return <Stack direction="row">{breadcrumb}</Stack>;
  };

  const _renderPopup = () => {
    const parentFolder =
      selectedFolder.length > 0
        ? selectedFolder[selectedFolder.length - 1]
        : null;
    return (
      <FolderDetails
        open={isShowFolderPopup}
        onClose={() => setIsShowFolderPopup(false)}
        payload={null}
        parent={parentFolder}
      />
    );
  };

  const _renderRootFolder = () => (
    <Stack
      direction="column"
      sx={{
        position: 'sticky',
        top: '12px',
        alignItems: 'space-between',
        width: '100%',
        maxWidth: '350px',
        marginRight: '12px',
        minHeight: '200px',
        height: 'max-content',
        padding: '0.2rem',
        border: 'solid #e2e4e9 1px',
        borderRadius: '5px',
      }}
      onLoad={() => setContentLoaded(true)}
    >
      <FolderList
        payload={rootFolders}
        active={selectedFolderId}
        onActive={(id: string) => onActiveFolder(id)}
      />
    </Stack>
  );

  const _renderFilesAndFolders = () => {
    if (selectedFolder.length === 0)
      return <Stack>{t('tooltip.selectFolderToContinue')}</Stack>;

    return (
      <Stack
        direction="column"
        flex={1}
        sx={{
          padding: '1rem',
          border: 'solid #e2e4e9 1px',
          borderRadius: '5px',
        }}
      >
        {folderIsLoading && !contentLoaded ? (
          <BreadcrumbSkeleton />
        ) : (
          _renderBreadCrumb()
        )}
        <Divider />
        {folderIsLoading ? (
          <ChildFolderSkeleton />
        ) : (
          <ChildFolder
            payload={childFolders}
            onSelect={(id: string) => onSelectChildFolder(id)}
            onCreate={() => setIsShowFolderPopup(true)}
          />
        )}
        {fileIsLoading ? (
          <ChildFolderSkeleton />
        ) : (
          <FileList
            payload={media}
            onUpload={onUpload}
            folderId={selectedFolderRef.current}
            onPreviewImage={(path: string) => setPreviewImage(path)}
          />
        )}
      </Stack>
    );
  };

  const _renderBottomSection = () => {
    return (
      <Stack direction="row">
        {_renderRootFolder()}
        {_renderFilesAndFolders()}
      </Stack>
    );
  };

  const _renderUploadProcess = () => {
    const collapseHeight = isCollapseUploadProcess ? '45px' : '200px';
    const resolveHeight = isHideCollapseUploadProcess ? '0px' : collapseHeight;
    const uploadSuccessLength = filesUploaded.length;
    const uploadFailLength = filesUploadFail.length;
    const uploadingLength = filesUploading.length;
    const disabledClosePopup =
      uploadingLength > uploadSuccessLength + uploadFailLength;
    return (
      <Stack
        direction="column"
        sx={{
          position: 'absolute',
          bottom: 50,
          right: 20,
          background: 'white',
          width: '300px',
          borderRadius: '5px',
          overflow: 'hidden',
          boxShadow: '0 7px 25px rgba(0,0,0,0.08)',
          height: resolveHeight,
          transition: 'all ease 0.25s',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ background: '#ccc', padding: '10px', height: '45px' }}
        >
          <Typography sx={{ fontSize: '15px' }}>
            {t('tooltip.filesIsUploading')}
          </Typography>
          <Stack direction="row" alignItems="center">
            <IconButton
              onClick={() =>
                setIsCollapseUploadProcess(!isCollapseUploadProcess)
              }
            >
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  transform: `rotate(${
                    isCollapseUploadProcess ? '180deg' : '0deg'
                  })`,
                }}
              />
            </IconButton>
            <IconButton
              disabled={disabledClosePopup}
              onClick={() => !disabledClosePopup && onHideUploadProcess()}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack direction="column" sx={{ overflow: 'auto' }}>
          {_.map(filesUploading, (file: File, index: number) => {
            const isUploadFail = filesUploadFail.includes(file.name);
            const isUploadSuccess = filesUploaded.includes(file.name);
            const isUploading = !isUploadFail && !isUploadSuccess;

            return (
              <React.Fragment key={`process-${file.name}`}>
                {index > 0 ? <Divider /> : null}
                <Stack
                  key={`file-${file.name}`}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ height: '38px', padding: '10px' }}
                >
                  <Stack direction="row" alignItems="center">
                    <InsertDriveFileOutlinedIcon />
                    <Typography
                      title={file.name}
                      sx={{
                        fontSize: '13px',
                        ml: 0.5,
                        maxWidth: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {file.name}
                    </Typography>
                  </Stack>
                  <Box sx={{ display: 'flex' }}>
                    {isUploading ? (
                      <CircularProgress size={18} color="info" />
                    ) : null}
                    {isUploadSuccess ? (
                      <CheckCircleOutlineOutlinedIcon color="success" />
                    ) : null}
                    {isUploadFail ? (
                      <ErrorOutlineOutlinedIcon color="error" />
                    ) : null}
                  </Box>
                </Stack>
              </React.Fragment>
            );
          })}
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      {_renderPopup()}
      {_renderTopSection()}
      {_renderBottomSection()}
      {_renderUploadProcess()}
      {previewImages ? (
        <PreviewImages
          payload={media}
          selectedImage={previewImages}
          onClose={() => setPreviewImage('')}
        />
      ) : null}
    </>
  );
};

export default MediaManagement;
