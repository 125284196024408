import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@utils';
import { IMetaStructure } from '@/Interfaces/Common.interface';

import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  IAssign,
  ICreateFeedback,
  ICreateTheards,
  IFeedbackFilter,
  IUpdateFeedback,
  IUpdateStatus,
  IUpdateTheards,
} from '@/Interfaces/Feedback.interface';

const { ACTION_TYPES, ROUTERS } = CONSTANTS;

// SINGLE_ACTIONS

const clearFeedback = () => {
  return {
    type: ACTION_TYPES.CLEAR_FEEDBACK,
  };
};

const setMetaPagination = (payload: IMetaStructure) => {
  return {
    type: ACTION_TYPES.SET_META_FEEDBACK,
    payload,
  };
};

const FeedbacksRequest = () => {
  return {
    type: ACTION_TYPES.FEEDBACK_IS_REQUEST,
  };
};

const fetchFeedbacksSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_FEEDBACK_SUCCESS,
    payload,
  };
};

const fetchFeedbacksFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_FEEDBACK_FAILURE,
  };
};

const fetchFeedbacks = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(FeedbacksRequest());
    await API.fetchFeedbacks(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFeedbacksFailure());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchFeedbacksSuccess(items));
          dispatch(setMetaPagination(meta));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchFeedbacksFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createFeedbacksSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_ANNOUNCEMENT_SUCCESS,
  };
};

const createFeedbacksFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_ANNOUNCEMENT_FAILURE,
  };
};

const createFeedbacks = (payload: ICreateFeedback) => {
  return async (dispatch: any) => {
    await API.createFeedbacks(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createFeedbacksFailure());
        } else {
          dispatch(createFeedbacksSuccess());
          Utils.redirect(ROUTERS.FEEDBACK);
          toast.success(message);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFeedbacksFailure());
      });
  };
};

const updateFeedbacksSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_FEEDBACK_SUCCESS,
    payload,
  };
};

const updateFeedbacksFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_FEEDBACK_FAILURE,
  };
};

const updateFeedbacks = (payload: IUpdateFeedback) => {
  return async (dispatch: any) => {
    dispatch(FeedbacksRequest());
    await API.updateFeedbacks(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateFeedbacksFailure());
          toast.error(message);
        } else {
          dispatch(updateFeedbacksSuccess(result));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(updateFeedbacksFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const getFeedbackByIdSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_FEEDBACK_BY_ID_SUCCESS,
    payload,
  };
};

const getFeedbackByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_FEEDBACK_BY_ID_FAILURE,
  };
};

const getFeedbackById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(FeedbacksRequest());
    await API.getFeedbackById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getFeedbackByIdFail());
        else {
          dispatch(getFeedbackByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getFeedbackByIdFail());
      });
  };
};

const getAllForUserFeedbacksSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_ALL_FOR_USER_FEEDBACKS_SUCCESS,
    payload,
  };
};

const getAllForUserFeedbacksFailure = () => {
  return {
    type: ACTION_TYPES.GET_ALL_FOR_USER_FEEDBACKS_FAILURE,
  };
};

const getAllForUserFeedbacks = (payload: IFeedbackFilter) => {
  return async (dispatch: any) => {
    dispatch(FeedbacksRequest());
    await API.getAllForUserFeedbacks(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getAllForUserFeedbacksFailure());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(getAllForUserFeedbacksSuccess(items));
          dispatch(setMetaPagination(meta));
        }
      })
      .catch(async (error: any) => {
        await dispatch(getAllForUserFeedbacksFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createThreadsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.CREATE_THREAD_SUCCESS,
    payload,
  };
};

const createThreadsFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_THREAD_FAILURE,
  };
};

const createThreads = (payload: ICreateTheards) => {
  return async (dispatch: any) => {
    await API.createThreads(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createThreadsFailure());
          toast.error(message);
        } else {
          dispatch(createThreadsSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await dispatch(createThreadsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const deleteThreadSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.DELETE_THREAD_SUCCESS,
    payload,
  };
};

const deleteThreadFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_THREAD_FAILURE,
  };
};

const deleteThread = (id: string) => {
  return async (dispatch: any) => {
    await API.deleteThread(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteThreadFailure());
          toast.error(message);
        } else {
          dispatch(deleteThreadSuccess(result));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(deleteThreadFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const updateThreadSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_THREAD_SUCCESS,
    payload,
  };
};

const updateThreadFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_THREAD_FAILURE,
  };
};

const updateThread = (payload: IUpdateTheards) => {
  return async (dispatch: any) => {
    await API.updateThread(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateThreadFailure());
          toast.error(message);
        } else {
          dispatch(updateThreadSuccess(result));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(updateThreadFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const assignHandlerSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.ASSIGN_HANDLER_SUCCESS,
    payload,
  };
};

const assignHandlerFailure = () => {
  return {
    type: ACTION_TYPES.ASSIGN_HANDLER_FAILURE,
  };
};

const assignHandler = (id: string, payload: IAssign) => {
  return async (dispatch: any) => {
    await API.assignHandler(id, payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(assignHandlerFailure());
          toast.error(message);
        } else {
          dispatch(assignHandlerSuccess(result));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(assignHandlerFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const updateStatusSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_STATUS_SUCCESS,
    payload,
  };
};

const updateStatusFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_STATUS_FAILURE,
  };
};

const updateStatus = (payload: IUpdateStatus) => {
  return async (dispatch: any) => {
    dispatch(FeedbacksRequest());
    await API.updateStatus(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateStatusFailure());
          toast.error(message);
        } else {
          dispatch(updateStatusSuccess(result));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(updateStatusFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const fetchFeedbacksDashboardSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_FEEDBACK_DASHBOARD_SUCCESS,
    payload,
  };
};

const fetchFeedbacksDashboardStatusFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_FEEDBACK_DASHBOARD_FAILURE,
  };
};

const fetchFeedbacksDashboard = () => {
  return async (dispatch: any) => {
    dispatch(FeedbacksRequest());
    await API.fetchFeedbacksDashboard()
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFeedbacksDashboardStatusFailure());
        else {
          dispatch(fetchFeedbacksDashboardSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchFeedbacksDashboardStatusFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  fetchFeedbacks,
  createFeedbacks,
  clearFeedback,
  getFeedbackById,
  getAllForUserFeedbacks,
  createThreads,
  deleteThread,
  updateThread,
  assignHandler,
  updateFeedbacks,
  updateStatus,
  fetchFeedbacksDashboard,
};
