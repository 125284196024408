import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';

const { MEDIA } = CONSTANTS.API;

export const fetchMedia = async (payload: any) => {
  return sendRequest(MEDIA.BASIC, 'GET', payload);
};

export const downloadFiles = async (payload: any) => {
  return sendRequest(MEDIA.DOWNLOAD, 'POST_BLOB', payload);
};

export const uploadMedia = async (payload: any) => {
  return sendRequest(MEDIA.BASIC, 'POST_FORM_DATA', payload);
};
export const deleteMedia = async (id: string) => {
  return sendRequest(`${MEDIA.BASIC}/${id}`, 'DEL');
};
