import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';

const { PORTFOLIO } = CONSTANTS.API;
const { NewsStatus } = CONSTANTS.ENUMS;

export const fetchPorfolios = async (payload: any) => {
  return sendRequest(PORTFOLIO.BASIC, 'GET', payload);
};

export const getPorfolioById = async (id: string) => {
  return sendRequest(`${PORTFOLIO.BASIC}/get-by-id/${id}`, 'GET');
};

export const createPorfolio = async (payload: any) => {
  return sendRequest(PORTFOLIO.BASIC, 'POST', payload);
};

export const updatePorfolio = async (payload: any, id: string) => {
  return sendRequest(`${PORTFOLIO.BASIC}/${id}`, 'PUT', payload);
};

export const updateStatusPorfolio = async (status: string, id: string) => {
  const resolvePath = (() => {
    switch (status) {
      case NewsStatus.ACTIVE:
        return PORTFOLIO.ACTIVATE;
      case NewsStatus.INACTIVE:
        return PORTFOLIO.DEACTIVATE;
      case NewsStatus.PUBLISH:
        return PORTFOLIO.PUBLISH;
      default:
        break;
    }
    return '';
  })();
  return sendRequest(`${resolvePath}/${id}`, 'PUT');
};

export const getPortfolioForDashboard = async (payload: any) => {
  return sendRequest(PORTFOLIO.DASHBOARD, 'GET', payload);
};
