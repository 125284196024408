import ROUTERS from './Constants/Routers.constant';
import API from './Constants/API.constant';
import ACTION_TYPES from './Constants/ActionTypes.constant';
import COOKIE_KEYS from './Constants/CookieKeys.constant';
import LOCALE from './Constants/Locale.constant';
import LIST from './Lists';
import PERFORMANCE_REVIEW from './Constants/PerformanceReview.contant';
import COLUMNS from './Constants/Columns.constant';
import MODULE_API from './Constants/ModuleApi.constant';
import * as ENUMS from './Enums';
import TECH_LIST from './Constants/Tech.constant';
import PORTFOLIO_CAT from './Constants/Portfolio.constant';

export default {
  ROUTERS,
  ACTION_TYPES,
  COOKIE_KEYS,
  LOCALE,
  API,
  LIST,
  COLUMNS,
  ENUMS,
  PERFORMANCE_REVIEW,
  MODULE_API,
  TECH_LIST,
  PORTFOLIO_CAT,
};
