import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 1,
  itemsPerPage: 10,
  totalItems: 1,
  totalPages: 1,
};

const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  updateDocumentationSuccess: false,
  details: {},
  forUser: null,
  allForUsers: null,
  meta: DEFAULT_META,
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.CLEAR_ANNOUNCEMENT_USER:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        forUser: null,
        allForUsers: null,
        details: {},
      };
    case ACTION_TYPES.ANNOUNCEMENT_IS_REQUEST_USER:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.SET_META_ANNOUNCEMENT_USER:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.GET_FOR_USER_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        forUser: payload,
      };
    case ACTION_TYPES.GET_FOR_USER_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.GET_ALL_FOR_USER_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        allForUser: payload,
      };
    case ACTION_TYPES.GET_ALL_FOR_USER_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.UPDATE_READ_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.UPDATE_READ_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    default:
      return state;
  }
};
