import React, { useEffect } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-multi-lang';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import CONSTANTS from '@/Constants';
import { Dropdown, RoundButton } from '@/Components/Common';
import { IAnnouncementFilter } from '@/Interfaces/Project.interface';
import Utils from '@/Utils';
import { AnnouncementDataTable } from '@/Components/LayoutPart/DataTable';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { RootState, useTypedDispatch } from '@/store';
import { IAnnouncementStructure } from '@/Interfaces/Announcement.interface';
import { AnnouncementAction } from '@/Actions';
import Alert from '@/Widgets/Alert';

const { fetchAnnouncements, updateTypeAnnouncements, deleteAnnouncements } =
  AnnouncementAction;
const { ROUTERS, ENUMS, MODULE_API } = CONSTANTS;

const initialFilter: IAnnouncementFilter = {
  type: ENUMS.Type.ALL_TYPE,
  keyword: '',
  page: 1,
  limit: 10,
};

const initialStatusOptions = [
  { labelEN: 'All type', labelVI: 'Tất cả', value: 'allType' },
  { labelEN: 'Show', labelVI: 'Hoạt động', value: 'show' },
  { labelEN: 'Hide', labelVI: 'Không hoạt động', value: 'hide' },
];

const CreateAnnouncement: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const language = Utils.getSavedLocale();
  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: initialFilter,
  });
  const meta: IMetaStructure = useSelector((state: RootState) =>
    get(state.ANNOUNCEMENT, 'meta')
  );
  const payload: any = useSelector((state: RootState) =>
    get(state.ANNOUNCEMENT, 'payload')
  );
  const isLoading: any = useSelector((state: RootState) =>
    get(state.ANNOUNCEMENT, 'requestIsLoading')
  );
  const isAcceptCreatedAnnouncement = Utils.isValidPermission(
    MODULE_API.ANNOUNCEMENT.CREATE_ANNOUNCEMENT
  );

  const fetchPayload = (filterParams: any) => {
    const resolverFilter = Utils.validateFilters(filterParams);
    dispatch(fetchAnnouncements(resolverFilter));
  };

  useEffect(() => {
    fetchPayload(initialFilter);
  }, []);

  const statusOptions = React.useMemo(() => {
    const resolveOptions: any[] = [];
    const isEnglish = language === 'en';
    for (const option of initialStatusOptions) {
      const resolveLabel = isEnglish ? option.labelEN : option.labelVI;
      resolveOptions.push({
        label: resolveLabel,
        value: option.value,
      });
    }
    return resolveOptions;
  }, [initialStatusOptions, language]);

  const onReset = () => {
    reset();
    fetchPayload(initialFilter);
  };

  const onSearch = (data: IAnnouncementFilter) => {
    fetchPayload(data);
  };

  const onRowAction = async (
    announcement: IAnnouncementStructure,
    action: 'edit' | 'remove' | 'report'
  ) => {
    const { id } = announcement;
    const currentFilter = getValues();
    const resolveFilter = Utils.validateFilters(currentFilter);
    if (action === 'edit')
      Utils.redirect({ pathname: ROUTERS.ANNOUNCEMENT_DETAILS, id });
    if (action === 'report') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningChangeStatusAnnouncement'),
      });
      if (isAgree === 'ok') {
        if (announcement.type === 'hide')
          dispatch(updateTypeAnnouncements(id, 'show', resolveFilter));
        else dispatch(updateTypeAnnouncements(id, 'hide', resolveFilter));
      }
    }
    if (action === 'remove') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningDeleteAnnouncement'),
      });
      if (isAgree === 'ok') {
        const currentPage = getValues('page');
        if (payload.length === 1 && currentPage !== 1) {
          setValue('page', 1);
          resolveFilter.page = 1;
        }
        await dispatch(deleteAnnouncements(id, resolveFilter));
      }
    }
  };

  const onPageAction = async (value: number, type: 'limit' | 'page') => {
    const currentFilter = getValues();
    const newFilter = {
      ...currentFilter,
      [type]: value,
    };
    setValue(type, value);
    if (type === 'limit') {
      setValue('page', 1);
      Object.assign(newFilter, { page: 1 });
    }
    fetchPayload(newFilter);
  };

  const _renderTopSection = () => (
    <Stack>
      <Typography variant="h2" mb={2}>
        {t('title.announcement')}
      </Typography>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row">
          <Controller
            name="keyword"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                size="small"
                onChange={(e: any) => field.onChange(e.target.value)}
                placeholder={t('placeholder.keyword')}
                sx={{ mr: 1, width: '250px' }}
              />
            )}
          />
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <Dropdown
                value={field.value}
                sx={{ mr: 1 }}
                options={statusOptions}
                onChange={(e: any) => field.onChange(e.target.value)}
              />
            )}
          />
          <Button
            sx={{ mr: 1 }}
            onClick={handleSubmit(onSearch)}
            variant="contained"
          >
            {t('button.filter')}
          </Button>
          <Button onClick={() => onReset()} variant="outlined">
            {t('button.reset')}
          </Button>
        </Stack>
        {isAcceptCreatedAnnouncement && (
          <RoundButton
            type="contained"
            size="large"
            label={t('button.create')}
            onClick={() => Utils.redirect(ROUTERS.ANNOUNCEMENT_CREATE)}
          />
        )}
      </Stack>
    </Stack>
  );

  const _renderbodySection = () => {
    return (
      <Stack sx={{ mt: 2 }}>
        <AnnouncementDataTable
          meta={meta}
          payload={payload}
          onRowAction={onRowAction}
          onPageAction={onPageAction}
          isLoading={isLoading}
        />
      </Stack>
    );
  };

  return (
    <>
      {_renderTopSection()}
      {_renderbodySection()}
    </>
  );
};

export default CreateAnnouncement;
