import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
import { IEvent } from '../Interfaces/Event.interface';

const { EVENT } = CONSTANTS.API;

export const fetchEvents = async (payload?: { year: number }) => {
  return sendRequest(EVENT.BASIC, 'GET', payload);
};

export const fetchNextEvents = async (payload: { day: number }) => {
  return sendRequest(`${EVENT.NEXT_EVENTS}/${payload.day}`, 'GET');
};

export const createEvent = async (payload: Omit<IEvent, 'id'>) => {
  return sendRequest(EVENT.BASIC, 'POST', payload);
};

export const updateEvent = async (payload: IEvent) => {
  const { id } = payload;
  return sendRequest(`${EVENT.BASIC}/${id}`, 'PUT', payload);
};

export const deleteEvent = async (id: string) => {
  return sendRequest(`${EVENT.BASIC}/${id}`, 'DEL');
};
