import _ from 'lodash';
import jwt_decode from 'jwt-decode';
import CONSTANTS from '@/Constants';
import { history } from '@/store';
import { Alert, Loading } from '@widgets';
import forEach from 'lodash/forEach';
import dayjs from 'dayjs';
import CryptoJS from 'crypto-js';
import { getSavedUserData } from './Cookie.utils';
import { IUserDetailsStructure } from '../Interfaces/User.interface';
// import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';

interface ILocationStructure {
  pathname: string;
  id?: string;
  search?: object;
}

// Declare constants
const { ROUTERS, ENUMS } = CONSTANTS;

// declare avaiable screens
// const AVAIABLE_ROUTES = [ROUTER.INITIALAZATION, ROUTER.HOME];

// Check network connection
const checkNetworkConnection = () => {
  return navigator.onLine;
};

// Redirect screen
const detectLocationBeforeRedirect = (location: string) => {
  // const userRole: ROLE.ADMIN | ROLE.EVENT_ADMIN | ROLE.USER = getUserRole();
  // if (userRole) {
  //   const allowLocation = LOCATIONS[userRole];
  //   if (!_.includes(allowLocation, location)) {
  //     Alert({
  //       type: 'ERROR',
  //       message:
  //         "Your account doesn't have permission to view or manage this page",
  //     });
  //     return { isValid: false, redirectLocation: REDIRECT[userRole] };
  //   }
  // }
  return { isValid: true, redirectLocation: location };
};

const redirect = (location: string | ILocationStructure, state?: any) => {
  let findLocation;
  let newLocation;
  if (_.isString(location)) {
    const resolveLocation: string = location as string;
    findLocation = _.find(
      ROUTERS,
      (route: string) => route === resolveLocation
    );
    newLocation = location;
  } else {
    const resolveLocation: ILocationStructure = location as ILocationStructure;
    const { pathname, id, search } = resolveLocation;
    findLocation = _.find(ROUTERS, (route: string) => route === pathname);
    newLocation = _.replace(pathname, ':id', id);
    if (search) {
      const generateSearch: string[] = [];
      forEach(search, (value: string, key: string) =>
        generateSearch.push(`${key}=${value}`)
      );
      const resolveSearch = generateSearch.join('&');
      newLocation += `?${resolveSearch}`;
    }
  }
  if (!findLocation) {
    return Alert({
      type: 'WARNING',
      message: `Invalid page: ${location}`,
    });
  } else {
    const { isValid, redirectLocation } =
      detectLocationBeforeRedirect(findLocation);

    if (!isValid) return history.push(redirectLocation);
    return history.push(newLocation, state);
  }
};

// Check life of token
const checkTokenLifeTime = (token: string | null) => {
  if (!token) {
    Alert({
      type: 'WARNING',
      message: 'Please login to continue...',
    });
    return false;
  }
  const decodedToken: any = jwt_decode(token);
  const dateNow = new Date();
  if (decodedToken.exp < Math.floor(dateNow.getTime() / 1000)) {
    Alert({
      type: 'WARNING',
      message: 'Your token has been expired! ',
    });
    return false;
  }
  return true;
};

// Return app run mode
const checkAppMode = (modeType?: string) => {
  if (modeType) return import.meta.env.MODE === modeType;
  return import.meta.env.MODE;
};

// Wait until all promises have settled (each may resolve to reject)
const multipleRequests = (instants: any[]) => {
  Loading.start();
  Promise.allSettled(instants)
    .then(() => {
      return Loading.stop();
    })
    .catch(() => {
      Loading.stop();
    });
};

const validateFilters = (filters: any) => {
  const resolveFilters: any = {};
  _.forEach(filters, (value: string | number | any[] | Date, key: string) => {
    const isDate = value instanceof Date;
    if (isDate)
      _.assign(resolveFilters, { [key]: dayjs(value).format('YYYY-MM-DD') });
    else if (
      (_.isString(value) &&
        !_.isEmpty(_.trim(value)) &&
        value !== ENUMS.Status.ALL_TYPE) ||
      (_.isArray(value) && !_.isEmpty(value)) ||
      _.isNumber(value)
    ) {
      _.assign(resolveFilters, { [key]: _.trim(value) });
    }
  });
  return resolveFilters;
};

const regexPayload = (
  value: string,
  type: 'email' | 'password' | 'domain' | 'ip' | 'port'
) => {
  const regexEmail = new RegExp(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  );
  const regexContainsWhiteSpace = new RegExp(/^[^\\s]+$/);
  const regexPassword = new RegExp(
    /[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]{1,}$/
  );
  const regexDomain = new RegExp(
    /^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/g
  );
  const regexIP = new RegExp(/^[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+$/g);
  const regexPort = new RegExp(
    /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/g
  );

  if (type === 'email') {
    if (!regexContainsWhiteSpace.test(value)) return true;
    return regexEmail.test(value);
  }
  if (type === 'password') return regexPassword.test(value);

  if (type === 'domain') return regexDomain.test(value);
  if (type === 'ip') return regexIP.test(value);
  if (type === 'port') return regexPort.test(value);

  return true;
};

const decodeSalary = (salary: string) => {
  const base64 = CryptoJS.enc.Base64.parse(salary);
  const words = CryptoJS.enc.Utf8.stringify(base64);
  return words;
};

const getFileFromURL = async (payload: {
  originalName: string;
  path: string;
}) => {
  const resolveLink = `${import.meta.env.VITE_BE_URL}${payload.path}`;
  const blob = await fetch(resolveLink).then((r) => r.blob());
  const file = new File([blob], payload.originalName);
  return file;
};

const convertFileSize = (bytes: number) => {
  if (bytes == 0) return '0.00 B';
  const resolveBytes = Math.floor(Math.log(bytes) / Math.log(1024));

  return (
    (bytes / Math.pow(1024, resolveBytes)).toFixed(2) +
    ' ' +
    ' KMGTP'.charAt(resolveBytes) +
    'B'
  );
};

const isValidPermission = (permission: string) => {
  const userData = getSavedUserData();
  if (userData) {
    const { acceptApi } = userData?.role;
    if (_.get(userData, 'role.name') === 'Admin') return true;
    const isAcceptApi = _.includes(acceptApi, permission);
    return isAcceptApi;
  }
  return false;
};

const generateUnFillPersonalInformation = (payload: IUserDetailsStructure) => {
  let progress = 0;

  if (payload) {
    const { userData } = payload;
    const resolveUserData = _.omit(userData, [
      'id',
      'createdAt',
      'updatedAt',
      'deletedAt',
      'avatar',
      'acquiringKnowledge',
      'documents',
      'lastReviewedDate',
      'salary',
      'skills',
    ]);
    const length = Object.keys(resolveUserData).length;

    const percent = 100 / length;

    for (const key in resolveUserData) {
      if (resolveUserData[key]) progress += percent;
    }
  }

  return Math.round(progress);
};

const toNonAccentVietnamese = (str: string) => {
  str = str.trim().toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
  str = str.replace(/\u02C6|\u0306|\u031B/g, '');
  return str;
};

const createMarkup = (html: any) => {
  return { __html: html };
};

export {
  checkNetworkConnection,
  redirect,
  checkTokenLifeTime,
  checkAppMode,
  multipleRequests,
  validateFilters,
  regexPayload,
  decodeSalary,
  getFileFromURL,
  convertFileSize,
  isValidPermission,
  generateUnFillPersonalInformation,
  toNonAccentVietnamese,
  createMarkup,
};
