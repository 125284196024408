import React from 'react';
import CONSTANTS from '@/Constants';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import { useTranslation } from 'react-multi-lang';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import Utils from '@/Utils';

import { StickyHeadTable } from '@/Components/Common';

import { Avatar, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { IUserDetailsStructure } from '../../../Interfaces/User.interface';

const { MODULE_API } = CONSTANTS;

interface IColumnStructure {
  id:
    | 'no'
    | 'staff_code'
    | 'full_name'
    | 'email'
    | 'position'
    | 'status'
    | 'action';

  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

interface ISectionProps {
  payload: any;
  onRowAction(user: any, action: 'remove'): void;
  isLoading?: boolean;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'staff_code', label: 'staffCode', minWidth: 10 },
  { id: 'full_name', label: 'fullName', minWidth: 200 },
  { id: 'email', label: 'email', minWidth: 100 },
  { id: 'position', label: 'position', minWidth: 100 },
  { id: 'action', label: 'action', minWidth: 50, align: 'center' },
];

const createDataForTable = (
  no: string,
  staff_code: string,
  full_name: JSX.Element,
  email: string,
  position: string,
  action: JSX.Element
): any => {
  return {
    no,
    staff_code,
    full_name,
    position,
    email,
    action,
  };
};

const UsersDataTable: React.FC<ISectionProps> = ({
  payload,
  onRowAction,
  isLoading = false,
}) => {
  const t = useTranslation();
  const [rows, setRows] = React.useState<any[]>([]);

  React.useEffect(() => {
    const isAcceptViewDetails = Utils.isValidPermission(
      MODULE_API.USER.GET_USER_BY_ID
    );
    const isAcceptEdit = Utils.isValidPermission(
      MODULE_API.USER.UPDATE_USER_BY_ID
    );
    const isAllowAction = isAcceptViewDetails || isAcceptEdit;
    const actionTooltip = isAllowAction
      ? t('tooltip.clickToRemoveThisUser')
      : t('tooltip.notAuthorize');

    const resolveRows: any = [];
    if (!isEmpty(payload)) {
      forEach(payload, (item: IUserDetailsStructure, index: number) => {
        const { email, userData, staffCode } = item;
        const resolveRowIndex = index + 1;

        resolveRows.push(
          createDataForTable(
            `${resolveRowIndex}`,
            staffCode?.code || 'N/I',
            <Stack direction="row" alignItems="center">
              <Avatar
                sx={{ width: '30px', height: '30px', mr: 0.5 }}
                src={userData?.avatar?.path}
              >
                {userData?.avatar?.path ? null : userData.fullName[0]}
              </Avatar>
              <Typography sx={{ fontSize: '14px' }}>
                {userData?.fullName || 'N/I'}
              </Typography>
            </Stack>,
            email,
            userData?.position || 'N/I',
            <Tooltip title={actionTooltip}>
              <span>
                <IconButton
                  disabled={!isAllowAction}
                  onClick={() => isAllowAction && onRowAction(item, 'remove')}
                >
                  <DoDisturbOnOutlinedIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable columns={COLUMNS} rows={rows} isLoading={isLoading} />
  );
};

export default UsersDataTable;
