/* eslint-disable indent */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router';
import { useTranslation } from 'react-multi-lang';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography, InputLabel } from '@mui/material';

import {
  RoundedContainer,
  TextField,
  UploadImageInput,
  TextEditor,
} from '@/Components/Common';

import { RootState, useTypedDispatch } from '@/store';
import Constants from '@/Constants';
import Utils from '@/Utils';
import { NewsActions } from '@/Actions';
import { INewsStructure } from '@/Interfaces/News.interface';

const { ROUTERS } = Constants;
const { getPostById, updatePost, setDefaultNewsReducer } = NewsActions;

// eslint-disable-next-line react/function-component-definition
const UpdatePost: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const isLoading: boolean = useSelector((state: RootState) =>
    _.get(state.NEWS, 'requestIsLoading')
  );

  const details: INewsStructure = useSelector((state: RootState) =>
    _.get(state.NEWS, 'details')
  );

  const { id } = useParams();

  const schema = yup
    .object({
      title: yup.string().trim().required(t('message.titleRequired')),
      content: yup.string().trim().required(t('message.contentRequired')),
      location: yup.string().trim().required(t('message.locationRequired')),
      image: yup
        .array()
        .of(
          yup
            .mixed()
            .test(
              'fileType',
              t('message.thumbnailTypeError'),
              function (value) {
                if (!value) return false;
                return value instanceof File;
              }
            )
        )
        .test(
          'atLeastOneFile',
          t('message.thumbnailRequired'),
          function (value) {
            return value && value.length > 0;
          }
        ),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      content: '',
      location: '',
      image: [],
    },
  });

  useEffect(() => {
    if (id) dispatch(getPostById(id));
    return () => {
      dispatch(setDefaultNewsReducer());
    };
  }, [id]);

  const generateDetails = async () => {
    if (!_.isEmpty(details)) {
      setValue('title', details.title);
      setValue('content', details.content ? JSON.parse(details.content) : '');
      setValue('location', details.location);
      const blob = await fetch(details?.image.path).then((r) => r.blob());
      const file = new File([blob], details?.image?.originalName);
      const resolveFile: File[] = [file];
      setValue('image', resolveFile as any);
    }
  };

  useEffect(() => {
    generateDetails();
  }, [details]);

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('content', JSON.stringify(data.content));
    formData.append('location', data.location);
    formData.append('image', !_.isEmpty(data.image) ? data.image[0] : '');
    if (id) dispatch(updatePost(formData, id));
  };

  const _renderTopSection = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Typography fontSize="20px" fontWeight="500">
        {t('title.updatePost')}
      </Typography>
    </Stack>
  );

  const _renderBottomSection = () => (
    <RoundedContainer>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <TextField
              value={field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.value)
              }
              label={t('label.title')}
              placeholder={t('placeholder.titlePost')}
              message={errors.title?.message}
              required
              disabled={isLoading}
            />
          )}
        />
        <Controller
          name="content"
          control={control}
          render={({ field }) => (
            <TextEditor
              value={field.value}
              onChange={(value: any) => field.onChange(value)}
              label={t('label.content')}
              message={errors.content?.message}
              required
              disabled={isLoading}
            />
          )}
        />

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
              {t('label.thumbnail')}{' '}
              <Typography component="span" color="error">
                (*)
              </Typography>
            </InputLabel>
            <Controller
              name="image"
              control={control}
              render={({ field }) => (
                <UploadImageInput
                  label=""
                  files={field.value as any}
                  onFileChange={(file) => field.onChange(file)}
                  type="LOGO"
                  required
                  message={errors.image?.message}
                  disabled={isLoading}
                />
              )}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Controller
              name="location"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    field.onChange(event.target.value)
                  }
                  label={t('label.location')}
                  placeholder={t('placeholder.locationPost')}
                  message={errors.location?.message}
                  multiline
                  rows={3}
                  required
                  disabled={isLoading}
                />
              )}
            />
          </Box>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mt={2}
        >
          <Button
            variant="outlined"
            onClick={() => Utils.redirect(ROUTERS.NEWS_MANAGER)}
          >
            {t('button.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ ml: 1 }}
            loading={isLoading}
          >
            {t('button.save')}
          </LoadingButton>
        </Stack>
      </Box>
    </RoundedContainer>
  );

  const _renderContent = () => (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );

  return <Stack>{_renderContent()}</Stack>;
};

export default UpdatePost;
