import * as React from 'react';
import { useTranslation } from 'react-multi-lang';
// import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Utils from '@/Utils';
import { Alert } from '@/Widgets';
import { FoldersAction } from '@/Actions';

import { TextField } from '@/Components/Common';
import { useTypedDispatch } from '@/store';
import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import { IFolderStructure } from '@/Interfaces/Folders.interface';
import PopupLayout from '../PopupLayout';

interface ISectionProps {
  open: boolean;
  onClose(isFetch?: boolean): void;
  payload: IFolderStructure | null;
  parent: IFolderStructure | null;
}

const { createFolder, updateFolder, clearFolderStatus } = FoldersAction;

const FolderDetails: React.FC<ISectionProps> = ({
  open,
  onClose,
  payload,
  parent,
}) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const isAcceptCreate = Utils.isValidPermission(
    ModuleApiConstant.FOLDER.CREATE_FOLDER
  );
  const isAcceptUpdate = Utils.isValidPermission(
    ModuleApiConstant.FOLDER.UPDATE_FOLDER
  );

  const schema = yup
    .object({
      name: yup.string().required(t('message.folderNameRequired')),
    })
    .required();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  React.useEffect(() => {
    if (open) {
      if (payload && !isAcceptUpdate) {
        onClose();
        Alert({
          type: 'ERROR',
          message: t('popup.notAuthorizeForEditFolder'),
        });
      } else if (!payload && !isAcceptCreate) {
        onClose();
        Alert({
          type: 'ERROR',
          message: t('popup.notAuthorizeForCreateFolder'),
        });
      } else if (payload) setValue('name', payload?.name);
    } else reset();
  }, [open]);

  // React.useEffect(() => {
  //   if (isRequestSuccess && open) {
  //     dispatch(fetchRootFolders());
  //     handleClose();
  //   }
  // }, [isRequestSuccess]);

  const handleClose = () => {
    dispatch(clearFolderStatus());
    onClose();
  };

  const onSubmit = (data: { name: string }) => {
    const resolvePayload: any = {};
    if (parent) Object.assign(resolvePayload, { parentId: parent.id });

    if (payload) {
      Object.assign(resolvePayload, { ...payload, ...data });
      dispatch(updateFolder(resolvePayload));
      handleClose();
    } else {
      Object.assign(resolvePayload, { ...data });
      dispatch(createFolder(resolvePayload));
      handleClose();
    }
  };

  const _renderForm = () => (
    <>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            label={t('label.name')}
            placeholder="Image"
            value={field.value}
            message={errors.name?.message}
            onChange={(e: any) => field.onChange(e)}
            required
          />
        )}
      />
      <Stack direction="row" sx={{ mt: '20px', justifyContent: 'flex-end' }}>
        <LoadingButton
          variant="outlined"
          onClick={() => handleClose()}
          sx={{ mr: 1 }}
        >
          {t('button.cancel')}
        </LoadingButton>
        <LoadingButton variant="contained" onClick={handleSubmit(onSubmit)}>
          {t('button.save')}
        </LoadingButton>
      </Stack>
    </>
  );

  return (
    <PopupLayout
      title={payload?.id ? t('title.editFolder') : t('title.createFolder')}
      content={_renderForm()}
      open={open}
      onClose={onClose}
    />
  );
};

export default FolderDetails;
