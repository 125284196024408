// import Alert from '../alert';
// import Notification from '../Notification';

import Utils from '.';
import Constants from '../Constants';
import { Alert } from '../Widgets';

const AUTHORISED_ERROR = [401];
const INTERAL_SERVER_ERROR = [500, 501];
const BAD_REQUEST_ERROR = [400, 422];
const WRONG_URL_ERROR = [404];

export type ResponseValidation = {
  data: {
    status: boolean;
    message: string;
    payload: any;
  };
  isHideAlert: boolean;
};

// RESOLVE REQUEST RESPONSE
const resolveResponse = (response: ResponseValidation, hideAlert?: boolean) => {
  return new Promise((resolve) => {
    const { status, payload } = response.data;
    if (status) {
      if (!hideAlert) {
        // Notification({
        //   type: 'success',
        //   message,
        //   isMessageServer: true,
        // });
      }
      if (payload) resolve(payload);
      else resolve(true);
    } else {
      // Notification({
      //   type: 'warning',
      //   message,
      //   isMessageServer: true,
      // });
      resolve(false);
    }
  });
};

const resolveFailureResponse = (response: {
  type: string;
  message: string;
  statusCode: number;
}) => {
  return new Promise((resolve) => {
    const { message = 'Internal Server Error', statusCode } = response;
    Alert({
      type: 'ERROR',
      message,
    })
      .then((value) => {
        if (
          value === 'ok' &&
          [
            ...AUTHORISED_ERROR,
            ...INTERAL_SERVER_ERROR,
            ...BAD_REQUEST_ERROR,
            ...WRONG_URL_ERROR,
          ].includes(statusCode)
        ) {
          Utils.redirect({
            pathname: Constants.ROUTERS.AUTH,
            search: {
              form: 'sign-in',
            },
          });
          Utils.clearAllSavedData();
        }
        return true;
      })
      .catch(() => false);
    resolve(true);
  });
};

export { resolveResponse, resolveFailureResponse };
