import React from 'react';
import { useTranslation } from 'react-multi-lang';
import { Stack, Typography, Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import Utils from '@/Utils';

import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import Details from './details';
import User from './users';

interface ITab {
  label: string;
  value: string;
  component: React.JSX.Element | React.JSX.Element[];
  permission: boolean;
}

const EditRole: React.FC = () => {
  const t = useTranslation();
  const [tabIndex, setTabIndex] = React.useState<string>('roleDetails');

  const isAcceptEditRole = Utils.isValidPermission(
    ModuleApiConstant.ROLE_PERMISSION.UPDATE_ROLE
  );

  const tabPanels = [
    {
      label: 'roleDetails',
      value: 'roleDetails',
      component: <Details />,
      permission: isAcceptEditRole,
    },
    {
      label: 'users',
      value: 'users',
      component: <User />,
      permission: isAcceptEditRole,
    },
  ];

  const _renderTopSection = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Typography fontSize="20px" fontWeight="500">
        {t('title.editRole')}
      </Typography>
    </Stack>
  );

  const _renderTab = () =>
    tabPanels.map((tab: ITab) =>
      tab.permission ? (
        <Tab
          key={`tab-${tab.value}`}
          label={t(`title.${tab.label}`)}
          value={tab.value}
        />
      ) : null
    );

  const _renderTabPanel = () =>
    tabPanels.map((tab: ITab) =>
      tab.permission && tabIndex === tab.value ? (
        <TabPanel
          key={`tab-panel-${tab.value}`}
          value={tab.value}
          sx={{ paddingX: 0 }}
        >
          {tab.component}
        </TabPanel>
      ) : null
    );

  const _renderBottomSection = () => {
    return (
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(_event: any, newValue: string) => setTabIndex(newValue)}
          >
            {_renderTab()}
          </TabList>
        </Box>
        {_renderTabPanel()}
      </TabContext>
    );
  };

  return (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default EditRole;
