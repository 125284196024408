import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { Box, Button, Stack, Typography } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { RootState, useTypedDispatch } from '@/store';
import { TagActions, WikiActions } from '@/Actions';
import Constants from '@/Constants';
import Utils from '@/Utils';

import {
  AutocompleteSelect,
  Loading,
  MarkdownEditor,
  RoundedContainer,
  TextField,
} from '@/Components/Common';

import { ITag } from '../../../Interfaces/Tag.interface';

const { ROUTERS, MODULE_API } = Constants;
const { createWiki } = WikiActions;
const { fetchTags, createTag } = TagActions;

const CreateDocumentation: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.WIKI, 'requestIsLoading')
  );

  const tags: ITag[] = useSelector((state: RootState) =>
    get(state.TAG, 'payload')
  );
  const isAcceptCreateTag = Utils.isValidPermission(MODULE_API.TAG.CREATE_TAG);

  const schema = yup
    .object({
      title: yup.string().trim().required(t('message.titleRequired')),
      description: yup.string().trim(),
      content: yup.string().trim().required(t('message.contentRequired')),
      attachment: yup.array(),
      tags: yup
        .array()
        .min(1, t('message.tagsLeastAtOneItem'))
        .required(t('message.tagsRequired')),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      description: '',
      content: '',
      attachment: [],
      tags: [],
    },
  });

  React.useEffect(() => {
    dispatch(fetchTags());
  }, []);

  const tagOptions = React.useMemo(() => {
    const resolveOptions: { label: string; value: string; color: string }[] =
      [];
    if (tags && tags.length > 0)
      tags.forEach((item: ITag) =>
        resolveOptions.push({
          label: item.name,
          value: item.id,
          color: item.color,
        })
      );
    return resolveOptions;
  }, [tags]);

  const onSubmit = (data: {
    title: string;
    description: string;
    content: string;
    tags: string[];
    attachment: File[];
  }) => {
    const { title, description, content, attachment } = data;
    const formData = new FormData();
    formData.append('title', title.trim());
    formData.append('description', description.trim());
    formData.append('content', content.trim());
    if (data.tags && data.tags.length > 0)
      for (const tag of data.tags) {
        formData.append('tags[]', tag);
      }
    if (attachment.length > 0)
      for (const file of attachment) {
        formData.append('attachment', file);
      }
    dispatch(createWiki(formData));
  };

  const onCreateTag = (payload: any) => dispatch(createTag(payload));

  const _renderTopSection = () => (
    <Typography variant="h2">{t('title.createWiki')}</Typography>
  );

  const _renderBottomSection = () => (
    <RoundedContainer>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <TextField
              value={field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.value)
              }
              label={t('label.title')}
              placeholder={t('placeholder.titleDocumentation')}
              message={errors.title?.message}
              required
            />
          )}
        />
        <Controller
          name="tags"
          control={control}
          render={({ field }) => (
            <AutocompleteSelect
              label={t('label.tags')}
              placeholder={t('placeholder.tags')}
              message={errors.tags?.message}
              value={field.value}
              options={tagOptions}
              required
              onChange={(newValue: string[]) => field.onChange(newValue)}
              onCreate={onCreateTag}
              sx={{ mb: 1 }}
              isAllowCreate={isAcceptCreateTag}
            />
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              value={field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.value)
              }
              label={t('label.description')}
              placeholder={t('placeholder.descriptionDocumentation')}
              rows={4}
              multiline
              message={errors.description?.message}
            />
          )}
        />
        <Controller
          name="content"
          control={control}
          render={({ field }) => (
            <MarkdownEditor
              value={field.value}
              onChange={(newValue?: string) => field.onChange(newValue)}
              label={t('label.content')}
              message={errors.content?.message}
              required
            />
          )}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mt={2}
        >
          <Button
            onClick={() => Utils.redirect(ROUTERS.WIKI)}
            variant="outlined"
          >
            {t('button.cancel')}
          </Button>
          <Button type="submit" variant="contained" sx={{ ml: 1 }}>
            {t('button.create')}
          </Button>
        </Stack>
      </Box>
    </RoundedContainer>
  );

  const _renderContent = () => (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );

  return (
    <>
      {isLoading ? <Loading /> : null}
      {!isLoading ? _renderContent() : null}
    </>
  );
};

export default CreateDocumentation;
