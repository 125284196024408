import { SxProps, Theme } from '@mui/system';
import { CommonColors } from '@/Themes';

export const projectInfor: SxProps<Theme> = {
  width: 1,
  textAlign: 'center',
  transition: '.4s transform linear, .2s box-shadow linear',
  ':hover': {
    boxShadow: '0px 8px 14px -4px rgba(0,0,0,0.2)',
    transform: 'translate(0px, -3px)',
  },
  background: 'none',
  p: 1,
  cursor: 'pointer',
  height: '100%',
  '& .pr-img': {
    objectFit: 'contain',
    width: '100%',
    height: 150,
  },
  '& .pr-title': {
    fontWeight: 600,
  },
  position: 'relative',
};

export const projectWrapper: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gap: '40px',
};

export const projectLink: SxProps<Theme> = {
  color: 'rgb(99,115, 129)',
  '&:hover': { color: CommonColors.ebonyClay },
  fontWeight: 400,
  fontSize: 13,
  fontFamily: 'sans-serif',
};

export const newProjectBadge: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  right: -25,
  color: 'rgb(0,108,156)',
  background: 'rgba(0,184,217,0.16)',
  fontWeight: 700,
  fontSize: '0.75rem',
  p: '0px 6px',
};
