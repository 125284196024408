import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';

const { TOOL } = CONSTANTS.API;

export const fetchTools = async (payload?: any) => {
  return sendRequest(TOOL.BASIC, 'GET', payload);
};

export const getToolByID = async (id: string) => {
  return sendRequest(`${TOOL.BASIC}/${id}`, 'GET');
};

export const createTool = async (payload: FormData) => {
  return sendRequest(TOOL.BASIC, 'POST_FORM_DATA', payload);
};

export const updateTool = async (payload: FormData, id: string) => {
  return sendRequest(`${TOOL.BASIC}/${id}`, 'PUT_FORM_DATA', payload);
};

export const deleteTool = async (id: string) => {
  return sendRequest(`${TOOL.BASIC}/${id}`, 'DEL');
};
