import React from 'react';
import CONSTANTS from '@/Constants';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import forEach from 'lodash/forEach';
import { useTranslation } from 'react-multi-lang';

import Utils from '@/Utils';

import { ChipStatus, StickyHeadTable } from '@/Components/Common';
import { IMetaStructure } from '@/Interfaces/Common.interface';

import { Box, IconButton, Tooltip } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IUserDetailsStructure } from '../../../Interfaces/User.interface';

const { ENUMS, MODULE_API } = CONSTANTS;

const { Status } = ENUMS;

interface IColumnStructure {
  id:
    | 'no'
    | 'staff_code'
    | 'full_name'
    | 'email'
    | 'position'
    | 'status'
    | 'action';

  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

interface ISectionProps {
  payload: any;
  meta: IMetaStructure;
  onRowAction(user: any, action: 'detail' | 'mail' | 'status'): void;
  onPageAction(value: number, type: 'limit' | 'page'): void;
  isLoading?: boolean;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 30, align: 'center' },
  { id: 'staff_code', label: 'staffCode', minWidth: 130 },
  { id: 'full_name', label: 'fullName', minWidth: 200 },
  { id: 'email', label: 'email', minWidth: 100 },
  { id: 'position', label: 'position', minWidth: 100 },
  { id: 'status', label: 'status', minWidth: 80, align: 'center' },
  { id: 'action', label: 'action', minWidth: 50, align: 'center' },
];

const createDataForTable = (
  no: string,
  staff_code: string,
  full_name: string,
  email: string,
  position: string,
  status: JSX.Element,
  action: JSX.Element
): any => {
  return {
    no,
    staff_code,
    full_name,
    position,
    email,
    status,
    action,
  };
};

const UsersDataTable: React.FC<ISectionProps> = ({
  payload,
  meta,
  onRowAction,
  onPageAction,
  isLoading = false,
}) => {
  const t = useTranslation();
  const [rows, setRows] = React.useState<any[]>([]);

  React.useEffect(() => {
    const isAcceptViewDetails = Utils.isValidPermission(
      MODULE_API.USER.GET_USER_BY_ID
    );
    const isAcceptEdit = Utils.isValidPermission(
      MODULE_API.USER.UPDATE_USER_BY_ID
    );
    const isAllowAction = isAcceptViewDetails || isAcceptEdit;
    const actionTooltip = isAllowAction
      ? t('tooltip.viewDetails')
      : t('tooltip.notAuthorize');

    const resolveRows: any = [];
    if (!isEmpty(payload)) {
      forEach(payload, (item: IUserDetailsStructure, index: number) => {
        const { email, status, userData, staffCode } = item;
        const isActive = status === Status.ACTIVE;
        const currentPage = get(meta, 'currentPage');
        const limit = get(meta, 'itemsPerPage');
        const resolveRowIndex = index + 1 + (currentPage - 1) * limit;

        const statusTooltip = isActive
          ? t('tooltip.deactivateTooltip')
          : t('tooltip.activateTooltip');

        resolveRows.push(
          createDataForTable(
            `${resolveRowIndex}`,
            staffCode?.code || 'N/I',
            userData?.fullName || 'N/I',
            email,
            userData?.position ? startCase(userData?.position) : 'N/I',
            <Tooltip title={statusTooltip}>
              <Box
                component="div"
                onClick={() => onRowAction(item, 'status')}
                sx={{
                  '* :hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <ChipStatus label={status} />
              </Box>
            </Tooltip>,
            <Tooltip title={actionTooltip}>
              <span>
                <IconButton
                  disabled={!isAllowAction}
                  onClick={() => isAllowAction && onRowAction(item, 'detail')}
                >
                  <VisibilityOutlinedIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      paginate={meta}
      onAction={onPageAction}
      isLoading={isLoading}
    />
  );
};

export default UsersDataTable;
