import {
  Avatar,
  Box,
  Button,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-multi-lang';
import AdjustIcon from '@mui/icons-material/Adjust';
import MessageIcon from '@mui/icons-material/Message';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import dayjs from 'dayjs';
import _ from 'lodash';
import Utils from '@/Utils';
import CONSTANTS from '@/Constants';
import {
  IDataFeedbacks,
  IFeedbackFilter,
} from '@/Interfaces/Feedback.interface';
import { RootState, useTypedDispatch } from '@/store';
import { FeedbacksActions } from '@/Actions';
import { EventSkeleton } from '@/Components/Common/Skeleton';
import { CommonColors } from '@/Themes';
import replyStyles from './Feedbacks.Styles';
import { Dropdown, RoundButton, RoundedContainer } from '@/Components/Common';

const { ROUTERS, ENUMS, MODULE_API } = CONSTANTS;
const { getAllForUserFeedbacks, fetchFeedbacks } = FeedbacksActions;

const initialFilter: IFeedbackFilter = {
  status: ENUMS.Type.ALL_TYPE,
  keyword: '',
  page: 1,
  limit: 10,
};

const initialStatusOptions = [
  { labelEN: 'All type', labelVI: 'Tất cả', value: 'allType' },
  { labelEN: 'Open', labelVI: 'Đang mở', value: 'open' },
  { labelEN: 'Close', labelVI: 'Đã đóng', value: 'close' },
];

const FeedbackList: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const language = Utils.getSavedLocale();
  const userData = Utils.getSavedUserData();
  const locationState = useLocation().state;
  const isAcceptFetch = Utils.isValidPermission(
    MODULE_API.FEEDBACKS.FETCH_FEEDBACKS
  );
  const isAcceptGetAllForUser = Utils.isValidPermission(
    MODULE_API.FEEDBACKS.GET_ALL_FOR_USER
  );
  const isAcceptCreate = Utils.isValidPermission(
    MODULE_API.FEEDBACKS.CREATE_FEEDBACK
  );
  const [dataFeedbacks, setDataFeedbacks] = useState<any>([]);
  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: initialFilter,
  });
  const allForUser: any = useSelector((state: RootState) =>
    get(state.FEEDBACK, 'allForUser')
  );
  const payload: any = useSelector((state: RootState) =>
    get(state.FEEDBACK, 'payload')
  );
  const meta: any = useSelector((state: RootState) =>
    get(state.FEEDBACK, 'meta')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.FEEDBACK, 'requestIsLoading')
  );
  const count =
    Math.ceil(meta?.totalItems || 0 / (meta?.itemsPerPage || 0)) || 0;

  const statusOptions = React.useMemo(() => {
    const resolveOptions: any[] = [];
    const isEnglish = language === 'en';
    for (const option of initialStatusOptions) {
      const resolveLabel = isEnglish ? option.labelEN : option.labelVI;
      resolveOptions.push({
        label: resolveLabel,
        value: option.value,
      });
    }
    return resolveOptions;
  }, [initialStatusOptions, language]);

  const fetchAllforUser = (filterParams: any) => {
    const resolverFilter = Utils.validateFilters(filterParams);
    dispatch(getAllForUserFeedbacks(resolverFilter));
  };

  const fetchPayload = (filterParams: any) => {
    const resolverFilter = Utils.validateFilters(filterParams);
    dispatch(fetchFeedbacks(resolverFilter));
  };

  const onSearch = (data: IFeedbackFilter) => {
    if (_.get(userData, 'role.roleCode') !== ENUMS.Role.ADMIN)
      if (isAcceptGetAllForUser) fetchAllforUser(data);
    if (isAcceptFetch) fetchPayload(data);
  };

  const onReset = () => {
    reset();
    if (_.get(userData, 'role.roleCode') !== ENUMS.Role.ADMIN)
      if (isAcceptGetAllForUser) fetchAllforUser(initialFilter);
    if (isAcceptFetch) fetchPayload(initialFilter);
  };

  useEffect(() => {
    if (isAcceptFetch) setDataFeedbacks(payload);
    else setDataFeedbacks(allForUser);
  }, [allForUser, payload]);

  React.useEffect(() => {
    if (isAcceptFetch)
      if (!_.isEmpty(locationState?.status)) {
        fetchPayload({ ...initialFilter, status: locationState.status });
        setValue('status', locationState.status);
      } else fetchPayload(initialFilter);
    else if (_.get(userData, 'role.roleCode') !== ENUMS.Role.ADMIN)
      if (isAcceptGetAllForUser) {
        if (!_.isEmpty(locationState?.status)) {
          fetchAllforUser({ ...initialFilter, status: locationState.status });
          setValue('status', locationState.status);
        } else fetchAllforUser(initialFilter);
      }
  }, [locationState]);

  const handleChange = (_e: any, newPage: number) => {
    const currentFilter = getValues();
    if (_.get(userData, 'role.roleCode') !== ENUMS.Role.ADMIN)
      if (isAcceptGetAllForUser)
        fetchAllforUser({ ...currentFilter, page: newPage });
    if (isAcceptFetch) fetchPayload({ ...currentFilter, page: newPage });
  };

  const _renderTopSection = () => {
    return (
      <Stack>
        <Typography variant="h2">{t('title.feedback')}</Typography>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row">
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  size="small"
                  onChange={(e: any) => field.onChange(e.target.value)}
                  placeholder={t('placeholder.keyword')}
                  sx={{ mr: 1, width: '250px' }}
                />
              )}
            />
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Dropdown
                  value={field.value}
                  sx={{ mr: 1, width: '150px' }}
                  options={statusOptions}
                  onChange={(e: any) => field.onChange(e.target.value)}
                />
              )}
            />
            <Button
              sx={{ mr: 1 }}
              onClick={handleSubmit(onSearch)}
              variant="contained"
            >
              {t('button.filter')}
            </Button>
            <Button onClick={() => onReset()} variant="outlined">
              {t('button.reset')}
            </Button>
          </Stack>
          {isAcceptCreate && (
            <RoundButton
              type="contained"
              size="large"
              label={t('button.create')}
              onClick={() => Utils.redirect(ROUTERS.CREATE_FEEDBACK)}
            />
          )}
        </Stack>
      </Stack>
    );
  };

  const _renderbodySection = () => {
    if (dataFeedbacks.length === 0)
      return (
        <RoundedContainer sx={{ mt: 2, maxHeight: 150 }}>
          <Typography>
            {t('tooltip.noDocumentationFoundForThisFeedback')}
          </Typography>
        </RoundedContainer>
      );
    return (
      <Stack sx={{ mt: 2 }}>
        {_.map(dataFeedbacks, (item: IDataFeedbacks, index: number) => {
          const { id } = item;
          const isAcceptRole =
            _.get(userData, 'role.roleCode') === ENUMS.Role.ADMIN ||
            _.get(userData, 'role.roleCode') === ENUMS.Role.OFFICE_MANAGER;
          return (
            <RoundedContainer
              mt={1}
              key={index}
              sx={{
                transition: '.4s transform linear, .2s box-shadow linear',
                ':hover': {
                  boxShadow: '0px 8px 14px -4px rgba(0,0,0,0.2)',
                  transform: 'translate(0px, -3px)',
                },
              }}
            >
              <Grid
                container
                direction="row"
                spacing={1}
                onClick={() =>
                  Utils.redirect({
                    pathname: ROUTERS.REPLY_FEEDBACK,
                    id,
                  })
                }
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    color: '#0969da',
                  },
                }}
              >
                <Grid item xs={8}>
                  <Box display="flex">
                    {item.status === 'open' ? (
                      <Box title={item.status}>
                        <AdjustIcon
                          sx={{
                            color: CommonColors.jewel,
                            fontSize: 16,
                            mt: '4px',
                            mr: 1,
                          }}
                        />
                      </Box>
                    ) : (
                      <Box title={item.status}>
                        <AdjustIcon
                          sx={{ color: 'red', fontSize: 16, mt: '4px', mr: 1 }}
                        />
                      </Box>
                    )}
                    <Box minWidth={85} mr={1}>
                      {item.status === 'open' ? (
                        <Typography
                          sx={{
                            ...replyStyles.statusStyles,
                            minWidth: 55,
                            background: CommonColors.jewel,
                          }}
                        >
                          {t('label.open')}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            ...replyStyles.statusStyles,
                            minWidth: 85,
                            background: '#B22222',
                          }}
                        >
                          {t('label.closed')}
                        </Typography>
                      )}
                    </Box>
                    <Typography
                      variant="h6"
                      sx={{
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        maxWidth: 550,
                        textOverflow: 'ellipsis',
                        fontSize: 16,
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                  <Box mt={1} ml={3} display="flex">
                    <Typography
                      sx={{ color: CommonColors.shuttleGray, fontSize: 16 }}
                    >
                      {t('label.opened')}{' '}
                      {item.updatedAt
                        ? dayjs(item.updatedAt).format('HH:mm, DD-MM-YYYY')
                        : 'N/I'}
                    </Typography>
                    <Box ml={3} display="flex">
                      <MessageIcon
                        sx={{
                          fontSize: 20,
                          mt: '3px',
                          mr: 1,
                          color: CommonColors.shuttleGray,
                        }}
                      />
                      <Typography
                        sx={{ color: CommonColors.shuttleGray, fontSize: 16 }}
                      >
                        {item.threads?.length}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box mt={1}>
                    <Box display="flex">
                      <Typography
                        sx={{ color: '#000000de', fontSize: 16, minWidth: 70 }}
                      >
                        {t('label.assignee')}:{' '}
                      </Typography>
                      {item?.assignee ? (
                        <Box display="flex">
                          <Box title={item.assignee.userData.fullName}>
                            {item.assignee.userData?.avatar ? (
                              <Avatar
                                alt={item.assignee.userData.fullName.slice(
                                  0,
                                  1
                                )}
                                sx={{
                                  ...replyStyles.avartarFeedbackListStyle,
                                }}
                                style={{ fontSize: 5, border: 'red' }}
                                src={item.assignee.userData.avatar.path}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  ...replyStyles.avartarFeedbackListStyle,
                                }}
                                style={{ fontSize: 15 }}
                              >
                                {item.assignee.userData.fullName.slice(0, 1)}
                              </Avatar>
                            )}
                          </Box>
                          <Typography
                            sx={{
                              color: CommonColors.shuttleGray,
                              fontSize: 16,
                            }}
                          >
                            {item?.assignee?.userData?.fullName}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography
                          sx={{ ml: 1, color: CommonColors.shuttleGray }}
                        >
                          Unassigned
                        </Typography>
                      )}
                    </Box>
                    {isAcceptRole ? (
                      <Box sx={{ display: 'flex' }}>
                        <Typography
                          sx={{
                            fontSize: 16,
                            color: '#000000de',
                            minWidth: 70,
                          }}
                        >
                          {t('label.sender')}:{' '}
                        </Typography>
                        <Box title={item.createdBy.userData.fullName}>
                          {item.createdBy.userData?.avatar ? (
                            <Avatar
                              alt={item.createdBy.userData.fullName.slice(0, 1)}
                              sx={{
                                ...replyStyles.avartarFeedbackListStyle,
                              }}
                              style={{ fontSize: 5, border: 'red' }}
                              src={item.createdBy.userData.avatar.path}
                            />
                          ) : (
                            <Avatar
                              sx={{
                                ...replyStyles.avartarFeedbackListStyle,
                              }}
                              style={{ fontSize: 15 }}
                            >
                              {item.createdBy.userData.fullName.slice(0, 1)}
                            </Avatar>
                          )}
                        </Box>
                        <Typography
                          sx={{ color: CommonColors.shuttleGray, fontSize: 16 }}
                        >
                          {item.createdBy.userData.fullName}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
            </RoundedContainer>
          );
        })}
      </Stack>
    );
  };

  const _renderPagination = () => {
    return (
      <Grid item xs={12} display="flex" justifyContent="center" my={4}>
        <Pagination
          count={count}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Grid>
    );
  };

  const _renderContent = () => (
    <>
      {_renderTopSection()}
      {isLoading ? <EventSkeleton /> : <Box> {_renderbodySection()}</Box>}
      {_renderPagination()}
    </>
  );

  const renderMain = () => {
    return (
      <Stack flex={1} direction="column">
        {_renderContent()}
      </Stack>
    );
  };

  return renderMain();
};

export default FeedbackList;
