import USImage from '@/Assets/Images/united-states.png';
import VNImage from '@/Assets/Images/vietnam.png';
import logo from '@/Assets/Images/logo.svg';
import logoFlow from '@/Assets/Images/logo_flow.svg';
import whiteLogo from '@/Assets/Images/white_logo.svg';
import wordIcon from '@/Assets/Images/word.png';
import excelIcon from '@/Assets/Images/excel.png';
import pdfLogo from '@/Assets/Images/pdf.png';
import vietnamFlag from '@/Assets/Images/vietnam_flag.svg';
import ukFlag from '@/Assets/Images/uk_flag.svg';
import exportImage from '@/Assets/Images/ExportImage.png';
import exportLogo from '@/Assets/Images/exportLogo.png';
import exportIdra from '@/Assets/Images/exportIdra.png';
import filmIcon from '@/Assets/Images/film.png';
import audioIcon from '@/Assets/Images/audio-waves.png';
import docIcon from '@/Assets/Images/doc.png';
import pptIcon from '@/Assets/Images/ppt.png';
import sheetIcon from '@/Assets/Images/sheets.png';
import flowIdraLogo from '@/Assets/Images/flow_idra_logo.png';
import preLogo from '@/Assets/Images/pre_logo.svg';
import preName from '@/Assets/Images/pre_name.svg';
import singIn from '@/Assets/Images/sign_in.png';
import theworldbankLogo from '@/Assets/Images/theworldbank-logo@2x.png';
import cocaLogo from '@/Assets/Images/cocaLogo.png';
import henkelLogo from '@/Assets/Images/henkelLogo.png';
import raiffeisenLogo from '@/Assets/Images/raiffeisenLogo.png';
import gizLogo from '@/Assets/Images/gizLogo.png';
import vodafoneLogo from '@/Assets/Images/vodafoneLogo.png';
import telemetrixLogo from '@/Assets/Images/telemetrixLogo.png';
import undpLogo from '@/Assets/Images/undpLogo.png';
import ifcLogo from '@/Assets/Images/ifcLogo.png';
import marketResearch from '@/Assets/Images/market-research.svg';
import mediaIntelligence from '@/Assets/Images/media-intelligence.svg';
import softwareDevelopement from '@/Assets/Images/softwareDevelopement.svg';
import bgResearchservices from '@/Assets/Images/bgResearchservices.jpg';
import iconQuantitative from '@/Assets/Images/iconQuanity.png';
import auroni from '@/Assets/Images/Auroni.jpeg';
import mondi from '@/Assets/Images/mondi.jpg';
import ledi from '@/Assets/Images/ledi.jpg';
import electron from '@/Assets/Images/Electron.svg';
import javaScript from '@/Assets/Images/JavaScript.svg';
import laravel from '@/Assets/Images/Laravel.svg';
import materialUI from '@/Assets/Images/MaterialUI.svg';
import nestjs from '@/Assets/Images/Nestjs.svg';
import php from '@/Assets/Images/PHP.svg';
import react from '@/Assets/Images/React.svg';
import typeScript from '@/Assets/Images/TypeScript.svg';
import vitejs from '@/Assets/Images/Vitejs.svg';
import desktop from '@/Assets/Images/desktop.svg';
import mobile from '@/Assets/Images/mobile.svg';
import web from '@/Assets/Images/web.svg';
import wordPress from '@/Assets/Images/WordPress.svg';
import swift from '@/Assets/Images/Swift.svg';
import flutter from '@/Assets/Images/Flutter.svg';
import nextjs from '@/Assets/Images/Next.js.svg';
import minSportit from '@/Assets/Images/minSportit.jpg';
import minRural from '@/Assets/Images/minRural.jpg';
import minRinis from '@/Assets/Images/minRinis.jpg';
import teamBanner from '@/Assets/Images/team.jpg';
import ourApproach from '@/Assets/Images/ourApproach.jpg';
import ourProcess from '@/Assets/Images/ourProcess.jpg';
import ourVision from '@/Assets/Images/ourVision.jpg';
import ourTeam from '@/Assets/Images/ourTeam.svg';
import figma from '@/Assets/Images/Figma.svg';
import HTML5 from '@/Assets/Images/HTML5.svg';
import postgresSQL from '@/Assets/Images/PostgresSQL.svg';
import python from '@/Assets/Images/Python.svg';
import sass from '@/Assets/Images/Sass.svg';
import socket from '@/Assets/Images/Socket.io.svg';
import SQLite from '@/Assets/Images/SQLite.svg';
import adonisJS from '@/Assets/Images/AdonisJS.svg';
import AWS from '@/Assets/Images/AWS.svg';
import nodejs from '@/Assets/Images/Node.js.svg';
import css3 from '@/Assets/Images/CSS3.svg';
import leaflet from '@/Assets/Images/leaflet.svg';
import tailwindCSS from '@/Assets/Images/TailwindCSS.svg';
import expo from '@/Assets/Images/expo.svg';
import mySQL from '@/Assets/Images/MySQL.svg';
import jenkins from '@/Assets/Images/Jenkins.svg';
import heroku from '@/Assets/Images/Heroku.svg';
import wooCommerce from '@/Assets/Images/WooCommerce.svg';
import vercel from '@/Assets/Images/Vercel.svg';
import apacheTomcat from '@/Assets/Images/ApacheTomcat.svg';
import selenium from '@/Assets/Images/Selenium.svg';
import redux from '@/Assets/Images/Redux.svg';
import redis from '@/Assets/Images/Redis.svg';
import nodemon from '@/Assets/Images/Nodemon.svg';
import NGINX from '@/Assets/Images/NGINX.svg';
import linux from '@/Assets/Images/Linux.svg';
import jQuery from '@/Assets/Images/jQuery.svg';
import ionic from '@/Assets/Images/Ionic.svg';
import gulpjs from '@/Assets/Images/Gulp.js.svg';
import graphQL from '@/Assets/Images/GraphQL.svg';
import gitLab from '@/Assets/Images/GitLab.svg';
import gitHub from '@/Assets/Images/GitHub.svg';
import git from '@/Assets/Images/Git.svg';
import cloudflare from '@/Assets/Images/Cloudflare.svg';
import bootstrap from '@/Assets/Images/Bootstrap.svg';
import apache from '@/Assets/Images/Apache.svg';
import angularJS from '@/Assets/Images/AngularJS.svg';
import android from '@/Assets/Images/Android.svg';
import bitBucket from '@/Assets/Images/BitBucket.svg';
import docker from '@/Assets/Images/Docker.svg';
import express from '@/Assets/Images/Express.svg';
import pupperteer from '@/Assets/Images/Pupperteer.png';
import ourApproachPlaceholder from '@/Assets/Images/ourApproachPlaceholder.jpg';
import ourProcessPlaceholder from '@/Assets/Images/ourProcessPlaceholder.jpg';
import ourVisionPlaceholder from '@/Assets/Images/ourVisionPlaceholder.jpg';
import teamPlaceholder from '@/Assets/Images/teamPlaceholder.jpg';
import kombetare from '@/Assets/Images/Kombetare.jpg';
import typeorm from '@/Assets/Images/typeorm.png';
import zoom from '@/Assets/Images/zoom.png';
import charkaui from '@/Assets/Images/charkaui.png';

export default {
  usImage: USImage,
  vnImage: VNImage,
  charkaui,
  kombetare,
  typeorm,
  zoom,
  ourApproachPlaceholder,
  ourProcessPlaceholder,
  ourVisionPlaceholder,
  teamPlaceholder,
  heroku,
  wooCommerce,
  vercel,
  apacheTomcat,
  selenium,
  redux,
  nodemon,
  NGINX,
  redis,
  linux,
  jQuery,
  ionic,
  gulpjs,
  graphQL,
  gitLab,
  gitHub,
  git,
  cloudflare,
  bootstrap,
  apache,
  angularJS,
  android,
  bitBucket,
  docker,
  express,
  pupperteer,
  //
  logo,
  logoFlow,
  whiteLogo,
  wordIcon,
  excelIcon,
  pdfLogo,
  vietnamFlag,
  ukFlag,
  exportImage,
  exportIdra,
  exportLogo,
  filmIcon,
  audioIcon,
  docIcon,
  pptIcon,
  sheetIcon,
  flowIdraLogo,
  preLogo,
  preName,
  singIn,
  theworldbankLogo,
  cocaLogo,
  henkelLogo,
  raiffeisenLogo,
  gizLogo,
  vodafoneLogo,
  telemetrixLogo,
  undpLogo,
  ifcLogo,
  marketResearch,
  mediaIntelligence,
  softwareDevelopement,
  bgResearchservices,
  iconQuantitative,
  auroni,
  mondi,
  ledi,
  electron,
  javaScript,
  laravel,
  materialUI,
  nestjs,
  php,
  react,
  typeScript,
  vitejs,
  desktop,
  web,
  mobile,
  wordPress,
  swift,
  flutter,
  nextjs,
  minSportit,
  minRural,
  minRinis,
  teamBanner,
  ourApproach,
  ourProcess,
  ourVision,
  ourTeam,
  figma,
  HTML5,
  postgresSQL,
  python,
  sass,
  socket,
  SQLite,
  adonisJS,
  AWS,
  nodejs,
  css3,
  leaflet,
  tailwindCSS,
  expo,
  mySQL,
  jenkins,
};
