import React from 'react';
import ReactDOM from 'react-dom/client';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang';
import {
  Wrapper,
  Container,
  IconWrapper,
  SuccessIcon,
  ErrorIcon,
  WarningIcon,
  Title,
  ButtonWrapper,
  Button,
} from './Alert.widget.styles';
import { CommonColors } from '@/Themes';

interface SectionProps {
  type?: 'SUCCESS' | 'ERROR' | 'WARNING';
  message?: string;
  buttons?: any;
  callback?(value: string): unknown;
  isTranslate?: boolean;
  subMessage?: string;
}

let ROOT_ELEMENT: ReactDOM.Root | null = null;

const hideAlert = () => {
  return new Promise<void>((resolve) => {
    const emptyElemnt = React.createElement('div', {}, []);
    if (ROOT_ELEMENT) ROOT_ELEMENT.render(emptyElemnt);
    resolve();
  });
};

const AlertPopup: React.FC<SectionProps> = (props: SectionProps) => {
  // Delacre translation
  const t = useTranslation();
  // Declare Props
  const { type, message, buttons, callback, isTranslate, subMessage } = props;

  // EVENT CLICK ON BUTTONS
  const handleClick = (value: string) => {
    if (value !== 'wrapper') {
      if (callback) return callback(value);
      return hideAlert();
    }
    return false;
  };

  const renderErrorIcon = () => {
    return (
      <ErrorIcon>
        <span className="error_x_mark">
          <span className="error_line error_left error_animateX_Left" />
          <span className="error_line error_right error_animateX_Right" />
        </span>
        <div className="error_place_holder" />
        <div className="f-error_icon_fix-fix" />
      </ErrorIcon>
    );
  };

  const renderSuccessIcon = () => {
    return (
      <SuccessIcon>
        <span className="success_icon_line success_line_tip" />
        <span className="success_icon_line success_line_long" />
        <div className="success_icon_circle" />
        <div className="success_icon_fix" />
      </SuccessIcon>
    );
  };

  const renderWaringIcon = () => {
    return (
      <WarningIcon>
        <span className="warning_body warning_pulseWarningIns" />
        <span className="warning_dot warning_pulseWarningIns" />
      </WarningIcon>
    );
  };

  const renderIcons = () => {
    return (
      <IconWrapper>
        {type === 'SUCCESS' && renderSuccessIcon()}
        {type === 'ERROR' && renderErrorIcon()}
        {type === 'WARNING' && renderWaringIcon()}
        {!type && renderWaringIcon()}
      </IconWrapper>
    );
  };

  const renderTitle = () => {
    if (type === 'SUCCESS')
      return <Title className="title_success">{t(`popup.success`)}</Title>;
    if (type === 'ERROR')
      return <Title className="title_error">{t(`popup.error`)}</Title>;
    if (type === 'WARNING')
      return <Title className="title_warning">{t(`popup.warning`)}</Title>;
    return null;
  };

  const renderDefaultButtons = () => {
    if (type === 'SUCCESS' || type === 'ERROR') {
      return (
        <ButtonWrapper>
          <Button value="ok" onClick={() => handleClick('ok')}>
            {t(`button.ok`)}
          </Button>
        </ButtonWrapper>
      );
    }
    return (
      <ButtonWrapper>
        <Button
          color={CommonColors.bismark}
          background={CommonColors.loblolly}
          value="cancel"
          onClick={() => handleClick('cancel')}
        >
          {t(`button.cancel`)}
        </Button>
        <Button value="ok" onClick={() => handleClick('ok')}>
          {t(`button.ok`)}
        </Button>
      </ButtonWrapper>
    );
  };

  const renderCustomButtons = () => {
    return (
      <ButtonWrapper>
        {_.map(buttons, (button: any, index: number) => {
          return (
            <Button
              key={index}
              color={button.color}
              background={button.background}
              value={button.value}
              onClick={() => handleClick(button.value)}
            >
              {t(`button.${button.title}`)}
            </Button>
          );
        })}
      </ButtonWrapper>
    );
  };

  return (
    <Wrapper onClick={() => handleClick('wrapper')}>
      <Container>
        {renderIcons()}
        {renderTitle()}
        {!isTranslate && <p className="alert__message">{message}</p>}
        {isTranslate && (
          <p className="alert__message">
            {t(`alert.${message}`)}
            {subMessage}
          </p>
        )}
        {!buttons && renderDefaultButtons()}
        {_.isArray(buttons) && !_.isEmpty(buttons) && renderCustomButtons()}
      </Container>
    </Wrapper>
  );
};

const showAlert = (attribute?: any) => {
  const { type, message, buttons, isTranslate, subMessage } = attribute;
  return new Promise((resolve) => {
    if (!ROOT_ELEMENT)
      ROOT_ELEMENT = ReactDOM.createRoot(
        document.getElementById('alert') as HTMLElement
      );
    ROOT_ELEMENT.render(
      <AlertPopup
        type={type}
        message={message}
        buttons={buttons}
        callback={(value: string) => resolve(value)}
        isTranslate={isTranslate}
        subMessage={subMessage}
      />
    );
  });
};

const Alert = (attribute?: SectionProps) => {
  return new Promise((resolve) => {
    showAlert(attribute)
      .then((value) => {
        hideAlert()
          .then(() => resolve(value))
          .catch((hideError) => hideError);
      })
      .catch((err) => err);
  });
};

export default Alert;
