import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-multi-lang';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Button, TextField, Typography, Stack } from '@mui/material';

import Utils from '@/Utils';
import { WorkProgressActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { Alert } from '@/Widgets';
import CONSTANTS from '@/Constants';
import { RoundedContainer } from '@/Components/Common';
import WorkProgressDataTable from '@/Components/LayoutPart/DataTable/WorkProgressListDataTable';
import AddWorkProgressPopup from '@/Components/Popup/AddWorkProgressPopup';
import {
  IWasAssigned,
  IWorkProgressFilter,
  IWorkProgressResponseStructure,
} from '@/Interfaces/WorkProgress.interface';

import { getSavedUserData } from '@/Utils/Cookie.utils';

const initialFilter: IWorkProgressFilter = {
  keyword: '',
  page: 1,
  limit: 10,
  orderBy: 'createdAt',
};
const { ROUTERS, ENUMS, MODULE_API } = CONSTANTS;
const { fetchWorkProgress, deleteWorkProgress, updateStatusWorkProgress } =
  WorkProgressActions;

const WorkProgressList: React.FC = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const meta = useSelector((state: RootState) =>
    _.get(state.WORK_PROGRESS, 'meta')
  );
  const payload = useSelector((state: RootState) =>
    _.get(state.WORK_PROGRESS, 'payload')
  );
  const { control, setValue, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialFilter,
  });
  const keywordValue = getValues('keyword');
  const [isShowAddPopup, setIsShowAddPopup] = React.useState<boolean>(false);

  const isAcceptAdd = Utils.isValidPermission(MODULE_API.WORK_PROGRESS.CREATE);
  const isLoadingWorkProgress = useSelector((state: RootState) =>
    _.get(state.WORK_PROGRESS, 'requestIsLoading')
  );
  const isAcceptViewDetailsApi = Utils.isValidPermission(
    MODULE_API.WORK_PROGRESS.GET_BY_ID
  );

  const fetchPayload = (filterParams: IWorkProgressFilter) => {
    const resolverFilter = Utils.validateFilters(filterParams);
    dispatch(fetchWorkProgress(resolverFilter));
  };

  React.useEffect(() => {
    fetchPayload(initialFilter);
  }, []);

  const onReset = () => {
    reset();
    fetchPayload(initialFilter);
  };

  const onSearch = (data: IWorkProgressFilter) => fetchPayload(data);

  const onPageAction = async (value: number, type: 'limit' | 'page') => {
    const currentFilter = getValues();
    const newFilter = {
      ...currentFilter,
      [type]: value,
    };

    setValue(type, value);
    if (type === 'limit') {
      setValue('page', 1);
      Object.assign(newFilter, { page: 1 });
    }

    fetchPayload(newFilter);
  };

  const onRowAction = async (
    workProgress: IWorkProgressResponseStructure,
    action: 'edit' | 'status' | 'delete'
  ) => {
    const { id, status, name, wasAssigned } = workProgress;
    if (action === 'status') {
      const isAcceptUpdate = Utils.isValidPermission(
        MODULE_API.WORK_PROGRESS.UPDATE
      );
      const isDone = status === ENUMS.WorkProgressStatus.DONE;
      const message = isDone
        ? t('popup.confirmChangeWorkProgressStatusToProcessing')
        : t('popup.confirmChangeWorkProgressStatusToDone');

      const isAgree = await Alert({
        type: 'WARNING',
        message,
      });
      if (isAgree === 'ok') {
        const currentFilter = getValues();
        const resolveFilter = Utils.validateFilters(currentFilter);
        if (id) {
          if (!isAcceptUpdate) {
            Alert({
              type: 'ERROR',
              message: t('popup.notAuthorizeForUpdateWorkProgress'),
            });
          } else
            dispatch(
              updateStatusWorkProgress(
                {
                  status: `${isDone ? 'processing' : 'done'}`,
                },
                id,
                resolveFilter
              )
            );
        }
      }
    } else if (action === 'edit') {
      const getUserData = getSavedUserData();
      let flag = false;
      if (!isAcceptViewDetailsApi) {
        _.map(wasAssigned, (item: IWasAssigned) => {
          if (item.email === getUserData.email) {
            flag = true;
          }
        });
        if (flag)
          Utils.redirect({ pathname: ROUTERS.WORK_PROGRESS_DETAILS, id });
        else
          Alert({
            type: 'ERROR',
            message: t('popup.notAuthorizeForViewWorkProgressDetails'),
          });
      } else Utils.redirect({ pathname: ROUTERS.WORK_PROGRESS_DETAILS, id });
    } else if (action === 'delete') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.confirmDeleteWorkProgress', { name }),
      });
      if (isAgree === 'ok') {
        const currentPage = getValues('page');
        const currentFilter = getValues();
        const resolveFilter = Utils.validateFilters(currentFilter);
        if (payload.length === 1 && currentPage !== 1) {
          setValue('page', 1);
          resolveFilter.page = 1;
        }
        dispatch(deleteWorkProgress(id, resolveFilter));
      }
    }
  };

  const _renderAddPopup = () => (
    <AddWorkProgressPopup
      open={isShowAddPopup}
      onClose={() => setIsShowAddPopup(false)}
    />
  );
  const _renderTopSection = () => {
    return (
      <Stack direction="column" sx={{ mb: 2 }}>
        <Typography variant="h2">{t('title.workProgress')}</Typography>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Stack flex={1} direction="row">
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  size="small"
                  onChange={(e: any) => field.onChange(e.target.value)}
                  placeholder={t('placeholder.keyword')}
                  sx={{ mr: 2, maxWidth: '190px' }}
                />
              )}
            />
            <Button
              onClick={handleSubmit(onSearch)}
              variant="contained"
              sx={{ mr: 1 }}
            >
              {t('button.filter')}
            </Button>
            <Button
              onClick={() => onReset()}
              variant="outlined"
              sx={{ mr: 1 }}
              disabled={!keywordValue}
            >
              {t('button.reset')}
            </Button>
          </Stack>

          {isAcceptAdd && (
            <Button
              variant="contained"
              onClick={() => {
                setIsShowAddPopup(true);
              }}
            >
              {t('button.create')}
            </Button>
          )}
        </Stack>
      </Stack>
    );
  };

  const _renderBottomSection = () => (
    <RoundedContainer>
      <WorkProgressDataTable
        meta={meta}
        payload={payload}
        onPageAction={onPageAction}
        onRowAction={onRowAction}
        isLoading={isLoadingWorkProgress}
      />
    </RoundedContainer>
  );

  const renderMain = () => {
    return (
      <Stack flex={1} direction="column" sx={{ height: 'max-content', p: 2 }}>
        {_renderAddPopup()}
        {_renderTopSection()}
        {_renderBottomSection()}
      </Stack>
    );
  };
  return renderMain();
};

export default WorkProgressList;
