import React from 'react';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import dayjs from 'dayjs';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, Tooltip } from '@mui/material';

import { StickyHeadTable } from '@/Components/Common';
import { useTranslation } from 'react-multi-lang';
import { IMetaStructure } from '../../../Interfaces/Common.interface';

interface ISectionProps {
  payload: any;
  onRowAction(role: any, action: 'edit'): void;
  onPageAction(value: number, type: 'limit' | 'page'): void;
  isLoading?: boolean;
  meta: IMetaStructure;
}

interface IDataTableStructure {
  no: string;
  name: string;
  description: string;
  totalUser: string;
  updated_at: string;
  action: JSX.Element;
}

interface IColumnStructure {
  id: 'no' | 'name' | 'description' | 'totalUser' | 'updated_at' | 'action';

  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'name', label: 'roleName', minWidth: 200 },
  { id: 'description', label: 'description', minWidth: 300 },
  { id: 'totalUser', label: 'totalUser', minWidth: 200, align: 'center' },
  {
    id: 'updated_at',
    label: 'updatedAt',
    minWidth: 80,
    align: 'center',
  },
  { id: 'action', label: 'action', minWidth: 100, align: 'center' },
];

const createDataTable = (
  no: string,
  name: string,
  description: string,
  totalUser: string,
  updated_at: string,
  action: JSX.Element
): IDataTableStructure => {
  return {
    no,
    name,
    description,
    totalUser,
    updated_at,
    action,
  };
};

const PermissionsDataTable: React.FC<ISectionProps> = ({
  payload,
  onRowAction,
  onPageAction,
  isLoading = false,
  meta,
}) => {
  const t = useTranslation();
  const [rows, setRows] = React.useState<IDataTableStructure[]>([]);

  React.useEffect(() => {
    const resolveRows: IDataTableStructure[] = [];
    if (!isEmpty(payload)) {
      forEach(
        payload,
        (
          item: {
            id: string;
            updatedAt: string;
            name: string;
            description: string;
            totalUser: string;
          },
          index: number
        ) => {
          const { updatedAt, name, description, totalUser } = item;

          const updateTime = dayjs(updatedAt);

          resolveRows.push(
            createDataTable(
              `${index + 1}`,
              name,
              description,
              totalUser,
              updateTime.isValid()
                ? updateTime.format('HH:mm, DD-MM-YYYY')
                : t('table.noInformation'),
              <Tooltip title={t('tooltip.clickToEditRole')}>
                <IconButton onClick={() => onRowAction(item, 'edit')}>
                  <EditOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )
          );
        }
      );
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      isLoading={isLoading}
      paginate={meta}
      onAction={onPageAction}
    />
  );
};

export default PermissionsDataTable;
