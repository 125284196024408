import { Skeleton, Stack } from '@mui/material';

const EventSkeleton = () => {
  return (
    <Stack direction="column">
      <Skeleton width={150} height={35} sx={{ mb: 0.5 }} />
      <Skeleton variant="rectangular" height={70} sx={{ mb: 1.5 }} />
      <Skeleton variant="rectangular" height={70} sx={{ mb: 1.5 }} />
      <Skeleton variant="rectangular" height={70} sx={{ mb: 1.5 }} />
      <Skeleton width={150} height={35} sx={{ mb: 0.5 }} />
      <Skeleton variant="rectangular" height={70} sx={{ mb: 1.5 }} />
      <Skeleton variant="rectangular" height={70} sx={{ mb: 1.5 }} />
      <Skeleton variant="rectangular" height={70} sx={{ mb: 1.5 }} />
    </Stack>
  );
};

export default EventSkeleton;
