interface ISideBarList {
  label: string;
  value?: string;
}

const USER_MENU: ISideBarList[] = [
  {
    label: 'Home',
    value: '/',
  },
  {
    label: 'Portfolio',
    value: '/portfolio',
  },
  {
    label: 'About Us',
    value: '/about-us',
  },
  // {
  //   label: 'News',
  //   value: '/news-and-publications',
  // },
];

export default {
  USER_MENU,
};
