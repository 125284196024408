import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import _ from 'lodash';

import { Alert } from '@/Widgets';
import Constants from '@/Constants';
import Utils from '@/Utils';
import { TagActions, WikiActions } from '@/Actions';

import { RootState, useTypedDispatch } from '@/store';
import {
  AutocompleteSelect,
  MarkdownEditor,
  RoundedContainer,
} from '@/Components/Common';

import { IDocumentation } from '@/Interfaces/Documentation.interface';

import { ITag } from '@/Interfaces/Tag.interface';
import { WikiSkeleton } from '../../../Components/Common/Skeleton';
import { IUserDetailsStructure } from '../../../Interfaces/User.interface';
import { CommonColors } from '@/Themes';

const { ROUTERS, MODULE_API, ENUMS } = Constants;
const { fetchWiki, setDefaultReducerWiki, resetWikiStatus, deleteWiki } =
  WikiActions;
const { fetchTags } = TagActions;

const initialFilter = {
  keyword: '',
  tags: [],
};

const Documentation: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const userData: IUserDetailsStructure = Utils.getSavedUserData();

  const isAcceptFetch = Utils.isValidPermission(MODULE_API.WIKI.FETCH);
  const isAcceptCreate = Utils.isValidPermission(MODULE_API.WIKI.CREATE);
  const isAcceptEdit =
    Utils.isValidPermission(MODULE_API.WIKI.UPDATE) ||
    userData?.role?.roleCode === ENUMS.Role.ADMIN;
  const isAcceptDelete =
    Utils.isValidPermission(MODULE_API.WIKI.DELETE) ||
    userData?.role?.roleCode === ENUMS.Role.ADMIN;

  const payload: IDocumentation[] = useSelector((state: RootState) =>
    get(state.WIKI, 'payload')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.WIKI, 'requestIsLoading')
  );
  const deleteIsSuccess: boolean = useSelector((state: RootState) =>
    get(state.WIKI, 'deleteIsSuccess')
  );
  const tags: ITag[] = useSelector((state: RootState) =>
    get(state.TAG, 'payload')
  );
  const [keyword, setKeyword] = React.useState<string>('');
  const [search, setSearch] = React.useState<any>(initialFilter);

  const [selectedWiki, setSelectedWiki] = React.useState<IDocumentation | null>(
    null
  );
  const [openDilog, setOpenDilog] = React.useState<boolean>(false);
  const [newWidth, setNewWidth] = React.useState<any>({
    width: Utils.getWidth() || 450,
  });

  const fetchPayload = (filterParams: any) => {
    const resolveFilterParams = Utils.validateFilters(filterParams);
    dispatch(fetchWiki(resolveFilterParams));
  };

  const handleClose = () => setOpenDilog(false);

  React.useEffect(() => {
    if (!isAcceptFetch) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForFetchWiki'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else {
      dispatch(fetchTags());
      fetchPayload(initialFilter);
    }
    return () => {
      dispatch(setDefaultReducerWiki());
    };
  }, []);

  React.useEffect(() => {
    return () => {
      if (!_.isEmpty(newWidth)) Utils.saveWidth(newWidth?.width || 450);
    };
  }, [newWidth]);

  React.useEffect(() => {
    if (deleteIsSuccess) {
      setSelectedWiki(null);
      dispatch(resetWikiStatus());
    }
    if (search.tags) {
      fetchPayload(search);
      setSelectedWiki(null);
    }
  }, [deleteIsSuccess, search]);

  const onReset = () => {
    setKeyword('');
    setSearch(initialFilter);
    fetchPayload(initialFilter);
  };

  const onEdit = (idSelectedWiki: string) =>
    Utils.redirect({ pathname: ROUTERS.EDIT_WIKI, id: idSelectedWiki });

  const onDelete = async (idSelectedWiki: string) => {
    const isAgree = await Alert({
      type: 'WARNING',
      message: t('popup.warningDeleteWiki'),
    });
    if (isAgree === 'ok' && idSelectedWiki)
      dispatch(deleteWiki(idSelectedWiki));
  };

  const tagOptions = React.useMemo(() => {
    const resolveOptions: { label: string; value: string; color: string }[] =
      [];
    if (tags && tags.length > 0)
      tags.forEach((item: ITag) =>
        resolveOptions.push({
          label: item.name,
          value: item.id,
          color: item.color,
        })
      );
    return resolveOptions;
  }, [tags]);

  const handleMousemove = (e: any) => {
    const offsetRight =
      document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
    const minWidth = 150;
    if (offsetRight > minWidth) setNewWidth({ width: offsetRight });
  };

  const handleMouseup = () => {
    document.removeEventListener('mousemove', handleMousemove, true);
    document.removeEventListener('mouseup', handleMouseup, true);
  };

  const handleMousedown = () => {
    document.addEventListener('mousemove', handleMousemove, true);
    document.addEventListener('mouseup', handleMouseup, true);
  };

  const _renderTopSection = () => (
    <Stack direction="column" mb={2}>
      <Typography variant="h2">{t('title.wiki')}</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row">
          <TextField
            value={keyword}
            size="small"
            onChange={(e: any) => setKeyword(e.target.value)}
            placeholder={t('placeholder.keyword')}
            sx={{ mr: 1, maxWidth: '190px' }}
          />
          <AutocompleteSelect
            placeholder={t('placeholder.tags')}
            value={search.tags}
            options={tagOptions}
            required
            onChange={(newValue: string[]) => setSearch({ tags: newValue })}
            sx={{ mr: 1 }}
            dropdownSx={{ width: '250px' }}
          />
          <Button onClick={() => onReset()} variant="outlined">
            {t('button.reset')}
          </Button>
        </Stack>
        {isAcceptCreate ? (
          <Button
            variant="contained"
            onClick={() => Utils.redirect(ROUTERS.CREATE_WIKI)}
          >
            {t('button.create')}
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );

  const _renderTags = (items: ITag[]) => (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        flexWrap: 'wrap',
        overflow: 'auto',
        width: 1,
      }}
    >
      {map(items, (tag: ITag) => (
        <Stack
          key={`tag-${tag.id}`}
          sx={{
            border: '1px',
            borderRadius: 1,
            px: 0.5,
            mr: 0.5,
            background: tag.color,
            color: 'white',
            transition: '.4s transform linear, .2s box-shadow linear',
            ':hover': {
              cursor: 'pointer',
              background: CommonColors.grey,
            },
          }}
        >
          <Typography onClick={() => setSearch({ tags: tag.id })}>
            {tag.name}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );

  const _renderWikiForm = () => {
    if (!selectedWiki)
      return (
        <Stack
          direction="column"
          flex={1}
          ml={1}
          sx={{
            padding: '5px',
          }}
        >
          {t('tooltip.selectWikiToContinue')}
        </Stack>
      );
    return (
      <Stack
        direction="column"
        flex={1}
        ml={1}
        sx={{
          padding: '5px',
        }}
      >
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: '500',
              textTransform: 'capitalize',
            }}
          >
            {selectedWiki.title}
          </Typography>
        </Stack>
        {_renderTags(selectedWiki.tags)}
        <Divider sx={{ mt: 1, mb: 1 }} />
        {selectedWiki.description ? (
          <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
            {selectedWiki.description}
          </Typography>
        ) : null}
        <Divider sx={{ mt: 1, mb: 2 }} />
        <MarkdownEditor value={selectedWiki.content} onlyMarkdown />
      </Stack>
    );
  };

  const _renderWiki = () => {
    const convertKeyword = Utils.toNonAccentVietnamese(keyword);

    const resolvePayloadByKeyword = _.filter(payload, (tool: IDocumentation) =>
      Utils.toNonAccentVietnamese(tool.title).includes(convertKeyword)
    );
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={2.5}>
              <Typography variant="h6" fontSize={16}>
                {t('table.title')}
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography variant="h6" fontSize={16}>
                {t('table.tags')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" fontSize={16}>
                {t('table.description')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h6" fontSize={16}>
                {t('table.action')}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
        </Grid>
        {resolvePayloadByKeyword?.length === 0 ? (
          <Stack
            direction="column"
            flex={1}
            ml={1}
            sx={{
              width: '300px',
              padding: '5px',
            }}
          >
            {t('tooltip.selectWikiToContinue')}
          </Stack>
        ) : (
          <Grid item xs={12}>
            {_.map(resolvePayloadByKeyword, (item: any, index: number) => {
              const isAuthor = userData?.id === item.createdBy.id;
              return (
                <Stack key={index}>
                  <Grid
                    container
                    sx={{
                      my: 1,
                    }}
                  >
                    <Grid
                      item
                      xs={2.5}
                      onClick={() => {
                        setSelectedWiki(item);
                      }}
                      sx={{ '&:hover': { cursor: 'pointer' } }}
                    >
                      <Typography
                        sx={{
                          display: '-webkit-box',
                          textOverflow: 'ellipse',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textTransform: 'capitalize',
                          mr: 4,
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                      {_renderTags(item.tags)}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ '&:hover': { cursor: 'pointer' } }}
                      onClick={() => {
                        setSelectedWiki(item);
                      }}
                    >
                      <Typography
                        sx={{
                          display: '-webkit-box',
                          textOverflow: 'ellipse',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textTransform: 'capitalize',
                          mr: 4,
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      {isAcceptEdit && isAuthor && (
                        <Tooltip title={t('tooltip.clickToEditWiki')}>
                          <IconButton
                            onClick={() => isAuthor && onEdit(item.id)}
                          >
                            <EditOutlinedIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {isAcceptDelete && isAuthor && (
                        <Tooltip title={t('tooltip.clickToDeleteWiki')}>
                          <IconButton
                            onClick={() => isAuthor && onDelete(item.id)}
                          >
                            <DeleteOutlineOutlinedIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                  {index + 1 < resolvePayloadByKeyword.length && <Divider />}
                </Stack>
              );
            })}
          </Grid>
        )}
      </Grid>
    );
  };

  const _renderDiglogWiki = () => {
    return (
      <Dialog
        open={openDilog}
        PaperProps={{
          style: {
            maxWidth: '70%',
            minWidth: '50%',
          },
        }}
        onClose={handleClose}
      >
        <DialogContent>{_renderWikiForm()}</DialogContent>
      </Dialog>
    );
  };

  const _renderTopSelectWikiForm = () => {
    const isAuthor = userData?.id === selectedWiki?.createdBy?.id;
    return (
      <Stack direction="row" justifyContent="space-between" mt={0.5}>
        <Stack onClick={() => setSelectedWiki(null)}>
          <Tooltip title={t('tooltip.close')}>
            <KeyboardDoubleArrowRightIcon
              sx={{
                color: CommonColors.grey,
                '&:hover': {
                  cursor: 'pointer',
                  color: CommonColors.bismark,
                },
              }}
            />
          </Tooltip>
        </Stack>
        <Stack direction="row">
          {isAcceptEdit && isAuthor && (
            <Tooltip title={t('tooltip.clickToEditWiki')}>
              <IconButton onClick={() => isAuthor && onEdit(selectedWiki?.id)}>
                <EditOutlinedIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          )}
          {isAcceptDelete && isAuthor && (
            <Tooltip title={t('tooltip.clickToDeleteWiki')}>
              <IconButton
                onClick={() => isAuthor && onDelete(selectedWiki?.id)}
              >
                <DeleteOutlineOutlinedIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t('tooltip.fullScreen')}>
            <IconButton onClick={() => setOpenDilog(true)}>
              <OpenInFullIcon
                sx={{
                  fontSize: 16,
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    );
  };

  const _renderBottomSection = () => (
    <RoundedContainer>
      {isLoading ? (
        <WikiSkeleton />
      ) : (
        <Stack direction="row">{_renderWiki()}</Stack>
      )}
    </RoundedContainer>
  );

  return (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
      <Drawer
        anchor="right"
        open={!_.isEmpty(selectedWiki)}
        variant="persistent"
        onClose={() => setSelectedWiki(null)}
        PaperProps={{ style: newWidth }}
      >
        <Stack role="presentation">
          <Stack
            sx={{
              width: '5px',
              cursor: 'ew-resize',
              padding: '4px 0 0',
              borderTop: '1px solid #ddd',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              zIndex: '100',
              backgroundColor: '#f4f7f9',
            }}
            onMouseDown={() => {
              handleMousedown();
            }}
          />
          {_renderTopSelectWikiForm()}
          {_renderWikiForm()}
        </Stack>
      </Drawer>
      <Stack width={0.8}>{_renderDiglogWiki()}</Stack>
    </>
  );
};

export default Documentation;
