import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@utils';
import {
  IMeetingNoteFilterStructure,
  IMeetingNoteStructure,
} from '@/Interfaces/Project.interface';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { toast } from 'react-toastify';
import {
  IDailyReport,
  IFilterProjectReports,
} from '../Interfaces/DailyReport.interface';
import ProjectAction from './Project.action';

const { ACTION_TYPES, ENUMS, ROUTERS } = CONSTANTS;
const { fetchProjects } = ProjectAction;

// SINGLE_ACTIONS
const setMetaPagination = (payload: IMetaStructure) => {
  return {
    type: ACTION_TYPES.SET_META_DAILY_REPORT,
    payload,
  };
};

const resetDailyReportStatus = () => {
  return {
    type: ACTION_TYPES.RESET_DAILY_REPORT_STATUS,
  };
};

const setDefaultReducerDailyReport = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_REDUCER_DAILY_REPORT,
  };
};

const dailyReportIsLoading = () => {
  return {
    type: ACTION_TYPES.DAILY_REPORT_IS_LOADING,
  };
};

const clearReportDetails = () => {
  return {
    type: ACTION_TYPES.CLEAR_REPORT_DETAILS,
  };
};

// ASYNC_ACTIONS
const fetchDailyReportsSuccess = (payload: IDailyReport[]) => {
  return {
    type: ACTION_TYPES.FETCH_DAILY_REPORTS_SUCCESS,
    payload,
  };
};

const fetchDailyReportsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_DAILY_REPORTS_FAILURE,
  };
};

const fetchDailyReports = (
  projectId: string,
  payload: IMeetingNoteFilterStructure
) => {
  return async (dispatch: any) => {
    dispatch(dailyReportIsLoading());
    await API.fetchDailyReportByProjectId(projectId, payload)
      .then(async (response: any) => {
        const results = await Utils.resolveResponse(response, true);
        if (!results) await dispatch(fetchDailyReportsFailure());
        else {
          const resolveResult: {
            items: IDailyReport[];
            meta: IMetaStructure;
          } = results as {
            items: IDailyReport[];
            meta: IMetaStructure;
          };
          await dispatch(setMetaPagination(resolveResult?.meta));
          await dispatch(fetchDailyReportsSuccess(resolveResult?.items));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(fetchDailyReportsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const fetchProjectReportsSuccess = (payload: IDailyReport[]) => {
  return {
    type: ACTION_TYPES.FETCH_PROJECT_REPORTS_SUCCESS,
    payload,
  };
};

const fetchProjectReportsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_PROJECT_REPORTS_FAILURE,
  };
};

const fetchProjectReports = (payload: IFilterProjectReports) => {
  return async (dispatch: any) => {
    dispatch(dailyReportIsLoading());
    await API.fetchProjectReports(payload)
      .then(async (response: any) => {
        const results = await Utils.resolveResponse(response, true);
        if (!results) await dispatch(fetchProjectReportsFailure());
        else {
          const resolveResult: {
            items: IDailyReport[];
            meta: IMetaStructure;
          } = results as {
            items: IDailyReport[];
            meta: IMetaStructure;
          };
          await dispatch(setMetaPagination(resolveResult?.meta));
          await dispatch(fetchProjectReportsSuccess(resolveResult?.items));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(fetchProjectReportsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const getReportDetailsByDateAndProjectSuccess = (payload: IDailyReport) => {
  return {
    type: ACTION_TYPES.GET_REPORT_DETAILS_BY_DATE_AND_PROJECT_SUCCESS,
    payload,
  };
};

const getReportDetailsByDateAndProjectFailure = () => {
  return {
    type: ACTION_TYPES.GET_REPORT_DETAILS_BY_DATE_AND_PROJECT_FAILURE,
  };
};

const getReportDetailsByDateAndProject = (payload: {
  date: string;
  project: string;
}) => {
  return async (dispatch: any) => {
    dispatch(dailyReportIsLoading());
    await API.getReportDetailsByDateAndProject(payload)
      .then(async (response: any) => {
        const results = await Utils.resolveResponse(response, true);
        if (!results) await dispatch(getReportDetailsByDateAndProjectFailure());
        else {
          const resolveResult: IDailyReport = results as IDailyReport;
          await dispatch(
            getReportDetailsByDateAndProjectSuccess(resolveResult)
          );
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(getReportDetailsByDateAndProjectFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createDailyReportSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_DAILY_REPORT_SUCCESS,
  };
};

const createDailyReportFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_DAILY_REPORT_FAILURE,
  };
};

const createDailyReport = (
  payload: FormData,
  project: string,
  filter?: IMeetingNoteFilterStructure
) => {
  return async (dispatch: any) => {
    dispatch(dailyReportIsLoading());
    await API.createDailyReport(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createDailyReportFailure());
        } else {
          await dispatch(createDailyReportSuccess());
          if (filter) await dispatch(fetchDailyReports(project, filter));
          const isDashboard = window.location.pathname === ROUTERS.DASHBOARD;
          if (isDashboard)
            await dispatch(
              fetchProjects({
                status: ENUMS.ProjectStatus.IN_PROGRESS,
                startDate: null,
              })
            );
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createDailyReportFailure());
      });
  };
};

const updateDailyReportSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_DAILY_REPORT_SUCCESS,
  };
};

const updateDailyReportFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_DAILY_REPORT_FAILURE,
  };
};

const updateDailyReport = (
  payload: FormData,
  id: string,
  project: string,
  filter?: IMeetingNoteFilterStructure
) => {
  return async (dispatch: any) => {
    dispatch(dailyReportIsLoading());
    await API.updateDailyReport(payload, id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateDailyReportFailure());
        } else {
          await dispatch(updateDailyReportSuccess());
          if (filter) await dispatch(fetchDailyReports(project, filter));
          const isDashboard = window.location.pathname === ROUTERS.DASHBOARD;
          if (isDashboard)
            await dispatch(
              fetchProjects({
                status: ENUMS.ProjectStatus.IN_PROGRESS,
                startDate: null,
              })
            );
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateDailyReportFailure());
      });
  };
};

const getDailyReportByIdSuccess = (payload: IMeetingNoteStructure) => {
  return {
    type: ACTION_TYPES.GET_DAILY_REPORT_BY_ID_SUCCESS,
    payload,
  };
};

const getDailyReportByIdFailure = () => {
  return {
    type: ACTION_TYPES.GET_DAILY_REPORT_BY_ID_FAILURE,
  };
};

const getDailyReportById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(dailyReportIsLoading());
    await API.getDailyReportById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getDailyReportByIdFailure());
        else {
          const resolveResult: IMeetingNoteStructure =
            result as IMeetingNoteStructure;
          dispatch(getDailyReportByIdSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(getDailyReportByIdFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const deleteDailyReportSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_DAILY_REPORT_SUCCESS,
  };
};

const deleteDailyReportFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_DAILY_REPORT_FAILURE,
  };
};

const deleteDailyReport = (
  id: string,
  projectId: string,
  filter: IMeetingNoteFilterStructure
) => {
  return async (dispatch: any) => {
    dispatch(dailyReportIsLoading());
    await API.deleteDailyReport(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteDailyReportFailure());
          toast.error(message);
        } else {
          dispatch(deleteDailyReportSuccess());
          dispatch(fetchDailyReports(projectId, filter));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(deleteDailyReportFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  setDefaultReducerDailyReport,
  fetchProjectReports,
  fetchDailyReports,
  createDailyReport,
  updateDailyReport,
  deleteDailyReport,
  getDailyReportById,
  resetDailyReportStatus,
  getReportDetailsByDateAndProject,
  clearReportDetails,
};
