import ACTION_TYPES from '@/Constants/Constants/ActionTypes.constant';
import API from '@/APIs';
import Utils from '@/Utils';
import _ from 'lodash';
import { IMetaStructure } from '@/Interfaces/Common.interface';

const clearAnnouncement = () => {
  return {
    type: ACTION_TYPES.CLEAR_ANNOUNCEMENT_USER,
  };
};

const setMetaPagination = (payload: IMetaStructure) => {
  return {
    type: ACTION_TYPES.SET_META_ANNOUNCEMENT_USER,
    payload,
  };
};

const AnnouncementsRequest = () => {
  return {
    type: ACTION_TYPES.ANNOUNCEMENT_IS_REQUEST_USER,
  };
};

const getForUserAnnouncementsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_FOR_USER_ANNOUNCEMENT_SUCCESS,
    payload,
  };
};

const getForUserAnnouncementsFailure = () => {
  return {
    type: ACTION_TYPES.GET_FOR_USER_ANNOUNCEMENT_FAILURE,
  };
};

const getForUserAnnouncements = () => {
  return async (dispatch: any) => {
    dispatch(AnnouncementsRequest());
    await API.getForUserAnnouncements()
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getForUserAnnouncementsFailure());
        else {
          dispatch(getForUserAnnouncementsSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await dispatch(getForUserAnnouncementsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const getAllforUserAnnouncementsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_ALL_FOR_USER_ANNOUNCEMENT_SUCCESS,
    payload,
  };
};

const getAllforUserAnnouncementsFailure = () => {
  return {
    type: ACTION_TYPES.GET_ALL_FOR_USER_ANNOUNCEMENT_FAILURE,
  };
};

const getAllforUserAnnouncements = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(AnnouncementsRequest());
    await API.getAllforUserAnnouncements(payload).then(async (res: any) => {
      const result: unknown = await Utils.resolveResponse(res, true);
      if (!result) await dispatch(getAllforUserAnnouncementsFailure());
      else {
        const meta: any = _.get(result, 'meta');
        dispatch(getAllforUserAnnouncementsSuccess(result));
        dispatch(setMetaPagination(meta));
      }
    });
  };
};

const updateReadAnnouncementsSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_READ_ANNOUNCEMENT_SUCCESS,
  };
};

const updateReadAnnouncementsFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_READ_ANNOUNCEMENT_FAILURE,
  };
};

const updateReadAnnouncements = (id: string, type: string, pagination: any) => {
  return async (dispatch: any) => {
    await API.updateReadAnnouncements(id, type)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(updateReadAnnouncementsFailure());
        } else {
          dispatch(updateReadAnnouncementsSuccess());
          dispatch(getForUserAnnouncements());
          dispatch(getAllforUserAnnouncements(pagination));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateReadAnnouncementsFailure());
      });
  };
};

export default {
  clearAnnouncement,
  getForUserAnnouncements,
  getAllforUserAnnouncements,
  updateReadAnnouncements,
};
