import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import _ from 'lodash';
import { Images } from '@/Themes';

const marketTingResearch = [
  { label: 'Brand Research' },
  { label: 'Consumer Research' },
  { label: 'Concept / Product Testing' },
  { label: 'Customer Satisfaction Studies' },
  { label: 'Employee Research' },
  { label: 'Market Analysis and Evaluation' },
  { label: 'Performance Evaluations and Studies' },
  { label: 'Pricing Studies' },
  { label: 'Segmentation Studies' },
  { label: 'Tracking Studies' },
  { label: 'Usage and Attitude Studies' },
];

const POLLS = [
  { label: 'Election Polling' },
  { label: 'Life Quality and Stratification Studies' },
  { label: 'General Issues Assessment' },
];

const ECONOMIC = [
  { label: 'Business Environment Research' },
  { label: 'Business to Business (B2B) studies' },
];

const MEDIA = [
  { label: 'TV and radio audience research' },
  { label: 'Media Evaluation/Research' },
];

const RESEARCH = [
  { label: 'TV and radio audience research' },
  { label: 'Media Evaluation/Research' },
];

const omnibus: any[] = [];

const dataMarketSectors = [
  { label: 'Advertising / Public Relations' },
  { label: 'Confectionery' },
  { label: 'Financial Services/Banking' },
  { label: 'IT / Software / Hardware' },
  { label: 'Petrol / Oil / Gas' },
  { label: 'Retail / Wholesale' },
  { label: 'Tobacco /Cigarettes' },
  { label: 'Cosmetics / Hygiene/Detergents' },
  { label: 'Energy / Utilities' },
  { label: 'Healthcare / Pharmaceutical' },
  { label: 'Media / Entertainment' },
  { label: 'Public Sector / Government' },
  { label: 'Travel / Tourism / Sport / Leisure' },
];

interface IPropsMarket {
  callback(action?: string, data?: any): void;
}

const MarketReSearch: React.FC<IPropsMarket> = ({ callback }) => {
  const _renderMenuList = (data: any[]) => {
    return (
      <Box ml={2}>
        {_.map(data, (item: any, index: number) => {
          return (
            <Box
              key={index}
              display="flex"
              sx={{ alignItems: 'center', mb: '10px' }}
            >
              <AcUnitIcon sx={{ fontSize: 8, mr: 1 }} />
              <Typography
                sx={{
                  fontSize: 18,
                  lineHeight: '32px',
                  color: '#3f3f3f',
                  fontWeight: 400,
                }}
              >
                {item?.label}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  const _renderMarketSectors = () => {
    return (
      <Box>
        <Typography
          component="h3"
          sx={{
            fontSize: 26,
            color: '#212121',
            fontWeight: 700,
            fontStyle: 'normal',
            lineHeight: '30px',
            position: 'relative',
            pl: 2,
            my: 4,
            '&::after': {
              content: '""',
              position: 'absolute',
              width: 4,
              height: 30,
              backgroundColor: '#0068c5',
              bottom: 0,
              left: '0',
            },
          }}
        >
          RESEARCH SERVICES
        </Typography>
        <Box ml={1}>
          <Grid container>
            {_.map(dataMarketSectors, (item: any, index: number) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={2.6}
                  key={index}
                  sx={{
                    border: 'solid 1px #898989',
                    p: '11px 26px',
                    mx: { xs: 1, md: 2 },
                    my: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: '0.5s',
                    borderRadius: '15px',
                    '&:hover': {
                      background: '#1C79CB',
                      color: '#ffffff',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 20,
                      lineHeight: '30px',
                      textAlign: 'center',
                      color: '#3f3f3f',
                      fontWeight: 600,
                      '&:hover': {
                        color: '#ffffff',
                      },
                    }}
                  >
                    {item.label}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    );
  };

  return (
    <Box px={2}>
      <Box>
        <Typography
          component="h3"
          sx={{
            fontSize: 26,
            color: '#212121',
            fontWeight: 700,
            fontStyle: 'normal',
            lineHeight: '30px',
            position: 'relative',
            pl: 2,
            my: 4,
            '&::after': {
              content: '""',
              position: 'absolute',
              width: 4,
              height: 30,
              backgroundColor: '#0068c5',
              bottom: 0,
              left: '0',
            },
          }}
        >
          RESEARCH SERVICES
        </Typography>
        <Box
          sx={{
            fontSize: 18,
            lineHeight: '28px',
            fontWeight: 400,
            mt: 2,
          }}
        >
          FLOWIDRA offers full-scale market research services. It offers both
          quantitative and qualitative high quality research for private and
          institutional clients. The methods used include face-to-face, CATI,
          CAWI, focus groups, in-depth interviews, product testing, mystery
          shopping, etc. FLOWIDRA has more than 400 interviewers covering all
          the regions of Albania and 250 interveiws covering all of Kosovo. It
          also possesses all the equipments and facilities for qualitative
          research (focus groups, in-depth interviews). Many leading brands in
          the market use IDRA research services. FLOWIDRA is a proud member of
          ESOMAR (www.esomar.org) and applies ISO standard 20252-2012 for
          &quot;Market, opinion and social research&quot;.
          <Typography
            sx={{
              fontSize: 18,
              lineHeight: '28px',
              fontWeight: 400,
              mt: 2,
            }}
          >
            FLOWIDRA is a proud member of ESOMAR (www.esomar.org) and applies
            ISO standard 20252-2012 for &quot;Market, opinion and social
            research&quot;.
          </Typography>
        </Box>
        <Grid
          container
          sx={{
            background: '#0068c5',
            backgroundImage: `url(${Images.bgResearchservices})`,
            minHeight: 380,
            px: { xs: 4, md: 8 },
            py: 4,
            my: 4,
            display: { xs: 'block', sm: 'flex' },
            justifyContent: 'space-between',
          }}
        >
          <Grid
            item
            xs={12}
            sm={5.5}
            sx={{
              background: '#ffffff',
              border: '3px solid #0099FF',
              borderRadius: '15px',
              // mr: { xs: 0, sm: 10 },
              p: { xs: 4, sm: 4, md: 8 },
              mb: { xs: 4, sm: 0 },
              '&:hover': {
                border: '3px solid #1C79CB',
              },
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ width: 25, height: 25, mr: 1 }}>
                <Box
                  component="img"
                  src={Images.iconQuantitative}
                  sx={{ width: 1, height: 1 }}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: 20,
                  lineHeight: '30px',
                  color: '#0068c5',
                  mb: 2,
                }}
              >
                Quantitative
              </Typography>
            </Box>
            <Box sx={{ height: { xs: 150, sm: 165 }, mb: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AcUnitIcon sx={{ fontSize: 10, mr: 1 }} />
                <Typography
                  sx={{
                    fontSize: 18,
                    lineHeight: '28px',
                    color: '#3f3f3f',
                  }}
                >
                  Face-to-face (PAPI/CAPI)
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AcUnitIcon sx={{ fontSize: 10, mr: 1 }} />
                <Typography
                  sx={{
                    fontSize: 18,
                    lineHeight: '28px',
                    color: '#3f3f3f',
                  }}
                >
                  CATI
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AcUnitIcon sx={{ fontSize: 10, mr: 1 }} />

                <Typography
                  sx={{
                    fontSize: 18,
                    lineHeight: '28px',
                    color: '#3f3f3f',
                  }}
                >
                  CAWI
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AcUnitIcon sx={{ fontSize: 10, mr: 1 }} />
                <Typography
                  sx={{
                    fontSize: 18,
                    lineHeight: '28px',
                    color: '#3f3f3f',
                  }}
                >
                  Central Location Testing
                </Typography>
              </Box>
            </Box>
            <Box>
              <Button
                variant="outlined"
                sx={{
                  color: '#ffffff',
                  border: '2px solid #1C79CB',
                  background: '#0068c5',
                  '&:hover': {
                    border: '2px solid #0068c5',
                    background: '#ffffff',
                    color: '#0068c5',
                  },
                }}
                onClick={() => {
                  callback('market', 'market_1');
                  window.scrollTo({
                    top: 450,
                    behavior: 'smooth',
                  });
                }}
              >
                READ MORE +
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5.5}
            sx={{
              background: '#ffffff',
              border: '3px solid #0099FF',
              borderRadius: '15px',
              p: { xs: 4, sm: 4, md: 8 },
              '&:hover': {
                border: '3px solid #1C79CB',
              },
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ width: 25, height: 25, mr: 1 }}>
                <Box
                  component="img"
                  src={Images.iconQuantitative}
                  sx={{ width: 1, height: 1 }}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: 20,
                  lineHeight: '30px',
                  color: '#0068c5',
                  mb: 2,
                }}
              >
                Quantitative
              </Typography>
            </Box>
            <Box sx={{ height: { xs: 150, sm: 165 }, mb: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AcUnitIcon sx={{ fontSize: 10, mr: 1 }} />
                <Typography
                  sx={{
                    fontSize: 18,
                    lineHeight: '28px',
                    color: '#3f3f3f',
                  }}
                >
                  Focus groups
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AcUnitIcon sx={{ fontSize: 10, mr: 1 }} />

                <Typography
                  sx={{
                    fontSize: 18,
                    lineHeight: '28px',
                    color: '#3f3f3f',
                  }}
                >
                  In-depth interviews
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AcUnitIcon sx={{ fontSize: 10, mr: 1 }} />
                <Typography
                  sx={{
                    fontSize: 18,
                    lineHeight: '28px',
                    color: '#3f3f3f',
                  }}
                >
                  Desk research
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AcUnitIcon sx={{ fontSize: 10, mr: 1 }} />
                <Typography
                  sx={{
                    fontSize: 18,
                    lineHeight: '28px',
                    color: '#3f3f3f',
                  }}
                >
                  Observational research
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AcUnitIcon sx={{ fontSize: 10, mr: 1 }} />
                <Typography
                  sx={{
                    fontSize: 18,
                    lineHeight: '28px',
                    color: '#3f3f3f',
                  }}
                >
                  Mystery shopping
                </Typography>
              </Box>
            </Box>
            <Box>
              <Button
                variant="outlined"
                sx={{
                  color: '#ffffff',
                  border: '2px solid #1C79CB',
                  background: '#0068c5',
                  '&:hover': {
                    border: '2px solid #0068c5',
                    background: '#ffffff',
                    color: '#0068c5',
                  },
                }}
                onClick={() => {
                  callback('market', []);
                  window.scrollTo({
                    top: 450,
                    behavior: 'smooth',
                  });
                }}
              >
                READ MORE +
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography
          component="h3"
          sx={{
            fontSize: 26,
            color: '#212121',
            fontWeight: 700,
            fontStyle: 'normal',
            lineHeight: '30px',
            position: 'relative',
            pl: 2,
            my: 4,
            '&::after': {
              content: '""',
              position: 'absolute',
              width: 4,
              height: 30,
              backgroundColor: '#0068c5',
              bottom: 0,
              left: '0',
            },
          }}
        >
          RESEARCH SOLUTIONS
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Box>
            <Typography
              sx={{ color: '#212121', fontWeight: 700, fontSize: 20, mb: 2 }}
            >
              Marketing Research
            </Typography>
            {_renderMenuList(marketTingResearch)}
          </Box>
          <Box>
            <Typography
              sx={{ color: '#212121', fontWeight: 700, fontSize: 20, mb: 2 }}
            >
              Opinion Polls
            </Typography>
            {_renderMenuList(POLLS)}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <Typography
              sx={{ color: '#212121', fontWeight: 700, fontSize: 20, mb: 2 }}
            >
              Economic Research
            </Typography>
            {_renderMenuList(ECONOMIC)}
          </Box>
          <Box>
            <Typography
              sx={{ color: '#212121', fontWeight: 700, fontSize: 20, mb: 2 }}
            >
              Media Research
            </Typography>
            {_renderMenuList(MEDIA)}
          </Box>
          <Box>
            <Typography
              sx={{ color: '#212121', fontWeight: 700, fontSize: 20, mb: 2 }}
            >
              Advertising Research
            </Typography>
            {_renderMenuList(RESEARCH)}
          </Box>
          <Box>
            <Typography
              sx={{ color: '#212121', fontWeight: 700, fontSize: 20, mb: 2 }}
            >
              Omnibus
            </Typography>
            {_renderMenuList(omnibus)}
          </Box>
        </Grid>
      </Grid>
      <Box>{_renderMarketSectors()}</Box>
    </Box>
  );
};

export default MarketReSearch;
