import CONSTANTS from '@/Constants';
import API from '@/APIs';
import {
  IUserInformationDetailsStructure,
  IFilterUserInformationStructure,
} from '@/Interfaces/UserInformation.interface';
import Utils from '@/Utils';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { Alert } from '@/Widgets';
import { IUserDetailsStructure } from '../Interfaces/User.interface';
import { AuthActions, PermissionActions } from '.';

const { ACTION_TYPES } = CONSTANTS;

const { logout } = AuthActions;
const { getRoleById } = PermissionActions;

// SINGLE ACTIONS
const userIsRequest = () => {
  return {
    type: ACTION_TYPES.USER_INFORMATION_IS_REQUEST,
  };
};

const setUsersMeta = (payload: any) => {
  return {
    type: ACTION_TYPES.SET_USERS_META,
    payload,
  };
};

const clearUserPayload = () => {
  return {
    type: ACTION_TYPES.CLEAR_USER_PAYLOAD,
  };
};

const clearStatusUserDetails = () => {
  return {
    type: ACTION_TYPES.CLEAR_STATUS_USER_DETAILS,
  };
};

const resetUserReducer = () => {
  return {
    type: ACTION_TYPES.RESET_USER_REDUCER,
  };
};

const resetUserStatus = () => {
  return {
    type: ACTION_TYPES.RESET_USER_STATUS,
  };
};

// ASYNC ACTIONS
const fetchUserSuccess = (payload: IUserInformationDetailsStructure) => {
  return {
    type: ACTION_TYPES.FETCH_USERS_SUCCESS,
    payload,
  };
};

const fetchUserFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_USERS_FAILURE,
  };
};

const fetchUser = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.fetchUser(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchUserFailure());
        else {
          const resolveResult: { items: any; meta: any } = result as {
            items: any;
            meta: any;
          };
          dispatch(setUsersMeta(resolveResult.meta));
          dispatch(fetchUserSuccess(resolveResult.items));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(fetchUserFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const getUserByIdFailure = () => {
  return {
    type: ACTION_TYPES.GET_USER_BY_ID_FAILURE,
  };
};

const getUserByIdSuccess = (payload: IUserInformationDetailsStructure) => {
  return {
    type: ACTION_TYPES.GET_USER_BY_ID_SUCCESS,
    payload,
  };
};

const getUserById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.getUserById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getUserByIdFailure());
        else {
          const resolveResult: IUserInformationDetailsStructure =
            result as IUserInformationDetailsStructure;
          dispatch(getUserByIdSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(getUserByIdFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createUserFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_USER_FAILURE,
  };
};

const createUserSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_USER_SUCCESS,
  };
};

const createUser = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.createUser(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response.data.message;
        if (!result) {
          toast.error(message);
          await dispatch(createUserFailure());
        } else {
          toast.success(message);
          dispatch(createUserSuccess());
        }

        return true;
      })
      .catch(async (error) => {
        await dispatch(createUserFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const updateUserFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_USER_INFORMATION_FAILURE,
  };
};

const updateUserSuccess = (payload: IUserInformationDetailsStructure) => {
  return {
    type: ACTION_TYPES.UPDATE_USER_INFORMATION_SUCCESS,
    payload,
  };
};

const updateUser = (id: string, payload: any) => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.updateUser(id, payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateUserFailure());
        } else {
          const resolveResult: IUserInformationDetailsStructure =
            result as IUserInformationDetailsStructure;

          await dispatch(updateUserSuccess(resolveResult));

          const userPayload = Utils.getSavedUserData();

          if (userPayload.id === id) Utils.saveUserData(resolveResult);

          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(updateUserFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const changePasswordFailure = () => {
  return {
    type: ACTION_TYPES.CHANGE_PASSWORD_FAILURE,
  };
};

const changePasswordSuccess = () => {
  return {
    type: ACTION_TYPES.CHANGE_PASSWORD_SUCCESS,
  };
};

const changePassword = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.changePassword(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response.data.message;
        if (!result) {
          toast.error(message);
          await dispatch(changePasswordFailure());
        } else {
          toast.success(message);
          dispatch(changePasswordSuccess());
          dispatch(logout());
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error);
        await dispatch(changePasswordFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const exportUsersFailure = () => {
  return {
    type: ACTION_TYPES.EXPORT_USERS_FAILURE,
  };
};

const exportUsersSuccess = () => {
  return {
    type: ACTION_TYPES.EXPORT_USERS_SUCCESS,
  };
};

const exportUsers = (exportPayload: any) => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.exportUsers(exportPayload)
      .then(async (response: any) => {
        const data = response?.data;
        if (!data) {
          toast.error('Export users failure!');
          await dispatch(exportUsersFailure());
        } else {
          saveAs(
            data,
            `FLOW_IDRA_Users_List_${dayjs().format('DD_MM_YYYY')}.xlsx`
          );
          toast.success('Export users successfully!');
          dispatch(exportUsersSuccess());
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(exportUsersFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const activateUserSuccess = () => {
  return {
    type: ACTION_TYPES.ACTIVATE_USER_SUCCESS,
  };
};

const activateUserFail = () => {
  return {
    type: ACTION_TYPES.ACTIVATE_USER_FAILURE,
  };
};

const activateUser = (id: string, filter: IFilterUserInformationStructure) => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.activateUser(id)
      .then(async (response: any) => {
        const { status, message } = response?.data;
        if (!status) {
          Alert({
            type: 'ERROR',
            message,
          });
          await dispatch(activateUserFail());
          toast.error(message);
        } else {
          await dispatch(fetchUser(filter));
          await dispatch(activateUserSuccess());
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(activateUserFail());
        toast.error(error);
      });
  };
};

const deactivateUserSuccess = () => {
  return {
    type: ACTION_TYPES.DEACTIVATE_USER_SUCCESS,
  };
};

const deactivateUserFail = () => {
  return {
    type: ACTION_TYPES.DEACTIVATE_USER_FAILURE,
  };
};

const deactivateUser = (
  id: string,
  filter: IFilterUserInformationStructure
) => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.deactivateUser(id)
      .then(async (response: any) => {
        const { status, message } = response?.data;
        if (!status) {
          Alert({
            type: 'ERROR',
            message,
          });
          await dispatch(deactivateUserFail());
          toast.error(message);
        } else {
          await dispatch(fetchUser(filter));
          await dispatch(deactivateUserSuccess());
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deactivateUserFail());
        toast.error(error);
      });
  };
};

const updateAvatarUserSuccess = (payload: IUserDetailsStructure) => {
  return {
    type: ACTION_TYPES.UPDATE_AVATAR_USER_SUCCESS,
    payload,
  };
};

const updateAvatarUserFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_AVATAR_USER_FAILURE,
  };
};

const updateAvatarUser = (id: string, payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.updateAvatarUser(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        const { status, message } = result;
        if (!status) {
          await dispatch(updateAvatarUserFailure());
          toast.error(message);
        } else {
          const resolveResult: IUserDetailsStructure =
            result as IUserDetailsStructure;
          dispatch(updateAvatarUserSuccess(resolveResult));
          toast.error(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(updateAvatarUserFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const updateProfileFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_PROFILE_FAILURE,
  };
};

const updateProfileSuccess = (payload: IUserInformationDetailsStructure) => {
  return {
    type: ACTION_TYPES.UPDATE_PROFILE_SUCCESS,
    payload,
  };
};

const updateProfile = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.updateProfile(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateProfileFailure());
        } else {
          const resolveResult: IUserInformationDetailsStructure =
            result as IUserInformationDetailsStructure;

          await dispatch(updateProfileSuccess(resolveResult));
          Utils.saveUserData(resolveResult);

          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(updateProfileFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const updateAvatarProfileSuccess = (payload: IUserDetailsStructure) => {
  return {
    type: ACTION_TYPES.UPDATE_AVATAR_PROFILE_SUCCESS,
    payload,
  };
};

const updateAvatarProfileFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_AVATAR_PROFILE_FAILURE,
  };
};

const updateAvatarProfile = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.updateAvatarProfile(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        const { status, message } = result;
        if (!status) {
          await dispatch(updateAvatarProfileFailure());
          toast.error(message);
        } else {
          const resolveResult: IUserDetailsStructure =
            result as IUserDetailsStructure;
          dispatch(updateAvatarProfileSuccess(resolveResult));
          Utils.saveUserData(resolveResult);
          toast.error(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(updateAvatarProfileFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const fetchUserUnassignedRoleSuccess = (payload: IUserDetailsStructure[]) => {
  return {
    type: ACTION_TYPES.FETCH_USER_UNASSIGNED_ROLE_SUCCESS,
    payload,
  };
};

const fetchUserUnassignedRoleFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_USER_UNASSIGNED_ROLE_FAILURE,
  };
};

const fetchUserUnassignedRole = () => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.fetchUserUnassignedRole()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        const { message } = result;
        if (!result) {
          await dispatch(fetchUserUnassignedRoleFailure());
          toast.error(message);
        } else {
          const resolveResult: IUserDetailsStructure[] =
            result as IUserDetailsStructure[];
          dispatch(fetchUserUnassignedRoleSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(fetchUserUnassignedRoleFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const assignRoleForUserSuccess = () => {
  return {
    type: ACTION_TYPES.ASSIGN_ROLE_FOR_USER_SUCCESS,
  };
};

const assignRoleForUserFailure = () => {
  return {
    type: ACTION_TYPES.ASSIGN_ROLE_FOR_USER_FAILURE,
  };
};

const assignRoleForUser = (id: string, payload: { role: string }) => {
  return async (dispatch: any) => {
    dispatch(userIsRequest());
    await API.assignRoleForUser(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        const { message } = result;
        if (!result) {
          await dispatch(assignRoleForUserFailure());
          toast.error(message);
        } else {
          dispatch(getRoleById(payload.role));
          dispatch(fetchUserUnassignedRole());
          dispatch(assignRoleForUserSuccess());
          toast.error(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(assignRoleForUserFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  fetchUser,
  getUserById,
  createUser,
  updateUser,
  changePassword,
  exportUsers,
  clearUserPayload,
  activateUser,
  deactivateUser,
  clearStatusUserDetails,
  updateAvatarUser,
  updateAvatarProfile,
  resetUserReducer,
  updateProfile,
  resetUserStatus,
  fetchUserUnassignedRole,
  assignRoleForUser,
};
