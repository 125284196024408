import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import dayjs from 'dayjs';

import { Box, Tooltip, IconButton, Avatar, Typography } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { deepPurple } from '@mui/material/colors';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { StickyHeadTable } from '@/Components/Common';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { IAnnouncementStructure } from '@/Interfaces/Announcement.interface';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang';
import CONSTANTS from '@/Constants';
import Utils from '@/Utils';

const { MODULE_API } = CONSTANTS;

interface ISectionProps {
  meta: IMetaStructure;
  payload: any;
  onRowAction(Project: any, action: 'edit' | 'remove' | 'report'): void;
  onPageAction(value: number, type: 'limit' | 'page'): void;
  isLoading?: boolean;
}

interface IColumnStructure {
  id: 'no' | 'title' | 'receiver' | 'created_at' | 'action' | 'created_by';
  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  {
    id: 'title',
    label: 'title',
    minWidth: 150,
    maxWidth: 250,
    align: 'center',
  },
  {
    id: 'receiver',
    label: 'receiver',
    minWidth: 130,
    maxWidth: 250,
    align: 'center',
  },
  { id: 'created_by', label: 'createdBy', minWidth: 120, align: 'center' },
  { id: 'created_at', label: 'createdAt', minWidth: 120, align: 'center' },
  { id: 'action', label: 'action', minWidth: 130, align: 'center' },
];

const createDataTable = (
  no: string,
  title: JSX.Element,
  receiver: JSX.Element,
  created_by: string,
  created_at: string,
  action: JSX.Element
): any => {
  return {
    no,
    title,
    receiver,
    created_by,
    created_at,
    action,
  };
};

const AnnouncementDataTable: React.FC<ISectionProps> = ({
  payload,
  meta,
  onPageAction,
  onRowAction,
  isLoading = false,
}) => {
  const t = useTranslation();
  const [rows, setRows] = useState<any[]>([]);

  const isAcceptDeletedAnnouncement = Utils.isValidPermission(
    MODULE_API.ANNOUNCEMENT.DELETE_ANNOUNCEMENT
  );
  const isAcceptChangeStatusdAnnouncement = Utils.isValidPermission(
    MODULE_API.ANNOUNCEMENT.UPDATE_ANNOUNCEMENT_TYPE
  );
  const isAcceptDetailsAnnouncement = Utils.isValidPermission(
    MODULE_API.ANNOUNCEMENT.GET_ANNOUNCEMENT_BY_ID
  );

  React.useEffect(() => {
    const resolveRows: any = [];
    if (!isEmpty(payload)) {
      forEach(payload, (item: IAnnouncementStructure, index: number) => {
        const { title, createdAt, receivers, type, createdBy } = item;
        const sender = createdBy.email;
        const recevierList = _.map(receivers, (el: any) => el.receiver);
        const createdTime = dayjs(createdAt);

        const currentPage = get(meta, 'currentPage');
        const limit = get(meta, 'itemsPerPage');
        const resolveRowIndex = index + 1 + (currentPage - 1) * limit;

        resolveRows.push(
          createDataTable(
            `${resolveRowIndex}`,
            <Typography
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                height: 1,
                maxWidth: 250,
              }}
            >
              {title}
            </Typography>,
            <Box display={'flex'} justifyContent="left">
              {_.map(recevierList, (receiver: any, innerKey: number) => {
                return (
                  innerKey < 5 && (
                    <Box
                      sx={{ display: 'flex' }}
                      title={receiver.email}
                      key={innerKey}
                    >
                      {receiver?.userData?.avatar ? (
                        <Box>
                          <Avatar
                            alt={receiver.userData.fullName.slice(0, 1)}
                            sx={{
                              bgcolor: deepPurple[200],
                              fontSize: 8,
                              textAlign: 'center',
                              mr: '2px',
                            }}
                            style={{ fontSize: 15, border: 'red' }}
                            src={receiver.userData.avatar.path}
                          />
                        </Box>
                      ) : (
                        <Box>
                          <Avatar
                            sx={{
                              bgcolor: deepPurple[200],
                              fontSize: 8,
                              textAlign: 'center',
                              mr: '2px',
                            }}
                            style={{ fontSize: 15 }}
                          >
                            {receiver.userData.fullName.slice(0, 1)}
                          </Avatar>
                        </Box>
                      )}
                    </Box>
                  )
                );
              })}
              <Box>
                {recevierList.length > 5 && (
                  <Box
                    sx={{
                      border: '1px',
                      borderRadius: '20px',
                      width: 40,
                      height: 40,
                      background: '#EEEEEE',
                      position: 'relative',
                    }}
                  >
                    <Typography
                      sx={{
                        margin: 0,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      +{recevierList.length - 5}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>,
            sender,
            createdTime ? createdTime.format('HH:mm, DD-MM-YYYY') : 'N/I',
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              {isAcceptChangeStatusdAnnouncement && (
                <Box>
                  {type !== 'hide' ? (
                    <Tooltip title={t('tooltip.clickToHideAnnouncement')}>
                      <span>
                        <IconButton onClick={() => onRowAction(item, 'report')}>
                          <ToggleOnIcon sx={{ color: 'blue', fontSize: 35 }} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('tooltip.clickToShowAnnouncement')}>
                      <span>
                        <IconButton onClick={() => onRowAction(item, 'report')}>
                          <ToggleOffIcon sx={{ color: 'gray', fontSize: 35 }} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </Box>
              )}
              {isAcceptDetailsAnnouncement && (
                <Tooltip title={t('tooltip.clickToDetailThisAnnouncement')}>
                  <span>
                    <IconButton onClick={() => onRowAction(item, 'edit')}>
                      <PreviewIcon fontSize="medium" />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {isAcceptDeletedAnnouncement && (
                <Tooltip title={t('tooltip.clickToRemoveAnnouncement')}>
                  <span>
                    <IconButton onClick={() => onRowAction(item, 'remove')}>
                      <DeleteOutlineIcon fontSize="medium" />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Box>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      onAction={onPageAction}
      paginate={meta}
      isLoading={isLoading}
    />
  );
};

export default AnnouncementDataTable;
