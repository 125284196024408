import React, { useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { useSelector } from 'react-redux';

import { getSavedUserData } from '@/Utils/Cookie.utils';
import Utils from '@/Utils';
import CONSTANTS from '@/Constants';
import { RootState } from '@/store';
import { IUserInformationDetailsStructure } from '@/Interfaces/User.interface';

const { MODULE_API } = CONSTANTS;
interface IAnnouncement {
  payload: IUserInformationDetailsStructure[];
  onUnfocus?(data?: IUserInformationDetailsStructure[]): void;
  details?: any;
  onAddNewIds?(ids: string[]): void;
  onAddRemoveIds?(ids: string[]): void;
  message?: string;
  sxContainer?: object;
}

const TransferList: React.FC<IAnnouncement> = ({
  payload,
  onUnfocus,
  details,
  onAddNewIds,
  onAddRemoveIds,
  message,
  sxContainer,
}) => {
  const t = useTranslation();
  const [onChecked, setOnChecked] = React.useState<any>([]);

  const [checked, setChecked] = React.useState<any>([]);
  const [choose, setChoose] = React.useState<any>([]);
  const [chosen, setChosen] = React.useState<any>([]);
  const [currentSide, setCurrentSide] = React.useState<
    'left' | 'right' | 'default'
  >('default');
  const [isInitChosen, setIsInitChosen] = React.useState<boolean>(false);
  const [isInitChoose, setIsInitChoose] = React.useState<boolean>(false);
  const isLoading: IUserInformationDetailsStructure[] = useSelector(
    (state: RootState) => _.get(state.USER, 'requestIsLoading')
  );
  const isAcceptEdit = Utils.isValidPermission(MODULE_API.WORK_PROGRESS.UPDATE);
  const userData = getSavedUserData();

  const generateNewPayload = (init: IUserInformationDetailsStructure[]) => {
    const resolveOptions: any[] = [];
    if (choose) {
      _.map(init, (item: any) => {
        resolveOptions.push({
          label: item.userData.fullName,
          id: item.id,
          avatar: item.userData?.avatar,
        });
      });
    }
    return resolveOptions;
  };

  useEffect(() => {
    if (!_.isEmpty(payload || !_.isEmpty(payload))) {
      const newPayload = generateNewPayload(payload);
      if (_.isEmpty(chosen)) {
        const recevier = _.map(details?.wasAssigned, (element: any) => element);
        const newRecevier = _.filter(newPayload, (item: any) =>
          _.find(details?.wasAssigned, ['id', item.id])
        );
        const newLeftList = _.filter(
          newPayload,
          (item: any) => !_.find(recevier, ['id', item.id])
        );
        if (!isInitChosen && !_.isEmpty(newRecevier)) {
          setChosen(newRecevier);
          setIsInitChosen(true);
        } else setChoose(newPayload);
        if (!isInitChoose && !_.isEmpty(newLeftList)) {
          setChoose(newLeftList);
          setIsInitChoose(true);
        }
      } else {
        const newLeftList = _.filter(
          newPayload,
          (item: any) => !_.find(chosen, ['id', item.id])
        );
        setChoose(newLeftList);
        // setChecked([]);
        // setCurrentSide('default');
      }
    }
  }, [payload]);

  const handleToggle = (value: any, postion: 'left' | 'right') => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const newOnChecked = [...onChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
      newOnChecked.push(value.id);
    } else {
      newChecked.splice(currentIndex, 1);
      newOnChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setCurrentSide(postion);
    setOnChecked(newOnChecked);
    if (_.isEmpty(newChecked)) setCurrentSide('default');
  };

  const handleCheckedRight = () => {
    const newRightList = [...chosen, ...checked];
    const newLeftList = _.filter(
      choose,
      (item: any) => !_.find(checked, ['id', item.id])
    );
    const newReceiverIds = _.map(newRightList, (element: any) => element.id);
    if (onUnfocus) onUnfocus(newReceiverIds);
    setChoose(newLeftList);
    setChosen(_.uniqWith(newRightList, _.isEqual));
    setChecked([]);
    setOnChecked([]);
    setCurrentSide('default');
    const checkedIds = _.map(checked, (item: any) => item?.id);
    if (onAddNewIds) onAddNewIds(checkedIds);
  };

  const handleCheckedLeft = () => {
    const newLeftList = [...choose, ...checked];
    const newRightList = _.filter(
      chosen,
      (item: any) => !_.find(checked, ['id', item.id])
    );
    const recevierIds = _.map(newRightList, (element: any) => element.id);
    if (onUnfocus) onUnfocus(recevierIds);
    setChoose(_.uniqWith(newLeftList, _.isEqual));
    setChosen(newRightList);
    setChecked([]);
    setOnChecked([]);
    setCurrentSide('default');
    const checkedIds = _.map(checked, (item: any) => item?.id);
    if (onAddRemoveIds) onAddRemoveIds(checkedIds);
  };

  const handleAllRight = () => {
    const newListRight = _.filter(
      generateNewPayload(payload),
      (item: any) => !_.find(chosen, ['id', item.id])
    );
    const resolveLeftArray = chosen;
    resolveLeftArray.push(...newListRight);
    setChosen(resolveLeftArray);

    const recevier = _.map(
      generateNewPayload(payload),
      (element: any) => element.id
    );
    const newRightList = _.filter(recevier, (item: any) =>
      _.find(chosen, ['id', item])
    );
    if (onUnfocus) onUnfocus(recevier);
    if (onAddNewIds) onAddNewIds(newRightList);
    setChoose([]);
  };

  const handleAllLeft = () => {
    setChoose(generateNewPayload(payload));
    const recevier = _.map(
      generateNewPayload(payload),
      (element: any) => element.id
    );
    const newLeftList = _.filter(
      recevier,
      (item: any) => !_.find(choose, ['id', item])
    );
    if (onUnfocus) onUnfocus([]);
    if (onAddRemoveIds) onAddRemoveIds(newLeftList);
    setChosen([]);
  };

  const customList = (
    ListData: IUserInformationDetailsStructure[],
    position: 'left' | 'right'
  ) => {
    return (
      <Paper
        className={!_.isEmpty(message) ? 'required' : ''}
        sx={{
          width: 1,
          height: 350,
          overflow: 'auto',
          position: 'relative',
          '&.required': {
            outline: '1px solid #d32f2f',
            borderColor: 'transparent',
          },
          ...sxContainer,
        }}
      >
        {isLoading ? (
          <CircularProgress
            sx={{
              position: 'absolute',
              left: '50%',
              top: '20%',
            }}
          />
        ) : (
          <List dense component="div" role="list">
            {_.map(
              _.orderBy(ListData, ['label']),
              (item: any, index: number) => {
                const labelId = `transfer-list-item-${index}-label`;
                return (
                  <ListItem key={index} role="listitem">
                    <ListItemIcon>
                      <Checkbox
                        // checked={checked.indexOf(item) !== -1}
                        checked={_.includes(onChecked, item.id)}
                        key={index}
                        disabled={
                          (position !== currentSide &&
                            currentSide !== 'default') ||
                          !isAcceptEdit
                        }
                        // tabIndex={-1}
                        disableRipple
                        onChange={() => {
                          handleToggle(item, position);
                        }}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={
                        <Box
                          sx={{
                            display: 'flex',
                            background:
                              item.id === userData.id ? '#EEEEEE' : 'none',
                          }}
                          title={item.email}
                        >
                          {item?.avatar ? (
                            <Avatar
                              alt={item.label.slice(0, 1)}
                              sx={{
                                bgcolor: deepPurple[200],
                                fontSize: 8,
                                textAlign: 'center',
                                mr: '2px',
                              }}
                              style={{ fontSize: 15, border: 'red' }}
                              src={item.avatar.path}
                            />
                          ) : (
                            <Avatar
                              sx={{
                                bgcolor: deepPurple[200],
                                fontSize: 10,
                                textAlign: 'center',
                              }}
                            >
                              {item?.label?.slice(0, 1)}
                            </Avatar>
                          )}
                          <Typography mt={1} ml={2}>
                            {item?.label}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                );
              }
            )}
          </List>
        )}
      </Paper>
    );
  };

  const _renderBody = () => {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item xs={5}>
          <Typography fontWeight={450}>
            {t('label.choose')}: {choose.length}
          </Typography>
          {customList(choose, 'left')}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={choose.length === 0 || checked.length > 0}
              aria-label="move all right"
              disableElevation={!isAcceptEdit}
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={() => handleCheckedRight()}
              disabled={
                choose.length === 0 ||
                currentSide === 'right' ||
                checked.length === 0
              }
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={
                chosen.length === 0 ||
                checked.length === 0 ||
                currentSide === 'left'
              }
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={
                chosen.length === 0 || checked.length > 0 || !isAcceptEdit
              }
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Typography fontWeight={450}>
            {t('label.chosen')}: {chosen.length}
          </Typography>
          {customList(chosen, 'right')}
        </Grid>
      </Grid>
    );
  };

  return <Grid container>{_renderBody()}</Grid>;
};

export default TransferList;
