import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 1,
  itemsPerPage: 10,
  totalItems: 1,
  totalPages: 1,
};

const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  updateDocumentationSuccess: false,
  details: {},
  payload: [],
  allForUser: [],
  forDashboard: [],
  meta: DEFAULT_META,
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.CLEAR_FEEDBACK:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        details: {},
        payload: [],
        allForUser: [],
        forDashboard: [],
      };
    case ACTION_TYPES.FEEDBACK_IS_REQUEST:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.SET_META_FEEDBACK:
      return {
        ...state,
        meta: payload,
      };

    case ACTION_TYPES.FETCH_FEEDBACK_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload,
      };
    case ACTION_TYPES.FETCH_FEEDBACK_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };

    case ACTION_TYPES.GET_ALL_FOR_USER_FEEDBACKS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        allForUser: payload,
      };
    case ACTION_TYPES.GET_ALL_FOR_USER_FEEDBACKS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };

    case ACTION_TYPES.GET_FEEDBACK_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.GET_FEEDBACK_BY_ID_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        details: {},
      };

    case ACTION_TYPES.CREATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload,
      };
    case ACTION_TYPES.CREATE_FEEDBACK_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };

    case ACTION_TYPES.UPDATE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.UPDATE_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.CREATE_THREAD_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.CREATE_THREAD_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.DELETE_THREAD_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.DELETE_THREAD_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.UPDATE_THREAD_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.UPDATE_THREAD_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.ASSIGN_HANDLER_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.ASSIGN_HANDLER_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.UPDATE_STATUS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.UPDATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.UPDATE_FEEDBACK_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.FETCH_FEEDBACK_DASHBOARD_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        forDashboard: payload,
      };
    case ACTION_TYPES.FETCH_FEEDBACK_DASHBOARD_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    default:
      return state;
  }
};
