import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';

import CONSTANTS from '@/Constants';
import Utils from '@/Utils';

import {
  Box,
  Tooltip,
  IconButton,
  Stack,
  Avatar,
  Typography,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { StickyHeadTable } from '@/Components/Common';
import { IProjectMemberResponse } from '../../../Interfaces/ProjectMember.interface';
import dayjs from 'dayjs';
import { useTranslation } from 'react-multi-lang';

const { MODULE_API } = CONSTANTS;

interface ISectionProps {
  payload: any[];
  onRowAction(member: IProjectMemberResponse, action: 'edit' | 'remove'): void;
  isLoading?: boolean;
}

interface IColumnStructure {
  id: 'no' | 'fullName' | 'position' | 'dateJoined' | 'action';
  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'fullName', label: 'fullName', minWidth: 100 },
  { id: 'position', label: 'position', minWidth: 80 },
  { id: 'dateJoined', label: 'dateJoined', minWidth: 80, align: 'center' },
  { id: 'action', label: 'action', minWidth: 50, align: 'center' },
];

const createDataTable = (
  no: string,
  fullName: JSX.Element,
  position: string,
  dateJoined: string,
  action: JSX.Element
): any => {
  return {
    no,
    fullName,
    position,
    dateJoined,
    action,
  };
};

const ProjectMemberDataTable: React.FC<ISectionProps> = ({
  payload,
  onRowAction,
  isLoading = false,
}) => {
  const t = useTranslation();
  const [rows, setRows] = useState<any[]>([]);

  React.useEffect(() => {
    const isAcceptEdit = Utils.isValidPermission(
      MODULE_API.PROJECT_MEMBER.UPDATE
    );
    const isAcceptDelete = Utils.isValidPermission(
      MODULE_API.PROJECT_MEMBER.DELETE
    );

    const resolveRows: any = [];
    if (!isEmpty(payload)) {
      forEach(payload, (item: IProjectMemberResponse, index: number) => {
        const { dateJoined, user, position } = item;

        const resolveRowIndex = index + 1;

        const resolveDateJoined = dayjs(dateJoined);

        resolveRows.push(
          createDataTable(
            `${resolveRowIndex}`,
            <Stack direction="row" alignItems="center">
              <Avatar
                src={user.userData?.avatar?.path}
                sx={{ width: '28px', height: '28px' }}
              >
                {user?.userData?.fullName[0]}
              </Avatar>
              <Typography sx={{ fontSize: '14px', ml: 1 }}>
                {user?.userData?.fullName}
              </Typography>
            </Stack>,
            position,
            resolveDateJoined.isValid()
              ? resolveDateJoined.format('DD-MM-YYYY')
              : t('table.noInformation'),
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Tooltip title={t('tooltip.clickToEditMember')}>
                <span>
                  <IconButton
                    onClick={() => isAcceptEdit && onRowAction(item, 'edit')}
                    disabled={!isAcceptEdit}
                  >
                    <EditOutlinedIcon fontSize="medium" />
                  </IconButton>
                </span>
              </Tooltip>

              {isAcceptDelete && (
                <Tooltip title={t('tooltip.clickToDeleteMember')}>
                  <IconButton onClick={() => onRowAction(item, 'remove')}>
                    <DeleteOutlineOutlinedIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable columns={COLUMNS} rows={rows} isLoading={isLoading} />
  );
};

export default ProjectMemberDataTable;
