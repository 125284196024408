import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
import { IPermissionDetailsStructure } from '../Interfaces/Permission.interface';

const { ROLES } = CONSTANTS.API;

export const fetchRoles = async (payload?: { limit: number; page: number }) => {
  return sendRequest(ROLES.BASIC, 'GET', payload);
};

export const fetchRoleOptions = async () => {
  return sendRequest(ROLES.OPTIONS, 'GET');
};

export const getRoleById = async (id: string) => {
  return sendRequest(`${ROLES.BASIC}/${id}`, 'GET');
};

export const createRole = async (payload: IPermissionDetailsStructure) => {
  return sendRequest(ROLES.BASIC, 'POST', payload);
};

export const updateRole = async (payload: IPermissionDetailsStructure) => {
  const { id } = payload;
  return sendRequest(`${ROLES.BASIC}/${id}`, 'PUT', payload);
};

export const fetchPermissions = async () => {
  return sendRequest(ROLES.PERMISSIONS, 'GET');
};

export const removeUserRole = async (id: string) => {
  return sendRequest(`${ROLES.REMOVE_USER}/${id}`, 'PUT');
};
