import { Skeleton, Stack, Typography } from '@mui/material';

const BreadcrumbSkeleton = () => {
  return (
    <Stack direction="row" alignItems="center">
      <Skeleton width={80} height={30} />
      <Typography sx={{ mx: 1 }}>/</Typography>
      <Skeleton width={50} height={30} />
      <Typography sx={{ mx: 1 }}>/</Typography>
      <Skeleton width={70} height={30} />
    </Stack>
  );
};

export default BreadcrumbSkeleton;
