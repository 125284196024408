import { SxProps, Theme } from '@mui/system';

export const listItemStyles: SxProps<Theme> = {
  '&.inactive': {
    borderRadius: '4px',
    color: '#42526e',
    height: '38px',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontWeight: '400',
    '&:hover': {
      background: 'rgba(9,30,66,.08)',
      color: '#42526e',
    },
  },
  '&.active': {
    color: '#42526e',
    height: '38px',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontWeight: '500',
    background: 'rgba(9,30,66,.08)',
    '&:hover': {
      background: 'rgba(9,30,66,.08)',
      color: '#42526e',
    },
  },
};
