import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { motion } from 'framer-motion';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  Stack,
  TextField,
  Typography,
  Skeleton,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  Tooltip,
} from '@mui/material';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import {
  RoundButton,
  LazyLoad,
  Dropdown,
  LightBoxImage,
  NoDataWereFound,
  Popper,
} from '@/Components/Common';
import Utils from '@/Utils';
import Constants from '@/Constants';
import { CrawlingSystemActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { CommonColors } from '@/Themes';
import {
  ICrawlingProjectFilter,
  ICrawlingSystemStructure,
  IImageStructure,
} from '@/Interfaces/CrawlingProjects.interface';

const { ROUTERS, ENUMS } = Constants;

const {
  fetchCrawlProjects,
  setDefaultReducerCrawlProject,
  captureImageProjects,
} = CrawlingSystemActions;
const initialFilter: any = {
  status: ENUMS.Status.ACTIVE,
  keyword: '',
  type: 'allType',
  page: 0,
  limit: 0,
  active: '',
};

const projectStatus = [
  {
    value: 'true',
    label: 'active',
  },
  {
    value: 'false',
    label: 'inactive',
  },
];

const projectTypes = [
  { value: 'allType', label: 'allType' },
  { value: ENUMS.ProjectTypes.WEBAPP, label: 'webApp' },
  { value: ENUMS.ProjectTypes.WEBSERVICE, label: 'webService' },
  { value: ENUMS.ProjectTypes.OTHER, label: 'other' },
];

const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
const locale = Utils.getSavedLocale();
const CrawlingSystem: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const isLoading = useSelector((state: RootState) =>
    _.get(state.CRAWLING_SYSTEM, 'requestIsLoading')
  );
  const captureProjectIsLoading = useSelector((state: RootState) =>
    _.get(state.CRAWLING_SYSTEM, 'captureProjectIsRequest')
  );
  const captureProjectIsSuccess = useSelector((state: RootState) =>
    _.get(state.CRAWLING_SYSTEM, 'crawlIsSuccess')
  );

  const payload = useSelector((state: RootState) =>
    _.get(state.CRAWLING_SYSTEM, 'payload')
  );
  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues: initialFilter,
  });

  const [details, setDetails] = useState<ICrawlingSystemStructure[]>([]);
  const [selectedImage, setSelectedImage] = useState<IImageStructure | null>(
    null
  );
  const [isShowLightbox, setIsShowLightbox] = useState<boolean>(false);
  const [itemPerRow, setItemPerRow] = useState<number | string>(3);
  const [isOpenPopper, setIsOpenPopper] = useState<boolean>(false);
  const [timer, setTimer] = useState<Dayjs>(dayjs('00:00:00', 'HH:mm:ss'));
  const [msgInvalidTimer, setMsgInvalidTimer] = useState<string>('');
  const [currentTimer, setCurrentTimer] = useState<Dayjs | null>(null);
  const [countDown, setCountDown] = useState<number>(0);
  const [runTimer, setRunTimer] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch(fetchCrawlProjects({ page: 0, limit: 0 }));
    return () => {
      dispatch(setDefaultReducerCrawlProject());
    };
  }, []);

  useEffect(() => {
    if (captureProjectIsSuccess) {
      setCurrentTimer(null);
      const d = dayjs(timer).format('HH:mm:ss'); // your input string
      const a = d.split(':'); // split it at the colons
      const result = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]; // seconds
      setCountDown(result);
      if (result > 0) setRunTimer(true);
    }
  }, [captureProjectIsSuccess]);

  useEffect(() => {
    if (captureProjectIsLoading) setIsOpenPopper(false);
  }, [captureProjectIsLoading]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let timerId: string | number | NodeJS.Timeout | undefined;
    if (runTimer && countDown > 0) {
      timerId = setInterval(() => {
        // eslint-disable-next-line no-shadow
        setCountDown((prev) => prev - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    // expired
    if (countDown <= 0 && runTimer) {
      dispatch(captureImageProjects(getValues()));
      setRunTimer(false);
    }
  }, [countDown, runTimer]);

  useEffect(() => {
    if (payload) setDetails(payload);
  }, [payload]);

  const hours = Math.floor(countDown / (60 * 60))
    .toString()
    .padStart(2, '0');
  const totalSeconds = countDown - _.toNumber(hours) * 60 * 60;
  const minutes = Math.floor(totalSeconds / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (totalSeconds - _.toNumber(minutes) * 60)
    .toString()
    .padStart(2, '0');

  const fetchPayload = (filterParams: any) => {
    const resolverFilter = Utils.validateFilters(
      filterParams?.type !== 'allType'
        ? filterParams
        : _.omit(filterParams, ['type'])
    );
    dispatch(fetchCrawlProjects(resolverFilter));
  };

  const formatTime = (lastTime: string) => {
    const formattedTime = new Date(lastTime).getTime();
    const currentTime = Date.now();
    const timeDiff = currentTime - formattedTime;

    const _days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const _hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const _minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    if (_days > 0) {
      return _days === 1 ? 'yesterday' : `${_days} days ago`;
    }
    if (_hours > 0) {
      return _hours === 1 ? '1 hour ago' : `${_hours} hours ago`;
    }
    if (_minutes > 0) {
      return _minutes === 1 ? '1 minute ago' : `${_minutes} minutes ago`;
    }
    return 'just now';
  };

  const onReset = () => {
    reset();
    fetchPayload(initialFilter);
  };

  const onSearch = (data: ICrawlingProjectFilter) => fetchPayload(data);

  const generateNumberOfRowSkeleton = (
    data: number,
    type: 'container' | 'numberRender'
  ) => {
    const resolveData = {
      3: type === 'container' ? 4 : 12,
      4: type === 'container' ? 3 : 16,
      5: type === 'container' ? 2.4 : 20,
    };
    return resolveData[data as keyof typeof resolveData] || 3;
  };

  const _renderGallery = () => {
    if (_.isEmpty(details)) return <NoDataWereFound />;
    return (
      <Box>
        <ImageList
          sx={{ width: '100%', minHeight: 1 }}
          cols={_.toNumber(itemPerRow) || 3}
          gap={30}
          variant="standard"
        >
          {_.map(details, (item: ICrawlingSystemStructure, index: number) => {
            return (
              <motion.div
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                <Box
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        width: 1,
                        p: 1,
                        fontSize: 16,
                        overFlow: 'hidden',
                        display: '-webkit-box',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        textAlign: 'center',
                        background: item?.active
                          ? CommonColors.oceanGreen
                          : CommonColors.cardinal,
                        color: '#ffffff',
                        position: 'relative',
                      }}
                    >
                      {`${_.startCase(item.name)} - ${
                        locale === 'vi' && item.type === 'Other'
                          ? 'Khác'
                          : item.type
                      }`}
                    </Typography>
                  </Box>
                  <ImageListItem
                    onClick={() => {
                      setIsShowLightbox(true);
                      setSelectedImage(_.first(item?.image));
                    }}
                  >
                    <LazyLoad
                      src={_.head(item.image)?.link}
                      alt={_.head(item.image)?.link}
                      style={{
                        width: '100%',
                        height: '150px',
                        objectFit: 'contain',
                        cursor: 'pointer',
                      }}
                    />
                  </ImageListItem>
                  <Box
                    sx={{
                      mt: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      color: '#ffffff',
                      p: 1,
                      background: item?.active
                        ? CommonColors.oceanGreen
                        : CommonColors.cardinal,
                    }}
                  >
                    {item?.active ? (
                      <Tooltip title={t('tooltip.active')}>
                        <CheckCircleOutlinedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('tooltip.inactive')}>
                        <CancelOutlinedIcon />
                      </Tooltip>
                    )}

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Tooltip title={t('tooltip.crawlingTime')}>
                        <AccessTimeIcon />
                      </Tooltip>
                      <Typography
                        sx={{
                          fontSize: 15,
                          textAlign: 'center',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {(_.first(item?.image) &&
                          formatTime(_.first(item?.image)?.createdAt)) ??
                          t('title.waitingForCrawling')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </motion.div>
            );
          })}
        </ImageList>
      </Box>
    );
  };

  const _renderLightboxImage = () => {
    if (!details) return;
    const resolvePayload = _.map(details, (item: ICrawlingSystemStructure) => {
      return {
        src: _.first(item?.image)?.link,
        alt: _.first(item?.image)?.name,
      };
    });
    const findIndex = _.findIndex(
      details,
      (item: ICrawlingSystemStructure) =>
        _.first(item?.image)?.link === selectedImage?.link
    );
    return (
      <LightBoxImage
        payload={resolvePayload}
        open={isShowLightbox}
        onClose={() => {
          setIsShowLightbox(false);
          setSelectedImage(null);
        }}
        index={findIndex}
      />
    );
  };

  const _renderCustomInput = (params: any) => {
    if (inputRef?.current?.value && regex.test(inputRef?.current?.value))
      setMsgInvalidTimer('');
    return (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        inputRef={inputRef}
        size="small"
        error={!_.isEmpty(msgInvalidTimer)}
        helperText={msgInvalidTimer}
        onChange={(e: any) => {
          if (!regex.test(e.target.value))
            setMsgInvalidTimer('Invalid format time');
          else setMsgInvalidTimer('');
        }}
      />
    );
  };

  const _renderSettingPopper = () => (
    <Popper
      sx={{
        width: 'max-content',
        zIndex: 999,
        '& .main-popper-content': {
          p: 0,
        },
        '& .MuiTimePickerToolbar-hourMinuteLabel': {
          alignItems: 'center',
        },
      }}
      content={
        <Box>
          <StaticTimePicker
            label={t('label.selectTime')}
            value={currentTimer ?? timer}
            onChange={(newValue: Dayjs | null) => setCurrentTimer(newValue)}
            // minutesStep={5}
            renderInput={(params) => _renderCustomInput(params)}
            orientation="landscape"
            componentsProps={{ actionBar: { actions: [] } }} // hidden actions
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
            <Button
              variant="outlined"
              onClick={() => {
                setMsgInvalidTimer('');
                setCurrentTimer(dayjs('00:00:00', 'HH:mm:ss'));
              }}
            >
              {t('button.resetCrawl')}
            </Button>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setIsOpenPopper(false);
                  setCurrentTimer(null);
                }}
              >
                {t('button.cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={!_.isEmpty(msgInvalidTimer)}
                onClick={() => {
                  if (currentTimer) {
                    setTimer(currentTimer);
                    const d = dayjs(currentTimer).format('HH:mm:ss'); // your input string
                    const a = d.split(':'); // split it at the colons
                    const result = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
                    if (result === 0) setRunTimer(false);
                    else setRunTimer(true);
                    setCountDown(
                      result > countDown ? result - countDown : result
                    );
                  }
                  setIsOpenPopper(false);
                }}
              >
                {t('button.saveAndApply')}
              </Button>
            </Box>
          </Box>
        </Box>
      }
      open={isOpenPopper}
      placement="bottom-end"
      onClose={() => {
        setIsOpenPopper(false);
        setCurrentTimer(null);
      }}
    >
      <IconButton
        onClick={() => setIsOpenPopper(true)}
        disabled={_.isEmpty(details) || isLoading || captureProjectIsLoading}
      >
        <Tooltip title={t('tooltip.settings')}>
          <SettingsIcon />
        </Tooltip>
      </IconButton>
    </Popper>
  );

  const _renderTopSection = () => {
    return (
      <Stack sx={{ width: 1, height: 'max-content' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '15px',
          }}
        >
          <Typography fontSize="20px" fontWeight="500">
            {t('title.crawlingSystem')}
          </Typography>
          <Stack direction="row" alignItems="center" sx={{ fontWeight: 500 }}>
            {captureProjectIsLoading
              ? `${t('title.waitingForCrawling')}...`
              : `${hours}:${minutes}:${seconds}`}
            {_renderSettingPopper()}
          </Stack>
        </Box>
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', flexWrap: 'wrap', gap: 1 }}
        >
          <Stack direction="row">
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  size="small"
                  onChange={(e: any) => field.onChange(e.target.value)}
                  placeholder={t('placeholder.projectName')}
                  sx={{ mr: 1, maxWidth: '190px' }}
                  disabled={isLoading || captureProjectIsLoading}
                />
              )}
            />
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Dropdown
                  sx={{ mr: 1, width: 130, minWidth: 'max-content' }}
                  value={field.value || 'allType'}
                  options={projectTypes}
                  onChange={(e: any) => {
                    field.onChange(e.target.value);
                  }}
                  isTranslate
                  disabled={isLoading || captureProjectIsLoading}
                />
              )}
            />
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <Dropdown
                  sx={{ mr: 1, width: 130, minWidth: 'max-content' }}
                  value={field.value}
                  options={projectStatus}
                  onChange={(e: any) => {
                    field.onChange(e.target.value);
                  }}
                  isTranslate
                  disabled={isLoading || captureProjectIsLoading}
                />
              )}
            />
            <Button
              onClick={handleSubmit(onSearch)}
              variant="contained"
              sx={{ mr: 1 }}
              disabled={isLoading || captureProjectIsLoading}
            >
              {t('button.filter')}
            </Button>
            <Button
              onClick={onReset}
              variant="outlined"
              disabled={isLoading || captureProjectIsLoading}
            >
              {t('button.reset')}
            </Button>
          </Stack>
          <Stack direction="row">
            <RoundButton
              size="large"
              label={t('button.goToProjectManagement')}
              onClick={() => Utils.redirect(ROUTERS.CRAWLING_PROJECT)}
              sx={{ mr: 1 }}
              disabled={isLoading || captureProjectIsLoading}
            />
            <RoundButton
              type="contained"
              size="large"
              label={t('button.startCrawling')}
              onClick={() => dispatch(captureImageProjects(getValues()))}
              disabled={
                _.isEmpty(details) || isLoading || captureProjectIsLoading
              }
            />
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const renderMain = () => {
    return (
      <Stack>
        {_renderTopSection()}
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>{t('label.itemPerRow')}:</FormLabel>
          <RadioGroup
            aria-label="Location"
            name="location"
            value={itemPerRow}
            onChange={(e) => setItemPerRow(e.target.value)}
            row={true}
          >
            <FormControlLabel value="3" control={<Radio />} label="3" />
            <FormControlLabel value="4" control={<Radio />} label="4" />
            <FormControlLabel value="5" control={<Radio />} label="5" />
          </RadioGroup>
        </FormControl>
        {isLoading || captureProjectIsLoading ? (
          <Grid container spacing={2}>
            {_.map(
              new Array(
                generateNumberOfRowSkeleton(
                  _.toNumber(itemPerRow),
                  'numberRender'
                )
              ),
              (_i: any, index: number) => {
                return (
                  <Grid
                    item
                    xs={generateNumberOfRowSkeleton(
                      _.toNumber(itemPerRow),
                      'container'
                    )}
                    key={index}
                  >
                    <Skeleton variant="rectangular" height={200} />
                  </Grid>
                );
              }
            )}
          </Grid>
        ) : (
          <>
            {_renderGallery()}
            {_renderLightboxImage()}
          </>
        )}
      </Stack>
    );
  };
  return renderMain();
};

export default CrawlingSystem;
