import { Box, Grid, Typography } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import _ from 'lodash';

const dataMarketSectors = [
  { label: 'Total coverage time / space' },
  { label: 'Share of coverage time / space' },
  { label: 'Number of news reports / articles (mentions)' },
  { label: 'Number of dedicated news reports / articles (press releases)' },
  { label: 'Ratio of text vs brand appeareance / text vs. photo' },
  { label: 'Number of newspapers' },
  { label: 'Advertising Value Equivalency' },
  { label: 'Tone of Voice' },
];

const dataAudience = [
  { label: 'Media campaign planning' },
  { label: 'Media buying negotiations' },
  { label: 'Post-buy evaluations' },
  { label: 'Competitive analysis' },
];

const dataCustomised = [
  { label: 'Cross-media analysis' },
  { label: 'Sector-based media presence' },
  { label: 'Cost analysis of advertising & PR campaigns' },
];

const Media = () => {
  const _renderEditorialContentMonitoring = () => {
    return (
      <Box>
        <Typography
          component="h3"
          sx={{
            fontSize: 26,
            color: '#212121',
            fontWeight: 700,
            fontStyle: 'normal',
            lineHeight: '30px',
            position: 'relative',
            pl: 2,
            my: 4,
            '&::after': {
              content: '""',
              position: 'absolute',
              width: 4,
              height: 30,
              backgroundColor: '#0068c5',
              bottom: 0,
              left: '0',
            },
          }}
        >
          EDITORIAL CONTENT MONITORING
        </Typography>
        <Box>
          <Typography
            sx={{
              color: '#3f3f3f',
              fontSize: 18,
              fontWeight: 400,
              lineHeight: '28px',
            }}
          >
            Traditionally known as Press Clipping this service extends now into
            TV and Online media in addition to newspapers and magazines. It
            brings to clients mentions, news, interviews and articles covering
            themes, keywords and brands related to the client or to the
            competition. This service is valuable to PR departments,
            spokespersons and communication managers. Key performance indicators
            that are captured by IDRAMEDIA include:
          </Typography>
        </Box>
        <Grid container mt={2}>
          {_.map(dataMarketSectors, (item: any, index: number) => {
            return (
              <Grid
                item
                xs={6}
                sm={3}
                key={index}
                sx={{
                  border: 'solid 1px #898989',
                  p: '11px 26px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    lineHeight: '30px',
                    textAlign: 'center',
                    color: '#3f3f3f',
                    fontWeight: 600,
                  }}
                >
                  {item.label}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const _renderAudienceRatings = () => {
    return (
      <Box>
        <Typography
          component="h3"
          sx={{
            fontSize: 26,
            color: '#212121',
            fontWeight: 700,
            fontStyle: 'normal',
            lineHeight: '30px',
            position: 'relative',
            pl: 2,
            my: 4,
            '&::after': {
              content: '""',
              position: 'absolute',
              width: 4,
              height: 30,
              backgroundColor: '#0068c5',
              bottom: 0,
              left: '0',
            },
          }}
        >
          AUDIENCE RATINGS
        </Typography>
        <Box>
          <Typography
            sx={{
              color: '#3f3f3f',
              fontSize: 18,
              fontWeight: 400,
              lineHeight: '28px',
              ml: 2,
            }}
          >
            IDRAMEDIA has introduced an original method for measuring audience
            preferences regarding media channels and particular media content.
            The results it brings are valuable indicators of viewers&apos;
            emotional bonding with content and therefore a reliable predictor of
            advertising & PR campaign effectiveness. The qualitative scores can
            be used for:
          </Typography>
        </Box>
        <Grid container my={4}>
          {_.map(dataAudience, (item: any, index: number) => {
            return (
              <Grid
                item
                xs={6}
                sm={3}
                key={index}
                sx={{ border: 'solid 1px #898989', p: '11px 26px' }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    lineHeight: '30px',
                    textAlign: 'center',
                    color: '#3f3f3f',
                    fontWeight: 600,
                  }}
                >
                  {item.label}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Typography
          sx={{
            color: '#3f3f3f',
            fontSize: 18,
            fontWeight: 400,
            lineHeight: '28px',
            ml: 2,
          }}
        >
          In addition, and in cooperation with Telemetrix sh.a, IDRAMEDIA offers
          professional TV audience measurement.
        </Typography>
      </Box>
    );
  };

  const _renderCustomised = () => {
    return (
      <Box>
        <Typography
          component="h3"
          sx={{
            fontSize: 26,
            color: '#212121',
            fontWeight: 700,
            fontStyle: 'normal',
            lineHeight: '30px',
            position: 'relative',
            pl: 2,
            my: 4,
            '&::after': {
              content: '""',
              position: 'absolute',
              width: 4,
              height: 30,
              backgroundColor: '#0068c5',
              bottom: { xs: 25, sm: 0 },
              left: '0',
            },
          }}
        >
          CUSTOMISED MONITORING & ANALUSIS
        </Typography>
        <Box>
          <Typography
            sx={{
              color: '#3f3f3f',
              fontSize: 18,
              fontWeight: 400,
              lineHeight: '28px',
              ml: 2,
            }}
          >
            For clients who require in-depth knowledge of media activities and
            competitive information we prepare special reports that measure
            performance of given sectors and companies. These reports are
            important for annual planning and reporting and for projecting
            future media strategies.
          </Typography>
        </Box>
        <Grid container mt={4}>
          {_.map(dataCustomised, (item: any, index: number) => {
            return (
              <Grid
                item
                xs={4}
                key={index}
                sx={{
                  border: 'solid 1px #898989',
                  p: '11px 26px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    lineHeight: '30px',
                    textAlign: 'center',
                    color: '#3f3f3f',
                    fontWeight: 600,
                  }}
                >
                  {item.label}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  return (
    <Box mt={4} px={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                width: 4,
                height: 120,
                backgroundColor: '#FF7F0E',
                bottom: 0,
                right: '0',
                top: '50%',
                transform: 'translateY(-50%)',
              },
            }}
          >
            <Typography
              component="h5"
              sx={{
                color: '#3f3f3f',
                fontSize: 20,
                fontWeight: 700,
                letterSpacing: '0.3px',

                mr: 4,
              }}
            >
              WE COVER TV, PRINT, RADIO, ONLINE AND OOH MEDIA AND HELP CLIENTS
              INTERPRET TRENDS AND INFORMATION SO THEY CAN IMPROVE THEIR MEDIA
              STRATEGIES.
            </Typography>
          </Box>
          <Box width={1}>
            <Box
              component="img"
              src="https://flow-idra.com/assets/img/ourservices-image.jpg"
              sx={{ height: 255, mt: 2, width: 1 }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            sx={{
              color: '#3f3f3f',
              fontSize: 18,
              fontWeight: 400,
              lineHeight: '28px',
            }}
          >
            FLOW IDRA provides the best media monitoring and advertising
            intelligence solutions in Albania and Kosova. We are the partner of
            choice for serious business companies, international organisations,
            PR and advertising agencies, and media outlets that seek to monitor,
            document and make sense out of their media activities.
          </Typography>
          <Typography
            sx={{
              color: '#3f3f3f',
              fontSize: 18,
              fontWeight: 400,
              lineHeight: '28px',
              mt: 1,
            }}
          >
            Our main advantage is easy to describe: we are a truly professional
            and independent media monitoring unit and deliver excellent
            solutions to our clients. Through our web-based monitoring platforms
            clients can follow any media channels on real-time, view latest ads
            or news content, verify airing of spots, and download
            up-to-the-minute reports.
          </Typography>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Typography
          component="h3"
          sx={{
            fontSize: 26,
            color: '#212121',
            fontWeight: 700,
            fontStyle: 'normal',
            lineHeight: '30px',
            position: 'relative',
            pl: 2,
            my: 4,
            '&::after': {
              content: '""',
              position: 'absolute',
              width: 4,
              height: 30,
              backgroundColor: '#0068c5',
              bottom: 0,
              left: '0',
            },
          }}
        >
          ADVERTISING INTELLIGENCE
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography
              component="h5"
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: '#212121',
                mb: 2,
              }}
            >
              Media Plan Verification
            </Typography>
            <Typography
              component="p"
              sx={{
                color: '#3f3f3f',
                fontSize: 18,
                ml: 1,
                lineHeight: '28px',
              }}
            >
              When you need to be absolutely sure that all your spots, logos,
              pop-ups or mentions are actually aired / published as planned,
              IDRAMEDIA is here to provide you with the evidence, to capture any
              changes however small, and to ensure that changes to the plan are
              well-documented.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="h5"
              sx={{ fontSize: 20, fontWeight: 700, color: '#212121', mb: 2 }}
            >
              Competitive Reports
            </Typography>
            <Typography
              component="p"
              sx={{
                color: '#3f3f3f',
                fontSize: 18,
                ml: 1,
                lineHeight: '28px',
              }}
            >
              They vary in scope and format, but generally include sector-based
              data that provide the full picture of the advertising market
              expressed in various metrics such as ad air time, spending, number
              of ad occurrences, time of airing, duration, prime time presence,
              weekend share, premium placements, etc.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="h5"
              sx={{ fontSize: 20, fontWeight: 700, color: '#212121', mb: 2 }}
            >
              Daily Reports
            </Typography>
            <Typography
              component="p"
              sx={{
                color: '#3f3f3f',
                fontSize: 18,
                ml: 1,
                lineHeight: '28px',
              }}
            >
              When you need immediate documentation for your own or your
              competitors&apos; TV, Print or Radio ads, IDRAMEDIA captures and
              sends you the video, image or audio file in record time.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="h5"
              sx={{ fontSize: 20, fontWeight: 700, color: '#212121', mb: 2 }}
            >
              Alerts & Notifications
            </Typography>
            <Typography
              component="p"
              sx={{
                color: '#3f3f3f',
                fontSize: 18,
                ml: 1,
                lineHeight: '28px',
              }}
            >
              We send alerts and notify our clients immediately in cases of
              relevant events, news or ads. Alerts are send via e-mails, SMS or
              Skype, depending on clients&apos; preference.
            </Typography>
          </Grid>
          <Typography
            sx={{
              color: '#3f3f3f',
              fontSize: 18,
              lineHeight: '28px',
              mt: 2,
              ml: 2,
            }}
          >
            FLOWIDRA produces advertising performance indicators that are useful
            for clients who want to improve their media strategies and increase
            the effectiveness of their advertising campaigns. Key indicators
            include:
          </Typography>
          <Grid container mt={4} ml={3}>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              sx={{ alignItems: 'center' }}
            >
              <AcUnitIcon sx={{ fontSize: 8, mr: 1 }} />
              <Typography
                sx={{
                  color: '#3f3f3f',
                  fontSize: 18,
                  lineHeight: '28px',
                }}
              >
                Number of ad occurrences
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              sx={{ alignItems: 'center' }}
            >
              <AcUnitIcon sx={{ fontSize: 8, mr: 1 }} />
              <Typography
                sx={{
                  color: '#3f3f3f',
                  fontSize: 18,
                  lineHeight: '28px',
                }}
              >
                Day part splits
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              sx={{ alignItems: 'center' }}
            >
              <AcUnitIcon sx={{ fontSize: 8, mr: 1 }} />
              <Typography
                sx={{
                  color: '#3f3f3f',
                  fontSize: 18,
                  lineHeight: '28px',
                }}
              >
                Time of airings / Ad placement
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              sx={{ alignItems: 'center' }}
            >
              <AcUnitIcon sx={{ fontSize: 8, mr: 1 }} />
              <Typography
                sx={{
                  color: '#3f3f3f',
                  fontSize: 18,
                  lineHeight: '28px',
                }}
              >
                Weekend share
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              sx={{ alignItems: 'baseline' }}
            >
              <AcUnitIcon
                sx={{
                  fontSize: 8,
                  mr: 1,
                }}
              />
              <Typography
                sx={{
                  color: '#3f3f3f',
                  fontSize: 18,
                  lineHeight: '28px',
                }}
              >
                Total Advertising Air Time / Total Advertising Space
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              sx={{ alignItems: 'center' }}
            >
              <AcUnitIcon sx={{ fontSize: 8, mr: 1 }} />
              <Typography
                sx={{
                  color: '#3f3f3f',
                  fontSize: 18,
                  lineHeight: '28px',
                }}
              >
                Premium placement shares
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              sx={{ alignItems: 'center' }}
            >
              <AcUnitIcon sx={{ fontSize: 8, mr: 1 }} />
              <Typography
                sx={{
                  color: '#3f3f3f',
                  fontSize: 18,
                  lineHeight: '28px',
                }}
              >
                Spending
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              sx={{ alignItems: 'center' }}
            >
              <AcUnitIcon sx={{ fontSize: 8, mr: 1 }} />
              <Typography
                sx={{
                  color: '#3f3f3f',
                  fontSize: 18,
                  lineHeight: '28px',
                }}
              >
                Free Spots vs. Sponsorships
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              sx={{ alignItems: 'center' }}
            >
              <AcUnitIcon sx={{ fontSize: 8, mr: 1 }} />
              <Typography
                sx={{
                  color: '#3f3f3f',
                  fontSize: 18,
                  lineHeight: '28px',
                }}
              >
                Prime Time presence
              </Typography>
            </Grid>
          </Grid>
          <Box mt={2} ml={2}>
            <Typography
              sx={{
                color: '#3f3f3f',
                fontSize: 18,
                lineHeight: '28px',
                mt: 2,
                ml: 2,
              }}
            >
              Our clients work with us to measure TV advertising, to monitor
              media campaigns, to analyze advertising trends, to gain insight
              into creative content, to evaluate expenditures, to understand
              competitors’ advertising moves and to identify the best options
              for spending their advertising budgets.
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box>{_renderEditorialContentMonitoring()}</Box>
      <Box>{_renderAudienceRatings()}</Box>
      <Box>{_renderCustomised()}</Box>
    </Box>
  );
};

export default Media;
