import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';

const { CONFIG_SYSTEM } = CONSTANTS.API;

export const fetchConfigSystems = async (payload: any) => {
  return sendRequest(CONFIG_SYSTEM.BASIC, 'GET', payload);
};

export const getConfigSystemByID = async (id: string) => {
  return sendRequest(`${CONFIG_SYSTEM.BASIC}/${id}`, 'GET');
};

export const createConfigSystem = async (payload: any) => {
  return sendRequest(CONFIG_SYSTEM.BASIC, 'POST', payload);
};

export const updateConfigSystem = async (payload: any) => {
  return sendRequest(`${CONFIG_SYSTEM.BASIC}/${payload.id}`, 'PUT', payload);
};

export const updateDocumentationConfigSystem = async (payload: any) => {
  return sendRequest(
    `${CONFIG_SYSTEM.DOCUMENTATION}/${payload.id}`,
    'PUT',
    payload
  );
};

export const deleteConfigSystem = async (id: string) => {
  return sendRequest(`${CONFIG_SYSTEM.BASIC}/${id}`, 'DEL');
};
