import { useRef, useEffect, useState, ForwardRefRenderFunction } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Stack, InputLabel, Box, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { inputLabelStyles } from './Input.styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface IOptionsStructure {
  label: string;
  value: string;
}

interface IAutocompleteInput {
  name?: string;
  label: string;
  containerSx?: object;
  labelSx?: object;
  placeholder?: string;
  value: string;
  options?: IOptionsStructure[];
  onChange(newValue: any, event?: any): void;
  message?: string;
  required?: boolean;
  disabled?: boolean;
}

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: IOptionsStructure) => option.label + option.value,
});

const AutocompleteInput: ForwardRefRenderFunction<
  HTMLInputElement,
  IAutocompleteInput
> = ({
  name,
  label,
  containerSx,
  labelSx,
  placeholder = '',
  options = [],
  onChange,
  value = '',
  message = '',
  required = false,
  disabled = false,
  ...otherProps
}) => {
  const [currentValue, setCurrentValue] = useState<IOptionsStructure | null>(
    null
  );

  const autocompleteRef = useRef<HTMLElement>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const isShowComponent = !isEmpty(message);

  useEffect(() => {
    const resolveValue = options.find(
      (option: IOptionsStructure) => option.value === value
    );
    if (resolveValue) setCurrentValue(resolveValue);
    return () => setCurrentValue(null);
  }, [value, options]);

  const handleChange = (_event: any, newValue: any) =>
    onChange(newValue?.value);

  const renderBorderColor = () => {
    if (!isEmpty(message)) return 'error.main';
    else {
      if (isFocus) return 'black';
      return 'transparent';
    }
  };

  return (
    <Stack sx={containerSx}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          boxShadow: '0 7px 25px rgba(0,0,0,0.08)',
          borderRadius: '15px',
          borderBottom: 'none',
          alignItems: 'center',
          // background: '#e5e5e5',
          height: '36px',
          transition: 'all ease 0.25s',
        }}
        ref={autocompleteRef}
        component="div"
        className={isShowComponent ? 'required' : ''}
      >
        <InputLabel
          sx={{
            ...inputLabelStyles,
            ...labelSx,
          }}
        >
          {label}
          {required && (
            <Typography component="span" color="error" ml={0.5}>
              *
            </Typography>
          )}
        </InputLabel>

        <Autocomplete
          {...otherProps}
          id={name}
          filterOptions={filterOptions}
          options={options}
          isOptionEqualToValue={(option: IOptionsStructure, newValue: any) =>
            option.value === newValue.value
          }
          sx={{
            flex: 1,
            ml: '-12px',
            height: '35px',
            paddingLeft: '20px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderLeftWidth: '0px',
            overflow: 'hidden',
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
            transition: 'all ease 0.25s',
            paddingRight: !isEmpty(message) ? '55px' : '0px',
            borderColor: renderBorderColor(),
            '&:hover, &:focus-visible, &:focus-within': {
              borderColor: 'inherit',
            },
            '& .MuiAutocomplete-option': {
              fontSize: '14px',
            },
            '& .MuiAutocomplete-endAdornment': {
              right: !isEmpty(message) ? '-20px' : '0px',
            },
          }}
          onChange={handleChange}
          onOpen={() => setIsFocus(true)}
          onClose={() => setIsFocus(false)}
          disabled={disabled}
          value={currentValue}
          renderOption={(props, option) => (
            <li {...props} style={{ fontSize: '14px' }}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              sx={{
                minWidth: '14rem',
                flex: 1,
                height: '100%',
                '& .MuiInputBase-root': {
                  background: 'transparent',
                  height: '100%',
                },
                '& .MuiAutocomplete-endAdornment': {
                  top: 'calc(50% - 20px)!important',
                  right: '10px',
                  zIndex: 999,
                },
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                placeholder,
                style: {
                  fontSize: '14px',
                  paddingRight: 0,
                  paddingLeft: '1px',
                },
              }}
            />
          )}
        />
        <WarningAmberIcon
          color="error"
          fontSize="small"
          sx={{
            position: 'absolute',
            right: '10px',
            opacity: `${!isEmpty(message) ? 1 : 0}`,
            transition: 'all ease 0.25s',
          }}
        />
      </Box>
      {isShowComponent && (
        <Typography fontSize="13px" color="error">
          *{message}
        </Typography>
      )}
    </Stack>
  );
};

export default AutocompleteInput;
