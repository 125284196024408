import React from 'react';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { t } from 'react-multi-lang';

import { StickyHeadTable } from '@/Components/Common';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import Utils from '@/Utils';
import Constants from '@/Constants';
import { IConfigSystem } from '@/Interfaces/ConfigSystem.interface';

import { Tooltip, Link, Typography, Stack, IconButton } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

interface IColumnStructure {
  id:
    | 'no'
    | 'project'
    | 'mainName'
    | 'mainIP'
    | 'mainPort'
    | 'mainURL'
    | 'action';
  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

interface ISectionProps {
  meta: IMetaStructure;
  payload: IConfigSystem[];
  onPageAction?(value: number, type: 'limit' | 'page'): void;
  onRowAction(item: IConfigSystem, action: 'details' | 'edit' | 'delete'): void;
  isLoading?: boolean;
}

interface IDataTableStructure {
  no: string;
  project: JSX.Element;
  mainName: JSX.Element;
  mainIP: JSX.Element;
  mainPort: JSX.Element;
  mainURL: JSX.Element;
  action: JSX.Element;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'project', label: 'project', minWidth: 300, align: 'left' },
  { id: 'mainName', label: 'mainName', minWidth: 5, align: 'center' },
  { id: 'mainIP', label: 'mainIP', minWidth: 5, align: 'center' },
  { id: 'mainPort', label: 'mainPort', minWidth: 5, align: 'center' },
  { id: 'mainURL', label: 'mainURL', minWidth: 5, align: 'center' },
  { id: 'action', label: 'action', minWidth: 5, align: 'center' },
];

const createDataTable = (
  no: string,
  project: JSX.Element,
  mainName: JSX.Element,
  mainIP: JSX.Element,
  mainPort: JSX.Element,
  mainURL: JSX.Element,
  action: JSX.Element
): IDataTableStructure => {
  return {
    no,
    project,
    mainName,
    mainIP,
    mainPort,
    mainURL,
    action,
  };
};

const { MODULE_API } = Constants;

const ConfigSystemDataTable: React.FC<ISectionProps> = ({
  payload,
  meta,
  onRowAction,
  onPageAction,
  isLoading = false,
}) => {
  const [rows, setRows] = React.useState<IDataTableStructure[]>([]);
  const isAcceptEdit =
    Utils.isValidPermission(MODULE_API.CONFIG_SYSTEMS.UPDATE_CONFIG_SYSTEM) ||
    Utils.isValidPermission(MODULE_API.CONFIG_SYSTEMS.UPDATE_DOCUMENTATION);
  const isAcceptViewDetails = Utils.isValidPermission(
    MODULE_API.CONFIG_SYSTEMS.GET_CONFIG_SYSTEM_BY_ID
  );
  const isAcceptDelete = Utils.isValidPermission(
    MODULE_API.CONFIG_SYSTEMS.DELETE_CONFIG_SYSTEM
  );

  React.useEffect(() => {
    const resolveRows: IDataTableStructure[] = [];
    if (!isEmpty(payload)) {
      forEach(payload, (item: IConfigSystem, index: number) => {
        const { ip, name, port, project, url } = item;

        const currentPage = get(meta, 'currentPage');
        const limit = get(meta, 'itemsPerPage');
        const resolveRowIndex = index + 1 + (currentPage - 1) * limit;

        resolveRows.push(
          createDataTable(
            `${resolveRowIndex}`,
            <Typography sx={{ fontSize: '14px' }}>{project?.name}</Typography>,
            <Typography sx={{ fontSize: '14px' }}>
              {name || t('table.noInformation')}
            </Typography>,
            <Typography sx={{ fontSize: '14px' }}>
              {port || t('table.noInformation')}
            </Typography>,
            <Typography sx={{ fontSize: '14px' }}>
              {ip || t('table.noInformation')}
            </Typography>,
            <Stack direction="row">
              {url ? (
                <Link
                  href={url}
                  target="_blank"
                  sx={{
                    fontSize: '14px',
                    ':hover': { cursor: 'pointer' },
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    height: 1,
                    maxWidth: { xs: 300, md: 400, lg: 500, xl: 600 },
                  }}
                >
                  {url}
                </Link>
              ) : (
                <Typography sx={{ fontSize: '14px' }}>
                  {t('table.noInformation')}
                </Typography>
              )}
            </Stack>,
            <Stack
              direction="row"
              sx={{ alignItems: 'center', justifyContent: 'center' }}
            >
              {!isAcceptEdit ? (
                <Tooltip title={t('tooltip.clickToViewDetailsThisConfig')}>
                  <IconButton
                    disabled={!isAcceptViewDetails}
                    onClick={() =>
                      isAcceptViewDetails && onRowAction(item, 'details')
                    }
                  >
                    <RemoveRedEyeOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : null}
              {isAcceptEdit ? (
                <Tooltip title={t('tooltip.clickToEditThisConfig')}>
                  <IconButton
                    onClick={() => isAcceptEdit && onRowAction(item, 'edit')}
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : null}
              {isAcceptDelete ? (
                <Tooltip title={t('tooltip.clickToDeleteThisConfig')}>
                  <IconButton
                    onClick={() =>
                      isAcceptDelete && onRowAction(item, 'delete')
                    }
                  >
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Stack>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      onAction={onPageAction}
      paginate={meta}
      isLoading={isLoading}
    />
  );
};

export default ConfigSystemDataTable;
