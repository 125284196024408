import React from 'react';
import { useTranslation } from 'react-multi-lang';
import { useParams } from 'react-router';
import _ from 'lodash';
import { validate as uuidValidate } from 'uuid';
import { Stack } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { Alert } from '@/Widgets';
import Utils from '@/Utils';
import CONSTANTS from '@/Constants';
import { WorkProgressActions } from '@/Actions';
import { useTypedDispatch } from '@/store';
import { RoundButton } from '@/Components/Common';
import { StepWorkProgressDataTable } from '@/Components/LayoutPart/DataTable';
import { AddOrUpdateStepWorkProgressPopup } from '@/Components/Popup';
import { IListStepProgressStructure } from '@/Interfaces/WorkProgress.interface';

import { getSavedUserData } from '@/Utils/Cookie.utils';

const { ENUMS, MODULE_API } = CONSTANTS;

const { updateStepWorkProgress, deleteStepWorkProgress } = WorkProgressActions;
interface ISectionProps {
  payload: any;
}
const StepWorkProgress: React.FC<ISectionProps> = (payload) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const workProgressId = useParams().id;

  const [isShowAddOrUpdateStepPopup, setIsShowAddOrUpdateStepPopup] =
    React.useState<boolean>(false);
  const [showAddOrUpdateStep, setShowAddOrUpdateStep] = React.useState<
    'add' | 'edit'
  >('add');
  const [stepId, setStepId] = React.useState<string>('');
  const isAcceptAddStepApi = Utils.isValidPermission(
    MODULE_API.WORK_PROGRESS.CREATE_STEP
  );
  const [checkIsAssigned, setCheckIsAssigned] = React.useState<boolean>(false);

  React.useEffect(() => {
    const userData = getSavedUserData();
    _.map(payload.payload.wasAssigned, (item: any) => {
      if (item.email === userData.email) {
        setCheckIsAssigned(true);
      }
    });
  }, [payload]);

  const onRowAction = async (
    stepProgress: IListStepProgressStructure,
    action: 'edit' | 'status' | 'delete'
  ) => {
    const { id, status, content } = stepProgress;
    if (action === 'status') {
      const isDone = status === ENUMS.WorkProgressStatus.DONE;
      const message = isDone
        ? t('popup.confirmChangeStepStatusToProcessing')
        : t('popup.confirmChangeStepStatusToDone');
      const isAgree = await Alert({
        type: 'WARNING',
        message,
      });
      if (isAgree === 'ok') {
        if (workProgressId && uuidValidate(workProgressId)) {
          dispatch(
            updateStepWorkProgress(
              {
                content,
                status: `${isDone ? 'processing' : 'done'}`,
              },
              id,
              workProgressId
            )
          );
        }
      }
    } else if (action === 'edit') {
      setIsShowAddOrUpdateStepPopup(true);
      setShowAddOrUpdateStep('edit');
      setStepId(id);
    } else if (action === 'delete') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.confirmDeleteStepWorkProgress'),
      });
      if (isAgree === 'ok') {
        if (workProgressId && uuidValidate(workProgressId))
          dispatch(deleteStepWorkProgress(id, workProgressId));
      }
    }
  };

  const _renderAddOrUpdateStepPopup = () => (
    <AddOrUpdateStepWorkProgressPopup
      open={isShowAddOrUpdateStepPopup}
      onClose={() => setIsShowAddOrUpdateStepPopup(false)}
      showAddOrUpdate={showAddOrUpdateStep}
      stepId={stepId}
    />
  );

  const renderMain = () => {
    return (
      <Stack direction="column">
        {(checkIsAssigned || isAcceptAddStepApi) && (
          <Stack direction="row" justifyContent="flex-end">
            <RoundButton
              label={t('button.add')}
              type="contained"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                setIsShowAddOrUpdateStepPopup(true);
                setShowAddOrUpdateStep('add');
              }}
              sx={{ width: '100px', mb: 1 }}
            />
          </Stack>
        )}
        <Stack direction="row" sx={{ mr: 1 }}>
          <StepWorkProgressDataTable
            payload={payload.payload.listStep}
            onRowAction={onRowAction}
          />
        </Stack>
        {_renderAddOrUpdateStepPopup()}
      </Stack>
    );
  };

  return renderMain();
};

export default StepWorkProgress;
