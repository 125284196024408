import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';
import { toast } from 'react-toastify';
import { IFolderStructure } from '../Interfaces/Folders.interface';

const { ACTION_TYPES } = CONSTANTS;

// SINGLE ACTIONS
const clearFolderStatus = () => {
  return {
    type: ACTION_TYPES.CLEAR_FOLDER_STATUS,
  };
};

const foldersIsRequest = () => {
  return {
    type: ACTION_TYPES.FOLDERS_IS_REQUEST,
  };
};

const setDefaultFolderReducer = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_FOLDER_REDUCERS,
  };
};

// ASYNC ACTIONS
const fetchRootFoldersFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_ROOT_FOLDERS_FAILURE,
  };
};

const fetchRootFoldersSuccess = (payload: IFolderStructure[]) => {
  return {
    type: ACTION_TYPES.FETCH_ROOT_FOLDERS_SUCCESS,
    payload,
  };
};

const fetchRootFolders = () => {
  return async (dispatch: any) => {
    dispatch(foldersIsRequest());
    await API.fetchRootFolders()
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchRootFoldersFailure());
        else {
          const resolveResult: IFolderStructure[] =
            result as IFolderStructure[];
          dispatch(fetchRootFoldersSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
      });
  };
};

const fetchChildFoldersFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_CHILD_FOLDERS_FAILURE,
  };
};

const fetchChildFoldersSuccess = (payload: IFolderStructure[]) => {
  return {
    type: ACTION_TYPES.FETCH_CHILD_FOLDERS_SUCCESS,
    payload,
  };
};

const fetchChildFolders = (parentId: string) => {
  return async (dispatch: any) => {
    dispatch(foldersIsRequest());
    await API.fetchChildFolders(parentId)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchChildFoldersFailure());
        else {
          const resolveResult: IFolderStructure[] =
            result as IFolderStructure[];
          dispatch(fetchChildFoldersSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createFoldersFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_FOLDER_FAILURE,
  };
};

const createFoldersSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_FOLDER_SUCCESS,
  };
};

const createFolder = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(foldersIsRequest());
    await API.createFolder(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response.data.message;
        if (!result) {
          toast.error(message);
          await dispatch(createFoldersFailure());
        } else {
          if (payload?.parentId) dispatch(fetchChildFolders(payload?.parentId));
          else dispatch(fetchRootFolders());
          toast.success(message);
          dispatch(createFoldersSuccess());
        }

        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        dispatch(createFoldersFailure());
      });
  };
};

const updateFolderFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_FOLDER_FAILURE,
  };
};

const updateFolderSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_FOLDER_SUCCESS,
  };
};

const updateFolder = (payload: {
  id: string;
  name: string;
  parentId?: string;
}) => {
  return async (dispatch: any) => {
    dispatch(foldersIsRequest());
    await API.updateFolder(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response.data.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateFolderFailure());
        } else {
          if (payload?.parentId) dispatch(fetchChildFolders(payload?.parentId));
          else dispatch(fetchRootFolders());
          toast.success(message);
          dispatch(updateFolderSuccess());
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        dispatch(updateFolderFailure());
      });
  };
};

const deleteFolderFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_FOLDER_FAILURE,
  };
};

const deleteFolderSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_FOLDER_SUCCESS,
  };
};

const deleteFolder = (id: string, parentId?: string) => {
  return async (dispatch: any) => {
    dispatch(foldersIsRequest());
    await API.deleteFolder(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response.data.message;
        if (!result) {
          toast.error(message);
          await dispatch(deleteFolderFailure());
        } else {
          if (parentId) dispatch(fetchChildFolders(parentId));
          else dispatch(fetchRootFolders());
          toast.success(message);
          dispatch(deleteFolderSuccess());
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        dispatch(deleteFolderFailure());
      });
  };
};

export default {
  clearFolderStatus,
  setDefaultFolderReducer,
  fetchRootFolders,
  fetchChildFolders,
  createFolder,
  updateFolder,
  deleteFolder,
};
