import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@utils';
import { toast } from 'react-toastify';
import { ITool } from '../Interfaces/Tool.interface';

const { ACTION_TYPES, ROUTERS } = CONSTANTS;

// SINGLE_ACTIONS
const toolIsRequest = () => {
  return {
    type: ACTION_TYPES.TOOL_IS_REQUEST,
  };
};

// ASYNC_ACTIONS
const fetchToolsSuccess = (payload: ITool[]) => {
  return {
    type: ACTION_TYPES.FETCH_TOOLS_SUCCESS,
    payload,
  };
};

const fetchToolsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_TOOLS_FAILURE,
  };
};

const fetchTools = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(toolIsRequest());
    await API.fetchTools(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchToolsFailure());
        else {
          const resolvePayload: ITool[] = result as ITool[];

          dispatch(fetchToolsSuccess(resolvePayload));
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(fetchToolsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const getToolByIDSuccess = (payload: ITool) => {
  return {
    type: ACTION_TYPES.GET_TOOL_BY_ID_SUCCESS,
    payload,
  };
};

const getToolByIDFailure = () => {
  return {
    type: ACTION_TYPES.GET_TOOL_BY_ID_FAILURE,
  };
};

const getToolByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(toolIsRequest());
    await API.getToolByID(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getToolByIDFailure());
        else {
          const resolvePayload: ITool = result as ITool;

          dispatch(getToolByIDSuccess(resolvePayload));
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(getToolByIDFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createToolSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_TOOL_SUCCESS,
  };
};

const createToolFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_TOOL_FAILURE,
  };
};

const createTool = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(toolIsRequest());
    await API.createTool(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createToolFailure());
        } else {
          await dispatch(createToolSuccess());
          await dispatch(fetchTools());
          Utils.redirect(ROUTERS.TOOLS);
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createToolFailure());
      });
  };
};

const updateToolSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_TOOL_SUCCESS,
  };
};

const updateToolFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_TOOL_FAILURE,
  };
};

const updateTool = (payload: FormData, id: string) => {
  return async (dispatch: any) => {
    dispatch(toolIsRequest());
    await API.updateTool(payload, id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateToolFailure());
        } else {
          await dispatch(updateToolSuccess());
          await dispatch(fetchTools());
          Utils.redirect(ROUTERS.TOOLS);
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateToolFailure());
      });
  };
};

const deleteToolSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_TOOL_SUCCESS,
  };
};

const deleteToolFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_TOOL_FAILURE,
  };
};

const deleteTool = (id: string) => {
  return async (dispatch: any) => {
    dispatch(toolIsRequest());
    await API.deleteTool(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteToolFailure());
          toast.error(message);
        } else {
          dispatch(deleteToolSuccess());
          dispatch(fetchTools());
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(deleteToolFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  fetchTools,
  getToolByID,
  createTool,
  updateTool,
  deleteTool,
};
