import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
// import { IFolderStructure } from '@/Interfaces/Folders.interface';

const { WIKI } = CONSTANTS.API;

export const fetchWiki = async (filterParams?: any) => {
  return sendRequest(WIKI.BASIC, 'GET', filterParams);
};

export const createWiki = async (payload: FormData) => {
  return sendRequest(WIKI.BASIC, 'POST_FORM_DATA', payload);
};

export const updateWiki = async (id: string, payload: any) => {
  return sendRequest(`${WIKI.BASIC}/${id}`, 'PUT', payload);
};

export const updateWikiAttachment = async (id: string, payload: any) => {
  return sendRequest(
    `${WIKI.UPDATE_ATTACHMENT}/${id}`,
    'PUT_FORM_DATA',
    payload
  );
};

export const getWikiById = async (id: string) => {
  return sendRequest(`${WIKI.BASIC}/${id}`, 'GET');
};

export const deleteWiki = async (id: string) => {
  return sendRequest(`${WIKI.BASIC}/${id}`, 'DEL');
};
