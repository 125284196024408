import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';
import {
  ICreateStepWorkProgress,
  ICreateWorkProgress,
  IUpdateWorkProgress,
  IUpdateStepWorkProgress,
  IWorkProgressFilter,
  IWorkProgressResponseStructure,
  IStepWorkProgressDetailsStructure,
} from '@/Interfaces/WorkProgress.interface';
import { toast } from 'react-toastify';

const { ACTION_TYPES, ROUTERS } = CONSTANTS;

// SINGLE ACTIONS
const WorkProgressIsRequest = () => {
  return {
    type: ACTION_TYPES.WORK_PROGRESS_IS_REQUEST,
  };
};

const setWorkProgressMeta = (payload: any) => {
  return {
    type: ACTION_TYPES.SET_META_WORK_PROGRESS,
    payload,
  };
};

const setDefaultReducerWorkProgress = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_REDUCER_WORK_PROGRESS,
  };
};

const clearStatusWorkProgress = () => {
  return {
    type: ACTION_TYPES.CLEAR_STATUS_WORK_PROGRESS,
  };
};

// ASYNC ACTIONS
const fetchWorkProgressSuccess = (payload: IWorkProgressResponseStructure) => {
  return {
    type: ACTION_TYPES.FETCH_WORK_PROGRESS_SUCCESS,
    payload,
  };
};

const fetchWorkProgressFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_WORK_PROGRESS_FAILURE,
  };
};

const fetchWorkProgress = (payload: IWorkProgressFilter) => {
  return async (dispatch: any) => {
    dispatch(WorkProgressIsRequest());
    await API.fetchWorkProgress(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchWorkProgressFailure());
        else {
          const resolveResult: { items: any; meta: any } = result as {
            items: any;
            meta: any;
          };
          dispatch(setWorkProgressMeta(resolveResult.meta));
          dispatch(fetchWorkProgressSuccess(resolveResult.items));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(fetchWorkProgressFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const getWorkProgressByIdSuccess = (
  payload: IWorkProgressResponseStructure
) => {
  return {
    type: ACTION_TYPES.GET_WORK_PROGRESS_BY_ID_SUCCESS,
    payload,
  };
};

const getWorkProgressByIdFailure = () => {
  return {
    type: ACTION_TYPES.GET_WORK_PROGRESS_BY_ID_FAILURE,
  };
};

const getWorkProgressById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(WorkProgressIsRequest());
    await API.getWorkProgressById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(getWorkProgressByIdFailure());
          await Utils.redirect(ROUTERS.DASHBOARD);
          toast.error(message);
        } else {
          const resolveResult: IWorkProgressResponseStructure =
            result as IWorkProgressResponseStructure;
          dispatch(getWorkProgressByIdSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(getWorkProgressByIdFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createWorkProgressSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_WORK_PROGRESS_SUCCESS,
  };
};

const createWorkProgressFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_WORK_PROGRESS_FAILURE,
  };
};

const createWorkProgress = (payload: ICreateWorkProgress) => {
  return async (dispatch: any) => {
    dispatch(WorkProgressIsRequest());
    await API.createWorkProgress(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createWorkProgressFailure());
        } else {
          await dispatch(createWorkProgressSuccess());
          await Utils.redirect(ROUTERS.WORK_PROGRESS);
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createWorkProgressFailure());
      });
  };
};

const updateWorkProgressSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_WORK_PROGRESS_SUCCESS,
  };
};

const updateWorkProgressFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_WORK_PROGRESS_FAILURE,
  };
};

const updateWorkProgress = (payload: IUpdateWorkProgress, id: string) => {
  return async (dispatch: any) => {
    dispatch(WorkProgressIsRequest());
    await API.updateWorkProgress(payload, id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateWorkProgressFailure());
          toast.error(message);
        } else {
          await dispatch(updateWorkProgressSuccess());
          await Utils.redirect(ROUTERS.WORK_PROGRESS);
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateWorkProgressFailure());
      });
  };
};

const updateStatusWorkProgressSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_STATUS_WORK_PROGRESS_SUCCESS,
  };
};

const updateStatusWorkProgressFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_STATUS_WORK_PROGRESS_FAILURE,
  };
};

const updateStatusWorkProgress = (
  payload: any,
  id: string,
  resolveFilter: IWorkProgressFilter
) => {
  return async (dispatch: any) => {
    dispatch(WorkProgressIsRequest());
    await API.updateStatusWorkProgress(payload, id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateStatusWorkProgressFailure());
        } else {
          toast.success(message);
          await dispatch(updateStatusWorkProgressSuccess());
          await dispatch(fetchWorkProgress(resolveFilter));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateStatusWorkProgressFailure());
      });
  };
};

const deleteWorkProgressSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_WORK_PROGRESS_SUCCESS,
  };
};

const deleteWorkProgressFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_WORK_PROGRESS_FAILURE,
  };
};

const deleteWorkProgress = (id: string, payload: IWorkProgressFilter) => {
  return async (dispatch: any) => {
    dispatch(WorkProgressIsRequest());
    await API.deleteWorkprogress(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(deleteWorkProgressFailure());
        } else {
          toast.success(message);
          dispatch(deleteWorkProgressSuccess());
          dispatch(fetchWorkProgress(payload));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(deleteWorkProgressFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};
const createStepWorkProgressSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_STEP_WORK_PROGRESS_SUCCESS,
  };
};

const createStepWorkProgressFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_STEP_WORK_PROGRESS_FAILURE,
  };
};

const createStepWorkProgress = (
  payload: ICreateStepWorkProgress,
  workProgressId: string
) => {
  return async (dispatch: any) => {
    dispatch(WorkProgressIsRequest());
    await API.createStepWorkProgress(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createStepWorkProgressFailure());
        } else {
          await dispatch(createStepWorkProgressSuccess());
          await dispatch(getWorkProgressById(workProgressId));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createStepWorkProgressFailure());
      });
  };
};

const getStepWorkProgressByIdSuccess = (
  payload: IStepWorkProgressDetailsStructure
) => {
  return {
    type: ACTION_TYPES.GET_STEP_WORK_PROGRESS_BY_ID_SUCCESS,
    payload,
  };
};

const getStepWorkProgressByIdFailure = () => {
  return {
    type: ACTION_TYPES.GET_STEP_WORK_PROGRESS_BY_ID_FAILURE,
  };
};

const getStepWorkProgressById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(WorkProgressIsRequest());
    await API.getStepWorkProgressById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(getStepWorkProgressByIdFailure());
        } else {
          const resolveResult: IStepWorkProgressDetailsStructure =
            result as IStepWorkProgressDetailsStructure;
          await dispatch(getStepWorkProgressByIdSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getStepWorkProgressByIdFailure());
      });
  };
};

const updateStepWorkProgressSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_STEP_WORK_PROGRESS_SUCCESS,
  };
};

const updateStepWorkProgressFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_STEP_WORK_PROGRESS_FAILURE,
  };
};

const updateStepWorkProgress = (
  payload: IUpdateStepWorkProgress,
  id: string,
  workProgressId: string
) => {
  return async (dispatch: any) => {
    dispatch(WorkProgressIsRequest());
    await API.updateStepWorkProgress(payload, id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateStepWorkProgressFailure());
          toast.error(message);
        } else {
          await dispatch(updateStepWorkProgressSuccess());
          await dispatch(getWorkProgressById(workProgressId));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateStepWorkProgressFailure());
      });
  };
};

const deleteStepWorkProgressSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_STEP_WORK_PROGRESS_SUCCESS,
  };
};

const deleteStepWorkProgressFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_STEP_WORK_PROGRESS_FAILURE,
  };
};

const deleteStepWorkProgress = (id: string, workProgressId: string) => {
  return async (dispatch: any) => {
    dispatch(WorkProgressIsRequest());
    await API.deleteStepWorkProgress(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteStepWorkProgressFailure());
          toast.error(message);
        } else {
          dispatch(deleteStepWorkProgressSuccess());
          dispatch(getWorkProgressById(workProgressId));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(deleteStepWorkProgressFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  setDefaultReducerWorkProgress,
  clearStatusWorkProgress,
  fetchWorkProgress,
  setWorkProgressMeta,
  createWorkProgress,
  updateWorkProgress,
  getWorkProgressById,
  createStepWorkProgress,
  getStepWorkProgressById,
  updateStepWorkProgress,
  deleteWorkProgress,
  deleteStepWorkProgress,
  updateStatusWorkProgress,
};
