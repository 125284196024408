import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-multi-lang';

interface SectionProps {
  open: boolean;
  onClose(value?: string): void;
  payload: any;
}

const DialogAnnouncement: React.FC<SectionProps> = (props: SectionProps) => {
  const { open, onClose, payload } = props;
  const t = useTranslation();
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 850,
        },
      }}
    >
      <DialogTitle sx={{ textTransform: 'capitalize', fontSize: 16 }}>
        {payload?.announcement?.title}
      </DialogTitle>
      <DialogContent>
        <Typography mb={2} ml={1}>
          {payload?.announcement?.content}
        </Typography>
        <Box>
          <Typography fontSize={14} color={'#A8A8A8'}>
            From: {payload?.announcement?.createdBy?.userData?.fullName}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<CloseIcon />}
          onClick={() => {
            onClose();
          }}
          variant="contained"
        >
          {t('button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogAnnouncement;
