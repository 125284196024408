import React from 'react';
import get from 'lodash/get';
import assign from 'lodash/assign';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { Typography, Stack, Button, TextField, Box } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';

import CONSTANTS from '@/Constants';
import { Select, RoundedContainer } from '@/Components/Common';
import AddNewUser from '@/Components/Popup/AddNewUser';
import { UserDataTable } from '@/Components/LayoutPart/DataTable';
import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import { ExportUserPopup } from '@/Components/Popup';

import Utils from '@/Utils';
import { Alert } from '@/Widgets';
import { UserActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';

import { IMetaStructure } from '@/Interfaces/Common.interface';
import {
  IFilterUserInformationStructure,
  IUserInformationDetailsStructure,
} from '@/Interfaces/UserInformation.interface';

const { deactivateUser, activateUser } = UserActions;

const { ENUMS, ROUTERS } = CONSTANTS;

const initialFilter = {
  status: ENUMS.Status.ALL_TYPE,
  keyword: '',
  page: 1,
  limit: 10,
};

const { fetchUser } = UserActions;

const initialStatusOptions = [
  { labelEN: 'All type', labelVI: 'Tất cả', value: 'allType' },
  { labelEN: 'Active', labelVI: 'Hoạt động', value: 'active' },
  { labelEN: 'Inactive', labelVI: 'Không hoạt động', value: 'inactive' },
];

const UserList = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const language = Utils.getSavedLocale();

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: initialFilter,
  });

  const payload: IUserInformationDetailsStructure[] = useSelector(
    (state: RootState) => get(state.USER, 'payload')
  );
  const meta: IMetaStructure = useSelector((state: RootState) =>
    get(state.USER, 'meta')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.USER, 'requestIsLoading')
  );

  const [isShowAddUser, setIsShowAddUser] = React.useState<boolean>(false);
  const [isShowExportPopup, setIsShowExportPopup] =
    React.useState<boolean>(false);

  const isAcceptFetchUser = Utils.isValidPermission(
    ModuleApiConstant.USER.FETCH_USERS
  );
  const isAcceptCreateUser = Utils.isValidPermission(
    ModuleApiConstant.USER.CREATE_USER
  );

  const fetchPayload = (filterParams: IFilterUserInformationStructure) => {
    const resolveFilters = Utils.validateFilters(filterParams);
    dispatch(fetchUser(resolveFilters));
  };

  React.useEffect(() => {
    if (!isAcceptFetchUser) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForFetchUsers'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else fetchPayload(initialFilter);
  }, []);

  const statusOptions = React.useMemo(() => {
    const resolveOptions: any[] = [];
    const isEnglish = language === 'en';

    for (const option of initialStatusOptions) {
      const resolveLabel = isEnglish ? option.labelEN : option.labelVI;
      resolveOptions.push({
        label: resolveLabel,
        value: option.value,
      });
    }

    return resolveOptions;
  }, [initialStatusOptions, language]);

  const onRowAction = async (
    user: { id: string; email: string; status: string },
    action: 'detail' | 'mail' | 'status'
  ) => {
    const { id, status } = user;
    if (action === 'detail')
      Utils.redirect({ pathname: ROUTERS.USER_INFORMATION_DETAILS, id });

    if (action === 'status') {
      const isActive = status === ENUMS.Status.ACTIVE;
      const message = isActive
        ? t('popup.warningDeactivateUser')
        : t('popup.warningActivateUser');
      const isAgree = await Alert({ type: 'WARNING', message });
      if (isAgree === 'ok') {
        const currentFilter = getValues();
        const resolveFilter = Utils.validateFilters(currentFilter);
        const isLastItem = payload.length === 1;
        if (isLastItem) {
          const page = currentFilter.page > 1 ? currentFilter.page - 1 : 1;
          assign(resolveFilter, { page });
        }
        if (isActive) dispatch(deactivateUser(id, resolveFilter));
        else dispatch(activateUser(id, resolveFilter));
      }
    }
  };

  const onPageAction = async (
    value: number,
    type: 'limit' | 'page' | 'status'
  ) => {
    const currentFilter = getValues();
    const newFilter = {
      ...currentFilter,
      [type]: value,
    };

    setValue(type, value);
    if (type === 'limit') {
      setValue('page', 1);
      Object.assign(newFilter, { page: 1 });
    }

    fetchPayload(newFilter);
  };

  const onSearch = (data: any) => fetchPayload(data);

  const onReset = () => {
    reset();
    fetchPayload(initialFilter);
  };

  const onAdd = () => setIsShowAddUser(true);

  const onExport = () => setIsShowExportPopup(true);

  const onClosePopup = () => {
    fetchPayload(initialFilter);
    setIsShowAddUser(false);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') event.preventDefault();
  };

  const _renderPopup = () => {
    return (
      <>
        <AddNewUser open={isShowAddUser} onClose={onClosePopup} />
        <ExportUserPopup
          open={isShowExportPopup}
          onClose={() => setIsShowExportPopup(false)}
          filterPayload={getValues()}
        />
      </>
    );
  };

  const _renderTopSection = () => {
    return (
      <Stack direction="column" mb={2}>
        <Typography variant="h2">{t('title.users')}</Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ flexWrap: 'wrap' }}
        >
          <Box component="form">
            <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
              <Controller
                name="keyword"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    size="small"
                    onChange={(e: any) => field.onChange(e.target.value)}
                    onKeyDown={onKeyDown}
                    onKeyPress={onKeyDown}
                    placeholder={t('placeholder.keyword')}
                    sx={{ mr: 1, maxWidth: '190px' }}
                  />
                )}
              />
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    sx={{ mr: 1, minWidth: '250px', width: '250px' }}
                    options={statusOptions}
                    onChange={(e: any) => field.onChange(e)}
                  />
                )}
              />

              <Button
                sx={{ mr: 1 }}
                onClick={handleSubmit(onSearch)}
                variant="contained"
              >
                {t('button.filter')}
              </Button>
              <Button onClick={() => onReset()} variant="outlined">
                {t('button.reset')}
              </Button>
            </Stack>
          </Box>
          <Stack direction="row">
            <Button onClick={onExport} variant="outlined">
              {t('button.export')}
            </Button>
            {isAcceptCreateUser && (
              <Button sx={{ ml: '10px' }} onClick={onAdd} variant="contained">
                {t('button.create')}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const _renderBottomSection = () => (
    <RoundedContainer>
      <UserDataTable
        payload={payload}
        meta={meta}
        onRowAction={onRowAction}
        onPageAction={onPageAction}
        isLoading={isLoading && !isShowExportPopup && !isShowAddUser}
      />
    </RoundedContainer>
  );

  return (
    <>
      {_renderPopup()}
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};
export default UserList;
