import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';

const { USER } = CONSTANTS.API;

export const fetchUser = async (payload: any) => {
  return sendRequest(USER.BASIC, 'GET', payload);
};

export const getUserById = async (id: string) => {
  return sendRequest(`${USER.BASIC}/${id}`, 'GET');
};

export const createUser = async (payload: any) => {
  return sendRequest(USER.BASIC, 'POST', payload);
};

export const updateUser = async (id: string, payload: FormData) => {
  return sendRequest(`${USER.BASIC}/${id}`, 'PUT', payload);
};

export const viewUserSalary = async (id: string) => {
  return sendRequest(`${USER.VIEW_SALARY}/${id}`, 'GET');
};

export const resetPassword = async (id: string) => {
  return sendRequest(`${USER.RESET_PASSWORD}/${id}`, 'POST');
};

export const changePassword = async (payload: any) => {
  return sendRequest(USER.CHANGE_PASSWORD, 'PUT', payload);
};

export const exportUsers = async (payload: any) => {
  return sendRequest(USER.EXPORT, 'POST_BLOB', payload);
};

export const activateUser = async (id: string) => {
  return sendRequest(`${USER.ACTIVATE}/${id}`, 'PUT');
};

export const deactivateUser = async (id: string) => {
  return sendRequest(`${USER.DEACTIVATE}/${id}`, 'PUT');
};

export const updateAvatarUser = async (id: string, payload: FormData) => {
  return sendRequest(`${USER.UPDATE_AVATAR}/${id}`, 'PUT_FORM_DATA', payload);
};

export const updateProfile = async (payload: any) => {
  return sendRequest(USER.UPDATE_PROFILE, 'PUT', payload);
};

export const updateAvatarProfile = async (payload: FormData) => {
  return sendRequest(USER.UPDATE_AVATAR_PROFILE, 'PUT_FORM_DATA', payload);
};

export const fetchUserUnassignedRole = async () => {
  return sendRequest(USER.UNASSIGNED_ROLE, 'GET');
};

export const assignRoleForUser = async (
  id: string,
  payload: { role: string }
) => {
  return sendRequest(`${USER.ASSIGN_ROLE}/${id}`, 'PUT', payload);
};
