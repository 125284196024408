import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
import { ICreateFeedbackGuest } from '@/Interfaces/FeedbackGuests.interface';

const { FEEDBACK_GUEST } = CONSTANTS.API;

export const fetchFeedbackGuests = async (payload: any) => {
  return sendRequest(FEEDBACK_GUEST.BASIC, 'GET', payload);
};

export const createFeedbackGuest = async (payload: ICreateFeedbackGuest) => {
  return sendRequest(FEEDBACK_GUEST.BASIC, 'POST', payload);
};
