/* eslint-disable indent */
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';

import {
  Box,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Typography,
  Link,
  Switch,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { RootState } from '@/store';
import { TableSkeleton } from '@/Components/Common/Skeleton';

import {
  ITask,
  IDailyTaskResponse,
  IDailyTaskProject,
} from '@/Interfaces/DailyTask.interface';

interface ISectionProps {
  payload: IDailyTaskResponse[];
}

type TDisplayMode = 'user' | 'date';

interface ITaskStructure {
  name: string;
  link: string;
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderLeft: `1px solid ${theme.palette.divider}`,
  borderRight: `1px solid ${theme.palette.divider}`,
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  // textAlign: 'center',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const DailyReportTable: React.FC<ISectionProps> = ({ payload }) => {
  const t = useTranslation();
  const uniqueDates = [
    ...new Set(payload.map((item) => dayjs(item.date).format('DD-MM-YYYY'))),
  ];

  const uniqueUsers = [...new Set(payload.map((item) => item.createdBy.id))];

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'isFetchLoading')
  );

  const [displayMode, setDisplayMode] = useState<TDisplayMode>('user');
  const [isStickyTable, setIsStickyTable] = useState<boolean>(false);

  const tableRef = useRef<any>(null);
  const tableHeadRef = useRef<any>(null);
  const NAVBAR_HEIGHT = 48;

  const handleScroll = () => {
    const tableHeader = tableRef?.current?.querySelector('thead');
    if (tableRef && tableRef?.current) {
      const rect = tableHeader?.getBoundingClientRect();
      if (rect?.top <= NAVBAR_HEIGHT) {
        tableHeader.style.position = 'sticky';
        tableHeader.style.top = `${NAVBAR_HEIGHT}px`;
        tableHeader.style.zIndex = 101;
      } else {
        tableHeader.style.position = 'static';
        tableHeader.style.top = 'auto';
        tableHeader.style.zIndex = 'auto';
      }
      setIsStickyTable(rect?.top <= NAVBAR_HEIGHT);
    }
  };

  const throttledHandleScroll = _.throttle(handleScroll, 100);

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll, true);
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll, true);
    };
  }, [tableRef.current]);

  const _renderTableHead = () => {
    return (
      <StyledTableRow
        sx={{
          background: '#fff',
          boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
        }}
      >
        <StyledTableCell
          sx={{
            // eslint-disable-next-line max-len
            background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='0' x2='100' y2='100' stroke='rgba(0, 0, 0, 0.12)' vector-effect='non-scaling-stroke'/></svg>")`,
            backgroundSize: '100% 100%',
            marginBottom: ' 1rem',
            justifyContent: ' space-between',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridAutoRows: '1fr',
            minWidth: 135,
          }}
        >
          <Box sx={{ gridColumnStart: 2, textAlign: 'right' }}>
            {displayMode === 'date' ? t('label.user') : t('label.date')}
          </Box>
          <Box sx={{ gridColumnStart: 1 }}>
            {displayMode === 'date' ? t('label.date') : t('label.user')}
          </Box>
        </StyledTableCell>
        {displayMode === 'date' &&
          uniqueUsers.map((userId, index) => {
            const user = payload.find((item) => item.createdBy.id === userId);
            return (
              <StyledTableCell key={index} sx={{ minWidth: 100 }}>
                {user?.createdBy?.userData?.fullName}
              </StyledTableCell>
            );
          })}
        {displayMode === 'user' &&
          uniqueDates.map((date, index) => {
            return (
              <StyledTableCell key={index} sx={{ minWidth: 100 }}>
                {date}
              </StyledTableCell>
            );
          })}
      </StyledTableRow>
    );
  };

  const _renderTableBody = () => {
    return displayMode === 'date'
      ? uniqueDates.map((date, index) => (
          <StyledTableRow key={`${date}-${index}`}>
            <StyledTableCell>{date}</StyledTableCell>
            {uniqueUsers.map((user, userIndex) => {
              const report = payload.find(
                (item) =>
                  dayjs(item.date).format('DD-MM-YYYY') === date &&
                  item.createdBy.id === user
              );
              return (
                <StyledTableCell key={`${user}-${userIndex}`}>
                  {report ? (
                    <Box>
                      {_.map(
                        report?.dailyTaskProjects,
                        (
                          dailyTask: IDailyTaskProject,
                          dailyTaskindex: number
                        ) => {
                          return (
                            <Box key={dailyTask?.id || dailyTaskindex}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  m: 0,
                                  width: 'max-content',
                                  fontWeight: 500,
                                }}
                              >
                                {dailyTask.project.name}
                              </Typography>
                              <Box>
                                {_.map(
                                  dailyTask.tasks,
                                  (task: ITaskStructure, _index: number) => (
                                    <Box
                                      key={_index}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <AddIcon sx={{ fontSize: 12 }} />
                                      <Link href={task.link} target="_blank">
                                        {task.name}
                                      </Link>
                                    </Box>
                                  )
                                )}
                                {dailyTask.note && (
                                  <Box>
                                    <Typography variant="body2">
                                      <b>{t('label.note')}:</b>
                                    </Typography>
                                    <Typography variant="body2">
                                      {dailyTask.note}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  ) : (
                    'N/A'
                  )}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        ))
      : uniqueUsers.map((user, userIndex) => {
          const currentItem = _.find(
            payload,
            (data: any) => data.createdBy.id === user
          );
          return (
            <StyledTableRow key={`${user}-${userIndex}`}>
              <StyledTableCell>
                {currentItem?.createdBy?.userData?.fullName}
              </StyledTableCell>
              {uniqueDates.map((date, _index) => {
                const report = payload.find(
                  (item) =>
                    dayjs(item.date).format('DD-MM-YYYY') === date &&
                    item.createdBy.id === user
                );
                return (
                  <StyledTableCell key={`${user}-${_index}`}>
                    {report ? (
                      <Box>
                        {_.map(
                          report?.dailyTaskProjects,
                          (dailyTask: IDailyTaskProject, index: number) => {
                            return (
                              <Box key={dailyTask?.id || index}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    m: 0,
                                    width: 'max-content',
                                    fontWeight: 500,
                                  }}
                                >
                                  {dailyTask.project.name}
                                </Typography>
                                <Box>
                                  {_.map(
                                    dailyTask.tasks,
                                    (task: ITask, taskIndex: number) => (
                                      <Box
                                        key={taskIndex}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <AddIcon
                                          sx={{
                                            fontSize: 12,
                                          }}
                                        />
                                        <Tooltip title={task.link}>
                                          <Link
                                            href={task.link}
                                            target="_blank"
                                          >
                                            {task.name}
                                          </Link>
                                        </Tooltip>
                                      </Box>
                                    )
                                  )}
                                  {dailyTask.note && (
                                    <Box>
                                      <Typography variant="body2">
                                        <Box
                                          component="b"
                                          sx={{ fontWeight: 500 }}
                                        >
                                          {t('label.note')}:
                                        </Box>
                                      </Typography>
                                      <Typography variant="body2">
                                        {dailyTask.note}
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    ) : (
                      'N/A'
                    )}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          );
        });
  };

  const _renderTable = () => (
    <TableContainer
      sx={{
        maxHeight: isStickyTable
          ? `calc(100vh - ${tableHeadRef?.current?.offsetHeight || 65}px)`
          : 'auto',
      }}
    >
      {isFetchLoading ? (
        <TableSkeleton />
      ) : (
        <Table sx={{ minWidth: 1 }} ref={tableRef}>
          <TableHead ref={tableHeadRef}>{_renderTableHead()}</TableHead>
          <TableBody>
            {!_.isEmpty(payload) ? (
              _renderTableBody()
            ) : (
              <TableCell>{t('table.noDataWereFound')}</TableCell>
            )}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );

  const renderMain = () => {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Switch
            checked={displayMode === 'user'}
            onChange={() =>
              setDisplayMode(displayMode === 'user' ? 'date' : 'user')
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography>
            {t('label.switchViewsBy')} {t('label.user')} / {t('label.date')}
          </Typography>
        </Box>
        {_renderTable()}
      </Box>
    );
  };
  return renderMain();
};

export default DailyReportTable;
