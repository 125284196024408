import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import AuthReducer from './Authentication.reducer';
import DevToolsReducer from './DevTool.reducer';
import RoleReducer from './Role.reducer';
import ProjectReducer from './Project.reducer';
import UserReducer from './User.reducer';
import FolderReducer from './Folder.reducer';
import MediaReducer from './Media.reducer';
import LogReducer from './Logs.reducer';
import CommonReducer from './Common.reducer';
import DailyReport from './DailyReport.reducer';
import WikiReducer from './Wiki.reducer';
import EventReducer from './Event.reducer';
import Attendance from './Attendance.reducer';
import ProjectMemberReducer from './ProjectMember.reducer';
import AnnouncementReducer from './Announcement.reducer';
import TagReducer from './Tag.reducer';
import CrawlingSystemReducer from './CrawlingSystem.reducer';
import AnnouncementUserReducer from './AnnouncementUser.reducer.';
import FeedbackReducer from './Feedbacks.reducer';
import FeedbackGuestReducer from './FeedbackGuests.reducer';
import ConfigSystemReducer from './ConfigSystem.reducer';
import FileTemplateReducer from './FileTemplate.reducer';
import WorkProgressReducer from './WorkProgress.reducer';
import ToolReducer from './Tool.reducer';
import NewsReducer from './News.reducer';
import DailyTaskReducer from './DailyTask.reducer';
import AdminPorfolioReducer from './AdminPorfolio.reducer';
import PortfolioReducer from './Portfolio.reducer';

const createRootReducer = (history: History) =>
  combineReducers({
    ROUTER: connectRouter(history),
    AUTH: AuthReducer,
    DEV_TOOL: DevToolsReducer,
    ROLE: RoleReducer,
    PROJECT: ProjectReducer,
    USER: UserReducer,
    FOLDERS: FolderReducer,
    MEDIA: MediaReducer,
    LOGS: LogReducer,
    COMMON: CommonReducer,
    DAILY_REPORT: DailyReport,
    WIKI: WikiReducer,
    EVENT: EventReducer,
    ATTENDANCE: Attendance,
    PROJECT_MEMBER: ProjectMemberReducer,
    ANNOUNCEMENT: AnnouncementReducer,
    TAG: TagReducer,
    CRAWLING_SYSTEM: CrawlingSystemReducer,
    ANNOUNCEMENT_USER: AnnouncementUserReducer,
    FEEDBACK: FeedbackReducer,
    FEEDBACK_GUEST: FeedbackGuestReducer,
    CONFIG_SYSTEM: ConfigSystemReducer,
    FILE_TEMPLATE: FileTemplateReducer,
    WORK_PROGRESS: WorkProgressReducer,
    TOOL: ToolReducer,
    NEWS: NewsReducer,
    DAILY_TASK: DailyTaskReducer,
    ADMIN_PORFOLIO: AdminPorfolioReducer,
    PORTFOLIO: PortfolioReducer,
  });

export default createRootReducer;
