import { Skeleton, Stack } from '@mui/material';

const AnnouncementSkeleton = () => {
  const _renderRowSkeleton = (isMargin?: boolean) => (
    <Stack direction="column" mb={isMargin ? 1 : 0}>
      <Stack direction="row" alignItems="center" flex={1}>
        <Skeleton variant="circular" width={10} height={10} sx={{ mr: 1 }} />
        <Skeleton width={160} height={40} />
      </Stack>
    </Stack>
  );

  return (
    <Stack direction="column">
      {_renderRowSkeleton(true)}
      {_renderRowSkeleton(true)}
      {_renderRowSkeleton()}
    </Stack>
  );
};

export default AnnouncementSkeleton;
