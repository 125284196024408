/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
import React from 'react';
import _ from 'lodash';

import { Box, Button, Grid, Paper, Typography, Container } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import Slider from 'react-slick';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';
import { Helmet } from 'react-helmet';
import { TypeAnimation } from 'react-type-animation';

import Fade from 'react-reveal/Fade';
import { motion } from 'framer-motion';
import ProgressiveImage from 'react-progressive-graceful-image';

import { mainWrapper } from './About.styles';
import { CommonColors, Images } from '@/Themes';

const OUR_CLIENT = [
  Images.minRinis,
  Images.minRural,
  Images.minSportit,
  Images.raiffeisenLogo,
  Images.telemetrixLogo,
  Images.theworldbankLogo,
  Images.undpLogo,
  Images.kombetare,
];

// const { ROUTERS } = Constants;
const AboutUs: React.FC = () => {
  const slickSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    centermode: true,
    centerPadding: '50px',
    arrows: false,
  };

  const _renderBanner = () => (
    <Box sx={{ background: '#EAEAE5', mb: 3 }}>
      <Container component="main" maxWidth="lg">
        <Grid container>
          <Grid item xs={6}>
            <ProgressiveImage
              src={Images.teamBanner}
              placeholder={Images.teamPlaceholder}
            >
              {(src: string, loading: boolean | undefined): any => (
                <img
                  className={`image${
                    loading ? ' loading-image' : ' loaded-image'
                  }`}
                  src={src}
                  alt="sea beach"
                  style={{ objectFit: 'contain', width: '100%' }}
                />
              )}
            </ProgressiveImage>
          </Grid>
          <Grid item xs={6} sx={{ p: 4 }}>
            <Typography sx={{ fontWeight: 700, fontSize: '2.5rem' }}>
              About us
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                color: CommonColors.shuttleGray,
              }}
            >
              Collaborate. Communicate. Conquer.
            </Typography>

            <Box>
              <TypeAnimation
                sequence={[
                  `Flow-IDRA was established in 2017 and is a subsidiary of IDRA
              Research & Consulting. Its primary focus is on research and
              software development. With a young and dynamic team boasting
              diverse expertise and experience in the field of information
              technology, we are committed to collaborating with you to
              transform your ideas into reality`,
                ]}
                style={{ fontWeight: 400, marginTop: 2, fontFamily: 'monospace' }}
                repeat={1}
                speed={{type: "keyStrokeDelayInMs", value: 15}}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Slider {...slickSettings}>
              {_.map(OUR_CLIENT, (logo: any, index: number) => (
                <Box key={index} sx={{ mx: 1 }}>
                  <LazyLoadImage
                    key={index}
                    src={logo}
                    alt="clientLogo"
                    loading="lazy"
                    style={{ width: 150, objectFit: 'contain', height: 150 }}
                  />
                </Box>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const _renderVision = () => (
    <Box>
      <Container component="main" maxWidth="lg">
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <Fade left cascade>
              <Paper
                elevation={0}
                sx={{
                  boxShadow: '-24px 24px 72px -8px rgba(145, 158, 171, 0.24)',
                  p: 4,
                  borderRadius: 4,
                }}
              >
                <Typography sx={{ fontWeight: 700, fontSize: '2rem' }}>
                  Our Vision
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    mt: 2,
                  }}
                  component="div"
                >
                  At Flow-IDRA, we envision a future where innovative technology
                  solutions empower businesses and individuals to thrive in a
                  digital world. Our vision is to be a leading force in shaping
                  this future, harnessing the potential of cutting-edge
                  technologies to create impactful web and mobile solutions.
                </Typography>
              </Paper>
            </Fade>
          </Grid>
          <Grid item xs={6}>
            <ProgressiveImage
              src={Images.ourVision}
              placeholder={Images.ourVisionPlaceholder}
            >
              {(src: string, loading: boolean | undefined): any => (
                <img
                  className={`image${
                    loading ? ' loading-image' : ' loaded-image'
                  }`}
                  src={src}
                  alt="sea beach"
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                  }}
                />
              )}
            </ProgressiveImage>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const _renderApproach = () => (
    <Box>
      <Container component="main" maxWidth="lg">
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <ProgressiveImage
              src={Images.ourApproach}
              placeholder={Images.ourApproachPlaceholder}
            >
              {(src: string, loading: boolean | undefined): any => (
                <img
                  className={`image${
                    loading ? ' loading-image' : ' loaded-image'
                  }`}
                  src={src}
                  alt="sea beach"
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                  }}
                />
              )}
            </ProgressiveImage>
          </Grid>
          <Grid item xs={6}>
            <Fade right cascade>
              <Paper
                elevation={0}
                sx={{
                  boxShadow: '-24px 24px 72px -8px rgba(145, 158, 171, 0.24)',
                  p: 4,
                  borderRadius: 4,
                }}
              >
                <Typography sx={{ fontWeight: 700, fontSize: '2rem' }}>
                  Our Approach
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    mt: 2,
                    whiteSpace: 'pre-line',
                  }}
                  component="div"
                >
                  At Flow-IDRA, our approach to IT solutions is grounded in a
                  commitment to excellence, innovation, and client satisfaction.
                  We believe in harnessing the power of cutting-edge
                  technologies to deliver solutions that not only meet but
                  exceed our clients' expectations.
                </Typography>
              </Paper>
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const _renderProcess = () => (
    <Box>
      <Container component="main" maxWidth="lg">
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <Fade left cascade>
              <Paper
                elevation={0}
                sx={{
                  boxShadow: '-24px 24px 72px -8px rgba(145, 158, 171, 0.24)',
                  p: 4,
                  borderRadius: 4,
                }}
              >
                <Typography sx={{ fontWeight: 700, fontSize: '2rem' }}>
                  Our Process
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    mt: 2,
                    whiteSpace: 'pre-line',
                  }}
                  component="div"
                >
                  Our development process is a refined orchestration of
                  creativity, expertise, and precision. From conceptualization
                  to deployment, we follow a systematic approach to ensure the
                  seamless execution of every project.
                  {'\n'}
                  {'\n'}
                  1. Idea Exploration: We start by delving into your ideas,
                  understanding your vision, and exploring the possibilities.
                  Our team collaborates closely with you to define project goals
                  and objectives.
                  {'\n'}
                  {'\n'}
                  2. Strategic Planning: With a clear understanding of your
                  requirements, we strategize and plan the project. This
                  involves technology selection, resource allocation, and a
                  timeline that ensures timely and efficient delivery.
                  {'\n'}
                  {'\n'}
                  3. Development & Design: Our proficient team utilizes a
                  diverse set of languages and technologies to craft robust,
                  user-friendly solutions. From front-end development using
                  ReactJS and React Native to back-end development with NodeJS
                  and NestJS, we bring your vision to life.
                  {'\n'}
                  {'\n'}
                  4. Quality Assurance: Rigorous testing is an integral part of
                  our process. We conduct thorough quality assurance checks to
                  ensure that the solution is not only functional but also
                  reliable and secure.
                  {'\n'}
                  {'\n'}
                  5. Deployment & Support: Once the solution passes our
                  stringent testing phase, we deploy it with precision. Our
                  commitment doesn't end with deployment; we provide ongoing
                  support to ensure the continued success and adaptability of
                  the solution.
                </Typography>
              </Paper>
            </Fade>
          </Grid>
          <Grid item xs={6}>
            <ProgressiveImage
              src={Images.ourProcess}
              placeholder={Images.ourProcessPlaceholder}
            >
              {(src: string, loading: boolean | undefined): any => (
                <img
                  className={`image${
                    loading ? ' loading-image' : ' loaded-image'
                  }`}
                  src={src}
                  alt="sea beach"
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                  }}
                />
              )}
            </ProgressiveImage>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const _renderOurTeam = () => (
    <Box sx={{ background: '#EAEAE5', overflow: 'hidden' }}>
      <Container component="main" maxWidth="lg">
        <Grid container columnSpacing={2} p={2}>
          <Grid item xs={6}>
            <MapContainer
              center={[16.458857, 107.59148]}
              zoom={15}
              scrollWheelZoom={false}
              style={{ height: '300px', width: '100%' }}
              dragging={false}
              zoomControl={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker
                position={[16.458857, 107.59148]}
                icon={
                  new Icon({
                    iconUrl: markerIconPng,
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                  })
                }
              />
            </MapContainer>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                rowGap: 4,
              }}
            >
              <Typography
                sx={{ fontWeight: 700, fontSize: '2rem' }}
                textAlign="center"
              >
                Our Team
              </Typography>
              <Typography sx={{ fontWeight: 400 }} textAlign="center">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry
              </Typography>
              <motion.div
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
                style={{ width: '100%', textAlign: 'center' }}
              >
                <Button
                  variant="contained"
                  endIcon={<Diversity1Icon />}
                  sx={{ fontWeight: 700 }}
                  onClick={() =>
                    window.open(
                      'https://idracompany.com/who-we-are?category=ourTeam',
                      '_blank'
                    )
                  }
                >
                  See us on IDRA Team
                </Button>
              </motion.div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const renderMain = () => {
    return (
      <Box sx={{ ...mainWrapper }}>
        <Helmet>
          <title>About Us</title>
        </Helmet>
        {_renderBanner()}
        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          {_renderVision()}
        </motion.div>
        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          {_renderApproach()}
        </motion.div>
        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          {_renderProcess()}
        </motion.div>
        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          {_renderOurTeam()}
        </motion.div>
      </Box>
    );
  };
  return renderMain();
};
export default AboutUs;
