import { Box, Skeleton, Stack } from '@mui/material';

const NewsLoadding: React.FC = () => {
  return (
    <Box>
      <Stack>
        <Skeleton />
      </Stack>
      <Stack>
        <Skeleton
          sx={{
            height: '160px',
            my: 3,
          }}
        />
      </Stack>
      <Stack>
        <Skeleton />
      </Stack>
    </Box>
  );
};
export default NewsLoadding;
