import { setDefaultLanguage, setTranslations } from 'react-multi-lang';
import en from './en';
import vi from './vi';
import { getSavedLocale } from '@/Utils/Cookie.utils';

const initTranslations = () => {
  const language = getSavedLocale();
  setTranslations({ en, vi });
  setDefaultLanguage(language);
};

export default { initTranslations };
