import { useLayoutEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Router, useRoutes } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { styled } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider } from '@mui/material/styles';

import { CssBaseline } from '@mui/material';
import enLocale from 'date-fns/locale/en-US';
import { store, history as historyStore } from '@/store';

// Potals
import MainRouter from './MainRouters';
import AuthRouter from './AuthenticationRouters';
import CommonRouter from './CommonRouters';
import CoreTheme from '../Themes/CoreTheme';
import HomeRouter from './HomeRouter';

const ToastContainerStyled = styled(ToastContainer)`
  &.Toastify__toast-container {
    z-index: 200000;
  }
`;

function RootRouter({ history, ...props }: any) {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
}

function App() {
  const elements = useRoutes([
    AuthRouter,
    MainRouter,
    CommonRouter,
    HomeRouter,
  ]);

  return elements;
}

function Root() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={enLocale}>
      <Provider store={store}>
        <div id="alert" />
        <div id="notification" />
        <ToastContainerStyled
          position="bottom-right"
          autoClose={1500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover={false}
        />
        <RootRouter history={historyStore}>
          <ThemeProvider theme={CoreTheme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </RootRouter>
      </Provider>
    </LocalizationProvider>
  );
}

export default hot(Root);
