import React from 'react';
import Lightbox, { SlideImage } from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import 'yet-another-react-lightbox/styles.css';

interface ISectionProps {
  open: boolean;
  onClose(): void;
  payload: SlideImage[];
  index?: number;
}

const LightBoxImage: React.FC<ISectionProps> = ({
  open,
  onClose,
  payload,
  index = 0,
}) => {
  return (
    <Lightbox
      index={index}
      open={open}
      close={onClose}
      slides={payload}
      plugins={[Fullscreen, Zoom]}
    />
  );
};

export default LightBoxImage;
