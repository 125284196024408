import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import { useTranslation } from 'react-multi-lang';

import {
  InputLabel,
  Box,
  IconButton,
  Stack,
  Typography,
  Tooltip,
} from '@mui/material';
import { CommonColors } from '@/Themes';
import ImageIcon from '@mui/icons-material/Image';
interface IUploadFileInput {
  label?: string;
  onFileChange(newFiles: File[]): void;
  files: File[];
  message?: string;
  accept?: string;
  direction?: 'row' | 'column';
  containerSx?: object;
  labelSx?: object;
  required?: boolean;
  disabled?: boolean;
  type?: 'LOGO' | 'AVATAR';
  preview?: string;
}

const ALLOWED_IMAGE = ['image/jpg', 'image/jpeg', 'image/png'];

const DEFAULT_PREVIEW = {
  AVATAR: 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png',
  LOGO: 'https://www.madeireiraestrela.com.br/images/joomlart/demo/default.jpg',
};

const UploadFileInput: React.FC<IUploadFileInput> = ({
  label,
  onFileChange,
  message = '',
  files = [],
  accept = '.png,.jpg,.jpeg',
  direction = 'column',
  containerSx,
  disabled = false,
  type = 'AVATAR',
  preview = '',
}) => {
  const t = useTranslation();
  const [validateMessage, setValidateMessage] = useState<string>('');
  const [previewImage, setPreviewImage] = useState<string>(
    DEFAULT_PREVIEW[type]
  );
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (preview) setPreviewImage(preview);
  }, [preview]);

  useEffect(() => {
    if (isEmpty(files)) {
      if (inputRef && inputRef.current) inputRef.current.value = '';
      setPreviewImage(DEFAULT_PREVIEW[type]);
    } else {
      const imageUrl = URL.createObjectURL(files[0]);
      setPreviewImage(imageUrl);
    }
  }, [files]);

  useEffect(() => {
    setValidateMessage(message);
  }, [message]);

  const onChangeFile = (e: any) => {
    if (e) {
      setValidateMessage('');
      const targetFiles = e.target.files;
      if (targetFiles.length !== 0) {
        const resolveFile: File = targetFiles[0];

        let isValid = true;

        if (resolveFile.size > 2000000) {
          setValidateMessage(`${t('message.fileLargeThan')} 2MB'`);
          isValid = false;
        }

        if (!includes(ALLOWED_IMAGE, resolveFile.type)) {
          setValidateMessage(t('message.fileTypeWrong'));
          isValid = false;
        }

        if (isValid) onFileChange([resolveFile]);
        else onFileChange([]);
      }
    }
  };

  return (
    <Stack>
      <Box
        sx={{
          borderBottom: 'none',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: direction,
          ...containerSx,
        }}
        component="div"
      >
        {label ? (
          <InputLabel
            sx={{
              color: 'black',
              mb: 1,
            }}
          >
            {label}
          </InputLabel>
        ) : null}
        <Box
          component="div"
          sx={{
            position: 'relative',
            boxShadow: '0 7px 25px rgba(0,0,0,0.08)',
            boxSizing: 'border-box',
            transition: 'all ease 0.25s',
            overflow: 'hidden',
            borderRadius: type === 'AVATAR' ? '50%' : '4px',
            width: type === 'AVATAR' ? '250px' : '100%',
            height: type === 'AVATAR' ? '250px' : 'auto',
            maxHeight: '250px',
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: !isEmpty(message) ? 'error.main' : '#ccc',
            '&:hover': {
              borderColor: !isEmpty(message)
                ? 'error.main'
                : 'rgba(0, 0, 0, 0.87)',
              '& .image-buttons': {
                opacity: 1,
              },
            },
          }}
        >
          <img
            src={previewImage}
            alt=""
            width="auto"
            height="100%"
            style={{
              objectFit: type === 'AVATAR' ? 'cover' : 'contain',
              maxWidth: '100%',
              borderRadius: '4px',
            }}
          />
          <Box
            component="div"
            className="image-buttons"
            sx={{
              opacity: 0,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0,0,0,0.5)',
              transition: 'all ease 0.25s',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Tooltip title="Edit Image">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                disabled={disabled}
                sx={{ color: CommonColors.mystic, m: '0 5px' }}
              >
                <ImageIcon />
                <input
                  hidden
                  type="file"
                  accept={accept}
                  ref={inputRef}
                  onChange={(e: any) => onChangeFile(e)}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {validateMessage && (
        <Typography fontSize="13px" color="error">
          {validateMessage}
        </Typography>
      )}
    </Stack>
  );
};

export default UploadFileInput;
