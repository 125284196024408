export default {
  AUTHENTICATION: {
    LOGIN: 'POST - /api/auth/login',
    FORGOT_PASSWORD: 'POST - /api/auth/forgot-password',
    REFRESH_TOKEN: 'POST - /api/auth/refresh-token',
  },
  FILE: {
    FETCH_FILES: 'GET - /api/files',
    UPLOAD_FILE: 'POST - /api/files',
    REMOVE_FILE: 'DELETE - /api/files/:id',
    REMOVE_MULTIPLE_FILE: 'DELETE - /api/files',
    DOWNLOAD_MULTIPLE: 'POST - /api/files/download',
  },
  FOLDER: {
    FETCH_FOLDERS: 'GET - /api/folders',
    CREATE_FOLDER: 'POST - /api/folders',
    UPDATE_FOLDER: 'PUT - /api/folders/:id',
    DELETE_FOLDER: 'DELETE - /api/folders/:id',
  },
  PROJECT: {
    FETCH_PROJECTS: 'GET - /api/projects',
    EXPORT_PROJECTS: 'GET - /api/projects/export',
    GET_PROJECT_BY_ID: 'GET - /api/projects/:id',
    CREATE_PROJECT: 'POST - /api/projects',
    UPDATE_PROJECT: 'PUT - /api/projects/:id',
    DELETE_PROJECT: 'DELETE - /api/projects/:id',
    UPDATE_DOCUMENTATION: 'PUT - /api/projects/documentation/:id',
  },
  ROLE_PERMISSION: {
    FETCH_ROLES: 'GET - /api/roles',
    CREATE_ROLE: 'POST - /api/roles',
    UPDATE_ROLE: 'PUT - /api/roles/:id',
    FETCH_PERMISSIONS: 'GET - /api/roles/permissions',
    GET_ROLE_BY_ID: 'GET - /api/roles/:id',
    ACTIVATE_ROLE: 'PUT - /api/roles/activate/:id',
    DEACTIVATE_ROLE: 'PUT - /api/roles/deactivate/:id',
  },
  USER: {
    FETCH_USERS: 'GET - /api/users',
    GET_USER_BY_ID: 'GET - /api/users/:id',
    CREATE_USER: 'POST - /api/users',
    UPDATE_USER_BY_ID: 'PUT - /api/users/:id',
    ACTIVATE_USER: 'PUT - /api/users/activate/:id',
    DEACTIVATE_USER: 'PUT - /api/users/deactivate/:id',
    RESET_PASSWORD: 'POST - /api/users/reset-password/:id',
    CHANGE_PASSWORD: 'PUT - /api/users/change-password',
    EXPORT_USERS: 'GET - /api/users/export',
  },
  LOGS: {
    FETCH_LOGS: 'GET - /api/logs',
  },
  DAILY_REPORT: {
    FETCH: 'GET - /api/daily-report',
    FETCH_PROJECT_REPORTS: 'GET - /api/daily-report/projects',
    CREATE: 'POST - /api/daily-report',
    UPDATE: 'PUT - /api/daily-report/:id',
    REMOVE: 'DELETE - /api/daily-report/:id',
  },
  WIKI: {
    FETCH: 'GET - /api/wiki',
    CREATE: 'POST - /api/wiki',
    UPDATE: 'PUT - /api/wiki/:id',
    UPDATE_ATTACHMENTS: 'PUT - /api/wiki/attachments/:id',
    DELETE: 'DELETE - /api/wiki/:id',
    GET_BY_ID: 'GET - /api/wiki/:id',
  },
  EVENT: {
    FETCH_EVENT: 'GET - /api/events',
    CREATE_EVENT: 'POST - /api/events',
    UPDATE_EVENT: 'PUT - /api/events/:id',
    DELETE_EVENT: 'DELETE - /api/events/:id',
  },
  ATTENDANCE: {
    FETCH: 'GET - /api/attendances',
    FETCH_OWN: 'GET - /api/attendances/own',
    UPDATE: 'PUT - /api/attendances',
    GET_BY_ID: 'GET - /api/attendances/:id',
    EXPORT: 'GET - /api/attendances/export',
  },
  PROJECT_MEMBER: {
    FETCH: 'GET - /api/project-members',
    FETCH_UNASSIGNED_MEMBER: 'GET - /api/project-members/unassigned/:id',
    CREATE: 'POST - /api/project-members',
    UPDATE: 'PUT - /api/project-members/:id',
    DELETE: 'DELETE - /api/project-members/:id',
  },
  ANNOUNCEMENT: {
    FETCH_ANNOUNCEMENT: 'GET - /api/announcements',
    GET_ANNOUNCEMENT_BY_ID: 'GET - /api/announcements/:id',
    CREATE_ANNOUNCEMENT: 'POST - /api/announcements',
    UPDATE_ANNOUNCEMENT: 'PUT - /api/announcements/:id',
    DELETE_ANNOUNCEMENT: 'DELETE - /api/announcements/:id',
    GET_FOR_USER: 'GET - /api/announcements/forUser',
    UPDATE_ANNOUNCEMENT_TYPE: 'PUT - /api/announcements/updateType/:id',
  },
  CRAWLING_SYSTEM: {
    FETCH_CRAWL_PROJECT: 'GET - /api/crawl-project',
    GET_CRAWL_PROJECT_BY_ID: 'GET - /api/crawl-project/get-by-id/:id',
    CREATE_CRAWL_PROJECT: 'POST - /api/crawl-project',
    UPDATE_CRAWL_PROJECT: 'PUT - /api/crawl-project/:id',
    ACTIVATE_CRAWL_PROJECT: 'PUT - /api/crawl-project/activate/:id',
    DEACTIVATE_CRAWL_PROJECT: 'PUT - /api/crawl-project/deactivate/:id',
    DELETE_CRAWL_PROJECT: 'DELETE - /api/crawl-project/:id',
  },
  TAG: {
    FETCH_TAG: 'GET - /api/tags',
    GET_BY_ID: 'GET - /api/tags/:id',
    CREATE_TAG: 'POST - /api/tags',
    UPDATE_TAG: 'PUT - /api/tags/:id',
    DELETE_TAG: 'DELETE - /api/tags/:id',
  },
  FEEDBACKS: {
    FETCH_FEEDBACKS: 'GET - /api/feedbacks',
    GET_FEEDBACK_BY_ID: 'GET - /api/feedbacks/:id',
    CREATE_FEEDBACK: 'POST - /api/feedbacks',
    UPDATE_FEEDBACK: 'PUT - /api/feedbacks/:id',
    CREATE_THREAD: 'POST - /api/feedbacks/thread',
    UPDATE_THREAD: 'PUT - /api/feedbacks/thread/:id',
    DELETE_THREAD: 'DELETE - /api/feedbacks/thread/:id',
    UPDATE_FEEDBACK_STATUS: 'PUT - /api/feedbacks/status/:id',
    GET_FOR_DASHBOARD: 'GET - /api/feedbacks/dashboard',
    GET_ALL_FOR_USER: 'GET - /api/feedbacks/forUser',
    ASSIGN_HANDLER: 'PUT - /api/feedbacks/assign/:id',
  },
  FEEDBACK_GUESTS: {
    FETCH_FEEDBACK_GUESTS: 'GET - /api/feedback-guest',
  },
  CONFIG_SYSTEMS: {
    FETCH_CONFIG_SYSTEMS: 'GET - /api/config-systems',
    GET_CONFIG_SYSTEM_BY_ID: 'GET - /api/config-systems/:id',
    CREATE_CONFIG_SYSTEM: 'POST - /api/config-systems',
    UPDATE_CONFIG_SYSTEM: 'PUT - /api/config-systems/:id',
    UPDATE_DOCUMENTATION: 'PUT - /api/config-systems/documentation/:id',
    DELETE_CONFIG_SYSTEM: 'DELETE - /api/config-systems/:id',
  },
  FILE_TEMPLATES: {
    FETCH_FILE_TEMPLATES: 'GET - /api/file-templates',
    GET_FILE_TEMPLATE_BY_ID: 'GET - /api/file-templates/:id',
    CREATE_FILE_TEMPLATE: 'POST - /api/file-templates',
    UPDATE_FILE_TEMPLATE: 'PUT - /api/file-templates/:id',
    DELETE_FILE_TEMPLATE: 'DELETE - /api/file-templates/:id',
  },
  WORK_PROGRESS: {
    FETCH: 'GET - /api/work-progress',
    GET_BY_ID: 'GET - /api/work-progress/:id',
    CREATE: 'POST - /api/work-progress',
    UPDATE: 'PUT - /api/work-progress/:id',
    DELETE: 'DELETE - /api/work-progress/:id',
    CREATE_STEP: 'POST - /api/work-progress/step-progress',
    GET_STEP_BY_ID: 'GET - /api/work-progress/step-progress/:id',
    UPDATE_STEP: 'PUT - /api/work-progress/step-progress/:id',
    DELETE_STEP: 'DELETE - /api/work-progress/step-progress/:id',
  },
  TOOL: {
    FETCH_TOOLS: 'GET - /api/tools',
    GET_TOOL_BY_ID: 'GET - /api/tools/:id',
    CREATE_TOOL: 'POST - /api/tools',
    UPDATE_TOOL: 'PUT - /api/tools/:id',
    DELETE_TOOL: 'DELETE - /api/tools/:id',
  },
  NEWS: {
    FETCH_NEWS: 'GET - /api/news',
    GET_POST_BY_ID: 'GET - /api/news/:id',
    CREATE_POST: 'POST - /api/news',
    UPDATE_POST: 'PUT - /api/news/:id',
    ACTIVE_POST: 'PUT - /api/news/activate/:id',
    DEACTIVE_POST: 'PUT - /api/news/deactive/:id',
    PUBLISH_POST: 'PUT - /api/news/publish/:id',
    DELETE_POST: 'DELETE - /api/news/:id',
  },
  DAILY_TASK: {
    FETCH: 'GET - /api/daily-task',
    GET_BY_ID: 'GET - /api/daily-task/:id',
    MY: 'GET - /api/daily-task/me',
    CREATE: 'POST - /api/daily-task',
    UPDATE: 'PUT - /api/daily-task/:id',
    DELETE: 'DELETE - /api/daily-task/:id',
  },
  PORTFOLIO: {
    FETCH: 'GET - /api/portfolio',
    GET_NEW_BY_ID: 'GET - /api/portfolio/:id',
    CREATE: 'POST - /api/portfolio',
    UPDATE: 'PUT - /api/portfolio/:id',
    PUBLISH: 'PUT - /api/portfolio/publish/:id',
    ACTIVATE: 'PUT - /api/portfolio/activate/:id',
    DEACTIVATE: 'PUT - /api/portfolio/deactivate/:id',
  },
};
