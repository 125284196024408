/* eslint-disable no-shadow */
export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ALL_TYPE = 'allType',
}

export enum EmployeeStatus {
  INTERN = 'intern',
  APPRENTICESHIP = 'apprenticeship',
  PROBATIONARY = 'probationary',
  OFFICIAL_STAFF = 'officialStaff',
}

export enum Role {
  EMPLOYEE = 'EE',
  LEADER = 'LE',
  HUMAN_RESOURCES = 'HR',
  OFFICE_MANAGER = 'OM',
  ADMIN = 'AD',
}

export enum FormStatus {
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  DENIED = 'denied',
}

export enum PerformanceEvaluation {
  EXCELLENT = 'excellent',
  GOOD = 'good',
  FAIR = 'fair',
  POOR = 'poor',
}

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  ACTIVATE = 'ACTIVATE',
  INACTIVATE = 'INACTIVATE',
}

export enum NotificationType {
  PERFORMANCE_REVIEW = 'performanceReview',
  LEAVE_APPLICATION = 'leaveApplication',
  RESET_PASSWORD = 'resetPassword',
}

export enum NotificationStatus {
  READ = 'read',
  UNREAD = 'unread',
}

export enum ProjectStatus {
  ALL_TYPE = 'allType',
  PENDING = 'pending',
  PROPOSAL = 'proposal',
  IN_PROGRESS = 'inProgress',
  DONE = 'done',
}

export enum ProjectType {
  ALL_TYPE = 'allType',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export enum LeaveAt {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
}

export enum PerformanceReviewStatus {
  SUBMITTED = 'submitted',
  IN_REVIEW = 'inReview',
  FINAL_DECISION = 'finalDecision',
}

export enum InReviewStatus {
  HR_REVIEW = 'hrReview',
  LEADER_REVIEW = 'leaderReview',
  OFFICE_MANAGER_REVIEW = 'officeManagerReview',
  GENERAL_MANAGER_REVIEW = 'generalManagerReview',
}

export enum FinalDecisionStatus {
  APPROVED = 'approved',
  DENIED = 'denied',
}

export enum LeaveApplicationStatus {
  SUBMITTED = 'submitted',
  FORWARDED = 'forwarded',
  REPLIED = 'replied',
}

export enum MailStatus {
  OPENING = 'opening',
  CLOSED = 'closed',
}

export enum Attendances {
  X = 'X',
  P = 'P',
  KL = 'KL',
  OM = 'OM',
  NV = 'NV',
  CD = 'CD',
  X2 = 'X/2',
  P2 = 'P/2',
  K2 = 'K/2',
  CO = 'CO',
  T = 'T',
}

export enum EventColors {
  TOMATO_RED = '#D50000',
  PINK = '#E67C73',
  ORANGE = '#F4511E',
  BANANA = '#F6BF26',
  LIGHT_GREEN = '#33B679',
  BASIL = '#0B8043',
  BLUE = '#039BE5',
  BLUEBERRY = '#3F51B5',
  LAVENDER = '#7986CB',
  GRAPE = '#8E24AA',
  SMOKE = '#616161',
}

export enum ProjectTypes {
  WEBAPP = 'Webapp',
  WEBSERVICE = 'Webservice',
  OTHER = 'Other',
}
export enum ModuleKeys {
  DASHBOARD = 'dashboard',
  USERS = 'users',
  ATTENDANCES = 'attendances',
  PROJECTS = 'projects',
  PROJECT_REPORTS = 'project reports',
  ROLES = 'roles',
  FILES = 'files',
  WIKI = 'wiki',
  LOGS = 'logs',
  EVENTS = 'events',
  ANNOUNCEMENTS = 'announcements',
  CRAWLING_SYSTEM = 'crawling system',
  FEEDBACKS = 'feedbacks',
  FEEDBACK_GUESTS = 'feedback guests',
  CONFIG_SYSTEM = 'config system',
  FILE_TEMPLATE = 'file template',
  WORK_PROGRESS = 'work progress',
  TOOL = 'tool',
  NEWS = 'news',
  DAILY_TASK = 'daily tasks',
  PORTFOLIO = 'portfolio',
}

export enum Type {
  HIDE = 'hide',
  SHOW = 'show',
  ALL_TYPE = 'allType',
}

export enum AnnouncementtStatus {
  ALL_TYPE = 'allType',
  READ = 'read',
  UNREAD = 'unread',
}

export enum WorkProgressStatus {
  DONE = 'done',
  PROCESSING = 'processing',
}

export enum PermissionKeys {
  FILES = 'files',
  FOLDERS = 'folder',
  PROJECTS = 'projects',
  ROLES = 'roles',
  LOGS = 'logs',
  USERS = 'users',
  DAILY_REPORT = 'daily report',
  WIKI = 'wiki',
  ATTENDANCES = 'attendances',
  EVENTS = 'events',
  PROJECT_MEMBERS = 'project members',
  TAGS = 'tags',
  ANNOUNCEMENTS = 'announcements',
  WORK_PROGRESS = 'work progress',
  FEEDBACKS = 'feedbacks',
  FEEDBACK_GUESTS = 'feedback guests',
  CONFIG_SYSTEMS = 'config systems',
  FILE_TEMPLATES = 'file templates',
  TOOLS = 'tool',
  NEWS = 'news',
  DAILY_TASK = 'daily tasks',
}

export enum NewsStatus {
  ALL_TYPE = 'allType',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PUBLISH = 'publish',
  TERMINATED = 'terminated',
}
