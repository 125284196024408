import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { useForm } from 'react-hook-form';

import { Button, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import Utils from '@/Utils';
import { Alert } from '@/Widgets';
import Constants from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import { RoundedContainer } from '@/Components/Common';
import { ConfigSystemAction } from '@/Actions';

import { ConfigSystemDataTable } from '@/Components/LayoutPart/DataTable';
import { IConfigSystem } from '@/Interfaces/ConfigSystem.interface';
import { IMetaStructure } from '@/Interfaces/Common.interface';

const { ROUTERS, MODULE_API } = Constants;
const { clearConfigSystem, fetchConfigSystems, deleteConfigSystem } =
  ConfigSystemAction;

const initialFilter: any = {
  page: 1,
  limit: 10,
};

const ListConfigSystem: React.FC = () => {
  /* Importing the useTranslation hook from the react-i18next library. */
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const { setValue, getValues } = useForm({
    defaultValues: initialFilter,
  });

  /* Getting the data from the redux store. */
  const meta: IMetaStructure = useSelector((state: RootState) =>
    get(state.CONFIG_SYSTEM, 'meta')
  );
  const payload: IConfigSystem[] = useSelector((state: RootState) =>
    get(state.CONFIG_SYSTEM, 'payload')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.CONFIG_SYSTEM, 'requestIsLoading')
  );

  const isAcceptCreateApi = Utils.isValidPermission(
    MODULE_API.CONFIG_SYSTEMS.CREATE_CONFIG_SYSTEM
  );

  /**
   * It's a function that returns a function that dispatches an action
   */
  const fetchPayload = (filterParams: any) => {
    const resolverFilter = Utils.validateFilters(filterParams);
    dispatch(fetchConfigSystems(resolverFilter));
  };

  React.useEffect(() => {
    const isAcceptApi = Utils.isValidPermission(
      MODULE_API.CONFIG_SYSTEMS.FETCH_CONFIG_SYSTEMS
    );
    if (!isAcceptApi) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizedToFetchConfigSystem'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else fetchPayload(initialFilter);
    return () => {
      dispatch(clearConfigSystem());
    };
  }, []);

  /**
   * It's a function that takes in an item and a type, and if the type is details or edit, it redirects
   * to the details page, and if the type is delete, it asks for confirmation and then deletes the item
   * @param {IConfigSystem} item - IConfigSystem - the item that is currently being processed
   * @param {'details' | 'edit' | 'delete'} type - 'details' | 'edit' | 'delete'
   */
  const onRowAction = async (
    item: IConfigSystem,
    type: 'details' | 'edit' | 'delete'
  ) => {
    if (type === 'details' || type === 'edit')
      Utils.redirect({ pathname: ROUTERS.CONFIG_SYSTEM_DETAILS, id: item.id });

    if (type === 'delete') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningBeforeDeleteConfig'),
      });
      if (isAgree === 'ok') {
        const currentFilter = getValues();
        const resolveFilter = Utils.validateFilters(currentFilter);
        dispatch(deleteConfigSystem(item.id, resolveFilter));
      }
    }
  };

  const onPageAction = async (value: number, type: 'limit' | 'page') => {
    const currentFilter = getValues();
    const newFilter = {
      ...currentFilter,
      [type]: value,
    };

    setValue(type, value);
    if (type === 'limit') {
      setValue('page', 1);
      Object.assign(newFilter, { page: 1 });
    }

    fetchPayload(newFilter);
  };

  /* The function to render the layout */
  const _renderTopSection = () => (
    <Stack direction="row" sx={{ justifyContent: 'space-between', mb: 2 }}>
      <Typography variant="h2">{t('title.configSystem')}</Typography>
      {isAcceptCreateApi && (
        <Button
          variant="contained"
          onClick={() => Utils.redirect(ROUTERS.CONFIG_SYSTEM_CREATE)}
        >
          {t('button.create')}
        </Button>
      )}
    </Stack>
  );

  const _renderBottomSection = () => (
    <RoundedContainer>
      <ConfigSystemDataTable
        payload={payload}
        meta={meta}
        onPageAction={onPageAction}
        onRowAction={onRowAction}
        isLoading={isLoading}
      />
    </RoundedContainer>
  );

  return (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default ListConfigSystem;
