import React, { ForwardRefRenderFunction } from 'react';
// import { forEach } from 'lodash';

import {
  Box,
  FormHelperText,
  Typography,
  StandardTextFieldProps,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import isEmpty from 'lodash/isEmpty';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Stack } from '@mui/material';

// import Utils from '@/Utils';
import { useTranslation } from 'react-multi-lang';
// import { SelectInputProps } from '@mui/material/Select/SelectInput';

interface IOptionsStructure {
  startEl?: JSX.Element;
  label: string;
  value: string | number;
}

interface IProps extends StandardTextFieldProps {
  value?: string;
  label?: string;
  onChange?(e: any): any;
  options: IOptionsStructure[];
  required?: boolean;
  sx?: object;
  allowCopy?: boolean;
  message?: string;
  disabled?: boolean;
  isTranslate?: boolean;
}

const Dropdown: ForwardRefRenderFunction<HTMLSelectElement, IProps> = ({
  value,
  label,
  onChange,
  options,
  required = false,
  message = '',
  disabled = false,
  isTranslate = false,
  sx,
}) => {
  const t = useTranslation();

  const onChangeOption = (e: SelectChangeEvent) => {
    if (onChange) onChange(e);
  };

  const _renderOptions = () => {
    const render = options.map(
      (
        option: {
          startEl?: JSX.Element;
          label: string;
          value: string | number;
        },
        index: number
      ) => {
        const { startEl } = option;
        let resolveStartEl = null;
        if (startEl)
          resolveStartEl = React.cloneElement(startEl, {
            style: {
              width: '26px',
              height: '26px',
              marginRight: '8px',
            },
          });
        return (
          <MenuItem
            key={`${option.label}-${index}`}
            value={option.value}
            sx={{ display: 'flex' }}
          >
            {resolveStartEl}
            {isTranslate ? t(`menu.${option.label}`) : option.label}
          </MenuItem>
        );
      }
    );
    return render;
  };

  return (
    <Stack direction="column" sx={{ flex: 1, ...sx }}>
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}{' '}
          {required ? (
            <Typography component="span" color="error">
              (*)
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      <FormControl
        size="small"
        error={required && !isEmpty(message)}
        disabled={disabled}
      >
        <Select
          sx={{
            minWidth: '80px',
            '	.MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          placeholder="Placeholder"
          value={value}
          defaultValue="Initial value"
          onChange={onChangeOption}
          displayEmpty
          renderValue={
            value !== ''
              ? undefined
              : () => <Box sx={{ opacity: 0.5 }}>{t('label.choose')}</Box>
          }
        >
          {isEmpty(options) ? (
            <MenuItem disabled>
              <em>{t('label.noOptions')}</em>
            </MenuItem>
          ) : (
            _renderOptions()
          )}
        </Select>
      </FormControl>
      {message ? (
        <FormHelperText error={Boolean(message)}>{message}</FormHelperText>
      ) : null}
    </Stack>
  );
};

export default Dropdown;
