import { ForwardRefRenderFunction } from 'react';
// import { forEach } from 'lodash';

import {
  Box,
  FormHelperText,
  Typography,
  StandardTextFieldProps,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import isEmpty from 'lodash/isEmpty';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Stack } from '@mui/material';

// import Utils from '@/Utils';
import { useTranslation } from 'react-multi-lang';
// import { SelectInputProps } from '@mui/material/Select/SelectInput';

interface IOptionsStructure {
  label: string;
  value: string | number;
}

interface IProps extends StandardTextFieldProps {
  value?: string;
  label?: string;
  onChange?(e: any): any;
  options: IOptionsStructure[];
  required?: boolean;
  sx?: object;
  allowCopy?: boolean;
  message?: string;
  disabled?: boolean;
  isTranslate?: boolean;
}

const Dropdown: ForwardRefRenderFunction<HTMLSelectElement, IProps> = ({
  value,
  label,
  onChange,
  options,
  required = false,
  message = '',
  disabled = false,
  isTranslate = false,
  sx,
}) => {
  const t = useTranslation();

  const onChangeOption = (e: SelectChangeEvent) => {
    if (onChange) onChange(e);
  };

  const _renderOptions = () => {
    const render = options.map(
      (
        option: {
          label: string;
          value: string | number;
        },
        index: number
      ) => {
        return (
          <MenuItem
            key={`${option.label}-${index}`}
            value={option.value}
            sx={{
              fontSize: '0.8rem',
              textAlign: 'center',
            }}
          >
            {isTranslate ? t(`menu.${option.label}`) : option.label}
          </MenuItem>
        );
      }
    );
    return render;
  };

  return (
    <Stack
      direction="column"
      sx={{
        flex: 1,
        '& .MuiOutlinedInput-root': {
          width: '50px',
          textAlign: 'center',
        },

        ...sx,
      }}
    >
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}{' '}
          {required ? (
            <Typography component="span" color="error">
              (*)
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      <FormControl
        size="small"
        error={required && !isEmpty(message)}
        disabled={disabled}
      >
        <Select
          sx={{
            minWidth: '50px',
            height: '20px',
            '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiSelect-select': {
              textOverflow: 'unset !important',
              fontSize: '0.8rem',
            },
            boxShadow: 'none',
            '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
            '& .MuiSvgIcon-root': { right: 0, fontSize: 'large' },
            '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
              { paddingRight: 0 },
          }}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          placeholder="Placeholder"
          value={value}
          defaultValue="Initial value"
          onChange={onChangeOption}
          displayEmpty
          renderValue={
            value !== '' ? undefined : () => <Box sx={{ opacity: 0.5 }}></Box>
          }
        >
          {isEmpty(options) ? (
            <MenuItem disabled>
              <em>{t('label.noOptions')}</em>
            </MenuItem>
          ) : (
            _renderOptions()
          )}
        </Select>
      </FormControl>
      {message ? (
        <FormHelperText error={Boolean(message)}>{message}</FormHelperText>
      ) : null}
    </Stack>
  );
};

export default Dropdown;
