import * as React from 'react';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-multi-lang';

interface ISectionProps {
  label:
    | 'post'
    | 'put'
    | 'delete'
    | 'submitted'
    | 'replied'
    | 'active'
    | 'inactive'
    | 'pending'
    | 'proposal'
    | 'inProgress'
    | 'done'
    | 'inReview'
    | 'finalDecision'
    | 'approved'
    | 'denied'
    | 'intern'
    | 'apprenticeship'
    | 'probationary'
    | 'officialStaff'
    | 'read'
    | 'unread'
    | 'activate'
    | 'inactivate'
    | 'active'
    | 'unRead'
    | 'processing'
    | 'publish';
  disabled?: boolean;
}

type colorTypes =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

const ChipStatus: React.FC<ISectionProps> = ({ label }) => {
  const t = useTranslation();

  const generateLabel = React.useMemo(() => {
    const result = t(`table.${label}`);
    return result;
  }, [label]);

  const generateColor = React.useMemo(() => {
    let resolveColor: colorTypes = 'default';
    switch (label) {
      case 'submitted':
      case 'pending':
      case 'put':
      case 'apprenticeship':
      case 'inProgress':
      case 'publish':
        resolveColor = 'info';
        break;
      case 'approved':
      case 'active':
      case 'done':
      case 'replied':
      case 'read':
        resolveColor = 'success';
        break;
      case 'post':
      case 'intern':
      case 'processing':
        resolveColor = 'warning';
        break;
      case 'unRead':
        resolveColor = 'error';
        break;
      default:
        break;
    }
    return resolveColor;
  }, [label]);

  return (
    <Chip
      variant="outlined"
      color={generateColor}
      label={generateLabel}
      sx={{
        userSelect: 'none',
        borderRadius: '5px',
        fontWeight: '600',
        minWidth: '120px',
      }}
    />
  );
};

export default ChipStatus;
