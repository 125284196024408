import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const initialState = {
  contentIsLoaded: false,
  expandSidebar: false,
  userContentIsLoaded: false,
};

export default (
  state = initialState,
  action: { type: string; payload: string | boolean }
) => {
  const { type } = action;
  switch (type) {
    case ACTION_TYPES.CONTENT_IS_LOADED:
      return {
        ...state,
        contentIsLoaded: true,
      };
    case ACTION_TYPES.CONTENT_USER_IS_LOADED:
      return {
        ...state,
        userContentIsLoaded: true,
      };

    case ACTION_TYPES.EXPAND_SIDEBAR:
      return {
        ...state,
        expandSidebar: action.payload,
      };

    default:
      return state;
  }
};
