import { useSelector } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { INewsStructure } from '@/Interfaces/News.interface';
import { RootState, useTypedDispatch } from '@/store';
import { NewsActions } from '@/Actions';
import NewsLoaddingDetail from '@/Components/LayoutPart/NewsLoading/NewsLoadingDetail';
import Constants from '@/Constants';
import Utils from '@/Utils';
const { ROUTERS } = Constants;
const { getPostById, setDefaultNewsReducer } = NewsActions;

const NewsDetail = () => {
  const { id } = useParams();
  const dispatch = useTypedDispatch();
  const [dataNews, setDataNews] = useState<INewsStructure>({
    content: null,
    id: '',
    image: {},
    location: '',
    status: '',
    title: '',
    createdAt: '',
    deletedAt: '',
    publishAt: '',
    updatedAt: '',
  });

  const isLoading: boolean = useSelector((state: RootState) =>
    _.get(state.NEWS, 'requestIsLoading')
  );

  const details: INewsStructure = useSelector((state: RootState) =>
    _.get(state.NEWS, 'details')
  );

  useEffect(() => {
    if (id) dispatch(getPostById(id));
    return () => {
      dispatch(setDefaultNewsReducer());
    };
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(details)) setDataNews(details);
  }, [details]);

  const _renderContent = () => {
    return (
      <Box>
        <Typography
          component="h1"
          sx={{
            fontWeight: 700,
            lineHeight: '45px',
            fontSize: 36,
            textAlign: 'center',
            mb: 1,
          }}
        >
          {details?.title}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
            <AccessAlarmIcon sx={{ mr: 1, fontSize: 20, color: '#EE7700' }} />
            <Typography
              sx={{
                fontWeight: 400,
                color: '#b3b3b3',
                fontSize: 18,
                lineHeight: '16px',
              }}
            >
              {dayjs(dataNews?.publishAt)
                ? dayjs(dataNews?.publishAt).format('MMMM D, YYYY')
                : 'N/I'}
            </Typography>
          </Box>
          <Box sx={{ mx: 1 }}>-</Box>
          <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 20 }}>
            <Typography
              sx={{
                fontWeight: 700,
                color: '#b3b3b3',
                fontSize: 18,
                lineHeight: '16px',

                textTransform: 'capitalize',
                fontStyle: 'italic',
              }}
            >
              {dataNews?.location}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box sx={{ width: 1, textAlign: 'center', my: 3 }}>
            <Box
              component="img"
              src={details?.image?.path}
              sx={{ width: 0.8 }}
            />
          </Box>
          <Box>
            <Box
              sx={{
                color: '#3f3f3f',
                fontSize: 18,
                fontWeight: 400,
                lineHeight: '28px',
                ml: 2,
              }}
              component="div"
              dangerouslySetInnerHTML={Utils.createMarkup(
                JSON.parse(dataNews?.content)
              )}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Box sx={{ px: { xs: 2, sm: 4, lg: 2 } }}>
        <Box>
          {isLoading ? (
            <Box>
              <NewsLoaddingDetail />
            </Box>
          ) : (
            <Box>
              <Box>{_renderContent()}</Box>
              <Button
                variant="outlined"
                onClick={() => {
                  Utils.redirect(ROUTERS.NEWS);
                  window.scrollTo({
                    top: 400,
                    behavior: 'smooth',
                  });
                }}
                startIcon={<KeyboardArrowLeftIcon />}
              >
                Back
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return renderMain();
};

export default NewsDetail;
