import { Skeleton, Stack } from '@mui/material';
import { RoundedContainer } from '../Containers';

const UserDetailsSkeleton = () => {
  const _renderRowSkeleton = () => (
    <Stack direction="row" mt={1}>
      <Stack flex={1} mr={2}>
        <Skeleton width={120} height={30} />
        <Skeleton height={45} />
      </Stack>
      <Stack flex={1}>
        <Skeleton width={120} height={30} />
        <Skeleton height={45} />
      </Stack>
    </Stack>
  );

  return (
    <Stack direction="column">
      <Skeleton width={200} height={40} />
      <Stack direction="row" justifyContent="center">
        <Skeleton variant="circular" width={250} height={250} />
      </Stack>
      <RoundedContainer mt={3}>
        <Skeleton width={200} height={40} />
        {_renderRowSkeleton()}
        {_renderRowSkeleton()}
        {_renderRowSkeleton()}
      </RoundedContainer>
      <RoundedContainer mt={3}>
        <Skeleton width={200} height={40} />
        {_renderRowSkeleton()}
      </RoundedContainer>
      <RoundedContainer mt={3}>
        <Skeleton width={200} height={40} />
        {_renderRowSkeleton()}
        {_renderRowSkeleton()}
      </RoundedContainer>
    </Stack>
  );
};

export default UserDetailsSkeleton;
