import React from 'react';
import { Stack } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateSelectArg, EventClickArg } from '@fullcalendar/core';
import enLocale from '@fullcalendar/core/locales/en-nz';
import viLocale from '@fullcalendar/core/locales/vi';
import Utils from '@/Utils';
import { mainStyles } from './FullCalendar.style';
import dayjs from 'dayjs';

interface ISelect {
  startDate: string;
  endDate: string;
}

interface IPayload {
  title: string;
  start: string;
  end: string;
}

interface IProps {
  sx?: object;
  payload: IPayload[];
  onSelect(payload: ISelect): void;
  onClick(id: string): void;
  selectable?: boolean;
}

const Calendar: React.FC<IProps> = ({
  sx,
  onSelect,
  onClick,
  payload = [],
  selectable = false,
}) => {
  const locale = Utils.getSavedLocale();
  return (
    <Stack
      sx={{
        flex: 1,
        ' .fc-event': {
          cursor: 'pointer',
        },
        ' .fc-event-title': {
          whiteSpace: 'pre-wrap',
        },
        ...sx,
        ...mainStyles,
      }}
    >
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={payload}
        displayEventTime={false}
        selectable={selectable}
        select={(arg: DateSelectArg) => {
          const subtractEndDate = dayjs(arg.endStr)
            .subtract(1, 'day')
            .format('YYYY-MM-DD');
          onSelect({ startDate: arg.startStr, endDate: subtractEndDate });
        }}
        height="auto"
        eventClick={(event: EventClickArg) => onClick(event.event.id)}
        handleWindowResize
        locale={locale === 'en' ? enLocale : viLocale}
        // timeZone="Asia/Ho_Chi_Minh"
      />
    </Stack>
  );
};

export default Calendar;
