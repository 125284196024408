import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';
import { Typography } from '@mui/material';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref
) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '5px',
  p: 3,
  minWidth: '350px',
};

interface IProps {
  open: boolean;
  onClose(): void;
  title: string;
  content?: JSX.Element | null;
  children?: JSX.Element | null;
}

const PopupLayout: React.FC<IProps> = ({
  open,
  onClose,
  title,
  content,
  children,
}) => {
  const handleClose = (
    _event: any,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'escapeKeyDown') onClose();
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      // slots={{ backdrop: Backdrop }} // Deprecated in new version
      components={{ Backdrop }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography mb="15px" fontSize="20px" fontWeight="500">
            {title}
          </Typography>
          {content || children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default PopupLayout;
