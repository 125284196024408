import get from 'lodash/get';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useEffect } from 'react';
import { AnnouncementUserAction } from '@/Actions';
import { IAnnouncementFilterUser } from '@/Interfaces/Project.interface';
import { RootState, useTypedDispatch } from '@/store';
import { CommonColors } from '@/Themes';
import Utils from '@/Utils';
import Constants from '@/Constants';
import { RoundedContainer } from '@/Components/Common';

const { ROUTERS, MODULE_API } = Constants;
const { getForUserAnnouncements, updateReadAnnouncements, clearAnnouncement } =
  AnnouncementUserAction;

const initialFilter: IAnnouncementFilterUser = {
  page: 1,
  limit: 10,
};

const Announcement = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const announcementUser: any = useSelector((state: RootState) =>
    get(state.ANNOUNCEMENT_USER, 'forUser')
  );
  const isAcceptFetchAnnouncement = Utils.isValidPermission(
    MODULE_API.ANNOUNCEMENT.GET_FOR_USER
  );

  useEffect(() => {
    if (isAcceptFetchAnnouncement) dispatch(getForUserAnnouncements());
    else dispatch(clearAnnouncement());
  }, []);

  const handeleOnchangeStatus = (id: string, status: string) => {
    if (status === 'unRead')
      dispatch(updateReadAnnouncements(id, 'read', initialFilter));
  };

  const _renderAnnouncementBody = () => {
    if (announcementUser?.announcements?.length === 0)
      return <Typography>{t('tooltip.noAnnouncementWereFound')}</Typography>;
    return (
      <Stack>
        {_.map(announcementUser?.announcements, (item: any, index: number) => {
          return (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  ':hover': {
                    backgroundColor: '#efefef',
                  },
                  height: 15,
                }}
                onClick={() => handeleOnchangeStatus(item?.id, item?.status)}
              >
                <Stack direction="row" alignItems="center">
                  {item?.status === 'unRead' && (
                    <Box
                      sx={{
                        width: '8px',
                        height: '8px',
                        background: '#039BE5',
                        borderRadius: '50%',
                        marginRight: '8px',
                      }}
                    />
                  )}
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      textTransform: 'capitalize',
                    }}
                  >
                    {item.announcement.title}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ mx: 2 }}>
                <Typography sx={{ fontSize: 16 }}>
                  {item.announcement.content}
                </Typography>
                <Box>
                  <Typography fontSize={14} color="#A8A8A8">
                    From: {item.announcement.createdBy.userData.fullName}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Stack>
    );
  };

  const _renderMain = () => {
    return (
      <Stack>
        <RoundedContainer mt={2}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <Typography variant="caption">
                {t('title.announcement')}
              </Typography>
              {announcementUser && (
                <NotificationsActiveOutlinedIcon
                  sx={{
                    color: CommonColors.pickledBluewood,
                    ml: 2,
                    fontSize: 25,
                  }}
                />
              )}
            </Box>
            <Box sx={{ mb: 1 }}>
              <Badge
                badgeContent={announcementUser?.remain}
                sx={{
                  '& .MuiBadge-badge': {
                    minWidth: 6,
                    height: 17,
                  },
                }}
                color="primary"
              >
                <Button
                  onClick={() => Utils.redirect(ROUTERS.ANNOUNCEMENT_USER)}
                  sx={{ mr: 1 }}
                  variant="outlined"
                >
                  {t('button.readMore')}
                </Button>
              </Badge>
            </Box>
          </Box>
          {_renderAnnouncementBody()}
        </RoundedContainer>
      </Stack>
    );
  };
  return <Stack>{_renderMain()}</Stack>;
};

export default Announcement;
