import { styled } from '@mui/system';

export const LoadingWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 20;
  background-color: rgba(16, 16, 16, 0.7);
  width: 100vw;
  height: 100%;
`;
