import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import {
  Box,
  Grid,
  Paper,
  Typography,
  Container,
  Divider,
  Skeleton,
  CircularProgress,
} from '@mui/material';
import { validate as uuidValidate } from 'uuid';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import ProgressiveImage from 'react-progressive-graceful-image';
import { Helmet } from 'react-helmet';

import Slide from 'react-reveal/Slide';
import { motion } from 'framer-motion';

import {
  mainWrapper,
  bannerWrapper,
  projectWrapper,
  projectSliderWrapper,
  projectLabel,
  categoryCard,
} from './Portfolio.styles';
import { RootState, useTypedDispatch } from '@/store';
import { PorfolioActions } from '@/Actions';
import CONSTANTS from '@/Constants';

const { getUserPorfolioById } = PorfolioActions;

const { TECH_LIST, ROUTERS } = CONSTANTS;
const Portfolio: React.FC = () => {
  const params = useParams();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const detailsStore = useSelector((state: RootState) =>
    _.get(state.PORTFOLIO, 'details')
  );
  const isLoading = useSelector((state: RootState) =>
    _.get(state.PORTFOLIO, 'requestIsLoading')
  );
  const [details, setDetails] = useState<any>({});

  useEffect(() => {
    if (params?.id && uuidValidate(params?.id)) {
      dispatch(getUserPorfolioById(params?.id));
    } else navigate(ROUTERS.PORTFOLIO);
  }, [params]);

  useEffect(() => {
    if (detailsStore?.id === params?.id) setDetails(detailsStore);
  }, [detailsStore]);

  const _renderBanner = () => (
    <Box sx={bannerWrapper}>
      <Container component="main" maxWidth="lg" sx={{ height: 300 }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={12} className="banner-content">
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '3rem',
                  backgroundImage:
                    'linear-gradient(90deg, rgba(6,0,36,1) 0%, rgba(245,251,243,1) 35%, rgba(255,109,0,1) 100%)',
                  backgroundSize: '200% auto',
                  color: ' #fff',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                  animation: 'textclip 2s linear infinite',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '@keyframes textclip': {
                    to: {
                      backgroundPosition: '200% center',
                    },
                  },
                  width: 1,
                }}
              >
                {details?.name}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const _renderTech = () => (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        flexWrap: 'wrap',
      }}
    >
      {_.map(details?.technology || [], (tech: string) => {
        const findTech = _.find(TECH_LIST, ['label', tech]);
        return (
          <LazyLoadImage
            key={`${details?.id}${tech}`}
            src={findTech?.icon}
            alt="appIcon"
            loading="lazy"
            style={{ width: 30, height: 30 }}
          />
        );
      })}
    </Box>
  );

  const _renderSlider = () => (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      modules={[Pagination]}
    >
      {_.map(details?.image || [], (img: any) => (
        <SwiperSlide key={img?.id}>
          <ProgressiveImage
            src={_.get(img, 'file.path')}
            placeholder={_.get(img, 'file.thumbnail')}
          >
            {(src: string, loading: boolean | undefined): any => (
              <img
                className={`image${
                  loading ? ' loading-image' : ' loaded-image'
                }`}
                src={src}
                alt="sea beach"
                style={{ width: '100%', objectFit: 'contain' }}
              />
            )}
          </ProgressiveImage>
        </SwiperSlide>
      ))}
    </Swiper>
  );

  const _renderProjectDetail = () => (
    <Grid container sx={{ p: 2, rowGap: 2 }}>
      <Grid item xs={12}>
        <Typography
          sx={{ ...projectLabel, fontSize: '1.5rem' }}
          component="div"
        >
          Project Infomation
          <Divider variant="middle" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        ) : (
          <Typography sx={projectLabel}>
            Category:{' '}
            <Typography component="span">{details?.category}</Typography>
          </Typography>
        )}
        {isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        ) : (
          <Typography sx={projectLabel}>
            Client: <Typography component="span">{details?.client}</Typography>
          </Typography>
        )}
        {isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        ) : (
          <Typography sx={projectLabel}>
            Date: <Typography component="span">{details?.date}</Typography>
          </Typography>
        )}
        {isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        ) : (
          <Typography sx={projectLabel}>Technologies: </Typography>
        )}
        {isLoading ? (
          <Skeleton variant="rectangular" width={210} height={60} />
        ) : (
          _renderTech()
        )}
      </Grid>
      {details?.url && (
        <Grid item xs={12}>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.8 }}
            style={{ width: '100%' }}
          >
            <LoadingButton
              variant="contained"
              fullWidth
              loading={isLoading}
              endIcon={<OpenInNewIcon />}
              sx={{ fontWeight: 700 }}
              onClick={() => window.open(details?.url, '_blank')}
            >
              URL
            </LoadingButton>
          </motion.div>
        </Grid>
      )}
    </Grid>
  );

  const _renderProject = () => (
    <Container
      component="main"
      sx={{ p: 3, width: 1, display: 'flex', justifyContent: 'center' }}
      maxWidth="lg"
    >
      <Grid
        container
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          rowGap: 2,
        }}
      >
        <Grid item xs={12} md={6} sx={projectSliderWrapper}>
          {_renderSlider()}
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper elevation={0} sx={{ ...projectWrapper, p: 2 }}>
            {_renderProjectDetail()}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <motion.div whileInView="onscreen" viewport={{ once: true }}>
            <Grid container sx={{ p: 2 }} spacing={2}>
              {_.map(details?.tag || [], (item: string) => (
                <Grid item xs={3} key={item}>
                  <Paper sx={categoryCard} elevation={2}>
                    <Typography className="pr-title">{item}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </motion.div>
        </Grid>
        <Grid item xs={12}>
          {isLoading && (
            <Skeleton variant="rectangular" width={1} height={60} />
          )}
          {details?.content?.general && (
            <motion.div whileInView="onscreen" viewport={{ once: true }}>
              <Paper elevation={0} sx={{ ...projectWrapper, p: 2 }}>
                <Grid container sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ ...projectLabel, fontSize: '1.5rem' }}
                      component="div"
                    >
                      General
                      <Divider variant="middle" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Slide bottom>
                      <Box
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: details?.content?.general,
                        }}
                      />
                    </Slide>
                  </Grid>
                </Grid>
              </Paper>
            </motion.div>
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoading && (
            <Skeleton variant="rectangular" width={1} height={60} />
          )}
          {details?.content?.assignment && (
            <motion.div whileInView="onscreen" viewport={{ once: true }}>
              <Paper elevation={0} sx={{ ...projectWrapper, p: 2 }}>
                <Grid container sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ ...projectLabel, fontSize: '1.5rem' }}
                      component="div"
                    >
                      Assignment
                      <Divider variant="middle" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Slide bottom>
                      <Box
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: details?.content?.assignment,
                        }}
                      />
                    </Slide>
                  </Grid>
                </Grid>
              </Paper>
            </motion.div>
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoading && (
            <Skeleton variant="rectangular" width={1} height={60} />
          )}
          {details?.content?.solution && (
            <motion.div whileInView="onscreen" viewport={{ once: true }}>
              <Paper elevation={0} sx={{ ...projectWrapper, p: 2 }}>
                <Grid container sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ ...projectLabel, fontSize: '1.5rem' }}
                      component="div"
                    >
                      Solution
                      <Divider variant="middle" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Slide bottom>
                      <Box
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: details?.content?.solution,
                        }}
                      />
                    </Slide>
                  </Grid>
                </Grid>
              </Paper>
            </motion.div>
          )}
        </Grid>
      </Grid>
    </Container>
  );

  const renderMain = () => {
    return (
      <Box sx={mainWrapper}>
        <Helmet>
          <title>{details?.name || 'FLOW IDRA'}</title>
        </Helmet>
        {_renderBanner()}
        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          {_renderProject()}
        </motion.div>
      </Box>
    );
  };
  return renderMain();
};
export default Portfolio;
