import { Grid, Skeleton, Stack } from '@mui/material';

const ToolListSkeleton = () => {
  const _renderItem = () => (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} sx={{ height: 'auto' }}>
      <Stack
        direction="column"
        sx={{
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
          borderRadius: '5px',
          overflow: 'hidden',
        }}
      >
        <Skeleton variant="rectangular" height={150} />
        <Stack direction="column" sx={{ padding: '10px' }}>
          <Skeleton variant="text" height={50} />
          <Skeleton variant="text" height={25} />
          <Skeleton variant="text" height={25} />
          <Skeleton variant="text" width={100} height={25} />
        </Stack>
      </Stack>
    </Grid>
  );

  return (
    <Stack direction="row" sx={{ p: 1 }}>
      <Stack direction="column" sx={{ flex: 1 }}>
        <Grid container spacing={3}>
          {_renderItem()}
          {_renderItem()}
          {_renderItem()}
          {_renderItem()}
          {_renderItem()}
          {_renderItem()}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default ToolListSkeleton;
