import CONSTANTS from '@/Constants';
import { IMetaStructure } from '../Interfaces/Common.interface';

const { ACTION_TYPES } = CONSTANTS;

const initialMeta: IMetaStructure = {
  currentPage: 1,
  itemCount: 1,
  itemsPerPage: 10,
  totalItems: 1,
  totalPages: 1,
};

const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  submitIsSuccess: false,
  details: {},
  payload: [],
  lastReports: [],
  meta: initialMeta,
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.RESET_DAILY_REPORT_STATUS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        submitIsSuccess: false,
      };
    case ACTION_TYPES.DAILY_REPORT_IS_LOADING:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.CLEAR_REPORT_DETAILS:
      return {
        ...state,
        details: {},
      };
    case ACTION_TYPES.SET_DEFAULT_REDUCER_DAILY_REPORT:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        submitIsSuccess: false,
        payload: [],
        details: {},
        meta: initialMeta,
      };
    case ACTION_TYPES.SET_META_DAILY_REPORT:
      return {
        ...state,
        meta: payload,
      };

    case ACTION_TYPES.FETCH_DAILY_REPORTS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload,
      };
    case ACTION_TYPES.FETCH_DAILY_REPORTS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };

    case ACTION_TYPES.FETCH_PROJECT_REPORTS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        lastReports: payload,
      };
    case ACTION_TYPES.FETCH_PROJECT_REPORTS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        lastReports: [],
      };

    case ACTION_TYPES.GET_DAILY_REPORT_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.GET_DAILY_REPORT_BY_ID_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.CREATE_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        submitIsSuccess: true,
      };
    case ACTION_TYPES.CREATE_DAILY_REPORT_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        submitIsSuccess: false,
      };

    case ACTION_TYPES.UPDATE_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        submitIsSuccess: true,
      };
    case ACTION_TYPES.UPDATE_DAILY_REPORT_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        submitIsSuccess: false,
      };

    case ACTION_TYPES.DELETE_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.DELETE_DAILY_REPORT_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.GET_REPORT_DETAILS_BY_DATE_AND_PROJECT_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.GET_REPORT_DETAILS_BY_DATE_AND_PROJECT_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        details: {},
      };

    default:
      return state;
  }
};
