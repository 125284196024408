import React from 'react';
import has from 'lodash/has';
import get from 'lodash/get';
import { validate as uuidValidate } from 'uuid';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { Stack, Box, Button } from '@mui/material';

import CONSTANTS from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import { PermissionActions } from '@/Actions';
import { Alert } from '@/Widgets';
import Utils from '@/Utils';
import { RoundedContainer } from '@/Components/Common';
import { IRoleDetailsStructure } from '@/Interfaces/Role.interface';

import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import { UserRoleDataTable } from '@/Components/LayoutPart/DataTable';
import { RoleDetailsSkeleton } from '@/Components/Common/Skeleton';
import { IUserDetailsStructure } from '../../../../Interfaces/User.interface';
import { AddUserToRolePopup } from '../../../../Components/Popup';

const { ROUTERS } = CONSTANTS;
const {
  getRoleById,
  fetchPermissions,
  setDefaultReducerPermission,
  removeUserRole,
} = PermissionActions;

const User: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const { id } = useParams();

  const roleDetails: IRoleDetailsStructure = useSelector((state: RootState) =>
    get(state.ROLE, 'details')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.ROLE, 'requestIsLoading')
  );

  const [isShowAddUserPopup, setIsShowAddUserPopup] =
    React.useState<boolean>(false);
  const [contentIsLoaded, setContentIsLoaded] = React.useState<boolean>(false);
  const [users, setUsers] = React.useState<IUserDetailsStructure[]>([]);

  React.useEffect(() => {
    const isAcceptApi = Utils.isValidPermission(
      ModuleApiConstant.ROLE_PERMISSION.UPDATE_ROLE
    );
    if (!isAcceptApi) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForEditRole'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else if (id && uuidValidate(id)) {
      dispatch(fetchPermissions());
      dispatch(getRoleById(id));
    }
    return () => {
      dispatch(setDefaultReducerPermission());
    };
  }, []);

  React.useEffect(() => {
    if (has(roleDetails, 'id')) {
      const { userRole } = roleDetails;
      if (userRole && userRole.length > 0) setUsers(userRole);
    }
  }, [roleDetails]);

  const onRowAction = async (user: IUserDetailsStructure, action: 'remove') => {
    if (action === 'remove') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningRemoveUserFromRole'),
      });
      if (isAgree === 'ok') dispatch(removeUserRole(user.id));
    }
  };

  // Render layout
  const _renderPopup = () => {
    return (
      <AddUserToRolePopup
        onClose={() => setIsShowAddUserPopup(false)}
        open={isShowAddUserPopup}
      />
    );
  };

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        <Stack direction="row" justifyContent="end" mb={2}>
          <Button
            variant="contained"
            onClick={() => setIsShowAddUserPopup(true)}
          >
            {t('button.add')}
          </Button>
        </Stack>
        <UserRoleDataTable payload={users} onRowAction={onRowAction} />
      </RoundedContainer>
    );
  };

  const _renderContent = () => {
    return (
      <Box onLoad={() => setContentIsLoaded(true)}>
        {_renderBottomSection()}
      </Box>
    );
  };

  const _renderSkeleton = () => <RoleDetailsSkeleton />;

  const renderMain = () => {
    return (
      <Stack>
        {_renderPopup()}
        {isLoading && !contentIsLoaded ? _renderSkeleton() : _renderContent()}
      </Stack>
    );
  };

  return renderMain();
};

export default User;
