/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Typography, Box } from '@mui/material';

import Constants from '@/Constants';
import { Alert } from '@/Widgets';
import Utils from '@/Utils';
import { DailyTaskActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';

import MyReport from './MyReport';
import DailyTaskList from './DailyTaskList';

const { MODULE_API, ROUTERS } = Constants;
const { resetDailyTaskReducer } = DailyTaskActions;

type TScreen = 'list' | 'me';

const DailyReport: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();
  const screen = searchParams.get('screen');
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.USER, 'payload')
  );

  const isAcceptApi =
    Utils.isValidPermission(MODULE_API.DAILY_TASK.FETCH) ||
    Utils.isValidPermission(MODULE_API.DAILY_TASK.MY);

  const [currentScreen, setCurrentScreen] = useState<TScreen>('me');

  useEffect(() => {
    if (!isAcceptApi) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForFetchDailyReport'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else if (!Utils.isValidPermission(MODULE_API.DAILY_TASK.FETCH)) {
      setCurrentScreen('me');
    } else setCurrentScreen(screen ? (screen as TScreen) : 'list');
    return () => {
      dispatch(resetDailyTaskReducer());
    };
  }, []);

  return isAcceptApi ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {isFetchLoading && (
        <Typography variant="h2">
          {currentScreen === 'list'
            ? t('title.dailyTask')
            : t('title.myReport')}
        </Typography>
      )}

      {currentScreen === 'list' && (
        <DailyTaskList
          onScreenChange={(newValue: TScreen) => setCurrentScreen(newValue)}
        />
      )}
      {currentScreen === 'me' && (
        <MyReport
          onScreenChange={(newValue: TScreen) => setCurrentScreen(newValue)}
        />
      )}
    </Box>
  ) : null;
};

export default DailyReport;
