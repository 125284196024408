import React from 'react';
import get from 'lodash/get';
import filter from 'lodash/filter';
import { useSelector } from 'react-redux';
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { useTranslation } from 'react-multi-lang';

import { Alert } from '@/Widgets';
import { RootState, useTypedDispatch } from '@/store';
import { FoldersAction } from '@/Actions';
import Utils from '@/Utils';

import { IFolderStructure } from '@/Interfaces/Folders.interface';
import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import FolderDetails from '@/Components/Popup/FolderDetails';
import { RootFolderSkeleton } from '../../../Components/Common/Skeleton';
import Constants from '../../../Constants';
interface IProps {
  payload: IFolderStructure[];
  active: string;
  onActive(id: string): void;
}

const { deleteFolder } = FoldersAction;
const { MODULE_API } = Constants;

const FolderList: React.FC<IProps> = ({ payload, active = '', onActive }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.FOLDERS, 'requestIsLoading')
  );

  const isAcceptEdit = Utils.isValidPermission(MODULE_API.FOLDER.UPDATE_FOLDER);
  const isAcceptRemove = Utils.isValidPermission(
    MODULE_API.FOLDER.DELETE_FOLDER
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);
  const [folderEditing, setFolderEditing] =
    React.useState<IFolderStructure | null>(null);
  const [isShowFolderPopup, setIsShowFolderPopup] =
    React.useState<boolean>(false);
  const [keyword, setKeyword] = React.useState<string>('');

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFolderEditing(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onAction = async (action: 'edit' | 'delete') => {
    if (action === 'edit') setIsShowFolderPopup(true);
    if (action === 'delete') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningDeleteFolder'),
      });
      if (isAgree === 'ok' && folderEditing)
        dispatch(deleteFolder(folderEditing.id));

      handleClose();
    }
  };

  const _renderFolder = () => {
    const isAllowCreateFolder = Utils.isValidPermission(
      ModuleApiConstant.FOLDER.CREATE_FOLDER
    );
    const convertKeyword = Utils.toNonAccentVietnamese(keyword);
    const resolvePayloadByKeyword = filter(
      payload,
      (folder: IFolderStructure) =>
        Utils.toNonAccentVietnamese(folder.name).includes(convertKeyword)
    );
    const folders = resolvePayloadByKeyword.map((folder: IFolderStructure) => {
      const isActive = folder.id === active;
      return (
        <Stack
          key={`folder-${folder.id}`}
          direction="row"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            paddingY: '4px',
            height: '38px',
            textTransform: 'none',
            background: isActive ? '#ccc' : 'white',
            cursor: 'pointer',
          }}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            onActive(folder.id);
          }}
        >
          <Stack
            direction="row"
            flex={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'start',
              paddingX: '10px',
              textTransform: 'none',
            }}
          >
            <FolderOutlinedIcon sx={{ fontSize: '20px', marginRight: '5px' }} />

            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: isActive ? '500' : '400',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 1,
              }}
            >
              {folder.name}
            </Typography>
          </Stack>
          {isAcceptEdit || isAcceptRemove ? (
            <IconButton
              onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                handleClick(event);
                setFolderEditing(folder);
              }}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          ) : null}
        </Stack>
      );
    });
    return (
      <Stack direction="column" sx={{ width: '100%' }}>
        <Stack direction="row" sx={{ mb: 1 }}>
          <TextField
            value={keyword}
            size="small"
            onChange={(e: any) => setKeyword(e.target.value)}
            placeholder={t('placeholder.keyword')}
            sx={{ mr: 1, maxWidth: '190px' }}
          />
          <Button
            onClick={() => setKeyword('')}
            variant="outlined"
            sx={{ minWidth: 'max-content', mr: 1 }}
            disabled={!keyword}
          >
            {t('button.reset')}
          </Button>
          {isAllowCreateFolder && (
            <Button
              variant="contained"
              onClick={() => setIsShowFolderPopup(true)}
            >
              {t('button.create')}
            </Button>
          )}
        </Stack>
        {folders}
      </Stack>
    );
  };

  const _renderPopover = () => {
    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          {isAcceptEdit ? (
            <ListItem disablePadding>
              <ListItemButton
                sx={{ py: '5px' }}
                onClick={() => onAction('edit')}
              >
                <ListItemIcon sx={{ minWidth: '32px' }}>
                  <EditOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={t('button.edit')}
                  sx={{ span: { fontSize: '14px' } }}
                />
              </ListItemButton>
            </ListItem>
          ) : null}
          {isAcceptRemove ? (
            <ListItem disablePadding>
              <ListItemButton
                sx={{ py: '5px' }}
                onClick={() => onAction('delete')}
              >
                <ListItemIcon sx={{ minWidth: '32px' }}>
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={t('button.delete')}
                  sx={{ span: { fontSize: '14px' } }}
                />
              </ListItemButton>
            </ListItem>
          ) : null}
        </List>
      </Popover>
    );
  };

  const _renderPopup = () => {
    return (
      <FolderDetails
        open={isShowFolderPopup}
        payload={folderEditing}
        onClose={() => {
          setIsShowFolderPopup(false);
          setFolderEditing(null);
          handleClose();
        }}
        parent={null}
      />
    );
  };

  return (
    <Stack>
      {_renderPopup()}
      {isLoading ? <RootFolderSkeleton /> : _renderFolder()}
      {!isAcceptEdit && !isAcceptEdit ? null : _renderPopover()}
    </Stack>
  );
};

export default FolderList;
