import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Stack, useMediaQuery, useTheme } from '@mui/material';

import { Images } from '@/Themes';
import SignIn from './SignIn';
import ForgotPassword from './ForgotPassword';

const Auth: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [searchParams, setSearchParams] = useSearchParams();
  const containerRef = React.useRef<HTMLDivElement>(null);

  const form = searchParams.get('form');

  React.useEffect(() => {
    if (!form) setSearchParams({ form: 'sign-in' });
  }, []);

  React.useEffect(() => {
    if (form && containerRef && containerRef.current) {
      const height = containerRef.current.clientHeight;
      if (form === 'sign-in')
        containerRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      if (form === 'forgot-password')
        containerRef.current.scrollTo({
          top: height,
          left: 0,
          behavior: 'smooth',
        });
    }
  }, [form]);

  const _renderMain = () => {
    return (
      <Stack
        direction="row"
        sx={{
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
          borderRadius: '10px',
          backgroundColor: 'white',
          width: '100%',
          maxWidth: '800px',
          height: '100%',
          maxHeight: '600px',
        }}
      >
        {matches ? (
          <Stack
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              height: '600px',
            }}
          >
            <img src={Images.singIn} alt="" width="100%" />
          </Stack>
        ) : null}

        <Stack
          direction="column"
          sx={{ flex: 1, height: 'auto', overflow: 'hidden' }}
          ref={containerRef}
        >
          <SignIn sx={{ opacity: form === 'sign-in' ? 1 : 0 }} />
          <ForgotPassword
            sx={{ opacity: form === 'forgot-password' ? 1 : 0 }}
          />
        </Stack>
      </Stack>
    );
  };

  return _renderMain();
};

export default Auth;
