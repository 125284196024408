import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@utils';
import { IPermissionDetailsStructure } from '@/Interfaces/Permission.interface';
import { toast } from 'react-toastify';
import { IMetaStructure } from '../Interfaces/Common.interface';
import { IUserDetailsStructure } from '../Interfaces/User.interface';

const { ACTION_TYPES, ROUTERS } = CONSTANTS;

// SINGLE ACTIONS
const permissionIsRequest = () => {
  return {
    type: ACTION_TYPES.PERMISSION_IS_REQUEST,
  };
};

const setDefaultReducerPermission = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_REDUCER_PERMISSION,
  };
};

const resetPermissionStatus = () => {
  return {
    type: ACTION_TYPES.RESET_PERMISSION_STATUS,
  };
};

const setMetaRole = (payload: IMetaStructure) => {
  return {
    type: ACTION_TYPES.SET_META_ROLE,
    payload,
  };
};

// ASYNC ACTIONS
const fetchPermissionsSuccess = (payload: IPermissionDetailsStructure[]) => {
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONS_SUCCESS,
    payload,
  };
};

const fetchPermissionsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONS_FAILURE,
  };
};

const fetchPermissions = () => {
  return async (dispatch: any) => {
    dispatch(permissionIsRequest());
    await API.fetchPermissions()
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchPermissionsFailure());
        else {
          const resolveResult: IPermissionDetailsStructure[] =
            result as IPermissionDetailsStructure[];
          dispatch(fetchPermissionsSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(fetchRolesFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const fetchRolesSuccess = (payload: IPermissionDetailsStructure[]) => {
  return {
    type: ACTION_TYPES.FETCH_ROLES_SUCCESS,
    payload,
  };
};

const fetchRolesFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_ROLES_FAILURE,
  };
};

const fetchRoles = (payload?: { page: number; limit: number }) => {
  return async (dispatch: any) => {
    dispatch(permissionIsRequest());
    await API.fetchRoles(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchRolesFailure());
        else {
          const resolveResult: {
            items: IPermissionDetailsStructure[];
            meta: IMetaStructure;
          } = result as {
            items: IPermissionDetailsStructure[];
            meta: IMetaStructure;
          };
          dispatch(setMetaRole(resolveResult.meta));
          dispatch(fetchRolesSuccess(resolveResult.items));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(fetchRolesFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const fetchRoleOptionsSuccess = (payload: IPermissionDetailsStructure[]) => {
  return {
    type: ACTION_TYPES.FETCH_ROLE_OPTIONS_SUCCESS,
    payload,
  };
};

const fetchRoleOptionsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_ROLE_OPTIONS_FAILURE,
  };
};

const fetchRoleOptions = () => {
  return async (dispatch: any) => {
    dispatch(permissionIsRequest());
    await API.fetchRoleOptions()
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchRoleOptionsFailure());
        else {
          const resolveResult: IPermissionDetailsStructure[] =
            result as IPermissionDetailsStructure[];
          dispatch(fetchRoleOptionsSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(fetchRoleOptionsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const getRoleByIdSuccess = (payload: IPermissionDetailsStructure) => {
  return {
    type: ACTION_TYPES.GET_ROLE_BY_ID_SUCCESS,
    payload,
  };
};

const getRoleByIdFailure = () => {
  return {
    type: ACTION_TYPES.GET_ROLE_BY_ID_FAILURE,
  };
};

const getRoleById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(permissionIsRequest());
    await API.getRoleById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getRoleByIdFailure());
        else {
          const resolveResult: IPermissionDetailsStructure =
            result as IPermissionDetailsStructure;
          dispatch(getRoleByIdSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(getRoleByIdFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createRoleSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_ROLE_SUCCESS,
  };
};

const createRoleFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_ROLE_FAILURE,
  };
};

const createRole = (payload: IPermissionDetailsStructure) => {
  return async (dispatch: any) => {
    dispatch(permissionIsRequest());
    await API.createRole(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response.data.message;
        if (!result) {
          await dispatch(createRoleFailure());
          toast.error(message);
        } else {
          dispatch(createRoleSuccess());
          await Utils.redirect(ROUTERS.ROLE_LIST);
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(createRoleFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const updateRoleSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_ROLE_SUCCESS,
  };
};

const updateRoleFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_ROLE_FAILURE,
  };
};

const updateRole = (payload: IPermissionDetailsStructure) => {
  return async (dispatch: any) => {
    dispatch(permissionIsRequest());
    await API.updateRole(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response.data.message;
        if (!result) {
          await dispatch(updateRoleFailure());
          toast.error(message);
        } else {
          dispatch(updateRoleSuccess());
          await Utils.redirect(ROUTERS.ROLE_LIST);
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(updateRoleFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const fetchUserUnassignedRoleSuccess = (payload: IUserDetailsStructure[]) => {
  return {
    type: ACTION_TYPES.FETCH_USER_UNASSIGNED_ROLE_SUCCESS,
    payload,
  };
};

const fetchUserUnassignedRoleFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_USER_UNASSIGNED_ROLE_FAILURE,
  };
};

const fetchUserUnassignedRole = () => {
  return async (dispatch: any) => {
    await API.fetchUserUnassignedRole()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        const { message } = result;
        if (!result) {
          await dispatch(fetchUserUnassignedRoleFailure());
          toast.error(message);
        } else {
          const resolveResult: IUserDetailsStructure[] =
            result as IUserDetailsStructure[];
          dispatch(fetchUserUnassignedRoleSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(fetchUserUnassignedRoleFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const removeUserRoleSuccess = (payload: IPermissionDetailsStructure) => {
  return {
    type: ACTION_TYPES.REMOVE_USER_ROLE_SUCCESS,
    payload,
  };
};

const removeUserRoleFailure = () => {
  return {
    type: ACTION_TYPES.REMOVE_USER_ROLE_FAILURE,
  };
};

const removeUserRole = (id: string) => {
  return async (dispatch: any) => {
    dispatch(permissionIsRequest());
    await API.removeUserRole(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response.data.message;
        if (!result) {
          await dispatch(removeUserRoleFailure());
          toast.error(message);
        } else {
          const resolveResult: IPermissionDetailsStructure =
            result as IPermissionDetailsStructure;
          dispatch(removeUserRoleSuccess(resolveResult));
          dispatch(fetchUserUnassignedRole());
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(removeUserRoleFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  setDefaultReducerPermission,
  resetPermissionStatus,
  fetchPermissions,
  fetchRoles,
  getRoleById,
  updateRole,
  createRole,
  removeUserRole,
  fetchRoleOptions,
};
