interface IColumnStructure {
  id:
    | 'no'
    | 'index'
    | 'role_name'
    | 'updated_at'
    | 'action'
    | 'status'
    | 'title'
    | 'name'
    | 'description'
    | 'working_date'
    | 'subject'
    | 'send_at'
    | 'subject'
    | 'content'
    | 'to'
    | 'createdAt'
    | 'action'
    | 'attachments'
    | 'staff_code'
    | 'name'
    | 'user_log'
    | 'method'
    | 'status'
    | 'created_at'
    | 'action_by'
    | 'action_at'
    | 'log_name'
    | 'reply'
    | 'start_date'
    | 'end_date'
    | 'position'
    | 'file_name'
    | 'capacity'
    | 'upload_at'
    | 'remove'
    | 'from_date'
    | 'to_date'
    | 'project_name'
    | 'position'
    | 'skills'
    | 'full_name'
    | 'export'
    | 'email'
    | 'staff_code'
    | 'export_options'
    | 'mask_as_read'
    | 'send_by'
    | 'view'
    | 'totalUser'
    | 'createdBy'
    | 'date';

  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

const LEAVE_APPLICATION_COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'No', minWidth: 5, align: 'center' },
  { id: 'staff_code', label: 'Staff Code', minWidth: 200 },
  { id: 'subject', label: 'Subject', minWidth: 100 },
  { id: 'content', label: 'Content', minWidth: 300 },
  {
    id: 'send_at',
    label: 'Send at',
    minWidth: 120,
    align: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 80,
    align: 'center',
  },
  { id: 'action', label: 'Action', minWidth: 50 },
];

const MAIL_COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'No', width: 5, align: 'center' },
  { id: 'to', label: 'To', width: 200 },
  { id: 'subject', label: 'Subject', flex: 1 },
  { id: 'created_at', label: 'Send at', width: 200, align: 'center' },
  { id: 'reply', label: 'Reply', width: 150, align: 'center' },
  { id: 'attachments', label: 'Attachments', width: 150, align: 'center' },
  { id: 'action', label: 'Action', width: 150, align: 'center' },
];

const PERFORMANCE_REVIEW_COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'No', minWidth: 5, align: 'center' },
  { id: 'title', label: 'Title', minWidth: 100 },
  { id: 'staff_code', label: 'Staff Code', minWidth: 5, align: 'center' },
  { id: 'created_at', label: 'Send at', minWidth: 5, align: 'center' },
  { id: 'status', label: 'Status', minWidth: 5, align: 'center' },
  { id: 'export_options', label: 'Export', minWidth: 8, align: 'center' },
  { id: 'action', label: 'Action', minWidth: 8, align: 'right' },
];

const NOTIFICATIONS_COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'No', minWidth: 5, align: 'center' },
  { id: 'name', label: 'Name', minWidth: 300, align: 'center' },
  { id: 'send_by', label: 'Send By', minWidth: 5, align: 'center' },
  { id: 'send_at', label: 'Send at', minWidth: 5, align: 'center' },
  { id: 'status', label: 'Status', minWidth: 5, align: 'center' },
  { id: 'mask_as_read', label: 'Mask as read', minWidth: 5, align: 'center' },
  { id: 'view', label: 'View', minWidth: 5, align: 'center' },
];

const COLUMNS = {
  MAIL_COLUMNS,
  PERFORMANCE_REVIEW_COLUMNS,
  LEAVE_APPLICATION_COLUMNS,
  NOTIFICATIONS_COLUMNS,
};

export default COLUMNS;
