import * as React from 'react';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import { LoadingButton } from '@mui/lab';
import { Stack, Box } from '@mui/material';
import { useParams } from 'react-router';

import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import { Alert } from '@/Widgets';

import { AddUserToRoleDataTable } from '../../LayoutPart/DataTable';
import { IUserDetailsStructure } from '@/Interfaces/User.interface';
import { RootState, useTypedDispatch } from '@/store';
import PopupLayout from '../PopupLayout';
import { UserActions } from '@/Actions';
import Utils from '@/Utils';

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const { fetchUserUnassignedRole, clearStatusUserDetails, assignRoleForUser } =
  UserActions;

const AddUserToRolePopup: React.FC<SectionProps> = ({ open, onClose }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const { id } = useParams();

  const isCreateUserSuccess = useSelector((state: RootState) =>
    get(state.USER, 'createUserIsSuccess')
  );
  const isLoading = useSelector((state: RootState) =>
    get(state.USER, 'requestIsLoading')
  );
  const users: IUserDetailsStructure[] = useSelector((state: RootState) =>
    get(state.USER, 'unassignedRole')
  );

  React.useEffect(() => {
    dispatch(fetchUserUnassignedRole());
  }, []);

  React.useEffect(() => {
    if (open) {
      const isAcceptApi = Utils.isValidPermission(
        ModuleApiConstant.USER.CREATE_USER
      );
      if (!isAcceptApi) {
        onClose();
        Alert({
          type: 'ERROR',
          message: t('message.notAuthorizeForCreateUser'),
        });
      }
    }
  }, [open]);
  const onCancel = () => {
    dispatch(clearStatusUserDetails());
    onClose();
  };

  React.useEffect(() => {
    if (isCreateUserSuccess) onCancel();
  }, [isCreateUserSuccess]);

  const onRowAction = (user: IUserDetailsStructure, action: 'add') => {
    if (action === 'add' && id)
      dispatch(assignRoleForUser(user.id, { role: id }));
  };

  const _renderForm = () => (
    <>
      <Box component="form" sx={{ width: '100%', flex: 1 }}>
        <AddUserToRoleDataTable
          payload={users}
          onRowAction={onRowAction}
          isLoading={isLoading}
        />
      </Box>
      <Stack sx={{ justifyContent: 'end', marginTop: 5 }} direction="row">
        <LoadingButton sx={{ mr: 1 }} onClick={onCancel}>
          {t('button.close')}
        </LoadingButton>
      </Stack>
    </>
  );

  return (
    <PopupLayout
      title={t('title.addUser')}
      content={_renderForm()}
      open={open}
      onClose={onClose}
    />
  );
};

export default AddUserToRolePopup;
