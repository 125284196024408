import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 1,
  itemsPerPage: 10,
  totalItems: 1,
  totalPages: 1,
};

const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  isUploadSuccess: false,
  idDeleteSuccess: false,
  fileIsDownloading: false,
  downloadIsSuccess: false,
  payload: [],
  meta: DEFAULT_META,
};

const MediaReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_DEFAULT_MEDIA_STATUS:
      return {
        ...state,
        isUploadSuccess: false,
        idDeleteSuccess: false,
        downloadIsSuccess: false,
        fileIsDownloading: false,
      };
    case ACTION_TYPES.MEDIA_IS_REQUEST:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
        isUploadSuccess: false,
      };
    case ACTION_TYPES.MEDIA_IS_DOWNLOADING:
      return {
        ...state,
        fileIsDownloading: true,
      };
    case ACTION_TYPES.SET_DEFAULT_MEDIA_REDUCER:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        isUploadSuccess: false,
        idDeleteSuccess: false,
        fileIsDownloading: false,
        payload: [],
        meta: DEFAULT_META,
      };
    case ACTION_TYPES.SET_MEDIA_META:
      return {
        ...state,
        meta: payload,
      };

    case ACTION_TYPES.FETCH_MEDIA_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };
    case ACTION_TYPES.FETCH_MEDIA_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload,
      };
    case ACTION_TYPES.UPLOAD_MEDIA_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        isUploadSuccess: false,
      };
    case ACTION_TYPES.UPLOAD_MEDIA_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        isUploadSuccess: true,
      };
    case ACTION_TYPES.DELETE_MEDIA_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        idDeleteSuccess: true,
      };
    case ACTION_TYPES.DELETE_MEDIA_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        idDeleteSuccess: false,
      };
    case ACTION_TYPES.DOWNLOAD_FILES_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        fileIsDownloading: false,
        downloadIsSuccess: true,
      };
    case ACTION_TYPES.DOWNLOAD_FILES_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        fileIsDownloading: false,
        downloadIsSuccess: false,
      };

    default:
      return state;
  }
};

export default MediaReducer;
