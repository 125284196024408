import * as React from 'react';
import get from 'lodash/get';
import { useParams, useSearchParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import { useTranslation } from 'react-multi-lang';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import {
  Stack,
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';

import { DailyReportActions } from '@/Actions';
import Utils from '@/Utils';
import Constants from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import { DatePicker, TextEditor, UploadFile } from '@/Components/Common';

import PopupLayout from '../PopupLayout';
import { IDailyReport } from '@/Interfaces/DailyReport.interface';

interface SectionProps {
  onClose(): void;
  filterPayload?: { page: number; limit: number };
  isProjectDetails?: boolean;
}

const {
  fetchDailyReports,
  createDailyReport,
  updateDailyReport,
  getReportDetailsByDateAndProject,
  clearReportDetails,
} = DailyReportActions;
const { ROUTERS, MODULE_API } = Constants;

const DailyReportPopup: React.FC<SectionProps> = ({
  onClose,
  filterPayload,
  isProjectDetails = false,
}) => {
  const [searchParams] = useSearchParams();
  const project = searchParams.get('project');
  const date = searchParams.get('date');
  const open = Boolean(project);
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const id = useParams().id || project;

  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.DAILY_REPORT, 'requestIsLoading')
  );
  const submitIsSuccess: boolean = useSelector((state: RootState) =>
    get(state.DAILY_REPORT, 'submitIsSuccess')
  );
  const details: IDailyReport = useSelector((state: RootState) =>
    get(state.DAILY_REPORT, 'details')
  );
  const isAcceptFetchProjectReport = Utils.isValidPermission(
    MODULE_API.DAILY_REPORT.FETCH_PROJECT_REPORTS
  );
  const reports: IDailyReport[] = useSelector((state: RootState) =>
    get(state.DAILY_REPORT, 'payload')
  );

  const [filename, setFilename] = React.useState<string>('');
  const [isShowLastReports, setIsShowLastReports] =
    React.useState<boolean>(false);

  const schema = yup
    .object({
      date: yup
        .date()
        .typeError(t('message.dateInvalidFormat'))
        .required(t('message.dateRequired')),
      description: yup
        .string()
        .trim()
        .required(t('message.descriptionRequired')),
      attachment: yup.mixed().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      date: '',
      description: '',
      attachment: null,
    },
  });

  React.useEffect(() => {
    if (open && project) {
      let currentDate = dayjs().format('YYYY-MM-DD');
      if (date) currentDate = date;
      setValue('date', currentDate);

      dispatch(
        getReportDetailsByDateAndProject({ project, date: currentDate })
      );
      if (id && !isProjectDetails)
        dispatch(fetchDailyReports(id, { page: 1, limit: 5 }));
    } else {
      reset();
      setFilename('');
      dispatch(clearReportDetails());
    }
  }, [open]);

  React.useEffect(() => {
    if (details?.id) {
      const { description, attachment } = details;
      setValue('date', dayjs(details.date).format('YYYY-MM-DD'));
      setValue('description', description);
      if (attachment) setFilename(attachment.originalName);
    } else {
      const { description } = details;
      setValue('description', description);
    }
  }, [details]);

  React.useEffect(() => {
    if (submitIsSuccess) {
      onClose();
      reset();
      setFilename('');
    }
  }, [submitIsSuccess]);

  const onSubmit = (
    data: { date: string; description: string; attachment: File | null },
    e: any
  ) => {
    e.preventDefault();
    if (id && uuidValidate(id)) {
      const formData = new FormData();
      formData.append('project', id);
      formData.append('date', data.date);
      formData.append('description', data.description);
      if (data.attachment) formData.append('attachment', data.attachment);
      if (details?.id) {
        formData.append('id', details.id);
        dispatch(updateDailyReport(formData, details?.id, id, filterPayload));
      } else dispatch(createDailyReport(formData, id, filterPayload));
    }
  };

  const onChangeDate = (e: Date) => {
    if (project) {
      const selectedDate = dayjs(e).format('YYYY-MM-DD');
      dispatch(
        getReportDetailsByDateAndProject({ project, date: selectedDate })
      );
    }
  };

  console.log(errors);

  const _renderLastReports = () => (
    <Stack
      direction="column"
      sx={{
        transition: 'all ease 0.25s',
        width: isShowLastReports ? '350px' : '0px',
        overflow: 'hidden',
        ml: isShowLastReports ? 2 : 0,
        opacity: isShowLastReports ? 1 : 0,
        whiteSpace: 'nowrap',
        height: '100%',
        maxHeight: '500px',
      }}
    >
      <Typography
        sx={{
          fontWeight: '500',
          mb: 1,
          fontSize: '15px',
        }}
      >
        {t('label.lastReports')}
      </Typography>
      <Typography
        sx={{
          fontWeight: '400',
          mb: 1,
          fontSize: '15px',
        }}
      >
        {/* {t('label.project')}: <b>{project?.name}</b> */}
      </Typography>
      <Stack direction="column" sx={{ overflow: 'auto' }}>
        {reports.length > 0 &&
          reports.map((report: IDailyReport) => (
            <Stack
              direction="column"
              key={`report-${report.id}`}
              sx={{
                p: 1,
                border: '1px solid #ccc',
                borderRadius: '5px',
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  mb: 1,
                  whiteSpace: isShowLastReports ? 'pre-wrap' : 'nowrap',
                }}
              >
                {t('label.date')}:{' '}
                <b>{dayjs(report.date).format('DD-MM-YYYY')}</b>
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  whiteSpace: 'pre-wrap',
                  '*': {
                    margin: '5px 0px',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: report.description }}
              />
            </Stack>
          ))}
        {reports.length === 0 ? (
          <Typography sx={{ fontSize: '14px' }}>
            {t('tooltip.noDataWereFound')}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );

  const _renderForm = () => (
    <Stack direction="row">
      <Box component="form" sx={{ width: '700px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <DatePicker
                label={t('label.date')}
                value={field.value}
                message={errors.date?.message}
                onChange={(e: any) => {
                  field.onChange(e);
                  onChangeDate(e);
                }}
                required
                disableFuture
                sx={{ mb: 2 }}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextEditor
                value={field.value}
                message={errors.description?.message}
                label={t('label.description')}
                containerSx={{ mb: 2 }}
                onChange={(e: any) => field.onChange(e)}
                required
              />
            )}
          />
          <Controller
            name="attachment"
            control={control}
            render={({ field }) => (
              <UploadFile
                label={t('label.attachment')}
                onChange={(file: File | null) => {
                  field.onChange(file);
                  setError('attachment', { message: '' });
                  if (file) setFilename(file.name);
                  else setFilename('');
                }}
                filename={filename}
                downloadPath={details?.attachment?.path}
                type="file"
                sx={{ mb: 2 }}
              />
            )}
          />
          <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
            <Stack>
              {isAcceptFetchProjectReport && !isProjectDetails ? (
                <FormControlLabel
                  control={
                    <Switch
                      checked={isShowLastReports}
                      onClick={() => setIsShowLastReports(!isShowLastReports)}
                    />
                  }
                  label={t('label.showFiveLastReports')}
                />
              ) : null}
            </Stack>
            <Stack direction="row">
              {isAcceptFetchProjectReport && !isProjectDetails ? (
                <Button
                  variant="outlined"
                  sx={{ mr: 1 }}
                  onClick={() => Utils.redirect(ROUTERS.PROJECT_REPORT)}
                >
                  {t('button.reportPage')}
                </Button>
              ) : null}
              <LoadingButton
                variant="outlined"
                sx={{ mr: 1 }}
                onClick={onClose}
              >
                {t('button.cancel')}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
              >
                {details?.id ? t('button.save') : t('button.create')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </Box>
      {!isProjectDetails ? _renderLastReports() : null}
    </Stack>
  );

  return (
    <PopupLayout
      title={details?.id ? t('title.updateReport') : t('title.createReport')}
      content={_renderForm()}
      open={open}
      onClose={onClose}
    />
  );
};

export default DailyReportPopup;
