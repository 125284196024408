import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { AuthActions } from '@/Actions';
import { Images } from '@/Themes';
import { RootState, useTypedDispatch } from '@/store';

import {
  Link,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

const { login } = AuthActions;

const schema = yup
  .object({
    email: yup.string().trim().email().label('Email').required(),
    password: yup.string().min(1).trim().label('Password').required(),
  })
  .required();

interface IProps {
  sx?: object;
}

const SignIn: React.FC<IProps> = ({ sx }) => {
  const dispatch = useTypedDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const form = searchParams.get('form');
  const redirect = searchParams.get('redirect');
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.AUTH, 'requestIsLoading')
  );

  const [isShowPassword, setIsShowPassword] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (form && form !== 'sign-in') {
      setTimeout(() => {
        reset();
      }, 300);
    }
  }, [form]);

  const onSubmit = (data: { email: string; password: string }) => {
    dispatch(login(data, redirect));
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleSubmit(onSubmit);
  };

  const _renderForm = () => (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '300px',
        padding: '20px',
      }}
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            placeholder="Email"
            size="small"
            sx={{
              mb: 1,
            }}
            color={errors.email?.message ? 'error' : 'info'}
            onKeyDown={onKeyDown}
            error={Boolean(errors.email?.message)}
            helperText={errors.email?.message}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            placeholder="Password"
            type={isShowPassword ? 'text' : 'password'}
            size="small"
            sx={{ mb: 1 }}
            onKeyDown={onKeyDown}
            error={Boolean(errors.password?.message)}
            color={errors.password?.message ? 'error' : 'info'}
            InputProps={{
              style: { background: 'rgb(232, 240, 254)' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setIsShowPassword(!isShowPassword)}
                    edge="end"
                  >
                    {isShowPassword ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={errors.password?.message}
          />
        )}
      />
      <LoadingButton
        variant="contained"
        type="submit"
        sx={{
          marginY: 2,
          width: '100%',
        }}
        loading={isLoading}
      >
        Sign in
      </LoadingButton>
    </form>
  );

  const _renderMain = () => {
    return (
      <Stack
        sx={{
          minHeight: '100%',
          minWidth: '80%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'all ease 0.25s',
          ...sx,
        }}
      >
        <img src={Images.flowIdraLogo} alt="" width="60px" />
        <Typography variant="h6" sx={{ marginY: '20px' }}>
          Sign in
        </Typography>
        {_renderForm()}
        <Link
          component="button"
          variant="body2"
          onClick={() => setSearchParams({ form: 'forgot-password' })}
          sx={{ textDecoration: 'none' }}
        >
          Forgot password?
        </Link>
      </Stack>
    );
  };

  return _renderMain();
};

export default SignIn;
