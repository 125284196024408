import React from 'react';
import dayjs from 'dayjs';
import { map, groupBy, forEach, find, filter, get } from 'lodash';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import Utils from '@/Utils';
import { Alert } from '@/Widgets';
import Constants from '@/Constants';
import { EventActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { FullCalendar, RoundedContainer, TextField } from '@/Components/Common';

import { IEvent } from '@/Interfaces/Event.interface';
import { EventDetailsPopup } from '@/Components/Popup';
import { EventColors } from '@/Constants/Enums';
import { EventSkeleton } from '../../../Components/Common/Skeleton';

const { ROUTERS, MODULE_API } = Constants;
const { fetchEvents, deleteEvent, setDefaultEventReducer } = EventActions;

interface IDate {
  id?: string;
  nameEN: string;
  nameVI: string;
  startDate: string;
  endDate: string;
  color: EventColors;
}

interface ISelect {
  startDate: string;
  endDate: string;
}

const Event: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const locale = Utils.getSavedLocale();

  const payload: IEvent[] = useSelector((state: RootState) =>
    get(state.EVENT, 'payload')
  );
  const requestIsSuccess: boolean = useSelector((state: RootState) =>
    get(state.EVENT, 'requestIsSuccess')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.EVENT, 'requestIsLoading')
  );

  const isAcceptCreate = Utils.isValidPermission(MODULE_API.EVENT.CREATE_EVENT);
  const isAcceptUpdate = Utils.isValidPermission(MODULE_API.EVENT.UPDATE_EVENT);
  const isAcceptDelete = Utils.isValidPermission(MODULE_API.EVENT.DELETE_EVENT);

  const [keyword, setKeyword] = React.useState<string>('');
  const [datePayload, setDatePayload] = React.useState<IDate | null>(null);
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

  const fetchPayload = () => {
    dispatch(fetchEvents());
  };

  React.useEffect(() => {
    const isAcceptApi = Utils.isValidPermission(MODULE_API.EVENT.FETCH_EVENT);
    if (!isAcceptApi) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForFetchEvent'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else fetchPayload();
    return () => {
      dispatch(setDefaultEventReducer());
    };
  }, []);

  const handleScrollToCurrentEvent = () => {
    const parentElement = document.getElementById('parent-events');
    const currentDate = new Date().toISOString();
    const isExistEventOfThisDay = payload.find((event: IEvent) => {
      if (
        dayjs(event.startDate).format('YYYY-MM-DD') <=
          dayjs(currentDate).format('YYYY-MM-DD') &&
        dayjs(event.endDate).format('YYYY-MM-DD') >=
          dayjs(currentDate).format('YYYY-MM-DD')
      )
        return event;
      return null;
    });

    if (isExistEventOfThisDay) {
      const eventElement = document.getElementById(
        `event-${isExistEventOfThisDay.startDate}`
      );
      if (eventElement)
        setTimeout(() => {
          parentElement?.scrollTo({
            top: eventElement?.offsetTop - eventElement?.offsetHeight - 100,
            left: 0,
            behavior: 'smooth',
          });
        }, 1500);
    } else {
      const currentMonth = dayjs().format('YYYY-MM');
      const eventElement = document.getElementById(`${currentMonth}`);
      if (eventElement)
        setTimeout(() => {
          parentElement?.scrollTo({
            top: eventElement?.offsetTop - eventElement?.offsetHeight - 100,
            left: 0,
            behavior: 'smooth',
          });
        }, 1500);
    }
  };

  React.useEffect(() => {
    if (requestIsSuccess) {
      if (!isLoaded) handleScrollToCurrentEvent();
      setIsLoaded(true);
    }
  }, [requestIsSuccess]);

  const resolveEvents = React.useMemo(() => {
    const result: {
      id: string;
      title: string;
      start: string;
      end: string;
      backgroundColor: string;
      borderColor: string;
    }[] = [];
    if (payload.length > 0) {
      forEach(payload, (event: IEvent) =>
        result.push({
          id: event.id,
          title: locale === 'en' ? event.nameEN : event.nameVI,
          start: dayjs(event.startDate).format('YYYY-MM-DD'),
          end: dayjs(event.endDate).add(1, 'day').format('YYYY-MM-DD'),
          backgroundColor: event.color,
          borderColor: event.color,
        })
      );
    }
    return result;
  }, [payload]);

  const onDelete = async (event: IEvent) => {
    const isAgree = await Alert({
      type: 'WARNING',
      message: t('popup.warningDeleteEvent'),
    });
    if (isAgree === 'ok') dispatch(deleteEvent(event.id));
  };

  const onSelectEvent = (id: string) => {
    const findEvent = find(payload, (event: IEvent) => event.id === id);
    if (findEvent) setDatePayload(findEvent);
  };

  const _renderPopup = () => (
    <EventDetailsPopup
      open={Boolean(datePayload)}
      onClose={(isFetch?: boolean) => {
        if (isFetch) fetchPayload();
        setDatePayload(null);
      }}
      payload={datePayload}
    />
  );

  const _renderTopSection = () => (
    <Typography variant="h2">{t('title.events')}</Typography>
  );

  const _renderFilterSection = () => {
    return (
      <Stack
        direction="row"
        sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 3 }}
      >
        <TextField
          value={keyword}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setKeyword(event.target.value)
          }
          size="small"
          placeholder={t('placeholder.keyword')}
          style={{ mb: 0 }}
        />
        <Button
          disabled={!keyword.trim()}
          onClick={() => {
            setKeyword('');
            setTimeout(() => handleScrollToCurrentEvent(), 1000);
          }}
        >
          {t('button.reset')}
        </Button>
        {isAcceptCreate ? (
          <Button
            variant="contained"
            onClick={() => {
              setDatePayload({
                nameEN: '',
                nameVI: '',
                color: EventColors.BLUE,
                startDate: dayjs().format('YYYY-MM-DD'),
                endDate: dayjs().format('YYYY-MM-DD'),
              });
            }}
          >
            {t('button.create')}
          </Button>
        ) : null}
      </Stack>
    );
  };

  const _renderEventThisMonth = () => {
    const convertKeyword = Utils.toNonAccentVietnamese(keyword);

    const resolvePayloadByKeyword = filter(
      payload,
      (event: IEvent) =>
        Utils.toNonAccentVietnamese(event.nameEN).includes(convertKeyword) ||
        Utils.toNonAccentVietnamese(event.nameVI).includes(convertKeyword)
    );

    const groupByYear = groupBy(resolvePayloadByKeyword, (event: IEvent) => {
      return event.startDate.substring(0, 7);
    });
    const groupEvents = map(
      groupByYear,
      (yearPayload: IEvent[], time: string) => {
        const timeLabel: string[] = time.split('-').reverse();
        const eventElements: React.JSX.Element[] = map(
          yearPayload,
          (event: IEvent, index: number) => (
            <Stack
              direction="column"
              key={`event-${event.id}-${index}`}
              sx={{
                boxShadow: '0 7px 25px rgba(0,0,0,0.08)',
                m: '5px 0',
                p: 2,
              }}
              id={`event-${event.startDate}`}
            >
              <Stack direction="row" alignItems="center">
                <Typography sx={{ fontSize: '17px', mr: 1 }}>
                  {locale === 'en' ? event.nameEN : event.nameVI}
                </Typography>
                {event.id ? (
                  <>
                    {isAcceptUpdate ? (
                      <Tooltip title={t('tooltip.clickToEditEvent')}>
                        <IconButton onClick={() => setDatePayload(event)}>
                          <EditOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {isAcceptDelete ? (
                      <Tooltip title={t('tooltip.clickToDeleteEvent')}>
                        <IconButton onClick={() => onDelete(event)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </>
                ) : null}
              </Stack>
              <Typography sx={{ fontSize: '14px' }}>
                {dayjs(event.startDate).format('DD-MM-YYYY')} -{' '}
                {dayjs(event.endDate).format('DD-MM-YYYY')}
              </Typography>
            </Stack>
          )
        );
        return (
          <Stack direction="column" mb={2} key={`group-event-${time}`}>
            <Stack
              sx={{
                fontWeight: '500',
                position: 'sticky',
                top: 38,
                zIndex: 1,
                background: '#ffffff',
                height: '38px',
                display: 'flex',
                justifyContent: 'center',
              }}
              id={`${time}`}
            >
              <Typography sx={{ fontSize: '15px', fontWeight: 500 }}>
                {t('label.month')} {timeLabel[0]} {t('label.year')}{' '}
                {timeLabel[1]}
              </Typography>
            </Stack>
            {eventElements}
          </Stack>
        );
      }
    );
    return (
      <Stack direction="column" mb={2} px={1}>
        {_renderFilterSection()}
        {isLoading ? (
          <EventSkeleton />
        ) : groupEvents && groupEvents.length > 0 ? (
          groupEvents
        ) : (
          <Typography>{t('tooltip.noInformation')}</Typography>
        )}
      </Stack>
    );
  };

  const _renderEventOfCurrentWeek = () => {
    return (
      <Stack
        direction="column"
        sx={{
          width: '300px',
          mr: 2,
          height: '100%',
          maxHeight: 'calc(100vh - 300px)',
          overflow: 'auto',
        }}
        id="parent-events"
      >
        {_renderEventThisMonth()}
      </Stack>
    );
  };

  const _renderBottomSection = () => (
    <RoundedContainer>
      <Stack direction="row">
        {_renderEventOfCurrentWeek()}
        <FullCalendar
          onSelect={(dateRange: ISelect) => {
            setDatePayload({
              nameEN: '',
              nameVI: '',
              color: EventColors.BLUE,
              ...dateRange,
            });
          }}
          selectable={isAcceptCreate || isAcceptUpdate}
          onClick={(id: string) => onSelectEvent(id)}
          payload={resolveEvents}
        />
      </Stack>
    </RoundedContainer>
  );

  return (
    <>
      {_renderPopup()}
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default Event;
