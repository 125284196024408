import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@utils';
import { toast } from 'react-toastify';
import { ITag } from '../Interfaces/Tag.interface';

const { ACTION_TYPES } = CONSTANTS;

// SINGLE_ACTIONS
const projectMemberIsRequest = () => {
  return {
    type: ACTION_TYPES.TAG_IS_REQUEST,
  };
};

// ASYNC_ACTIONS
const fetchTagsSuccess = (payload: ITag[]) => {
  return {
    type: ACTION_TYPES.FETCH_TAGS_SUCCESS,
    payload,
  };
};

const fetchTagsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_TAGS_FAILURE,
  };
};

const fetchTags = () => {
  return async (dispatch: any) => {
    dispatch(projectMemberIsRequest());
    await API.fetchTags()
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchTagsFailure());
        else {
          const resolvePayload: ITag[] = result as ITag[];

          dispatch(fetchTagsSuccess(resolvePayload));
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(fetchTagsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createTagSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_TAG_SUCCESS,
  };
};

const createTagFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_TAG_FAILURE,
  };
};

const createTag = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(projectMemberIsRequest());
    await API.createTag(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createTagFailure());
        } else {
          await dispatch(createTagSuccess());
          await dispatch(fetchTags());
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createTagFailure());
      });
  };
};

const deleteTagSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_TAG_SUCCESS,
  };
};

const deleteTagFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_TAG_FAILURE,
  };
};

const deleteTag = (id: string) => {
  return async (dispatch: any) => {
    dispatch(projectMemberIsRequest());
    await API.deleteTag(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteTagFailure());
          toast.error(message);
        } else {
          dispatch(deleteTagSuccess());
          dispatch(fetchTags());
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(deleteTagFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  fetchTags,
  createTag,
  deleteTag,
};
