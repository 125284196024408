import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { validate as uuidValidate } from 'uuid';
import { Stack, Button } from '@mui/material';
import { useParams } from 'react-router';

import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import CONSTANTS from '@/Constants';
import { Alert } from '@/Widgets';

import { RoundedContainer } from '@/Components/Common';

import { ProjectMemberDataTable } from '@/Components/LayoutPart/DataTable';
import {
  IProjectMember,
  IProjectMemberResponse,
} from '@/Interfaces/ProjectMember.interface';
import { ProjectMemberActions } from '@/Actions';
import { MemberDetailsPopup } from '@/Components/Popup';
import { IUserDetailsStructure } from '../../../../Interfaces/User.interface';

const initialDetails: IProjectMember = {
  dateJoined: '',
  project: '',
  user: '',
  position: '',
};

const { ROUTERS, MODULE_API } = CONSTANTS;
const { fetchProjectMembers, deleteProjectMember } = ProjectMemberActions;

const Members: React.FC = () => {
  const t = useTranslation();
  const { id } = useParams();
  const dispatch = useTypedDispatch();
  const payload: IProjectMemberResponse[] = useSelector((state: RootState) =>
    get(state.PROJECT_MEMBER, 'payload')
  );
  const popupSubmitSuccess: boolean = useSelector((state: RootState) =>
    get(state.PROJECT_MEMBER, 'popupSubmitSuccess')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.PROJECT_MEMBER, 'requestIsLoading')
  );

  const [memberDetails, setMemberDetails] =
    React.useState<IProjectMember | null>(null);
  const [currentUser, setCurrentUser] =
    React.useState<IUserDetailsStructure | null>(null);

  const isAcceptFetch = Utils.isValidPermission(
    MODULE_API.PROJECT_MEMBER.FETCH
  );

  const isAcceptCreate = Utils.isValidPermission(
    MODULE_API.PROJECT_MEMBER.CREATE
  );

  React.useEffect(() => {
    if (!isAcceptFetch) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForUpdateProjectDocumentation'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else if (id && uuidValidate(id)) dispatch(fetchProjectMembers(id));
  }, []);

  React.useEffect(() => {
    if (popupSubmitSuccess && id && uuidValidate(id))
      dispatch(fetchProjectMembers(id));
  }, [popupSubmitSuccess]);

  const onAddMember = () => {
    if (id) setMemberDetails({ ...initialDetails, project: id });
  };

  const onRowAction = async (
    member: IProjectMemberResponse,
    action: 'edit' | 'remove'
  ) => {
    if (action === 'edit') {
      const resolveDetails: IProjectMember = {
        id: member.id,
        dateJoined: member.dateJoined,
        project: member.project.id,
        user: member.user.id,
        position: member.position,
      };
      setCurrentUser(member.user);
      setMemberDetails(resolveDetails);
    }
    if (action === 'remove') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: 'Are you sure you want to remove this member?',
      });
      if (isAgree === 'ok' && member.id && id)
        dispatch(deleteProjectMember(member.id, id));
    }
  };

  const _renderPopup = () => {
    return (
      <MemberDetailsPopup
        payload={memberDetails}
        onClose={() => {
          setMemberDetails(null);
          setCurrentUser(null);
        }}
        currentUser={currentUser}
      />
    );
  };

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mb={2}
        >
          {isAcceptCreate ? (
            <Button variant="contained" onClick={() => onAddMember()}>
              {t('button.add')}
            </Button>
          ) : null}
        </Stack>
        <ProjectMemberDataTable
          payload={payload}
          onRowAction={onRowAction}
          isLoading={isLoading}
        />
      </RoundedContainer>
    );
  };

  return (
    <>
      {_renderPopup()}
      {_renderBottomSection()}
    </>
  );
};

export default Members;
