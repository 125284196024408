import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 1,
  itemsPerPage: 10,
  totalItems: 1,
  totalPages: 1,
};

const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  createUserIsSuccess: false,
  exportUserIsSuccess: false,
  updateAvatarSuccess: false,
  unassignedRole: [],
  payload: [],
  details: {},
  documents: [],
  workHistory: [],
  projects: [],
  meta: DEFAULT_META,
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.USER_INFORMATION_IS_REQUEST:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.SET_USERS_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.RESET_USER_STATUS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        createUserIsSuccess: false,
        exportUserIsSuccess: false,
        updateAvatarSuccess: false,
      };
    case ACTION_TYPES.RESET_USER_REDUCER:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        createUserIsSuccess: false,
        exportUserIsSuccess: false,
        updateAvatarSuccess: false,
        payload: [],
        details: {},
        documents: [],
        workHistory: [],
        projects: [],
        meta: DEFAULT_META,
      };
    case ACTION_TYPES.CLEAR_USER_PAYLOAD:
      return {
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        createUserIsSuccess: false,
        createUserProjectIsSuccess: false,
        payload: [],
        details: {},
        meta: DEFAULT_META,
      };
    case ACTION_TYPES.CLEAR_STATUS_USER_DETAILS: {
      return {
        ...state,
        createUserIsSuccess: false,
      };
    }

    case ACTION_TYPES.FETCH_USERS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload,
      };
    case ACTION_TYPES.FETCH_USERS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };

    case ACTION_TYPES.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        details: {},
      };

    case ACTION_TYPES.CREATE_USER_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        createUserIsSuccess: true,
      };
    case ACTION_TYPES.CREATE_USER_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        createUserIsSuccess: false,
      };

    case ACTION_TYPES.UPDATE_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.UPDATE_USER_INFORMATION_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        details: {},
      };

    case ACTION_TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.EXPORT_USERS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        exportUserIsSuccess: true,
      };
    case ACTION_TYPES.EXPORT_USERS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        exportUserIsSuccess: false,
      };

    case ACTION_TYPES.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
      };
    case ACTION_TYPES.ACTIVATE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
      };

    case ACTION_TYPES.DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
      };
    case ACTION_TYPES.DEACTIVATE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
      };

    case ACTION_TYPES.UPDATE_AVATAR_USER_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        updateAvatarSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.UPDATE_AVATAR_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        updateAvatarSuccess: false,
      };

    case ACTION_TYPES.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
      };
    case ACTION_TYPES.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
      };

    case ACTION_TYPES.UPDATE_AVATAR_PROFILE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        updateAvatarSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.UPDATE_AVATAR_PROFILE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        updateAvatarSuccess: false,
      };

    case ACTION_TYPES.FETCH_USER_UNASSIGNED_ROLE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
        unassignedRole: payload,
      };
    case ACTION_TYPES.FETCH_USER_UNASSIGNED_ROLE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        updateAvatarSuccess: false,
        unassignedRole: [],
      };

    case ACTION_TYPES.ASSIGN_ROLE_FOR_USER_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        requestIsLoading: false,
      };
    case ACTION_TYPES.ASSIGN_ROLE_FOR_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
        updateAvatarSuccess: false,
      };

    default:
      return state;
  }
};
