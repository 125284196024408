import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';

const { NEWS } = CONSTANTS.API;
const { NewsStatus } = CONSTANTS.ENUMS;

export const fetchNews = async (payload: any) => {
  return sendRequest(NEWS.BASIC, 'GET', payload);
};

export const getPostById = async (id: string) => {
  return sendRequest(`${NEWS.GET_BY_ID}/${id}`, 'GET');
};

export const createPost = async (payload: any) => {
  return sendRequest(NEWS.BASIC, 'POST', payload);
};

export const updatePost = async (payload: any, id: string) => {
  return sendRequest(`${NEWS.BASIC}/${id}`, 'PUT', payload);
};

export const deletePost = async (id: string) => {
  return sendRequest(`${NEWS.BASIC}/${id}`, 'DEL');
};

export const updateStatusPost = async (status: string, id: string) => {
  const resolvePath = (() => {
    switch (status) {
      case NewsStatus.ACTIVE:
        return NEWS.ACTIVATE;
      case NewsStatus.INACTIVE:
        return NEWS.DEACTIVATE;
      case NewsStatus.PUBLISH:
        return NEWS.PUBLISH;
      default:
        break;
    }
    return '';
  })();
  return sendRequest(`${resolvePath}/${id}`, 'PUT');
};
