import { toast } from 'react-toastify';
import { isArray, omit } from 'lodash/';

import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';

import { IMetaStructure } from '@/Interfaces/Common.interface';
import {
  ICrawlingSystemStructure,
  ICrawlingProjectFilter,
} from '@/Interfaces/CrawlingProjects.interface';

const { ACTION_TYPES, ROUTERS } = CONSTANTS;

// SINGLE_ACTIONS
const crawlProjectIsRequest = () => {
  return {
    type: ACTION_TYPES.CRAWL_PROJECT_IS_REQUEST,
  };
};

const captureProjectIsRequest = () => {
  return {
    type: ACTION_TYPES.CAPTURE_PROJECT_IS_REQUEST,
  };
};

const setMetaPagination = (payload: IMetaStructure) => {
  return {
    type: ACTION_TYPES.SET_META_CRAWL_PROJECT,
    payload,
  };
};

const setDefaultReducerCrawlProject = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_REDUCER_CRAWL_PROJECT,
  };
};

// ASYNC_ACTIONS
const fetchCrawlProjectSuccess = (payload: ICrawlingProjectFilter[]) => {
  return {
    type: ACTION_TYPES.FETCH_CRAWL_PROJECT_SUCCESS,
    payload,
  };
};

const fetchCrawlProjectFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_CRAWL_PROJECT_FAILURE,
  };
};

const fetchCrawlProjects = (payload: ICrawlingProjectFilter) => {
  return async (dispatch: any) => {
    dispatch(crawlProjectIsRequest());
    await API.fetchCrawlProjects(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchCrawlProjectFailure());
        else if (typeof result === 'object' && result !== null) {
          const resolvePayload: ICrawlingSystemStructure[] = [];
          if (isArray(result)) resolvePayload.push(...[...result]);
          else {
            const resolveResult: {
              items: ICrawlingSystemStructure[];
              meta: IMetaStructure;
            } = result as {
              items: ICrawlingSystemStructure[];
              meta: IMetaStructure;
            };
            dispatch(setMetaPagination(resolveResult.meta));
            resolvePayload.push(...[...resolveResult.items]);
          }
          dispatch(fetchCrawlProjectSuccess(resolvePayload));
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(fetchCrawlProjectFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createCrawlProjectSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_CRAWL_PROJECT_SUCCESS,
  };
};

const createCrawlProjectFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_CRAWL_PROJECT_FAILURE,
  };
};

const createCrawlProject = (payload: ICrawlingSystemStructure) => {
  return async (dispatch: any) => {
    dispatch(crawlProjectIsRequest());
    await API.createCrawlProject(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createCrawlProjectFailure());
        } else {
          await dispatch(createCrawlProjectSuccess());
          await Utils.redirect(ROUTERS.CRAWLING_PROJECT);
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createCrawlProjectFailure());
      });
  };
};

const updateCrawlProjectSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_CRAWL_PROJECT_SUCCESS,
  };
};

const updateCrawlProjectFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_CRAWL_PROJECT_FAILURE,
  };
};

const updateCrawlProject = (payload: ICrawlingSystemStructure) => {
  return async (dispatch: any) => {
    dispatch(crawlProjectIsRequest());
    await API.updateCrawlProject(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateCrawlProjectFailure());
        } else {
          await dispatch(updateCrawlProjectSuccess());
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateCrawlProjectFailure());
      });
  };
};

const getCrawlProjectByIdSuccess = (payload: ICrawlingSystemStructure) => {
  return {
    type: ACTION_TYPES.GET_CRAWL_PROJECT_BY_ID_SUCCESS,
    payload,
  };
};

const getCrawlProjectByIdFailure = () => {
  return {
    type: ACTION_TYPES.GET_CRAWL_PROJECT_BY_ID_FAILURE,
  };
};

const getCrawlProjectById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(crawlProjectIsRequest());
    await API.getCrawlProjectById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getCrawlProjectByIdFailure());
        else {
          const resolveResult: ICrawlingSystemStructure =
            result as ICrawlingSystemStructure;
          dispatch(getCrawlProjectByIdSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(getCrawlProjectByIdFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const activateProjectSuccess = () => {
  return {
    type: ACTION_TYPES.ACTIVATE_CRAWL_PROJECT_SUCCESS,
  };
};

const activateProjectFailure = () => {
  return {
    type: ACTION_TYPES.ACTIVATE_CRAWL_PROJECT_FAILURE,
  };
};

const activateCrawlProject = (
  id: string,
  filterParams: ICrawlingProjectFilter
) => {
  return async (dispatch: any) => {
    dispatch(crawlProjectIsRequest());
    await API.activateCrawlProject(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(activateProjectFailure());
          toast.error(message);
        } else {
          dispatch(activateProjectSuccess());
          dispatch(fetchCrawlProjects(filterParams));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(activateProjectFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const deactivateProjectSuccess = () => {
  return {
    type: ACTION_TYPES.DEACTIVATE_CRAWL_PROJECT_SUCCESS,
  };
};

const deactivateProjectFailure = () => {
  return {
    type: ACTION_TYPES.DEACTIVATE_CRAWL_PROJECT_FAILURE,
  };
};

const deactivateCrawlProject = (
  id: string,
  filterParams: ICrawlingProjectFilter
) => {
  return async (dispatch: any) => {
    dispatch(crawlProjectIsRequest());
    await API.deactivateCrawlProject(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deactivateProjectFailure());
          toast.error(message);
        } else {
          dispatch(deactivateProjectSuccess());
          dispatch(fetchCrawlProjects(filterParams));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(deactivateProjectFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const captureImageProjectSuccess = () => {
  return {
    type: ACTION_TYPES.CAPTURE_IMAGE_PROJECT_SUCCESS,
  };
};

const captureImageProjectFailure = () => {
  return {
    type: ACTION_TYPES.CAPTURE_IMAGE_PROJECT_FAILURE,
  };
};

const captureImageProjects = (payload: ICrawlingProjectFilter) => {
  return async (dispatch: any) => {
    dispatch(captureProjectIsRequest());
    await API.captureCrawlProject()
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(captureImageProjectFailure());
          toast.error(message);
        } else {
          dispatch(captureImageProjectSuccess());
          const resolvePayload =
            payload?.type === 'allType' ? omit(payload, ['type']) : payload;
          dispatch(fetchCrawlProjects(resolvePayload));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(captureImageProjectFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  fetchCrawlProjects,
  getCrawlProjectById,
  createCrawlProject,
  updateCrawlProject,
  activateCrawlProject,
  deactivateCrawlProject,
  captureImageProjects,
  setDefaultReducerCrawlProject,
};
