import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-multi-lang';
import { isEmpty, forEach, get } from 'lodash';
import dayjs from 'dayjs';

import { Tooltip, IconButton, Typography } from '@mui/material';
import { StickyHeadTable } from '@/Components/Common';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { IDailyReport } from '@/Interfaces/DailyReport.interface';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

interface IDataRowStructure {
  no: number;
  reportDate: string;
  projectName: JSX.Element;
  projectCode: JSX.Element;
  reportBy: JSX.Element;
  action: JSX.Element;
}

interface IColumnStructure {
  id:
    | 'no'
    | 'reportDate'
    | 'projectName'
    | 'projectCode'
    | 'reportBy'
    | 'action';

  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'reportDate', label: 'reportDate', minWidth: 150, align: 'center' },
  { id: 'projectName', label: 'projectName', minWidth: 150 },
  { id: 'projectCode', label: 'projectCode', minWidth: 130 },
  { id: 'reportBy', label: 'reportBy', minWidth: 100, align: 'center' },
  { id: 'action', label: 'action', minWidth: 100, align: 'center' },
];

const createDataTable = (
  no: number,
  reportDate: string,
  projectName: JSX.Element,
  projectCode: JSX.Element,
  reportBy: JSX.Element,
  action: JSX.Element
): IDataRowStructure => {
  return {
    no,
    reportDate,
    projectName,
    projectCode,
    reportBy,
    action,
  };
};

interface ISectionProps {
  meta: IMetaStructure;
  payload: IDailyReport[];
  onRowAction(content: IDailyReport, action: 'view' | 'delete'): void;
  onPageAction(value: number, type: 'limit' | 'page'): void;
  isLoading?: boolean;
}

const ProjectReportsDataTable: React.FC<ISectionProps> = ({
  meta,
  payload,
  onPageAction,
  onRowAction,
  isLoading = false,
}) => {
  // Declare localization
  const t = useTranslation();
  const [rows, setRows] = useState<IDataRowStructure[]>([]);

  useEffect(() => {
    const resolveRows: IDataRowStructure[] = [];
    if (!isEmpty(payload)) {
      forEach(payload, (item: IDailyReport, index: number) => {
        const { createdBy, date, project } = item;

        const limit = get(meta, 'itemsPerPage');
        const currentPage = get(meta, 'currentPage');
        index = index + 1 + (currentPage - 1) * limit;

        const reportDate = dayjs(date);
        resolveRows.push(
          createDataTable(
            index,
            reportDate.isValid()
              ? reportDate.format('DD-MM-YYYY')
              : t('table.noInformation'),

            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                height: 1,
                maxWidth: 400,
              }}
            >
              {project?.name ? project.name : t('table.noInformation')}
            </Typography>,
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                height: 1,
                maxWidth: 200,
              }}
            >
              {project?.projectCode
                ? project?.projectCode
                : t('table.noInformation')}
            </Typography>,

            <Tooltip title={createdBy.email}>
              <Typography
                sx={{
                  fontSize: '15px',
                }}
              >
                {createdBy?.userData?.fullName}
              </Typography>
            </Tooltip>,
            <Tooltip title={t('tooltip.viewDetails')}>
              <IconButton onClick={() => onRowAction(item, 'view')}>
                <VisibilityOutlinedIcon />
              </IconButton>
            </Tooltip>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      onAction={onPageAction}
      paginate={meta}
      isLoading={isLoading}
    />
  );
};

export default ProjectReportsDataTable;
