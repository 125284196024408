import React, { useEffect } from 'react';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-multi-lang';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useSelector } from 'react-redux';

import { Stack, Typography, Grid, Button, TextField } from '@mui/material';
import CONSTANTS from '@/Constants';
import Utils from '@/Utils';
import { CrawlingSystemActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { RoundButton, RoundedContainer, Dropdown } from '@/Components/Common';

import { CrawlingProjectDataTable } from '@/Components/LayoutPart/DataTable';
import {
  ICrawlingProjectFilter,
  ICrawlingSystemStructure,
} from '@/Interfaces/CrawlingProjects.interface';
import { Alert } from '@/Widgets';

const { ROUTERS, ENUMS } = CONSTANTS;

const { fetchCrawlProjects, deactivateCrawlProject } = CrawlingSystemActions;
const initialFilter: any = {
  type: 'allType',
  keyword: '',
  page: 1,
  limit: 10,
};

const projectTypes = [
  { value: 'allType', label: 'allType' },
  { value: ENUMS.ProjectTypes.WEBAPP, label: 'webApp' },
  { value: ENUMS.ProjectTypes.WEBSERVICE, label: 'webService' },
  { value: ENUMS.ProjectTypes.OTHER, label: 'other' },
];
// eslint-disable-next-line react/function-component-definition
const CrawlingProject: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const isLoading: boolean = useSelector((state: RootState) =>
    _.get(state.CRAWLING_SYSTEM, 'requestIsLoading')
  );

  const payload: ICrawlingSystemStructure[] = useSelector((state: RootState) =>
    _.get(state.CRAWLING_SYSTEM, 'payload')
  );

  const pagination = useSelector((state: RootState) =>
    _.get(state.CRAWLING_SYSTEM, 'pagination')
  );
  const meta = useSelector((state: RootState) =>
    _.get(state.CRAWLING_SYSTEM, 'meta')
  );

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: initialFilter,
  });

  useEffect(() => {
    dispatch(fetchCrawlProjects(pagination));
  }, []);

  const fetchPayload = (filterParams: any) => {
    const resolverFilter = Utils.validateFilters(filterParams);
    dispatch(fetchCrawlProjects(resolverFilter));
  };

  const onReset = () => {
    reset();
    fetchPayload(initialFilter);
  };

  const onSearch = (data: ICrawlingProjectFilter) => fetchPayload(data);
  // Handle function
  const onRowAction = async (
    project: { id: string },
    action: 'edit' | 'remove'
  ) => {
    const { id } = project;
    if (action === 'edit')
      Utils.redirect({ pathname: ROUTERS.CRAWLING_PROJECT_DETAIL, id });
    if (action === 'remove') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningDeleteProject'),
      });
      if (isAgree === 'ok') dispatch(deactivateCrawlProject(id, pagination));
    }
  };

  const onPageAction = async (value: number, type: 'limit' | 'page') => {
    const currentFilter = getValues();
    const newFilter = {
      ...currentFilter,
      [type]: value,
    };

    setValue(type, value);
    if (type === 'limit') {
      setValue('page', 1);
      Object.assign(newFilter, { page: 1 });
    }
    fetchPayload(newFilter);
  };

  // Render layout
  const _renderTopSection = () => {
    return (
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Typography fontSize="20px" fontWeight="500">
            {t('title.projectManagement')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RoundButton
            startIcon={<ArrowBackIosNewIcon fontSize="small" />}
            label={t('button.backToCrawlingSystem')}
            onClick={() => Utils.redirect(ROUTERS.CRAWLING_SYSTEM)}
            sx={{ border: 'none', ':hover': { border: 'none' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Stack direction="row" sx={{ flex: 1 }}>
              <Controller
                name="keyword"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    size="small"
                    onChange={(e: any) => field.onChange(e.target.value)}
                    placeholder={t('placeholder.projectName')}
                    sx={{ mr: 1, maxWidth: '190px' }}
                    disabled={isLoading}
                  />
                )}
              />
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    sx={{ mr: 1, maxWidth: '190px' }}
                    value={field.value}
                    placeholder="To"
                    options={projectTypes}
                    onChange={(e: any) => field.onChange(e.target.value)}
                    disabled={isLoading}
                    isTranslate
                  />
                )}
              />
              <Button
                sx={{ mr: 1 }}
                onClick={handleSubmit(onSearch)}
                variant="contained"
                disabled={isLoading}
              >
                {t('button.filter')}
              </Button>
              <Button
                onClick={() => onReset()}
                variant="outlined"
                disabled={isLoading}
              >
                {t('button.reset')}
              </Button>
            </Stack>
            <RoundButton
              type="contained"
              size="large"
              label={t('button.create')}
              onClick={() => Utils.redirect(ROUTERS.CRAWLING_PROJECT_CREATE)}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  const _renderBottomSection = () => (
    <RoundedContainer>
      <CrawlingProjectDataTable
        meta={meta}
        payload={payload}
        onRowAction={onRowAction}
        onPageAction={onPageAction}
        isLoading={isLoading}
      />
    </RoundedContainer>
  );

  return (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default CrawlingProject;
