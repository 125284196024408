import {
  Stack,
  TextField,
  StandardTextFieldProps,
  FormHelperText,
  InputLabel,
  Typography,
} from '@mui/material';
import React from 'react';

interface IProps extends StandardTextFieldProps {
  label?: string;
  message?: string;
  style?: object;
}

const TextFieldInput: React.FunctionComponent<IProps> = ({
  label = '',
  message = '',
  style = {},
  children,
  ...props
}) => {
  return (
    <Stack direction="column" sx={{ mb: 1, flex: 1, ...style }}>
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}{' '}
          {props.required ? (
            <Typography
              component="span"
              color="error"
              sx={{ fontSize: '13px' }}
            >
              (*)
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      <TextField
        size="small"
        color={message ? 'error' : 'info'}
        error={Boolean(message)}
        {...props}
        required={false}
      />
      {message ? (
        <FormHelperText error={Boolean(message)}>{message}</FormHelperText>
      ) : null}
    </Stack>
  );
};

export default TextFieldInput;
