import React from 'react';
import get from 'lodash/get';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { Button, Stack, TextField, Typography } from '@mui/material';

import { RootState, useTypedDispatch } from '@/store';
import Utils from '@/Utils';
import CONSTANTS from '@/Constants';
import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import Alert from '@/Widgets/Alert';
import { DailyReportActions } from '@/Actions';

import { RoundedContainer } from '@/Components/Common';
import { ProjectReportsDataTable } from '@/Components/LayoutPart/DataTable';

import { IMetaStructure } from '@/Interfaces/Common.interface';
import {
  IDailyReport,
  IFilterProjectReports,
} from '@/Interfaces/DailyReport.interface';

import { DailyReportDetailsPopup } from '@/Components/Popup';

const { ROUTERS } = CONSTANTS;

const { fetchProjectReports, setDefaultReducerDailyReport } =
  DailyReportActions;

const initialFilter: IFilterProjectReports = {
  page: 1,
  limit: 10,
  keyword: '',
};

const ProjectReports: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: initialFilter,
  });

  const meta: IMetaStructure = useSelector((state: RootState) =>
    get(state.DAILY_REPORT, 'meta')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.DAILY_REPORT, 'requestIsLoading')
  );
  const payload: IDailyReport[] = useSelector((state: RootState) =>
    get(state.DAILY_REPORT, 'lastReports')
  );

  const [selectedReport, setSelectedReport] =
    React.useState<IDailyReport | null>(null);

  const fetchPayload = (filterParams: any) => {
    const resolverFilter = Utils.validateFilters(filterParams);
    dispatch(fetchProjectReports(resolverFilter));
  };

  React.useEffect(() => {
    const isAcceptApi = Utils.isValidPermission(
      ModuleApiConstant.DAILY_REPORT.FETCH_PROJECT_REPORTS
    );
    if (!isAcceptApi) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForFetchReport'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else fetchPayload(initialFilter);

    return () => {
      dispatch(setDefaultReducerDailyReport());
    };
  }, []);

  const onReset = () => {
    reset();
    fetchPayload(initialFilter);
  };

  const onSearch = (data: IFilterProjectReports) => {
    fetchPayload(data);
  };

  const onRowAction = async (report: IDailyReport, action: 'view') => {
    if (!report)
      return Alert({
        type: 'ERROR',
        message: t('popup.errorGettingProjectInformation'),
      });

    if (action === 'view') setSelectedReport(report);
    return null;
  };

  const onPageAction = async (value: number, type: 'limit' | 'page') => {
    const currentFilter = getValues();
    const newFilter = {
      ...currentFilter,
      [type]: value,
    };

    setValue(type, value);
    if (type === 'limit') {
      setValue('page', 1);
      Object.assign(newFilter, { page: 1 });
    }

    fetchPayload(newFilter);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') event.preventDefault();
  };

  const _renderPopup = () => {
    return (
      <DailyReportDetailsPopup
        onClose={() => setSelectedReport(null)}
        payload={selectedReport}
      />
    );
  };

  const _renderActionForm = () => {
    return (
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row" sx={{ flex: 1 }}>
          <Controller
            name="keyword"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                size="small"
                onChange={(e: any) => field.onChange(e.target.value)}
                placeholder={t('placeholder.keyword')}
                sx={{ mr: 1, maxWidth: '190px' }}
                onKeyDown={onKeyDown}
                onKeyPress={onKeyDown}
              />
            )}
          />

          <Button
            sx={{ mr: 1 }}
            onClick={handleSubmit(onSearch)}
            variant="contained"
          >
            {t('button.filter')}
          </Button>
          <Button onClick={() => onReset()} variant="outlined">
            {t('button.reset')}
          </Button>
        </Stack>
      </Stack>
    );
  };

  const _renderTopSection = () => {
    return (
      <Stack sx={{ mb: 2 }} direction="column">
        <Typography variant="h2">{t('title.projectReports')}</Typography>
        {_renderActionForm()}
      </Stack>
    );
  };

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        <ProjectReportsDataTable
          meta={meta}
          payload={payload}
          onRowAction={onRowAction}
          onPageAction={onPageAction}
          isLoading={isLoading}
        />
      </RoundedContainer>
    );
  };

  const renderMain = () => (
    <>
      {_renderPopup()}
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );

  return renderMain();
};
export default ProjectReports;
