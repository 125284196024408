/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable indent */
import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import {
  Stack,
  Box,
  InputLabel,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  // Divider,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-multi-lang';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';

import { DatePicker, TextField, Textarea } from '@/Components/Common';

import { DailyTaskActions, ProjectActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import Utils from '@/Utils';
import Constants from '@/Constants';
import { IProjectResponseStructure } from '@/Interfaces/Project.interface';

const { createDailyTask } = DailyTaskActions;
const { fetchProjects } = ProjectActions;

const { ROUTERS } = Constants;

interface IOption {
  label: string;
  value: string;
}
interface ITaskName {
  label: string;
  value: string;
}

const KEY_TASK_NAME: ITaskName[] = [
  {
    label: 'taskName',
    value: 'name',
  },
  {
    label: 'linkTask',
    value: 'link',
  },
];

const initialFilter = {
  date: new Date(),
  dataReport: [
    {
      projectId: '',
      tasks: [{ name: '', link: '' }],
      note: '',
      uuId: uuidv4(),
    },
  ],
};
/* eslint-disable react/function-component-definition */
const CreateDailyReport: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const projects: IProjectResponseStructure[] = useSelector(
    (state: RootState) => _.get(state.PROJECT, 'payload')
  );

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'isActionLoading')
  );

  const schema = yup
    .object({
      date: yup.string().trim().required(t('message.dateRequired')),
      dataReport: yup.lazy((value) =>
        value.length
          ? yup.array().of(
              yup.object({
                projectId: yup
                  .string()
                  .trim()
                  .required(t('message.projectNameRequired')),
                tasks: yup.lazy((_value) =>
                  _value.length
                    ? yup.array().of(
                        yup.object({
                          name: yup
                            .string()
                            .trim()
                            .required(t('message.nameRequired')),
                          link: yup
                            .string()
                            .trim()
                            .required(t('message.linkRequired'))
                            .matches(
                              /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
                              t('message.linkInvalid')
                            ),
                        })
                      )
                    : yup.array().notRequired()
                ),
              })
            )
          : yup.array().notRequired()
      ),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialFilter,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dataReport',
  });

  const watchDataReport = watch('dataReport', []);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const projectOptions = useMemo(() => {
    const options: IOption[] = [];
    _.forEach(projects, (item: any) =>
      options.push({
        label: item.name,
        value: item.id,
      })
    );
    return options;
  }, [projects]);

  useEffect(() => {
    dispatch(fetchProjects());
  }, []);

  const handleAddMore = useCallback(
    (key: number) => {
      const currentValue = getValues(`dataReport.${key}`);
      setValue(`dataReport.${key}`, {
        ...currentValue,
        tasks: [...currentValue.tasks, { name: '', link: '' }],
      });
    },
    [setValue]
  );

  const handleOtherProject = useCallback(
    (event: any) => {
      event.preventDefault();
      append({
        projectId: '',
        tasks: [{ name: '', link: '' }],
        note: '',
        uuId: uuidv4(),
      });
      if (containerRef.current) {
        const scrollPosition = containerRef.current.scrollHeight;
        containerRef.current.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }
    },
    [append, containerRef]
  );

  const handleRemove = (keyDataReport: number, keyTask: number) => {
    const currentValue = getValues(`dataReport.${keyDataReport}`);
    const newForm = _.filter(
      currentValue?.tasks,
      (_item: any, index: any) => index !== keyTask
    );
    setValue(`dataReport.${keyDataReport}`, {
      ...currentValue,
      tasks: newForm,
    });
  };

  const onCancel = () => {
    reset();
    Utils.redirect({
      pathname: ROUTERS.DAILY_TASK,
      search: {
        screen: 'me',
      },
    });
  };

  const onSubmit = (data: any) => {
    dispatch(
      createDailyTask({
        ...data,
        date: dayjs(data.date).format('YYYY-MM-DD'),
        userId: userData?.id,
      })
    );
  };

  const _renderForm = () => {
    return (
      <Box component="form">
        <Stack>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <DatePicker
                value={field.value}
                onChange={(date: any) => field.onChange(date)}
                label={t('label.date')}
                placeholder={t('label.date')}
                sx={{ mr: 1, maxWidth: '190px' }}
                message={errors.date?.message}
                required
                disableFuture
              />
            )}
          />
        </Stack>
        {_.map(watchDataReport, (res: any, indexKey: number) => {
          return (
            <Accordion defaultExpanded sx={{ p: 2, mt: 2 }} key={res?.uuId}>
              <AccordionSummary>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 1,
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <CircleIcon sx={{ fontSize: 12, mt: '4px', mr: 1 }} />
                    <InputLabel
                      sx={{
                        fontWeight: '500',
                        color: 'black',
                        mb: 0.5,
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('label.project')} {indexKey + 1}
                    </InputLabel>
                  </Box>
                  <IconButton
                    onClick={() => remove(indexKey)}
                    sx={{
                      display: `${fields?.length <= 1 ? 'none' : 'block'}`,
                    }}
                  >
                    <CloseIcon color="error" />
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Stack>
                  <InputLabel
                    sx={{
                      fontWeight: '500',
                      color: 'black',
                      mb: 0.5,
                    }}
                  >
                    {t('label.projectName')}
                    <Typography component="span" color="error">
                      (*)
                    </Typography>
                  </InputLabel>
                  <Controller
                    name={`dataReport.${indexKey}.projectId`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        value={field.value}
                        onChange={(e: any) => field.onChange(e)}
                        placeholder={t('label.project')}
                        displayEmpty
                        renderValue={
                          field.value !== ''
                            ? undefined
                            : () => (
                                <Box sx={{ opacity: 0.5 }}>
                                  {t('label.choose')}
                                </Box>
                              )
                        }
                        error={
                          !!errors?.dataReport?.[indexKey]?.projectId?.message
                        }
                      >
                        {!_.isEmpty(projectOptions) ? (
                          _.map(
                            projectOptions,
                            (option: any, keyOption: number) => {
                              return (
                                <MenuItem
                                  key={keyOption}
                                  value={option.value}
                                  disabled={getValues('dataReport').some(
                                    (i) => option.value === i.projectId
                                  )}
                                >
                                  {option.label}
                                </MenuItem>
                              );
                            }
                          )
                        ) : (
                          <MenuItem disabled>{t('label.noOption')}</MenuItem>
                        )}
                      </Select>
                    )}
                  />
                  {errors?.dataReport && (
                    <FormHelperText
                      error={Boolean(
                        errors?.dataReport[indexKey]?.projectId?.message
                      )}
                    >
                      {errors?.dataReport[indexKey]?.projectId?.message}
                    </FormHelperText>
                  )}
                </Stack>
                <Stack my={1}>
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <InputLabel
                        sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}
                      >
                        {t('label.task')}
                      </InputLabel>
                    </AccordionSummary>
                    <AccordionDetails>
                      {_.map(res?.tasks, (item: any, index: number) => {
                        const dataIndexReport = errors?.dataReport || [];
                        const dataIndex =
                          dataIndexReport[indexKey]?.tasks || [];
                        return (
                          <Grid
                            container
                            width={1}
                            sx={{ display: 'flex' }}
                            key={index}
                          >
                            <Grid item xs={0.2}>
                              {/* <AcUnitIcon sx={{ fontSize: 25, mr: 1, mt: 1 }} /> */}
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 700,
                                }}
                              >
                                {index + 1}.
                              </Typography>
                            </Grid>
                            {_.map(
                              KEY_TASK_NAME,
                              (head: any, innerKey: number) => {
                                return (
                                  <Grid
                                    item
                                    xs={5.5}
                                    key={`row${index}-${innerKey}`}
                                    sx={{ display: 'flex' }}
                                  >
                                    <Controller
                                      name={
                                        head.value === 'link'
                                          ? `dataReport.${indexKey}.tasks.${index}.${'link'}`
                                          : `dataReport.${indexKey}.tasks.${index}.${'name'}`
                                      }
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          value={_.get(item, head.value)}
                                          onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                          ) =>
                                            field.onChange(event.target.value)
                                          }
                                          label={t(`label.${head.label}`)}
                                          placeholder={
                                            head.value === 'link'
                                              ? t('label.linkTask')
                                              : t('label.taskName')
                                          }
                                          message={
                                            dataIndex && head.value === 'link'
                                              ? dataIndex[index]?.link?.message
                                              : dataIndex[index]?.name?.message
                                          }
                                          required
                                          style={{ mr: 1 }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                );
                              }
                            )}
                            <Grid
                              item
                              xs={0.5}
                              sx={{
                                display:
                                  res?.tasks?.length <= 1 ? 'none' : 'flex',
                                alignSelf: 'flex-end',
                                alignContent: 'flex-end',
                              }}
                            >
                              <IconButton
                                sx={{
                                  mb:
                                    !!dataIndex[index]?.link?.message ||
                                    !!dataIndex[index]?.name?.message
                                      ? 4
                                      : 1,
                                }}
                                onClick={() => {
                                  handleRemove(indexKey, index);
                                }}
                              >
                                <CloseIcon color="error" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        );
                      })}
                      <Grid item xs={12} textAlign={'center'}>
                        <Button
                          variant="outlined"
                          startIcon={<AddIcon />}
                          onClick={() => handleAddMore(indexKey)}
                        >
                          {t('button.addNewTask')}
                        </Button>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
                <Stack>
                  <Controller
                    name={`dataReport.${indexKey}.note`}
                    control={control}
                    render={({ field }) => (
                      <Textarea
                        value={field.value}
                        label={t('label.note')}
                        placeholder={t('label.note')}
                        sx={{ mb: 1 }}
                        onChange={(e: any) => field.onChange(e.target.value)}
                        maxLength={500}
                        rows={4}
                      />
                    )}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Box
          sx={{
            float: 'right',
            marginTop: 5,
            mb: 2,
            justifyContent: 'space-between',
            display: 'flex',
            width: 1,
          }}
        >
          <LoadingButton
            sx={{ mr: 1 }}
            onClick={handleOtherProject}
            variant="outlined"
            startIcon={<AddIcon />}
            loading={isActionLoading}
          >
            {t('button.otherProject')}
          </LoadingButton>
          <Box>
            <LoadingButton
              sx={{ mr: 1 }}
              onClick={onCancel}
              variant="outlined"
              loading={isActionLoading}
            >
              {t('button.cancel')}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              loading={isActionLoading}
            >
              {t('button.create')}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
        ref={containerRef}
      >
        <Typography variant="h2">{t('title.createDailyTask')}</Typography>
        {_renderForm()}
      </Box>
    );
  };

  return renderMain();
};

export default CreateDailyReport;
