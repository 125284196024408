import React from 'react';
import ReactDOM from 'react-dom/client';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingWrapper } from './Loading.widget.styles';

let ROOT_ELEMENT: ReactDOM.Root | null = null;

const stop = () => {
  const emptyElemnt = React.createElement('div', {}, []);
  if (ROOT_ELEMENT) ROOT_ELEMENT.render(emptyElemnt);
};

const LoadingComponent = () => {
  return (
    <LoadingWrapper>
      <CircularProgress />
    </LoadingWrapper>
  );
};

const start = () => {
  if (!ROOT_ELEMENT)
    ROOT_ELEMENT = ReactDOM.createRoot(
      document.getElementById('alert') as HTMLElement
    );
  ROOT_ELEMENT.render(<LoadingComponent />);
};

const Loading = {
  start,
  stop,
};

export default Loading;
