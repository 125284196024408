import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@utils';
import { toast } from 'react-toastify';
import { IProjectMember } from '../Interfaces/ProjectMember.interface';

const { ACTION_TYPES } = CONSTANTS;

// SINGLE_ACTIONS
const setDefaultProjectMemberState = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_PROJECT_MEMBER_STATE,
  };
};

const setDefaultProjectMemberStatus = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_PROJECT_MEMBER_STATUS,
  };
};

const projectMemberIsRequest = () => {
  return {
    type: ACTION_TYPES.PROJECT_MEMBER_IS_REQUEST,
  };
};

// ASYNC_ACTIONS
const fetchProjectMembersSuccess = (payload: IProjectMember[]) => {
  return {
    type: ACTION_TYPES.FETCH_PROJECT_MEMBERS_SUCCESS,
    payload,
  };
};

const fetchProjectMembersFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_PROJECT_MEMBERS_FAILURE,
  };
};

const fetchProjectMembers = (id: string) => {
  return async (dispatch: any) => {
    dispatch(projectMemberIsRequest());
    await API.fetchProjectMembers(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchProjectMembersFailure());
        else {
          if (typeof result === 'object' && result !== null) {
            const resolvePayload: IProjectMember[] = result as IProjectMember[];

            dispatch(fetchProjectMembersSuccess(resolvePayload));
          }
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(fetchProjectMembersFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const fetchUnassignedProjectMembersSuccess = (payload: IProjectMember[]) => {
  return {
    type: ACTION_TYPES.FETCH_UNASSIGNED_MEMBERS_SUCCESS,
    payload,
  };
};

const fetchUnassignedProjectMembersFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_UNASSIGNED_MEMBERS_FAILURE,
  };
};

const fetchUnassignedProjectMembers = (id: string) => {
  return async (dispatch: any) => {
    dispatch(projectMemberIsRequest());
    await API.fetchUnassignedProjectMembers(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchUnassignedProjectMembersFailure());
        else {
          if (typeof result === 'object' && result !== null) {
            const resolveResult: IProjectMember[] = result as IProjectMember[];
            dispatch(fetchUnassignedProjectMembersSuccess(resolveResult));
          }
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(fetchUnassignedProjectMembersFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createProjectMemberSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_PROJECT_MEMBER_SUCCESS,
  };
};

const createProjectMemberFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_PROJECT_MEMBER_FAILURE,
  };
};

const createProjectMember = (payload: Omit<IProjectMember, 'id'>) => {
  return async (dispatch: any) => {
    dispatch(projectMemberIsRequest());
    await API.createProjectMember(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createProjectMemberFailure());
        } else {
          await dispatch(createProjectMemberSuccess());
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createProjectMemberFailure());
      });
  };
};

const updateProjectMemberSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_PROJECT_MEMBER_SUCCESS,
  };
};

const updateProjectMemberFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_PROJECT_MEMBER_FAILURE,
  };
};

const updateProjectMember = (payload: IProjectMember) => {
  return async (dispatch: any) => {
    dispatch(projectMemberIsRequest());
    await API.updateProjectMember(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateProjectMemberFailure());
        } else {
          await dispatch(updateProjectMemberSuccess());
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateProjectMemberFailure());
      });
  };
};

const getProjectMemberByIdSuccess = (payload: IProjectMember) => {
  return {
    type: ACTION_TYPES.GET_PROJECT_MEMBER_BY_ID_SUCCESS,
    payload,
  };
};

const getProjectMemberByIdFailure = () => {
  return {
    type: ACTION_TYPES.GET_PROJECT_MEMBER_BY_ID_FAILURE,
  };
};

const getProjectMemberById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(projectMemberIsRequest());
    await API.getProjectMemberById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getProjectMemberByIdFailure());
        else {
          const resolveResult: IProjectMember = result as IProjectMember;
          dispatch(getProjectMemberByIdSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(getProjectMemberByIdFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const deleteProjectMemberSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_PROJECT_MEMBER_SUCCESS,
  };
};

const deleteProjectMemberFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_PROJECT_MEMBER_FAILURE,
  };
};

const deleteProjectMember = (id: string, projectId: string) => {
  return async (dispatch: any) => {
    dispatch(projectMemberIsRequest());
    await API.deleteProjectMember(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteProjectMemberFailure());
          toast.error(message);
        } else {
          dispatch(deleteProjectMemberSuccess());
          dispatch(fetchProjectMembers(projectId));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(deleteProjectMemberFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  setDefaultProjectMemberState,
  setDefaultProjectMemberStatus,
  fetchProjectMembers,
  fetchUnassignedProjectMembers,
  createProjectMember,
  updateProjectMember,
  deleteProjectMember,
  getProjectMemberById,
};
