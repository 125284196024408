import React from 'react';
import { trim, get, forEach } from 'lodash';
import { useTranslation } from 'react-multi-lang';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { validate as uuidValidate } from 'uuid';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

import Utils from '@/Utils';
import { Alert } from '@/Widgets';
import Constants from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import {
  RoundedContainer,
  TextField,
  MarkdownEditor,
  Select,
} from '@/Components/Common';
import { ConfigSystemAction, ProjectActions } from '@/Actions';

import { IProjectResponseStructure } from '@/Interfaces/Project.interface';
import {
  IConfigSystem,
  IUpdateConfigSystem,
} from '@/Interfaces/ConfigSystem.interface';

interface IOption {
  label: string;
  value: string;
}

const { ROUTERS, MODULE_API } = Constants;
const { clearConfigSystem, updateConfigSystem, getConfigSystemByID } =
  ConfigSystemAction;
const { fetchProjectsNotConfig } = ProjectActions;

const regexDomain =
  /^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\\-]*[A-Za-z0-9])$/g;
const regexPort =
  /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/g;

const EditConfigSystem: React.FC = () => {
  /* Importing the useTranslation hook from the react-i18next library. */
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  /* Destructuring the id from the useParams hook. */
  const { id } = useParams();

  /* Using the useTypedSelector hook to get the details, isLoading, and projects from the Redux store. */
  const details: IConfigSystem = useSelector((state: RootState) =>
    get(state.CONFIG_SYSTEM, 'details')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.CONFIG_SYSTEM, 'requestIsLoading')
  );
  const projects: IProjectResponseStructure[] = useSelector(
    (state: RootState) => get(state.PROJECT, 'projectsNotConfig')
  );

  /* Checking if the user has permission to update the config system. */
  const isAcceptEdit = Utils.isValidPermission(
    MODULE_API.CONFIG_SYSTEMS.UPDATE_CONFIG_SYSTEM
  );
  const isAcceptViewDetails = Utils.isValidPermission(
    MODULE_API.CONFIG_SYSTEMS.GET_CONFIG_SYSTEM_BY_ID
  );
  /* Using the useForm hook from react-hook-form to create a form. */

  const schema = yup.object().shape(
    {
      ip: yup.string().when('ip', ([ip]) => {
        if (ip) {
          const isMatchIP = Utils.regexPayload(ip, 'ip');
          const isMatchDomain = Utils.regexPayload(ip, 'domain');
          if (isMatchIP || isMatchDomain)
            return yup.string().trim().required(t('message.ipRequired'));
          else
            return yup
              .string()
              .trim()
              .matches(regexDomain, t('message.domainOrIPInvalidFormat'))
              .required(t('message.ipRequired'));
        }
        return yup.string().trim().required(t('message.ipRequired'));
      }),
      name: yup.string().trim().required(t('message.nameRequired')),
      port: yup
        .string()
        .trim()
        .matches(regexPort, t('message.portInvalidFormat'))
        .required(t('message.portRequired')),
      project: yup.string().trim().required(t('message.projectRequired')),
      url: yup
        .string()
        .url(t('message.urlInvalidFormat'))
        .trim()
        .required(t('message.urlRequired')),
      documentation: yup.string().trim(),
      subConfig: yup.array().when('subConfig', ([subConfig]) => {
        if (subConfig && subConfig.length > 0)
          return yup.array(
            yup.object().shape({
              name: yup.string().required(t('message.nameRequired')),
              url: yup
                .string()
                .url(t('message.urlInvalidFormat'))
                .trim()
                .required(t('message.urlRequired')),
            })
          );
        return yup.array();
      }),
    },
    [
      ['ip', 'ip'],
      ['port', 'port'],
      ['subConfig', 'subConfig'],
    ]
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<IUpdateConfigSystem>({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    name: 'subConfig',
    control,
  });

  const projectDetails: IOption[] = [
    {
      label: `${details.project?.projectCode} - ${details.project?.name}`,
      value: details.project?.id,
    },
  ];

  React.useEffect(() => {
    if (!isAcceptEdit && !isAcceptViewDetails) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizedToUpdateConfigSystem'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else if (id && uuidValidate(id)) dispatch(getConfigSystemByID(id));
    return () => {
      dispatch(clearConfigSystem());
    };
  }, []);

  React.useEffect(() => {
    if (details?.id) {
      const { documentation, ip, name, port, project, subConfig, url } =
        details;
      setValue('id', details.id);
      setValue('documentation', documentation);
      setValue('ip', ip);
      setValue('name', name);
      setValue('port', port);
      setValue('project', project?.id);
      setValue('url', url);
      setValue('subConfig', subConfig);

      if (isAcceptEdit) dispatch(fetchProjectsNotConfig(project?.id));
    }
  }, [details]);

  /* Creating an array of options for the select component. */
  const projectOptions = React.useMemo(() => {
    const options: IOption[] = [];
    forEach(projects, (project: IProjectResponseStructure) =>
      options.push({
        label: `${project.projectCode} - ${project.name}`,
        value: project.id,
      })
    );
    return options;
  }, [projects]);

  /**
   * A function that is called when the form is submitted.
   * @param {any} data - The data that is passed to the form.
   */
  const onSubmit = () => {
    const newPayload = getValues();
    newPayload.subConfig.map((item) => {
      item.name = trim(item.name);
    });
    dispatch(updateConfigSystem(newPayload));
  };

  /**
   * `onAddSubConfig` is a function that takes no arguments and returns a function that takes no
   * arguments and returns nothing
   */
  const onAddSubConfig = () => {
    const newSubConfig = {
      ip: '',
      port: '',
      name: '',
      url: '',
    };
    append(newSubConfig);
  };

  /**
   * It removes a subconfiguration from the list of subconfigurations.
   * @param {number} index - The index of the sub-configuration to be deleted.
   */
  const onRemoveSubConfig = async (index: number) => {
    const isAgree = await Alert({
      type: 'WARNING',
      message: t('popup.warningBeforeRemoveSubConfig'),
    });
    if (isAgree === 'ok') remove(index);
  };

  /* The function to render the layout */
  const _renderTopSection = () => {
    const renderTitle = isAcceptEdit
      ? t('title.editConfigSystem')
      : t('title.configSystemDetails');
    return <Typography variant="h2">{renderTitle}</Typography>;
  };

  const _renderMainConfig = () => {
    return (
      <Stack direction="column">
        <Typography variant="h3" sx={{ marginBottom: 0 }}>
          {t('title.mainConfig')}
        </Typography>
        <Controller
          name="project"
          control={control}
          render={({ field }) => (
            <Select
              options={isAcceptEdit ? projectOptions : projectDetails}
              sx={{ display: 'flex', mb: 1 }}
              label={t('label.project')}
              required={!!isAcceptEdit}
              message={errors.project?.message}
              value={field.value}
              onChange={(e: any) => field.onChange(e)}
              disabled={!isAcceptEdit}
            />
          )}
        />
        <Stack direction="row" mb={1}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value?.trim()}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label={t('label.name')}
                placeholder="IDRA Website Internal"
                message={errors.name?.message}
                required={!!isAcceptEdit}
                style={{ width: '400px', flex: 'unset', mr: 1 }}
                disabled={!isAcceptEdit}
              />
            )}
          />
          <Controller
            name="ip"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label={t('label.domainOrIP')}
                placeholder="192.168.0.1"
                message={errors.ip?.message}
                required={!!isAcceptEdit}
                style={{ mr: 1 }}
                disabled={!isAcceptEdit}
              />
            )}
          />
          <Controller
            name="port"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label={t('label.port')}
                placeholder="3000"
                message={errors.port?.message}
                required={!!isAcceptEdit}
                style={{ width: '150px', flex: 'unset', mr: 1 }}
                disabled={!isAcceptEdit}
              />
            )}
          />
          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label={t('label.url')}
                placeholder="http://192.168.0.1:3000"
                message={errors.url?.message}
                required={!!isAcceptEdit}
                style={{ mr: 1 }}
                disabled={!isAcceptEdit}
              />
            )}
          />
        </Stack>
      </Stack>
    );
  };

  const _renderSubConfig = () => {
    return (
      <Stack direction="column">
        <Typography variant="h3" sx={{ marginBottom: 0 }}>
          {t('title.subConfig')}
        </Typography>
        {fields &&
          fields.map(
            (
              item: {
                id: string;
                ip: string;
                port: string;
                url: string;
                name: string;
              },
              index: number
            ) => {
              return (
                <Stack key={item.id} direction="row" mb={1}>
                  <Typography sx={{ fontWeight: '500', marginRight: 1 }}>
                    {index + 1}.
                  </Typography>
                  <Controller
                    name={`subConfig.${index}.name`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        value={field.value}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          field.onChange(event.target.value);
                        }}
                        label={t('label.name')}
                        placeholder="IDRA Website Staging"
                        message={errors.subConfig?.[index]?.name?.message}
                        required={!!isAcceptEdit}
                        style={{ width: '400px', flex: 'unset', mr: 1 }}
                        disabled={!isAcceptEdit}
                      />
                    )}
                  />
                  <Controller
                    name={`subConfig.${index}.ip`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        value={field.value}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          field.onChange(event.target.value);
                        }}
                        label={t('label.domainOrIP')}
                        placeholder="192.168.0.1"
                        style={{ mr: 1 }}
                        disabled={!isAcceptEdit}
                      />
                    )}
                  />
                  <Controller
                    name={`subConfig.${index}.port`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        value={field.value}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          field.onChange(event.target.value);
                        }}
                        label={t('label.port')}
                        placeholder="3000"
                        style={{ width: '150px', flex: 'unset', mr: 1 }}
                        disabled={!isAcceptEdit}
                      />
                    )}
                  />
                  <Controller
                    name={`subConfig.${index}.url`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        value={field.value}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          field.onChange(event.target.value);
                        }}
                        label={t('label.url')}
                        placeholder="http://192.168.0.1:3000"
                        message={errors.subConfig?.[index]?.url?.message}
                        required={!!isAcceptEdit}
                        style={{ mr: 1 }}
                        disabled={!isAcceptEdit}
                      />
                    )}
                  />
                  <Tooltip title={t('tooltip.clickToRemoveThisSubConfig')}>
                    <span>
                      <IconButton
                        sx={{ mt: '26px' }}
                        onClick={() => onRemoveSubConfig(index)}
                        disabled={!isAcceptEdit}
                      >
                        <RemoveCircleOutlineOutlinedIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Stack>
              );
            }
          )}
        {isAcceptEdit && (
          <Button
            variant="contained"
            sx={{ width: '100%', maxWidth: '300px' }}
            onClick={() => onAddSubConfig()}
          >
            {t('button.add')}
          </Button>
        )}
      </Stack>
    );
  };

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          {_renderMainConfig()}
          {_renderSubConfig()}
          <Controller
            name="documentation"
            control={control}
            render={({ field }) => (
              <MarkdownEditor
                value={field.value}
                onChange={(event: any) => field.onChange(event)}
                label={t('label.documentation')}
                sx={{ mt: 1 }}
                disabled={!isAcceptEdit}
              />
            )}
          />

          <Stack direction="row" justifyContent="flex-end" sx={{ mt: '50px' }}>
            <LoadingButton
              onClick={() => Utils.redirect(ROUTERS.CONFIG_SYSTEM)}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              {t('button.cancel')}
            </LoadingButton>
            {isAcceptEdit && (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                {t('button.save')}
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </RoundedContainer>
    );
  };

  return (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default EditConfigSystem;
