import React from 'react';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import { useTranslation } from 'react-multi-lang';
import {
  Checkbox,
  FormHelperText,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';

import Utils from '@/Utils';
import { StickyColumnTable } from '../../Common/Table';

import { IPermissionStructure } from '@/Interfaces/Role.interface';

interface ISectionProps {
  payload: any;
  acceptApi: any;
  callback(checkedPermissions: string[]): void;
  message?: string;
  label?: string;
  required?: boolean;
  sx?: object;
  disabledRows?: string[];
}

const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (_value, index: number) => start + index * step
  );

const PermissionsDataTable: React.FC<ISectionProps> = ({
  payload,
  acceptApi,
  callback,
  message = '',
  required = false,
  label = '',
  sx = {},
  disabledRows,
}) => {
  const t = useTranslation();
  const language = Utils.getSavedLocale();
  const [rows, setRows] = React.useState<any[]>([]);
  const [columns, setColumns] = React.useState<any[]>([]);

  const onCheck = (permission: string) => {
    const isExist = includes(acceptApi, permission);
    const resolveAcceptApi: string[] = [];
    if (isExist) {
      const filterPermissions = filter(
        acceptApi,
        (item: string) => item !== permission
      );
      resolveAcceptApi.push(...filterPermissions);
    } else resolveAcceptApi.push(...acceptApi, permission);
    callback(resolveAcceptApi);
  };

  React.useEffect(() => {
    let totalColumns = 0;
    if (payload) {
      for (const item of payload) {
        if (!item) break;
        const { permissions } = item;
        if (totalColumns < permissions.length && permissions)
          totalColumns = permissions.length;
      }
    }
    const rangeColumns = arrayRange(1, totalColumns, 1);
    const resolveColumns: any[] = [
      {
        id: 'module',
        label: t('table.module'),
        minWidth: 5,
        align: 'left',
      },
    ];
    for (const key of rangeColumns) {
      resolveColumns.push({
        id: `permission-${key}`,
        label: `${t('table.permission')} ${key}`,
        minWidth: 35,
        align: 'left',
      });
    }
    setColumns(resolveColumns);
  }, [payload]);

  React.useEffect(() => {
    const resolveRows: any[] = [];
    const columnsLength = columns.length;
    const rangeColumns = arrayRange(1, columnsLength, 1);
    if (!isEmpty(payload) && columnsLength > 0) {
      forEach(payload, (item: IPermissionStructure) => {
        if (!item) return;
        const { labelEN, labelVI, permissions, key } = item;
        const isDisabled = disabledRows?.includes(key);

        const resolveLabel = language === 'en' ? labelEN : labelVI;

        const row = {
          module: resolveLabel,
        };
        for (const colKey of rangeColumns) {
          const currentPermission = permissions[colKey - 1];
          Object.assign(row, {
            [`permission-${colKey}`]: currentPermission ? (
              <Stack direction="row" key={`permission-${labelEN}-${colKey}`}>
                <Stack
                  direction="row"
                  onClick={() => !isDisabled && onCheck(currentPermission.api)}
                  alignItems="center"
                  sx={{
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Checkbox
                    checked={includes(acceptApi, currentPermission.api)}
                    size="small"
                    disableRipple
                    disabled={isDisabled}
                    sx={{ p: 0, mr: 1 }}
                  />
                  {language === 'en'
                    ? currentPermission.nameEN
                    : currentPermission.nameVI}
                </Stack>
              </Stack>
            ) : null,
          });
        }
        resolveRows.push(row);
      });
    }
    setRows(resolveRows);
  }, [payload, acceptApi, columns]);

  return (
    <Stack direction="column" sx={{ flex: 1, ...sx }}>
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}{' '}
          {required ? (
            <Typography component="span" color="error">
              (*)
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      <StickyColumnTable columns={columns} rows={rows} />
      {message ? (
        <FormHelperText error={Boolean(message)}>{message}</FormHelperText>
      ) : null}
    </Stack>
  );
};

export default PermissionsDataTable;
