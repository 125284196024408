import React from 'react';
import Select, {
  components,
  OptionProps,
  PlaceholderProps,
} from 'react-select';
import DoneIcon from '@mui/icons-material/Done';
import { FormHelperText, InputLabel, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-multi-lang';

interface IOption {
  label: string;
  value: string;
  color?: string;
}

interface IProps {
  label?: string;
  required?: boolean;
  placeholder?: string;
  message?: string;
  options: IOption[];
  onChange(newValue: string): void;
  value: string;
  sx?: object;
  onCreate?(payload: any): void;
  dropdownSx?: object;
  disabled?: boolean;
}

const SelectComponent: React.FC<IProps> = ({
  label,
  required = false,
  placeholder = '',
  message = '',
  value = [],
  onChange,
  options,
  sx = {},
  dropdownSx = {},
  disabled = false,
}) => {
  const t = useTranslation();
  const [currentValue, setCurrentValue] = React.useState<any>([]);
  const [inputValue, setInputValue] = React.useState<string>('');
  const selectRef = React.useRef<any | null>(null);

  React.useEffect(() => {
    if (options) {
      const findOptions = options.find((item: IOption) => item.value === value);
      if (findOptions) setCurrentValue(findOptions);
    }
  }, [options, value]);

  const handleChange = (newValue: any) => {
    setCurrentValue(newValue);
    if (onChange) onChange(newValue.value);
  };

  const NoOptionsMessage = () => {
    return (
      <Stack
        direction="row"
        sx={{ alignItems: 'center', height: '38px', padding: '5px 10px' }}
      >
        <Typography sx={{ fontSize: '15px' }}>
          {inputValue ? inputValue : t('label.noOptions')}
        </Typography>
      </Stack>
    );
  };

  const Placeholder = (props: PlaceholderProps<IOption>) => {
    return (
      <components.Placeholder {...props}>
        {placeholder || 'Choose'}
      </components.Placeholder>
    );
  };

  const CustomOption = (payload: OptionProps<IOption>) => {
    if (payload.isDisabled) return null;
    return (
      <div {...payload.innerProps}>
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '38px',
            padding: '5px 10px',
            ':hover': {
              cursor: 'pointer',
              background: 'rgba(9,30,66,.08)',
            },
          }}
        >
          <Typography sx={{ fontWeight: payload.isSelected ? 500 : 400 }}>
            {payload.children}
          </Typography>
          {payload.isSelected ? (
            <DoneIcon
              sx={{
                width: '20px',
                height: '20px',
                color: '#42526e',
              }}
            />
          ) : null}
        </Stack>
      </div>
    );
  };

  return (
    <Stack direction="column" sx={{ flex: 1, ...sx }}>
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}{' '}
          {required ? (
            <Typography component="span" color="error">
              (*)
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      <Select
        closeMenuOnSelect={true}
        components={{
          Placeholder,
          NoOptionsMessage,
          Option: CustomOption,
        }}
        isMulti={false}
        value={currentValue}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        onInputChange={(newValue: string) => setInputValue(newValue)}
        ref={selectRef}
        hideSelectedOptions={false}
        isDisabled={disabled}
        styles={{
          container: (base: any) => ({
            ...base,
            width: '100%',
            ...dropdownSx,
          }),
          control: (base: any, state: any) => {
            const errorColor = Boolean(message)
              ? '0 0 0 1px #d32f2f'
              : '0 0 0 1px #2684ff';
            const elementColor = state.isFocused ? errorColor : 'transparent';
            return {
              ...base,
              height: '40px',
              width: '100%',
              borderColor: Boolean(message) ? '#d32f2f' : 'hsl(0, 0%, 80%)',
              boxShadow: elementColor,
              '&:hover': {
                borderColor: Boolean(message) ? '#d32f2f' : 'hsl(0, 0%, 80%)',
                boxShadow: elementColor,
              },
            };
          },
          singleValue: (base: any) => ({
            ...base,
            marginLeft: '5px',
          }),
          placeholder: (base: any) => ({
            ...base,
            marginLeft: '5px',
            color: '#A2A2A2',
          }),
          menu: (base: any) => ({
            ...base,
            zIndex: 99,
          }),
          valueContainer: (base: any) => ({
            ...base,
            flexWrap: 'nowrap',
          }),
        }}
      />
      {message ? (
        <FormHelperText error={Boolean(message)}>{message}</FormHelperText>
      ) : null}
    </Stack>
  );
};

export default SelectComponent;
