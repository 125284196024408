import { Box, Container, Typography } from '@mui/material';

import React from 'react';
import NoData from '../../../Assets/Images/NoData.gif';

interface INoDataWereFound {
  height?: number;
}

const NoDataWereFound: React.FC<INoDataWereFound> = ({
  height,
}: INoDataWereFound) => {
  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ height: height || 500 }}>
        <Box
          sx={{ height: 300 }}
          component="img"
          src={NoData}
          alt="loading..."
        />
        <Typography textAlign="center" variant="subtitle2">
          No data were found
        </Typography>
      </Box>
    </Container>
  );
};

export default NoDataWereFound;
