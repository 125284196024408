import * as React from 'react';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Stack, Typography, IconButton, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import { Alert } from '@/Widgets';
import { PermissionActions, UserActions } from '@/Actions';
import { Select } from '../../Common';
import Constants from '@/Constants';
import PopupLayout from '../PopupLayout';

interface SectionProps {
  open: boolean;
  onClose(isFetch?: boolean): void;
  filterPayload: any;
}

interface IColumn {
  labelEN: string;
  labelVI: string;
  value: string;
  isChecked: boolean;
  index: number;
}

const { resetUserStatus, exportUsers } = UserActions;
const { fetchRoles, setDefaultReducerPermission } = PermissionActions;

const exportOptionColumns: IColumn[] = [
  {
    labelEN: 'Full name',
    labelVI: 'Họ và tên',
    value: 'fullName',
    isChecked: true,
    index: 0,
  },
  {
    labelEN: 'Email',
    labelVI: 'Thư điện tử',
    value: 'email',
    isChecked: true,
    index: 1,
  },
  {
    labelEN: 'Role',
    labelVI: 'Vai trò',
    value: 'role',
    isChecked: true,
    index: 2,
  },
  {
    labelEN: 'Staff Code',
    labelVI: 'Mã nhân viên',
    value: 'staffCode',
    isChecked: true,
    index: 3,
  },
  {
    labelEN: 'Birthday',
    labelVI: 'Ngày sinh nhật',
    value: 'birthday',
    isChecked: true,
    index: 4,
  },
  {
    labelEN: 'Permanent Address',
    labelVI: 'Địa chỉ thường trú',
    value: 'permanentAddress',
    isChecked: true,
    index: 5,
  },
  {
    labelEN: 'Current Address',
    labelVI: 'Địa chỉ hiện tại',
    value: 'currentAddress',
    isChecked: true,
    index: 6,
  },
  {
    labelEN: 'Identify Card Number',
    labelVI: 'Số CMND/CCCD',
    value: 'identifyCardNumber',
    isChecked: true,
    index: 7,
  },
  {
    labelEN: 'Issued on',
    labelVI: 'Cấp ngày',
    value: 'issuedOn',
    isChecked: true,
    index: 8,
  },
  {
    labelEN: 'Place of Issue',
    labelVI: 'Cấp tại',
    value: 'placeOfIssue',
    isChecked: true,
    index: 9,
  },
  {
    labelEN: 'Bank name',
    labelVI: 'Tên ngân hàng',
    value: 'bankName',
    isChecked: true,
    index: 10,
  },
  {
    labelEN: 'Bank branch',
    labelVI: 'Chi nhánh ngân hàng',
    value: 'bankBranch',
    isChecked: true,
    index: 11,
  },
  {
    labelEN: 'Bank account number',
    labelVI: 'Số tài khoản',
    value: 'bankAccountNumber',
    isChecked: true,
    index: 12,
  },
  {
    labelEN: 'Social Insurance Number',
    labelVI: 'Số bảo hiểm xã hội',
    value: 'socialInsuranceNumber',
    isChecked: true,
    index: 13,
  },
  {
    labelEN: 'Personal income tax code',
    labelVI: 'Mã số thuế thu nhập cá nhân',
    value: 'personalIncomeTaxCode',
    isChecked: true,
    index: 14,
  },
  {
    labelEN: 'On board date',
    labelVI: 'Ngày ký làm việc chính thức',
    value: 'onBoardDate',
    isChecked: true,
    index: 15,
  },
  {
    labelEN: 'Work status',
    labelVI: 'Tình trạng công việc',
    value: 'workStatus',
    isChecked: true,
    index: 16,
  },
  {
    labelEN: 'Position',
    labelVI: 'Vị trí',
    value: 'position',
    isChecked: true,
    index: 17,
  },
];

const languageOptions = [
  { label: 'English', value: 'labelEN' },
  { label: 'Vietnamese', value: 'labelVI' },
];

const { MODULE_API } = Constants;

const ExportUserPopup: React.FC<SectionProps> = ({
  open,
  onClose,
  filterPayload,
}) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const selectingLanguage = Utils.getSavedLocale();

  const exportUserIsSuccess: boolean = useSelector((state: RootState) =>
    get(state.USER, 'exportUserIsSuccess')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.USER, 'requestIsLoading')
  );

  const [selectedColumns, setSelectedColumns] =
    React.useState<IColumn[]>(exportOptionColumns);
  const [msg, setMsg] = React.useState<string>('');
  const [language, setLanguage] = React.useState<string>('labelEN');

  React.useEffect(() => {
    dispatch(fetchRoles());
    return () => {
      dispatch(setDefaultReducerPermission());
    };
  }, []);

  React.useEffect(() => {
    if (exportUserIsSuccess) {
      dispatch(resetUserStatus());
      onClose();
    }
  }, [exportUserIsSuccess]);

  React.useEffect(() => {
    if (open) {
      const isAcceptApi = Utils.isValidPermission(MODULE_API.USER.CREATE_USER);
      if (!isAcceptApi) {
        onClose();
        Alert({
          type: 'ERROR',
          message: t('popup.notAuthorizeForExportUsers'),
        });
      }
    } else {
      setSelectedColumns(exportOptionColumns);
      setMsg('');
    }
  }, [open]);

  const onCancel = () => {
    dispatch(resetUserStatus());
    onClose();
  };

  const validateForm = () => {
    const totalCheckedColumns = selectedColumns.filter(
      (item: IColumn) => item.isChecked
    ).length;

    return totalCheckedColumns >= 3;
  };

  const onSubmit = () => {
    const isValid = validateForm();
    if (!isValid) setMsg(t('message.selectedLeastAt3Columns'));
    else {
      dispatch(
        exportUsers({ ...filterPayload, columns: selectedColumns, language })
      );
    }
  };

  const onCheck = (item: IColumn) => {
    const resolveSelectedColumns: IColumn[] = [];
    forEach(selectedColumns, (column: IColumn) => {
      if (item.value === column.value)
        resolveSelectedColumns.push({
          ...column,
          isChecked: !column.isChecked,
        });
      else resolveSelectedColumns.push(column);
    });
    if (msg) setMsg('');
    const sortSelectedColumn = resolveSelectedColumns.sort(
      (a: IColumn, b: IColumn) =>
        a.isChecked === b.isChecked ? 0 : a.isChecked ? -1 : 1
    );

    setSelectedColumns(sortSelectedColumn);
  };

  const onMoveUp = (index: number) => {
    const cloneColumns: IColumn[] = [...selectedColumns];
    [cloneColumns[index], cloneColumns[index - 1]] = [
      cloneColumns[index - 1],
      cloneColumns[index],
    ];

    setSelectedColumns(cloneColumns);
  };

  const onMoveDown = (index: number) => {
    const cloneColumns: IColumn[] = [...selectedColumns];
    [cloneColumns[index], cloneColumns[index + 1]] = [
      cloneColumns[index + 1],
      cloneColumns[index],
    ];

    setSelectedColumns(cloneColumns);
  };

  const _renderForm = () => {
    return (
      <form
        style={{
          width: '100%',
          marginTop: '30px',
          overflow: 'auto',
        }}
      >
        <Select
          sx={{ mb: 2 }}
          label={t('label.language')}
          value={language}
          options={languageOptions}
          onChange={(e: any) => setLanguage(e)}
        />
        <Stack
          sx={{ position: 'relative', maxHeight: '50vh', overflowY: 'scroll' }}
          direction="column"
        >
          {selectedColumns.map((item: IColumn, index: number) => {
            const isFirstItem = index === 0;
            const isLastItem = index === exportOptionColumns.length - 1;
            const label =
              selectingLanguage === 'en' ? item.labelEN : item.labelVI;
            return (
              <Stack
                direction="row"
                alignItems="center"
                key={`item-${item.value}`}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  flex={1}
                  onClick={() => onCheck(item)}
                  sx={{
                    userSelect: 'none',
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography sx={{ width: '20px' }}>{index + 1}.</Typography>
                  <Checkbox checked={item.isChecked} />
                  <Typography>{label}</Typography>
                </Stack>
                <Stack direction="row">
                  <IconButton
                    disabled={!item.isChecked || isFirstItem}
                    onClick={() => onMoveUp(index)}
                  >
                    <KeyboardArrowUpIcon />
                  </IconButton>
                  <IconButton
                    disabled={!item.isChecked || isLastItem}
                    onClick={() => onMoveDown(index)}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
        {msg ? (
          <Typography sx={{ fontSize: '15px', mt: 1, color: '#e53e3e' }}>
            {msg}
          </Typography>
        ) : null}
        <Stack sx={{ float: 'right', marginTop: 5 }} direction="row">
          <LoadingButton sx={{ mr: 1 }} onClick={onCancel}>
            {t('button.cancel')}
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={onSubmit}
          >
            {t('button.export')}
          </LoadingButton>
        </Stack>
      </form>
    );
  };

  return (
    <PopupLayout
      title={t('title.exportUsers')}
      content={_renderForm()}
      open={open}
      onClose={onClose}
    />
  );
};

export default ExportUserPopup;
