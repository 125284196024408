import React, { useEffect } from 'react';
// import Utils from '@/Utils';
import {
  Box,
  Button,
  Card,
  Grid,
  Pagination,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import { NewsActions } from '@/Actions';
import { NewsLoadding } from '@/Components/LayoutPart/NewsLoading';
import { NoDataWereFound } from '@/Components/Common';
import Constants from '@/Constants';

const { ROUTERS } = Constants;

const { fetchNews } = NewsActions;

interface dataNews {
  id: string;
  title: string;
  image: any;
  location: string;
  publishAt: string;
  content: string;
}

const NewsList: React.FC = () => {
  const dispatch = useTypedDispatch();
  const payload = useSelector((state: RootState) =>
    _.get(state.NEWS, 'payload')
  );
  const isLoading = useSelector((state: RootState) =>
    _.get(state.NEWS, 'requestIsLoading')
  );
  const meta = useSelector((state: RootState) => _.get(state.NEWS, 'meta'));

  const count =
    Math.ceil(meta?.totalItems || 0 / (meta?.itemsPerPage || 0)) || 0;

  const handleChange = (_e: any, newPage: number) => {
    dispatch(fetchNews({ page: newPage, limit: 12, status: 'publish' }));
    window.scrollTo({
      top: 300,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    dispatch(fetchNews({ page: 1, limit: 12, status: 'publish' }));
  }, []);

  const _renderCardNewsContent = () => {
    return (
      <Box>
        {isLoading ? (
          <Grid container mt={4} spacing={2}>
            {_.map(new Array(12), (_i: any, index: number) => {
              return (
                <Grid key={index} height="350px" item xs={12} sm={6} md={4}>
                  <NewsLoadding />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid container spacing={4}>
            {_.map(payload, (item: dataNews, index: number) => {
              const text = JSON.parse(item?.content);
              const createdTime = dayjs(item?.publishAt);
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    '&:hover': {
                      '& .title_card_content': { color: '#4158d0' },
                      '& .btn_view_artical_content': {
                        color: '#4158d0',
                        border: '1px solid #4158d0',
                      },
                    },
                  }}
                >
                  <Card
                    sx={{
                      cursor: 'pointer',
                      transition: '.4s transform linear, .2s box-shadow linear',
                      borderRadius: '15px',
                      ':hover': {
                        boxShadow: '0px 8px 14px -4px rgba(0,0,0,0.2)',
                        transform: 'translate(0px, -3px)',
                      },
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: 1,
                        borderRadius: '15px',
                        height: 215,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                      }}
                      src={item?.image?.path}
                    />
                    <Box p={2}>
                      <Tooltip title={item?.title}>
                        <Typography
                          className="title_card_content"
                          sx={{
                            fontWeight: 700,
                            fontSize: 25,
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item?.title}
                        </Typography>
                      </Tooltip>
                      <Typography
                        sx={{
                          color: '#808080',
                          fontSize: 16,
                        }}
                      >
                        {createdTime
                          ? createdTime.format('MMMM D, YYYY')
                          : 'N/I'}
                      </Typography>
                      <Box
                        sx={{
                          display: '-webkit-box',
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden !important',
                          textOverflow: 'ellipsis',
                          fontSize: 18,

                          '& p': {
                            my: 0,
                          },
                          '& p:not(:first-child)': {
                            display: 'none',
                          },
                        }}
                        component="div"
                        dangerouslySetInnerHTML={Utils.createMarkup(text)}
                      />
                    </Box>
                    <Box p={2} textAlign="right">
                      <Button
                        variant="outlined"
                        endIcon={<ArrowForwardIcon />}
                        onClick={() => {
                          Utils.redirect({
                            pathname: ROUTERS.NEWS_DETAIL,
                            id: item.id,
                          });
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                          });
                        }}
                        className="btn_view_artical_content"
                      >
                        VIEW ARTICAL
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    );
  };

  const _renderPagination = () => {
    return (
      <Box>
        <Pagination
          count={count}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Box sx={{ px: 2, m: 'auto' }}>
        <Box mt={6}>
          {!isLoading && _.isEmpty(payload) && <NoDataWereFound />}
          <Box>{_renderCardNewsContent()}</Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}
            width={1}
          >
            {!_.isEmpty(payload) && <Box> {_renderPagination()}</Box>}
          </Box>
        </Box>
      </Box>
    );
  };

  return renderMain();
};

export default NewsList;
