import _ from 'lodash';
import Utils from '@utils';
import CONSTANTS from '@/Constants';
import API from '@/APIs';
import {
  ForgotPasswordStructure,
  IChangePassword,
  LoginStructure,
} from '@/Interfaces/Auth.interface';
import { Role } from '../Constants/Enums';
import { Alert } from '../Widgets';
import { persistor } from '../store';

const { ROUTERS, ACTION_TYPES } = CONSTANTS;

// SINGLE ACTIONS
const authIsRequest = () => {
  return {
    type: ACTION_TYPES.AUTH_IS_REQUEST,
  };
};

const setLogged = () => {
  return {
    type: ACTION_TYPES.LOGOUT,
  };
};

const logout = () => {
  return (dispatch: any) => {
    dispatch(setLogged());
    Utils.clearAllSavedData();
    persistor.purge();
    Utils.redirect(ROUTERS.AUTH);
  };
};

const clearAuthMessage = () => {
  return {
    type: ACTION_TYPES.CLEAR_AUTH_MESSAGE,
  };
};

// ASYNC ACTIONS
const loginSuccess = (
  payload:
    | Role.ADMIN
    | Role.EMPLOYEE
    | Role.HUMAN_RESOURCES
    | Role.LEADER
    | Role.OFFICE_MANAGER
) => {
  return {
    type: ACTION_TYPES.LOGIN_SUCCESS,
    payload,
  };
};

const loginFail = (payload: string) => {
  return {
    type: ACTION_TYPES.LOGIN_FAILURE,
    payload,
  };
};

const login = (payload: LoginStructure, redirectPath: string | null) => {
  return async (dispatch: any) => {
    dispatch(clearAuthMessage());
    dispatch(authIsRequest());
    await API.login(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) {
          const message = response?.data?.message;
          await dispatch(loginFail(message));
          await Alert({ type: 'ERROR', message });
        } else {
          const token = _.get(result, 'accessToken.token');
          const userPayload = _.get(result, 'user');
          const role = _.get(userPayload, 'role.roleCode');
          Utils.saveToken(token);
          Utils.saveUserData(userPayload);
          if (redirectPath) {
            Utils.redirect(redirectPath);
          } else Utils.redirect(ROUTERS.DASHBOARD);
          dispatch(loginSuccess(role));
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(
          loginFail('The server is having problems, please try again later!')
        );
      });
  };
};

const sendRecoveryLinkFail = (payload: string) => {
  return {
    type: ACTION_TYPES.SEND_RECOVERY_LINK_FAILURE,
    payload,
  };
};

const sendRecoveryLinkSuccess = (payload: string) => {
  return {
    type: ACTION_TYPES.SEND_RECOVERY_LINK_SUCCESS,
    payload,
  };
};

const sendRecoveryLink = (payload: ForgotPasswordStructure) => {
  return async (dispatch: any) => {
    dispatch(clearAuthMessage());
    dispatch(authIsRequest());
    await API.sendRecoveryLink(payload)
      .then(async (response: any) => {
        const message = response?.data?.message;
        const status = response?.data.status;
        if (!status) {
          Alert({ type: 'ERROR', message });
          await dispatch(sendRecoveryLinkFail(message));
        } else {
          await dispatch(sendRecoveryLinkSuccess('success'));
          const isAgree = await Alert({ type: 'SUCCESS', message });
          if (isAgree === 'ok')
            Utils.redirect({
              pathname: ROUTERS.AUTH,
              search: { form: 'sign-in' },
            });
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(
          sendRecoveryLinkFail(
            'The server is having problems, please try again later!'
          )
        );
      });
  };
};

const changePasswordFail = (payload: string) => {
  return {
    type: ACTION_TYPES.CHANGE_PASSWORD_FAILURE,
    payload,
  };
};

const changePasswordSuccess = (payload: string) => {
  return {
    type: ACTION_TYPES.CHANGE_PASSWORD_SUCCESS,
    payload,
  };
};

const changePassword = (payload: IChangePassword) => {
  return async (dispatch: any) => {
    dispatch(authIsRequest());
    await API.enterPassword(payload)
      .then(async (response: any) => {
        const message = response?.data?.message;
        const status = response?.data.status;
        if (!status) {
          Alert({ type: 'ERROR', message });
          await dispatch(changePasswordFail(message));
        } else {
          await dispatch(changePasswordSuccess('success'));
          const isAgree = await Alert({ type: 'SUCCESS', message });
          if (isAgree === 'ok')
            Utils.redirect({
              pathname: ROUTERS.AUTH,
              search: { form: 'sign-in' },
            });
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(
          changePasswordFail(
            'The server is having problems, please try again later!'
          )
        );
      });
  };
};

export default {
  login,
  logout,
  sendRecoveryLink,
  clearAuthMessage,
  changePassword,
};
