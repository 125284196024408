/* eslint-disable prettier/prettier */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-multi-lang';
import { Controller, useForm } from 'react-hook-form';

import { DateRange } from 'mui-daterange-picker';
import {
  Button,
  Typography,
  Stack,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
  TextField,
  InputLabel,
} from '@mui/material';

import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

import DateRangePicker from '@/Components/Popup/DateRangePickerPopup';

import {
  RoundedContainer,
  AutocompleteSelect,
  Dropdown,
} from '@/Components/Common';

import DailyReportTable from './DailyTaskTable';

import { UserActions, ProjectActions, DailyTaskActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import Utils from '@/Utils';
import Constants from '@/Constants';

import { IUserDetailsStructure } from '@/Interfaces/User.interface';
import { IProjectResponseStructure } from '@/Interfaces/Project.interface';
import { IDailyTaskResponse } from '@/Interfaces/DailyTask.interface';

const { fetchUser } = UserActions;
const { fetchProjects } = ProjectActions;
const { fetchDailyTasks } = DailyTaskActions;

const { MODULE_API } = Constants;

interface IOption {
  label: string;
  value: string;
}

const initialFilter = {
  keyword: '',
  page: 0,
  limit: 0,
  status: '',
  projectId: '',
  userIds: [],
};

interface ISectionProps {
  onScreenChange(newValue: string): void;
}

const endDate = dayjs();
const startDate = endDate;

const DailyTaskList: React.FC<ISectionProps> = ({ onScreenChange }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const users: IUserDetailsStructure[] = useSelector((state: RootState) =>
    _.get(state.USER, 'payload')
  );
  const projects: IProjectResponseStructure[] = useSelector(
    (state: RootState) => _.get(state.PROJECT, 'payload')
  );
  const dailyTasks: IDailyTaskResponse[] = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'payload')
  );
  const pagination = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'pagination')
  );

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'isFetchLoading')
  );

  const [isOpenPicker, setIsOpenPicker] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
  });

  const [isSelectedAllUsers, setIsSelectedAllUsers] = useState<boolean>(false);

  const { control, setValue, watch, handleSubmit, reset } = useForm({
    defaultValues: initialFilter,
  });

  const isAcceptGetMyReport = Utils.isValidPermission(MODULE_API.DAILY_TASK.MY);

  const watchUsers = watch('userIds');

  const userOptions = React.useMemo(() => {
    const options: IOption[] = [];
    _.forEach(users, (user: IUserDetailsStructure) =>
      options.push({
        label: user.userData.fullName,
        value: user.id,
      })
    );
    return options;
  }, [users]);

  const projectOptions = React.useMemo(() => {
    const options: IOption[] = [
      {
        label: t('label.allProjects'),
        value: 'allType',
      },
    ];
    _.forEach(projects, (project: IProjectResponseStructure) =>
      options.push({
        label: project.name,
        value: project.id,
      })
    );
    return options;
  }, [projects]);

  const resolvedPayload = [...dailyTasks].sort((a: any, b: any) => {
    const dateComparison = a.date.localeCompare(b.date);
    if (dateComparison === 0) {
      return a?.user?.id.localeCompare(b?.createdBy?.id);
    }
    return dateComparison;
  });

  useEffect(() => {
    dispatch(
      fetchDailyTasks({
        page: 0,
        limit: 0,
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
      })
    );
    dispatch(
      fetchUser({
        page: 0,
        limit: 0,
      })
    );
    dispatch(
      fetchProjects({
        page: 0,
        limit: 0,
        startDate: null,
      })
    );
  }, []);

  const handleSelectAllUsers = (event: any) => {
    setIsSelectedAllUsers(event.target.checked);
    setValue(
      'userIds',
      event.target.checked ? _.compact(_.map(users, 'id')) : []
    );
  };

  const handleReset = () => {
    setDateRange({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
    dispatch(
      fetchDailyTasks({
        page: 0,
        limit: 0,
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
      })
    );

    reset();
  };

  const onSubmit = (data: any) => {
    dispatch(
      fetchDailyTasks({
        ...pagination,
        ...data,
        keyword: _.trim(data.keyword),
        startDate: dayjs(dateRange.startDate).isValid()
          ? dayjs(dateRange.startDate).format('YYYY-MM-DD')
          : '',
        endDate: dayjs(dateRange.endDate).isValid()
          ? dayjs(dateRange.endDate).format('YYYY-MM-DD')
          : '',
      })
    );
  };

  const _renderTopSection = () => (
    <RoundedContainer>
      <Stack sx={{ mb: 2 }} direction="column">
        <Stack>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <InputLabel>
                  {!_.isEmpty(dateRange)
                    ? `${dayjs(dateRange.startDate).format('DD-MM-YYYY')} - ${dayjs(dateRange.endDate).format('DD-MM-YYYY')}`
                    : 'No dates have been selected'}
                </InputLabel>

                <IconButton
                  disabled={isFetchLoading}
                  onClick={() => setIsOpenPicker(true)}
                >
                  <AccessAlarmIcon />
                </IconButton>
              </Box>
              <Controller
                name="keyword"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={isFetchLoading}
                    value={field.value}
                    size="small"
                    onChange={(e: any) => field.onChange(e.target.value)}
                    placeholder={t('placeholder.keyword')}
                    sx={{ mr: 1, maxWidth: '190px' }}
                  />
                )}
              />
              <Controller
                name="projectId"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    disabled={isFetchLoading || _.isEmpty(projects)}
                    sx={{ mr: 1, width: '190px' }}
                    value={field.value || 'allType'}
                    placeholder="Project"
                    options={projectOptions}
                    onChange={(e: any) => field.onChange(e)}
                  />
                )}
              />
              <Button
                variant="contained"
                disabled={isFetchLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t('button.filter')}
              </Button>
              <Button
                variant="outlined"
                disabled={isFetchLoading}
                onClick={handleReset}
              >
                {t('button.reset')}
              </Button>
            </Box>
            {isAcceptGetMyReport && (
              <Box>
                <Button
                  variant="outlined"
                  disabled={isFetchLoading}
                  sx={{ mr: 1 }}
                  onClick={() => onScreenChange('me')}
                >
                  {t('button.myReport')}
                </Button>
              </Box>
            )}
          </Box>
          <Controller
            name="userIds"
            control={control}
            render={({ field }) => (
              <AutocompleteSelect
                sx={{
                  width: 1,
                  mt: 2,
                  '.autocomplete-label > div': {
                    color: 'gray',
                  },
                }}
                value={field.value}
                options={userOptions}
                onChange={(newValue) => field.onChange(newValue)}
                label={t('label.usersList')}
              />
            )}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={
                  isSelectedAllUsers &&
                  _.size(userOptions) === _.size(watchUsers)
                }
                sx={{
                  pointerEvents: 'none',
                  fontSize: 12,
                }}
              />
            }
            disabled={_.isEmpty(users)}
            label={
              <Typography variant="subtitle1">
                {!isSelectedAllUsers ||
                _.size(userOptions) !== _.size(watchUsers)
                  ? t('label.selectAllUsers')
                  : t('label.unSelectAllUsers')}
              </Typography>
            }
            onChange={handleSelectAllUsers}
          />
          <DateRangePicker
            open={isOpenPicker}
            onClose={() => setIsOpenPicker(!isOpenPicker)}
            callback={(newValue) => setDateRange(newValue)}
            payload={dateRange}
          />
        </Stack>
      </Stack>
    </RoundedContainer>
  );

  const _renderBottomSection = () => (
    <RoundedContainer>
      <DailyReportTable payload={resolvedPayload} />
    </RoundedContainer>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {_renderTopSection()}
      {_renderBottomSection()}
    </Box>
  );
};

export default DailyTaskList;
