import React from 'react';
import { useTranslation } from 'react-multi-lang';

import { Box, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
  const t = useTranslation();
  return (
    <Box
      component="div"
      sx={{
        background: '#E7ECEF',
        color: '#202c39',
        fontSize: '12px',
        padding: '10px 0',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Typography align="center" sx={{ fontSize: '13px' }}>
        {t('tooltip.designedAndDevelopedBy')}
        <Link
          href="https://flow-idra.com/"
          target="_blank"
          sx={{ fontSize: '13px', fontWeight: 'bold', color: 'inherit' }}
        >
          FLOW-IDRA
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
