import React from 'react';
import assign from 'lodash/assign';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';

import Utils from '@/Utils';
import { Alert } from '@/Widgets';
import Constants from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import { RoundedContainer } from '@/Components/Common';
import { LogsDataTable } from '@/Components/LayoutPart/DataTable';
import LogsAction from '@/Actions/Logs.action';

import { IMetaStructure } from '@/Interfaces/Common.interface';
import { ILogsDetailsStructure } from '@/Interfaces/Logs.interface';

import { Typography } from '@mui/material';

const { ROUTERS, MODULE_API } = Constants;
const { fetchLogs, setDefaultLogsReducer } = LogsAction;

interface ILogsStructure {
  id: string;
  name: string;
  userLog: string;
  createdAt: string;
  status: string;
  page: number;
  limit: number;
}

const DEFAULT_PARAMS = {
  id: '',
  name: '',
  userLog: '',
  createdAt: '',
  status: '',
  page: 1,
  limit: 10,
};

const Logs: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const meta: IMetaStructure = useSelector((state: RootState) =>
    get(state.LOGS, 'meta')
  );
  const payload: ILogsDetailsStructure[] = useSelector(
    (state: RootState) => get(state.LOGS, 'payload')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.LOGS, 'requestIsLoading')
  );

  const [payloadParams, setPayloadParams] =
    React.useState<ILogsStructure>(DEFAULT_PARAMS);

  const fetchPayload = (filterPayload: ILogsStructure) => {
    const resolveFilter = Utils.validateFilters(filterPayload);
    dispatch(fetchLogs(resolveFilter));
  };

  React.useEffect(() => {
    const isAcceptApi = Utils.isValidPermission(MODULE_API.LOGS.FETCH_LOGS);
    if (!isAcceptApi) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForFetchLogs'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else fetchPayload(DEFAULT_PARAMS);
    return () => {
      dispatch(setDefaultLogsReducer());
    };
  }, []);

  const onPageAction = (value: number, type: 'limit' | 'page') => {
    const newPayload = { ...payloadParams, [type]: value };

    if (type === 'limit') assign(newPayload, { page: 1 });

    setPayloadParams(newPayload);
    fetchPayload(newPayload);
  };

  const _renderTopSection = () => (
    <Typography variant="h2">{t('title.logs')}</Typography>
  );

  const _renderBottomSection = () => (
    <RoundedContainer>
      <LogsDataTable
        payload={payload}
        meta={meta}
        onPageAction={onPageAction}
        isLoading={isLoading}
      />
    </RoundedContainer>
  );

  return (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default Logs;
