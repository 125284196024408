import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-multi-lang';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { validate as uuidValidate } from 'uuid';
import { Typography, Box, Stack, Fade, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import { WorkProgressActions } from '@/Actions';
import CONSTANTS from '@/Constants';

import { Textarea } from '@/Components/Common';
import { getSavedUserData } from '@/Utils/Cookie.utils';

interface ISectionProps {
  open: boolean;
  onClose(): void;
  showAddOrUpdate: 'add' | 'edit';
  stepId?: string;
}

const { ENUMS, MODULE_API } = CONSTANTS;

const {
  createStepWorkProgress,
  getStepWorkProgressById,
  updateStepWorkProgress,
  clearStatusWorkProgress,
} = WorkProgressActions;

const AddOrUpdateStepWorkProgressPopup: React.FC<ISectionProps> = ({
  open,
  onClose,
  showAddOrUpdate,
  stepId,
}) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const { id } = useParams();
  const isCreateStepWorkProgressSuccess = useSelector((state: RootState) =>
    _.get(state.WORK_PROGRESS, 'createStepWorkProgressIsSuccess')
  );
  const isUpdateStepWorkProgressSuccess = useSelector((state: RootState) =>
    _.get(state.WORK_PROGRESS, 'updateStepWorkProgressIsSuccess')
  );
  const stepDetails = useSelector((state: RootState) =>
    _.get(state.WORK_PROGRESS, 'step')
  );
  const isAcceptUpdateStep = Utils.isValidPermission(
    MODULE_API.WORK_PROGRESS.UPDATE_STEP
  );
  const [isCreatedStep, setIsCreatedStep] = React.useState<boolean>(false);

  const schema = yup
    .object()
    .shape({
      content: yup
        .string()
        .trim()
        .required(t('message.stepWorkProgressContentRequired')),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      content: '',
      status: ENUMS.WorkProgressStatus.PROCESSING,
    },
  });

  React.useEffect(() => {
    if (open && stepId) {
      if (showAddOrUpdate === 'edit') {
        dispatch(getStepWorkProgressById(stepId));
      }
    }
  }, [open]);

  React.useEffect(() => {
    if (stepDetails && stepId) {
      setValue('content', stepDetails?.content);
      setValue('status', stepDetails?.status);
    }
    const userData = getSavedUserData();

    if (stepDetails.stepProgressBeCreatedBy?.email === userData?.email) {
      setIsCreatedStep(true);
    }
  }, [stepDetails]);

  const onCancel = () => {
    dispatch(clearStatusWorkProgress());
    setIsCreatedStep(false);
    reset();
    onClose();
  };

  React.useEffect(() => {
    if (isCreateStepWorkProgressSuccess) {
      onCancel();
    }
  }, [isCreateStepWorkProgressSuccess]);

  React.useEffect(() => {
    if (isUpdateStepWorkProgressSuccess) {
      onCancel();
    }
  }, [isUpdateStepWorkProgressSuccess]);

  const onSubmit = (data: any) => {
    if (id && uuidValidate(id)) {
      if (showAddOrUpdate === 'add') {
        dispatch(createStepWorkProgress({ ...data, workProgressId: id }, id));
      } else if (showAddOrUpdate === 'edit') {
        if (stepId) dispatch(updateStepWorkProgress(data, stepId, id));
      }
    }
  };

  const _renderButtonSubmit = () => {
    if (showAddOrUpdate === 'edit') {
      if (isAcceptUpdateStep)
        return (
          <LoadingButton variant="contained" onClick={handleSubmit(onSubmit)}>
            {t('button.save')}
          </LoadingButton>
        );
      else if (isCreatedStep)
        return (
          <LoadingButton variant="contained" onClick={handleSubmit(onSubmit)}>
            {t('button.save')}
          </LoadingButton>
        );
      else return null;
    }
    return (
      <LoadingButton variant="contained" onClick={handleSubmit(onSubmit)}>
        {t('button.add')}
      </LoadingButton>
    );
  };

  const _renderForm = () => (
    <Box component="form" sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          name="content"
          control={control}
          render={({ field }) => (
            <Stack>
              <Textarea
                label={t('label.content')}
                placeholder={t('placeholder.contentStepWorkProgress')}
                value={field.value}
                onChange={(event: any) => field.onChange(event.target.value)}
                sx={{
                  maxWidth: '100%',
                  fontSize: '15px',
                  padding: 1,
                  textarea: {
                    '&:hover': {
                      border: 'none',
                      overflow: 'auto',
                      outline: 'none',
                    },
                  },
                }}
                message={errors.content?.message}
                required
              />
            </Stack>
          )}
        />
      </Box>
    </Box>
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            minHeight: '280px',
            transform: 'translate(-50%, -50%)',
            width: 750,
            background: 'white',
            boxShadow: 24,
            p: 2,
            borderRadius: '5px',
          }}
        >
          <Typography mb="15px" fontSize="18px" fontWeight="500">
            {showAddOrUpdate === 'add'
              ? t('title.addStepWorkProgress')
              : t('title.updateStepWorkProgress')}
          </Typography>
          {_renderForm()}
          <Stack sx={{ float: 'right', marginTop: 5 }} direction="row">
            <LoadingButton
              sx={{ mr: 1 }}
              onClick={() => onCancel()}
              variant="outlined"
            >
              {t('button.cancel')}
            </LoadingButton>
            {_renderButtonSubmit()}
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddOrUpdateStepWorkProgressPopup;
