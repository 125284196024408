import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 1,
  itemsPerPage: 10,
  totalItems: 1,
  totalPages: 1,
};
const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  payload: [],
  details: {},
  meta: DEFAULT_META,
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.PORFOLIO_IS_REQUEST:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.SET_DEFAULT_PORFOLIO_REDUCER:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        payload: [],
        details: {},
        meta: DEFAULT_META,
      };
    case ACTION_TYPES.SET_PORFOLIO_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.FETCH_PORFOLIOS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload,
      };
    case ACTION_TYPES.FETCH_PORFOLIOS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };
    case ACTION_TYPES.CREATE_PORFOLIO_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.CREATE_PORFOLIO_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.UPDATE_STATUS_PORFOLIO_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.UPDATE_STATUS_PORFOLIO_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.GET_PORFOLIO_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.GET_PORFOLIO_BY_ID_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.UPDATE_PORFOLIO_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.UPDATE_PORFOLIO_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    default:
      return state;
  }
};
