import { SxProps, Theme } from '@mui/system';

export const inputLabelStyles: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 1rem',
  background: '#273643',
  borderRadius: '15px',
  color: 'white',
  fontSize: '14px',
  height: '35px',
  width: 'auto',
  minWidth: 'max-content',
};
