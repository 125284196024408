import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 1,
  itemsPerPage: 10,
  totalItems: 1,
  totalPages: 1,
};
const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  payload: [],
  details: {},
  meta: DEFAULT_META,
  calculateCategory: {},
  featured: [],
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.USER_PORFOLIO_IS_REQUEST:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.SET_USER_DEFAULT_PORFOLIO_REDUCER:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        payload: [],
        details: {},
        meta: DEFAULT_META,
      };
    case ACTION_TYPES.SET_USER_PORFOLIO_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.FETCH_USER_PORTFOLIO_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload,
      };
    case ACTION_TYPES.FETCH_USER_PORTFOLIO_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };
    case ACTION_TYPES.CALCULATE_PORTFOLIO_HOMEPAGE_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        calculateCategory: payload,
      };
    case ACTION_TYPES.CALCULATE_PORTFOLIO_HOMEPAGE_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };
    case ACTION_TYPES.GET_USER_PORFOLIO_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.GET_USER_PORFOLIO_BY_ID_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.GET_FEATURED_PORTFOLIO_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        featured: payload,
      };
    case ACTION_TYPES.GET_FEATURED_PORTFOLIO_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        featured: [],
      };
    default:
      return state;
  }
};
