import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';

const { DAILY_REPORT_TASK } = CONSTANTS.API;

export const createDailyReportTask = async (payload: FormData) => {
  return sendRequest(DAILY_REPORT_TASK.BASIC, 'POST', payload);
};

export const fetchDailyReportTask = async (payload: any) => {
  return sendRequest(DAILY_REPORT_TASK.BASIC, 'GET', payload);
};

export const getMyDailyTasks = async (payload: any) => {
  return sendRequest(DAILY_REPORT_TASK.ME, 'GET', payload);
};

export const getDailyReportTaskById = async (id: string) => {
  return sendRequest(`${DAILY_REPORT_TASK.BASIC}/${id}`, `GET`);
};

export const updateDailyReportTask = async (payload: any, id: string) => {
  return sendRequest(`${DAILY_REPORT_TASK.BASIC}/${id}`, 'PUT', payload);
};

export const deleteDailyReportTask = async (id: string) => {
  return sendRequest(`${DAILY_REPORT_TASK.BASIC}/${id}`, `DEL`);
};
