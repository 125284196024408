import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';

const { ANNOUNCEMENT } = CONSTANTS.API;

export const getForUserAnnouncements = async () => {
  return sendRequest(ANNOUNCEMENT.FOR_USERS, 'GET');
};

export const getAllforUserAnnouncements = async (payload: any) => {
  return sendRequest(ANNOUNCEMENT.ALL_FOR_USERS, 'GET', payload);
};

export const updateReadAnnouncements = async (id: string, type: string) => {
  return sendRequest(`${ANNOUNCEMENT.READ_ANNOUNCEMENT}/${id}`, 'PUT', {
    type,
  });
};
