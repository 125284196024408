import React from 'react';
import { Outlet } from 'react-router';

import { Box, Stack } from '@mui/material';

const AuthenticationLayout: React.FC = () => {
  return (
    <Box
      component="div"
      sx={{
        overflow: 'hidden',
        backgroundColor: 'rgb(252, 252, 252)',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Stack
        component="main"
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '100px 40px',
        }}
      >
        <Outlet />
      </Stack>
    </Box>
  );
};

export default AuthenticationLayout;
