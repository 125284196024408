import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
import { IProjectMember } from '../Interfaces/ProjectMember.interface';

const { PROJECT_MEMBER } = CONSTANTS.API;

export const fetchProjectMembers = async (id: string) => {
  return sendRequest(`${PROJECT_MEMBER.BASIC}/${id}`, 'GET');
};

export const fetchUnassignedProjectMembers = async (id: string) => {
  return sendRequest(`${PROJECT_MEMBER.UNASSIGNED_MEMBERS}/${id}`, 'GET');
};

export const getProjectMemberById = async (id: string) => {
  return sendRequest(`${PROJECT_MEMBER.BASIC}/${id}`, `GET`);
};

export const createProjectMember = async (
  payload: Omit<IProjectMember, 'id'>
) => {
  return sendRequest(PROJECT_MEMBER.BASIC, 'POST', payload);
};

export const updateProjectMember = async (payload: IProjectMember) => {
  const { id } = payload;
  return sendRequest(`${PROJECT_MEMBER.BASIC}/${id}`, 'PUT', payload);
};

export const deleteProjectMember = async (id: string) => {
  return sendRequest(`${PROJECT_MEMBER.BASIC}/${id}`, `DEL`);
};
