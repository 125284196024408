import React from 'react';
import isEmpty from 'lodash/isEmpty';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';

interface IStickyHeadTable {
  columns: any[];
  rows: any[];
  caption?: string;
  onAction?(value: number, type: 'limit' | 'page'): void;
  paginate?: any;
  sx?: object;
}

const StickyHeadTable: React.FC<IStickyHeadTable> = ({
  columns,
  rows,
  caption,
  sx,
}) => {
  return (
    <Paper sx={{ width: '100%', maxWidth: '1278px' }}>
      {caption && (
        <Typography sx={{ p: 2, fontSize: 14, fontWeight: 600 }}>
          {caption}
        </Typography>
      )}
      <TableContainer style={sx}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column: any, index: number) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    width: column.width || 'auto',
                    flex: column.flex || 'unset',
                    position: index === 0 ? 'sticky' : 'unset',
                    left: 0,
                    background: 'white',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index: number) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code || index}
                >
                  {columns.map((column, columnIndex: number) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{
                          paddingY: '5px',
                          whiteSpace: 'nowrap',
                          position: columnIndex === 0 ? 'sticky' : 'unset',
                          fontWeight: columnIndex === 0 ? '500' : '400',
                          left: 0,
                          background: 'white',
                          zIndex: 1,
                        }}
                      >
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isEmpty(rows) && (
        <Typography sx={{ p: 2, fontSize: 14, fontWeight: 600 }}>
          No data were found
        </Typography>
      )}
    </Paper>
  );
};

export default StickyHeadTable;
