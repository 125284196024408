/* eslint-disable react/function-component-definition */
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-multi-lang';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { vi, enUS } from 'date-fns/locale';
import {
  DateRangePicker as MUIDateRangePicker,
  DateRange,
} from 'mui-daterange-picker';
import Utils from '@/Utils';

interface SectionProps {
  open: boolean;
  onClose(): void;
  callback(newValue: any): void;
  payload?: DateRange;
}

const MAX_DATE = dayjs().toString();

const DateRangePicker: React.FC<SectionProps> = (props: SectionProps) => {
  // Constructors
  const { open, onClose, payload, callback } = props;
  const t = useTranslation();
  const locale = Utils.getSavedLocale();
  const [dateRange, setDateRange] = React.useState<DateRange>({});

  const handleClose = () => onClose();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: 'max-content',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        {t('label.chooseATimePeriod')}
      </DialogTitle>
      <DialogContent>
        <MUIDateRangePicker
          open={true}
          initialDateRange={payload}
          onChange={(range: any) => range && setDateRange(range)}
          toggle={() => {}}
          locale={locale === 'vi' ? vi : enUS}
          wrapperClassName="date-range-picker-container"
          maxDate={MAX_DATE}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          startIcon={<CloseIcon />}
          onClick={handleClose}
          variant="outlined"
        >
          {t('button.cancel')}
        </Button>
        <LoadingButton
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={() => {
            callback(dateRange);
            handleClose();
          }}
        >
          {t('button.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DateRangePicker;
