import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import LeftIcon from '@mui/icons-material/ArrowLeft';
import RightIcon from '@mui/icons-material/ArrowRight';
import GetAppIcon from '@mui/icons-material/GetApp';
import { isEmpty } from 'lodash';

import Utils from '@/Utils';
import { Alert } from '@/Widgets';
import Constants from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import { DatePicker, RoundedContainer } from '@/Components/Common';
import AttendancesAction from '@/Actions/Attendances.action';
import AttendancesDataTable from '@/Components/LayoutPart/DataTable/AttendanceDataTable';
import { getSavedLocale } from '@/Utils/Cookie.utils';
const { ROUTERS, MODULE_API } = Constants;
const { fetchAttendances, updateAttendances, setMeta, exportAttendance } =
  AttendancesAction;

interface IAttendancesStructure {
  id: string;
  userId: string;
  date: string;
  userData: {
    fullName: string;
  };
  attendances: [];
}

interface IMetaStructure {
  monthAndYear: string;
  keyword: string;
}

const DEFAULT_PARAMS: IMetaStructure = {
  monthAndYear: '',
  keyword: '',
};

interface AttendancePayload {
  userId: string;
  id?: string;
  date: string;
  typeAttendances: string;
}

// attendance table
interface IColumnStructure {
  label: any;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

const Logs: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const payload: IAttendancesStructure[] = useSelector((state: RootState) =>
    get(state.ATTENDANCE, 'payload')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.ATTENDANCE, 'requestIsLoading')
  );
  const meta: IMetaStructure = useSelector((state: RootState) =>
    get(state.ATTENDANCE, 'meta')
  );
  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: DEFAULT_PARAMS,
  });
  const keywordValue = getValues('keyword');
  const monthAndYearValue = getValues('monthAndYear');

  const currentMonth = dayjs(new Date()).format('MM/YYYY');
  const [heads, setHeads] = React.useState<any[]>([]);

  const fetchPayload = (filterPayload: any) => {
    dispatch(setMeta(filterPayload));
    dispatch(fetchAttendances(filterPayload));
  };

  const getDateInMonth = (month: number, year: number): Date[] => {
    const date = new Date(year, month, 1);
    const days: Date[] = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  React.useEffect(() => {
    const d = meta.monthAndYear ? new Date(meta.monthAndYear) : new Date();
    const dateInmonth: Date[] = getDateInMonth(d.getMonth(), d.getFullYear());
    const columns: IColumnStructure[] = [
      { label: t('table.no'), minWidth: 30, align: 'center' },
      { label: t('table.fullName'), minWidth: 200, align: 'center' },
      ...dateInmonth.map((value: Date) => {
        const day = value.toLocaleString('en-us', { weekday: 'short' });
        const label = (
          <Box
            flexDirection="column"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <span>{t(`table.${day}`)}</span>
            <span>{value.getDate().toString().padStart(2, '0')}</span>
          </Box>
        );
        const minWidth = 40;
        return {
          label:
            day === 'Sat' || day === 'Sun' ? (
              <span style={{ color: '#d0d0d0' }}>{label}</span>
            ) : (
              label
            ),
          minWidth,
        };
      }),
    ];
    setHeads(columns);
  }, [meta]);

  React.useEffect(() => {
    const isAcceptApiFetchAll = Utils.isValidPermission(
      MODULE_API.ATTENDANCE.FETCH
    );
    const isAcceptApiFetchOwn = Utils.isValidPermission(
      MODULE_API.ATTENDANCE.FETCH_OWN
    );

    if (!isAcceptApiFetchAll && !isAcceptApiFetchOwn) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForFetchAttendances'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else fetchPayload(DEFAULT_PARAMS);
  }, []);

  const addOrUpdateAttendances = async (
    item: { id: string },
    value: string,
    date: string,
    attendanceId: string
  ) => {
    const attendances: AttendancePayload = {
      userId: item.id,
      date,
      typeAttendances: value,
    };
    if (attendanceId) {
      attendances.id = attendanceId;
    }
    dispatch(updateAttendances(attendances));
  };

  const onReset = () => {
    reset();
    dispatch(setMeta(DEFAULT_PARAMS));
    fetchPayload(DEFAULT_PARAMS);
  };

  const onSearch = (data: IMetaStructure) => fetchPayload(data);

  const onExport = () => {
    const localeStorage = getSavedLocale();
    const d = meta.monthAndYear ? new Date(meta.monthAndYear) : new Date();
    const monthExport = meta.monthAndYear
      ? meta.monthAndYear
      : `${d.getMonth() + 1}/1/${d.getFullYear()}`;
    const exportPayload = {
      ...meta,
      language: localeStorage.toUpperCase(),
    };
    const saveAsMonth = dayjs(monthExport).format('MM_YYYY');
    dispatch(exportAttendance(exportPayload, saveAsMonth));
  };

  const _renderTopSection = () => {
    const isAcceptExportAttendance = Utils.isValidPermission(
      MODULE_API.ATTENDANCE.EXPORT
    );
    return (
      <Stack>
        <Typography variant="h2">{t('menu.attendances')}</Typography>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" sx={{ mr: 5 }}>
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  size="small"
                  onChange={(e: any) => field.onChange(e.target.value)}
                  placeholder={t('placeholder.keyword')}
                  sx={{ mr: 1, maxWidth: '190px' }}
                />
              )}
            />
            <Controller
              name="monthAndYear"
              control={control}
              render={({ field }) => (
                <DatePicker
                  inputFormat="MM/YYYY"
                  views={['year', 'month']}
                  label=""
                  value={field.value}
                  onChange={(date: any) => field.onChange(date)}
                  placeholder={currentMonth}
                  sx={{ mr: 1, maxWidth: '190px' }}
                  openTo="month"
                />
              )}
            />
            <Button
              sx={{ mr: 1 }}
              onClick={handleSubmit(onSearch)}
              variant="contained"
            >
              {t('button.filter')}
            </Button>
            <Button
              sx={{ mr: 2 }}
              onClick={() => onReset()}
              variant="outlined"
              disabled={!keywordValue && !monthAndYearValue}
            >
              {t('button.reset')}
            </Button>
          </Stack>
          {isAcceptExportAttendance && (
            <Button
              startIcon={<GetAppIcon />}
              onClick={() => onExport()}
              variant="outlined"
              disabled={isEmpty(payload)}
            >
              {t('button.export')}
            </Button>
          )}
        </Stack>
      </Stack>
    );
  };

  const adjustMonth = (direction: 'prev' | 'next') => {
    const { monthAndYear, keyword } = getValues();
    const currentDate = monthAndYear ? dayjs(monthAndYear) : dayjs();
    const newDate = currentDate
      .add(direction === 'prev' ? -1 : 1, 'month')
      .format('MM/DD/YYYY');
    setValue('monthAndYear', newDate);
    onSearch({ monthAndYear: newDate, keyword });
  };

  const _renderTable = () => {
    const d = meta.monthAndYear ? new Date(meta.monthAndYear) : new Date();
    const monthFilter = `${d.getMonth() + 1}/${d.getFullYear()}`;
    return (
      <RoundedContainer sx={{ m: '1em 0' }}>
        <Stack
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: '10px',
            flexDirection: 'row',
          }}
        >
          <LeftIcon
            fontSize="large"
            onClick={() => adjustMonth('prev')}
            sx={{ '&:hover': { cursor: 'pointer' } }}
          />
          <Typography fontSize="20px" fontWeight="500">
            {t('label.employeeTimesheet')}
            {DEFAULT_PARAMS.monthAndYear
              ? DEFAULT_PARAMS.monthAndYear
              : monthFilter}
          </Typography>
          <RightIcon
            fontSize="large"
            onClick={() => adjustMonth('next')}
            sx={{ '&:hover': { cursor: 'pointer' } }}
          />
        </Stack>

        <AttendancesDataTable
          head={heads}
          payload={payload}
          onRowAction={addOrUpdateAttendances}
          isLoading={isLoading}
        />
      </RoundedContainer>
    );
  };

  const _renderContent = () => (
    <>
      {_renderTopSection()}
      {_renderTable()}
    </>
  );

  const renderMain = () => {
    return (
      <Stack flex={1} direction="column" sx={{ height: 'max-content' }}>
        {_renderContent()}
      </Stack>
    );
  };

  return renderMain();
};

export default Logs;
