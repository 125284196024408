import React from 'react';
import isEmpty from 'lodash/isEmpty';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination, {
  LabelDisplayedRowsArgs,
} from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-multi-lang';
import { TableSkeleton } from '../Skeleton';

interface IStickyHeadTable {
  columns: any[];
  rows: any[];
  caption?: string;
  onAction?(value: number, type: 'limit' | 'page'): void;
  paginate?: any;
  sx?: object;
  isLoading?: boolean;
  isNotTranslate?: boolean;
}

const StickyHeadTable: React.FC<IStickyHeadTable> = ({
  columns,
  rows,
  caption,
  onAction,
  paginate,
  sx,
  isLoading = false,
  isNotTranslate = false,
}) => {
  // Declare localization
  const t = useTranslation();
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    if (paginate) {
      const { currentPage, itemsPerPage, totalItems } = paginate;
      setRowsPerPage(itemsPerPage);
      setCount(totalItems);
      setPage(currentPage - 1);
    }
  }, [paginate]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    if (onAction) onAction(newPage + 1, 'page');
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (onAction) onAction(+event.target.value, 'limit');
  };

  const _renderTable = () => (
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{
                top: 0,
                minWidth: column.minWidth,
                // width: column.width || 'auto',
                // flex: column.flex || 'unset',
              }}
            >
              {isNotTranslate ? column.label : t(`table.${column.label}`)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {!isLoading &&
          rows.map((row, index) => {
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.code || index}
              >
                {columns.map((column: any) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        paddingY: '5px',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {column.format && typeof value === 'number'
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        {isEmpty(rows) && !isLoading && (
          <TableRow>
            <TableCell colSpan={8}>
              <Typography sx={{ p: 0, fontSize: 14, fontWeight: 600 }}>
                {t(`table.noDataWereFound`)}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  const _renderSkeleton = () => <TableSkeleton />;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {caption && (
        <Typography sx={{ p: 2, fontSize: 14, fontWeight: 600 }}>
          {caption}
        </Typography>
      )}
      <TableContainer
        style={{
          width: '100%',
          // maxHeight: 'calc(100vh - 323px)',
          maxHeight: 440,
          ...sx,
        }}
      >
        {isLoading ? _renderSkeleton() : _renderTable()}
      </TableContainer>
      {!isLoading && !isEmpty(rows) && paginate && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => {
            return (
              '' +
              paginationInfo.from +
              '-' +
              paginationInfo.to +
              ` ${t('table.of')} ` +
              paginationInfo.count
            );
          }}
          labelRowsPerPage={t('table.rowsPerPage')}
        />
      )}
    </Paper>
  );
};

export default StickyHeadTable;
