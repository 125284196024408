import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';

const { TAGS } = CONSTANTS.API;

export const fetchTags = async () => {
  return sendRequest(TAGS.BASIC, 'GET');
};

export const createTag = async (payload: any) => {
  return sendRequest(TAGS.BASIC, 'POST', payload);
};

export const deleteTag = async (id: string) => {
  return sendRequest(`${TAGS.BASIC}/${id}`, 'DEL');
};
