import * as React from 'react';
import dayjs from 'dayjs';

import {
  Stack,
  Typography,
  Button,
  InputLabel,
  IconButton,
  Tooltip,
} from '@mui/material';
// import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { useTranslation } from 'react-multi-lang';
import PopupLayout from '../PopupLayout';
import { IDailyReport } from '@/Interfaces/DailyReport.interface';

interface SectionProps {
  onClose(): void;
  payload: IDailyReport | null;
}

const DailyReportPopup: React.FC<SectionProps> = ({ onClose, payload }) => {
  const open = Boolean(payload);
  const t = useTranslation();

  const _renderForm = () => (
    <>
      <Stack direction="row" mb={1}>
        <InputLabel sx={{ fontWeight: '500', color: 'black', mr: 2 }}>
          {t('label.date')}
        </InputLabel>
        <Typography>
          {payload ? dayjs(payload?.date).format('DD-MM-YYYY') : null}
        </Typography>
      </Stack>
      <Stack direction="row" mb={1} alignItems="center">
        <InputLabel sx={{ fontWeight: '500', color: 'black', mr: 2 }}>
          {t('label.attachment')}
        </InputLabel>
        {payload?.attachment ? (
          <Stack direction="row" alignItems="center">
            <Typography
              title={payload.attachment.convertedName}
              sx={{
                width: '100%',
                maxWidth: '200px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {payload.attachment.convertedName}
            </Typography>
            <Tooltip
              title={t('tooltip.clickToDownloadAttachment')}
              onClick={() => saveAs(payload.attachment.path)}
            >
              <IconButton sx={{ ml: 1 }}>
                <DownloadOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : null}
      </Stack>
      <Stack direction="column" flex={1}>
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {t('label.description')}
        </InputLabel>
        <Typography
          dangerouslySetInnerHTML={{
            __html: payload?.description || 'N/I',
          }}
          sx={{ minHeight: '240px', maxHeight: '360px', overflow: 'auto' }}
        />
      </Stack>
      <Stack direction="row" justifyContent="end" mt={2}>
        <Button variant="outlined" onClick={onClose}>
          {t('button.close')}
        </Button>
      </Stack>
    </>
  );

  return (
    <PopupLayout
      title={t('title.reportDetails')}
      content={_renderForm()}
      open={open}
      onClose={onClose}
    />
  );
};

export default DailyReportPopup;
