import menu from './menu.json';
import table from './table.json';
import title from './title.json';
import tooltip from './tooltip.json';
import popup from './popup.json';
import button from './button.json';
import label from './label.json';
import helpText from './helpText.json';
import message from './message.json';
import placeholder from './placeholder.json';
import alert from './alert.json';

export default {
  button,
  menu,
  table,
  title,
  tooltip,
  popup,
  label,
  helpText,
  message,
  placeholder,
  alert,
};
