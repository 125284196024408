/* eslint-disable react/function-component-definition */
import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-multi-lang';
import { Box, IconButton, Tooltip, Typography, Switch } from '@mui/material';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SendIcon from '@mui/icons-material/Send';

import Utils from '@/Utils';
import CONSTANTS from '@/Constants';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { ChipStatus, StickyHeadTable } from '@/Components/Common';

const { ENUMS } = CONSTANTS;

interface IColumnStructure {
  id: 'no' | 'name' | 'location' | 'createdAt' | 'status' | 'action' | 'url';
  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

interface IDataTableStructure {
  no: number;
  name: React.JSX.Element;
  url: React.JSX.Element;
  createdAt: React.JSX.Element;
  status: React.JSX.Element;
  action: React.JSX.Element;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'name', label: 'title', minWidth: 200, align: 'left' },
  { id: 'url', label: 'URL', minWidth: 200, align: 'left' },
  { id: 'createdAt', label: 'createdAt', minWidth: 50, align: 'left' },
  { id: 'status', label: 'status', minWidth: 50, align: 'center' },
  { id: 'action', label: 'action', minWidth: 5, align: 'center' },
];

const createDataTable = (
  no: number,
  name: React.JSX.Element,
  url: React.JSX.Element,
  createdAt: React.JSX.Element,
  status: React.JSX.Element,
  action: React.JSX.Element
): IDataTableStructure => {
  return {
    no,
    name,
    url,
    createdAt,
    status,
    action,
  };
};

interface ISectionProps {
  meta: IMetaStructure;
  payload: any[];
  onPageAction(value: number, type: 'limit' | 'page'): void;
  onRowAction(
    workProgress: any,
    action: 'edit' | 'publish' | 'active' | 'inactive'
  ): void;
  isLoading?: boolean;
}
const { MODULE_API } = CONSTANTS;

const PorfoliosDataTable: React.FC<ISectionProps> = ({
  meta,
  payload,
  isLoading = false,
  onPageAction,
  onRowAction,
}) => {
  const [rows, setRows] = React.useState<IDataTableStructure[]>([]);
  const t = useTranslation();
  const isAcceptViewDetails = Utils.isValidPermission(
    MODULE_API.NEWS.GET_POST_BY_ID
  );

  React.useEffect(() => {
    const resolveRows: IDataTableStructure[] = [];
    if (!_.isEmpty(payload)) {
      _.forEach(payload, (item: any, index: number) => {
        const { name, createdAt, status, url } = item;
        const currentPage = _.get(meta, 'currentPage');
        const limit = _.get(meta, 'itemsPerPage');
        const resolveRowIndex = index + 1 + (currentPage - 1) * limit;
        const resolveCreatedAt = createdAt
          ? dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss')
          : '';

        let resolveStatus: string;
        if (item.status === 'publish') resolveStatus = ENUMS.NewsStatus.ACTIVE;
        else
          resolveStatus =
            item.status === ENUMS.NewsStatus.ACTIVE
              ? ENUMS.NewsStatus.INACTIVE
              : ENUMS.NewsStatus.ACTIVE;

        resolveRows.push(
          createDataTable(
            resolveRowIndex,
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                // display: '-webkit-box',
                // WebkitLineClamp: 3,
                // WebkitBoxOrient: 'vertical',
                height: 1,
                maxWidth: 200,
              }}
            >
              {name}
            </Typography>,
            <Box component="a" href={url} target="_blank">
              {url}
            </Box>,
            <Box>{resolveCreatedAt || 'N/I'}</Box>,
            <ChipStatus label={status as any} />,
            <Box>
              <Tooltip title={t('tooltip.viewDetails')}>
                <IconButton
                  onClick={() => {
                    onRowAction(item, 'edit');
                  }}
                  disabled={!isAcceptViewDetails}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={t('tooltip.clickToChangeStatus', {
                  status: t(`table.${resolveStatus}`),
                })}
              >
                <Switch
                  size="medium"
                  disabled={item.status === ENUMS.NewsStatus.TERMINATED}
                  key={`active.${index}`}
                  defaultChecked={item?.status === ENUMS.NewsStatus.ACTIVE}
                  color={
                    item?.status === ENUMS.NewsStatus.ACTIVE
                      ? 'success'
                      : 'info'
                  }
                  checked={
                    item?.status === ENUMS.NewsStatus.ACTIVE ||
                    item?.status === ENUMS.NewsStatus.PUBLISH
                  }
                  onChange={() => onRowAction(item, resolveStatus as any)}
                />
              </Tooltip>
              <Tooltip
                title={t('tooltip.clickToChangeStatus', {
                  status: t(`table.${ENUMS.NewsStatus.PUBLISH}`),
                })}
              >
                <IconButton
                  onClick={() => {
                    onRowAction(item, 'publish');
                  }}
                  disabled={
                    !isAcceptViewDetails ||
                    item.status === ENUMS.NewsStatus.INACTIVE ||
                    item.status === ENUMS.NewsStatus.PUBLISH
                  }
                >
                  <SendIcon
                    color={
                      item.status === ENUMS.NewsStatus.PUBLISH
                        ? 'info'
                        : 'inherit'
                    }
                  />
                </IconButton>
              </Tooltip>
            </Box>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      onAction={onPageAction}
      paginate={meta}
      isLoading={isLoading}
      sx={{ maxHeight: '80%' }}
    />
  );
};

export default PorfoliosDataTable;
