import CONSTANTS from '@/Constants';
import isArray from 'lodash/isArray';
import API from '@/APIs';
import Utils from '@utils';
import {
  IProjectResponseStructure,
  IProjectFilter,
  ICreateProject,
  IUpdateProject,
} from '@/Interfaces/Project.interface';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { toast } from 'react-toastify';

const { ACTION_TYPES, ROUTERS } = CONSTANTS;

// SINGLE_ACTIONS
const setDefaultProjectStatus = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_PROJECT_STATUS,
  };
};

const setMetaPagination = (payload: IMetaStructure) => {
  return {
    type: ACTION_TYPES.SET_META_PROJECT,
    payload,
  };
};

const setDefaultReducerProject = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_REDUCER_PROJECT,
  };
};

const projectIsRequest = () => {
  return {
    type: ACTION_TYPES.PROJECT_IS_REQUEST,
  };
};

// ASYNC_ACTIONS
const fetchProjectsSuccess = (payload: IProjectResponseStructure[]) => {
  return {
    type: ACTION_TYPES.FETCH_PROJECTS_SUCCESS,
    payload,
  };
};

const fetchProjectsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_PROJECTS_FAILURE,
  };
};

const fetchProjects = (payload?: IProjectFilter) => {
  return async (dispatch: any) => {
    dispatch(projectIsRequest());
    await API.fetchProjects(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchProjectsFailure());
        else {
          if (typeof result === 'object' && result !== null) {
            const resolvePayload: IProjectResponseStructure[] = [];
            if (isArray(result)) resolvePayload.push(...[...result]);
            else {
              const resolveResult: {
                items: IProjectResponseStructure[];
                meta: IMetaStructure;
              } = result as {
                items: IProjectResponseStructure[];
                meta: IMetaStructure;
              };
              dispatch(setMetaPagination(resolveResult.meta));
              resolvePayload.push(...[...resolveResult.items]);
            }
            dispatch(fetchProjectsSuccess(resolvePayload));
          }
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(fetchProjectsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const fetchProjectsNotConfigSuccess = (
  payload: IProjectResponseStructure[]
) => {
  return {
    type: ACTION_TYPES.FETCH_PROJECTS_NOT_CONFIG_SUCCESS,
    payload,
  };
};

const fetchProjectsNotConfigFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_PROJECTS_NOT_CONFIG_FAILURE,
  };
};

const fetchProjectsNotConfig = (projectId?: string) => {
  return async (dispatch: any) => {
    dispatch(projectIsRequest());
    await API.fetchProjectsNotConfig(projectId)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchProjectsNotConfigFailure());
        else {
          if (typeof result === 'object' && result !== null) {
            const resolvePayload: IProjectResponseStructure[] = [];
            if (isArray(result)) resolvePayload.push(...[...result]);
            else {
              const resolveResult: {
                items: IProjectResponseStructure[];
                meta: IMetaStructure;
              } = result as {
                items: IProjectResponseStructure[];
                meta: IMetaStructure;
              };
              dispatch(setMetaPagination(resolveResult.meta));
              resolvePayload.push(...[...resolveResult.items]);
            }
            dispatch(fetchProjectsNotConfigSuccess(resolvePayload));
          }
        }
        return true;
      })
      .catch(async (error: any) => {
        await dispatch(fetchProjectsNotConfigFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createProjectSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_PROJECT_SUCCESS,
  };
};

const createProjectFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_PROJECT_FAILURE,
  };
};

const createProject = (payload: ICreateProject) => {
  return async (dispatch: any) => {
    dispatch(projectIsRequest());
    await API.createProject(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createProjectFailure());
        } else {
          await dispatch(createProjectSuccess());
          await Utils.redirect(ROUTERS.PROJECT_LIST);
          toast.success(message);
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createProjectFailure());
      });
  };
};

const updateProjectSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_PROJECT_SUCCESS,
  };
};

const updateProjectFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_PROJECT_FAILURE,
  };
};

const updateProject = (payload: IUpdateProject) => {
  return async (dispatch: any) => {
    dispatch(projectIsRequest());
    await API.updateProject(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateProjectFailure());
        } else {
          await dispatch(updateProjectSuccess());
          await Utils.redirect(ROUTERS.PROJECT_LIST);
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateProjectFailure());
      });
  };
};

const updateProjectDocumentationSuccess = (
  payload: IProjectResponseStructure
) => {
  return {
    type: ACTION_TYPES.UPDATE_PROJECT_DOCUMENTATION_SUCCESS,
    payload,
  };
};

const updateProjectDocumentationFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_PROJECT_DOCUMENTATION_FAILURE,
  };
};

const updateProjectDocumentation = (payload: IUpdateProject) => {
  return async (dispatch: any) => {
    dispatch(projectIsRequest());
    await API.updateProjectDocumentation(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateProjectDocumentationFailure());
        } else {
          const resolveResult: IProjectResponseStructure =
            result as IProjectResponseStructure;
          await dispatch(updateProjectDocumentationSuccess(resolveResult));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateProjectFailure());
      });
  };
};

const getProjectByIdSuccess = (payload: IProjectResponseStructure) => {
  return {
    type: ACTION_TYPES.GET_PROJECT_BY_ID_SUCCESS,
    payload,
  };
};

const getProjectByIdFailure = () => {
  return {
    type: ACTION_TYPES.GET_PROJECT_BY_ID_FAILURE,
  };
};

const getProjectById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(projectIsRequest());
    await API.getProjectById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getProjectByIdFailure());
        else {
          const resolveResult: IProjectResponseStructure =
            result as IProjectResponseStructure;
          dispatch(getProjectByIdSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(getProjectByIdFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const deleteProjectSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_PROJECT_SUCCESS,
  };
};

const deleteProjectFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_PROJECT_FAILURE,
  };
};

const deleteProject = (id: string, filterParams?: IProjectFilter) => {
  return async (dispatch: any) => {
    dispatch(projectIsRequest());
    await API.DeleteProject(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteProjectFailure());
          toast.error(message);
        } else {
          dispatch(deleteProjectSuccess());
          if (filterParams) dispatch(fetchProjects(filterParams));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(deleteProjectFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  setDefaultProjectStatus,
  fetchProjects,
  createProject,
  updateProject,
  deleteProject,
  setDefaultReducerProject,
  getProjectById,
  updateProjectDocumentation,
  fetchProjectsNotConfig,
};
