import { SxProps, Theme } from '@mui/system';

export const mainStyles: SxProps<Theme> = {
  ' .fc': {
    ' .fc-toolbar-title': {
      fontSize: '24px',
      margin: 0,
      textTransform: 'capitalize',
    },
    ' .fc-today-button.fc-button': {
      textTransform: 'capitalize',
    },
  },
};
