import React from 'react';
import { useTranslation } from 'react-multi-lang';
import { Box } from '@mui/system';

const NoteReportAttendance: React.FC = () => {
  const t = useTranslation();
  return (
    <Box
      sx={{ minWidth: 50, ml: 3, my: 3, display: 'flex' }}
      flexDirection="column"
    >
      <span>
        <b>{t('label.note')}</b>
      </span>
      <span>
        <b style={{ color: 'green' }}>X</b>: {t('label.workingDay')}
      </span>
      <span>
        <b style={{ color: 'green' }}>P</b>: {t('label.annualLeave')}
      </span>
      <span>
        <b style={{ color: 'green' }}>KL</b>: {t('label.unpaidLeave')}
      </span>
      <span>
        <b style={{ color: 'green' }}>OM</b>: {t('label.sickLeave')}
      </span>
      <span>
        <b style={{ color: 'green' }}>NV</b>: {t('label.stopWorking')}
      </span>
      <span>
        <b style={{ color: 'green' }}>CD</b>: {t('label.paidLeave')}
      </span>
      <span>
        <b style={{ color: 'green' }}>X/2</b>:{' '}
        {t('label.halfWorkingDay&HalfUnpaidLeave')}
      </span>
      <span>
        <b style={{ color: 'green' }}>P/2</b>:{' '}
        {t('label.halfAnnualLeave&HalfWorkingDay')}
      </span>
      <span>
        <b style={{ color: 'green' }}>K/2</b>:{' '}
        {t('label.halfAnnualLeave&HalfUnpaidLeave')}
      </span>
      <span>
        <b style={{ color: 'green' }}>CO</b>: {t('label.childrenSickLeave')}
      </span>
      <span>
        <b style={{ color: 'green' }}>T</b>: {t('label.accident')}
      </span>
    </Box>
  );
};

export default NoteReportAttendance;
