import { SxProps, Theme } from '@mui/system';
import { CommonColors } from '@/Themes';

export const bannerWrapper: SxProps<Theme> = {
  background:
    'linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),url(https://zone-ui.vercel.app/assets/background/overlay_1.jpg)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  overflow: 'hidden',
  '& .banner-content': {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: 2,
  },
};

export const bannerText: SxProps<Theme> = {
  fontWeight: 400,
  fontSize: '1rem',
  color: CommonColors.shuttleGray,
  fontFamily: 'sans-serif',
  textAlign: 'center',
};

export const mainWrapper: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  rowGap: '4em',
};

export const techCard: SxProps<Theme> = {
  position: 'absolute',
  top: -30,
  left: '45%',
  borderRadius: '50%',
  border: '2px solid',
  // p: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 50,
  borderColor: CommonColors.burntSienna,
};

export const paperTech: SxProps<Theme> = {
  p: 4,
  mt: 5,
  borderRadius: 4,
  position: 'relative',
};

export const featured: SxProps<Theme> = {
  background:
    'linear-gradient(to bottom, rgba(22, 28, 36, 0.8), rgba(22, 28, 36, 0.8)),url(https://zone-ui.vercel.app/assets/images/home/for_designer.jpg)',
  backgroundRepeat: 'no-repeat',
  '& .swiper': {
    width: 1,
    height: 1,
    py: '50px',
  },
  '& .swiper-slide': {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: 300,
    height: 300,
    img: {
      display: 'block',
      width: 1,
    },
  },
};

export const clientContent: SxProps<Theme> = {
  borderRadius: 5,
  p: 3,
  // maxWidth: '50%',
  // background: '#F4F6F8',
  // boxShadow: 2,
  height: 'max-content',
};

export const lopperClient: SxProps<Theme> = {
  width: 1,
};

export const categoryCard: SxProps<Theme> = {
  width: 1,
  textAlign: 'center',
  position: 'relative',
  transition: '.4s transform linear, .2s box-shadow linear',
  ':hover': {
    boxShadow: '0px 8px 14px -4px rgba(0,0,0,0.2)',
    transform: 'translate(0px, -3px)',
    background: 'white',
    color: 'rgb(250, 84, 28)',
  },
  background: 'none',
  border: '1px solid lightgray',
  p: 2,
  borderRadius: 3,
  cursor: 'pointer',
  height: '100%',
  '& .pr-title': {
    fontWeight: 600,
    fontSize: '1rem',
  },
  '& .pr-number': {
    fontWeight: 400,
    fontSize: '0.9rem',
    color: 'rgb(145, 158, 171)',
  },
  '& .hot': {
    position: 'absolute',
    top: -10,
    right: -10,
    color: CommonColors.fuzzyWuzzyBrown,
  },
};
