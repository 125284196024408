import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
import { ICrawlingSystemStructure } from '@/Interfaces/CrawlingProjects.interface';

const { CRAWLING_SYSTEM } = CONSTANTS.API;

export const fetchCrawlProjects = async (payload: any) => {
  return sendRequest(CRAWLING_SYSTEM.BASIC, 'GET', payload, true);
};

export const getCrawlProjectById = async (id: string) => {
  return sendRequest(
    `${CRAWLING_SYSTEM.GET_BY_ID}/${id}`,
    `GET`,
    undefined,
    true
  );
};

export const createCrawlProject = async (payload: ICrawlingSystemStructure) => {
  return sendRequest(CRAWLING_SYSTEM.BASIC, 'POST', payload, true);
};

export const updateCrawlProject = async (payload: ICrawlingSystemStructure) => {
  const { id } = payload;
  return sendRequest(`${CRAWLING_SYSTEM.BASIC}/${id}`, 'PUT', payload, true);
};

export const activateCrawlProject = async (id: string) => {
  return sendRequest(
    `${CRAWLING_SYSTEM.ACTIVATE}/${id}`,
    'PUT',
    undefined,
    true
  );
};

export const deactivateCrawlProject = async (id: string) => {
  return sendRequest(
    `${CRAWLING_SYSTEM.DEACTIVATE}/${id}`,
    'PUT',
    undefined,
    true
  );
};

export const captureCrawlProject = async () => {
  return sendRequest(
    `${CRAWLING_SYSTEM.CAPTURE_IMAGE}`,
    'GET',
    undefined,
    true
  );
};
