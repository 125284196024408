import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Typography, Stack, FormControlLabel, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Alert } from '@/Widgets';
import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import { RoundedContainer, TextField } from '@/Components/Common';
import { UserActions } from '@/Actions';
import CONSTANTS from '@/Constants';

const { ROUTERS, MODULE_API } = CONSTANTS;
const { changePassword, clearStatusUserDetails } = UserActions;

const ChangePassword: React.FC = () => {
  /* Importing the useTranslation hook from the react-i18next library. */
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  /* Getting the state from the store. */
  const requestIsSuccess: boolean = useSelector((state: RootState) =>
    get(state.USER, 'requestIsSuccess')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.USER, 'requestIsLoading')
  );
  /* Checking if the user has permission to change password. */
  const isAcceptChangePassword = Utils.isValidPermission(
    MODULE_API.USER.CHANGE_PASSWORD
  );
  /* A state variable that is used to show or hide the password. */
  const [isShowPassword, setIsShowPassword] = React.useState<boolean>(false);

  /* Using yup to validate the form. */
  const schema = yup.object().shape({
    oldPassword: yup.string().required(`${t('message.oldPasswordRequired')}`),
    newPassword: yup
      .string()
      .min(6, `${t('message.minLengthNewPassword')}`)
      .required(`${t('message.newPasswordRequired')}`),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
  });

  React.useEffect(() => {
    if (!isAcceptChangePassword) {
      Utils.redirect(ROUTERS.DASHBOARD);
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForChangePassword'),
      });
    }
  }, []);

  React.useEffect(() => {
    if (requestIsSuccess) dispatch(clearStatusUserDetails());
  }, [requestIsSuccess]);

  /* A function that resets the form. */
  const handleReset = async () => {
    const isAgree = await Alert({
      type: 'WARNING',
      message: t('popup.warningResetForm'),
    });
    if (isAgree === 'ok') reset();
  };

  /* A function that is called when the form is submitted. */
  const onSubmit = (data: { oldPassword: string; newPassword: string }) =>
    dispatch(changePassword(data));

  /* The function to render the layout */
  const _renderTopSection = () => (
    <Typography variant="h2">{t('title.changePassword')}</Typography>
  );

  const _renderBottomSection = () => (
    <RoundedContainer>
      <Stack direction="row">
        <Controller
          name="oldPassword"
          control={control}
          render={({ field }) => (
            <TextField
              label={t('label.oldPassword')}
              placeholder={t('placeholder.oldPassword')}
              value={field.value}
              message={errors.oldPassword?.message}
              onChange={(e: any) => field.onChange(e)}
              required
              sx={{ mr: 2 }}
              type={isShowPassword ? 'text' : 'password'}
              disabled={!isAcceptChangePassword}
            />
          )}
        />
        <Controller
          name="newPassword"
          control={control}
          render={({ field }) => (
            <TextField
              label={t('label.newPassword')}
              placeholder={t('placeholder.newPassword')}
              value={field.value}
              message={errors.newPassword?.message}
              onChange={(e: any) => field.onChange(e)}
              required
              type={isShowPassword ? 'text' : 'password'}
              disabled={!isAcceptChangePassword}
            />
          )}
        />
      </Stack>
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              checked={isShowPassword}
              onClick={() => setIsShowPassword(!isShowPassword)}
              size="small"
              sx={{ p: 0, mr: 0.5 }}
            />
          }
          label={t('label.showPassword')}
          sx={{ m: 0, span: { fontSize: '14px', color: 'black' } }}
        />
      </Stack>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'flex-end', mt: 2 }}>
        <LoadingButton onClick={handleReset} sx={{ mr: 1 }}>
          {t('button.discard')}
        </LoadingButton>
        {isAcceptChangePassword ? (
          <LoadingButton
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
          >
            {t('button.submit')}
          </LoadingButton>
        ) : null}
      </Stack>
    </RoundedContainer>
  );

  return (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default ChangePassword;
