import { Divider, Skeleton, Stack } from '@mui/material';

const WikiSkeleton = () => {
  const _renderLeftSection = () => {
    return (
      <Stack
        direction="column"
        sx={{ width: '100%', maxWidth: '200px', mr: 3 }}
      >
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
      </Stack>
    );
  };

  const _renderRightSection = () => {
    return (
      <Stack direction="column" sx={{ flex: 1 }}>
        <Skeleton variant="text" width={350} height={50} />
        <Stack direction="row">
          <Skeleton variant="text" width={100} height={35} sx={{ mr: 1 }} />
          <Skeleton variant="text" width={100} height={35} sx={{ mr: 1 }} />
          <Skeleton variant="text" width={100} height={35} />
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack direction="column">
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="85%" />
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="text" width="90%" />
          <Skeleton variant="text" width="92%" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" width="60%" />
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack direction="row" sx={{ p: 1 }}>
      {_renderLeftSection()}
      {_renderRightSection()}
    </Stack>
  );
};

export default WikiSkeleton;
