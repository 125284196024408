import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import {
  InputLabel,
  Typography,
  FormHelperText,
  InputAdornment,
  TextField,
  Stack,
} from '@mui/material';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { MobileDatePicker } from '@mui/x-date-pickers';
import Utils from '@/Utils';
interface ISectionProps {
  value: Date | string | null;
  onChange(newValue: Dayjs | string): void;
  label?: string;
  message?: string;
  required?: boolean;
  disabled?: boolean;
  minDate?: string | Date | null;
  maxDate?: string | Date | null;
  disableFuture?: boolean;
  disablePast?: boolean;
  placeholder?: string;
  inputFormat?: string;
  views?: Array<any>;
  sx?: object;
  openTo?: 'day' | 'month' | 'year';
}

const DatePick: React.FC<ISectionProps> = ({
  value,
  label = '',
  onChange,
  message = '',
  required = false,
  disabled = false,
  disableFuture = false,
  disablePast = false,
  minDate = null,
  maxDate = null,
  placeholder = '',
  inputFormat = 'DD-MM-YYYY',
  views = ['year', 'month', 'day'],
  sx = {},
  openTo = 'day',
}) => {
  const locale = Utils.getSavedLocale();
  return (
    <Stack
      direction="column"
      sx={{
        flex: 1,
        ...sx,
      }}
    >
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}{' '}
          {required ? (
            <Typography component="span" color="error">
              (*)
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      <MobileDatePicker
        inputFormat={inputFormat}
        value={value ? dayjs(value) : null}
        onChange={(newValue: Dayjs | null) => {
          if (newValue) {
            const formatTime = dayjs(newValue).format('MM/DD/YYYY');
            onChange(formatTime);
          }
        }}
        dayOfWeekFormatter={(day: string) => {
          if (locale === 'en') return day.charAt(0).toUpperCase();
          return day;
        }}
        disabled={disabled}
        minDate={dayjs(minDate)}
        maxDate={dayjs(maxDate)}
        disableFuture={disableFuture}
        disablePast={disablePast}
        openTo={openTo}
        views={views}
        showToolbar={false}
        closeOnSelect
        renderInput={(params: any) => (
          <TextField
            {...params}
            size="small"
            placeholder={placeholder ? placeholder : 'dd-mm-yyyy'}
            error={Boolean(message)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarMonthIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      {message ? (
        <FormHelperText error={Boolean(message)}>{message}</FormHelperText>
      ) : null}
    </Stack>
  );
};

export default DatePick;
