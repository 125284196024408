import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Typography,
  FormGroup,
  FormControlLabel,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  Avatar,
  AvatarGroup,
} from '@mui/material';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import RemoveIcon from '@mui/icons-material/Remove';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Slide from 'react-reveal/Slide';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { motion, AnimatePresence } from 'framer-motion';
import Slider from 'react-slick';
import ProgressiveImage from 'react-progressive-graceful-image';
import { Helmet } from 'react-helmet';

import { CommonColors, Images } from '@/Themes';
import { RootState, useTypedDispatch } from '@/store';
import { PorfolioActions } from '@/Actions';
import {
  bannerWrapper,
  bannerText,
  mainWrapper,
  techCard,
  paperTech,
  featured,
  clientContent,
  lopperClient,
  categoryCard,
} from './Home.styles';
import CONSTANTS from '@/Constants';
const { ROUTERS, PORTFOLIO_CAT } = CONSTANTS;

const OUR_TECH = [
  {
    label: 'Typescript',
    icon: Images.typeScript,
  },
  {
    label: 'Javascript',
    icon: Images.javaScript,
  },
  {
    label: 'React',
    icon: Images.react,
  },
  {
    label: 'Nest.js',
    icon: Images.nestjs,
  },
  {
    label: 'Vite.js',
    icon: Images.vitejs,
  },
  {
    label: 'Electron',
    icon: Images.electron,
  },
  {
    label: 'MUI',
    icon: Images.materialUI,
  },
  {
    label: 'Lavarel',
    icon: Images.laravel,
  },
  {
    label: 'PHP',
    icon: Images.php,
  },
];

const OUR_CLIENT = [
  Images.minRinis,
  Images.minRural,
  Images.minSportit,
  Images.raiffeisenLogo,
  Images.telemetrixLogo,
  Images.theworldbankLogo,
  Images.undpLogo,
  Images.kombetare,
];

const SERVICE_LIST = [
  'Custom Software Development',
  'Web Development',
  'Mobile App Development',
  'Cloud Solutions',
  'IT Consulting',
  'Cybersecurity',
  'Digital Transformation',
  'UI/UX Design',
  'Maintenance and Support',
];
const SERVICE_ITEM = [
  [
    'Harness the power of tailored solutions.',
    'Our expert developers craft customized software to address your unique business requirements, ensuring optimal functionality and performance.',
  ],
  [
    'Elevate your online presence with our web development services.',
    'From responsive websites to dynamic web applications, we create digital experiences that captivate and engage your audience.',
  ],
  [
    'Stay connected on the go.',
    'Our mobile app development team builds innovative, user-friendly applications for iOS and Android platforms, providing a seamless experience for your users.',
  ],
  [
    'Embrace the flexibility of cloud computing.',
    'Our cloud solutions optimize data storage, enhance scalability, and provide secure access to your resources from anywhere in the world.',
  ],
  [
    'Navigate the digital landscape with confidence.',
    'Our experienced IT consultants offer strategic insights, helping you make informed decisions to propel your business forward.',
  ],
  [
    'Protect your digital assets.',
    'Our cybersecurity services safeguard your data and infrastructure, ensuring a secure environment in the face of evolving cyber threats.',
  ],
  [
    'Embrace the future with digital transformation.',
    'We guide you through the process of adopting innovative technologies to streamline operations and drive business growth.',
  ],
  [
    'Make a lasting impression.',
    'Our UI/UX design experts create visually stunning and intuitively designed interfaces, enhancing user experiences across your digital platforms.',
  ],
  [
    'We’re with you every step of the way.',
    'Our dedicated support team provides ongoing maintenance, ensuring your systems run smoothly and efficiently.',
  ],
];
const { calculatePorfolios } = PorfolioActions;

const HomePage: React.FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const payload = useSelector((state: RootState) =>
    _.get(state.PORTFOLIO, 'calculateCategory')
  );
  const portfolioFeatured = useSelector((state: RootState) =>
    _.get(state.PORTFOLIO, 'featured')
  );
  const [expanded, setExpanded] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [categories, setCategories] = useState<any[]>([]);

  useEffect(() => {
    dispatch(calculatePorfolios({ category: PORTFOLIO_CAT }));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(payload)) {
      const sortedData = _.map(payload, (value: number, key: string) => {
        return {
          key,
          value,
        };
      });
      setCategories(_.orderBy(sortedData, ['value', 'key'], ['desc', 'asc']));
    }
  }, [payload]);

  const slickSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    centermode: true,
    centerPadding: '50px',
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const handleCollapse = (key: number) => {
    if (expanded === key) setExpanded(0);
    else setExpanded(key);
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const _renderTechs = () => (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
      }}
    >
      <AvatarGroup
        total={37}
        max={8}
        sx={{ '& .MuiAvatar-root': { fontSize: '0.9rem' } }}
      >
        {_.map(OUR_TECH, (item: any, index: number) => (
          <Avatar
            key={index}
            src={item?.icon}
            alt={item?.label}
            title={item?.label}
          />
        ))}
      </AvatarGroup>
    </Box>
  );

  const _renderBanner = () => (
    <Box sx={bannerWrapper}>
      <Container
        component="main"
        maxWidth="lg"
        sx={{ height: 'calc(100vh - 64px)', width: '100vw' }}
      >
        <Grid container sx={{ height: '100%' }} position="relative">
          <Grid item xs={12} md={5} className="banner-content">
            <Fade left cascade>
              <Typography sx={{ fontWeight: 700, fontSize: '4rem' }}>
                Where Ideas Become Reality with{' '}
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '4rem',
                    color: CommonColors.scienceBlue,
                  }}
                  component="span"
                >
                  FlowIdra
                </Typography>
              </Typography>
              <Typography sx={{ ...bannerText, textAlign: 'left' }}>
                We consult, design and deliver IT solutions that improve
                efficiency, increase performance and raise productivity.
              </Typography>
              <Button
                variant="contained"
                color="ebonyClay"
                sx={{ fontWeight: 700, fontSize: '0.875rem' }}
                endIcon={<OpenInNewIcon />}
                onClick={() => navigate(ROUTERS.PORTFOLIO)}
              >
                See more our products
              </Button>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '1rem',
                  color: CommonColors.shuttleGray,
                  fontFamily: 'sans-serif',
                }}
              >
                Our technologies
              </Typography>
            </Fade>
            {_renderTechs()}
          </Grid>
          <Box sx={{ position: 'absolute', bottom: 0, right: -450, width: 1 }}>
            <LazyLoadImage
              src="https://zone-ui.vercel.app/assets/images/home/home_hero.png"
              alt="logo"
              loading="lazy"
              style={{ objectFit: 'contain', width: '90%' }}
            />
          </Box>
        </Grid>
      </Container>
    </Box>
  );

  const _softwareDev = () => (
    <Paper
      elevation={0}
      sx={{
        ...paperTech,
        boxShadow: '-8px 8px 24px -4px rgba(145,158,171,.08)',
      }}
    >
      <Box sx={techCard}>
        <LazyLoadImage
          src={Images.desktop}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
      </Box>
      <Typography
        textAlign="center"
        sx={{
          fontSize: '1.25rem',
          fontWeight: 500,
        }}
      >
        SOFTWARE DEVELOPMENT
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <LazyLoadImage
          src={Images.electron}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
        <LazyLoadImage
          src={Images.react}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
        <LazyLoadImage
          src={Images.vitejs}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
      </Box>
      <Box>
        <FormGroup sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked
                icon={<VerifiedIcon />}
                checkedIcon={<VerifiedIcon />}
                color="warning"
              />
            }
            label="Development, integration, and maintenance of innovative software
        solutions."
          />
          <FormControlLabel
            control={
              <Checkbox
                checked
                icon={<VerifiedIcon />}
                checkedIcon={<VerifiedIcon />}
                color="warning"
              />
            }
            label="Management information systems, GIS and data analytics
        platforms for businesses and organizations"
          />
        </FormGroup>
      </Box>
    </Paper>
  );

  const _webDev = () => (
    <Paper
      elevation={0}
      sx={{
        boxShadow: '-24px 24px 72px -8px rgba(145, 158, 171, 0.24)',
        ...paperTech,
      }}
    >
      <Box sx={techCard}>
        <LazyLoadImage
          src={Images.web}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
      </Box>
      <Typography
        textAlign="center"
        sx={{
          fontSize: '1.25rem',
          fontWeight: 500,
        }}
      >
        WEB DEVELOPMENT
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <LazyLoadImage
          src={Images.vitejs}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
        <LazyLoadImage
          src={Images.react}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
        <LazyLoadImage
          src={Images.laravel}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
        <LazyLoadImage
          src={Images.wordPress}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
        <LazyLoadImage
          src={Images.nextjs}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
      </Box>
      <FormGroup sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked
              icon={<VerifiedIcon />}
              checkedIcon={<VerifiedIcon />}
              color="warning"
            />
          }
          label="Design and development of web solutions that bring tangible results."
        />
        <FormControlLabel
          control={
            <Checkbox
              checked
              icon={<VerifiedIcon />}
              checkedIcon={<VerifiedIcon />}
              color="warning"
            />
          }
          label="Through web design, custom web applications and digital marketing we
          support our clients to navigate in the ever-changing digital
          landscape"
          color="warning"
        />
      </FormGroup>
    </Paper>
  );

  const _mobileDev = () => (
    <Paper
      elevation={0}
      sx={{
        ...paperTech,
        boxShadow: '-8px 8px 24px -4px rgba(145, 158, 171, 0.08)',
      }}
    >
      <Box sx={techCard}>
        <LazyLoadImage
          src={Images.mobile}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
      </Box>
      <Typography
        textAlign="center"
        sx={{
          fontSize: '1.25rem',
          fontWeight: 500,
        }}
      >
        MOBILE APP DEVELOPMENT
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <LazyLoadImage
          src={Images.react}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
        <LazyLoadImage
          src={Images.flutter}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
        <LazyLoadImage
          src={Images.swift}
          alt="appIcon"
          loading="lazy"
          style={{ width: 30, height: 30 }}
        />
      </Box>
      <FormGroup sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked
              icon={<VerifiedIcon />}
              checkedIcon={<VerifiedIcon />}
              color="warning"
            />
          }
          label="Using the latest technologies"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked
              icon={<VerifiedIcon />}
              checkedIcon={<VerifiedIcon />}
              color="warning"
            />
          }
          label="We produce fully-fledged, stable, and
        scalable mobile applications"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked
              icon={<VerifiedIcon />}
              checkedIcon={<VerifiedIcon />}
              color="warning"
            />
          }
          label="We offer a full cycle of application
        design, integration, and management services"
          color="warning"
        />
      </FormGroup>
    </Paper>
  );

  const _renderListFeature = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} mt={4}>
        <Slide left>{_softwareDev()}</Slide>
      </Grid>
      <Grid item xs={12} md={4}>
        <Slide top>{_webDev()}</Slide>
      </Grid>
      <Grid item xs={12} md={4} mt={4}>
        <Slide right>{_mobileDev()}</Slide>
      </Grid>
    </Grid>
  );

  const _renderFeatures = () => (
    <Container
      component="main"
      sx={{ borderRadius: 5, p: 3, maxWidth: { xs: 1, md: 0.8 } }}
    >
      <Grid container flexDirection="column" rowGap={10}>
        <Grid item xs={12}>
          <Flip left cascade>
            <Typography
              textAlign="center"
              sx={{ fontSize: '32px', fontWeight: 700 }}
            >
              Our Features
            </Typography>
            <Typography sx={bannerText}>
              FLOW-IDRA is a full-service technology solution provider. We focus
              on the issues that customers face and provide technology
              consulting to meet their needs, from rapid response to long-term
              solutions, from strategy to implementation and delivery of
              service. Our services include:
            </Typography>
          </Flip>
        </Grid>
        <Grid item xs={12}>
          {_renderListFeature()}
        </Grid>
      </Grid>
    </Container>
  );

  const _renderFeatured = () => (
    <Box component="main" sx={featured}>
      <Container
        component="main"
        sx={{ borderRadius: 5, p: 3, maxWidth: { xs: 1, md: '70%' } }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            rowGap={2}
          >
            <Typography
              textAlign="center"
              sx={{ fontSize: '2.5rem', fontWeight: 700, color: 'white' }}
            >
              Our Products
            </Typography>
            <Button
              variant="contained"
              color="burntSienna"
              sx={{ fontWeight: 700, fontSize: '0.875rem' }}
              endIcon={<OpenInNewIcon />}
              onClick={() => navigate(ROUTERS.PORTFOLIO)}
            >
              Explore more 20+ products
            </Button>
          </Grid>
          <Grid item xs={12} md={8}>
            <Swiper
              effect="coverflow"
              grabCursor={true}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              autoplay={{
                delay: 1500,
                // disableOnInteraction: false,
              }}
              centeredSlides={true}
              slidesPerView="auto"
              pagination={true}
              modules={[EffectCoverflow, Pagination, Autoplay]}
            >
              {_.map(_.slice(portfolioFeatured, 0, 5), (item: any) => (
                <SwiperSlide key={item?.id}>
                  <Box
                    key={item?.id}
                    component="a"
                    href={`${ROUTERS.PORTFOLIO}/${item?.id}`}
                    target="_blank"
                  >
                    <ProgressiveImage
                      src={_.get(item?.image[0], 'file.path')}
                      placeholder={_.get(item?.image[0], 'file.thumbnail')}
                    >
                      {(src: string, loading: boolean | undefined): any => (
                        <img
                          className={`image${
                            loading ? ' loading-image' : ' loaded-image'
                          }`}
                          src={src}
                          alt="sea beach"
                          style={{
                            width: 300,
                            height: 300,
                            objectFit: 'contain',
                          }}
                        />
                      )}
                    </ProgressiveImage>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const _renderLooperClients = () => (
    <Box sx={lopperClient}>
      <Slider {...slickSettings}>
        {_.map(OUR_CLIENT, (logo: any, index: number) => (
          <Box key={index} sx={{ mx: 1 }}>
            <LazyLoadImage
              key={index}
              src={logo}
              alt="clientLogo"
              loading="lazy"
              style={{ width: 200, objectFit: 'contain', height: 200 }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );

  const _renderClients = () => (
    <Container component="main" sx={clientContent} maxWidth="lg">
      <Slide left>
        <Grid container flexDirection="column" rowGap={2}>
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              sx={{ fontSize: '32px', fontWeight: 700 }}
            >
              Our Clients
            </Typography>
            <Typography sx={bannerText}>
              We work closely with a wide range of clients from different
              sectors
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {_renderLooperClients()}
          </Grid>
        </Grid>
      </Slide>
    </Container>
  );

  const _renderFAQ = () => (
    <Container
      component="main"
      sx={{ borderRadius: 5, p: 3, width: { xs: 1, md: 0.6 } }}
    >
      <Grid container flexDirection="column" rowGap={5}>
        <Grid item xs={12}>
          <Typography
            textAlign="center"
            sx={{ fontSize: '32px', fontWeight: 700 }}
          >
            Frequently Asked Questions
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            elevation={expanded === 1 ? 1 : 0}
            onChange={() => handleCollapse(1)}
            expanded={expanded === 1}
          >
            <AccordionSummary
              expandIcon={expanded === 1 ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography fontWeight={500}>
                1. What can you help me with?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                With whatever we can.
                <br />
                Preparing cost analysis, establishing team roles, and creating
                roadmaps. Defining business goals, key business values, target
                users, main features, and prioritizing. We can assist you with
                creating user personas, mock-ups, user stories, and time frames,
                establishing project status, and preparing project estimation.
                In terms of project development, we prepare server-side
                architecture, front end, back end, graphic, and UX design, and
                create back-office consoles.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={expanded === 2 ? 1 : 0}
            onChange={() => handleCollapse(2)}
            expanded={expanded === 2}
          >
            <AccordionSummary
              expandIcon={expanded === 2 ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography fontWeight={500}>
                2. How do I create a product with you?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                If you have an idea contact us by our online form, e-mail, or
                phone. We&apos;ll meet and talk it over. Just be sure to prepare
                as much info about your idea as possible, it will smoothen the
                meeting and benefit further cooperation. If you don&apos;t know
                how to get around to it, go ahead and read our blog entry on how
                to write a project brief.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={expanded === 3 ? 1 : 0}
            onChange={() => handleCollapse(3)}
            expanded={expanded === 3}
          >
            <AccordionSummary
              expandIcon={expanded === 3 ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography fontWeight={500}>
                3. Do I need to be tech-savvy to cooperate with you and order a
                software application?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You certainly do not have to be a software development expert,
                that&apos;s what we&apos;re here for :) We welcome your
                experience in that matter if you happen to have any, but are
                prepared to guide you through the whole process and explain
                everything. Focus on your needs and we&apos;ll deliver what you
                require.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={expanded === 4 ? 1 : 0}
            onChange={() => handleCollapse(4)}
            expanded={expanded === 4}
          >
            <AccordionSummary
              expandIcon={expanded === 4 ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography fontWeight={500}>
                4. What happens after you finish my app?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Hopefully, the app is exactly what you dreamed of :) But apart
                from delivering a finished product, we are happy to provide you
                with technical support and app maintenance should you need it.
                After all, we know our work inside out. Of course, if you want
                to maintain the app by yourself the source code and all
                technical data are at your disposal, but even in that case, feel
                free to contact us if you need any help.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={expanded === 5 ? 1 : 0}
            onChange={() => handleCollapse(5)}
            expanded={expanded === 5}
          >
            <AccordionSummary
              expandIcon={expanded === 5 ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography fontWeight={500}>
                5. Can the software be upgraded in the future as new technology
                becomes available?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Of course! We are always happy to improve, upgrade, and further
                develop our work.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={expanded === 6 ? 1 : 0}
            onChange={() => handleCollapse(6)}
            expanded={expanded === 6}
          >
            <AccordionSummary
              expandIcon={expanded === 6 ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography fontWeight={500}>
                6. What if I come to you with a product that I want to improve?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We’ll be more than happy to help you with that. Beginning with a
                UX Audit (if applicable) through improving your system
                architecture and adding new features.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={expanded === 7 ? 1 : 0}
            onChange={() => handleCollapse(7)}
            expanded={expanded === 7}
          >
            <AccordionSummary
              expandIcon={expanded === 7 ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography fontWeight={500}>
                7. How much do you charge?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                It depends on many things: project scope, time spent on the
                project, and technological issues. We know it sounds vague but
                it’s simply honest. Remember that each software project is
                different, especially if you have a particularly original idea.
                We do not want to give you an unrealistic estimate, instead, we
                want to provide you with the best quality possible within your
                budget. To learn more about our pricing method read our blog
                entry
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={expanded === 8 ? 1 : 0}
            onChange={() => handleCollapse(8)}
            expanded={expanded === 8}
          >
            <AccordionSummary
              expandIcon={expanded === 8 ? <RemoveIcon /> : <AddIcon />}
            >
              <Typography fontWeight={500}>
                8. What is your experience?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We have more than 10 years of business experience. We&apos;ve
                worked with and completed projects for leading retailers,
                start-ups, real estate, telecommunications, etc… If you have any
                questions that we haven’t covered here, or if you would like to
                discuss how we can help your organization, please do not
                hesitate to contact us!
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Container>
  );

  const _renderContactUs = () => (
    <Container
      component="main"
      sx={{
        borderRadius: 5,
        p: 3,
        width: { xs: 0.8, md: 0.4 },
        border: '1px dashed lightgray',
      }}
    >
      <Grid container flexDirection="column" rowGap={5}>
        <Grid item xs={12}>
          <Typography
            textAlign="center"
            sx={{ fontSize: '32px', fontWeight: 700 }}
          >
            Still Have Questions?
          </Typography>
          <Typography sx={bannerText}>
            Please describe your case to receive the most accurate advice.
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="ebonyClay"
            sx={{ fontWeight: 700, fontSize: '0.875rem' }}
            endIcon={<ContactPhoneIcon />}
            onClick={() => navigate(ROUTERS.CONTACT)}
          >
            Contact Us
          </Button>
        </Grid>
      </Grid>
    </Container>
  );

  const _renderResearchSolution = () => (
    <Container
      component="main"
      sx={{ borderRadius: 5, p: 3, display: { xs: 'none', md: 'block' } }}
    >
      <Grid container rowGap={4}>
        <Grid item xs={12}>
          <Flip left cascade>
            <Typography
              textAlign="center"
              sx={{ fontSize: '32px', fontWeight: 700 }}
            >
              Our Services
            </Typography>
            <Typography sx={bannerText}>
              We are a leading IT company committed to delivering cutting-edge
              solutions to meet your digital needs. Our diverse range of
              services is designed to empower your business, enhance efficiency,
              and ensure a seamless digital experience. Here’s a glimpse of what
              we offer:
            </Typography>
          </Flip>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            sx={{
              boxShadow: '-24px 24px 72px -8px rgba(145, 158, 171, 0.24)',
              ...paperTech,
              height: '100%',
              mt: 0,
              width: 1,
              display: 'flex',
            }}
          >
            <Tabs
              orientation="vertical"
              variant="standard"
              value={selectedTab}
              onChange={handleChange}
              sx={{ borderRight: 1, borderColor: 'divider', width: 300 }}
            >
              {_.map(SERVICE_LIST, (item: string, index: number) => (
                <Tab
                  key={index}
                  label={
                    <Typography sx={{ fontWeight: 500 }} component="div">
                      <Flip left>{item}</Flip>
                    </Typography>
                  }
                  id={`vertical-tab-${index}`}
                />
              ))}
            </Tabs>
            {_.map(SERVICE_ITEM, (item: any, index: number) => (
              <Box
                component="div"
                key={index}
                role="tabpanel"
                hidden={selectedTab !== index}
                id={`vertical-tabpanel-${index}`}
                sx={{ width: '-webkit-fill-available' }}
              >
                {selectedTab === index && (
                  <AnimatePresence mode="wait">
                    <motion.div
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Grid container rowGap={2}>
                        {_.map(item, (service: string, innerKey: number) => (
                          <Grid xs={12} item key={service + innerKey}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Checkbox
                                checked
                                icon={<WorkspacePremiumIcon />}
                                checkedIcon={<WorkspacePremiumIcon />}
                                color="info"
                              />
                              <Typography>{service}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </motion.div>
                  </AnimatePresence>
                )}
              </Box>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );

  const _renderCategories = () => (
    <Box sx={{ background: 'rgb(244, 246, 248)' }}>
      <Container component="main" sx={{ borderRadius: 5, p: 3 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={4}>
            <Flip left>
              <Typography
                sx={{
                  fontSize: '38px',
                  fontWeight: 700,
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                Featured Category
              </Typography>
            </Flip>
            <Flip right>
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 400,
                  color: CommonColors.shuttleGray,
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                Since wire-frame renderings are relatively simple and fast to
                calculate, they are often used in cases
              </Typography>
            </Flip>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container sx={{ p: 2 }} spacing={2}>
              {_.map(categories, (item: any, key: number) => (
                <Grid item xs={6} md={4} key={key}>
                  <Paper
                    sx={categoryCard}
                    elevation={0}
                    onClick={() =>
                      navigate(`${ROUTERS.PORTFOLIO}?category=${item?.key}`)
                    }
                  >
                    {item?.value > 5 && (
                      <LocalFireDepartmentIcon className="hot" />
                    )}
                    <Typography className="pr-title">{item?.key}</Typography>
                    <Typography className="pr-number">
                      {item?.value || 0} projects
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const renderMain = () => {
    return (
      <Box sx={mainWrapper}>
        <Helmet>
          <title>FLOW IDRA</title>
        </Helmet>
        {_renderBanner()}
        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          {_renderFeatures()}
        </motion.div>
        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          {_renderFeatured()}
        </motion.div>
        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          {_renderCategories()}
        </motion.div>
        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          {_renderResearchSolution()}
        </motion.div>
        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          {_renderClients()}
        </motion.div>
        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          {_renderFAQ()}
        </motion.div>
        <motion.div whileInView="onscreen" viewport={{ once: true }}>
          <Fade right>{_renderContactUs()}</Fade>
        </motion.div>
      </Box>
    );
  };

  return renderMain();
};

export default HomePage;
