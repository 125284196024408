import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { Button, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Utils from '@/Utils';
import { Alert } from '@/Widgets';
import Constants from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import { RoundedContainer } from '@/Components/Common';

import { FileTemplateAction } from '@/Actions';
import { FileTemplateDataTable } from '@/Components/LayoutPart/DataTable';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { IFileTemplate } from '@/Interfaces/FileTemplate.interface';
const initialFilter: any = {
  page: 1,
  limit: 10,
};

const { ROUTERS, MODULE_API } = Constants;
const { clearFileTemplate, fetchFileTemplates, deleteFileTemplate } =
  FileTemplateAction;

const ListConfigSystem: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const { setValue, getValues } = useForm({
    defaultValues: initialFilter,
  });

  const meta: IMetaStructure = useSelector((state: RootState) =>
    get(state.FILE_TEMPLATE, 'meta')
  );
  const payload: IFileTemplate[] = useSelector((state: RootState) =>
    get(state.FILE_TEMPLATE, 'payload')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.FILE_TEMPLATE, 'requestIsLoading')
  );

  const fetchPayload = (filterParams?: any) => {
    const resolverFilter = Utils.validateFilters(filterParams);
    dispatch(fetchFileTemplates(resolverFilter));
  };
  const isAcceptFetch = Utils.isValidPermission(
    MODULE_API.FILE_TEMPLATES.FETCH_FILE_TEMPLATES
  );
  const isAcceptCreate = Utils.isValidPermission(
    MODULE_API.FILE_TEMPLATES.CREATE_FILE_TEMPLATE
  );

  React.useEffect(() => {
    if (!isAcceptFetch) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizedToFetchFileTemplate'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else fetchPayload();
    return () => {
      dispatch(clearFileTemplate());
    };
  }, []);

  const onPageAction = async (value: number, type: 'limit' | 'page') => {
    const currentFilter = getValues();
    const newFilter = {
      ...currentFilter,
      [type]: value,
    };

    setValue(type, value);
    if (type === 'limit') {
      setValue('page', 1);
      Object.assign(newFilter, { page: 1 });
    }

    fetchPayload(newFilter);
  };

  const onRowAction = async (
    item: IFileTemplate,
    type: 'details' | 'edit' | 'delete'
  ) => {
    if (type === 'details' || type === 'edit')
      Utils.redirect({ pathname: ROUTERS.FILE_TEMPLATES_DETAILS, id: item.id });

    if (type === 'delete') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningBeforeDeleteFileTemplate'),
      });
      if (isAgree === 'ok') {
        const currentFilter = getValues();
        const resolveFilter = Utils.validateFilters(currentFilter);
        dispatch(deleteFileTemplate(item.id, resolveFilter));
      }
    }
  };

  const _renderTopSection = () => (
    <Stack direction="row" sx={{ justifyContent: 'space-between', mb: 2 }}>
      <Typography variant="h2">{t('title.fileTemplates')}</Typography>
      {isAcceptCreate ? (
        <Button
          variant="contained"
          onClick={() => Utils.redirect(ROUTERS.FILE_TEMPLATES_CREATE)}
        >
          {t('button.create')}
        </Button>
      ) : null}
    </Stack>
  );

  const _renderBottomSection = () => (
    <RoundedContainer>
      <FileTemplateDataTable
        payload={payload}
        meta={meta}
        onPageAction={onPageAction}
        onRowAction={onRowAction}
        isLoading={isLoading}
      />
    </RoundedContainer>
  );

  return (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default ListConfigSystem;
