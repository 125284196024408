import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { validate as uuidValidate } from 'uuid';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Utils from '@/Utils';
import { Alert } from '@/Widgets';
import Constants from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import { RoundedContainer, TextField, CodeMirror } from '@/Components/Common';
import { FileTemplateAction } from '@/Actions';
import {
  IFileTemplate,
  IUpdateFileTemplate,
} from '@/Interfaces/FileTemplate.interface';
import { GenerateFileTemplatePopup } from '@/Components/Popup';

interface IForm {
  template: string;
  keys: {
    label: string;
    value: string;
  }[];
}

const { ROUTERS, MODULE_API } = Constants;
const { clearFileTemplate, updateFileTemplate, getFileTemplateByID } =
  FileTemplateAction;

const EditFileTemplate: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const { id } = useParams();

  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.FILE_TEMPLATE, 'requestIsLoading')
  );
  const details: IFileTemplate = useSelector((state: RootState) =>
    get(state.FILE_TEMPLATE, 'details')
  );

  const isAcceptCreate = Utils.isValidPermission(
    MODULE_API.FILE_TEMPLATES.UPDATE_FILE_TEMPLATE
  );
  const [templatePayload, setTemplatePayload] = React.useState<IForm | null>(
    null
  );

  const schema = yup.object().shape(
    {
      name: yup.string().trim().required(t('message.nameRequired')),
      template: yup
        .string()
        .when('isContainTemplate', ([isContainTemplate]) => {
          if (isContainTemplate)
            return yup.string().trim().required(t('message.templateRequired'));
          return yup.string();
        }),
      keys: yup.array().when('keys', ([keys]) => {
        if (keys && keys.length > 0)
          return yup.array(
            yup.object().shape({
              key: yup.string().trim().required(t('message.keyRequired')),
            })
          );
        return yup.array().min(1, t('message.keysLeastAtOneItem'));
      }),
    },
    [
      ['isContainTemplate', 'isContainTemplate'],
      ['keys', 'keys'],
    ]
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    setValue,
  } = useForm<IUpdateFileTemplate>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      isContainTemplate: false,
      template: '',
      keys: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'keys',
    control,
    rules: {
      minLength: 1,
    },
  });

  React.useEffect(() => {
    if (!isAcceptCreate) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizedToEditFileTemplate'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else if (id && uuidValidate(id)) dispatch(getFileTemplateByID(id));
    return () => {
      dispatch(clearFileTemplate());
    };
  }, []);

  React.useEffect(() => {
    if (details?.id) {
      const { isContainTemplate, keys, name, template } = details;
      const resolveKeys = keys.map((item: string) => ({ key: item }));
      setValue('id', details.id);
      setValue('isContainTemplate', isContainTemplate);
      setValue('name', name);
      setValue('template', template);
      setValue('keys', resolveKeys);
    }
  }, [details]);

  const watchIsContainTemplate = watch('isContainTemplate');

  const onSubmit = (data: IUpdateFileTemplate) => {
    const resolveKeys: string[] = data.keys.map(
      (item: { key: string }) => item.key
    );
    dispatch(updateFileTemplate({ ...data, keys: resolveKeys }));
  };

  const onAddNewKey = () => {
    const newSubConfig = {
      key: '',
    };
    setError('keys', { message: '' });
    append(newSubConfig);
  };

  const onRemoveSubConfig = async (index: number) => {
    const isAgree = await Alert({
      type: 'WARNING',
      message: t('popup.warningBeforeRemoveKey'),
    });
    if (isAgree === 'ok') remove(index);
  };

  // const onTest = () => {
  //   const payload = getValues();
  //   const resolveKeys: { label: string; value: string }[] = payload.keys.map(
  //     (item: { key: string }) => ({
  //       label: item.key,
  //       value: '',
  //     })
  //   );
  //   const resolvePayload = {
  //     ...payload,
  //     keys: resolveKeys,
  //   };
  //   setTemplatePayload(resolvePayload);
  // };

  const _renderPopup = () => {
    return (
      <GenerateFileTemplatePopup
        payload={templatePayload}
        onClose={() => setTemplatePayload(null)}
      />
    );
  };

  const _renderTopSection = () => (
    <Typography variant="h2">{t('title.editFileTemplate')}</Typography>
  );

  const _renderKeys = () => {
    const message = errors.keys?.message;
    return (
      <Stack direction="column">
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 1 }}>
          {t('label.keys')}{' '}
          <Typography component="span" color="error" sx={{ fontSize: '13px' }}>
            (*)
          </Typography>
        </InputLabel>
        <Grid container spacing={2}>
          {fields &&
            fields.map(
              (
                item: {
                  id: string;
                  key: string;
                },
                index: number
              ) => {
                return (
                  <Grid item sm={4} xs={4} md={4} xl={4} lg={4} key={item.id}>
                    <Stack direction="row">
                      <Controller
                        name={`keys.${index}.key`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            value={field.value}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => field.onChange(event.target.value)}
                            placeholder="VITE_BE_URL"
                            required
                            message={errors.keys?.[index]?.key?.message}
                            style={{ mb: 0, mr: 1 }}
                          />
                        )}
                      />
                      <Tooltip title={t('tooltip.clickToRemoveThisKey')}>
                        <IconButton onClick={() => onRemoveSubConfig(index)}>
                          <RemoveCircleOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Grid>
                );
              }
            )}
          <Grid item sm={4} xs={4} md={4} xl={4} lg={4}>
            <Button
              variant="contained"
              sx={{ width: '100%', maxWidth: '300px' }}
              onClick={() => onAddNewKey()}
            >
              {t('button.add')}
            </Button>
          </Grid>
        </Grid>
        {message ? (
          <FormHelperText error={Boolean(message)}>{message}</FormHelperText>
        ) : null}
      </Stack>
    );
  };

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Stack direction="column">
            <Stack direction="row" mb={1}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.onChange(event.target.value)
                    }
                    label={t('label.name')}
                    placeholder="Environment Backend"
                    message={errors.name?.message}
                    required
                    style={{ width: '50%', flex: 'unset', mr: 1 }}
                  />
                )}
              />
            </Stack>
            {_renderKeys()}
            <Controller
              name="isContainTemplate"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={false}
                      value={field.value}
                      checked={field.value}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (!event.target.checked)
                          setError('template', { message: '' });
                        field.onChange(event.target.checked);
                      }}
                    />
                  }
                  label={t('label.isContainTemplate')}
                  sx={{
                    userSelect: 'none',
                    span: {
                      fontSize: '14px',
                    },
                  }}
                />
              )}
            />
            {watchIsContainTemplate ? (
              <Controller
                name="template"
                control={control}
                render={({ field }) => (
                  <CodeMirror
                    value={field.value}
                    label={t('label.template')}
                    onChange={(newValue: string) => field.onChange(newValue)}
                    required
                    message={errors.template?.message}
                  />
                )}
              />
            ) : null}
          </Stack>

          <Stack direction="row" justifyContent="flex-end" sx={{ mt: '50px' }}>
            <LoadingButton
              onClick={() => Utils.redirect(ROUTERS.FILE_TEMPLATES)}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              {t('button.cancel')}
            </LoadingButton>
            {/* <LoadingButton onClick={() => onTest()} sx={{ mr: 1 }}>
              {t('button.test')}
            </LoadingButton> */}
            {isAcceptCreate && (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                {t('button.save')}
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </RoundedContainer>
    );
  };

  return (
    <>
      {_renderPopup()}
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default EditFileTemplate;
