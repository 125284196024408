import { toast } from 'react-toastify';

import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';
import { ICreateFeedbackGuest } from '@/Interfaces/FeedbackGuests.interface';

const { ACTION_TYPES } = CONSTANTS;

// SINGLE ACTIONS
const feedbackGuestsIsRequest = () => {
  return {
    type: ACTION_TYPES.FEEDBACK_GUESTS_IS_REQUEST,
  };
};

const setFeedbackGuestsMeta = (payload: any) => {
  return {
    type: ACTION_TYPES.SET_FEEDBACK_GUESTS_META,
    payload,
  };
};

const setDefaultLogsReducer = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_FEEDBACK_GUESTS_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchFeedbackGuestsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_FEEDBACK_GUESTS_SUCCESS,
    payload,
  };
};

const fetchFeedbackGuestsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_FEEDBACK_GUESTS_FAILURE,
  };
};

const fetchFeedbackGuests = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(feedbackGuestsIsRequest());
    await API.fetchFeedbackGuests(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFeedbackGuestsFailure());
        else {
          const resolveResult: { items: any; meta: any } = result as {
            items: any;
            meta: any;
          };
          dispatch(setFeedbackGuestsMeta(resolveResult.meta));
          dispatch(fetchFeedbackGuestsSuccess(resolveResult.items));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(fetchFeedbackGuestsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createFeedbackGuestsSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_FEEDBACK_GUEST_SUCCESS,
  };
};

const createFeedbackGuestsFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_FEEDBACK_GUEST_FAILURE,
  };
};

const createFeedbackGuest = (payload: ICreateFeedbackGuest) => {
  return async (dispatch: any) => {
    dispatch(feedbackGuestsIsRequest());
    await API.createFeedbackGuest(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFeedbackGuestsFailure());
        else {
          dispatch(createFeedbackGuestsSuccess());
          toast.success('Create feedback successfully!');
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(createFeedbackGuestsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  fetchFeedbackGuests,
  createFeedbackGuest,
  setDefaultLogsReducer,
};
