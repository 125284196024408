import ReactDOM from 'react-dom/client';
import '@fontsource/barlow/100.css';
import '@fontsource/barlow/200.css';
import '@fontsource/barlow/300.css';
import '@fontsource/barlow/400.css';
import '@fontsource/barlow/500.css';
import '@fontsource/barlow/700.css';
import '@fontsource/barlow/800.css';
import '@fontsource/barlow/900.css';
import '@fontsource/barlow/100-italic.css';
import '@fontsource/barlow/200-italic.css';
import '@fontsource/barlow/300-italic.css';
import '@fontsource/barlow/400-italic.css';
import '@fontsource/barlow/500-italic.css';
import '@fontsource/barlow/700-italic.css';
import '@fontsource/barlow/800-italic.css';
import '@fontsource/barlow/900-italic.css';
import './index.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Root from './Routers';
import LocaleConfig from './Locale';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'animate.css/animate.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

dayjs.extend(utc);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<Root />);

document.addEventListener('DOMContentLoaded', () => {
  LocaleConfig.initTranslations();
});
