import React from 'react';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { RootState, useTypedDispatch } from '@/store';
import Utils from '@/Utils';
import CONSTANTS from '@/Constants';
import Alert from '@/Widgets/Alert';
import { DailyReportActions, ProjectActions } from '@/Actions';

import {
  DatePicker,
  Dropdown,
  RoundButton,
  RoundedContainer,
} from '@/Components/Common';
import DailyReportPopup from '@/Components/Popup/DailyReportPopup';
import { ProjectListDataTable } from '@/Components/LayoutPart/DataTable';

import { IMetaStructure } from '@/Interfaces/Common.interface';
import {
  IProjectResponseStructure,
  IProjectFilter,
} from '@/Interfaces/Project.interface';

interface IOption {
  label: string;
  value: string;
}

const { ROUTERS, ENUMS, MODULE_API } = CONSTANTS;

const { fetchProjects, setDefaultReducerProject, deleteProject } =
  ProjectActions;
const { resetDailyReportStatus } = DailyReportActions;

const initialFilter: IProjectFilter = {
  startDate: null,
  status: ENUMS.Status.ALL_TYPE,
  type: ENUMS.ProjectType.ALL_TYPE,
  keyword: '',
  page: 1,
  limit: 10,
};

const status = [
  { value: ENUMS.ProjectStatus.ALL_TYPE, label: 'allType' },
  { value: ENUMS.ProjectStatus.PROPOSAL, label: 'proposal' },
  { value: ENUMS.ProjectStatus.PENDING, label: 'pending' },
  { value: ENUMS.ProjectStatus.IN_PROGRESS, label: 'inProgress' },
  { value: ENUMS.ProjectStatus.DONE, label: 'done' },
];

const ProjectsList: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: initialFilter,
  });

  const meta: IMetaStructure = useSelector((state: RootState) =>
    get(state.PROJECT, 'meta')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.PROJECT, 'requestIsLoading')
  );

  const payload: IProjectResponseStructure[] = useSelector((state: RootState) =>
    get(state.PROJECT, 'payload')
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const fetchPayload = (filterParams: any) => {
    const resolverFilter = Utils.validateFilters(filterParams);
    dispatch(fetchProjects(resolverFilter));
  };

  const isAcceptCreate = Utils.isValidPermission(
    MODULE_API.PROJECT.CREATE_PROJECT
  );
  const isAcceptFetch = Utils.isValidPermission(
    MODULE_API.PROJECT.FETCH_PROJECTS
  );

  React.useEffect(() => {
    if (!isAcceptFetch) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForFetchProjects'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else fetchPayload(initialFilter);

    return () => {
      dispatch(setDefaultReducerProject());
    };
  }, []);

  const statusOptions = React.useMemo(() => {
    const options: IOption[] = [];
    forEach(status, (option: IOption) =>
      options.push({ label: t(`menu.${option.label}`), value: option.value })
    );
    return options;
  }, [status]);

  const onReset = () => {
    reset();
    fetchPayload(initialFilter);
  };

  const onSearch = (data: IProjectFilter) => {
    fetchPayload(data);
  };

  const onRowAction = async (
    project: IProjectResponseStructure,
    action: 'edit' | 'remove' | 'report'
  ) => {
    const { id } = project;
    if (!project)
      return Alert({
        type: 'ERROR',
        message: t('popup.errorGettingProjectInformation'),
      });

    if (action === 'edit')
      Utils.redirect({ pathname: ROUTERS.PROJECT_MODIFY, id });

    if (action === 'report') {
      setSearchParams({ project: project.id });
    }

    if (action === 'remove') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningDeleteProject'),
      });
      if (isAgree === 'ok') {
        const currentPage = getValues('page');
        const currentFilter = getValues();
        const resolverFilter = Utils.validateFilters(currentFilter);
        if (payload.length === 1 && currentPage !== 1) {
          setValue('page', 1);
          resolverFilter.page = 1;
        }
        await dispatch(deleteProject(id, resolverFilter));
      }
    }
    return null;
  };

  const onPageAction = async (value: number, type: 'limit' | 'page') => {
    const currentFilter = getValues();
    const newFilter = {
      ...currentFilter,
      [type]: value,
    };

    setValue(type, value);
    if (type === 'limit') {
      setValue('page', 1);
      Object.assign(newFilter, { page: 1 });
    }

    fetchPayload(newFilter);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') event.preventDefault();
  };

  const _renderPopup = () => {
    return (
      <DailyReportPopup
        onClose={() => {
          if (searchParams.has('project')) {
            searchParams.delete('project');
            setSearchParams(searchParams);
          }
          dispatch(resetDailyReportStatus());
          onSearch(getValues());
        }}
      />
    );
  };

  const _renderTopSection = () => {
    return (
      <Stack sx={{ mb: 2 }} direction="column">
        <Typography variant="h2">{t('title.projects')}</Typography>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row" sx={{ flex: 1 }}>
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  size="small"
                  onKeyDown={onKeyDown}
                  onKeyPress={onKeyDown}
                  onChange={(e: any) => field.onChange(e.target.value)}
                  placeholder={t('placeholder.keyword')}
                  sx={{ mr: 1, maxWidth: '190px' }}
                />
              )}
            />
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  value={field.value}
                  onChange={(date: any) => field.onChange(date)}
                  placeholder={t('placeholder.fromDate')}
                  sx={{ mr: 1, maxWidth: '190px' }}
                />
              )}
            />
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Dropdown
                  sx={{ mr: 1, maxWidth: '190px' }}
                  value={field.value || ''}
                  placeholder="To"
                  options={statusOptions}
                  onChange={(e: any) => field.onChange(e)}
                />
              )}
            />

            <Button
              sx={{ mr: 1 }}
              onClick={handleSubmit(onSearch)}
              variant="contained"
            >
              {t('button.filter')}
            </Button>
            <Button onClick={() => onReset()} variant="outlined">
              {t('button.reset')}
            </Button>
          </Stack>
          {isAcceptCreate && (
            <RoundButton
              type="contained"
              size="large"
              label={t('button.create')}
              onClick={() => Utils.redirect(ROUTERS.PROJECT_CREATE)}
            />
          )}
        </Stack>
      </Stack>
    );
  };

  const _renderBottomSection = () => (
    <RoundedContainer>
      <ProjectListDataTable
        meta={meta}
        payload={payload}
        onRowAction={onRowAction}
        onPageAction={onPageAction}
        isLoading={isLoading}
      />
    </RoundedContainer>
  );

  const renderMain = () => (
    <>
      {_renderPopup()}
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );

  return renderMain();
};
export default ProjectsList;
