import CONSTANTS from '@/Constants';
import { IMetaStructure } from '../Interfaces/Common.interface';

const { ACTION_TYPES } = CONSTANTS;

const initialMeta: IMetaStructure = {
  currentPage: 1,
  itemCount: 1,
  itemsPerPage: 10,
  totalItems: 1,
  totalPages: 1,
};

const initialPagination: any = {};

const initialState = {
  isFetchLoading: false,
  isGetLoading: false,
  isActionLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  submitIsSuccess: false,
  details: {},
  payload: [],
  myReport: [],
  meta: initialMeta,
  pagination: initialPagination,
  myMeta: initialMeta,
  myPagination: initialPagination,
};

export default (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_DAILY_TASK_FETCH_IS_LOADING:
      return {
        ...state,
        isFetchLoading: true,
      };
    case ACTION_TYPES.SET_DAILY_TASK_GET_IS_LOADING:
      return {
        ...state,
        isGetLoading: true,
      };
    case ACTION_TYPES.SET_DAILY_TASK_ACTION_IS_LOADING:
      return {
        ...state,
        isGetLoading: true,
      };

    case ACTION_TYPES.RESET_DAILY_TASK_DETAIL:
      return {
        ...state,
        requestIsLoading: false,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        details: {},
      };
    case ACTION_TYPES.RESET_DAILY_TASK_STATUS:
      return {
        ...state,
        requestIsSuccess: false,
        submitIsSuccess: false,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.RESET_DAILY_TASK_REDUCER:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        submitIsSuccess: false,
        details: {},
        payload: [],
        myReport: [],
      };
    case ACTION_TYPES.SET_META_DAILY_TASK:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_PAGINATION_DAILY_TASK:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_META_MY_DAILY_TASK:
      return {
        ...state,
        myMeta: payload,
      };
    case ACTION_TYPES.SET_PAGINATION_MY_DAILY_TASK:
      return {
        ...state,
        myPagination: payload,
      };

    case ACTION_TYPES.FETCH_DAILY_TASKS_SUCCESS:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload,
      };
    case ACTION_TYPES.FETCH_DAILY_TASKS_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.GET_MY_DAILY_TASKS_SUCCESS:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        myReport: payload,
      };
    case ACTION_TYPES.GET_MY_DAILY_TASKS_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.GET_DAILY_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.GET_DAILY_TASK_BY_ID_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.CREATE_DAILY_TASK_SUCCESS:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        submitIsSuccess: true,
      };
    case ACTION_TYPES.CREATE_DAILY_TASK_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        submitIsSuccess: false,
      };

    case ACTION_TYPES.UPDATE_DAILY_TASK_SUCCESS:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        submitIsSuccess: true,
      };
    case ACTION_TYPES.UPDATE_DAILY_TASK_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        submitIsSuccess: false,
      };

    case ACTION_TYPES.DELETE_DAILY_TASK_SUCCESS:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.DELETE_DAILY_TASK_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    default:
      return state;
  }
};
