import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-multi-lang';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import {
  Typography,
  Box,
  Stack,
  Fade,
  Modal,
  InputLabel,
  TextField as TextFieldMui,
  InputAdornment,
  IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';

import Utils from '@/Utils';
import { Alert } from '@/Widgets';
import { RootState, useTypedDispatch } from '@/store';
import { WorkProgressActions, UserActions } from '@/Actions';
import CONSTANTS from '@/Constants';
import { TextField } from '@/Components/Common';
import TransferList from '@/Containers/MainContainer/WorkProgress/modify/transferList';
import { ICreateWorkProgress } from '@/Interfaces/WorkProgress.interface';
import { IUserInformationDetailsStructure } from '@/Interfaces/User.interface';

interface ISectionProps {
  open: boolean;
  onClose(): void;
}

const initialPayload: ICreateWorkProgress = {
  name: '',
  wasAssigned: [],
};

const { MODULE_API } = CONSTANTS;

const { createWorkProgress, fetchWorkProgress, clearStatusWorkProgress } =
  WorkProgressActions;
const { fetchUser } = UserActions;

const AddWorkProgressPopup: React.FC<ISectionProps> = ({ open, onClose }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const listUser: IUserInformationDetailsStructure[] = useSelector(
    (state: RootState) => _.get(state.USER, 'payload')
  );
  const isCreateWorkProgressSuccess = useSelector((state: RootState) =>
    _.get(state.WORK_PROGRESS, 'createWorkProgressIsSuccess')
  );
  const [message, setMessage] = React.useState<string>('');
  const [resolveSearchUsers, setResolveSearchUsers] = React.useState<any>([]);
  const [searched, setSearched] = React.useState('');

  const schema = yup
    .object()
    .shape({
      name: yup.string().trim().required(t('message.workProgressNameRequired')),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialPayload,
  });

  React.useEffect(() => setResolveSearchUsers(listUser), [listUser]);

  const clearSearched = () => {
    setSearched('');
    setResolveSearchUsers(listUser);
  };
  const onCancel = () => {
    dispatch(clearStatusWorkProgress());
    reset();
    setMessage('');
    clearSearched();
    onClose();
  };

  React.useEffect(() => {
    if (open) {
      const isAceeptFetchUser = Utils.isValidPermission(
        MODULE_API.USER.FETCH_USERS
      );
      if (!isAceeptFetchUser) {
        Alert({
          type: 'ERROR',
          message: t('popup.notAuthorizeForFetchUserToCreateWorkProgress'),
        });
        onCancel();
      } else
        dispatch(
          fetchUser({
            status: 'active',
            page: 1,
            limit: 0,
            orderBy: 'ASC',
          })
        );
    }
  }, [open]);

  React.useEffect(() => {
    if (isCreateWorkProgressSuccess) {
      onCancel();
      dispatch(fetchWorkProgress({ keyword: '', page: 1, limit: 10 }));
    }
  }, [isCreateWorkProgressSuccess]);

  // Resolve search user
  const searchAssignedList = (searchedVal: any) => {
    const resultsFilter = _.filter(listUser, (item: any) => {
      const label = _.get(item, 'userData.fullName');
      if (!label) return;
      const lowerLabel = _.toLower(searchedVal.target.value)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const lowerResult = _.toLower(label)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const result = lowerResult.indexOf(lowerLabel);
      return result > -1;
    });
    setSearched(searchedVal.target.value.toLowerCase());
    setResolveSearchUsers(resultsFilter);
  };

  const onSubmit = (data: ICreateWorkProgress) => {
    if (_.isEmpty(data.wasAssigned))
      setMessage(t('message.announcementreceiverRequired'));
    else dispatch(createWorkProgress(data));
  };

  const _renderForm = () => (
    <Box component="form" sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              label={t('label.workProgressName')}
              placeholder={t('label.workProgressName')}
              value={field.value}
              message={errors.name?.message}
              onChange={(e: any) => field.onChange(e)}
              required
            />
          )}
        />
        <Stack>
          <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
            {t('label.assignedBy')}{' '}
            <Typography component="span" color="error">
              (*)
            </Typography>
          </InputLabel>
          <Stack direction="row">
            <Stack flex={1} sx={{ mb: 1 }}>
              <TextFieldMui
                value={searched}
                placeholder={t('label.searchUsers')}
                variant="standard"
                onChange={(searchVal) => searchAssignedList(searchVal)}
                sx={{
                  width: '80%',
                  maxWidth: 250,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          clearSearched();
                        }}
                      >
                        <ClearIcon sx={{ height: '20px' }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack flex={1} />
          </Stack>
          <Controller
            name="wasAssigned"
            control={control}
            render={({ field }) => (
              <TransferList
                payload={resolveSearchUsers}
                onUnfocus={(data: any) => {
                  field.onChange(data);
                  setMessage('');
                }}
                message={message}
                sxContainer={{
                  '@media (max-height: 650px)': {
                    height: 300,
                  },
                }}
              />
            )}
          />
          <Typography color="#d32f2f" mt={0.5} fontSize="0.75rem">
            {message}
          </Typography>
        </Stack>
      </Box>
      <Stack sx={{ float: 'right', marginTop: 2 }} direction="row">
        <LoadingButton
          sx={{ mr: 1 }}
          onClick={() => onCancel()}
          variant="outlined"
        >
          {t('button.cancel')}
        </LoadingButton>
        <LoadingButton variant="contained" onClick={handleSubmit(onSubmit)}>
          {t('button.save')}
        </LoadingButton>
      </Stack>
    </Box>
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            minHeight: '280px',
            transform: 'translate(-50%, -50%)',
            width: 750,
            background: 'white',
            boxShadow: 24,
            p: 2,
            borderRadius: '5px',
          }}
        >
          <Typography mb="15px" fontSize="18px" fontWeight="500">
            {t('title.addWorkProgress')}
          </Typography>
          {_renderForm()}
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddWorkProgressPopup;
