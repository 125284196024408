import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang';
import dayjs from 'dayjs';

import Utils from '@/Utils';
import CONSTANTS from '@/Constants';
import { ChipStatus, StickyHeadTable } from '@/Components/Common';

import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { getSavedUserData } from '@/Utils/Cookie.utils';

interface IColumnStructure {
  id: 'no' | 'created_by' | 'created_at' | 'content' | 'status' | 'action';
  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

interface IDataTableStructure {
  no: number;
  created_by: string;
  created_at: string;
  content: JSX.Element;
  status: JSX.Element;
  action: JSX.Element;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'created_by', label: 'createdBy', minWidth: 100, align: 'left' },
  { id: 'created_at', label: 'createdAt', minWidth: 100, align: 'center' },
  { id: 'content', label: 'content', minWidth: 300, align: 'left' },
  { id: 'status', label: 'status', minWidth: 50, align: 'center' },
  { id: 'action', label: 'action', minWidth: 10, align: 'center' },
];

const createDataTable = (
  no: number,
  created_by: string,
  created_at: string,
  content: JSX.Element,
  status: JSX.Element,
  action: JSX.Element
): IDataTableStructure => {
  return {
    no,
    created_by,
    created_at,
    content,
    status,
    action,
  };
};

interface ISectionProps {
  payload: any;
  onRowAction(stepItem: any, action: 'edit' | 'status' | 'delete'): void;
  isLoading?: boolean;
}

const { MODULE_API } = CONSTANTS;
const StepWorkProgressDataTable: React.FC<ISectionProps> = ({
  payload,
  isLoading = false,
  onRowAction,
}) => {
  const [rows, setRows] = React.useState<IDataTableStructure[]>([]);
  const t = useTranslation();
  const isAcceptDeleteStepApi = Utils.isValidPermission(
    MODULE_API.WORK_PROGRESS.DELETE_STEP
  );
  const isAcceptViewStepApi = Utils.isValidPermission(
    MODULE_API.WORK_PROGRESS.GET_STEP_BY_ID
  );
  const userData = getSavedUserData();

  React.useEffect(() => {
    const resolveRows: IDataTableStructure[] = [];
    if (!_.isEmpty(payload)) {
      _.forEach(payload, (stepItem: any, index: number) => {
        const { stepProgressBeCreatedBy, createdAt, content, status } =
          stepItem;
        const resolveCreateAt = dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss');
        const resolveRowIndex = index + 1;
        const resolveStatus = status === 'done' ? 'processing' : 'done';
        let isCreatedStep = false;
        if (stepItem.stepProgressBeCreatedBy?.email === userData?.email) {
          isCreatedStep = true;
        }

        resolveRows.push(
          createDataTable(
            resolveRowIndex,
            stepProgressBeCreatedBy.email,
            resolveCreateAt,
            <Typography
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                height: 1,
                maxWidth: 250,
              }}
            >
              {content}
            </Typography>,
            <Tooltip
              title={t('tooltip.clickToChangeStatus', {
                status: resolveStatus,
              })}
            >
              <Box
                component="div"
                onClick={() => {
                  onRowAction(stepItem, 'status');
                }}
                sx={{
                  '* :hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <ChipStatus label={status} />
              </Box>
            </Tooltip>,
            <Box>
              <Tooltip title={t('tooltip.viewDetails')}>
                <IconButton
                  onClick={() => onRowAction(stepItem, 'edit')}
                  disabled={isAcceptViewStepApi ? false : !isCreatedStep}
                >
                  <VisibilityOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('tooltip.clickToDeleteStep')}>
                <IconButton
                  onClick={() => onRowAction(stepItem, 'delete')}
                  disabled={isAcceptDeleteStepApi ? false : !isCreatedStep}
                >
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable columns={COLUMNS} rows={rows} isLoading={isLoading} />
  );
};

export default StepWorkProgressDataTable;
