interface ActionTypes {
  [key: string]: string;
}

const generateSyncActions = (actionList: string[]) => {
  const map: { [key: string]: string } = {};
  actionList.map((action) => {
    const name = action.trim();
    if (name !== '') {
      map[`${name}_SUCCESS`] = `${name}_SUCCESS`;
      map[`${name}_FAILURE`] = `${name}_FAILURE`;
    }
  });
  return map;
};

const _asyncActions: ActionTypes = generateSyncActions([
  'LOGIN',
  'SEND_RECOVERY_LINK',
  'CHANGE_PASSWORD',
  // PERMISSIONS
  'FETCH_PERMISSIONS',
  'FETCH_ROLES',
  'GET_ROLE_BY_ID',
  'UPDATE_ROLE',
  'CREATE_ROLE',
  'REMOVE_USER_ROLE',
  'FETCH_ROLE_OPTIONS',

  // USERS
  'FETCH_USERS',
  'GET_USER_BY_ID',
  'CREATE_USER',
  'RESET_PASSWORD',
  'CHANGE_PASSWORD',
  'EXPORT_USERS',
  'UPDATE_USER_INFORMATION',
  'ACTIVATE_USER',
  'DEACTIVATE_USER',
  'UPDATE_PROFILE',
  'UPDATE_AVATAR_USER',
  'UPDATE_AVATAR_PROFILE',
  'FETCH_USER_UNASSIGNED_ROLE',
  'ASSIGN_ROLE_FOR_USER',

  // PROJECTS
  'FETCH_PROJECTS',
  'GET_PROJECT_BY_ID',
  'CREATE_PROJECT',
  'UPDATE_PROJECT',
  'DELETE_PROJECT',
  'UPDATE_PROJECT_DOCUMENTATION',
  'FETCH_PROJECTS_NOT_CONFIG',

  // FOLDERS
  'FETCH_ROOT_FOLDERS',
  'FETCH_CHILD_FOLDERS',
  'ACTIVATE_FOLDER',
  'DEACTIVATE_FOLDER',
  'CREATE_FOLDER',
  'UPDATE_FOLDER',
  'DELETE_FOLDER',

  // MEDIA
  'FETCH_MEDIA',
  'UPLOAD_MEDIA',
  'DELETE_MEDIA',
  'DOWNLOAD_FILES',

  // LOGS
  'FETCH_LOGS',

  // DAILY REPORT
  'FETCH_DAILY_REPORTS',
  'CREATE_DAILY_REPORT',
  'UPDATE_DAILY_REPORT',
  'GET_DAILY_REPORT_BY_ID',
  'DELETE_DAILY_REPORT',
  'GET_REPORT_DETAILS_BY_DATE_AND_PROJECT',

  // PROJECT REPORTS
  'FETCH_PROJECT_REPORTS',

  // WIKI
  'FETCH_WIKI',
  'CREATE_WIKI',
  'UPDATE_WIKI',
  'GET_WIKI_BY_ID',
  'DELETE_WIKI',
  'UPDATE_ATTACHMENT_WIKI',

  // EVENT
  'FETCH_EVENTS',
  'FETCH_NEXT_EVENTS',
  'CREATE_EVENT',
  'UPDATE_EVENT',
  'DELETE_EVENT',

  // ATTENDANCE
  'FETCH_ATTENDANCES',
  'UPDATE_ATTENDANCES',
  'EXPORT_ATTENDANCES',

  // PROJECT MEMBER
  'FETCH_PROJECT_MEMBERS',
  'FETCH_UNASSIGNED_MEMBERS',
  'CREATE_PROJECT_MEMBER',
  'UPDATE_PROJECT_MEMBER',
  'GET_PROJECT_MEMBER_BY_ID',
  'DELETE_PROJECT_MEMBER',

  // ANNOUNCEMENT
  'FETCH_ANNOUNCEMENT',
  'GET_ANNOUNCEMENT_BY_ID',
  'CREATE_ANNOUNCEMENT',
  'UPDATE_ANNOUNCEMENT',
  'DELETE_ANNOUNCEMENT',
  'GET_FOR_USER_ANNOUNCEMENT',
  'UPDATE_TYPE_ANNOUNCEMENT',
  'GET_ALL_FOR_USER_ANNOUNCEMENT',
  'UPDATE_READ_ANNOUNCEMENT',
  // TAGS
  'FETCH_TAGS',
  'CREATE_TAG',
  'DELETE_TAG',

  // CRAWLING SYSTEM
  'FETCH_CRAWL_PROJECT',
  'GET_CRAWL_PROJECT_BY_ID',
  'CREATE_CRAWL_PROJECT',
  'UPDATE_CRAWL_PROJECT',
  'CAPTURE_IMAGE_PROJECT',
  'ACTIVATE_CRAWL_PROJECT',
  'DEACTIVATE_CRAWL_PROJECT',
  // FEEDBACK
  'FETCH_FEEDBACK',
  'CREATE_FEEDBACK',
  'ASSIGN_FEEDBACK',
  'GET_FEEDBACK_BY_ID',
  'GET_ALL_FOR_USER_FEEDBACKS',
  'CREATE_THREAD',
  'DELETE_THREAD',
  'UPDATE_THREAD',
  'ASSIGN_HANDLER',
  'UPDATE_STATUS',
  'UPDATE_FEEDBACK',
  'FETCH_FEEDBACK_DASHBOARD',

  // FEEDBACK GUESTS
  'FETCH_FEEDBACK_GUESTS',
  'CREATE_FEEDBACK_GUEST',

  // CONFIG SYSTEM
  'FETCH_CONFIG_SYSTEMS',
  'CREATE_CONFIG_SYSTEM',
  'UPDATE_CONFIG_SYSTEM',
  'UPDATE_DOCUMENTATION_CONFIG_SYSTEM',
  'GET_CONFIG_SYSTEM_BY_ID',
  'DELETE_CONFIG_SYSTEM',

  // FILE TEMPLATE
  'FETCH_FILE_TEMPLATES',
  'CREATE_FILE_TEMPLATE',
  'UPDATE_FILE_TEMPLATE',
  'GET_FILE_TEMPLATE_BY_ID',
  'DELETE_FILE_TEMPLATE',

  // WORK PROGRESS
  'FETCH_WORK_PROGRESS',
  'GET_WORK_PROGRESS_BY_ID',
  'CREATE_WORK_PROGRESS',
  'UPDATE_WORK_PROGRESS',
  'UPDATE_STATUS_WORK_PROGRESS',
  'DELETE_WORK_PROGRESS',
  'CREATE_STEP_WORK_PROGRESS',
  'GET_STEP_WORK_PROGRESS_BY_ID',
  'UPDATE_STEP_WORK_PROGRESS',
  'DELETE_STEP_WORK_PROGRESS',

  // TOOL
  'FETCH_TOOLS',
  'CREATE_TOOL',
  'UPDATE_TOOL',
  'GET_TOOL_BY_ID',
  'DELETE_TOOL',

  // NEWS
  'FETCH_NEWS',
  'CREATE_POST',
  'UPDATE_POST',
  'GET_POST_BY_ID',
  'UPDATE_STATUS_POST',
  // DAILY TASK
  'CREATE_DAILY_TASK',
  'FETCH_DAILY_TASKS',
  'GET_MY_DAILY_TASKS',
  'UPDATE_DAILY_TASK',
  'GET_DAILY_TASK_BY_ID',
  'DELETE_DAILY_TASK',
  // PORFOLIO
  'FETCH_PORFOLIOS',
  'CREATE_PORFOLIO',
  'UPDATE_PORFOLIO',
  'GET_PORFOLIO_BY_ID',
  'UPDATE_STATUS_PORFOLIO',
  'CALCULATE_PORTFOLIO_HOMEPAGE',
  'FETCH_USER_PORTFOLIO',
  'GET_USER_PORFOLIO_BY_ID',
  'GET_FEATURED_PORTFOLIO',
]);

const _singleActions: ActionTypes = {
  // Common actions
  CONTENT_IS_LOADED: 'CONTENT_IS_LOADED',
  CONTENT_USER_IS_LOADED: 'CONTENT_USER_IS_LOADED',
  EXPAND_SIDEBAR: 'EXPAND_SIDEBAR',
  // Locale actions
  SET_LOCALE: 'SET_LOCALE',
  // Dev tool actions
  SET_SELECTED_TOOL: 'SET_SELECTED_TOOL',
  // Authentication actions
  AUTH_IS_REQUEST: 'AUTH_IS_REQUEST',
  LOGOUT: 'LOGOUT',
  KEEP_LOGGED: 'KEEP_LOGGED',
  CLEAR_AUTH_MESSAGE: 'CLEAR_AUTH_MESSAGE',
  // Permission actions
  PERMISSION_IS_REQUEST: 'PERMISSION_IS_REQUEST',
  SET_DEFAULT_REDUCER_PERMISSION: 'SET_DEFAULT_REDUCER_PERMISSION',
  // User Information actions
  USER_INFORMATION_IS_REQUEST: 'USER_INFORMATION_IS_REQUEST',
  SET_USERS_META: 'SET_USERS_META',
  CLEAR_USER_PAYLOAD: 'CLEAR_USER_PAYLOAD',
  CLEAR_STATUS_USER_DETAILS: 'CLEAR_STATUS_USER_DETAILS',
  // Project actions
  PROJECT_IS_REQUEST: 'PROJECT_IS_REQUEST',
  SET_DEFAULT_REDUCER_PROJECT: 'SET_DEFAULT_REDUCER_PROJECT',
  SET_META_PROJECT: 'SET_META_PROJECT',
  SET_DEFAULT_PROJECT_STATUS: 'SET_DEFAULT_PROJECT_STATUS',
  // Folders actions
  FOLDERS_IS_REQUEST: 'FOLDERS_IS_REQUEST',
  // Mail
  MAIL_IS_REQUEST: 'MAIL_IS_REQUEST',
  SET_MAILS_META: 'SET_MAILS_META',
  SET_DEFAULT_MAIL_REDUCER: 'SET_DEFAULT_MAIL_REDUCER',
  // PerformanceReview
  SET_PERFORMANCE_REVIEW_META: 'SET_PERFORMANCE_REVIEW_META',
  PERFORMANCE_REVIEW_IS_REQUEST: 'PERFORMANCE_REVIEW_IS_REQUEST',
  CLEAR_PERFORMANCE_REVIEW_PAYLOAD: 'CLEAR_PERFORMANCE_REVIEW_PAYLOAD',
  // Media
  SET_DEFAULT_MEDIA_STATUS: 'SET_DEFAULT_MEDIA_STATUS',
  MEDIA_IS_REQUEST: 'MEDIA_IS_REQUEST',
  SET_DEFAULT_MEDIA_REDUCER: 'SET_DEFAULT_MEDIA_REDUCER',
  SET_MEDIA_META: 'SET_MEDIA_META',
  MEDIA_IS_DOWNLOADING: 'MEDIA_IS_DOWNLOADING',
  // LOGS
  LOGS_IS_REQUEST: 'LOGS_IS_REQUEST',
  SET_DEFAULT_LOGS_REDUCER: 'SET_DEFAULT_LOGS_REDUCER',
  SET_LOGS_META: 'SET_LOGS_META',
  // LEAVE APPLICATION
  SET_DEFAULT_LEAVE_APPLICATION_REDUCER:
    'SET_DEFAULT_LEAVE_APPLICATION_REDUCER',
  LEAVE_APPLICATION_IS_REQUEST: 'LEAVE_APPLICATION_IS_REQUEST',
  SET_LEAVE_APPLICATION_META: 'SET_LEAVE_APPLICATION_META',
  CLEAR_LEAVE_APPLICATION_DETAILS: 'CLEAR_LEAVE_APPLICATION_DETAILS',
  // FOLDER
  CLEAR_FOLDER_STATUS: 'CLEAR_FOLDER_STATUS',
  // NOTIFICATION
  NOTIFICATION_IS_REQUEST: 'NOTIFICATION_IS_REQUEST',
  // DAILY REPORT
  SET_META_DAILY_REPORT: 'SET_META_DAILY_REPORT',
  SET_DEFAULT_REDUCER_DAILY_REPORT: 'SET_DEFAULT_REDUCER_DAILY_REPORT',
  DAILY_REPORT_IS_LOADING: 'DAILY_REPORT_IS_LOADING',
  RESET_DAILY_REPORT_STATUS: 'RESET_DAILY_REPORT_STATUS',
  CLEAR_REPORT_DETAILS: 'CLEAR_REPORT_DETAILS',
  // WIKI
  RESET_WIKI_STATUS: 'RESET_WIKI_STATUS',
  SET_DEFAULT_REDUCER_WIKI: 'SET_DEFAULT_REDUCER_WIKI',
  WIKI_IS_LOADING: 'WIKI_IS_LOADING',
  // USER
  RESET_USER_REDUCER: 'RESET_USER_REDUCER',
  RESET_USER_STATUS: 'RESET_USER_STATUS',
  // FOLDER
  EVENT_IS_REQUEST: 'EVENT_IS_REQUEST',
  CLEAR_EVENT_STATUS: 'CLEAR_EVENT_STATUS',
  SET_DEFAULT_EVENT_REDUCERS: 'SET_DEFAULT_EVENT_REDUCERS',

  // ATTENDANCES
  ATTENDANCES_IS_REQUEST: 'ATTENDANCES_IS_REQUEST',
  EXPORT_ATTENDANCES_IS_REQUEST: 'EXPORT_ATTENDANCES_IS_REQUEST',
  ATTENDANCES_IS_LOADING: 'ATTENDANCES_IS_LOADING',
  SET_ATTENDANCE_META: 'SET_ATTENDANCE_META',

  // PERMISSION
  RESET_PERMISSION_STATUS: 'RESET_PERMISSION_STATUS',
  SET_META_ROLE: 'SET_META_ROLE',

  // PROJECT MEMBER
  SET_DEFAULT_PROJECT_MEMBER_STATE: 'SET_DEFAULT_PROJECT_MEMBER_STATE',
  SET_DEFAULT_PROJECT_MEMBER_STATUS: 'SET_DEFAULT_PROJECT_MEMBER_STATUS',
  PROJECT_MEMBER_IS_REQUEST: 'PROJECT_MEMBER_IS_REQUEST',

  // ANNOUNCEMENT
  SET_META_ANNOUNCEMENT: 'SET_META_ANNOUNCEMENT',
  ANNOUNCEMENT_IS_REQUEST: 'ANNOUNCEMENT_IS_REQUEST',
  CLEAR_ANNOUNCEMENT: 'CLEAR_ANNOUNCEMENT',
  // ANNOUNCEMENT USER
  CLEAR_ANNOUNCEMENT_USER: 'CLEAR_ANNOUNCEMENT_USER',
  SET_META_ANNOUNCEMENT_USER: 'SET_META_ANNOUNCEMENT_USER',
  ANNOUNCEMENT_IS_REQUEST_USER: 'ANNOUNCEMENT_IS_REQUEST_USER',
  // TAG
  TAG_IS_REQUEST: 'TAG_IS_REQUEST',

  // CRAWLING SYSTEM
  CRAWL_PROJECT_IS_REQUEST: 'CRAWL_PROJECT_IS_REQUEST',
  CAPTURE_PROJECT_IS_REQUEST: 'CAPTURE_PROJECT_IS_REQUEST',
  SET_DEFAULT_REDUCER_CRAWL_PROJECT: 'SET_DEFAULT_REDUCER_CRAWL_PROJECT',
  SET_META_CRAWL_PROJECT: 'SET_META_CRAWL_PROJECT',

  // FEEDBACK
  CLEAR_FEEDBACK: 'CLEAR_FEEDBACK',
  SET_META_FEEDBACK: 'SET_META_FEEDBACK',
  FEEDBACK_IS_REQUEST: 'FEEDBACK_IS_REQUEST',
  // CONFIG SYSTEM
  CLEAR_CONFIG_SYSTEM: 'CLEAR_CONFIG_SYSTEM',
  SET_META_CONFIG_SYSTEM: 'SET_META_CONFIG_SYSTEM',
  CONFIG_SYSTEM_IS_REQUEST: 'CONFIG_SYSTEM_IS_REQUEST',
  // FEEDBACK GUEST
  FEEDBACK_GUESTS_IS_REQUEST: 'FEEDBACK_GUESTS_IS_REQUEST',
  SET_DEFAULT_FEEDBACK_GUESTS_REDUCER: 'SET_DEFAULT_FEEDBACK_GUESTS_REDUCER',
  SET_FEEDBACK_GUESTS_META: 'SET_FEEDBACK_GUESTS_META',
  // FILE TEMPLATE
  CLEAR_FILE_TEMPLATE: 'CLEAR_FILE_TEMPLATE',
  SET_META_FILE_TEMPLATE: 'SET_META_FILE_TEMPLATE',
  FILE_TEMPLATE_IS_REQUEST: 'FILE_TEMPLATE_IS_REQUEST',

  // WORK PROGRESS MANAGEMENT
  WORK_PROGRESS_IS_REQUEST: 'WORK_PROGRESS_IS_REQUESTS',
  SET_DEFAULT_REDUCER_WORK_PROGRESS: 'SET_DEFAULT_REDUCER_WORK_PROGRESS',
  SET_META_WORK_PROGRESS: 'SET_META_WORK_PROGRESS',
  CLEAR_STATUS_WORK_PROGRESS: 'CLEAR_STATUS_WORK_PROGRESS',

  // TOOL
  TOOL_IS_REQUEST: 'TOOL_IS_REQUEST',

  // NEWS
  NEWS_IS_REQUEST: 'NEWS_IS_REQUEST',
  SET_NEWS_META: 'SET_NEWS_META',
  SET_NEWS_PAGINATION: 'SET_NEWS_PAGINATION',
  SET_DEFAULT_NEWS_REDUCER: 'SET_DEFAULT_NEWS_REDUCER',

  // DAILY REPORT TASK
  SET_DAILY_TASK_FETCH_IS_LOADING: 'SET_DAILY_TASK_FETCH_IS_LOADING',
  SET_DAILY_TASK_GET_IS_LOADING: 'SET_DAILY_TASK_GET_IS_LOADING',
  SET_DAILY_TASK_ACTION_IS_LOADING: 'SET_DAILY_TASK_ACTION_IS_LOADING',
  SET_META_DAILY_TASK: 'SET_META_DAILY_TASK',
  SET_PAGINATION_DAILY_TASK: 'SET_PAGINATION_DAILY_TASK',
  SET_META_MY_DAILY_TASK: 'SET_META_MY_DAILY_TASK',
  SET_PAGINATION_MY_DAILY_TASK: 'SET_PAGINATION_MY_DAILY_TASK',
  RESET_DAILY_TASK_REDUCER: 'RESET_DAILY_TASK_REDUCER',
  RESET_MY_DAILY_TASK_REDUCER: 'RESET_MY_DAILY_TASK_REDUCER',
  DAILY_REPORT_TASK_IS_LOADING: 'DAILY_REPORT_TASK_IS_LOADING',
  RESET_DAILY_TASK_DETAIL: 'RESET_DAILY_TASK_DETAIL',
  RESET_DAILY_TASK_STATUS: 'RESET_DAILY_TASK_STATUS',

  // PORFOLIO
  PORFOLIO_IS_REQUEST: 'PORFOLIO_IS_REQUEST',
  SET_PORFOLIO_META: 'SET_PORFOLIO_META',
  SET_PORFOLIO_PAGINATION: 'SET_PORFOLIO_PAGINATION',
  SET_DEFAULT_PORFOLIO_REDUCER: 'SET_DEFAULT_PORFOLIO_REDUCER',
  USER_PORFOLIO_IS_REQUEST: 'USER_PORFOLIO_IS_REQUEST',
  SET_USER_PORFOLIO_META: 'SET_USER_PORFOLIO_META',
  SET_USER_PORFOLIO_PAGINATION: 'SET_USER_PORFOLIO_PAGINATION',
  SET_USER_DEFAULT_PORFOLIO_REDUCER: 'SET_USER_DEFAULT_PORFOLIO_REDUCER',
};

const ACTION_TYPES = {
  ..._asyncActions,
  ..._singleActions,
};

export default ACTION_TYPES;
