import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@utils';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { toast } from 'react-toastify';
import {
  IConfigSystem,
  ICreateConfigSystem,
  IUpdateConfigSystem,
} from '../Interfaces/ConfigSystem.interface';

const { ACTION_TYPES, ROUTERS } = CONSTANTS;

// SINGLE_ACTIONS

const clearConfigSystem = () => {
  return {
    type: ACTION_TYPES.CLEAR_CONFIG_SYSTEM,
  };
};

const setMetaPagination = (payload: IMetaStructure) => {
  return {
    type: ACTION_TYPES.SET_META_CONFIG_SYSTEM,
    payload,
  };
};

const configSystemIsLoading = () => {
  return {
    type: ACTION_TYPES.CONFIG_SYSTEM_IS_REQUEST,
  };
};

// ASYNC_ACTIONS
const fetchConfigSystemsSuccess = (payload: IConfigSystem[]) => {
  return {
    type: ACTION_TYPES.FETCH_CONFIG_SYSTEMS_SUCCESS,
    payload,
  };
};

const fetchConfigSystemsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_CONFIG_SYSTEMS_FAILURE,
  };
};

const fetchConfigSystems = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(configSystemIsLoading());
    await API.fetchConfigSystems(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchConfigSystemsFailure());
        else {
          const resolveResult: {
            items: IConfigSystem[];
            meta: IMetaStructure;
          } = result as { items: IConfigSystem[]; meta: IMetaStructure };
          dispatch(fetchConfigSystemsSuccess(resolveResult.items));
          dispatch(setMetaPagination(resolveResult.meta));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchConfigSystemsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createConfigSystemSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_CONFIG_SYSTEM_SUCCESS,
  };
};

const createConfigSystemFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_CONFIG_SYSTEM_FAILURE,
  };
};

const createConfigSystem = (payload: ICreateConfigSystem) => {
  return async (dispatch: any) => {
    await API.createConfigSystem(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createConfigSystemFailure());
        } else {
          dispatch(createConfigSystemSuccess());
          Utils.redirect(ROUTERS.CONFIG_SYSTEM);
          toast.success(message);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createConfigSystemFailure());
      });
  };
};

const updateConfigSystemSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_CONFIG_SYSTEM_SUCCESS,
    payload,
  };
};

const updateConfigSystemFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_CONFIG_SYSTEM_FAILURE,
  };
};

const updateConfigSystem = (payload: IUpdateConfigSystem) => {
  return async (dispatch: any) => {
    await API.updateConfigSystem(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateConfigSystemFail());
          toast.error(message);
        } else {
          dispatch(updateConfigSystemSuccess(result));
          toast.success(message);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateConfigSystemFail());
      });
  };
};

const updateDocumentationConfigSystemSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_DOCUMENTATION_CONFIG_SYSTEM_SUCCESS,
    payload,
  };
};

const updateDocumentationConfigSystemFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_DOCUMENTATION_CONFIG_SYSTEM_FAILURE,
  };
};

const updateDocumentationConfigSystem = (payload: IUpdateConfigSystem) => {
  return async (dispatch: any) => {
    await API.updateDocumentationConfigSystem(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateDocumentationConfigSystemFail());
          toast.error(message);
        } else {
          dispatch(updateDocumentationConfigSystemSuccess(result));
          toast.success(message);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateDocumentationConfigSystemFail());
      });
  };
};

const getConfigSystemByIDSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_CONFIG_SYSTEM_BY_ID_SUCCESS,
    payload,
  };
};

const getConfigSystemByIDFail = () => {
  return {
    type: ACTION_TYPES.GET_CONFIG_SYSTEM_BY_ID_FAILURE,
  };
};

const getConfigSystemByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(configSystemIsLoading());
    await API.getConfigSystemByID(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getConfigSystemByIDFail());
        else {
          dispatch(getConfigSystemByIDSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getConfigSystemByIDFail());
      });
  };
};

const deleteConfigSystemSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.DELETE_CONFIG_SYSTEM_SUCCESS,
    payload,
  };
};

const deleteConfigSystemFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_CONFIG_SYSTEM_FAILURE,
  };
};

const deleteConfigSystem = (id: string, payload: any) => {
  return async (dispatch: any) => {
    dispatch(configSystemIsLoading());
    await API.deleteConfigSystem(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(deleteConfigSystemFailure());
        } else {
          toast.success(message);
          await dispatch(fetchConfigSystems(payload));
          await dispatch(deleteConfigSystemSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await dispatch(deleteConfigSystemFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  clearConfigSystem,
  fetchConfigSystems,
  getConfigSystemByID,
  createConfigSystem,
  updateConfigSystem,
  updateDocumentationConfigSystem,
  deleteConfigSystem,
};
