import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { AuthActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { Images } from '@/Themes';

import { TextField, Typography, Stack, Link } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSearchParams } from 'react-router-dom';

const { sendRecoveryLink, clearAuthMessage } = AuthActions;

const schema = yup
  .object({
    email: yup.string().trim().email().label('Email').required(),
  })
  .required();

interface IProps {
  sx?: object;
}

const ForgotPassword: React.FC<IProps> = ({ sx }) => {
  const dispatch = useTypedDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const form = searchParams.get('form');
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.AUTH, 'requestIsLoading')
  );

  React.useEffect(() => {
    return () => {
      dispatch(clearAuthMessage());
    };
  }, []);

  React.useEffect(() => {
    if (form && form !== 'forgot-password') {
      setTimeout(() => {
        reset();
      }, 300);
    }
  }, [form]);

  const onSubmit = (data: { email: string }) => {
    dispatch(sendRecoveryLink(data));
  };
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleSubmit(onSubmit);
  };

  const _renderForm = () => {
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '300px',
          padding: '20px',
        }}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Email"
              size="small"
              sx={{ mb: 1 }}
              autoFocus
              color={errors.email?.message ? 'error' : 'info'}
              onKeyDown={onKeyDown}
              error={Boolean(errors.email?.message)}
              helperText={errors.email?.message}
            />
          )}
        />
        <LoadingButton
          variant="contained"
          type="submit"
          sx={{
            marginY: 2,
            width: '100%',
          }}
          loading={isLoading}
        >
          Send recovery link
        </LoadingButton>
      </form>
    );
  };

  const _renderMain = () => {
    return (
      <Stack
        sx={{
          minHeight: '100%',
          minWidth: '80%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'all ease 0.25s',
          ...sx,
        }}
      >
        <img src={Images.flowIdraLogo} alt="" width="60px" />
        <Typography variant="h6" sx={{ marginY: '20px' }}>
          Forgot password
        </Typography>
        {_renderForm()}
        <Link
          component="button"
          variant="body2"
          onClick={() => setSearchParams({ form: 'sign-in' })}
          sx={{ textDecoration: 'none' }}
        >
          Back to sign in
        </Link>
      </Stack>
    );
  };
  return _renderMain();
};

export default ForgotPassword;
