import { styled } from '@mui/material';
import { Box } from '@mui/system';

const RoundedContainer = styled(Box)(() => ({
  boxShadow: '0 7px 25px rgba(0,0,0,0.08)',
  borderRadius: '15px',
  backgroundColor: 'white',
  padding: '14px',
}));
export default RoundedContainer;
