import React from 'react';
import Select, {
  components,
  MultiValueGenericProps,
  OptionProps,
  PlaceholderProps,
} from 'react-select';
import DoneIcon from '@mui/icons-material/Done';
import { FormHelperText, InputLabel, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-multi-lang';
import { EventColors } from '../../../Constants/Enums';
interface IOption {
  label: string;
  value: string;
  color?: string;
}
interface IProps {
  label?: string;
  required?: boolean;
  placeholder?: string;
  message?: string;
  options: IOption[];
  onChange(newValue: string[]): void;
  value: string[];
  sx?: object;
  onCreate?(payload: any): void;
  dropdownSx?: object;
  isAllowCreate?: boolean;
}
const colorOptions = [
  {
    label: EventColors.TOMATO_RED,
    value: EventColors.TOMATO_RED,
  },
  {
    label: EventColors.PINK,
    value: EventColors.PINK,
  },
  {
    label: EventColors.ORANGE,
    value: EventColors.ORANGE,
  },
  {
    label: EventColors.BANANA,
    value: EventColors.BANANA,
  },
  {
    label: EventColors.LIGHT_GREEN,
    value: EventColors.LIGHT_GREEN,
  },
  {
    label: EventColors.BASIL,
    value: EventColors.BASIL,
  },
  {
    label: EventColors.BLUE,
    value: EventColors.BLUE,
  },
  {
    label: EventColors.BLUEBERRY,
    value: EventColors.BLUEBERRY,
  },
  {
    label: EventColors.LAVENDER,
    value: EventColors.LAVENDER,
  },
  {
    label: EventColors.GRAPE,
    value: EventColors.GRAPE,
  },
  {
    label: EventColors.SMOKE,
    value: EventColors.SMOKE,
  },
];
// const animatedComponents = makeAnimated();
const AutocompleteSelect: React.FC<IProps> = ({
  label,
  required = false,
  placeholder = '',
  message = '',
  value = [],
  onChange,
  options,
  sx = {},
  onCreate,
  dropdownSx = {},
  isAllowCreate = false,
}) => {
  const t = useTranslation();
  const [currentValue, setCurrentValue] = React.useState<any>([]);
  const [inputValue, setInputValue] = React.useState<string>('');
  const selectRef = React.useRef<any | null>(null);
  React.useEffect(() => {
    if (options) {
      const findOptions = options.filter((item: IOption) =>
        value.includes(item.value)
      );
      if (findOptions) setCurrentValue(findOptions);
    }
  }, [options, value]);
  const handleCreate = () => {
    const randomNumber = Math.floor(Math.random() * 11);
    const randomColor = colorOptions[randomNumber];
    const createPayload = {
      color: randomColor.value,
      name: inputValue,
    };
    if (selectRef && selectRef.current) selectRef.current.blur();
    if (onCreate) onCreate(createPayload);
  };
  const handleChange = (newValue: any) => {
    const resolveValue: string[] = [];
    if (newValue.length > 0)
      newValue.forEach((item: IOption) => resolveValue.push(item.value));
    setCurrentValue(newValue);
    if (onChange) onChange(resolveValue);
  };
  const MultiValueContainer = (props: MultiValueGenericProps<IOption>) => {
    return (
      <Stack
        direction="row"
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
          flexWrap: 'nowrap',
          minWidth: 'max-content',
        }}
      >
        <components.MultiValueContainer {...props} />
      </Stack>
    );
  };
  const MultiValueLabel = (props: MultiValueGenericProps<IOption>) => {
    return (
      <Stack
        direction="row"
        sx={{
          padding: '0 4px',
          background: props.data?.color,
          color: 'white',
          '> *': { color: 'white' },
        }}
        className={`${props.data?.color} autocomplete-label`}
      >
        <components.MultiValueLabel {...props} />
      </Stack>
    );
  };
  const Placeholder = (props: PlaceholderProps<IOption>) => {
    return (
      <components.Placeholder {...props}>
        {placeholder || t('placeholder.choose')}
      </components.Placeholder>
    );
  };
  const NoOptionsMessage = () => {
    return (
      <Stack
        direction="row"
        sx={{ alignItems: 'center', height: '38px', padding: '5px 10px' }}
      >
        <Typography sx={{ fontSize: '15px' }}>
          {inputValue ? inputValue : t('label.noOptions')}
        </Typography>
        {inputValue.trim() && isAllowCreate ? (
          <LoadingButton
            variant="contained"
            sx={{ ml: 1, height: '32px' }}
            onClick={() => isAllowCreate && handleCreate()}
          >
            {t('button.create')}
          </LoadingButton>
        ) : null}
      </Stack>
    );
  };
  const CustomOption = (payload: OptionProps<IOption>) => {
    if (payload.isDisabled) return null;
    return (
      <div {...payload.innerProps}>
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '38px',
            padding: '5px 10px',
            ':hover': {
              cursor: 'pointer',
              background: 'rgba(9,30,66,.08)',
            },
          }}
        >
          <Typography sx={{ fontWeight: payload.isSelected ? 500 : 400 }}>
            {payload.children}
          </Typography>
          {payload.isSelected ? (
            <DoneIcon
              sx={{
                width: '20px',
                height: '20px',
                color: '#42526e',
              }}
            />
          ) : null}
        </Stack>
      </div>
    );
  };
  return (
    <Stack direction="column" sx={{ flex: 1, ...sx }}>
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}{' '}
          {required ? (
            <Typography component="span" color="error">
              (*)
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      <Select
        closeMenuOnSelect={false}
        components={{
          Placeholder,
          MultiValueContainer,
          MultiValueLabel,
          NoOptionsMessage,
          Option: CustomOption,
        }}
        isMulti
        value={currentValue}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        onInputChange={(newValue: string) => setInputValue(newValue)}
        ref={selectRef}
        hideSelectedOptions={false}
        styles={{
          container: (base: any) => ({
            ...base,
            width: '100%',
            ...dropdownSx,
          }),
          control: (base: any, state: any) => {
            const errorColor = Boolean(message)
              ? '0 0 0 1px #d32f2f'
              : '0 0 0 1px #2684ff';
            const elementColor = state.isFocused ? errorColor : 'transparent';
            return {
              ...base,
              height: '40px',
              width: '100%',
              borderColor: Boolean(message) ? '#d32f2f' : 'hsl(0, 0%, 80%)',
              boxShadow: elementColor,
              '&:hover': {
                borderColor: Boolean(message) ? '#d32f2f' : 'hsl(0, 0%, 80%)',
                boxShadow: elementColor,
              },
            };
          },
          placeholder: (base: any) => ({
            ...base,
            marginLeft: '5px',
            color: '#A2A2A2',
          }),
          menu: (base: any) => ({
            ...base,
            zIndex: 99,
          }),
          valueContainer: (base: any) => ({
            ...base,
            flexWrap: 'nowrap',
            overflowX: 'auto',
          }),
        }}
      />
      {message ? (
        <FormHelperText error={Boolean(message)}>{message}</FormHelperText>
      ) : null}
    </Stack>
  );
};
export default AutocompleteSelect;
