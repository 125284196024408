import React from 'react';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { Stack, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CONSTANTS from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import { PermissionActions } from '@/Actions';
import Utils from '@/Utils';
import {
  CheckboxGroup,
  RoundedContainer,
  Textarea,
  TextField,
} from '@/Components/Common';
import { Alert } from '@/Widgets';

import { IPermissionStructure } from '@/Interfaces/Role.interface';
import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import { PermissionsDataTable } from '@/Components/LayoutPart/DataTable';
import { ModuleKeys } from '@/Constants/Enums';
import { DEFAULT_MENU } from '@/Components/DefaultLayout/MenuOptions';

interface IMenu {
  icon: React.JSX.Element;
  title: string;
  to: string;
  key: ModuleKeys;
}

const { ROUTERS } = CONSTANTS;

const { createRole, fetchPermissions, setDefaultReducerPermission } =
  PermissionActions;

const CreateRole: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const schema = yup
    .object({
      name: yup.string().trim().required(t('message.roleNameRequired')),
      roleCode: yup.string().trim().required(t('message.roleCodeRequired')),
      description: yup
        .string()
        .trim()
        .required(t('message.descriptionRequired')),
      acceptApi: yup.array().min(1, t('message.permissionLeastAtOneItem')),
      menu: yup.array().min(1, t('message.menuLeastAtOneItem')),
    })
    .required();

  const permissionsPayload: IPermissionStructure[] = useSelector(
    (state: RootState) => get(state.ROLE, 'permissions')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.ROLE, 'requestIsLoading')
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      roleCode: '',
      description: '',
      acceptApi: [],
      menu: [],
    },
  });

  React.useEffect(() => {
    const isAcceptApi = Utils.isValidPermission(
      ModuleApiConstant.ROLE_PERMISSION.CREATE_ROLE
    );
    if (!isAcceptApi) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForCreateRole'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else dispatch(fetchPermissions());
    return () => {
      dispatch(setDefaultReducerPermission());
    };
  }, []);

  const onSubmit = (data: {
    name: string;
    roleCode: string;
    description: string;
    acceptApi: string[];
  }) => {
    dispatch(createRole(data));
  };

  const onCancel = () => Utils.redirect(ROUTERS.ROLE_LIST);

  const menuOptions = React.useMemo(() => {
    const result: any[] = [];
    forEach(DEFAULT_MENU, (item: IMenu) =>
      result.push({
        label: (
          <Stack direction="row" alignItems="center">
            {item.icon}{' '}
            <Typography sx={{ fontSize: '14px', fontWeight: 500, ml: 0.5 }}>
              {t(`menu.${item.title}`)}
            </Typography>
          </Stack>
        ),
        value: item.key,
      })
    );
    return result;
  }, []);

  // Render layout
  const _renderTopSection = () => (
    <Typography variant="h2">{t('title.createRole')}</Typography>
  );

  const _renderBottomSection = () => {
    const isAcceptApi = Utils.isValidPermission(
      ModuleApiConstant.ROLE_PERMISSION.CREATE_ROLE
    );
    return (
      <RoundedContainer>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Stack>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={(e: any) => field.onChange(e.target.value)}
                  label={t('label.roleName')}
                  placeholder="Human Resources"
                  message={errors.name?.message}
                  required
                />
              )}
            />
            <Controller
              name="roleCode"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={(e: any) => field.onChange(e.target.value)}
                  label={t('label.roleCode')}
                  placeholder="humanResource"
                  message={errors.roleCode?.message}
                  required
                />
              )}
            />
          </Stack>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Textarea
                label={t('label.description')}
                placeholder={t('label.description')}
                sx={{ mb: 1 }}
                value={field.value}
                onChange={(e: any) => field.onChange(e.target.value)}
                required
                message={errors.description?.message}
              />
            )}
          />
          <Controller
            name="acceptApi"
            control={control}
            render={({ field }) => (
              <PermissionsDataTable
                label={t('label.permissions')}
                required
                message={errors.acceptApi?.message}
                payload={permissionsPayload}
                acceptApi={field.value}
                callback={(checkedPermission: string[]) =>
                  field.onChange(checkedPermission)
                }
                sx={{ mb: 1 }}
              />
            )}
          />
          <Controller
            name="menu"
            control={control}
            render={({ field }) => (
              <CheckboxGroup
                options={menuOptions}
                selected={field.value}
                onChange={field.onChange}
                label={t('label.menu')}
                sx={{ mb: 1 }}
                message={errors.menu?.message}
                required
                helperText={t('tooltip.ensurePermissionInMenuOfRole')}
              />
            )}
          />
          <Stack direction="row" mt={3} justifyContent="flex-end">
            <LoadingButton sx={{ mr: 1 }} onClick={onCancel} variant="outlined">
              {t('button.cancel')}
            </LoadingButton>

            {isAcceptApi && (
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
              >
                {t('button.create')}
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </RoundedContainer>
    );
  };

  return (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default CreateRole;
