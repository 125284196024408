import React from 'react';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import get from 'lodash/get';

import { ChipStatus, StickyHeadTable } from '@/Components/Common';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { ILogsDetailsStructure } from '@/Interfaces/Logs.interface';

import { Tooltip, Link, Typography } from '@mui/material';
import { t } from 'react-multi-lang';
import Utils from '../../../Utils';

interface IColumnStructure {
  id: 'no' | 'log_name' | 'method' | 'action_by' | 'action_at';
  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

interface ISectionProps {
  meta: IMetaStructure;
  payload: any[];
  onPageAction(value: number, type: 'limit' | 'page'): void;
  isLoading?: boolean;
}

interface IDataTableStructure {
  no: string;
  log_name: JSX.Element;
  method: JSX.Element;
  action_by: JSX.Element;
  action_at: string;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 20, align: 'center' },
  { id: 'log_name', label: 'logName', minWidth: 300, align: 'left' },
  { id: 'method', label: 'method', minWidth: 100, align: 'center' },
  { id: 'action_by', label: 'actionBy', minWidth: 100, align: 'center' },
  { id: 'action_at', label: 'actionAt', minWidth: 130, align: 'center' },
];

const createDataTable = (
  no: string,
  log_name: JSX.Element,
  method: JSX.Element,
  action_by: JSX.Element,
  action_at: string
): IDataTableStructure => {
  return {
    no,
    log_name,
    method,
    action_by,
    action_at,
  };
};

const LogsDataTable: React.FC<ISectionProps> = ({
  payload,
  meta,
  onPageAction,
  isLoading = false,
}) => {
  const [rows, setRows] = React.useState<IDataTableStructure[]>([]);
  const language = Utils.getSavedLocale();

  React.useEffect(() => {
    const resolveRows: IDataTableStructure[] = [];
    if (!isEmpty(payload)) {
      forEach(payload, (user: ILogsDetailsStructure, index: number) => {
        const { userLog, nameEN, nameVI, method, createdAt } = user;

        const { userData, email } = userLog;

        const createdDate = dayjs(createdAt);

        const currentPage = get(meta, 'currentPage');
        const limit = get(meta, 'itemsPerPage');
        const resolveRowIndex = index + 1 + (currentPage - 1) * limit;

        const resolveLogName = language === 'en' ? nameEN : nameVI;

        resolveRows.push(
          createDataTable(
            `${resolveRowIndex}`,
            <Typography
              fontSize="14px"
              dangerouslySetInnerHTML={{ __html: resolveLogName }}
            />,
            <ChipStatus label={method} />,
            <Tooltip title={email || t('tooltip.noInformation')}>
              <Link sx={{ textDecoration: 'none', fontSize: '14px' }}>
                {userData?.fullName || t('table.noInformation')}
              </Link>
            </Tooltip>,
            createdDate.isValid()
              ? createdDate.format('HH:mm, DD-MM-YYYY')
              : t('table.noInformation')
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      onAction={onPageAction}
      paginate={meta}
      isLoading={isLoading}
    />
  );
};

export default LogsDataTable;
