import { FormHelperText, InputLabel, Stack, Typography } from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
import { languages } from '@codemirror/language-data';
import { githubLight } from '@uiw/codemirror-theme-github';
import React from 'react';

interface IProps {
  value: string;
  onChange(newValue: string): void;
  label?: string;
  sx?: object;
  required?: boolean;
  message?: string;
}

const CodeMirrorEditor: React.FC<IProps> = ({
  value = '',
  onChange,
  label = '',
  sx = {},
  required = false,
  message = '',
}) => {
  return (
    <Stack direction="column" sx={{ flex: 1, ...sx }}>
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}{' '}
          {required ? (
            <Typography
              component="span"
              color="error"
              sx={{ fontSize: '13px' }}
            >
              (*)
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      <CodeMirror
        value={value}
        extensions={[
          markdown({ base: markdownLanguage, codeLanguages: languages }),
        ]}
        theme={githubLight}
        onChange={onChange}
        minHeight="200px"
        maxHeight="450px"
        basicSetup={{
          foldGutter: false,
          dropCursor: false,
          allowMultipleSelections: false,
          indentOnInput: false,
        }}
      />
      {message ? (
        <FormHelperText error={Boolean(message)}>{message}</FormHelperText>
      ) : null}
    </Stack>
  );
};

export default CodeMirrorEditor;
