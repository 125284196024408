import { Skeleton, Stack } from '@mui/material';

const SelfNotificationSkeleton = () => {
  return (
    <Stack direction="row" alignItems="center">
      <Skeleton variant="circular" width={60} height={60} sx={{ mr: '17px' }} />
      <Skeleton width={250} height={40} />
    </Stack>
  );
};

export default SelfNotificationSkeleton;
