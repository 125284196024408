import _ from 'lodash';
import Utils from '@utils';
import { toast } from 'react-toastify';
import CONSTANTS from '@/Constants';
import API from '@/APIs';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import {
  IAnnouncementStructure,
  ICreateAnnouncement,
  IUpdateAnnouncement,
} from '@/Interfaces/Announcement.interface';
import { UserActions } from '.';
const { fetchUser } = UserActions;

const { ACTION_TYPES, ROUTERS } = CONSTANTS;

// SINGLE_ACTIONS

const clearAnnouncement = () => {
  return {
    type: ACTION_TYPES.CLEAR_ANNOUNCEMENT,
  };
};

const setMetaPagination = (payload: IMetaStructure) => {
  return {
    type: ACTION_TYPES.SET_META_ANNOUNCEMENT,
    payload,
  };
};

const AnnouncementsRequest = () => {
  return {
    type: ACTION_TYPES.ANNOUNCEMENT_IS_REQUEST,
  };
};

// ASYNC_ACTIONS
const fetchAnnouncementsSuccess = (payload: IAnnouncementStructure[]) => {
  return {
    type: ACTION_TYPES.FETCH_ANNOUNCEMENT_SUCCESS,
    payload,
  };
};

const fetchAnnouncementsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_ANNOUNCEMENT_FAILURE,
  };
};

const fetchAnnouncements = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(AnnouncementsRequest());
    await API.fetchAnnouncements(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchAnnouncementsFailure());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchAnnouncementsSuccess(items));
          dispatch(setMetaPagination(meta));
        }
      })
      .catch(async (error: any) => {
        await dispatch(fetchAnnouncementsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createAnnouncementsSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_ANNOUNCEMENT_SUCCESS,
  };
};

const createAnnouncementsFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_ANNOUNCEMENT_FAILURE,
  };
};

const createAnnouncements = (payload: ICreateAnnouncement) => {
  return async (dispatch: any) => {
    await API.createAnnouncements(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createAnnouncementsFailure());
        } else {
          dispatch(createAnnouncementsSuccess());
          Utils.redirect(ROUTERS.ANNOUNCEMENT);
          toast.success(message);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createAnnouncementsFailure());
      });
  };
};

const updateAnnouncementsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_ANNOUNCEMENT_SUCCESS,
    payload,
  };
};

const updateAnnouncementsFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_ANNOUNCEMENT_FAILURE,
  };
};

const updateAnnouncements = (payload: IUpdateAnnouncement) => {
  return async (dispatch: any) => {
    dispatch(AnnouncementsRequest());
    await API.updateAnnouncements(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateAnnouncementsFail());
          toast.error(message);
        } else {
          dispatch(updateAnnouncementsSuccess(result));
          toast.success(message);
          dispatch(fetchUser({ page: 1, limit: 0, status: 'active' }));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateAnnouncementsFail());
      });
  };
};

const getAnnouncementsByIdSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_ANNOUNCEMENT_BY_ID_SUCCESS,
    payload,
  };
};

const getAnnouncementsByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_ANNOUNCEMENT_BY_ID_FAILURE,
  };
};

const getAnnouncementsById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(AnnouncementsRequest());
    await API.getAnnouncementsById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getAnnouncementsByIdFail());
        else {
          dispatch(getAnnouncementsByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getAnnouncementsByIdFail());
      });
  };
};

const getForUserAnnouncementsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_FOR_USER_ANNOUNCEMENT_SUCCESS,
    payload,
  };
};

const getForUserAnnouncementsFailure = () => {
  return {
    type: ACTION_TYPES.GET_FOR_USER_ANNOUNCEMENT_FAILURE,
  };
};

const getForUserAnnouncements = () => {
  return async (dispatch: any) => {
    dispatch(AnnouncementsRequest());
    await API.getForUserAnnouncements()
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getForUserAnnouncementsFailure());
        else {
          dispatch(getForUserAnnouncementsSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await dispatch(getForUserAnnouncementsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const updateTypeAnnouncementsSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_TYPE_ANNOUNCEMENT_SUCCESS,
  };
};

const updateTypeAnnouncementsFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_TYPE_ANNOUNCEMENT_FAILURE,
  };
};

const updateTypeAnnouncements = (id: string, type: string, filter: any) => {
  return async (dispatch: any) => {
    await API.updateAnnouncementsType(id, type)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateTypeAnnouncementsFailure());
        } else {
          dispatch(updateTypeAnnouncementsSuccess());
          dispatch(fetchAnnouncements(filter));
          toast.success(message);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateTypeAnnouncementsFailure());
      });
  };
};

const deleteAnnouncementsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.DELETE_ANNOUNCEMENT_SUCCESS,
    payload,
  };
};

const deleteAnnouncementsFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_ANNOUNCEMENT_FAILURE,
  };
};

const deleteAnnouncements = (id: string, filter: any) => {
  return async (dispatch: any) => {
    await API.deleteAnnouncements(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(deleteAnnouncementsFailure());
        } else {
          dispatch(deleteAnnouncementsSuccess(result));
          dispatch(fetchAnnouncements(filter));
          toast.success(message);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deleteAnnouncementsFailure());
      });
  };
};

export default {
  fetchAnnouncements,
  createAnnouncements,
  updateAnnouncements,
  getAnnouncementsById,
  getForUserAnnouncements,
  updateTypeAnnouncements,
  clearAnnouncement,
  deleteAnnouncements,
};
