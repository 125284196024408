/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';
import get from 'lodash/get';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreMore from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Tooltip } from '@mui/material';
import { listItemStyles } from './SideBar.styles';
import Utils from '@/Utils';

interface ISubMenuItem {
  title: string;
  to: string;
  icon?: React.JSX.Element;
}

interface IMenuItem {
  icon: React.JSX.Element;
  title: string;
  parentKey?: string;
  items?: ISubMenuItem[];
}

interface IMenuConfiguration {
  items: IMenuItem[] | ISubMenuItem[] | [] | undefined;
  isExpand: boolean;
}

const Menu: React.FC<IMenuConfiguration> = ({ items, isExpand }) => {
  const t = useTranslation();
  const currentPath = get(useLocation(), 'pathname');
  // Render Parts
  const listItemBody = (config: IMenuItem | ISubMenuItem) => {
    const { icon, title } = config;
    return (
      <>
        {icon && (
          <ListItemIcon
            sx={{ mr: '10px', minWidth: 'unset', color: 'inherit' }}
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          primaryTypographyProps={{
            fontSize: '14px',
            color: 'inherit',
            align: 'justify',
            fontWeight: 'inherit',
            whiteSpace: 'nowrap',
            width: 0,
          }}
          sx={{
            overflow: 'hidden',
            transition: 'all ease 0.25s',
            flex: isExpand ? 'unset' : '1 1 auto',
          }}
          primary={t(`menu.${title}`)}
          key={title}
        />
      </>
    );
  };

  const menuItem = (config: ISubMenuItem) => {
    const { to, title } = config;

    const isActive = currentPath.indexOf(to) === 0;
    return (
      <Tooltip
        title={t(`menu.${title}`)}
        placement="right"
        arrow
        disableHoverListener={!isExpand}
        key={title}
      >
        <ListItem
          button
          onClick={() => Utils.redirect(to)}
          className={isActive ? 'active' : 'inactive'}
          sx={listItemStyles}
        >
          {listItemBody(config)}
        </ListItem>
      </Tooltip>
    );
  };

  const expandableMenuItem = ({
    config,
    key,
    isCurrentPath,
  }: {
    config: IMenuItem;
    isCurrentPath: boolean;
    key: string;
  }) => {
    const [open, setOpen] = useState(isCurrentPath);

    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <div key={key}>
        <ListItem button onClick={handleClick}>
          {listItemBody(config)}
          {open ? <ExpandLessIcon /> : <ExpandMoreMore />}
        </ListItem>
      </div>
    );
  };

  const createList = () => {
    const menu: Array<React.JSX.Element> = [];
    (items as unknown as any[]).map((item) => {
      if (Array.isArray(item.items) && item.items.length > 0) {
        //! TODO: OPTIMIZE NEEDED: Change the logic, this logic is not good
        const currentCategory = currentPath.split('/')[2];
        const isCurrentPath = currentCategory === item.parentKey;

        menu.push(
          expandableMenuItem({
            config: item,
            key: item.title,
            isCurrentPath,
          })
        );
      } else menu.push(menuItem(item));
    });
    return menu.concat();
  };

  return <List sx={{ m: 1 }}>{createList()}</List>;
};

export default Menu;
