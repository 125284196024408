import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';
import { toast } from 'react-toastify';
import { IFileDetailsStructure } from '../Interfaces/File.interface';
import { saveAs } from 'file-saver';

const { ACTION_TYPES } = CONSTANTS;

const setDefaultMediaStatus = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_MEDIA_STATUS,
  };
};

const fileIsDownloading = () => {
  return {
    type: ACTION_TYPES.MEDIA_IS_DOWNLOADING,
  };
};

const mediaIsRequest = () => {
  return {
    type: ACTION_TYPES.MEDIA_IS_REQUEST,
  };
};

// ASYNC ACTIONS
const fetchMediaFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_MEDIA_FAILURE,
  };
};

const fetchMediaSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_MEDIA_SUCCESS,
    payload,
  };
};

const fetchMedia = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(mediaIsRequest());
    await API.fetchMedia(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchMediaFailure());
        else {
          const resolveResult: IFileDetailsStructure[] =
            result as IFileDetailsStructure[];
          dispatch(fetchMediaSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
      });
  };
};

const downloadFilesFailure = () => {
  return {
    type: ACTION_TYPES.DOWNLOAD_FILES_FAILURE,
  };
};

const downloadFilesSuccess = () => {
  return {
    type: ACTION_TYPES.DOWNLOAD_FILES_SUCCESS,
  };
};

const downloadFiles = (payload: { keys: string[] }) => {
  return async (dispatch: any) => {
    dispatch(fileIsDownloading());
    await API.downloadFiles(payload)
      .then(async (response: any) => {
        const data = response?.data;
        if (!data) {
          toast.success('Download files failure!');
          await dispatch(downloadFilesFailure());
        } else {
          saveAs(data, `download`);
          toast.success('Download files successfully!');
          dispatch(downloadFilesSuccess());
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
      });
  };
};

const uploadMediaFailure = () => {
  return {
    type: ACTION_TYPES.UPLOAD_MEDIA_FAILURE,
  };
};

const uploadMediaSuccess = () => {
  return {
    type: ACTION_TYPES.UPLOAD_MEDIA_SUCCESS,
  };
};

const uploadMedia = (payload: FormData) => {
  return async (dispatch: any) => {
    // dispatch(mediaIsRequest());
    await API.uploadMedia(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(uploadMediaFailure());
        else {
          dispatch(uploadMediaSuccess());
          const folderId = payload.get('folderId');
          if (folderId) dispatch(fetchMedia({ folderId }));
        }

        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        dispatch(uploadMediaFailure());
      });
  };
};

const deleteMediaFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_MEDIA_FAILURE,
  };
};

const deleteMediaSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_MEDIA_SUCCESS,
  };
};

const deleteMedia = (fileId: string, folderId: string) => {
  return async (dispatch: any) => {
    dispatch(mediaIsRequest());
    await API.deleteMedia(fileId)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response.data.message;
        if (!result) {
          toast.error(message);
          await dispatch(deleteMediaFailure());
        } else {
          toast.success(message);
          dispatch(deleteMediaSuccess());
          if (folderId)
            dispatch(fetchMedia({ page: 0, limit: 0, folderId: folderId }));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
      });
  };
};
export default {
  setDefaultMediaStatus,
  fetchMedia,
  uploadMedia,
  deleteMedia,
  downloadFiles,
  fileIsDownloading,
};
