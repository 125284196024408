import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';

import CONSTANTS from '@/Constants';
import Utils from '@/Utils';
import { PermissionActions } from '@/Actions';
import { Alert } from '@/Widgets';
import { RootState, useTypedDispatch } from '@/store';
import { RoundButton, RoundedContainer } from '@/Components/Common';

import { RoleDataTable } from '@/Components/LayoutPart/DataTable';
import { IRoleDetailsStructure } from '@/Interfaces/Role.interface';

import { IMetaStructure } from '../../../Interfaces/Common.interface';

interface IFilter {
  limit: number;
  page: number;
}

const { ROUTERS, MODULE_API } = CONSTANTS;

const { fetchRoles } = PermissionActions;

const initialFilter = {
  limit: 10,
  page: 1,
};

const RoleList: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const payload: IRoleDetailsStructure[] = useSelector((state: RootState) =>
    get(state.ROLE, 'payload')
  );
  const meta: IMetaStructure = useSelector((state: RootState) =>
    get(state.ROLE, 'meta')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.ROLE, 'requestIsLoading')
  );

  const [filter, setFilter] = React.useState<IFilter>(initialFilter);

  const fetchPayload = (filterParams: IFilter) => {
    const resolveFilter = Utils.validateFilters(filterParams);
    dispatch(fetchRoles(resolveFilter));
  };

  React.useEffect(() => {
    const isAcceptApi = Utils.isValidPermission(
      MODULE_API.ROLE_PERMISSION.FETCH_ROLES
    );

    if (!isAcceptApi) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForFetchRoles'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else fetchPayload(filter);
  }, []);

  // Handle function
  const onRowAction = (role: { id: string }, action: 'edit') => {
    const { id } = role;
    if (action === 'edit') Utils.redirect({ pathname: ROUTERS.EDIT_ROLE, id });
  };

  const onPageAction = async (value: number, type: 'limit' | 'page') => {
    const newFilter = {
      ...filter,
      [type]: value,
    };
    if (type === 'limit') {
      Object.assign(newFilter, { page: 1 });
    }
    setFilter(newFilter);
    fetchPayload(newFilter);
  };

  // Render layout
  const _renderTopSection = () => {
    const isAcceptApi = Utils.isValidPermission(
      MODULE_API.ROLE_PERMISSION.CREATE_ROLE
    );
    return (
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <Typography variant="h2">{t('title.roles')}</Typography>
        {isAcceptApi && (
          <RoundButton
            onClick={() => Utils.redirect(ROUTERS.CREATE_ROLE)}
            type="contained"
            label={t('button.create')}
          />
        )}
      </Stack>
    );
  };

  const _renderBottomSection = () => (
    <RoundedContainer>
      <RoleDataTable
        payload={payload}
        onRowAction={onRowAction}
        isLoading={isLoading}
        meta={meta}
        onPageAction={onPageAction}
      />
    </RoundedContainer>
  );

  return (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default RoleList;
