import { createTheme, PaletteColorOptions } from '@mui/material/styles';
import { enUS } from '@mui/material/locale';
import CommonColor from './CommonColor';
declare module '@mui/material/styles' {
  interface CustomPalette {
    burntSienna: PaletteColorOptions;
    bismark: PaletteColorOptions;
    pickledBluewood: PaletteColorOptions;
    loblolly: PaletteColorOptions;
    oceanGreen: PaletteColorOptions;
    fuzzyWuzzyBrown: PaletteColorOptions;
    diSerria: PaletteColorOptions;
    mystic: PaletteColorOptions;
    astronaut: PaletteColorOptions;
    hippieBlue: PaletteColorOptions;
    perano: PaletteColorOptions;
    lemonGrass: PaletteColorOptions;
    shark: PaletteColorOptions;
    ebonyClay: PaletteColorOptions;
    sidebar: any;
    navbar: any;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    burntSienna: true;
    bismark: true;
    pickledBluewood: true;
    loblolly: true;
    oceanGreen: true;
    fuzzyWuzzyBrown: true;
    diSerria: true;
    mystic: true;
    astronaut: true;
    hippieBlue: true;
    perano: true;
    lemonGrass: true;
    shark: true;
    ebonyClay: true;
  }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor, contrastText: '#fff' } });

const CoreTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#283845',
        dark: '#202C39',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
      sidebar: {
        main: '#202c39',
        text: 'white',
      },
      navbar: {
        main: 'black',
        text: 'white',
      },
      burntSienna: createColor(CommonColor.burntSienna),
      bismark: createColor(CommonColor.bismark),
      pickledBluewood: createColor(CommonColor.pickledBluewood),
      loblolly: createColor(CommonColor.loblolly),
      oceanGreen: createColor(CommonColor.oceanGreen),
      fuzzyWuzzyBrown: createColor(CommonColor.fuzzyWuzzyBrown),
      diSerria: createColor(CommonColor.diSerria),
      mystic: createColor(CommonColor.mystic),
      astronaut: createColor(CommonColor.astronaut),
      hippieBlue: createColor(CommonColor.hippieBlue),
      perano: createColor(CommonColor.perano),
      lemonGrass: createColor(CommonColor.lemonGrass),
      shark: createColor(CommonColor.shark),
      ebonyClay: createColor(CommonColor.ebonyClay),
    },
    typography: {
      subtitle2: {
        fontSize: '16px',
        marginBottom: '10px',
      },
      h6: {
        fontSize: '24px',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            fontSize: 16,
            '*::-webkit-scrollbar': {
              width: '4px',
              height: '5px',
            },
            '*::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px #42526e',
              webkitBoxShadow: 'inset 0 0 6px #42526e',
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              outline: '1px solid slategrey',
            },
          },
          body: {
            input: {
              WebkitBoxShadow: '0 0 0 1000px white inset',
            },
            'input:-internal-autofill-selected': {
              backgroundColor: 'transparent !important',
            },
            ' .MuiOutlinedInput-root': {
              background: 'white !important',
            },
            'input:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 30px white inset !important',
            },
            'input:-webkit-autofill:hover': {
              WebkitBoxShadow: '0 0 0 30px white inset !important',
            },
            'input:-webkit-autofill:focus': {
              WebkitBoxShadow: '0 0 0 30px white inset !important',
            },
            'input:-webkit-autofill:active': {
              WebkitBoxShadow: '0 0 0 30px white inset !important',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            textTransform: 'unset',
            fontSize: '15px',
            fontFamily: 'Barlow',
          },
          h2: {
            fontSize: '30px',
            fontWeight: '400',
            marginBottom: '10px',
          },
          h3: {
            fontSize: '22px',
          },
          h6: {
            fontSize: '20px',
          },
          caption: {
            fontSize: '14px',
            fontWeight: 500,
            marginBottom: '10px',
            display: 'inline-block',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'unset',
            fontSize: '15px',
            fontFamily: 'Barlow',
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 'unset',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            fontSize: '15px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            height: '21px',
            fontSize: '15px',
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            height: '38px',
            fontSize: '13px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '15px',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          // select: { height: '20px', fontSize: '15px', padding: '8px 14px' },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '15px',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            fontWeight: 400,
          },
        },
      },
    },
  },
  enUS
);
// Color Pattern
// https://coolors.co/202c39-283845-b8b08d-f2d492-f29559
export default CoreTheme;
