import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { validate as uuidValidate } from 'uuid';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Box, Button, Stack, Typography } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { TagActions, WikiActions } from '@/Actions';
import Constants from '@/Constants';
import { Alert } from '@/Widgets';
import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';

import {
  AutocompleteSelect,
  MarkdownEditor,
  RoundedContainer,
  TextField,
} from '@/Components/Common';

import { IDocumentation } from '@/Interfaces/Documentation.interface';

import { ITag } from '../../../Interfaces/Tag.interface';
import { WikiDetailsSkeleton } from '../../../Components/Common/Skeleton';

const initialDetails = {
  id: '',
  title: '',
  description: '',
  content: '',
  attachment: [],
  tags: [],
};

const { ROUTERS, MODULE_API } = Constants;

const { getWikiById, updateWiki, setDefaultReducerWiki } = WikiActions;
const { fetchTags, createTag } = TagActions;

const EditDocumentation: React.FC = () => {
  const t = useTranslation();
  const { id } = useParams();
  const dispatch = useTypedDispatch();
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.WIKI, 'requestIsLoading')
  );
  const details: IDocumentation = useSelector((state: RootState) =>
    get(state.WIKI, 'details')
  );
  const tags: ITag[] = useSelector((state: RootState) =>
    get(state.TAG, 'payload')
  );
  const isAcceptCreateTag = Utils.isValidPermission(MODULE_API.TAG.CREATE_TAG);

  const schema = yup
    .object({
      title: yup.string().trim().required(t('message.titleRequired')),
      description: yup.string().trim(),
      content: yup.string().trim().required(t('message.contentRequired')),
      tags: yup
        .array()
        .min(1, t('message.tagsLeastAtOneItem'))
        .required(t('message.tagsRequired')),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialDetails,
  });

  React.useEffect(() => {
    if (id && uuidValidate(id)) dispatch(getWikiById(id));
    else {
      Alert({ type: 'ERROR', message: "Can't detect current documentation" });
      Utils.redirect(ROUTERS.WIKI);
    }

    return () => {
      dispatch(setDefaultReducerWiki());
    };
  }, []);

  React.useEffect(() => {
    if (details.id) {
      const { content, description, title } = details;
      const resolveTags: any = [];
      for (const tag of details.tags) {
        resolveTags.push(tag.id);
      }
      setValue('title', title);
      setValue('description', description);
      setValue('content', content);
      setValue('tags', resolveTags);
    }
  }, [details]);

  React.useEffect(() => {
    dispatch(fetchTags());
  }, []);

  const tagOptions = React.useMemo(() => {
    const resolveOptions: { label: string; value: string; color: string }[] =
      [];
    if (tags && tags.length > 0)
      tags.forEach((item: ITag) =>
        resolveOptions.push({
          label: item.name,
          value: item.id,
          color: item.color,
        })
      );
    return resolveOptions;
  }, [tags]);

  const onSubmit = (data: {
    title: string;
    description: string;
    content: string;
    attachment: File[];
    tags: string[];
  }) => {
    if (id && uuidValidate(id)) {
      const { title, description, content } = data;
      dispatch(
        updateWiki({ id, title, description, content, tags: data.tags }, id)
      );
    }
  };

  const onCreateTag = (payload: any) => dispatch(createTag(payload));

  const _renderTopSection = () => (
    <Typography variant="h2">{t('title.editWiki')}</Typography>
  );

  const _renderBottomSection = () => (
    <RoundedContainer>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <TextField
              value={field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.value)
              }
              label={t('label.title')}
              placeholder={t('placeholder.titleDocumentation')}
              message={errors.title?.message}
              required
            />
          )}
        />
        <Controller
          name="tags"
          control={control}
          render={({ field }) => (
            <AutocompleteSelect
              label={t('label.tags')}
              placeholder={t('placeholder.tags')}
              message={errors.tags?.message}
              value={field.value}
              options={tagOptions}
              required
              onChange={(newValue: string[]) => field.onChange(newValue)}
              onCreate={onCreateTag}
              sx={{ mb: 1 }}
              isAllowCreate={isAcceptCreateTag}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              value={field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.value)
              }
              label={t('label.description')}
              placeholder={t('placeholder.descriptionDocumentation')}
              rows={4}
              multiline
              message={errors.description?.message}
            />
          )}
        />
        <Controller
          name="content"
          control={control}
          render={({ field }) => (
            <MarkdownEditor
              value={field.value}
              onChange={(newValue?: string) => field.onChange(newValue)}
              label={t('label.content')}
              message={errors.content?.message}
              required
            />
          )}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mt={2}
        >
          <Button
            onClick={() => Utils.redirect(ROUTERS.WIKI)}
            variant="outlined"
          >
            {t('button.cancel')}
          </Button>
          <Button type="submit" variant="contained" sx={{ ml: 1 }}>
            {t('button.save')}
          </Button>
        </Stack>
      </Box>
    </RoundedContainer>
  );

  if (isLoading) return <WikiDetailsSkeleton />;
  return (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default EditDocumentation;
