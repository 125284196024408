import { toast } from 'react-toastify';
import API from '@/APIs';
import Utils from '@/Utils';
import Constants from '@/Constants';

import { IMetaStructure } from '@/Interfaces/Common.interface';
import {
  IDailyTaskResponse,
  IDailyTaskFilter,
} from '@/Interfaces/DailyTask.interface';

const { ACTION_TYPES, ROUTERS } = Constants;

const setDailyTaskMeta = (payload: IMetaStructure) => {
  return {
    type: ACTION_TYPES.SET_META_DAILY_TASK,
    payload,
  };
};

const setDailyTaskPagination = (payload: any) => {
  return {
    type: ACTION_TYPES.SET_PAGINATION_DAILY_TASK,
    payload,
  };
};

const resetDailyTaskDetails = () => {
  return {
    type: ACTION_TYPES.RESET_DAILY_TASK_DETAIL,
  };
};

const resetDailyTaskStatus = () => {
  return {
    type: ACTION_TYPES.RESET_DAILY_TASK_STATUS,
  };
};

const resetDailyTaskReducer = () => {
  return {
    type: ACTION_TYPES.RESET_DAILY_TASK_REDUCER,
  };
};

const setMyDailyTaskMeta = (payload: IMetaStructure) => {
  return {
    type: ACTION_TYPES.SET_META_MY_DAILY_TASK,
    payload,
  };
};

const setMyDailyTaskPagination = (payload: any) => {
  return {
    type: ACTION_TYPES.SET_PAGINATION_MY_DAILY_TASK,
    payload,
  };
};

const setDailyTaskFetchLoading = () => {
  return {
    type: ACTION_TYPES.SET_DAILY_TASK_FETCH_IS_LOADING,
  };
};

const setDailyTaskGetLoading = () => {
  return {
    type: ACTION_TYPES.SET_DAILY_TASK_GET_IS_LOADING,
  };
};

const setDailyTaskActionLoading = () => {
  return {
    type: ACTION_TYPES.SET_DAILY_TASK_ACTION_IS_LOADING,
  };
};

const fetchDailyTasksSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_DAILY_TASKS_SUCCESS,
    payload,
  };
};

const fetchDailyTasksFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_DAILY_TASKS_FAILURE,
  };
};

const fetchDailyTasks = (payload?: IDailyTaskFilter) => {
  return async (dispatch: any) => {
    dispatch(setDailyTaskFetchLoading());
    await API.fetchDailyReportTask(payload)
      .then(async (response: any) => {
        const results = await Utils.resolveResponse(response, true);
        if (!results) await dispatch(fetchDailyTasksFailure());
        else {
          const resolveResult: {
            items: any[];
            meta: IMetaStructure;
          } = results as {
            items: any[];
            meta: IMetaStructure;
          };
          await dispatch(setDailyTaskPagination(payload));
          await dispatch(setDailyTaskMeta(resolveResult?.meta));
          await dispatch(fetchDailyTasksSuccess(resolveResult?.items));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(fetchDailyTasksFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const getMyReportSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_MY_DAILY_TASKS_SUCCESS,
    payload,
  };
};

const getMyReportFailure = () => {
  return {
    type: ACTION_TYPES.GET_MY_DAILY_TASKS_FAILURE,
  };
};

const getMyReport = (payload?: IDailyTaskFilter) => {
  return async (dispatch: any) => {
    dispatch(setDailyTaskFetchLoading());
    await API.getMyDailyTasks(payload)
      .then(async (response: any) => {
        const results = await Utils.resolveResponse(response, true);
        if (!results) await dispatch(getMyReportFailure());
        else {
          const resolveResult: {
            items: IDailyTaskResponse[];
            meta: IMetaStructure;
          } = results as {
            items: IDailyTaskResponse[];
            meta: IMetaStructure;
          };
          await dispatch(setMyDailyTaskPagination(payload));
          await dispatch(setMyDailyTaskMeta(resolveResult?.meta));
          await dispatch(getMyReportSuccess(resolveResult?.items));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(getMyReportFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createDailyReportSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_DAILY_TASK_SUCCESS,
  };
};

const createDailyReportFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_DAILY_TASK_FAILURE,
  };
};

const createDailyTask = (payload: FormData, filter?: any) => {
  return async (dispatch: any) => {
    dispatch(setDailyTaskActionLoading());
    await API.createDailyReportTask(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(createDailyReportFailure());
        } else {
          toast.success(message);
          await dispatch(createDailyReportSuccess());
          Utils.redirect({
            pathname: ROUTERS.DAILY_TASK,
            search: {
              sreen: 'me',
            },
          });
          await dispatch(getMyReport(filter));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createDailyReportFailure());
      });
  };
};

const getDailyReportByIdSuccess = (payload: IDailyTaskResponse) => {
  return {
    type: ACTION_TYPES.GET_DAILY_TASK_BY_ID_SUCCESS,
    payload,
  };
};

const getDailyReportByIdFailure = () => {
  return {
    type: ACTION_TYPES.GET_DAILY_TASK_BY_ID_FAILURE,
  };
};

const getDailyTaskById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setDailyTaskGetLoading());
    await API.getDailyReportTaskById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getDailyReportByIdFailure());
        else {
          const resolveResult: any = result as any;
          dispatch(getDailyReportByIdSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(getDailyReportByIdFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const updateDailyTaskSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_DAILY_TASK_SUCCESS,
  };
};

const updateDailyTaskFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_DAILY_TASK_FAILURE,
  };
};

const updateDailyTask = (
  payload: FormData,
  id: string,
  filter?: IDailyTaskFilter
) => {
  return async (dispatch: any) => {
    dispatch(setDailyTaskActionLoading());
    await API.updateDailyReportTask(payload, id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateDailyTaskFailure());
        } else {
          await dispatch(updateDailyTaskSuccess());
          await toast.success(message);
          await dispatch(getMyReport(filter));
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateDailyTaskFailure());
      });
  };
};

const deleteDailyTaskSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_DAILY_TASK_SUCCESS,
  };
};

const deleteDailyTaskFailure = () => {
  return {
    type: ACTION_TYPES.DELETE_DAILY_TASK_FAILURE,
  };
};

const deleteDailyTask = (id: string, filter: IDailyTaskFilter) => {
  return async (dispatch: any) => {
    dispatch(setDailyTaskActionLoading());
    await API.deleteDailyReportTask(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteDailyTaskFailure());
          toast.error(message);
        } else {
          dispatch(deleteDailyTaskSuccess());
          dispatch(fetchDailyTasks(filter));
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        toast.error(error.message);
        await dispatch(deleteDailyTaskFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  createDailyTask,
  fetchDailyTasks,
  getMyReport,
  getDailyTaskById,
  updateDailyTask,
  deleteDailyTask,
  resetDailyTaskDetails,
  resetDailyTaskStatus,
  resetDailyTaskReducer,
};
