import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
import {
  IAssign,
  ICreateFeedback,
  ICreateTheards,
  IFeedbackFilter,
  IUpdateFeedback,
  IUpdateStatus,
  IUpdateTheards,
} from '@/Interfaces/Feedback.interface';

const { FEEDBACK } = CONSTANTS.API;

export const fetchFeedbacks = async (payload: IFeedbackFilter) => {
  return sendRequest(FEEDBACK.BASIC, 'GET', payload);
};

export const getAllForUserFeedbacks = async (payload: IFeedbackFilter) => {
  return sendRequest(FEEDBACK.GET_ALL_FOR_USER, 'GET', payload);
};

export const getFeedbackById = async (id: string) => {
  return sendRequest(`${FEEDBACK.BASIC}/${id}`, `GET`);
};

export const updateFeedbacks = async (payload: IUpdateFeedback) => {
  const { id } = payload;
  return sendRequest(`${FEEDBACK.BASIC}/${id}`, 'PUT', payload);
};

export const createFeedbacks = async (payload: ICreateFeedback) => {
  return sendRequest(FEEDBACK.BASIC, 'POST', payload);
};

export const createThreads = async (payload: ICreateTheards) => {
  return sendRequest(FEEDBACK.THREAD, 'POST', payload);
};

export const deleteThread = async (id: string) => {
  return sendRequest(`${FEEDBACK.THREAD}/${id}`, `DEL`);
};

export const updateThread = async (payload: IUpdateTheards) => {
  const { id } = payload;
  return sendRequest(`${FEEDBACK.THREAD}/${id}`, 'PUT', payload);
};

export const assignHandler = async (id: string, assigneeId: IAssign) => {
  return sendRequest(`${FEEDBACK.ASSIGN_HANDLER}/${id}`, 'PUT', assigneeId);
};

export const updateStatus = async (payload: IUpdateStatus) => {
  const { id } = payload;
  return sendRequest(
    `${FEEDBACK.UPDATE_FEEDBACK_STATUS}/${id}`,
    'PUT',
    payload
  );
};

export const fetchFeedbacksDashboard = async () => {
  return sendRequest(FEEDBACK.GET_FOR_DASHBOARD, 'GET');
};
