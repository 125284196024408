import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

const { ACTION_TYPES } = CONSTANTS;

// SINGLE ACTIONS
const attendancesIsRequest = () => {
  return {
    type: ACTION_TYPES.ATTENDANCES_IS_REQUEST,
  };
};

const exportAttendancesIsRequest = () => {
  return {
    type: ACTION_TYPES.EXPORT_ATTENDANCES_IS_REQUEST,
  };
};

const setDefaultAttendancesReducer = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_ATTENDANCES_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchAttendancesSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_ATTENDANCES_SUCCESS,
    payload,
  };
};

const fetchAttendancesFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_ATTENDANCES_FAILURE,
  };
};

const updateAttendancesSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_ATTENDANCES_SUCCESS,
    payload,
  };
};

const updateAttendancesError = () => {
  return {
    type: ACTION_TYPES.UPDATE_ATTENDANCES_FAILURE,
  };
};

const setMeta = (payload: any) => {
  return {
    type: ACTION_TYPES.SET_ATTENDANCE_META,
    payload,
  };
};

const fetchAttendances = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(attendancesIsRequest());
    await API.fetchAttendances(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchAttendancesFailure());
        else {
          dispatch(fetchAttendancesSuccess(result));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(fetchAttendancesFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const updateAttendances = (payload: any) => {
  return async (dispatch: any) => {
    await API.updateAttendance(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(updateAttendancesError());
        else {
          await dispatch(
            updateAttendancesSuccess(_.head(_.get(response, 'data.payload')))
          );
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(updateAttendancesError());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const exportAttendanceSuccess = () => {
  return {
    type: ACTION_TYPES.EXPORT_ATTENDANCES_SUCCESS,
  };
};

const exportAttendanceFailure = () => {
  return {
    type: ACTION_TYPES.EXPORT_ATTENDANCES_FAILURE,
  };
};

const exportAttendance = (
  exportPayload: {
    monthAndYear: string;
    keyword: string;
    language: string;
  },
  saveAsMonth: string
) => {
  return async (dispatch: any) => {
    dispatch(exportAttendancesIsRequest());
    await API.exportAttendance(exportPayload)
      .then(async (response: any) => {
        const data = response?.data;
        if (!data) {
          toast.error('Export attendance failure!');
          await dispatch(exportAttendanceFailure());
        } else {
          saveAs(data, `FLOW_IDRA_Attendances_List_In_${saveAsMonth}.xlsx`);
          toast.success('Export attendances successfully!');
          dispatch(exportAttendanceSuccess());
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(exportAttendanceFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  fetchAttendances,
  setDefaultAttendancesReducer,
  updateAttendances,
  setMeta,
  exportAttendance,
};
