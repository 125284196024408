import React from 'react';
import forEach from 'lodash/forEach';
import { Outlet, useLocation } from 'react-router';
import dayjs from 'dayjs';
import { Stack } from '@mui/material';

import Utils from '@/Utils';

import { DEFAULT_MENU } from './MenuOptions';
import { SideBar, Footer, NavBar } from '../LayoutPart';

import { ModuleKeys } from '../../Constants/Enums';
import { useTypedDispatch } from '../../store';
import { AuthActions } from '../../Actions';
import { TransitionLayer } from '../Common';
import 'dayjs/locale/vi';
import 'dayjs/locale/en';

interface IMenu {
  icon: React.JSX.Element;
  title: string;
  to: string;
  key: ModuleKeys;
}

const { logout } = AuthActions;

// Declare utils
const PortalDefaultLayout: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const [menu, setMenu] = React.useState<IMenu[]>([]);
  const locale = Utils.getSavedLocale();

  React.useEffect(() => {
    document.body.classList.add('hide-scrollbar');
    window.scrollTo(0, 0);

    const userData = Utils.getSavedUserData();
    const roleMenu = userData?.role?.menu;

    if (roleMenu && typeof roleMenu === 'object') {
      const filterMenu: IMenu[] = [];
      forEach(DEFAULT_MENU, (item: IMenu) => {
        if (roleMenu.includes(item.key)) filterMenu.push(item);
      });
      if (filterMenu) setMenu(filterMenu);
    }
    if (locale === 'en') dayjs.locale('en');
    else dayjs.locale('vi');

    return () => document.body.classList.remove('hide-scrollbar');
  }, []);

  React.useEffect(() => {
    const userData = Utils.getSavedUserData();
    if (!userData) {
      dispatch(logout());
    }
  }, [pathname]);

  const renderLayout = () => (
    <TransitionLayer>
      <Outlet />
    </TransitionLayer>
  );

  return (
    <Stack direction="row" sx={{ width: '100vw', height: '100vh' }}>
      <SideBar items={menu} />
      <Stack direction="column" sx={{ width: '100%', height: '100%' }}>
        <NavBar items={menu} />
        {renderLayout()}
        <Footer />
      </Stack>
    </Stack>
  );
};

export default PortalDefaultLayout;
