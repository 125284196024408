import { Box, Card, CardContent, Skeleton, Stack } from '@mui/material';

const NewsLoaddingDetail: React.FC = () => {
  return (
    <Box>
      <Card
        sx={{
          height: '100%',
          p: 1,
        }}
      >
        <CardContent>
          <Stack>
            <Skeleton />
          </Stack>
          <Stack>
            <Skeleton
              sx={{
                height: '160px',
                my: 3,
              }}
            />
          </Stack>
          <Stack>
            <Skeleton />
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
export default NewsLoaddingDetail;
