import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { validate as uuidValidate } from 'uuid';
import { useSearchParams } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { AuthActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { Images } from '@/Themes';

import {
  TextField,
  Typography,
  Stack,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IChangePassword } from '@/Interfaces/Auth.interface';
import { Alert } from '@/Widgets';
import Constants from '@/Constants';
import Utils from '@/Utils';

const { ROUTERS } = Constants;
const { changePassword, clearAuthMessage } = AuthActions;

const schema = yup
  .object({
    requestId: yup.string().uuid().trim().label('Request ID').required(),
    password: yup.string().trim().label('Password').required(),
    confirmPassword: yup.string().trim().label('Confirm password').required(),
  })
  .required();

const EnterPassword: React.FC = () => {
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      requestId: '',
      password: '',
      confirmPassword: '',
    },
  });
  const [isShowPassword, setIsShowPassword] = React.useState<boolean>(false);

  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.AUTH, 'requestIsLoading')
  );

  React.useEffect(() => {
    const requestId = searchParams.get('request');
    if (requestId && uuidValidate(requestId)) {
      setValue('requestId', requestId);
    } else {
      Alert({ type: 'ERROR', message: 'Request URL is invalid' });
      Utils.redirect(ROUTERS.AUTH);
    }
    return () => {
      dispatch(clearAuthMessage());
    };
  }, []);

  const onSubmit = (data: IChangePassword) => {
    dispatch(changePassword(data));
  };
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleSubmit(onSubmit);
  };

  const _renderForm = () => {
    return (
      <form
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '300px',
          padding: '20px',
        }}
      >
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Password"
              size="small"
              sx={{ mb: 1 }}
              autoFocus
              color={errors.password?.message ? 'error' : 'info'}
              onKeyDown={onKeyDown}
              error={Boolean(errors.password?.message)}
              type={isShowPassword ? 'text' : 'password'}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Confirm password"
              size="small"
              sx={{ mb: 1 }}
              autoFocus
              color={errors.confirmPassword?.message ? 'error' : 'info'}
              onKeyDown={onKeyDown}
              error={Boolean(errors.confirmPassword?.message)}
              type={isShowPassword ? 'text' : 'password'}
            />
          )}
        />
        <Stack direction="row" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={isShowPassword}
                onClick={() => setIsShowPassword(!isShowPassword)}
                size="small"
                sx={{ p: 0, mr: 0.5 }}
              />
            }
            label="Show password"
            sx={{ m: 0, span: { fontSize: '14px', color: 'black' } }}
          />
        </Stack>
        <LoadingButton
          variant="contained"
          sx={{
            marginY: 2,
            width: '100%',
          }}
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </form>
    );
  };

  const _renderMain = () => {
    return (
      <>
        <Stack
          direction="row"
          sx={{
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            borderRadius: '10px',
            backgroundColor: 'white',
            width: '100%',
            maxWidth: '800px',
            height: '100%',
            maxHeight: '600px',
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              height: '600px',
            }}
          >
            <img src={Images.singIn} alt="" width="360px" />
          </Stack>

          <Stack
            direction="column"
            sx={{ flex: 1, height: 'auto', overflow: 'hidden' }}
          >
            <Stack
              sx={{
                minHeight: '100%',
                minWidth: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'all ease 0.25s',
              }}
            >
              <img src={Images.flowIdraLogo} alt="" width="60px" />
              <Typography variant="h6" sx={{ marginY: '20px' }}>
                Change password
              </Typography>
              {_renderForm()}
            </Stack>
          </Stack>
        </Stack>
      </>
    );
  };
  return _renderMain();
};

export default EnterPassword;
