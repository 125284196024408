import React from 'react';
import get from 'lodash/get';
import {
  AppBar,
  Typography,
  Avatar,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Drawer,
  Toolbar,
  Link,
  List,
  Menu,
  IconButton,
} from '@mui/material';
import { useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import { useTranslation } from 'react-multi-lang';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import { Alert } from '@/Widgets';
import Utils from '@/Utils';
import CONSTANTS from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import { AuthActions, UserActions } from '@/Actions';
import SideBar from '../SideBar/Menu';

import LanguageSwitch from './LanguageSwitch';

import { Images } from '@/Themes';
import { ModuleKeys } from '@/Constants/Enums';

const { ROUTERS } = CONSTANTS;
const { logout } = AuthActions;
const { resetUserStatus } = UserActions;

const userMenu = [
  {
    label: 'profile',
    value: 'profile',
    icon: <PersonOutlineOutlinedIcon />,
  },
  {
    label: 'changePassword',
    value: 'changePassword',
    icon: <PasswordOutlinedIcon />,
  },
  {
    label: 'logout',
    value: 'logout',
    icon: <LogoutIcon />,
  },
];
const drawerWidth = 240;

interface IMenu {
  icon: React.JSX.Element;
  title: string;
  to: string;
  key: ModuleKeys;
}

interface IProps {
  window?: () => Window;
  items: IMenu[];
}

const NavBar: React.FC<IProps> = ({ window, items }) => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  // Declare states
  const isUpdateAvatarSuccess: boolean = useSelector((state: RootState) =>
    get(state.USER, 'updateAvatarSuccess')
  );
  const userDetails = Utils.getSavedUserData();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isUpdateAvatarSuccess) {
      dispatch(resetUserStatus());
    }
  }, [isUpdateAvatarSuccess]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const onMenuAction = async (action: string) => {
    setAnchorEl(null);
    if (action === 'profile') Utils.redirect(ROUTERS.PROFILE);
    if (action === 'changePassword')
      Utils.redirect(ROUTERS.PROFILE_CHANGE_PASSWORD);
    if (action === 'logout') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningBeforeLogout'),
      });
      if (isAgree === 'ok') dispatch(logout());
    }
  };

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const _renderLanguageSwitch = () => <LanguageSwitch />;

  const _renderAvatar = () => {
    let avatar = 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png';
    if (userDetails?.userData?.avatar)
      avatar = userDetails?.userData?.avatar.path;
    return (
      <Stack direction="row" sx={{ ml: 2, alignItems: 'center' }}>
        <Typography sx={{ fontSize: '14px', mr: 1 }}>
          {userDetails?.userData?.fullName}
        </Typography>
        <Avatar
          sx={{ width: 30, height: 30, cursor: 'pointer' }}
          alt="Admin"
          src={avatar}
          onClick={handleClick}
        />
      </Stack>
    );
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div>
      <Toolbar
        sx={{
          fontSize: '20px',
          color: 'sidebar.text',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          padding: '0 24px !important',
        }}
      >
        <Link
          sx={{
            height: '34px',
            display: 'flex',
            flexDirection: 'row',
            ':hover': { cursor: 'pointer' },
          }}
          href={ROUTERS.DASHBOARD}
        >
          <img
            src={Images.logo}
            alt="FLOW IDRA logo"
            style={{
              visibility: 'visible',
            }}
          />
        </Link>
      </Toolbar>
      <List>{SideBar({ items, isExpand: false })}</List>
    </div>
  );

  const _renderUserMenu = () => (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{ mt: 6 }}
    >
      {userMenu.map(
        (item: { label: string; icon: React.JSX.Element; value: string }) => (
          <MenuItem
            onClick={() => onMenuAction(item.value)}
            key={`menu-${item.value}`}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              sx={{
                span: {
                  fontSize: '14px',
                },
              }}
            >
              {t(`menu.${item.label}`)}
            </ListItemText>
          </MenuItem>
        )
      )}
    </Menu>
  );

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: 'rgb(252,252,252,0.6)',
        color: '#42526e',
        fontSize: '14px',
        top: 0,
        zIndex: 100,
      }}
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: false,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            transition: 'all ease 0.25s',
            boxSizing: 'border-box',
            width: drawerWidth,
            overflow: 'hidden',
          },
        }}
        PaperProps={{
          sx: {
            backgroundColor: '#f4f5f7',
            color: 'sidebar.text',
          },
        }}
      >
        {drawer}
      </Drawer>
      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() => setMobileOpen(true)}
          sx={{ ml: 1, display: { xs: 'block', sm: 'none' } }}
        >
          <MenuOutlinedIcon fontSize="small" />
        </IconButton>
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '5px 16px',
            flex: 1,
          }}
        >
          {_renderLanguageSwitch()}

          {_renderAvatar()}
          {_renderUserMenu()}
        </Stack>
      </Stack>
    </AppBar>
  );
};

export default NavBar;
