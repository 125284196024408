/* eslint-disable indent */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';

import { useTranslation } from 'react-multi-lang';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { DateRange } from 'mui-daterange-picker';
import {
  Button,
  Stack,
  Box,
  IconButton,
  TextField,
  InputLabel,
} from '@mui/material';

import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import DateRangePicker from '@/Components/Popup/DateRangePickerPopup';

import { RoundedContainer, Dropdown, RoundButton } from '@/Components/Common';

import MyReportTable from './MyReportTable';

import { ProjectActions, DailyTaskActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import Constants from '@/Constants';
import Utils from '@/Utils';

import { IProjectResponseStructure } from '@/Interfaces/Project.interface';

const { MODULE_API, ROUTERS } = Constants;
const { fetchProjects } = ProjectActions;
const { getMyReport } = DailyTaskActions;
const endDate = dayjs();
const startDate = endDate?.subtract(7, 'day');

interface IOption {
  label: string;
  value: string;
}

interface ISectionProps {
  onScreenChange(newValue: string): void;
}

const initialFilter = {
  keyword: '',
  page: 0,
  limit: 0,
  status: '',
  projectId: '',
  userIds: [],
};

const MyReport: React.FC<ISectionProps> = ({ onScreenChange }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const projects = useSelector((state: RootState) =>
    _.get(state.PROJECT, 'payload')
  );
  const pagination = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'myPagination')
  );
  const userData = Utils.getSavedUserData();

  const myReports = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'myReport')
  );

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'isFetchLoading')
  );

  const isAcceptFetchDailyTask = Utils.isValidPermission(
    MODULE_API.DAILY_TASK.FETCH
  );

  const isAcceptCreateDailyTask = Utils.isValidPermission(
    MODULE_API.DAILY_TASK.CREATE
  );

  const [isOpenPicker, setIsOpenPicker] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialFilter,
  });

  const projectOptions = React.useMemo(() => {
    const options: IOption[] = [
      {
        label: t('label.allProjects'),
        value: 'allType',
      },
    ];
    _.forEach(projects, (project: IProjectResponseStructure) =>
      options.push({
        label: project.name,
        value: project.id,
      })
    );
    return options;
  }, [projects]);

  useEffect(() => {
    dispatch(
      getMyReport({
        page: 0,
        limit: 0,
        userId: userData.id,
        startDate: pagination?.startDate
          ? pagination?.startDate
          : dayjs(startDate).format('YYYY-MM-DD'),
        endDate: pagination?.endDate
          ? pagination?.endDate
          : dayjs(endDate).format('YYYY-MM-DD'),
      })
    );
    dispatch(
      fetchProjects({
        page: 0,
        limit: 0,
        startDate: null,
      })
    );
    setDateRange({
      startDate: pagination?.startDate
        ? new Date(pagination?.startDate)
        : startDate.toDate(),
      endDate: pagination?.endDate
        ? new Date(pagination?.endDate)
        : endDate.toDate(),
    });
  }, []);

  const handleReset = () => {
    setDateRange({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
    reset();
    dispatch(
      getMyReport({
        page: 0,
        limit: 0,
        startDate: dayjs(startDate).isValid()
          ? dayjs(startDate).format('YYYY-MM-DD')
          : '',
        endDate: dayjs(endDate).isValid()
          ? dayjs(endDate).format('YYYY-MM-DD')
          : '',
      })
    );
  };

  const onSubmit = (data: any) => {
    const cleanedData = _.pickBy(
      data,
      (value: any) => !(_.isString(value) && value.trim() === '')
    );

    const formattedData = {
      ...pagination,
      ...cleanedData,
      keyword: _.trim(data.keyword),
    };

    if (dayjs(data.startDate).isValid()) {
      formattedData.startDate = dayjs(startDate).format('YYYY-MM-DD');
    }

    if (dayjs(data.endDate).isValid()) {
      formattedData.endDate = dayjs(endDate).format('YYYY-MM-DD');
    }
    dispatch(getMyReport(formattedData));
  };

  const _renderTopSection = () => (
    <RoundedContainer>
      <Stack sx={{ mb: 2 }} direction="column">
        <Stack>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <InputLabel>
                  {!_.isEmpty(dateRange)
                    ? `${dayjs(dateRange.startDate).format(
                        'DD-MM-YYYY'
                      )} - ${dayjs(dateRange.endDate).format('DD-MM-YYYY')}`
                    : 'No dates have been selected'}
                </InputLabel>
                <IconButton
                  disabled={isFetchLoading}
                  onClick={() => setIsOpenPicker(true)}
                >
                  <AccessAlarmIcon />
                </IconButton>
              </Box>
              <Controller
                name="keyword"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={isFetchLoading}
                    value={field.value}
                    size="small"
                    onChange={(e: any) => field.onChange(e.target.value)}
                    placeholder={t('placeholder.keyword')}
                    sx={{ mr: 1, maxWidth: '190px' }}
                  />
                )}
              />
              <Controller
                name="projectId"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    disabled={isFetchLoading}
                    sx={{ mr: 1, width: '190px' }}
                    value={field.value || 'allType'}
                    placeholder={t('label.project')}
                    options={projectOptions}
                    onChange={(e: any) => field.onChange(e)}
                  />
                )}
              />
              <Button
                variant="contained"
                disabled={isFetchLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t('button.filter')}
              </Button>
              <Button
                variant="outlined"
                disabled={isFetchLoading}
                onClick={handleReset}
              >
                {t('button.reset')}
              </Button>
            </Box>
            {isAcceptCreateDailyTask && (
              <Button
                variant="contained"
                disabled={isFetchLoading}
                onClick={() => Utils.redirect(ROUTERS.DAILY_TASK_CREATE)}
              >
                {t('button.add')}
              </Button>
            )}
          </Box>
          <DateRangePicker
            open={isOpenPicker}
            onClose={() => setIsOpenPicker(!isOpenPicker)}
            callback={(newValue) => setDateRange(newValue)}
            payload={dateRange}
          />
        </Stack>
      </Stack>
    </RoundedContainer>
  );

  const _renderBottomSection = () => (
    <RoundedContainer>
      <MyReportTable payload={myReports} />
    </RoundedContainer>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {isAcceptFetchDailyTask && (
        <Box>
          <RoundButton
            disabled={isFetchLoading}
            startIcon={<ArrowBackIosNewIcon fontSize="small" />}
            label={t('button.backToDailyTask')}
            onClick={() => onScreenChange('list')}
            sx={{ border: 'none', ':hover': { border: 'none' } }}
          />
        </Box>
      )}

      {_renderTopSection()}
      {_renderBottomSection()}
    </Box>
  );
};

export default MyReport;
