export default {
  SAVED_SECURE_TOKEN: '@FIW:secure_token',
  CURRENT_LANGUAGE: '@FIW:current_language',
  SAVED_USER_DATA: '@FIW:udata',
  SAVED_FULL_NAME: '@FIW:full_name',
  ROLE_KEY: '@FIW:role_key',
  LOCALE: '@FIW:locale',
  SAVED_WIDTH: '@FIW:width',
  ACCEPT_COOKIE: '@FIW:is_accept_cookie',
};
