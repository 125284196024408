import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router';
import { validate as uuidValidate } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import {
  Typography,
  Stack,
  Box,
  InputLabel,
  TextField as TextFieldMui,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';

import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import { UserActions, WorkProgressActions } from '@/Actions';
import CONSTANTS from '@/Constants';
import { Alert } from '@/Widgets';
import { RoundedContainer, TextField } from '@/Components/Common';
import TransferList from './transferList';
import StepWorkProgress from './StepWorkProgress';
import { IUserInformationDetailsStructure } from '@/Interfaces/User.interface';
import {
  IUpdateWorkProgress,
  IWorkProgressResponseStructure,
} from '@/Interfaces/WorkProgress.interface';

const { ROUTERS, MODULE_API } = CONSTANTS;
const { fetchUser, resetUserReducer } = UserActions;
const {
  setDefaultReducerWorkProgress,
  getWorkProgressById,
  updateWorkProgress,
} = WorkProgressActions;

const WorkProgressDetails: React.FC = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const { id } = useParams();
  const details: IWorkProgressResponseStructure = useSelector(
    (state: RootState) => _.get(state.WORK_PROGRESS, 'details')
  );
  const listUser: IUserInformationDetailsStructure[] = useSelector(
    (state: RootState) => _.get(state.USER, 'payload')
  );
  const [message, setMessage] = React.useState<string>('');
  const isAcceptEdit = Utils.isValidPermission(MODULE_API.WORK_PROGRESS.UPDATE);
  const isAcceptFetchUser = Utils.isValidPermission(
    MODULE_API.USER.FETCH_USERS
  );
  // state assigned list
  const [newCheckedList, setNewCheckedList] = React.useState<string[]>([]);
  const [removeCheckedList, setRemoveCheckedList] = React.useState<string[]>(
    []
  );
  // state search user
  const [resolveSearchUsers, setResolveSearchUsers] = React.useState<any>([]);
  const [searched, setSearched] = React.useState('');

  const schema = yup
    .object()
    .shape({
      name: yup.string().trim().required(t('message.workProgressNameRequired')),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      createdBy: '',
    },
  });

  React.useEffect(() => {
    if (details?.id) {
      const { name, createdBy } = details;
      setValue('name', name);
      setValue('createdBy', createdBy?.userData?.fullName);
    }
    if (!isAcceptFetchUser) setResolveSearchUsers(details.wasAssigned);
  }, [details]);

  React.useEffect(() => {
    if (listUser) setResolveSearchUsers(listUser);
  }, [listUser]);

  React.useEffect(() => {
    if (id && uuidValidate(id)) {
      dispatch(getWorkProgressById(id));

      if (!isAcceptFetchUser && isAcceptEdit)
        Alert({
          type: 'ERROR',
          message: t('popup.notAuthorizeForFetchUsers'),
        });
      else if (isAcceptFetchUser)
        dispatch(
          fetchUser({
            status: 'active',
            page: 1,
            limit: 0,
          })
        );
    }
    return () => {
      dispatch(setDefaultReducerWorkProgress());
      dispatch(resetUserReducer());
      dispatch(setDefaultReducerWorkProgress());
    };
  }, []);

  // Resolve search user
  const searchAssignedList = (searchedVal: any) => {
    const resultsFilter = _.filter(listUser, (result: any) => {
      const label = _.get(result, 'userData.fullName');
      if (!label) return;
      const lowerLabel = _.toLower(searchedVal.target.value)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const lowerResult = _.toLower(label)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const checkLike = lowerResult.indexOf(lowerLabel);
      return checkLike > -1;
    });
    setSearched(searchedVal.target.value.toLowerCase());
    setResolveSearchUsers(resultsFilter);
  };

  const clearSearched = () => {
    setSearched('');
    setResolveSearchUsers(listUser);
  };

  // Resolve checked states
  const resolveCheckedStates = (checkIds: string[], type: 'add' | 'remove') => {
    let newRemoveListIds: string[] = [...removeCheckedList];
    let newAddListIds: string[] = [...newCheckedList];
    switch (type) {
      case 'add': {
        _.forEach(checkIds, (checkId: string) => {
          if (!_.includes(newCheckedList, checkIds))
            newAddListIds.push(checkId);
          else
            newAddListIds = _.filter(
              newAddListIds,
              (nId: string) => nId !== checkId
            );
          if (_.includes(removeCheckedList, checkId))
            newRemoveListIds = _.filter(
              newRemoveListIds,
              (rId: string) => rId !== checkId
            );
        });
        break;
      }
      default: {
        _.forEach(checkIds, (checkId: string) => {
          if (!_.includes(newRemoveListIds, checkId))
            newRemoveListIds.push(checkId);
          else
            newRemoveListIds = _.filter(
              newRemoveListIds,
              (nId: string) => nId !== checkId
            );
          if (_.includes(newAddListIds, checkId))
            newAddListIds = _.filter(
              newAddListIds,
              (rId: string) => rId !== checkId
            );
        });
        break;
      }
    }
    setNewCheckedList(newAddListIds);
    setRemoveCheckedList(newRemoveListIds);
  };

  const onCancel = () => {
    reset();
    Utils.redirect(ROUTERS.WORK_PROGRESS);
  };

  const onSubmit = (data: IUpdateWorkProgress) => {
    const assignee = _.map(details?.wasAssigned, (element: any) => element);
    const newWasAssigned = _.filter(
      newCheckedList,
      (item: any) => !_.find(assignee, ['id', item])
    );
    const removeWasAssigned = _.filter(removeCheckedList, (item: any) =>
      _.find(assignee, ['id', item])
    );
    if (!isAcceptFetchUser) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForFetchUserToCreateWorkProgress'),
      });
    } else if (
      _.isEmpty(newWasAssigned) &&
      removeWasAssigned?.length === assignee?.length
    )
      setMessage(t('message.workProgressAssignedByRequired'));
    else
      dispatch(
        updateWorkProgress(
          {
            ...data,
            newWasAssigned,
            removeWasAssigned,
          },
          details.id
        )
      );
  };

  const _renderTopSection = () => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h2">{t('title.workProgressDetails')}</Typography>
      </Stack>
    );
  };

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Stack direction="row" mb={1}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    field.onChange(event.target.value)
                  }
                  label={t('label.workProgressName')}
                  placeholder="IDRA Website"
                  message={errors.name?.message}
                  required={!!isAcceptEdit}
                  sx={{ mr: 1 }}
                  disabled={!isAcceptEdit}
                />
              )}
            />
          </Stack>

          <Stack direction="row" mb={2}>
            <Controller
              name="createdBy"
              control={control}
              render={({ field }) => (
                <InputLabel sx={{ color: 'black', mb: 0.5 }}>
                  <span style={{ fontWeight: 500 }}>
                    {t('label.createdBy')}
                  </span>
                  : {field.value}
                </InputLabel>
              )}
            />
            <Stack flex={1} />
          </Stack>

          <Stack mb={2}>
            <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
              {t('label.assignedBy')}{' '}
              {isAcceptEdit && (
                <Typography component="span" color="error">
                  (*)
                </Typography>
              )}
            </InputLabel>
            {isAcceptFetchUser && (
              <Stack direction="row">
                <Stack flex={1} sx={{ mb: 1 }}>
                  <TextFieldMui
                    value={searched}
                    placeholder={t('label.searchUsers')}
                    sx={{
                      width: '80%',
                      maxWidth: 250,
                    }}
                    variant="standard"
                    onChange={(searchVal) => searchAssignedList(searchVal)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              clearSearched();
                            }}
                          >
                            <ClearIcon sx={{ height: '20px' }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Stack flex={1} />
              </Stack>
            )}
            <Stack flex={1} sx={{ mb: 1 }}>
              <TransferList
                payload={resolveSearchUsers}
                details={details}
                onAddNewIds={(ids) => {
                  resolveCheckedStates(ids, 'add');
                }}
                onAddRemoveIds={(ids) => resolveCheckedStates(ids, 'remove')}
                message={message}
              />
              <Typography color="#d32f2f" mt={0.5} fontSize="0.75rem">
                {message}
              </Typography>
            </Stack>
          </Stack>

          <Stack>
            <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
              {t('label.stepWorkProgress')}
            </InputLabel>
            <StepWorkProgress payload={details} />
          </Stack>

          <Stack direction="row" justifyContent="flex-end" sx={{ mt: '50px' }}>
            <LoadingButton
              onClick={() => onCancel()}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              {t('button.cancel')}
            </LoadingButton>

            {isAcceptEdit && (
              <LoadingButton type="submit" variant="contained">
                {t('button.save')}
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </RoundedContainer>
    );
  };

  const renderMain = () => (
    <Stack flex={1} direction="column" sx={{ height: 'max-content', p: 2 }}>
      {_renderTopSection()}
      {_renderBottomSection()}
    </Stack>
  );
  return renderMain();
};

export default WorkProgressDetails;
