const createDataProjectList = (
  index: string,
  name: string,
  working_date: string,
  updated_at: string,
  status: string,
  action: JSX.Element
): any => {
  return {
    index,
    name,
    working_date,
    updated_at,
    status,
    action,
  };
};

export { createDataProjectList };
