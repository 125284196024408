import * as React from 'react';
import { useTranslation } from 'react-multi-lang';

import Alert from '@/Widgets/Alert';
import { Images } from '@/Themes';

import { getSavedLocale, saveLocale } from '@/Utils/Cookie.utils';
import { setLanguage } from 'react-multi-lang';
import { Dropdown } from '../../Common';
import { Avatar, Stack } from '@mui/material';

const LanguageSwitch: React.FC = () => {
  const t = useTranslation();
  const [locale, setLocale] = React.useState<string>('en');

  React.useEffect(() => {
    const localeStorage = getSavedLocale();
    setLocale(localeStorage);
  }, []);

  const handleChangeLanguage = async (lang: 'vi' | 'en') => {
    const isAgree = await Alert({
      type: 'WARNING',
      message: t('alert.isSwitchLanguage'),
    });
    if (isAgree === 'ok') {
      saveLocale(lang);
      setLanguage(lang);
      window.location.reload();
    }
  };

  return (
    <Stack direction="row">
      <Dropdown
        options={[
          {
            label: 'English',
            value: 'en',
            startEl: <Avatar src={Images.ukFlag} />,
          },
          {
            label: 'Tiếng Việt',
            value: 'vi',
            startEl: <Avatar src={Images.vietnamFlag} />,
          },
        ]}
        value={locale}
        onChange={(e: any) => handleChangeLanguage(e.target.value)}
      />
    </Stack>
  );
};

export default LanguageSwitch;
