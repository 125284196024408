import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

const TableSkeleton = () => {
  const _renderRow = (isHeader?: boolean) => {
    const resolveHeight = isHeader ? '57px' : '47px';
    const resolveAnimation = isHeader ? false : 'pulse';
    return (
      <TableRow>
        <TableCell
          sx={{
            width: '80px',
            height: resolveHeight,
            padding: '0 16px',
          }}
        >
          <Skeleton
            animation={resolveAnimation}
            variant="text"
            height={35}
            sx={{ fontSize: '1rem' }}
          />
        </TableCell>
        <TableCell
          sx={{ width: '350px', height: resolveHeight, padding: '0 16px' }}
        >
          <Skeleton
            animation={resolveAnimation}
            variant="text"
            height={35}
            sx={{ fontSize: '1rem' }}
          />
        </TableCell>
        <TableCell
          sx={{ width: '100px', height: resolveHeight, padding: '0 16px' }}
        >
          <Skeleton
            animation={resolveAnimation}
            variant="text"
            height={35}
            sx={{ fontSize: '1rem' }}
          />
        </TableCell>
        <TableCell
          sx={{ width: '150px', height: resolveHeight, padding: '0 16px' }}
        >
          <Skeleton
            animation={resolveAnimation}
            variant="text"
            height={35}
            sx={{ fontSize: '1rem' }}
          />
        </TableCell>
        <TableCell
          sx={{ width: '80px', height: resolveHeight, padding: '0 16px' }}
        >
          <Skeleton
            animation={resolveAnimation}
            variant="text"
            height={35}
            sx={{ fontSize: '1rem' }}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Table>
      <TableHead>{_renderRow(true)}</TableHead>
      <TableBody>
        {_renderRow()}
        {_renderRow()}
        {_renderRow()}
        {_renderRow()}
        {_renderRow()}
        {_renderRow()}
        {_renderRow()}
        {_renderRow()}
        {_renderRow()}
        {_renderRow()}
      </TableBody>
    </Table>
  );
};

export default TableSkeleton;
