import { Skeleton, Stack } from '@mui/material';

const ToolDetailsSkeleton = () => {
  return (
    <Stack direction="column">
      <Stack direction="row">
        <Stack direction="column" sx={{ flex: 1, mr: 1 }}>
          <Skeleton variant="text" width={80} height={28} />
          <Skeleton variant="text" height={50} />
        </Stack>
        <Stack direction="column" sx={{ flex: 1 }}>
          <Skeleton variant="text" width={80} height={28} />
          <Skeleton variant="text" height={50} />
        </Stack>
      </Stack>
      <Stack direction="column" sx={{ flex: 1, mt: 1 }}>
        <Skeleton variant="text" width={80} height={28} />
        <Skeleton variant="text" height={50} />
        <Skeleton
          variant="rectangular"
          width="50%"
          height={240}
          sx={{ borderRadius: '5px' }}
        />
      </Stack>
      <Stack direction="column" sx={{ flex: 1, mt: 1 }}>
        <Skeleton variant="text" width={80} height={28} />
        <Skeleton
          variant="rectangular"
          height={150}
          sx={{ borderRadius: '5px' }}
        />
      </Stack>
    </Stack>
  );
};

export default ToolDetailsSkeleton;
