import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';
import { ILogsDetailsStructure } from '@/Interfaces/Logs.interface';

const { ACTION_TYPES } = CONSTANTS;

// SINGLE ACTIONS
const logsIsRequest = () => {
  return {
    type: ACTION_TYPES.LOGS_IS_REQUEST,
  };
};

const setLogsMeta = (payload: any) => {
  return {
    type: ACTION_TYPES.SET_LOGS_META,
    payload,
  };
};

const setDefaultLogsReducer = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_LOGS_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchLogsSuccess = (payload: ILogsDetailsStructure) => {
  return {
    type: ACTION_TYPES.FETCH_LOGS_SUCCESS,
    payload,
  };
};

const fetchLogsFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_LOGS_FAILURE,
  };
};

const fetchLogs = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(logsIsRequest());
    await API.fetchLogs(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchLogsFailure());
        else {
          const resolveResult: { items: any; meta: any } = result as {
            items: any;
            meta: any;
          };
          dispatch(setLogsMeta(resolveResult.meta));
          dispatch(fetchLogsSuccess(resolveResult.items));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(fetchLogsFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  fetchLogs,
  setDefaultLogsReducer,
};
