import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import { get } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import CONSTANTS from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import { IAnnouncementFilterUser } from '@/Interfaces/Project.interface';
import { Select } from '@/Components/Common';
import { AnnouncementUserDataTable } from '@/Components/LayoutPart/DataTable';
import { IMetaStructure } from '@/Interfaces/Common.interface';

import { AnnouncementUserAction } from '@/Actions';
import { DialogAnnouncement } from '@/Components/LayoutPart';
import Utils from '@/Utils';

const { ENUMS } = CONSTANTS;
const { getAllforUserAnnouncements, updateReadAnnouncements } =
  AnnouncementUserAction;

const initialFilter: IAnnouncementFilterUser = {
  status: ENUMS.AnnouncementtStatus.ALL_TYPE,
  page: 1,
  limit: 10,
};

const initialStatusOptions = [
  { labelEN: 'All type', labelVI: 'Tất cả', value: 'allType' },
  { labelEN: 'Read', labelVI: 'Đã đọc', value: 'read' },
  { labelEN: 'Unread', labelVI: 'Chưa đọc', value: 'unRead' },
];

const AnnouncementUser: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const language = Utils.getSavedLocale();
  const [openAnnoucement, setOpenAnnouncement] = useState<boolean>(false);
  const [dataAnnoucement, setDataAnnouncement] = useState<any>();
  const [pagination, setPagination] =
    useState<IAnnouncementFilterUser>(initialFilter);
  const { control, setValue } = useForm({
    defaultValues: initialFilter,
  });
  const meta: IMetaStructure = useSelector((state: RootState) =>
    get(state.ANNOUNCEMENT_USER, 'meta')
  );
  const allForUser: any = useSelector((state: RootState) =>
    get(state.ANNOUNCEMENT_USER, 'allForUser')
  );
  const isLoading: any = useSelector((state: RootState) =>
    get(state.ANNOUNCEMENT_USER, 'requestIsLoading')
  );

  const fetchPayload = (filterParams: any) => {
    const resolverFilter = Utils.validateFilters(filterParams);
    dispatch(getAllforUserAnnouncements(resolverFilter));
  };

  useEffect(() => {
    fetchPayload(initialFilter);
  }, []);

  const onSearch = (data: string) => {
    setPagination({ ...pagination, status: data, page: 1 });
    fetchPayload({ ...initialFilter, status: data });
  };

  const statusOptions = React.useMemo(() => {
    const resolveOptions: any[] = [];
    const isEnglish = language === 'en';

    for (const option of initialStatusOptions) {
      const resolveLabel = isEnglish ? option.labelEN : option.labelVI;
      resolveOptions.push({
        label: resolveLabel,
        value: option.value,
      });
    }
    return resolveOptions;
  }, [initialStatusOptions, language]);

  const onPageAction = async (value: number, type: 'limit' | 'page') => {
    const currentFilter = pagination;
    const newFilter = {
      ...currentFilter,
      [type]: value,
    };
    setValue(type, value);
    if (type === 'limit') {
      setValue('page', 1);
      Object.assign(newFilter, { page: 1 });
    }
    setPagination(newFilter);
    fetchPayload(newFilter);
  };

  const onRowAction = async (item: any, action: 'detail') => {
    if (action === 'detail') {
      setOpenAnnouncement(true);
      setDataAnnouncement(item);
      if (item.status === 'unRead') {
        const currentFilter = pagination;
        const resolveFilter = Utils.validateFilters(currentFilter);
        dispatch(updateReadAnnouncements(item.id, 'read', resolveFilter));
      }
    }
  };

  const handleCloseAnnouncement = () => {
    setOpenAnnouncement(false);
  };

  const _renderDialogAnnouncement = () => {
    return (
      <Box>
        <DialogAnnouncement
          payload={dataAnnoucement}
          open={openAnnoucement}
          onClose={handleCloseAnnouncement}
        />
      </Box>
    );
  };

  const _renderTopSection = () => {
    return (
      <Box>
        <Typography variant="h2" mb={2}>
          {t('title.announcement')}
        </Typography>
        <Stack direction="row" sx={{ mb: 2 }}>
          <Stack direction="row">
            <Controller
              name="status"
              control={control}
              render={() => (
                <Select
                  value={pagination.status || ''}
                  sx={{ mr: 1, width: '150px' }}
                  options={statusOptions}
                  onChange={(e: any) => onSearch(e)}
                />
              )}
            />
          </Stack>
        </Stack>
      </Box>
    );
  };

  const _renderBody = () => {
    return (
      <Box>
        <AnnouncementUserDataTable
          meta={meta}
          payload={allForUser}
          onPageAction={onPageAction}
          onRowAction={onRowAction}
          isLoading={isLoading}
        />
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <>
        {_renderTopSection()}
        {_renderBody()}
        {_renderDialogAnnouncement()}
      </>
    );
  };

  return renderMain();
};

export default AnnouncementUser;
