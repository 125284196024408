import React from 'react';
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputLabel,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-multi-lang';

interface IProps {
  label?: string;
  required?: boolean;
  placeholder?: string;
  message?: string;
  onChange(file: File | null): void;
  sx?: object;
  disabled?: boolean;
  preview?: string;
  isShowPreview?: boolean;
  filename?: string;
  type: 'image' | 'file';
  downloadPath?: string;
}

const UploadFile: React.FC<IProps> = ({
  label = '',
  message = '',
  required = false,
  preview = '',
  isShowPreview = false,
  filename = '',
  onChange,
  type = 'image',
  downloadPath = '',
  sx = {},
}) => {
  const t = useTranslation();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [validateMessage, setValidateMessage] = React.useState<string>('');

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      onChange(file);
      if (file) {
        message = t('message.wrongTypeOnlySupportDocAndDocxType', {
          name: file.name,
        });
        if (
          file.type ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          file.type === 'application/msword'
        ) {
          setValidateMessage('');
        } else setValidateMessage(message);
      } else setValidateMessage('');
    } else onChange(null);
  };

  return (
    <Stack direction="column" sx={{ ...sx }}>
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}{' '}
          {required ? (
            <Typography
              component="span"
              color="error"
              sx={{ fontSize: '13px' }}
            >
              (*)
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      <Stack direction="column">
        <Box
          component="label"
          htmlFor="upload-file"
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            ':hover': {
              cursor: 'pointer',
            },
          }}
        >
          {type === 'image' ? <BrokenImageOutlinedIcon /> : null}
          {type === 'file' ? <AttachFileOutlinedIcon /> : null}
          <Typography sx={{ flex: 1, fontSize: '14px' }}>
            {filename || t('label.noFileChoosen')}
          </Typography>
          {downloadPath ? (
            <Tooltip title={t('tooltip.clickToDownloadAttachment')}>
              <IconButton>
                <CloudDownloadOutlinedIcon
                  fontSize="small"
                  onClick={() => saveAs(downloadPath)}
                />
              </IconButton>
            </Tooltip>
          ) : null}
          <Button
            onClick={() =>
              inputRef && inputRef.current && inputRef.current.click()
            }
          >
            {t('button.upload')}
          </Button>
        </Box>
        <input
          style={{ display: 'none' }}
          id="upload-file"
          type="file"
          ref={inputRef}
          onChange={onChangeInput}
          multiple={false}
        />
        {isShowPreview && preview ? (
          <Box
            component="div"
            sx={{
              width: '100%',
              maxWidth: '500px',
              height: 'auto',
              aspectRatio: '16 / 9',
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
              borderRadius: '5px',
              overflow: 'hidden',
            }}
          >
            <Box
              component="img"
              src={preview}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Box>
        ) : null}
      </Stack>
      {validateMessage ? (
        <FormHelperText error={true}>{validateMessage}</FormHelperText>
      ) : null}
    </Stack>
  );
};

export default UploadFile;
