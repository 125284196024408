import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const rotateOne = keyframes`
  from {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  to {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
`;

const rotateTwo = keyframes`
  from {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  to {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
`;

const rotateThree = keyframes`
  from {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  to {
     transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg)
  }
`;

const loaderStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '200px',
  },
  main: {
    position: 'relative',
    width: '46px',
    height: '46px',
    borderRadius: '50%',
    perspective: '800px',
  },
  inner: {
    position: 'absolute',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  innerOne: {
    left: '0%',
    top: '0%',
    animation: `${rotateOne} 1s linear infinite`,
    borderBottom: `3px solid #EE7700`,
  },
  innerTwo: {
    right: '0%',
    top: '0%',
    animation: `${rotateTwo} 1s linear infinite`,
    borderBottom: `3px solid #0068C5`,
  },
  innerThree: {
    right: '0%',
    bottom: '0%',
    animation: `${rotateThree} 1s linear infinite`,
    borderBottom: `3px solid white`,
  },
};

const Loading: React.FC = () => {
  return (
    <Box component="div" sx={loaderStyles.container}>
      <Box component="div" sx={loaderStyles.main}>
        <Box
          className="inner one"
          sx={{ ...loaderStyles.inner, ...loaderStyles.innerOne }}
        />
        <Box
          className="inner two"
          sx={{ ...loaderStyles.inner, ...loaderStyles.innerTwo }}
        />
        <Box
          className="inner three"
          sx={{ ...loaderStyles.inner, ...loaderStyles.innerThree }}
        />
      </Box>
    </Box>
  );
};

export default Loading;
