import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { RootState } from '../../../store';

interface IProps {
  children?: React.JSX.Element | React.JSX.Element[];
}

const TransitionLayer: React.FC<IProps> = ({ children }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const isExpandSidebar: boolean = useSelector((state: RootState) =>
    get(state.COMMON, 'expandSidebar')
  );

  const style = React.useMemo(() => {
    const resolveStyle = {
      width: '100%',
    };
    if (!matches) {
      if (isExpandSidebar)
        Object.assign(resolveStyle, {
          width: 'calc(100vw - 55px)',
        });
      else
        Object.assign(resolveStyle, {
          width: 'calc(100vw - 240px)',
        });
    }
    return resolveStyle;
  }, [isExpandSidebar, matches]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.25 }}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        transition: 'all ease 0.25s',
        overflow: 'auto',
        ...style,
      }}
    >
      {children}
    </motion.div>
  );
};

export default TransitionLayer;
