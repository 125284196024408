/* eslint-disable indent */
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';

import {
  Box,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  IconButton,
  Link,
  Tooltip,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { TableSkeleton } from '@/Components/Common/Skeleton';
import { RootState } from '@/store';
import Utils from '@/Utils';
import Constants from '@/Constants';
import { IDailyTaskResponse } from '@/Interfaces/DailyTask.interface';

const { ROUTERS } = Constants;

/* eslint-disable react/function-component-definition */

interface ISectionProps {
  payload: any[];
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderLeft: `1px solid ${theme.palette.divider}`,
  borderRight: `1px solid ${theme.palette.divider}`,
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  // textAlign: 'center',
}));

const StyledTableRow = styled(TableRow)(() => ({}));

const MyReportTable: React.FC<ISectionProps> = ({ payload }) => {
  const t = useTranslation();

  const uniqueDates = [
    ...new Set(payload.map((item) => dayjs(item.date).format('DD-MM-YYYY'))),
  ];
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'isFetchLoading')
  );

  const [isStickyTable, setIsStickyTable] = useState<boolean>(false);

  const tableRef = useRef<any>(null);
  const tableHeadRef = useRef<any>(null);
  const NAVBAR_HEIGHT = 80;

  const handleScroll = () => {
    const tableHeader = tableRef?.current?.querySelector('thead');
    if (tableRef && tableRef?.current) {
      const rect = tableHeader?.getBoundingClientRect();
      if (rect?.top <= NAVBAR_HEIGHT) {
        tableHeader.style.position = 'sticky';
        tableHeader.style.top = `${NAVBAR_HEIGHT}px`;
        tableHeader.style.zIndex = 101;
      } else {
        tableHeader.style.position = 'static';
        tableHeader.style.top = 'auto';
        tableHeader.style.zIndex = 'auto';
      }
      setIsStickyTable(rect?.top <= NAVBAR_HEIGHT);
    }
  };

  const throttledHandleScroll = _.throttle(handleScroll, 100);

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll, true);
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll, true);
    };
  }, [tableRef.current]);

  const _renderTableHead = () => {
    return (
      <StyledTableRow
        sx={{
          background: '#fff',
          boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
        }}
      >
        <StyledTableCell
          sx={{
            minWidth: 100,
            textAlign: 'center',
          }}
        >
          {t('table.date')}
        </StyledTableCell>
        <StyledTableCell sx={{ minWidth: 120 }}>
          {t('table.projectName')}
        </StyledTableCell>
        <StyledTableCell sx={{ minWidth: 100 }}>
          {t('table.taskName')}
        </StyledTableCell>
        <StyledTableCell sx={{ minWidth: 100 }}>
          {t('table.taskLink')}
        </StyledTableCell>
        <StyledTableCell sx={{ minWidth: 100 }}>
          {t('table.note')}
        </StyledTableCell>
        <StyledTableCell sx={{ minWidth: 50, textAlign: 'center' }}>
          {t('table.action')}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const _renderTableBody = () => {
    const rows: any = [];
    let previousDate: any = null;
    const reportsByDate = _.groupBy(
      _.map(payload, (item: IDailyTaskResponse) => {
        return {
          ...item,
          date: dayjs(item.date).format('DD-MM-YYYY'),
        };
      }),
      'date'
    );

    _.forEach(uniqueDates, (date: any) => {
      const reportsForDate = reportsByDate[date];
      _.forEach(reportsForDate, (report: any, reportIndex: any) => {
        const firstRowOfDate = reportIndex === 0;
        const currentDate = firstRowOfDate ? date : null;
        if (currentDate !== previousDate) {
          previousDate = currentDate;
        } else {
          date = null;
        }
        const totalTasks = report?.dailyTaskProjects?.reduce(
          (accumulator: any, currentItem: any) => {
            return accumulator + currentItem.tasks.length;
          },
          0
        );

        _.forEach(
          report.dailyTaskProjects,
          (dailyTaskProject: any, projectIndex: any) => {
            _.forEach(
              dailyTaskProject.tasks,
              (task: any, taskIndex: number) => {
                const row = (
                  <StyledTableRow
                    key={`${date}-${report.id}-${projectIndex}-${taskIndex}`}
                  >
                    {reportIndex === 0 &&
                    projectIndex === 0 &&
                    taskIndex === 0 ? (
                      <StyledTableCell
                        sx={{
                          textAlign: 'center',
                        }}
                        rowSpan={totalTasks}
                      >
                        {date}
                      </StyledTableCell>
                    ) : null}
                    {firstRowOfDate && taskIndex === 0 ? (
                      <StyledTableCell rowSpan={dailyTaskProject.tasks.length}>
                        {dailyTaskProject.project.name}
                      </StyledTableCell>
                    ) : null}
                    <StyledTableCell>{task.name}</StyledTableCell>
                    <StyledTableCell>
                      <Link href={task.link} target="_blank">
                        {task.link}
                      </Link>
                    </StyledTableCell>
                    {firstRowOfDate && taskIndex === 0 ? (
                      <StyledTableCell rowSpan={dailyTaskProject.tasks.length}>
                        {dailyTaskProject.note || 'N/A'}
                      </StyledTableCell>
                    ) : null}
                    {reportIndex === 0 &&
                    projectIndex === 0 &&
                    taskIndex === 0 ? (
                      <StyledTableCell rowSpan={totalTasks}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Tooltip title={t('tooltip.viewDetails')}>
                            <IconButton
                              onClick={() =>
                                Utils.redirect({
                                  pathname: ROUTERS.DAILY_TASK_DETAILS,
                                  id: report.id,
                                })
                              }
                            >
                              <RemoveRedEyeIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    ) : null}
                  </StyledTableRow>
                );

                rows.push(row);
              }
            );
          }
        );
      });
    });

    return rows;
  };

  const renderMain = () => {
    return (
      <TableContainer
        sx={{
          maxHeight: isStickyTable
            ? `calc(100vh - ${tableHeadRef?.current?.offsetHeight || 70}px)`
            : 'auto',
        }}
      >
        {isFetchLoading ? (
          <TableSkeleton />
        ) : (
          <Table sx={{ minWidth: 1 }} ref={tableRef}>
            <TableHead ref={tableHeadRef}>{_renderTableHead()}</TableHead>
            <TableBody>
              {!_.isEmpty(payload) ? (
                _renderTableBody()
              ) : (
                <TableCell colSpan={6}>{t('table.noDataWereFound')}</TableCell>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    );
  };
  return renderMain();
};

export default MyReportTable;
