import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';

const { FILE_TEMPLATE } = CONSTANTS.API;

export const fetchFileTemplates = async (payload: any) => {
  return sendRequest(FILE_TEMPLATE.BASIC, 'GET', payload);
};

export const getFileTemplateByID = async (id: string) => {
  return sendRequest(`${FILE_TEMPLATE.BASIC}/${id}`, 'GET');
};

export const createFileTemplate = async (payload: any) => {
  return sendRequest(FILE_TEMPLATE.BASIC, 'POST', payload);
};

export const updateFileTemplate = async (payload: any) => {
  return sendRequest(`${FILE_TEMPLATE.BASIC}/${payload.id}`, 'PUT', payload);
};

export const deleteFileTemplate = async (id: string) => {
  return sendRequest(`${FILE_TEMPLATE.BASIC}/${id}`, 'DEL');
};
