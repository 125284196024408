import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
import {
  ForgotPasswordStructure,
  IChangePassword,
  LoginStructure,
} from '@/Interfaces/Auth.interface';

const { AUTH } = CONSTANTS.API;

export const login = async (payload: LoginStructure) => {
  return sendRequest(AUTH.LOGIN, 'POST', payload);
};

export const sendRecoveryLink = async (payload: ForgotPasswordStructure) => {
  return sendRequest(AUTH.FORGOT_PASSWORD, 'POST', payload);
};

export const enterPassword = async (payload: IChangePassword) => {
  return sendRequest(AUTH.ENTER_PASSWORD, 'POST', payload);
};
