export default [
  {
    label: 'Job Knowledge',
    key: 'jobKnowledge',
  },
  {
    label: 'Work quality',
    key: 'workQuality',
  },
  {
    label: 'Productivity',
    key: 'productivity',
  },
  {
    label: 'Technical Skills',
    key: 'technicalSkills',
  },
  {
    label: 'Work Consistency',
    key: 'workConsistency',
  },
  {
    label: 'Enthusiasm',
    key: 'enthusiasm',
  },
  {
    label: 'Cooperation',
    key: 'cooperation',
  },
  {
    label: 'Attitude',
    key: 'attitude',
  },
  {
    label: 'Punctuality',
    key: 'punctuality',
  },
  {
    label: 'Attendance',
    key: 'attendance',
  },
  {
    label: 'Communication Skills',
    key: 'communicationSkills',
  },
];
