import React from 'react';

import { Box, IconButton, Link, Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import { useTypedDispatch } from '../../../store';
import { CommonActions } from '../../../Actions';
import { ModuleKeys } from '../../../Constants/Enums';
import CONSTANTS from '@/Constants';
import Menu from './Menu';
import { Images } from '@/Themes';
// import Utils from '../../../Utils';
const { ROUTERS } = CONSTANTS;

interface IMenu {
  icon: React.JSX.Element;
  title: string;
  to: string;
  key: ModuleKeys;
}

interface SectionProps {
  window?: () => Window;
  items: IMenu[];
}

const drawerWidth = 240;
const expandDrawerWidth = 55;

const { expandSideBar } = CommonActions;

const SideBar: React.FC<SectionProps> = (props: SectionProps) => {
  const { items } = props;
  const dispatch = useTypedDispatch();
  const [isExpand, setIsExpand] = React.useState<boolean>(false);

  const drawer = (
    <Stack direction="column" style={{ height: '100vh' }}>
      <Toolbar
        sx={{
          fontSize: '20px',
          color: 'sidebar.text',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          padding: isExpand ? '0 10px !important' : '0 24px !important',
        }}
      >
        <Link
          href={ROUTERS.HOME}
          sx={{
            height: '34px',
            display: 'flex',
            flexDirection: 'row',
            ':hover': { cursor: 'pointer' },
          }}
        >
          {isExpand ? (
            <img
              src={Images.logoFlow}
              alt="FLOW IDRA logo"
              style={{ width: '100%', height: '100%' }}
            />
          ) : (
            <img
              src={Images.logo}
              alt="FLOW IDRA logo"
              style={{
                visibility: isExpand ? 'hidden' : 'visible',
              }}
            />
          )}
        </Link>
      </Toolbar>
      <List sx={{ height: '100%', overflow: 'auto' }}>
        {Menu({ items, isExpand })}
      </List>
    </Stack>
  );

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        position: 'relative',
        ':hover': {
          ' .expand-btn': {
            opacity: 1,
          },
        },
      }}
    >
      <CssBaseline />
      <Box
        component="nav"
        sx={{
          transition: 'all ease 0.25s',
          width: { sm: isExpand ? expandDrawerWidth : drawerWidth },
          overflow: 'hidden',
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <IconButton
          className="expand-btn"
          sx={{
            transition: 'all ease 0.25s',
            opacity: 0,
            position: 'absolute',
            top: 30,
            right: 0,
            transform: `translate(50%, 0%) rotate(${isExpand ? 180 : 0}deg)`,
            zIndex: 9999,
            background: 'white',
            border: '1px solid #0288d1',
            width: '30px',
            height: '30px',
            ':hover': {
              cursor: 'pointer',
              background: '#0288d1',
              color: 'white',
            },
          }}
          color="info"
          onClick={() => {
            setIsExpand(!isExpand);
            dispatch(expandSideBar(!isExpand));
          }}
        >
          <KeyboardDoubleArrowLeftOutlinedIcon fontSize="small" />
        </IconButton>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            // height: '100vh',
            backgroundColor: 'black',
            '& .MuiDrawer-paper': {
              transition: 'all ease 0.25s',
              boxSizing: 'border-box',
              width: isExpand ? expandDrawerWidth : drawerWidth,
              overflow: 'hidden',
            },
          }}
          PaperProps={{
            sx: {
              backgroundColor: '#f4f5f7',
              color: 'sidebar.text',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default SideBar;
