/* eslint-disable max-len */
/* eslint-disable indent */
import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import dayjs from 'dayjs';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import {
  Stack,
  Box,
  InputLabel,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
  Typography,
  // Divider,
} from '@mui/material';
import { useTranslation } from 'react-multi-lang';
import { LoadingButton } from '@mui/lab';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';

import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import { DatePicker, TextField, Textarea } from '@/Components/Common';
import { DailyTaskActions, ProjectActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import Utils from '@/Utils';
import Constants from '@/Constants';
// import Utils from '@/Utils';
import { IProjectResponseStructure } from '@/Interfaces/Project.interface';

const { ROUTERS } = Constants;
const { resetDailyTaskDetails, getDailyTaskById, updateDailyTask } =
  DailyTaskActions;
const { fetchProjects } = ProjectActions;

interface IOption {
  label: string;
  value: string;
}
interface ITaskName {
  label: string;
  value: string;
}

const KEY_TASK_NAME: ITaskName[] = [
  {
    label: 'taskName',
    value: 'name',
  },
  {
    label: 'linkTask',
    value: 'link',
  },
];

const initialFilter = {
  date: '',
  dataReport: [
    {
      projectId: '',
      tasks: [{ name: '', link: '' }],
      note: '',
      uuId: uuidv4(),
    },
  ],
};
/* eslint-disable react/function-component-definition */
const UpdateDailyTask: React.FC = () => {
  const { id } = useParams();

  const t = useTranslation();
  const dispatch = useTypedDispatch();
  // const { UserId } = Utils.getSavedUserData();

  const projects: IProjectResponseStructure[] = useSelector(
    (state: RootState) => _.get(state.PROJECT, 'payload')
  );

  const pagination = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'myPagination')
  );

  const isGetLoading: boolean = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'isGetLoading')
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'isActionLoading')
  );
  const details: any = useSelector((state: RootState) =>
    _.get(state.DAILY_TASK, 'details')
  );

  const schema = yup
    .object({
      date: yup.string().trim().required(t('message.dateRequired')),
      dataReport: yup.lazy((value) =>
        value.length
          ? yup.array().of(
              yup.object({
                projectId: yup
                  .string()
                  .trim()
                  .required(t('message.projectNameRequired')),
                tasks: yup.lazy((_value) =>
                  _value.length
                    ? yup.array().of(
                        yup.object({
                          name: yup
                            .string()
                            .trim()
                            .required(t('message.nameRequired')),
                          link: yup
                            .string()
                            .trim()
                            .required(t('message.linkRequired'))
                            .matches(
                              /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)|\/|\?)*)?$/i,
                              t('message.linkInvalid')
                            ),
                        })
                      )
                    : yup.array().notRequired()
                ),
              })
            )
          : yup.array().notRequired()
      ),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialFilter,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dataReport',
  });

  const watchDataReport = watch('dataReport', []);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const projectOptions = useMemo(() => {
    const options: IOption[] = [];
    _.forEach(projects, (item: any) =>
      options.push({
        label: item.name,
        value: item.id,
      })
    );
    return options;
  }, [projects]);

  useEffect(() => {
    if (_.isEmpty(projects)) dispatch(fetchProjects());
    if (id) dispatch(getDailyTaskById(id));
    return () => {
      reset();
      dispatch(resetDailyTaskDetails());
    };
  }, []);

  useEffect(() => {
    if (details?.id) {
      const { date, dailyTaskProjects } = details;
      setValue('date', `${date}`);
      const resolveCondition =
        dailyTaskProjects &&
        _.map(dailyTaskProjects, (item: any) => {
          return { ...item, projectId: item.project.id };
        });
      setValue('dataReport', resolveCondition);
    }
  }, [details]);

  const handleAddMore = useCallback(
    (key: number) => {
      const currentValue = getValues(`dataReport.${key}`);
      setValue(`dataReport.${key}`, {
        ...currentValue,
        tasks: [...currentValue.tasks, { name: '', link: '' }],
      });
    },
    [setValue]
  );

  const handleOtherProject = useCallback(
    (event: any) => {
      event.preventDefault();
      append({
        projectId: '',
        tasks: [{ name: '', link: '' }],
        note: '',
        uuId: uuidv4(),
      });
      if (containerRef.current) {
        const scrollPosition = containerRef.current.scrollHeight;
        containerRef.current.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }
    },
    [append, containerRef]
  );

  const handleRemove = (keyDataReport: number, keyTask: number) => {
    const currentValue = getValues(`dataReport.${keyDataReport}`);
    const newForm = _.filter(
      currentValue?.tasks,
      (_item: any, index: any) => index !== keyTask
    );
    setValue(`dataReport.${keyDataReport}`, {
      ...currentValue,
      tasks: newForm,
    });
  };

  const onSubmit = (newData: any) => {
    const dataAfter = newData?.dataReport;
    const changeObject: any = {
      date: dayjs(newData.date).format('YYYY-MM-DD'),
      updatedProject: [],
      removedProject: [],
      newProject: [],
    };

    _.forEach(details.dailyTaskProjects, (itemBefore: any) => {
      const itemAfter = dataAfter.find(
        (item: any) => item?.project?.id === itemBefore?.project?.id
      );

      if (itemAfter) {
        const projectChange: any = {
          projectId: itemBefore.project.id,
          newTasks: [],
          removedTasks: [],
          updatedTasks: [],
          note: itemBefore.note,
        };

        if (itemBefore.note !== itemAfter.note) {
          projectChange.note = itemAfter.note;
        }

        itemBefore.tasks.forEach((taskBefore: any) => {
          const taskAfter = itemAfter.tasks.find(
            (task: any) => task.id === taskBefore.id
          );

          if (!taskAfter) {
            projectChange.removedTasks.push(taskBefore.id);
          } else if (
            taskBefore.name !== taskAfter.name ||
            taskBefore.link !== taskAfter.link
          ) {
            projectChange.updatedTasks.push(
              _.pick(taskAfter, ['id', 'name', 'link'])
            );
          }
        });

        itemAfter.tasks.forEach((taskAfter: any) => {
          const taskBefore = itemBefore.tasks.find(
            (task: any) => task.id === taskAfter.id
          );

          if (!taskBefore) {
            projectChange.newTasks.push(_.pick(taskAfter, ['name', 'link']));
          }
        });

        if (
          projectChange.newTasks.length > 0 ||
          projectChange.removedTasks.length > 0 ||
          projectChange.updatedTasks.length > 0 ||
          projectChange.note
        ) {
          changeObject.updatedProject.push(projectChange);
        }
      } else {
        changeObject.removedProject.push(itemBefore.project.id);
      }
    });

    dataAfter.forEach((itemAfter: any) => {
      const itemBefore = details.dailyTaskProjects.find(
        (item: any) => item?.project?.id === itemAfter.projectId
      );
      if (!itemBefore) {
        const newProject: any = {
          projectId: itemAfter?.projectId,
          tasks: _.map(itemAfter.tasks, (task: any) =>
            _.pick(task, ['name', 'link'])
          ),
        };
        if (itemAfter.note) {
          newProject.note = itemAfter.note;
        }
        changeObject.newProject.push(newProject);
      }
    });
    if (id) dispatch(updateDailyTask(changeObject, id, pagination));
  };

  const _renderForm = () => {
    return (
      <Box component="form">
        <Stack>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <DatePicker
                value={field.value}
                onChange={(date: any) => field.onChange(date)}
                label={t('label.date')}
                placeholder={t('label.date')}
                sx={{ mr: 1, maxWidth: '190px' }}
                message={errors.date?.message}
                required
                disabled={isGetLoading || isActionLoading}
                disableFuture
              />
            )}
          />
        </Stack>
        {_.map(watchDataReport, (res: any, indexKey: number) => {
          return (
            <Accordion defaultExpanded sx={{ p: 2, mt: 2 }} key={res?.uuId}>
              <AccordionSummary>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 1,
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <CircleIcon sx={{ fontSize: 12, mt: '4px', mr: 1 }} />
                    <InputLabel
                      sx={{
                        fontWeight: '500',
                        color: 'black',
                        mb: 0.5,
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('label.project')} {indexKey + 1}
                    </InputLabel>
                  </Box>
                  <IconButton
                    onClick={() => remove(indexKey)}
                    sx={{
                      display: `${fields?.length <= 1 ? 'none' : 'block'}`,
                    }}
                    disabled={isGetLoading || isActionLoading}
                  >
                    <CloseIcon color="error" />
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Stack>
                  <Controller
                    name={`dataReport.${indexKey}.projectId`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        value={field.value}
                        onChange={(e: any) => field.onChange(e)}
                        label=""
                        disabled={isGetLoading || isActionLoading}
                        displayEmpty
                        renderValue={
                          field.value !== ''
                            ? undefined
                            : () => (
                                <Box sx={{ opacity: 0.5 }}>
                                  {t('label.choose')}
                                </Box>
                              )
                        }
                        error={
                          !!errors?.dataReport?.[indexKey]?.projectId?.message
                        }
                      >
                        {!_.isEmpty(projectOptions) ? (
                          _.map(
                            projectOptions,
                            (option: any, keyOption: number) => {
                              return (
                                <MenuItem
                                  key={keyOption}
                                  value={option.value}
                                  disabled={getValues('dataReport').some(
                                    (i) => option.value === i.projectId
                                  )}
                                >
                                  {option.label}
                                </MenuItem>
                              );
                            }
                          )
                        ) : (
                          <MenuItem disabled>{t('label.noOption')}</MenuItem>
                        )}
                      </Select>
                    )}
                  />
                  {errors?.dataReport && (
                    <FormHelperText
                      error={!!errors?.dataReport[indexKey]?.projectId?.message}
                    >
                      {errors?.dataReport[indexKey]?.projectId?.message}
                    </FormHelperText>
                  )}
                </Stack>
                <Stack my={1}>
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <InputLabel
                        sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}
                      >
                        {t('label.task')}
                      </InputLabel>
                    </AccordionSummary>
                    <AccordionDetails>
                      {_.map(res?.tasks, (item: any, index: number) => {
                        const dataIndexReport = errors?.dataReport || [];
                        const dataIndex =
                          dataIndexReport[indexKey]?.tasks || [];
                        return (
                          <Grid container sx={{ display: 'flex' }} key={index}>
                            <Grid item xs={0.2}>
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 700,
                                }}
                              >
                                {index + 1}.
                              </Typography>
                            </Grid>
                            {_.map(
                              KEY_TASK_NAME,
                              (head: any, innerKey: number) => {
                                return (
                                  <Grid
                                    item
                                    xs={5.5}
                                    key={`row${index}-${innerKey}`}
                                    sx={{ display: 'flex' }}
                                  >
                                    <Controller
                                      name={
                                        head.value === 'link'
                                          ? `dataReport.${indexKey}.tasks.${index}.${'link'}`
                                          : `dataReport.${indexKey}.tasks.${index}.${'name'}`
                                      }
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          disabled={
                                            isGetLoading || isActionLoading
                                          }
                                          value={_.get(item, head.value)}
                                          onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                          ) =>
                                            field.onChange(event.target.value)
                                          }
                                          label={t(`label.${head.label}`)}
                                          placeholder={
                                            head.value === 'link'
                                              ? t('label.linkTask')
                                              : t('label.taskName')
                                          }
                                          message={
                                            dataIndex && head.value === 'link'
                                              ? dataIndex[index]?.link?.message
                                              : dataIndex[index]?.name?.message
                                          }
                                          required
                                          style={{ mr: 1 }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                );
                              }
                            )}
                            <Grid
                              item
                              xs={0.5}
                              sx={{
                                display:
                                  res?.tasks?.length <= 1 ? 'none' : 'flex',
                                alignSelf: 'flex-end',
                                alignContent: 'flex-end',
                              }}
                            >
                              <IconButton
                                sx={{
                                  mb:
                                    !!dataIndex[index]?.link?.message ||
                                    !!dataIndex[index]?.name?.message
                                      ? 4
                                      : 1,
                                }}
                                onClick={() => {
                                  handleRemove(indexKey, index);
                                }}
                                disabled={isGetLoading || isActionLoading}
                              >
                                <CloseIcon color="error" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        );
                      })}
                      <Grid item xs={12} textAlign="center">
                        <Button
                          disabled={isGetLoading || isActionLoading}
                          variant="outlined"
                          startIcon={<AddIcon />}
                          onClick={() => handleAddMore(indexKey)}
                        >
                          {t('button.addNewTask')}
                        </Button>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
                <Stack>
                  <Controller
                    name={`dataReport.${indexKey}.note`}
                    control={control}
                    render={({ field }) => (
                      <Textarea
                        disabled={isGetLoading || isActionLoading}
                        value={field.value}
                        label={t('label.note')}
                        placeholder={t('label.note')}
                        sx={{ mb: 1 }}
                        onChange={(e: any) => field.onChange(e.target.value)}
                        maxLength={500}
                        rows={4}
                      />
                    )}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Box
          sx={{
            float: 'right',
            marginTop: 5,
            mb: 2,
            justifyContent: 'space-between',
            display: 'flex',
            width: 1,
          }}
          // direction="row"
        >
          <LoadingButton
            sx={{ mr: 1 }}
            onClick={handleOtherProject}
            variant="outlined"
            startIcon={<AddIcon />}
            loading={isGetLoading || isActionLoading}
          >
            {t('button.otherProject')}
          </LoadingButton>
          <Box>
            <LoadingButton
              sx={{ mr: 1 }}
              onClick={() =>
                Utils.redirect({
                  pathname: ROUTERS.DAILY_TASK,
                  search: {
                    screen: 'me',
                  },
                })
              }
              variant="outlined"
              loading={isGetLoading || isActionLoading}
            >
              {t('button.cancel')}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              loading={isGetLoading || isActionLoading}
            >
              {t('button.save')}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
        ref={containerRef}
      >
        <Typography variant="h2">{t('title.updateDailyTask')}</Typography>
        {_renderForm()}
      </Box>
    );
  };

  return renderMain();
};

export default UpdateDailyTask;
