import { SxProps, Theme } from '@mui/system';
import { CommonColors } from '@/Themes';
import { deepPurple } from '@mui/material/colors';

export const iconButtonStyles: SxProps<Theme> = {
  color: CommonColors.denim,
  ':hover': {
    cursor: 'pointer',
    color: CommonColors.blueRibbon,
  },
};

export const buttonCloseStyles: SxProps<Theme> = {
  mx: 1,
  fontSize: 30,
  color: CommonColors.RredBerry,
  '&:hover': {
    cursor: 'pointer',
    color: 'red',
  },
};

export const titleStyles: SxProps<Theme> = {
  color: CommonColors.shuttleGray,
  fontSize: 16,
};

export const buttonSaveStyles: SxProps<Theme> = {
  fontSize: 30,
  color: CommonColors.denim,
  '&:hover': {
    cursor: 'pointer',
    color: CommonColors.blue,
  },
};

export const avatarStyles: SxProps<Theme> = {
  bgcolor: deepPurple[200],
  fontSize: 8,
  textAlign: 'center',
};

export const AdjustIconStyles: SxProps<Theme> = {
  fontSize: 16,
  mt: '4px',
  mr: 1,
};

export const messageIconStyles: SxProps<Theme> = {
  fontSize: 20,
  mt: 3,
  mr: 1,
  color: CommonColors.shuttleGray,
};

export const detailStyles: SxProps<Theme> = {
  color: CommonColors.shark,
  fontSize: 15,
  mb: 1,
  '& *': {
    py: 0,
    my: 0,
    textTransform: 'capitalize',
  },
};

export const fullNameStyles: SxProps<Theme> = {
  color: '#000000de',
  fontSize: 16,
  fontWeight: 500,
};

export const messageStyles: SxProps<Theme> = {
  color: CommonColors.shark,
  fontSize: 15,
  py: 1,
  px: 2,
  '& *': {
    color: CommonColors.shuttleGray,
    fontSize: 14,
  },
};

export const statusStyles: SxProps<Theme> = {
  border: '1px',
  borderRadius: 20,
  textAlign: 'center',
  background: CommonColors.jewel,
  color: '#ffff',
  mt: 0.4,
};

const avartarFeedbackListStyle: SxProps<Theme> = {
  bgcolor: deepPurple[200],
  mr: 1,
  ml: 1,
  width: 25,
  height: 25,
};

export default {
  iconButtonStyles,
  buttonCloseStyles,
  titleStyles,
  buttonSaveStyles,
  avatarStyles,
  AdjustIconStyles,
  messageIconStyles,
  detailStyles,
  fullNameStyles,
  messageStyles,
  statusStyles,
  avartarFeedbackListStyle,
};
