import React, { useEffect } from 'react';
import _ from 'lodash';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton, Tooltip, Box } from '@mui/material';

import { useTranslation } from 'react-multi-lang';
import { ChipStatus, StickyHeadTable } from '@/Components/Common';
import Utils from '@/Utils';
import { IMetaStructure } from '@/Interfaces/Common.interface';

interface ISectionProps {
  meta: IMetaStructure;
  payload: any;
  onRowAction(project: { id: string }, action: 'edit' | 'remove'): void;
  onPageAction(value: number, type: 'limit' | 'page'): void;
  isLoading?: boolean;
}

interface IDataTableStructure {
  no: string;
  name: JSX.Element;
  type: string;
  link: string;
  description: JSX.Element;
  active: JSX.Element;
  action: JSX.Element;
}

interface IColumnStructure {
  id: 'no' | 'name' | 'type' | 'link' | 'description' | 'active' | 'action';

  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'name', label: 'projectName', width: 200 },
  { id: 'type', label: 'type', minWidth: 50 },
  { id: 'link', label: 'link', minWidth: 150 },
  { id: 'description', label: 'description', minWidth: 200 },
  { id: 'active', label: 'status', minWidth: 50, align: 'center' },
  { id: 'action', label: 'action', minWidth: 100, align: 'center' },
];

const createDataTable = (
  no: string,
  name: JSX.Element,
  type: string,
  link: string,
  description: JSX.Element,
  active: JSX.Element,
  action: JSX.Element
): IDataTableStructure => {
  return {
    no,
    name,
    type,
    link,
    description,
    active,
    action,
  };
};

const locale = Utils.getSavedLocale();

const CrawlingProjectDataTable: React.FC<ISectionProps> = ({
  meta,
  payload,
  onRowAction,
  onPageAction,
  isLoading = false,
}) => {
  const t = useTranslation();
  const [rows, setRows] = React.useState<IDataTableStructure[]>([]);

  useEffect(() => {
    const resolveRows: IDataTableStructure[] = [];
    if (!_.isEmpty(payload)) {
      _.forEach(
        payload,
        (
          item: {
            id: string;
            name: string;
            type: string;
            link: string;
            description: string;
            active: boolean;
          },
          index: number
        ) => {
          const { name, type, link, description, active } = item;
          resolveRows.push(
            createDataTable(
              `${index + 1}`,
              <Box
                style={{
                  wordWrap: 'break-word',
                  whiteSpace: 'break-spaces',
                }}
              >
                {name}
              </Box>,
              // fixed type
              locale === 'vi' && type === 'Other' ? 'Khác' : type,
              link,
              <Box
                style={{
                  maxWidth: 200,
                  wordWrap: 'break-word',
                  whiteSpace: 'break-spaces',
                }}
              >
                {description}
              </Box>,
              <ChipStatus label={active ? 'active' : 'inactive'} />,
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                }}
              >
                <Tooltip title={t('tooltip.clickToEditProject')}>
                  <span>
                    <IconButton onClick={() => onRowAction(item, 'edit')}>
                      <EditOutlinedIcon fontSize="medium" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={t('tooltip.clickToDeleteProject')}>
                  <IconButton onClick={() => onRowAction(item, 'remove')}>
                    <DeleteOutlineOutlinedIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Box>
            )
          );
        }
      );
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      isLoading={isLoading}
      paginate={meta}
      onAction={onPageAction}
    />
  );
};

export default CrawlingProjectDataTable;
