import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import dayjs from 'dayjs';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import AdjustIcon from '@mui/icons-material/Adjust';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { CircularPercent, RoundedContainer } from '@/Components/Common';
import { Alert } from '@/Widgets';
import {
  DailyReportActions,
  EventActions,
  FeedbacksActions,
  ProjectActions,
  UserActions,
} from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import Utils from '@/Utils';
import Constants from '@/Constants';

import { ProjectStatus } from '@/Constants/Enums';

import { DashboardProjectDataTable } from '@/Components/LayoutPart/DataTable';
import { IProjectResponseStructure } from '@/Interfaces/Project.interface';
import DailyReportPopup from '@/Components/Popup/DailyReportPopup';
import { IEvent } from '@/Interfaces/Event.interface';
import {
  AnnouncementSkeleton,
  SelfNotificationSkeleton,
  UpcomingEventSkeleton,
} from '../../../Components/Common/Skeleton';
import { IUserDetailsStructure } from '../../../Interfaces/User.interface';
import Announcement from './Announcement';
import {
  IDataFeedbacks,
  IDataFeedbacksDashboard,
} from '@/Interfaces/Feedback.interface';
const { ROUTERS, MODULE_API, ENUMS } = Constants;
const { fetchProjects } = ProjectActions;
const { fetchNextEvents, setDefaultEventReducer } = EventActions;
const { resetDailyReportStatus, setDefaultReducerDailyReport } =
  DailyReportActions;
const { getUserById, clearUserPayload } = UserActions;
const { fetchFeedbacksDashboard, clearFeedback } = FeedbacksActions;

const Dashboard: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const locale = Utils.getSavedLocale();
  const [searchParams, setSearchParams] = useSearchParams();

  const projects: IProjectResponseStructure[] = useSelector(
    (state: RootState) => get(state.PROJECT, 'payload')
  );
  const projectIsLoading: boolean = useSelector((state: RootState) =>
    get(state.PROJECT, 'requestIsLoading')
  );
  const nextEvents: IEvent[] = useSelector((state: RootState) =>
    get(state.EVENT, 'nextEvents')
  );
  const eventIsLoading: boolean = useSelector((state: RootState) =>
    get(state.EVENT, 'requestIsLoading')
  );
  const userDetails: IUserDetailsStructure = useSelector((state: RootState) =>
    get(state.USER, 'details')
  );
  const userIsLoading: boolean = useSelector((state: RootState) =>
    get(state.USER, 'requestIsLoading')
  );
  const feedbacks: IDataFeedbacksDashboard = useSelector((state: RootState) =>
    get(state.FEEDBACK, 'forDashboard')
  );
  const feedbacksIsLoading: boolean = useSelector((state: RootState) =>
    get(state.FEEDBACK, 'requestIsLoading')
  );
  const isAcceptFetchProject = Utils.isValidPermission(
    MODULE_API.PROJECT.FETCH_PROJECTS
  );
  const isAcceptFetchFeedbacks = Utils.isValidPermission(
    MODULE_API.FEEDBACKS.GET_FOR_DASHBOARD
  );
  const isAcceptFetchAnnouncement = Utils.isValidPermission(
    MODULE_API.ANNOUNCEMENT.GET_FOR_USER
  );
  const userData = Utils.getSavedUserData();
  const checkRoles = _.get(userData, 'role.roleCode') === ENUMS.Role.ADMIN;

  // const [selectedProject, setSelectedProject] =
  //   React.useState<IProjectResponseStructure | null>(null);

  React.useEffect(() => {
    if (userData?.id) dispatch(getUserById(userData.id));
    if (isAcceptFetchFeedbacks) dispatch(fetchFeedbacksDashboard());
    if (isAcceptFetchProject)
      dispatch(
        fetchProjects({ status: ProjectStatus.IN_PROGRESS, startDate: null })
      );
    dispatch(fetchNextEvents({ day: 7 }));
    dispatch(resetDailyReportStatus());

    return () => {
      dispatch(setDefaultEventReducer());
      dispatch(setDefaultReducerDailyReport());
      dispatch(clearUserPayload());
      dispatch(clearFeedback());
    };
  }, []);

  const onRowAction = async (
    project: IProjectResponseStructure,
    action: 'report'
  ) => {
    if (!project)
      return Alert({
        type: 'ERROR',
        message: t('popup.warningDetectProject'),
      });
    if (action === 'report') setSearchParams({ project: project.id });
    return null;
  };

  const _renderPopup = () => {
    return (
      <DailyReportPopup
        onClose={() => {
          if (searchParams.has('project')) {
            searchParams.delete('project');
            setSearchParams(searchParams);
          }
          dispatch(resetDailyReportStatus());
          dispatch(
            fetchProjects({
              status: ProjectStatus.IN_PROGRESS,
              startDate: null,
            })
          );
        }}
      />
    );
  };

  const _renderSelfNotification = () => {
    const fillResult = Utils.generateUnFillPersonalInformation(userDetails);

    if (fillResult === 100) return null;
    return (
      <RoundedContainer>
        <Typography variant="h3">{t('title.selfNotification')}</Typography>
        <Stack direction="row" alignItems="center">
          {userIsLoading ? (
            <SelfNotificationSkeleton />
          ) : (
            <Stack direction="row" alignItems="center">
              <CircularPercent progress={fillResult} />
              <Typography ml={1}>
                {t('tooltip.yourInformationIsMissingLet')}
                <Link
                  sx={{ ml: 0.5, ':hover': { cursor: 'pointer' } }}
                  href={ROUTERS.PROFILE}
                >
                  {t('tooltip.fill')}
                </Link>
                !
              </Typography>
            </Stack>
          )}
        </Stack>
      </RoundedContainer>
    );
  };

  const _renderEvents = () => {
    if (nextEvents.length === 0)
      return <Typography>{t('tooltip.noEventWereFound')}</Typography>;

    // const resolveLocale = locale === 'en' ? en : vi;
    const dateFormat = 'MMM DD YYYY';

    return (
      <Stack direction="column">
        {nextEvents.map((event: IEvent, index: number) => {
          const { startDate, endDate } = event;
          const isDiffDate = event.startDate !== event.endDate;

          const resolveStartDate = dayjs(startDate);
          const resolveEndDate = dayjs(endDate);

          return (
            <Stack
              direction="column"
              key={`event-${event.id}`}
              sx={{ mt: index > 0 ? 2 : 0 }}
            >
              <Stack direction="row" alignItems="center">
                <Box
                  sx={{
                    width: '8px',
                    height: '8px',
                    background: event.color,
                    borderRadius: '50%',
                    marginRight: '8px',
                  }}
                />
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                  {locale === 'en' ? event.nameEN : event.nameVI}
                </Typography>
              </Stack>
              <Typography sx={{ fontSize: '14px', marginLeft: '17px' }}>
                {isDiffDate
                  ? resolveStartDate.format('MMM DD')
                  : resolveStartDate.format(dateFormat)}
                {isDiffDate ? ` - ${resolveEndDate.format(dateFormat)}` : null}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const _renderNextEvent = () => {
    const isShowViewMore = nextEvents.length >= 3;
    return (
      <RoundedContainer mt={2}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h3">{t('title.nextEvents')}</Typography>
          {isShowViewMore ? (
            <Link
              sx={{
                ml: 1,
                mb: 1,
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              href={ROUTERS.EVENT}
            >
              {t('button.viewMore')}
            </Link>
          ) : null}
        </Stack>
        {eventIsLoading ? <UpcomingEventSkeleton /> : _renderEvents()}
      </RoundedContainer>
    );
  };
  const _renderTopFeedbacks = () => {
    return (
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Typography variant="h3">{t('title.feedback')}</Typography>
          <Box
            sx={{
              display: 'flex',
              mx: 2,
              mt: 0.7,
              ':hover': {
                cursor: 'pointer',
                color: '#008b00',
              },
            }}
            onClick={() => Utils.redirect(ROUTERS.FEEDBACK, { status: 'open' })}
          >
            <AdjustIcon
              sx={{ color: '#1a7f37', fontSize: 16, mt: '3px', mr: 0.5 }}
            />
            <Typography>
              {t('label.open')}: {feedbacks?.statistical?.open}
            </Typography>
          </Box>
          <Box
            onClick={() =>
              Utils.redirect(ROUTERS.FEEDBACK, { status: 'close' })
            }
            sx={{
              display: 'flex',
              mt: 0.7,
              ':hover': {
                cursor: 'pointer',
                color: '#e74c3c',
              },
            }}
          >
            <CloseIcon
              color="error"
              sx={{ fontSize: 16, mt: '3px', mr: 0.5 }}
            />
            <Typography>
              {t('label.close')}: {feedbacks?.statistical?.close}
            </Typography>
          </Box>
        </Box>
        <Link
          sx={{
            ml: 1,
            mb: 1,
            ':hover': {
              cursor: 'pointer',
              color: '#0288d1',
            },
          }}
          href={ROUTERS.FEEDBACK}
        >
          {t('button.viewMore')}
        </Link>
      </Box>
    );
  };

  const _renderFeedback = () => {
    return (
      <Stack sx={{ mt: 2 }}>
        {!feedbacksIsLoading ? (
          <Box>
            {!_.isEmpty(feedbacks) && (
              <RoundedContainer mb={1}>
                {_renderTopFeedbacks()}
                {!_.isEmpty(feedbacks?.feedbacks) ? (
                  <Box>
                    {_.map(
                      feedbacks?.feedbacks,
                      (item: IDataFeedbacks, index: number) => {
                        const { id } = item;
                        const updateAt = dayjs(item.updatedAt).format(
                          'HH:mm, DD-MM-YYYY'
                        );
                        return (
                          <Box
                            key={index}
                            sx={{
                              border: '1px solid #d0d7de',
                              borderRadius: 6,
                              p: 2,
                              mb: 1,
                              cursor: 'pointer',
                              transition:
                                '.4s transform linear, .2s box-shadow linear',
                              ':hover': {
                                boxShadow: '0px 8px 14px -4px rgba(0,0,0,0.2)',
                                transform: 'translate(0px, -3px)',
                              },
                            }}
                            onClick={() =>
                              Utils.redirect({
                                pathname: ROUTERS.REPLY_FEEDBACK,
                                id,
                              })
                            }
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: 1,
                                maxWidth: 500,
                                fontSize: 16,
                              }}
                            >
                              {item?.title}
                            </Typography>
                            <Box mt={1}>
                              <Typography
                                sx={{ color: '#57606a', fontSize: 12 }}
                              >
                                {t('label.opened')}{' '}
                                {item.updatedAt ? updateAt : 'N/I'}
                              </Typography>
                            </Box>
                            <Box mt={1} display="flex">
                              {item.status === 'open' ? (
                                <Box title={item.status} display="flex">
                                  <AdjustIcon
                                    sx={{
                                      color: '#1a7f37',
                                      fontSize: 16,
                                      mt: '4px',
                                      mr: 1,
                                    }}
                                  />
                                  <Typography>{t('label.open')}</Typography>
                                </Box>
                              ) : (
                                <Box title={item.status} display="flex">
                                  <AdjustIcon
                                    sx={{
                                      color: 'red',
                                      fontSize: 16,
                                      mt: '4px',
                                      mr: 1,
                                    }}
                                  />
                                  <Typography>{t('label.close')}</Typography>
                                </Box>
                              )}
                              <Box ml={4} display="flex">
                                <MessageIcon
                                  sx={{
                                    fontSize: 20,
                                    mt: '3px',
                                    mr: 1,
                                    color: '#57606a',
                                  }}
                                />
                                <Typography
                                  sx={{ color: '#57606a', fontSize: 16 }}
                                >
                                  {item?.threads?.length}
                                </Typography>
                              </Box>
                              <Box sx={{ ml: 3 }}>
                                {checkRoles ? (
                                  <Box display="flex">
                                    <Typography
                                      sx={{ color: '#57606a', fontSize: 16 }}
                                    >
                                      {t('label.sender')}:{' '}
                                      {item?.createdBy?.userData?.fullName}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        ml: 2,
                                      }}
                                    >
                                      {item.assignee ? (
                                        <Typography
                                          sx={{
                                            color: '#57606a',
                                            fontSize: 16,
                                          }}
                                        >
                                          {t('label.responder')}:{' '}
                                          {item?.assignee?.userData?.fullName}
                                        </Typography>
                                      ) : (
                                        <Typography>
                                          {t('label.responder')}: Unassigned
                                        </Typography>
                                      )}
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box>
                                    {item.assignee ? (
                                      <Typography
                                        sx={{ color: '#57606a', fontSize: 16 }}
                                      >
                                        {t('label.responder')}:{' '}
                                        {item?.assignee?.userData?.fullName}
                                      </Typography>
                                    ) : (
                                      <Typography>
                                        {t('label.responder')}: Unassigned
                                      </Typography>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                ) : (
                  <Typography>
                    {t('tooltip.noDocumentationFoundForThisFeedback')}
                  </Typography>
                )}
              </RoundedContainer>
            )}
          </Box>
        ) : (
          <AnnouncementSkeleton />
        )}
      </Stack>
    );
  };

  const _renderInProgressProject = () => (
    <RoundedContainer sx={{ mt: 2 }}>
      <Typography variant="h3">{t('title.inProgressProject')}</Typography>
      <DashboardProjectDataTable
        payload={projects}
        onRowAction={onRowAction}
        isLoading={projectIsLoading}
      />
    </RoundedContainer>
  );

  return (
    <>
      {_renderPopup()}
      {_renderSelfNotification()}
      {_renderNextEvent()}
      <Grid container spacing={2}>
        {!checkRoles && isAcceptFetchAnnouncement ? (
          <Grid item xs={6}>
            <Announcement />
          </Grid>
        ) : null}
        {isAcceptFetchFeedbacks && (
          <Grid
            item
            xs={
              _.get(userData, 'role.roleCode') === ENUMS.Role.ADMIN ||
              !isAcceptFetchAnnouncement
                ? 12
                : 6
            }
          >
            {_renderFeedback()}
          </Grid>
        )}
      </Grid>
      {isAcceptFetchProject ? _renderInProgressProject() : null}
    </>
  );
};

export default Dashboard;
