import Button from '@mui/material/Button';
import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SelectChangeEvent,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from 'react';
interface IProps {
  labelSx?: object;
  label?: string;
  options: {
    label?: string;
    value?: string | number;
  }[];
  check?: boolean;
  defaultValue?: { label: string; value: string };
  onChange?(e: any): any;
}

const GroupButton = (props: IProps) => {
  const { label, options, defaultValue, onChange } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const onChangeOption = (e: SelectChangeEvent) => {
    if (onChange) onChange(e);
    setOpen(false);
  };

  return (
    <Box display={'flex'}>
      {label ? (
        <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
          {label}
        </InputLabel>
      ) : null}
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{
          '&.MuiButtonGroup-root': {
            boxShadow: 'none',
          },
        }}
      >
        <Button
          className={defaultValue?.value === 'open' ? 'titleStatus' : ''}
          variant="text"
          sx={{
            width: 120,
            color: '#ffff',
            background: '#B22222',
            '&:hover': {
              background: 'red',
            },
            '&.titleStatus': {
              color: '#ffff',
              background: '#1a7f37',
              '&:hover': {
                background: '#008B00',
              },
            },
          }}
          endIcon={<ArrowDropDownIcon />}
          onClick={handleToggle}
        >
          {defaultValue?.label}
        </Button>
        <Popper
          sx={{
            zIndex: 9999,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem>
                    {options.map((option: any, index) => {
                      return (
                        <MenuItem
                          className={option.value === 'open' ? 'status' : ''}
                          key={index}
                          sx={{
                            fontWeight: 550,
                            width: 120,
                            justifyContent: 'center',
                            textTransform: 'capitalize',
                            color: '#B22222',
                            position: 'relative',
                            '&.status': {
                              color: '#1a7f37',
                            },
                          }}
                          value={option.value}
                          onClick={() => onChangeOption(option.value)}
                        >
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ButtonGroup>
    </Box>
  );
};

export default GroupButton;
