import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import { useSearchParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import CONSTANTS from '@/Constants';
import { DailyReportActions } from '@/Actions';
import { Alert } from '@/Widgets';

import { RoundedContainer } from '@/Components/Common';

import ModuleApiConstant from '@/Constants/Constants/ModuleApi.constant';
import { DailyReportDataTable } from '@/Components/LayoutPart/DataTable';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import { IDailyReport } from '@/Interfaces/DailyReport.interface';
import DailyReportPopup from '@/Components/Popup/DailyReportPopup';

interface IFilter {
  page: number;
  limit: number;
}

const initialFilter: IFilter = {
  page: 1,
  limit: 10,
};

const { ROUTERS } = CONSTANTS;

const { deleteDailyReport, fetchDailyReports, resetDailyReportStatus } =
  DailyReportActions;

const EditProject: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const payload: IDailyReport[] = useSelector((state: RootState) =>
    get(state.DAILY_REPORT, 'payload')
  );
  const meta: IMetaStructure = useSelector((state: RootState) =>
    get(state.DAILY_REPORT, 'meta')
  );
  // const project: IProjectResponseStructure = useTypedSelector(
  //   (state: RootState) => get(state.PROJECT, 'details')
  // );

  // const isAcceptCreate = Utils.isValidPermission(
  //   MODULE_API.DAILY_REPORT.CREATE
  // );

  const [filter, setFilter] = React.useState<IFilter>(initialFilter);

  const fetchPayload = (filterParams: any) => {
    if (id && uuidValidate(id)) {
      const resolveFilter = Utils.validateFilters(filterParams);
      dispatch(fetchDailyReports(id, resolveFilter));
    }
  };

  React.useEffect(() => {
    const isAcceptCreateApi = Utils.isValidPermission(
      ModuleApiConstant.DAILY_REPORT.CREATE
    );
    if (!isAcceptCreateApi) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForAddReport'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else if (id && uuidValidate(id)) fetchPayload(filter);
  }, []);

  React.useEffect(() => {
    dispatch(resetDailyReportStatus());
  }, [payload]);

  const onRowAction = async (
    dailyReport: IDailyReport,
    action: 'edit' | 'delete'
  ) => {
    if (!dailyReport)
      return Alert({
        type: 'ERROR',
        message: t('popup.notFoundInformationReport'),
      });

    if (action === 'edit' && id) {
      const { date } = dailyReport;
      const currentDate = dayjs(date).format('YYYY-MM-DD');
      setSearchParams({ project: id, date: currentDate });
    }

    if (action === 'delete') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningDeleteReport'),
      });
      if (isAgree === 'ok') {
        const resolveFilter = Utils.validateFilters(filter);
        if (id && uuidValidate(id))
          dispatch(deleteDailyReport(dailyReport.id, id, resolveFilter));
      }
    }
    return null;
  };

  const onPageAction = async (value: number, type: 'limit' | 'page') => {
    const newFilter = { ...filter };
    if (type === 'limit') Object.assign(newFilter, { page: 1, limit: value });
    else Object.assign(newFilter, { page: value });
    setFilter(newFilter);
    fetchPayload(newFilter);
  };

  // const onReport = () => setSelectedProject(project);

  const _renderPopup = () => {
    return (
      <DailyReportPopup
        onClose={() => {
          if (searchParams.has('project')) {
            searchParams.delete('project');
            setSearchParams(searchParams);
          }
          dispatch(resetDailyReportStatus());
          fetchPayload(filter);
        }}
      />
    );
  };

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        <DailyReportDataTable
          meta={meta}
          onPageAction={onPageAction}
          onRowAction={onRowAction}
          payload={payload}
        />
      </RoundedContainer>
    );
  };

  return (
    <>
      {_renderPopup()}
      {_renderBottomSection()}
    </>
  );
};

export default EditProject;
