import React from 'react';
import {
  CardMedia,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { useTranslation } from 'react-multi-lang';

import { ITool } from '@/Interfaces/Tool.interface';
import { CommonColors } from '@/Themes';

interface IProps {
  open: any;
  onClose(): void;
  data?: ITool;
}

const DialogTools: React.FC<IProps> = ({ open, onClose, data }) => {
  const t = useTranslation();
  const formatDate = (value: any, typeFormat?: string) => {
    if (value) {
      const getValue =
        typeof value === 'string' ? value.replaceAll(',', '') : value;
      return dayjs(getValue).format(typeFormat || 'HH:mm, DD-MM-YYYY');
    }
    return 'No Date';
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      PaperProps={{
        style: {
          minWidth: '80%',
          padding: '10px',
        },
      }}
    >
      <Stack direction="row">
        <Typography
          variant="h2"
          sx={{
            fontWeight: 500,
            flex: 1,
            textAlign: 'center',
            mt: 1,
          }}
        >
          {data?.title}
        </Typography>
        <Tooltip title={t('tooltip.close')}>
          <IconButton onClick={() => onClose()}>
            <CloseIcon
              sx={{
                cursor: 'pointer',
                border: 1,
                borderRadius: '50%',
              }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider variant="inset" sx={{ width: '90%' }} />
      <DialogContent>
        <Stack direction="row" justifyContent="center">
          <CardMedia
            sx={{
              aspectRatio: '16/9',
              objectFit: 'cover',
              width: 0.6,
            }}
            image={data?.thumbnail?.path}
            title="green iguana"
          />
        </Stack>
        <Stack direction="row" justifyContent="end" sx={{ my: 1 }}>
          <Typography sx={{ color: CommonColors.grey }}>
            {data?.createdAt && formatDate(data?.createdAt)}
          </Typography>
        </Stack>
        <Link
          sx={{
            wordBreak: 'break-all',
            color: CommonColors.blueRibbon,
            ':hover': {
              cursor: 'pointer',
            },
          }}
          href={data?.url}
          target="_blank"
        >
          {data?.url}
        </Link>
        <Typography
          variant="body2"
          component="p"
          sx={{
            fontSize: '14px',
            marginTop: '5px',
          }}
        >
          {data?.description}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default DialogTools;
