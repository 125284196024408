import { Grid, Skeleton, Stack } from '@mui/material';

const ChildFolderSkeleton = () => {
  const _renderItem = () => (
    <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
      <Skeleton height={60} />
    </Grid>
  );

  return (
    <Stack direction="column">
      <Skeleton width={100} height={35} />
      <Grid container spacing={2}>
        {_renderItem()}
        {_renderItem()}
        {_renderItem()}
        {_renderItem()}
      </Grid>
    </Stack>
  );
};

export default ChildFolderSkeleton;
