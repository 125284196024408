import React from 'react';
import map from 'lodash/map';
import { useTranslation } from 'react-multi-lang';

import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { FoldersAction } from '@/Actions';
import { Alert } from '@/Widgets';
import { useTypedDispatch } from '@/store';
import FolderDetails from '@/Components/Popup/FolderDetails';
import Utils from '../../../Utils';
import Constants from '../../../Constants';

interface IFolder {
  id: string;
  createdAt: Date;
  deleteAt: Date;
  name: string;
  updatedAt: Date;
  parentId: string;
}

interface IProps {
  payload: IFolder[];
  onSelect(id: string): void;
  onCreate(): void;
}

const { deleteFolder } = FoldersAction;
const { MODULE_API } = Constants;

const ChildFolder: React.FC<IProps> = ({
  payload = [],
  onSelect,
  onCreate,
}) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);
  const [selectedFolder, setSelectedFolder] = React.useState<IFolder | null>(
    null
  );

  const [isShowFolderPopup, setIsShowFolderPopup] =
    React.useState<boolean>(false);

  const isAcceptCreate = Utils.isValidPermission(
    MODULE_API.FOLDER.CREATE_FOLDER
  );
  const isAcceptEdit = Utils.isValidPermission(MODULE_API.FOLDER.UPDATE_FOLDER);
  const isAcceptRemove = Utils.isValidPermission(
    MODULE_API.FOLDER.DELETE_FOLDER
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover123' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedFolder(null);
  };

  const onAction = async (action: 'edit' | 'delete') => {
    if (action === 'edit') setIsShowFolderPopup(true);

    if (action === 'delete') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningDeleteFolder'),
      });
      if (isAgree === 'ok' && selectedFolder) {
        setAnchorEl(null);
        setSelectedFolder(null);
        dispatch(deleteFolder(selectedFolder.id, selectedFolder.parentId));
      }
    }
  };

  const _renderPopover = () => {
    return (
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          {isAcceptEdit ? (
            <ListItem disablePadding>
              <ListItemButton
                sx={{ py: '5px' }}
                onClick={() => onAction('edit')}
              >
                <ListItemIcon sx={{ minWidth: '32px' }}>
                  <EditOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={t('button.edit')}
                  sx={{ span: { fontSize: '14px' } }}
                />
              </ListItemButton>
            </ListItem>
          ) : null}
          {isAcceptRemove ? (
            <ListItem disablePadding>
              <ListItemButton
                sx={{ py: '5px' }}
                onClick={() => onAction('delete')}
              >
                <ListItemIcon sx={{ minWidth: '32px' }}>
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={t('button.delete')}
                  sx={{ span: { fontSize: '14px' } }}
                />
              </ListItemButton>
            </ListItem>
          ) : null}
        </List>
      </Popover>
    );
  };

  const _renderFolders = () =>
    map(payload, (folder: IFolder) => (
      <Grid item key={`folder-${folder.id}`} xs={6} sm={4} md={4} lg={4} xl={4}>
        <Stack
          key={`folder-${folder.id}`}
          sx={{
            border: '1px solid #ccc',
            pl: 2,
            height: '38px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: `${isAcceptEdit ? 'space-between' : 'none'}`,
            borderRadius: '5px',
            ':hover': {
              cursor: 'pointer',
              background: 'rgba(9,30,66,.08)',
            },
          }}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            onSelect(folder.id);
          }}
        >
          <FolderOutlinedIcon sx={{ fontSize: '20px', marginRight: '5px' }} />
          <Typography
            sx={{
              fontSize: '15px',
              userSelect: 'none',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 1,
            }}
          >
            {folder.name}
          </Typography>
          {isAcceptEdit || isAcceptRemove ? (
            <IconButton
              onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
                setSelectedFolder(folder);
              }}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          ) : null}
        </Stack>
      </Grid>
    ));

  const _renderCreateFolderBtn = () => (
    <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
      <Stack
        sx={{
          width: '100%',
          border: '1px solid #ccc',
          px: 2,
          height: '38px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '5px',
          ':hover': {
            cursor: 'pointer',
            background: 'rgba(9,30,66,.08)',
          },
        }}
        onClick={onCreate}
      >
        <Typography sx={{ fontSize: '15px' }}>
          + {t('button.createFolder')}
        </Typography>
      </Stack>
    </Grid>
  );

  const _renderPopup = () => {
    return (
      <FolderDetails
        open={isShowFolderPopup}
        payload={selectedFolder}
        onClose={() => {
          setIsShowFolderPopup(false);
          setSelectedFolder(null);
          handleClose();
        }}
        parent={null}
      />
    );
  };

  return (
    <Stack direction="column" mt={2}>
      {_renderPopover()}
      {_renderPopup()}
      <Typography sx={{ fontSize: '15px', fontWeight: '500', mb: 1 }}>
        {t('title.folders')}
      </Typography>
      <Grid container spacing={2}>
        {_renderFolders()}
        {isAcceptCreate ? _renderCreateFolderBtn() : null}
      </Grid>
    </Stack>
  );
};

export default ChildFolder;
