import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  deleteIsSuccess: false,
  payload: [],
  details: {},
};

const WikiReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.RESET_WIKI_STATUS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        deleteIsSuccess: false,
      };
    case ACTION_TYPES.SET_DEFAULT_REDUCER_WIKI:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        payload: [],
      };
    case ACTION_TYPES.WIKI_IS_LOADING:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.FETCH_WIKI_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };
    case ACTION_TYPES.FETCH_WIKI_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload,
      };

    case ACTION_TYPES.CREATE_WIKI_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.CREATE_WIKI_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };

    case ACTION_TYPES.UPDATE_WIKI_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.UPDATE_WIKI_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };

    case ACTION_TYPES.GET_WIKI_BY_ID_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        details: {},
      };
    case ACTION_TYPES.GET_WIKI_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };

    case ACTION_TYPES.UPDATE_ATTACHMENT_WIKI_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        details: {},
      };
    case ACTION_TYPES.UPDATE_ATTACHMENT_WIKI_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };

    case ACTION_TYPES.DELETE_WIKI_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        deleteIsSuccess: false,
      };
    case ACTION_TYPES.DELETE_WIKI_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        deleteIsSuccess: true,
      };

    default:
      return state;
  }
};

export default WikiReducer;
