import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

// SINGLE ACTIONS
const contentIsLoaded = () => {
  return {
    type: ACTION_TYPES.CONTENT_IS_LOADED,
  };
};

const userContentIsLoaded = () => {
  return {
    type: ACTION_TYPES.CONTENT_USER_IS_LOADED,
  };
};

const expandSideBar = (payload: boolean) => {
  return {
    type: ACTION_TYPES.EXPAND_SIDEBAR,
    payload,
  };
};

export default {
  contentIsLoaded,
  expandSideBar,
  userContentIsLoaded,
};
