import { MainContainer } from '@containers';
import CONSTANTS from '@/Constants';
import { PortalDefaultLayout } from '@/Components/DefaultLayout';
import PreLoading from './PreLoading';
import ProtectedRoute from './ProtectedRouters';

const { ROUTERS } = CONSTANTS;

const UserRouter = {
  // path: ROUTERS.HOME,
  element: (
    <PreLoading>
      <PortalDefaultLayout />
    </PreLoading>
  ),
  children: [
    // {
    //   path: ROUTERS.HOME,
    //   element: (
    //     <ProtectedRoute location={ROUTERS.HOME}>
    //       <MainContainer.Home />
    //     </ProtectedRoute>
    //   ),
    // },
    {
      path: ROUTERS.PROFILE,
      element: (
        <ProtectedRoute location={ROUTERS.PROFILE}>
          <MainContainer.Profile />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.PROFILE_CHANGE_PASSWORD,
      element: (
        <ProtectedRoute location={ROUTERS.PROFILE_CHANGE_PASSWORD}>
          <MainContainer.ChangePassword />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.USERS,
      element: (
        <ProtectedRoute location={ROUTERS.USERS}>
          <MainContainer.UserList />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.USER_INFORMATION_DETAILS,
      element: (
        <ProtectedRoute location={ROUTERS.USER_INFORMATION_DETAILS}>
          <MainContainer.UserDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.FILE,
      element: (
        <ProtectedRoute location={ROUTERS.FILE}>
          <MainContainer.MediaManagement />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.ROLE_LIST,
      element: (
        <ProtectedRoute location={ROUTERS.ROLE_LIST}>
          <MainContainer.RoleList />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.EDIT_ROLE,
      element: (
        <ProtectedRoute location={ROUTERS.EDIT_ROLE}>
          <MainContainer.EditRole />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.PROJECT_LIST,
      element: (
        <ProtectedRoute location={ROUTERS.PROJECT_LIST}>
          <MainContainer.ProjectsList />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.PROJECT_CREATE,
      element: (
        <ProtectedRoute location={ROUTERS.PROJECT_CREATE}>
          <MainContainer.CreateProject />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.PROJECT_MODIFY,
      element: (
        <ProtectedRoute location={ROUTERS.PROJECT_MODIFY}>
          <MainContainer.ModifyProject />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CREATE_ROLE,
      element: (
        <ProtectedRoute location={ROUTERS.CREATE_ROLE}>
          <MainContainer.CreateRole />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.LOGS,
      element: (
        <ProtectedRoute location={ROUTERS.LOGS}>
          <MainContainer.Logs />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.PERSONAL_INFORMATION,
      element: (
        <ProtectedRoute location={ROUTERS.PERSONAL_INFORMATION}>
          <MainContainer.UserDetails key="personal_information" />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.DASHBOARD,
      element: (
        <ProtectedRoute location={ROUTERS.DASHBOARD}>
          <MainContainer.Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.WIKI,
      element: (
        <ProtectedRoute location={ROUTERS.WIKI}>
          <MainContainer.ListWiki />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CREATE_WIKI,
      element: (
        <ProtectedRoute location={ROUTERS.CREATE_WIKI}>
          <MainContainer.CreateWiki />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.EDIT_WIKI,
      element: (
        <ProtectedRoute location={ROUTERS.EDIT_WIKI}>
          <MainContainer.EditWiki />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.PROJECT_REPORT,
      element: (
        <ProtectedRoute location={ROUTERS.PROJECT_REPORT}>
          <MainContainer.ProjectReports />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.EVENT,
      element: (
        <ProtectedRoute location={ROUTERS.EVENT}>
          <MainContainer.Event />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.ATTENDANCE,
      element: (
        <ProtectedRoute location={ROUTERS.ATTENDANCE}>
          <MainContainer.Attendances />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.ANNOUNCEMENT,
      element: (
        <ProtectedRoute location={ROUTERS.ANNOUNCEMENT}>
          <MainContainer.AnnouncementList />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.ANNOUNCEMENT_CREATE,
      element: (
        <ProtectedRoute location={ROUTERS.ANNOUNCEMENT_CREATE}>
          <MainContainer.CreateAnnouncement />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CRAWLING_SYSTEM,
      element: (
        <ProtectedRoute location={ROUTERS.ANNOUNCEMENT}>
          <MainContainer.CrawlingSystem />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CRAWLING_PROJECT,
      element: (
        <ProtectedRoute location={ROUTERS.CRAWLING_PROJECT}>
          <MainContainer.CrawlingProject />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CRAWLING_PROJECT_CREATE,
      element: (
        <ProtectedRoute location={ROUTERS.CRAWLING_PROJECT_CREATE}>
          <MainContainer.CreateCrawlingProject />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CRAWLING_PROJECT_DETAIL,
      element: (
        <ProtectedRoute location={ROUTERS.CRAWLING_PROJECT_DETAIL}>
          <MainContainer.UpdateCrawlingProject />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.ANNOUNCEMENT_DETAILS,
      element: (
        <ProtectedRoute location={ROUTERS.ANNOUNCEMENT_DETAILS}>
          <MainContainer.UpdateAnnouncement />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.ANNOUNCEMENT_USER,
      element: (
        <ProtectedRoute location={ROUTERS.ANNOUNCEMENT_USER}>
          <MainContainer.AnnouncementUser />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.FEEDBACK,
      element: (
        <ProtectedRoute location={ROUTERS.FEEDBACK}>
          <MainContainer.FeedbackList />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.FEEDBACK_GUEST,
      element: (
        <ProtectedRoute location={ROUTERS.FEEDBACK_GUEST}>
          <MainContainer.FeedbackGuest />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CONFIG_SYSTEM,
      element: (
        <ProtectedRoute location={ROUTERS.CONFIG_SYSTEM}>
          <MainContainer.ListConfigSystem />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CREATE_FEEDBACK,
      element: (
        <ProtectedRoute location={ROUTERS.CREATE_FEEDBACK}>
          <MainContainer.CreatedFeedback />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CONFIG_SYSTEM_CREATE,
      element: (
        <ProtectedRoute location={ROUTERS.CONFIG_SYSTEM_CREATE}>
          <MainContainer.CreateConfigSystem />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.REPLY_FEEDBACK,
      element: (
        <ProtectedRoute location={ROUTERS.REPLY_FEEDBACK}>
          <MainContainer.UpdateFeedback />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CONFIG_SYSTEM_DETAILS,
      element: (
        <ProtectedRoute location={ROUTERS.CONFIG_SYSTEM_DETAILS}>
          <MainContainer.EditConfigSystem />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.FILE_TEMPLATES,
      element: (
        <ProtectedRoute location={ROUTERS.FILE_TEMPLATES}>
          <MainContainer.ListFileTemplate />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.FILE_TEMPLATES_CREATE,
      element: (
        <ProtectedRoute location={ROUTERS.FILE_TEMPLATES_CREATE}>
          <MainContainer.CreateFileTemplate />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.FILE_TEMPLATES_DETAILS,
      element: (
        <ProtectedRoute location={ROUTERS.FILE_TEMPLATES_DETAILS}>
          <MainContainer.EditFileTemplate />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.WORK_PROGRESS,
      element: (
        <ProtectedRoute location={ROUTERS.WORK_PROGRESS}>
          <MainContainer.WorkProgressList />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.WORK_PROGRESS_DETAILS,
      element: (
        <ProtectedRoute location={ROUTERS.WORK_PROGRESS_DETAILS}>
          <MainContainer.WorkProgressDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.TOOLS,
      element: (
        <ProtectedRoute location={ROUTERS.TOOLS}>
          <MainContainer.ToolList />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.TOOL_CREATE,
      element: (
        <ProtectedRoute location={ROUTERS.TOOL_CREATE}>
          <MainContainer.CreateTool />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.TOOL_DETAILS,
      element: (
        <ProtectedRoute location={ROUTERS.TOOL_DETAILS}>
          <MainContainer.EditTool />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.NEWS_MANAGER,
      element: (
        <ProtectedRoute location={ROUTERS.NEWS_MANAGER}>
          <MainContainer.NewsManager />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CREATE_POST,
      element: (
        <ProtectedRoute location={ROUTERS.CREATE_POST}>
          <MainContainer.CreatePost />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.UPDATE_POST,
      element: (
        <ProtectedRoute location={ROUTERS.UPDATE_POST}>
          <MainContainer.UpdatePost />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.DAILY_TASK,
      element: (
        <ProtectedRoute location={ROUTERS.DAILY_TASK}>
          <MainContainer.DailyTask />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.DAILY_TASK_CREATE,
      element: (
        <ProtectedRoute location={ROUTERS.DAILY_TASK_CREATE}>
          <MainContainer.CreateDailyTask />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.DAILY_TASK_DETAILS,
      element: (
        <ProtectedRoute location={ROUTERS.DAILY_TASK_DETAILS}>
          <MainContainer.UpdateDailyTask />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CREATE_PORTFOLIO,
      element: (
        <ProtectedRoute location={ROUTERS.CREATE_PORTFOLIO}>
          <MainContainer.CreatePorfolio />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.ADMIN_PORTFOLIO,
      element: (
        <ProtectedRoute location={ROUTERS.ADMIN_PORTFOLIO}>
          <MainContainer.PorfolioManager />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.ADMIN_PORTFOLIO_DETAIL,
      element: (
        <ProtectedRoute location={ROUTERS.ADMIN_PORTFOLIO_DETAIL}>
          <MainContainer.UpdatePorfolio />
        </ProtectedRoute>
      ),
    },
  ],
};

export default UserRouter;
