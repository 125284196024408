import * as UserApi from './User.api';
import * as AuthApi from './Authentication.api';
import * as RoleApi from './Role.api';
import * as FolderApi from './Folder.api';
import * as ProjectApi from './Project.api';
import * as MediaApi from './Media.api';
import * as LogsApi from './Logs.api';
import * as DailyReportApi from './DailyReport.api';
import * as WikiApi from './Wiki.api';
import * as EventApi from './Event.api';
import * as AttendanceApi from './Attendances.api';
import * as ProjectMemberApi from './ProjectMember.api';
import * as AnnouncementsApi from './Announcement.api';
import * as TagApi from './Tag.api';
import * as CrawlingSystemApi from './CrawlingSystem.api';
import * as AnnouncementsUserApi from './AnnounecementUser.api';
import * as FeedbacksApi from './Feedbacks.api';
import * as FeedbackGuestAPi from './FeedbackGuest.api';
import * as ConfigSystemApi from './ConfigSystem.api';
import * as FileTemplateApi from './FileTemplate.api';
import * as WorkProgressApi from './WorkProgress.api';
import * as ToolApi from './Tool.api';
import * as NewsApi from './News.api';
import * as DailyReportTask from './DailyReportTask.api';
import * as Porfolio from './Porfolio.api';

export default {
  ...AuthApi,
  ...RoleApi,
  ...UserApi,
  ...ProjectApi,
  ...FolderApi,
  ...MediaApi,
  ...LogsApi,
  ...DailyReportApi,
  ...WikiApi,
  ...EventApi,
  ...AttendanceApi,
  ...ProjectMemberApi,
  ...AnnouncementsApi,
  ...TagApi,
  ...CrawlingSystemApi,
  ...AnnouncementsUserApi,
  ...FeedbacksApi,
  ...FeedbackGuestAPi,
  ...ConfigSystemApi,
  ...FileTemplateApi,
  ...WorkProgressApi,
  ...ToolApi,
  ...NewsApi,
  ...DailyReportTask,
  ...Porfolio,
};
