/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import get from 'lodash/get';
import { saveAs } from 'file-saver';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import {
  Checkbox,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from 'react-multi-lang';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import Utils from '../../../Utils';
import Constants from '../../../Constants';
import { Alert } from '@/Widgets';
import { MediaActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { Images } from '@/Themes';
import { IFileDetailsStructure } from '@/Interfaces/File.interface';

interface IProps {
  payload: any[];
  onUpload(files: File[]): void;
  onPreviewImage(path: string): void;
  folderId: string | null;
}

const functionStyle = {
  sx: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.4)',
    color: 'white',
    ':hover': {
      background: 'rgba(0,0,0,0.7)',
      cursor: 'pointer',
    },
    '&.disabled': {
      cursor: 'not-allowed',
      background: 'transparent',
      color: 'black',
      opacity: 0.5,
    },
  },
};

const videoExtensions = [
  'webm',
  'mpg',
  'mp2',
  'mpeg',
  'mpe',
  'mpv',
  'ogg',
  'mp4',
  'm4p',
  'm4v',
  'avi',
  'mov',
  'wmv',
  'flv',
];
const audioExtensions = ['m4a', 'flac', 'mp3', 'wav', 'wma', 'aac'];
const imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'tiff', 'svg'];

const fileIcons = [
  {
    icon: Images.docIcon,
    ext: ['doc', 'docx', 'txt'],
  },
  {
    icon: Images.pptIcon,
    ext: ['ppt', 'pptx', 'pptm'],
  },
  {
    icon: Images.sheetIcon,
    ext: ['xls', 'xlsx', 'xlsm', 'csv', 'xlsb', 'ods'],
  },
  {
    icon: Images.pdfLogo,
    ext: ['pdf'],
  },
  {
    icon: Images.filmIcon,
    ext: [
      'webm',
      'mpg',
      'mp2',
      'mpeg',
      'mpe',
      'mpv',
      'ogg',
      'mp4',
      'm4p',
      'm4v',
      'avi',
      'mov',
      'wmv',
      'flv',
    ],
  },
  {
    icon: Images.audioIcon,
    ext: ['m4a', 'flac', 'mp3', 'wav', 'wma', 'aac'],
  },
];

const allowViewExtensions = [
  ...videoExtensions,
  ...audioExtensions,
  ...imageExtensions,
];

const { deleteMedia, downloadFiles, setDefaultMediaStatus } = MediaActions;

const { MODULE_API } = Constants;

const FileList: React.FC<IProps> = ({
  payload,
  onUpload,
  folderId,
  onPreviewImage,
}) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const isDownloadSuccess: boolean = useSelector((state: RootState) =>
    get(state.MEDIA, 'downloadIsSuccess')
  );
  const fileIsDownloading: boolean = useSelector((state: RootState) =>
    get(state.MEDIA, 'fileIsDownloading')
  );

  const [isEnableDownload, setIsEnableDownload] =
    React.useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = React.useState<string[]>([]);

  const onDrop = React.useCallback((acceptedFiles: any) => {
    if (acceptedFiles.length > 0) onUpload(acceptedFiles);
  }, []);

  const isAcceptUpload = Utils.isValidPermission(MODULE_API.FILE.UPLOAD_FILE);
  const isAcceptDownloadMultiples = Utils.isValidPermission(
    MODULE_API.FILE.DOWNLOAD_MULTIPLE
  );
  const isAcceptRemove = Utils.isValidPermission(MODULE_API.FILE.REMOVE_FILE);

  React.useEffect(() => {
    if (isDownloadSuccess) {
      setIsEnableDownload(false);
      setSelectedFiles([]);
      dispatch(setDefaultMediaStatus());
    }
  }, [isDownloadSuccess]);

  const onDropRejected = React.useCallback((rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length > 10)
      Alert({
        type: 'ERROR',
        message: t('popup.errorLimitFiles'),
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 10,
    multiple: true,
    onDropRejected,
    noDrag: true,
  });

  const onRemove = async (id: string) => {
    const isAgree = await Alert({
      type: 'WARNING',
      message: t('popup.warningRemoveFile'),
    });
    if (isAgree === 'ok' && folderId) {
      dispatch(deleteMedia(id, folderId));
    }
  };

  const onSelectAll = () => {
    const resolveAllPath: string[] = [];
    payload.forEach((item: IFileDetailsStructure) =>
      resolveAllPath.push(item.convertedName)
    );
    setSelectedFiles(resolveAllPath);
  };

  const onCheckFiles = async (name: string) => {
    const isIncludes = selectedFiles.includes(name);
    let resolveSelectedFiles = [...selectedFiles, name];
    if (isIncludes)
      resolveSelectedFiles = selectedFiles.filter(
        (item: string) => item !== name
      );

    setSelectedFiles(resolveSelectedFiles);
  };

  const onCancelDownload = () => {
    setSelectedFiles([]);
    setIsEnableDownload(false);
  };

  const onDownload = async () => {
    dispatch(downloadFiles({ keys: selectedFiles }));
  };

  const _renderThumbnail = (item: IFileDetailsStructure) => {
    const fileExt = item.originalName.split('.').pop();

    const isImage = fileExt ? imageExtensions.includes(fileExt) : false;
    const isVideo = fileExt ? videoExtensions.includes(fileExt) : false;

    if (!isImage && !isVideo && fileExt) {
      const findIcon = fileIcons.find(
        (fileIcon: { icon: string; ext: string[] }) =>
          fileIcon.ext.includes(fileExt)
      );
      return (
        <img
          src={findIcon?.icon}
          alt=""
          style={{
            width: '50%',
            height: '100%',
            margin: '0 auto',
            objectFit: 'contain',
          }}
        />
      );
    }

    if (isVideo)
      return (
        <video
          src={item.path}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      );

    return (
      <img
        src={item.thumbnail}
        alt=""
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />
    );
  };

  const _renderItemFunction = (item: IFileDetailsStructure) => {
    const fileExt = item.originalName.split('.').pop();
    const isAllowView = fileExt ? allowViewExtensions.includes(fileExt) : false;
    return (
      <Stack
        className="function"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          background: 'rgba(0,0,0,0.1)',
        }}
      >
        <Grid container sx={{ width: '100%', height: '180px' }}>
          {isAllowView ? (
            <>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <Stack
                  {...functionStyle}
                  onClick={() => isAllowView && onPreviewImage(item.path)}
                  className={isAllowView ? '' : 'disable'}
                >
                  <VisibilityIcon fontSize="small" />
                  <Typography sx={{ fontSize: '13px', textAlign: 'center' }}>
                    {t('button.view')}
                  </Typography>
                </Stack>
              </Grid>{' '}
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <Stack
                  {...functionStyle}
                  onClick={() => saveAs(item.path, item.convertedName)}
                >
                  <DownloadIcon fontSize="small" />
                  <Typography sx={{ fontSize: '13px', textAlign: 'center' }}>
                    {t('button.download')}
                  </Typography>
                </Stack>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Stack
                {...functionStyle}
                onClick={() => saveAs(item.path, item.convertedName)}
              >
                <DownloadIcon fontSize="small" />
                <Typography sx={{ fontSize: '13px', textAlign: 'center' }}>
                  {t('button.download')}
                </Typography>
              </Stack>
            </Grid>
          )}
          {isAcceptRemove ? (
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Stack {...functionStyle} onClick={() => onRemove(item.id)}>
                <DeleteOutlineOutlinedIcon fontSize="small" />
                <Typography sx={{ fontSize: '13px', textAlign: 'center' }}>
                  {t('button.remove')}
                </Typography>
              </Stack>
            </Grid>
          ) : null}
        </Grid>
      </Stack>
    );
  };

  const _renderContentWithSelectedFolder = () => {
    return (
      <Grid container spacing={2} sx={{}}>
        {!isEnableDownload && isAcceptUpload ? (
          <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                border: '1px solid #ccc',
                borderRadius: '5px',
                minWidth: '150px',
                minHeight: '150px',
                padding: '15px',
                alignItems: 'center',
                justifyContent: 'center',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <BackupOutlinedIcon />
              <Typography
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  fontSize: '13px',
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {t('tooltip.clickToUploadFile')}
              </Typography>
            </Stack>
          </Grid>
        ) : null}
        {payload.map((item: IFileDetailsStructure) => (
          <Grid key={`item-${item.id}`} item xs={6} sm={4} md={4} lg={4} xl={4}>
            {isEnableDownload ? (
              <Stack
                direction="column"
                sx={{
                  width: '100%',
                  border: '1px solid #ccc',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() =>
                  !fileIsDownloading && onCheckFiles(item.convertedName)
                }
              >
                {isEnableDownload && !fileIsDownloading ? (
                  <Checkbox
                    checked={selectedFiles.includes(item.convertedName)}
                  />
                ) : null}
                <Stack
                  sx={{
                    width: '100%',
                    height: '140px',
                    position: 'relative',
                    ':hover .function': {
                      opacity: 1,
                    },
                  }}
                >
                  {_renderThumbnail(item)}
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '40px',
                    px: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      fontSize: '13px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    title={item.convertedName}
                  >
                    {item.originalName}
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              <Stack
                direction="column"
                sx={{
                  width: '100%',
                  border: '1px solid #ccc',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <Stack
                  sx={{
                    width: '100%',
                    height: '140px',
                    position: 'relative',
                    ':hover .function': {
                      opacity: 1,
                    },
                  }}
                >
                  {_renderThumbnail(item)}
                  {_renderItemFunction(item)}
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '40px',
                    px: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      fontSize: '13px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    title={item.convertedName}
                  >
                    {item.originalName}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Grid>
        ))}
      </Grid>
    );
  };

  const _renderContentWithoutSelectedFolder = () => {
    return (
      <Typography sx={{ fontSize: '14px' }}>
        {t('tooltip.selectFolderToContinue')}
      </Typography>
    );
  };

  const _renderTopSection = () => {
    const isSelectedAll = selectedFiles.length === payload.length;
    return (
      <Stack direction="row" alignItems="center" mb={1}>
        <Typography sx={{ fontSize: '15px', fontWeight: '500', mr: 1 }}>
          {t('title.files')}
        </Typography>
        <Stack direction="row" alignItems="center">
          {!isEnableDownload && isAcceptDownloadMultiples ? (
            <Tooltip title={t('tooltip.clickToDownloadFileOfThisFolder')}>
              <IconButton onClick={() => setIsEnableDownload(true)}>
                <CloudDownloadOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : null}
          {isEnableDownload ? (
            !fileIsDownloading ? (
              <Stack direction="row">
                <Typography sx={{ mr: 1 }}>
                  {t('tooltip.selected')} {selectedFiles.length}/
                  {payload.length}
                </Typography>
                {isSelectedAll ? (
                  <Link
                    sx={{ mr: 1, ':hover': { cursor: 'pointer' } }}
                    onClick={() => setSelectedFiles([])}
                  >
                    {t('button.unselectAll')}
                  </Link>
                ) : (
                  <Link
                    sx={{ mr: 1, ':hover': { cursor: 'pointer' } }}
                    onClick={onSelectAll}
                  >
                    {t('button.selectAll')}
                  </Link>
                )}
                <Link
                  sx={{ mr: 1, ':hover': { cursor: 'pointer' } }}
                  onClick={onDownload}
                >
                  {t('button.download')}
                </Link>
                <Link
                  onClick={onCancelDownload}
                  sx={{ ':hover': { cursor: 'pointer' } }}
                >
                  {t('button.cancel')}
                </Link>
              </Stack>
            ) : (
              <Typography>{t('tooltip.downloading')}</Typography>
            )
          ) : null}
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack flex={1} mt={2}>
      {_renderTopSection()}
      {folderId
        ? _renderContentWithSelectedFolder()
        : _renderContentWithoutSelectedFolder()}
    </Stack>
  );
};

export default FileList;
