import { sendRequest } from '@/Configs';
import CONSTANTS from '@/Constants';
// import { IFolderStructure } from '@/Interfaces/Folders.interface';

const { FOLDERS } = CONSTANTS.API;

export const fetchFolders = async () => {
  return sendRequest(FOLDERS.BASIC, 'GET');
};

export const fetchRootFolders = async () => {
  return sendRequest(FOLDERS.BASIC, 'GET');
};

export const fetchChildFolders = async (parentId: string) => {
  return sendRequest(`${FOLDERS.CHILD}/${parentId}`, 'GET');
};

export const createFolder = async (payload: {
  name: string;
  parentId?: string;
}) => {
  return sendRequest(FOLDERS.BASIC, 'POST', payload);
};

export const updateFolder = async (payload: {
  id: string;
  name: string;
  parentId?: string;
}) => {
  const { id } = payload;
  return sendRequest(`${FOLDERS.BASIC}/${id}`, 'PUT', payload);
};

export const deleteFolder = async (id: string) => {
  return sendRequest(`${FOLDERS.BASIC}/${id}`, 'DEL');
};
