import React, { useState } from 'react';
// import Constants from '@/Constants';
import { Box } from '@mui/material';
// import _ from 'lodash';

import MarketReSearch from './MarketResearch';
import Media from './Media';
import OurServicesMarket from './OurServicesMarket';

// const { ROUTERS } = Constants;

const OurServices: React.FC = () => {
  const [selectedServices, setSelectedServices] =
    useState<string>('OUR_SERVICES');
  const [selectedMarket, setSelectedMartket] = useState<string>();

  const callback = (action: string, data?: string) => {
    if (action === 'OUR_SERVICES') setSelectedServices('OUR_SERVICES');
    if (action === 'MEDIA_INTELLIGENCE')
      setSelectedServices('MEDIA_INTELLIGENCE');
    if (action === 'market') {
      setSelectedServices('MARKET');
      if (data) setSelectedMartket(data);
    }
  };

  const _renderBody = () => {
    switch (selectedServices) {
      case 'OUR_SERVICES':
        return <MarketReSearch callback={callback} />;
      case 'MEDIA_INTELLIGENCE':
        return <Media />;
      case 'MARKET':
        return <OurServicesMarket callback={callback} data={selectedMarket} />;
      default:
        return null;
    }
  };

  const renderMain = () => {
    return (
      <Box>
        <Box>{_renderBody()}</Box>
      </Box>
    );
  };

  return renderMain();
};

export default OurServices;
