import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import dayjs from 'dayjs';

import { Box, Tooltip, IconButton, Typography } from '@mui/material';
import { ChipStatus, StickyHeadTable } from '@/Components/Common';
import PreviewIcon from '@mui/icons-material/Preview';
import { IMetaStructure } from '@/Interfaces/Common.interface';

interface ISectionProps {
  meta: IMetaStructure;
  payload: any;
  onRowAction(Project: any, action: 'detail'): void;
  onPageAction(value: number, type: 'limit' | 'page'): void;
  isLoading?: boolean;
}

interface IColumnStructure {
  id:
    | 'no'
    | 'title'
    | 'content'
    | 'action'
    | 'created_by'
    | 'status'
    | 'created_at';
  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  {
    id: 'title',
    label: 'title',
    minWidth: 150,
    maxWidth: 250,
    align: 'center',
  },
  { id: 'created_by', label: 'createdBy', minWidth: 50, align: 'center' },
  { id: 'created_at', label: 'createdAt', minWidth: 80, align: 'center' },
  { id: 'status', label: 'status', minWidth: 50, align: 'center' },
  { id: 'action', label: 'action', minWidth: 50, align: 'center' },
];

const createDataTable = (
  no: string,
  title: JSX.Element,
  created_by: string,
  created_at: string,
  status: JSX.Element,
  action: JSX.Element
): any => {
  return {
    no,
    title,
    created_by,
    action,
    created_at,
    status,
  };
};

const AnnouncementUserDataTable: React.FC<ISectionProps> = ({
  payload,
  meta,
  onPageAction,
  onRowAction,
  isLoading = false,
}) => {
  const [rows, setRows] = useState<any[]>([]);

  React.useEffect(() => {
    const resolveRows: any = [];
    if (!isEmpty(payload)) {
      forEach(payload.items, (item: any, index: number) => {
        const { announcement, status } = item;
        const sender = announcement.createdBy.email;
        const createdTime = dayjs(announcement?.createdAt);
        const currentPage = get(meta, 'currentPage');
        const limit = get(meta, 'itemsPerPage');
        const resolveRowIndex = index + 1 + (currentPage - 1) * limit;

        resolveRows.push(
          createDataTable(
            `${resolveRowIndex}`,
            <Typography
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                height: 1,
                maxWidth: 250,
              }}
            >
              {announcement?.title}
            </Typography>,
            sender,
            createdTime ? createdTime.format('HH:mm, DD-MM-YYYY') : 'N/I',
            <ChipStatus label={status} />,
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <Tooltip title={`Open to detail announcement`}>
                <span>
                  <IconButton onClick={() => onRowAction(item, 'detail')}>
                    <PreviewIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      onAction={onPageAction}
      paginate={meta}
      isLoading={isLoading}
    />
  );
};

export default AnnouncementUserDataTable;
