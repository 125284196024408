import { Box, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import { get } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { ICreateFeedback } from '@/Interfaces/Feedback.interface';
import { RootState, useTypedDispatch } from '@/store';
import FeedbacksAction from '@/Actions/Feedbacks.action';
import { RoundedContainer, TextEditor, TextField } from '@/Components/Common';
import Utils from '@/Utils';
import CONSTANTS from '@/Constants';

const { ROUTERS } = CONSTANTS;
const { createFeedbacks } = FeedbacksAction;

const CreatedFeedback: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const isLoading: any = useSelector((state: RootState) =>
    get(state.FEEDBACK, 'requestIsLoading')
  );

  const schema = yup
    .object()
    .shape({
      title: yup.string().trim().required(t('message.feedbackTitleRequired')),
      content: yup
        .string()
        .trim()
        .required(t('message.feedbackDescriptionRequired')),
    })
    .required();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      content: '',
    },
  });

  const onSubmit = (data: ICreateFeedback) => {
    dispatch(createFeedbacks(data));
  };

  const _renderTopSection = () => {
    return (
      <Stack>
        <Typography variant="h2">{t('title.createdFeedback')}</Typography>
      </Stack>
    );
  };

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Stack direction="column" mb={1}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('label.title')}
                  placeholder="IDRA Website"
                  message={errors.title?.message}
                  required
                  style={{ width: '100%' }}
                />
              )}
            />
            <Stack mt={1}>
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <TextEditor
                    value={field.value}
                    message={errors.content?.message}
                    label={t('label.description')}
                    containerSx={{ mb: 2 }}
                    onChange={(e: any) => field.onChange(e)}
                    required
                  />
                )}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              sx={{ mt: '50px' }}
            >
              <LoadingButton
                onClick={() => Utils.redirect(ROUTERS.FEEDBACK)}
                sx={{ mr: 1 }}
                variant="outlined"
                loading={isLoading}
              >
                {t('button.cancel')}
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                {t('button.create')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </RoundedContainer>
    );
  };

  const _renderContent = () => (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );

  const renderMain = () => {
    return (
      <Stack flex={1} direction="column">
        {_renderContent()}
      </Stack>
    );
  };

  return renderMain();
};

export default CreatedFeedback;
