import React from 'react';
import { Box, Button, Typography } from '@mui/material';
// import _ from 'lodash';
// import AcUnitIcon from '@mui/icons-material/AcUnit';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

// const dataMarketSectors = [
//   { label: 'Research and survey design' },
//   { label: 'Sample design' },
//   { label: 'Questionnaire design' },
//   { label: 'Fieldwork (PAPI, CAPI, CATI,CAWI etc)' },
//   { label: 'Data entry and validation' },
//   { label: 'Data analysis' },
//   {
//     label: 'Reporting (in-depth analysis, graphics, summary, recommendations)',
//   },
//   { label: 'Presentations (media, conference, workshop)' },
// ];

// const dataMarketResearch = [
//   { label: 'Understanding purchase decision-making' },
//   { label: 'Uncovering product and service needs and preferences' },
//   { label: 'Identifying business strengths, weaknesses, and opportunities' },
//   {
//     label:
//       'Evaluating the efficiency or pre-testing advertising/public relations strategies',
//   },
//   {
//     label:
//       'Exploring and discovering peoples attitudes, behavior, and intentions',
//   },
//   { label: 'Exploring brand positioning' },
//   { label: 'Testing new product ideas or concepts' },
//   { label: 'Assessing public affairs issues' },
// ];

// const MARKET = [
//   { label: 'Brand positioning' },
//   { label: 'Packaging and graphic design research' },
//   { label: 'Pricing perceptions' },
//   { label: 'Motivational research' },
//   { label: 'Advertisement and Communication research' },
//   { label: 'Product testing, etc' },
//   { label: 'Concept testing' },
// ];

// const dataMethods = [
//   { label: 'In-depth interviews' },
//   { label: 'Paired interviews' },
//   { label: 'Focus groups' },
//   { label: 'Observation' },
// ];

interface IPropsOur {
  callback(action: string): void;
  data?: any;
}

const OurServicesMarket: React.FC<IPropsOur> = ({ callback }) => {
  // const _renderTableMarket = (data: any) => {
  //   return (
  //     <Grid container mt={4}>
  //       {_.map(data, (item: any, index: number) => {
  //         return (
  //           <Grid
  //             item
  //             xs={6}
  //             sm={3}
  //             key={index}
  //             sx={{
  //               border: 'solid 1px #898989',
  //               p: '11px 26px',
  //               display: 'flex',
  //               alignItems: 'center',
  //               justifyContent: 'center',
  //             }}
  //           >
  //             <Typography
  //               sx={{
  //                 fontSize: 18,
  //                 lineHeight: '30px',
  //                 textAlign: 'center',
  //                 color: '#3f3f3f',
  //                 fontWeight: 500,
  //               }}
  //             >
  //               {item.label}
  //             </Typography>
  //           </Grid>
  //         );
  //       })}
  //     </Grid>
  //   );
  // };

  // const _renderMenuList = (data: any[]) => {
  //   return (
  //     <Grid container ml={2}>
  //       {_.map(data, (item: any, index: number) => {
  //         return (
  //           <Grid
  //             item
  //             xs={12}
  //             sm={6}
  //             key={index}
  //             display="flex"
  //             sx={{ alignItems: 'baseline', mb: '10px' }}
  //           >
  //             <AcUnitIcon sx={{ fontSize: 8, mr: 1 }} />
  //             <Typography
  //               sx={{
  //                 fontSize: 18,
  //                 lineHeight: '32px',
  //                 color: '#3f3f3f',
  //                 fontWeight: 400,
  //               }}
  //             >
  //               {item?.label}
  //             </Typography>
  //           </Grid>
  //         );
  //       })}
  //     </Grid>
  //   );
  // };

  // const _renderQuanity_one = () => {
  //   return (
  //     <Box>
  //       <Typography
  //         sx={{
  //           fontSize: 18,
  //           lineHeight: '28px',
  //           fontWeight: 400,
  //           mt: 2,
  //         }}
  //       >
  //         FLOWIDRA offers quantitative research services in the form of surveys
  //         both ad-hoc and continuous (omnibus, tracking, etc).
  //       </Typography>
  //       <Typography
  //         sx={{
  //           fontSize: 18,
  //           lineHeight: '28px',
  //           fontWeight: 400,
  //           mt: 2,
  //         }}
  //       >
  //         Each project is carefully considered to respond to client needs. IDRA
  //         offers a full package of quantitative research services:
  //       </Typography>
  //       <Box>{_renderTableMarket(dataMarketSectors)}</Box>
  //       <Typography
  //         sx={{
  //           fontSize: 18,
  //           lineHeight: '28px',
  //           fontWeight: 400,
  //           mt: 2,
  //         }}
  //       >
  //         Our work is conducted in accordance with ESOMAR Code of research
  //         practice. Our interviewers are well trained and experienced and abide
  //         by standards of IDRA IQCS (Interviewer Quality Control Scheme).
  //       </Typography>
  //     </Box>
  //   );
  // };

  // const _renderMarket = () => {
  //   return (
  //     <Box>
  //       <Box>
  //         <Typography
  //           sx={{
  //             fontSize: 18,
  //             lineHeight: '28px',
  //             fontWeight: 400,
  //             mt: 2,
  //           }}
  //         >
  //           Quantitative Research is more concerned with questions about: how
  //           much? how many? how often? to what extent? On the other hand,
  //           Qualitative Research is concerned with finding the answers to
  //           questions which begin with: why? how? in what way?
  //         </Typography>
  //         <Typography
  //           sx={{
  //             fontSize: 18,
  //             lineHeight: '28px',
  //             fontWeight: 400,
  //             mt: 2,
  //           }}
  //         >
  //           Qualitative research is applied when your business needs to explore
  //           some of the following issues:
  //         </Typography>
  //       </Box>
  //       <Box>{_renderTableMarket(dataMarketResearch)}</Box>
  //       <Box>
  //         <Typography
  //           sx={{
  //             fontSize: 18,
  //             lineHeight: '28px',
  //             fontWeight: 400,
  //             mt: 2,
  //           }}
  //         >
  //           During almost a decade of operation IDRA has acquired quite a wealth
  //           of experience and expertise to conduct qualitative research for many
  //           issues and clients. Some of the marketing issues we have been
  //           involved with are listed below:
  //         </Typography>
  //       </Box>
  //       <Box mt={2}>{_renderMenuList(MARKET)}</Box>
  //       <Box>
  //         <Typography
  //           sx={{
  //             fontSize: 18,
  //             lineHeight: '28px',
  //             fontWeight: 400,
  //             mt: 2,
  //           }}
  //         >
  //           IDRA employs highly qualified and experienced moderators who have
  //           been conducting qualitative research for some of the leading brands
  //           in the market including Coca-Cola, P&G, Vodafone, Raiffeisen Bank,
  //           JTI, etc.
  //         </Typography>
  //         <Typography
  //           sx={{
  //             fontSize: 18,
  //             lineHeight: '28px',
  //             fontWeight: 400,
  //             mt: 2,
  //           }}
  //         >
  //           We possess all the necessary facilities and equipment to conduct
  //           focus groups and in-depth interviews (video-audio registration,
  //           large TV screen in observer room, simultaneous translation
  //           equipment, etc).
  //         </Typography>
  //       </Box>
  //       <Box>
  //         <Typography
  //           component="h3"
  //           sx={{
  //             fontSize: 26,
  //             fontFamily: 'Inter',
  //             color: '#212121',
  //             fontWeight: 700,
  //             fontStyle: 'normal',
  //             lineHeight: '30px',
  //             position: 'relative',
  //             pl: 2,
  //             my: 4,
  //             '&::after': {
  //               content: '""',
  //               position: 'absolute',
  //               width: 4,
  //               height: 30,
  //               backgroundColor: '#0068c5',
  //               bottom: 0,
  //               left: '0',
  //             },
  //           }}
  //         >
  //           Methods
  //         </Typography>
  //         <Box>{_renderTableMarket(dataMethods)}</Box>
  //       </Box>
  //     </Box>
  //   );
  // };

  return (
    <Box px={2}>
      <Typography
        component="h3"
        sx={{
          fontSize: 26,
          fontFamily: 'Inter',
          color: '#212121',
          fontWeight: 700,
          fontStyle: 'normal',
          lineHeight: '30px',
          position: 'relative',
          pl: 2,
          my: 4,
          '&::after': {
            content: '""',
            position: 'absolute',
            width: 4,
            height: 30,
            backgroundColor: '#0068c5',
            bottom: 0,
            left: '0',
          },
        }}
      >
        QUANTITATIVE RESEARCH
      </Typography>
      {/* <>{data === 'market_1' ? _renderQuanity_one() : _renderMarket()}</> */}
      <Box mt={4}>
        <Button
          variant="outlined"
          onClick={() => callback('OUR_SERVICES')}
          startIcon={<KeyboardArrowLeftIcon />}
        >
          Back
        </Button>
      </Box>
    </Box>
  );
};

export default OurServicesMarket;
