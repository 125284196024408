import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  popupSubmitSuccess: false,
  payload: [],
  unassignedMembers: [],
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_DEFAULT_PROJECT_MEMBER_STATE:
      return {
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        popupSubmitSuccess: false,
        payload: [],
        unassignedMembers: [],
      };
    case ACTION_TYPES.SET_DEFAULT_PROJECT_MEMBER_STATUS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        popupSubmitSuccess: false,
      };
    case ACTION_TYPES.PROJECT_MEMBER_IS_REQUEST:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
        popupSubmitSuccess: false,
      };

    case ACTION_TYPES.FETCH_PROJECT_MEMBERS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        popupSubmitSuccess: false,
        payload,
      };
    case ACTION_TYPES.FETCH_PROJECT_MEMBERS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        popupSubmitSuccess: false,
        payload: [],
      };

    case ACTION_TYPES.FETCH_UNASSIGNED_MEMBERS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        unassignedMembers: payload,
      };
    case ACTION_TYPES.FETCH_UNASSIGNED_MEMBERS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        unassignedMembers: [],
      };

    case ACTION_TYPES.CREATE_PROJECT_MEMBER_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        popupSubmitSuccess: true,
      };
    case ACTION_TYPES.CREATE_PROJECT_MEMBER_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        popupSubmitSuccess: false,
      };

    case ACTION_TYPES.UPDATE_PROJECT_MEMBER_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        popupSubmitSuccess: true,
      };
    case ACTION_TYPES.UPDATE_PROJECT_MEMBER_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        popupSubmitSuccess: false,
      };

    case ACTION_TYPES.GET_PROJECT_MEMBER_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.GET_PROJECT_MEMBER_BY_ID_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.DELETE_PROJECT_MEMBER_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.DELETE_PROJECT_MEMBER_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    default:
      return state;
  }
};
