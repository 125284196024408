import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { animated, useSpring } from '@react-spring/web';

import {
  Box,
  Stack,
  Drawer,
  Grid,
  Container,
  Typography,
  Button,
} from '@mui/material';

import { RootState, useTypedDispatch } from '@/store';
import { Images } from '@/Themes';
import { CommonActions } from '@/Actions';
import Utils from '@/Utils';

interface IProps {
  children: React.JSX.Element;
}

const { userContentIsLoaded } = CommonActions;

const UserPreLoading: React.FC<IProps> = ({ children }) => {
  const containerSprings = useSpring({
    delay: 2500,
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
  });
  const logoSprings = useSpring({
    delay: 500,
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });
  const nameSprings = useSpring({
    delay: 1000,
    from: {
      overflow: 'hidden',
      width: '0px',
      opacity: 0,
    },
    to: {
      overflow: 'hidden',
      width: '180px',
      opacity: 1,
    },
  });
  const dispatch = useTypedDispatch();

  const isUserLoaded: boolean = useSelector((state: RootState) =>
    get(state.COMMON, 'userContentIsLoaded')
  );
  const [isOpen, setIsOpen] = React.useState(true);

  React.useEffect(() => {
    const timeout = setTimeout(() => dispatch(userContentIsLoaded()), 3000);
    const isAccepted = Utils.getAcceptCookie();
    if (isAccepted) setIsOpen(false);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!isUserLoaded)
    return (
      <animated.div style={containerSprings}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ width: '100vw', height: '100vh' }}
        >
          <animated.div style={logoSprings}>
            <Box component="img" src={Images.preLogo} />
          </animated.div>
          <animated.div style={nameSprings}>
            <Box component="img" src={Images.preName} sx={{ ml: 1.5 }} />
          </animated.div>
        </Stack>
      </animated.div>
    );

  return (
    <>
      {children}
      <Drawer
        anchor="bottom"
        // variant="persistent"
        open={isOpen}
        // onClose={() => setIsOpen(false)}
        PaperProps={{ sx: { p: 2 } }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ justifyContent: { sm: 'space-between', xs: 'center' } }}
          >
            <Grid item sm={1} xs={12} sx={{ textAlign: 'center' }}>
              <Box component="img" src={Images.preLogo} />
            </Grid>
            <Grid item sm={8} xs={12}>
              <Typography sx={{ fontSize: '0.875em' }}>
                We use cookies on our website to give you the most relevant
                experience by remembering your preferences and repeat visits. By
                clicking <b>&#34;Accept All&#34;</b>, you consent to the use of
                ALL the cookies. However, you may visit
                <b> &#34;Cookie Settings&#34;</b> to provide a controlled
                consent.
              </Typography>
            </Grid>
            <Grid
              item
              sm={2}
              xs={12}
              display="flex"
              flexDirection="column"
              rowGap={2}
            >
              <Button
                variant="contained"
                onClick={() => {
                  Utils.saveAcceptCookie(1);
                  setIsOpen(false);
                }}
              >
                Accept All
              </Button>
              <Button
                variant="contained"
                color="loblolly"
                onClick={() => setIsOpen(false)}
                sx={{ color: 'black' }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Drawer>
    </>
  );
};

export default UserPreLoading;
