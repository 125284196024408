import { CommonContainer } from '@/Containers';
import { AuthenticationLayout } from '@/Components/DefaultLayout';
import PreLoading from './PreLoading';

const AuthRouter = {
  element: (
    <PreLoading>
      <AuthenticationLayout />
    </PreLoading>
  ),
  children: [
    {
      path: '*',
      element: <CommonContainer.NotFound />,
    },
  ],
};

export default AuthRouter;
