import React from 'react';
import { useTranslation } from 'react-multi-lang';

import { Box, Typography, Tab } from '@mui/material';

import { TabContext, TabList, TabPanel } from '@mui/lab';

import ProjectDetails from './details';
import DailyReport from './daily-report';
import Documentation from './documentation';
import Members from './member';
import Utils from '@/Utils';
import CONSTANTS from '@/Constants';

interface ITab {
  label: string;
  value: string;
  component: React.JSX.Element | React.JSX.Element[];
  permission: boolean;
}

const { MODULE_API } = CONSTANTS;

const isAcceptEditProject =
  true || Utils.isValidPermission(MODULE_API.PROJECT.GET_PROJECT_BY_ID);

const isAcceptReport =
  true || Utils.isValidPermission(MODULE_API.DAILY_REPORT.FETCH);

const isAcceptAssignMember =
  true || Utils.isValidPermission(MODULE_API.PROJECT_MEMBER.FETCH);

const tabPanels = [
  {
    label: 'projectDetails',
    value: 'projectDetails',
    component: <ProjectDetails />,
    permission: isAcceptEditProject,
  },
  {
    label: 'dailyReport',
    value: 'dailyReport',
    component: <DailyReport />,
    permission: isAcceptReport,
  },
  {
    label: 'documentation',
    value: 'documentation',
    component: <Documentation />,
    permission: isAcceptEditProject,
  },
  {
    label: 'members',
    value: 'members',
    component: <Members />,
    permission: isAcceptAssignMember,
  },
];

const EditProject: React.FC = () => {
  const t = useTranslation();
  const [tabIndex, setTabIndex] = React.useState<string>('projectDetails');

  const _renderTopSection = () => (
    <Typography variant="h2">{t('title.editProject')}</Typography>
  );

  const _renderTab = () =>
    tabPanels.map((tab: ITab) =>
      tab.permission ? (
        <Tab
          key={`tab-${tab.value}`}
          label={t(`title.${tab.label}`)}
          value={tab.value}
        />
      ) : null
    );

  const _renderTabPanel = () =>
    tabPanels.map((tab: ITab) =>
      tab.permission && tabIndex === tab.value ? (
        <TabPanel
          key={`tab-panel-${tab.value}`}
          value={tab.value}
          sx={{ paddingX: 0 }}
        >
          {tab.component}
        </TabPanel>
      ) : null
    );

  const _renderBottomSection = () => {
    return (
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(_event: any, newValue: string) => setTabIndex(newValue)}
          >
            {_renderTab()}
          </TabList>
        </Box>
        {_renderTabPanel()}
      </TabContext>
    );
  };

  const renderMain = () => (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );

  return renderMain();
};

export default EditProject;
