import CONSTANTS from '@/Constants';

const { ACTION_TYPES } = CONSTANTS;

const DEFAULT_META = {
  keyword: '',
  monthAndYear: '',
};

const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  exportRequestIsLoading: false,
  payload: [],
  meta: DEFAULT_META,
  attendance: {},
};

const AttendanceReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.ATTENDANCES_IS_REQUEST:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.EXPORT_ATTENDANCES_IS_REQUEST:
      return {
        ...state,
        exportRequestIsLoading: true,
      };
    case ACTION_TYPES.SET_DEFAULT_ATTENDANCES_REDUCER:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload: [],
      };

    case ACTION_TYPES.FETCH_ATTENDANCES_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };
    case ACTION_TYPES.FETCH_ATTENDANCES_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload: payload,
      };

    case ACTION_TYPES.UPDATE_ATTENDANCES_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.SET_ATTENDANCE_META:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: true,
        meta: payload,
      };

    case ACTION_TYPES.UPDATE_ATTENDANCES_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: true,
        attendance: payload,
      };

    case ACTION_TYPES.EXPORT_ATTENDANCES_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: true,
        requestHasError: false,
        exportRequestIsLoading: false,
      };
    case ACTION_TYPES.EXPORT_ATTENDANCES_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestIsSuccess: false,
        requestHasError: true,
        exportRequestIsLoading: false,
      };
    default:
      return state;
  }
};

export default AttendanceReducer;
