import * as React from 'react';
import { useTranslation } from 'react-multi-lang';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import { saveAs } from 'file-saver';

// import { useTypedDispatch } from '@/store';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TextField } from '../../Common';
import PopupLayout from '../PopupLayout';

interface IPayload {
  template: string;
  keys: {
    label: string;
    value: string;
  }[];
}

interface IForm {
  name: string;
  template: string;
  keys: {
    label: string;
    value: string;
  }[];
}

interface SectionProps {
  onClose(): void;
  payload: IPayload | null;
}

const GenerateFileTemplatePopup: React.FC<SectionProps> = ({
  onClose,
  payload,
}) => {
  const t = useTranslation();
  // const dispatch = useTypedDispatch();
  const open = Boolean(payload);

  const schema = yup.object().shape({
    name: yup.string().trim().required(t('message.nameRequired')),
    template: yup.string().when('isContainTemplate', ([isContainTemplate]) => {
      if (isContainTemplate)
        return yup.string().trim().required(t('message.templateRequired'));
      return yup.string();
    }),
    keys: yup.array(
      yup.object().shape({
        value: yup.string().trim().required(t('message.valueRequired')),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    // watch,
    // setError,
    // getValues,
    setValue,
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      template: '',
      keys: [],
    },
  });

  const { fields } = useFieldArray({
    name: 'keys',
    control,
  });

  React.useEffect(() => {
    if (open && payload) {
      setValue('keys', payload.keys);
      setValue('template', payload.template);
    }
  }, [open]);

  const onCancel = () => {
    onClose();
  };

  const onSubmit = () => {
    const blob = new Blob(['\n\n\nVITE_BE_URL=300000']);
    const aTag = document.createElement('a');

    aTag.href = URL.createObjectURL(blob);
    aTag.download = 'Jenkinsfile';
    aTag.click();
    // saveAs(blob, '.env');
  };

  const _renderForm = () => (
    <>
      <form
        style={{
          width: '100%',
          overflow: 'auto',
        }}
      >
        <Stack direction="column">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.value)
                }
                label={t('label.name')}
                placeholder=".env"
                message={errors.name?.message}
                required
              />
            )}
          />
          {fields &&
            fields.map(
              (
                item: {
                  id: string;
                  label: string;
                  value: string;
                },
                index: number
              ) => {
                return (
                  <Stack direction="row" key={item.id}>
                    <Controller
                      name={`keys.${index}.label`}
                      control={control}
                      render={({ field }) => (
                        <Stack
                          direction="row"
                          sx={{ alignItems: 'center', height: '38px' }}
                        >
                          <Typography sx={{ mr: 1 }}>{field.value}</Typography>
                        </Stack>
                      )}
                    />

                    <Controller
                      name={`keys.${index}.value`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          value={field.value}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => field.onChange(event.target.value)}
                          placeholder="VITE_BE_URL"
                          required
                          message={errors.keys?.[index]?.value?.message}
                          style={{ mb: 0, mr: 1 }}
                        />
                      )}
                    />
                  </Stack>
                );
              }
            )}
        </Stack>
        <Stack sx={{ float: 'right', marginTop: 5 }} direction="row">
          <LoadingButton sx={{ mr: 1 }} onClick={onCancel}>
            {t('button.cancel')}
          </LoadingButton>
          <LoadingButton variant="contained" onClick={handleSubmit(onSubmit)}>
            {t('button.export')}
          </LoadingButton>
        </Stack>
      </form>
    </>
  );

  return (
    <PopupLayout
      title={t('title.generateFileTemplate')}
      content={_renderForm()}
      open={open}
      onClose={onClose}
    />
  );
};

export default GenerateFileTemplatePopup;
