import { Images } from '@/Themes';

export default [
  {
    label: 'React',
    icon: Images.react,
  },
  {
    label: 'Typescript',
    icon: Images.typeScript,
  },
  {
    label: 'Javascript',
    icon: Images.javaScript,
  },
  {
    label: 'Nestjs',
    icon: Images.nestjs,
  },
  {
    label: 'Nextjs',
    icon: Images.nextjs,
  },
  {
    label: 'Vitejs',
    icon: Images.vitejs,
  },
  {
    label: 'MUI',
    icon: Images.materialUI,
  },
  {
    label: 'Lavarel',
    icon: Images.laravel,
  },
  {
    label: 'PHP',
    icon: Images.php,
  },
  {
    label: 'Wordpress',
    icon: Images.wordPress,
  },
  {
    label: 'Flutter',
    icon: Images.flutter,
  },
  {
    label: 'Swift',
    icon: Images.swift,
  },
  {
    label: 'AdonisJS',
    icon: Images.adonisJS,
  },
  {
    label: 'AWS',
    icon: Images.AWS,
  },
  {
    label: 'Figma',
    icon: Images.figma,
  },
  {
    label: 'HTML5',
    icon: Images.HTML5,
  },
  {
    label: 'PostgresSQL',
    icon: Images.postgresSQL,
  },
  {
    label: 'Python',
    icon: Images.python,
  },
  {
    label: 'SASS',
    icon: Images.sass,
  },
  {
    label: 'Socket.io',
    icon: Images.socket,
  },
  {
    label: 'SQLite',
    icon: Images.SQLite,
  },
  {
    label: 'ElectronJS',
    icon: Images.electron,
  },
  {
    label: 'Leaflet',
    icon: Images.leaflet,
  },
  {
    label: 'CSS3',
    icon: Images.css3,
  },
  {
    label: 'TailwindCSS',
    icon: Images.tailwindCSS,
  },
  {
    label: 'NodeJS',
    icon: Images.nodejs,
  },
  {
    label: 'Expo',
    icon: Images.expo,
  },
  {
    label: 'MySQL',
    icon: Images.mySQL,
  },
  {
    label: 'Jenkins',
    icon: Images.jenkins,
  },
  {
    label: 'Heroku',
    icon: Images.heroku,
  },
  {
    label: 'WooCommerce',
    icon: Images.wooCommerce,
  },
  {
    label: 'Vercel',
    icon: Images.vercel,
  },
  {
    label: 'Apache Tomcat',
    icon: Images.apacheTomcat,
  },
  {
    label: 'Selenium',
    icon: Images.selenium,
  },
  {
    label: 'Redux',
    icon: Images.redux,
  },
  {
    label: 'Nodemon',
    icon: Images.nodemon,
  },
  {
    label: 'NGINX',
    icon: Images.NGINX,
  },
  {
    label: 'Redis',
    icon: Images.redis,
  },
  {
    label: 'Linux',
    icon: Images.linux,
  },
  {
    label: 'jQuery',
    icon: Images.jQuery,
  },
  {
    label: 'Ionic',
    icon: Images.ionic,
  },
  {
    label: 'Gulp.js',
    icon: Images.gulpjs,
  },
  {
    label: 'GraphQLs',
    icon: Images.graphQL,
  },
  {
    label: 'GitLab',
    icon: Images.gitLab,
  },
  {
    label: 'GitHub',
    icon: Images.gitHub,
  },
  {
    label: 'Git',
    icon: Images.git,
  },
  {
    label: 'Cloudflare',
    icon: Images.cloudflare,
  },
  {
    label: 'Bootstrap',
    icon: Images.bootstrap,
  },
  {
    label: 'Apache',
    icon: Images.apache,
  },
  {
    label: 'AngularJS',
    icon: Images.angularJS,
  },
  {
    label: 'Android',
    icon: Images.android,
  },
  {
    label: 'BitBucket',
    icon: Images.bitBucket,
  },
  {
    label: 'Docker',
    icon: Images.docker,
  },
  {
    label: 'Express',
    icon: Images.express,
  },
  {
    label: 'Pupperteer',
    icon: Images.pupperteer,
  },
  {
    label: 'TypeORM',
    icon: Images.typeorm,
  },
  {
    label: 'Zoom',
    icon: Images.zoom,
  },
  {
    label: 'Charka UI',
    icon: Images.charkaui,
  },
];
