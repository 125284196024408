import React from 'react';

import { Box, Typography, Container } from '@mui/material';
import { useLocation } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import dayjs from 'dayjs';
// import Utils from '@/Utils';
import Constants from '@/Constants';
import { Images } from '@/Themes';

const { ROUTERS } = Constants;

const FooterHomePage: React.FC = () => {
  const pathName = useLocation().pathname;
  if (pathName === ROUTERS.HOME)
    return (
      <Box
        sx={{
          width: 1,
          p: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', columnGap: 2 }}>
          <LazyLoadImage
            src={Images.logoFlow}
            alt="logo"
            style={{ cursor: 'pointer', width: 80, objectFit: 'contain' }}
            loading="lazy"
            onClick={() => window.open(ROUTERS.HOME, '_self')}
          />
          <LazyLoadImage
            src={Images.exportIdra}
            alt="logo"
            style={{ cursor: 'pointer', width: 100, objectFit: 'contain' }}
            loading="lazy"
            onClick={() => window.open('https://idracompany.com/', '_blank')}
          />
        </Box>
        <Typography
          sx={{
            textAlign: 'center',
            mt: 2,
            fontFamily: 'sans-serif',
            fontSize: '0.75rem',
          }}
        >
          © {dayjs().year()}. All rights reserved
        </Typography>
      </Box>
    );
  return (
    <Box
      sx={{
        width: 1,
        borderTop: '1px solid lightgray',
      }}
    >
      <Container
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        maxWidth="lg"
      >
        <Typography
          sx={{
            fontFamily: 'sans-serif',
            fontSize: '0.75rem',
          }}
        >
          © {dayjs().year()}. All rights reserved
        </Typography>
        <Typography
          sx={{
            ml: 'auto',
            fontFamily: 'sans-serif',
            fontSize: '0.75rem',
          }}
        >
          Help Center
        </Typography>
        <Typography
          sx={{
            ml: 2,
            fontFamily: 'sans-serif',
            fontSize: '0.75rem',
          }}
        >
          Terms and Service
        </Typography>
      </Container>
    </Box>
  );
};

export default FooterHomePage;
