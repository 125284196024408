import React, { useEffect } from 'react';
import _ from 'lodash';
// import Utils from '@/Utils';
// import Constants from '@/Constants';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BusinessIcon from '@mui/icons-material/Business';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PublicIcon from '@mui/icons-material/Public';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Helmet } from 'react-helmet';

import SendIcon from '@mui/icons-material/Send';

import { RootState, useTypedDispatch } from '@/store';

import { FeedbackGuestsActions } from '@/Actions';
import { bannerWrapper } from '../Portfolio/Portfolio.styles';

const { createFeedbackGuest, setDefaultLogsReducer } = FeedbackGuestsActions;
// const { ROUTERS } = Constants;

const schema = yup
  .object({
    subject: yup.string().trim().required('Subject is a required field'),
    firstName: yup.string().trim().required('First name is a required field'),
    lastName: yup.string().trim().required('Last name is a required field'),
    email: yup
      .string()
      .trim()
      .email('Email must be a valid email address')
      .required('Email is a required field'),
    message: yup.string().trim().required('Message is a required field'),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const Contact: React.FC = () => {
  const dispatch = useTypedDispatch();

  const isCreatedSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.FEEDBACK_GUEST, 'isCreatedSuccess')
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      subject: '',
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    },
  });

  useEffect(() => {
    if (isCreatedSuccess) reset();
  }, [isCreatedSuccess]);

  useEffect(() => {
    return () => {
      dispatch(setDefaultLogsReducer());
    };
  }, []);

  const onSubmit = (data: FormData) => {
    dispatch(createFeedbackGuest(data));
  };

  const _renderBanner = () => (
    <Box sx={bannerWrapper}>
      <Container component="main" maxWidth="lg" sx={{ height: 300 }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={12} className="banner-content">
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '3rem',
                backgroundImage:
                  'linear-gradient(90deg, rgba(6,0,36,1) 0%, rgba(245,251,243,1) 35%, rgba(255,109,0,1) 100%)',
                backgroundSize: '200% auto',
                color: ' #fff',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                animation: 'textclip 2s linear infinite',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '@keyframes textclip': {
                  to: {
                    backgroundPosition: '200% center',
                  },
                },
                width: 1,
              }}
            >
              Welcome to our Contact Us page!
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const _renderContactForm = () => (
    <Grid container mb={1} spacing={2}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '1.1rem',
            textAlign: 'center',
          }}
        >
          We Value Your Feedback
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.9rem',
            textAlign: 'center',
          }}
        >
          Your feedback is important to us, and we strive to respond to all
          inquiries promptly. Whether you’re a client, partner, or someone
          interested in our services, we look forward to hearing from you. Our
          team is dedicated to providing exceptional customer service and
          addressing your needs effectively.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="subject"
          control={control}
          render={({ field }) => (
            <TextField
              label="Subject"
              required
              fullWidth
              onChange={(event) => field.onChange(event.target.value)}
              error={Boolean(errors.subject?.message)}
              helperText={errors.subject?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              label="Email"
              required
              fullWidth
              onChange={(event) => field.onChange(event.target.value)}
              error={Boolean(errors.email?.message)}
              helperText={errors.email?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              label="First name"
              required
              fullWidth
              onChange={(event) => field.onChange(event.target.value)}
              error={Boolean(errors.firstName?.message)}
              helperText={errors.firstName?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              label="Last name"
              required
              fullWidth
              onChange={(event) => field.onChange(event.target.value)}
              error={Boolean(errors.lastName?.message)}
              helperText={errors.lastName?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="message"
          control={control}
          render={({ field }) => (
            <TextField
              label="Message"
              required
              fullWidth
              multiline
              rows={5}
              onChange={(event) => field.onChange(event.target.value)}
              error={Boolean(errors.message?.message)}
              helperText={errors.message?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          endIcon={<SendIcon />}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          sx={{ width: 150 }}
        >
          Submit
        </Button>
      </Grid>
      {isCreatedSuccess && (
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '1rem',
              textAlign: 'center',
            }}
          >
            Thank You for Contacting Us!
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '0.9rem',
              textAlign: 'center',
            }}
          >
            Thank you for reaching out to us! Your message has been received,
            and we appreciate your interest in Flow-IDRA. Our team will get back
            to you shortly.
          </Typography>
        </Grid>
      )}
    </Grid>
  );

  const _renderCompanyInfo = () => (
    <Grid container mb={1} spacing={2}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '1.1rem',
            textAlign: 'center',
          }}
        >
          Office Address
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <BadgeIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '1rem',
                      }}
                    >
                      FLOW-IDRA CO., LTD
                    </Typography>
                  }
                  sx={{ ml: 2 }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AlternateEmailIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '1rem',
                      }}
                      component="a"
                      href="mailto:services@flow-idra.com"
                    >
                      services@flow-idra.com
                    </Typography>
                  }
                  sx={{ ml: 2 }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FacebookIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '1rem',
                      }}
                      component="a"
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=61552830647403&mibextid=LQQJ4d"
                    >
                      FLOW-IDRA
                    </Typography>
                  }
                  sx={{ ml: 2 }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LinkedInIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '1rem',
                      }}
                      component="a"
                      target="_blank"
                      href="https://www.linkedin.com/company/74762775/admin/feed/posts/"
                    >
                      FLOW-IDRA LIMITED COMPANY
                    </Typography>
                  }
                  sx={{ ml: 2 }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={7}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '1rem',
                      }}
                    >
                      7th Floor, HCC Building, 28 Ly Thuong Kiet, Vinh Ninh
                      Ward, Hue City
                    </Typography>
                  }
                  sx={{ ml: 2 }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocationCityIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '1rem',
                      }}
                    >
                      Thua Thien Hue Province
                    </Typography>
                  }
                  sx={{ ml: 2 }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PublicIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '1rem',
                      }}
                    >
                      Viet Nam
                    </Typography>
                  }
                  sx={{ ml: 2 }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <VpnKeyIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '1rem',
                      }}
                    >
                      490000
                    </Typography>
                  }
                  sx={{ ml: 2 }}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const _renderBodyContact = () => {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        <Container maxWidth="lg">
          <Typography
            sx={{
              width: 1,
              textAlign: 'center',
              fontWeight: 500,
              mb: 2,
            }}
          >
            Whether you have questions, suggestions, ideas, want to build a
            website application, software, or would like to discuss a potential
            collaboration, we’re here to assist you. Feel free to use the
            provided contact information or fill out the form below, and our
            team will get back to you as soon as possible.
          </Typography>
          <Container maxWidth="md">
            {_renderCompanyInfo()}
            {_renderContactForm()}
          </Container>
        </Container>
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Box>
        <Helmet>
          <title>Contact Us</title>
        </Helmet>
        {_renderBanner()}
        {_renderBodyContact()}
      </Box>
    );
  };

  return renderMain();
};

export default Contact;
