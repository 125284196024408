import React from 'react';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { t } from 'react-multi-lang';

import { StickyHeadTable } from '@/Components/Common';
import { IMetaStructure } from '@/Interfaces/Common.interface';
import Utils from '@/Utils';
import Constants from '@/Constants';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { Tooltip, Stack, IconButton, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { IFileTemplate } from '@/Interfaces/FileTemplate.interface';

interface IColumnStructure {
  id: 'no' | 'name' | 'keys' | 'isContainTemplate' | 'createdBy' | 'action';
  label: string;
  width?: number;
  flex?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  line?: number;
}

interface ISectionProps {
  meta: IMetaStructure;
  payload: IFileTemplate[];
  onPageAction?(value: number, type: 'limit' | 'page'): void;
  onRowAction(item: IFileTemplate, action: 'details' | 'edit' | 'delete'): void;
  isLoading?: boolean;
}

interface IDataTableStructure {
  no: string;
  name: JSX.Element;
  keys: string;
  isContainTemplate: JSX.Element;
  createdBy: JSX.Element;
  action: JSX.Element;
}

const COLUMNS: IColumnStructure[] = [
  { id: 'no', label: 'no', minWidth: 5, align: 'center' },
  { id: 'name', label: 'fileTemplateName', minWidth: 300, align: 'left' },
  { id: 'keys', label: 'totalKeys', minWidth: 100, align: 'center' },
  {
    id: 'isContainTemplate',
    label: 'isContainTemplate',
    minWidth: 150,
    align: 'center',
  },
  { id: 'createdBy', label: 'createdBy', minWidth: 130, align: 'center' },
  { id: 'action', label: 'action', minWidth: 130, align: 'center' },
];

const createDataTable = (
  no: string,
  name: JSX.Element,
  keys: string,
  isContainTemplate: JSX.Element,
  createdBy: JSX.Element,
  action: JSX.Element
): IDataTableStructure => {
  return {
    no,
    name,
    keys,
    isContainTemplate,
    createdBy,
    action,
  };
};

const { MODULE_API } = Constants;

const FileTemplateDataTable: React.FC<ISectionProps> = ({
  payload,
  meta,
  onRowAction,
  onPageAction,
  isLoading = false,
}) => {
  const [rows, setRows] = React.useState<IDataTableStructure[]>([]);
  const isAcceptEdit = Utils.isValidPermission(
    MODULE_API.FILE_TEMPLATES.UPDATE_FILE_TEMPLATE
  );
  const isAcceptViewDetails = Utils.isValidPermission(
    MODULE_API.FILE_TEMPLATES.GET_FILE_TEMPLATE_BY_ID
  );
  const isAcceptDelete = Utils.isValidPermission(
    MODULE_API.CONFIG_SYSTEMS.DELETE_CONFIG_SYSTEM
  );

  React.useEffect(() => {
    const resolveRows: IDataTableStructure[] = [];
    if (!isEmpty(payload)) {
      forEach(payload, (item: IFileTemplate, index: number) => {
        const { createdBy, isContainTemplate, keys, name } = item;

        const currentPage = get(meta, 'currentPage');
        const limit = get(meta, 'itemsPerPage');
        const resolveRowIndex = index + 1 + (currentPage - 1) * limit;

        resolveRows.push(
          createDataTable(
            `${resolveRowIndex}`,
            <Typography
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                height: 1,
                maxWidth: { xs: 300, md: 400, lg: 500, xl: '90%' },
              }}
            >
              {`${name || t('table.noInformation')}`}
            </Typography>,
            `${keys.length || t('table.noInformation')}`,
            isContainTemplate ? (
              <CheckBoxOutlinedIcon fontSize="small" />
            ) : (
              <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
            ),
            <Stack direction="row" justifyContent="center">
              {createdBy.userData.fullName}
            </Stack>,
            <Stack
              direction="row"
              sx={{ alignItems: 'center', justifyContent: 'center' }}
            >
              {!isAcceptEdit ? (
                <Tooltip
                  title={t('tooltip.clickToViewDetailsThisFileTemplate')}
                >
                  <IconButton
                    disabled={!isAcceptViewDetails}
                    onClick={() =>
                      isAcceptViewDetails && onRowAction(item, 'details')
                    }
                  >
                    <RemoveRedEyeOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : null}
              {isAcceptEdit ? (
                <Tooltip title={t('tooltip.clickToEditThisFileTemplate')}>
                  <IconButton
                    onClick={() => isAcceptEdit && onRowAction(item, 'edit')}
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : null}
              {isAcceptDelete ? (
                <Tooltip title={t('tooltip.clickToDeleteThisFileTemplate')}>
                  <IconButton
                    onClick={() =>
                      isAcceptDelete && onRowAction(item, 'delete')
                    }
                  >
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Stack>
          )
        );
      });
    }
    setRows(resolveRows);
  }, [payload]);

  return (
    <StickyHeadTable
      columns={COLUMNS}
      rows={rows}
      onAction={onPageAction}
      paginate={meta}
      isLoading={isLoading}
    />
  );
};

export default FileTemplateDataTable;
