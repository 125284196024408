import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ISectionProps {
  alt: string;
  src: string;
  style?: object;
}

const LazyLoad: React.FC<ISectionProps> = ({ alt, src, style }) => (
  <LazyLoadImage
    style={style}
    alt={alt}
    src={src} // use normal <img> attributes as props
  />
);

export default LazyLoad;
