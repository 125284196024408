export default {
  // COMMON URL
  HOME: '/',
  AUTH: '/auth',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  PROFILE: '/profile',
  PROFILE_CHANGE_PASSWORD: '/profile/change-password',
  DASHBOARD: '/dashboard',
  // USER
  USERS: '/users',
  PERSONAL_INFORMATION: '/personal-information',
  USER_INFORMATION_DETAILS: '/users/:id',

  // PROJECT
  PROJECT_LIST: '/projects',
  PROJECT_CREATE: '/projects/create',
  PROJECT_MODIFY: '/projects/modify/:id',
  // PROJECT REPORT
  PROJECT_REPORT: '/project-reports',
  MYDOC: '/mydoc',
  // FILES
  FILE: '/files',
  // LOGS
  LOGS: '/logs',
  // ROLES
  ROLE_LIST: '/roles',
  EDIT_ROLE: '/roles/:id',
  CREATE_ROLE: 'roles/create',
  // WIKI
  WIKI: '/wiki',
  CREATE_WIKI: '/wiki/new',
  EDIT_WIKI: '/wiki/:id',
  // EVENT
  EVENT: '/events',
  // ATTENDANCE
  ATTENDANCE: '/attendances',
  // ANNOUNCEMENT
  ANNOUNCEMENT: '/announcement',
  ANNOUNCEMENT_CREATE: '/announcement/create',
  ANNOUNCEMENT_DETAILS: '/announcement/:id',
  ANNOUNCEMENT_USER: '/dashboard/announcements',
  // CRAWLING SYSTEM
  CRAWLING_SYSTEM: '/crawling-system',
  CRAWLING_PROJECT: '/crawling-project',
  CRAWLING_PROJECT_CREATE: '/crawling-project/create',
  CRAWLING_PROJECT_DETAIL: '/crawling-project/:id',
  // FEEDBACK
  FEEDBACK: '/feedbacks',
  FEEDBACK_GUEST: 'feedback-guests',
  CREATE_FEEDBACK: '/feedbacks/create',
  REPLY_FEEDBACK: 'feedbacks/reply/:id',
  // CONFIG SYSTEM
  CONFIG_SYSTEM: '/config-system',
  CONFIG_SYSTEM_CREATE: '/config-system/create',
  CONFIG_SYSTEM_DETAILS: '/config-system/:id',
  // FILE TEMPLATE
  FILE_TEMPLATES: '/file-templates',
  FILE_TEMPLATES_CREATE: '/file-templates/create',
  FILE_TEMPLATES_DETAILS: '/file-templates/:id',
  // WORK PROGRESS
  WORK_PROGRESS: '/work-progress',
  WORK_PROGRESS_DETAILS: '/work-progress/:id',
  // TOOLS
  TOOLS: '/tools',
  TOOL_DETAILS: '/tools/:id',
  TOOL_CREATE: '/tools/create',
  // ABOUT
  ABOUT_US: '/about-us',
  // OUR SERVICES
  OUR_SERVICES: '/our-services',
  OUR_SERVICES_MARRKET_QUANTITATIVE: 'our-services-market-quantitative',
  // NEWS
  NEWS: '/news-and-publications',
  NEWS_DETAIL: '/news-and-publications-details/:id',
  NEWS_MANAGER: '/news',
  CREATE_POST: 'news/create',
  UPDATE_POST: '/news/detail/:id',
  // CONTACT
  CONTACT: '/contact',
  // DAILY REPORT
  DAILY_TASK: '/daily-task',
  DAILY_TASK_CREATE: '/daily-task/create',
  DAILY_TASK_DETAILS: '/daily-task/:id',
  PORTFOLIO: '/portfolio',
  PORTFOLIO_DETAIL: '/portfolio/:id',
  ADMIN_PORTFOLIO: '/admin-portfolio',
  CREATE_PORTFOLIO: '/create-portfolio',
  ADMIN_PORTFOLIO_DETAIL: '/admin-portfolio/:id',
};
