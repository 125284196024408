import { Skeleton, Stack } from '@mui/material';

const RootFolderSkeleton = () => {
  const _renderRowSkeleton = () => (
    <Stack direction="row" mt={1}>
      <Skeleton width={20} height={30} />
      <Stack direction="row" flex={1} ml={1}>
        <Skeleton width={120} height={30} />
      </Stack>
      <Skeleton width={10} height={30} />
    </Stack>
  );

  return (
    <Stack direction="column" sx={{ p: 1 }}>
      {_renderRowSkeleton()}
      {_renderRowSkeleton()}
      {_renderRowSkeleton()}
    </Stack>
  );
};

export default RootFolderSkeleton;
