import React, { useEffect } from 'react';
import {
  get,
  forEach,
  includes,
  filter,
  omit,
  isEmpty,
  find,
  map,
} from 'lodash';
import { useSelector } from 'react-redux';
import { Box, InputLabel, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router';
import { validate as uuidValidate } from 'uuid';
import DropDownMui from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-multi-lang';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { RoundedContainer, Textarea, TextField } from '@/Components/Common';
import CONSTANTS from '@/Constants';
import { TransferList } from '@/Components/LayoutPart';
import { IUserInformationDetailsStructure } from '@/Interfaces/User.interface';
import { RootState, useTypedDispatch } from '@/store';
import { AnnouncementAction, PermissionActions, UserActions } from '@/Actions';
import Utils from '@/Utils';
import { IAnnouncementDetails } from '@/Interfaces/Announcement.interface';

import { IRoleDetailsStructure } from '@/Interfaces/Role.interface';
import { AnnouncementDetailsSkeleton } from '@/Components/Common/Skeleton';

const { fetchUser, resetUserReducer } = UserActions;
const { getAnnouncementsById, updateAnnouncements, clearAnnouncement } =
  AnnouncementAction;
const { fetchRoles } = PermissionActions;

const { ROUTERS, MODULE_API } = CONSTANTS;
const UpdateAnnouncement: React.FC = () => {
  const { id } = useParams();
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const [newCheckedList, setNewCheckedList] = React.useState<string[]>([]);
  const [removeCheckedList, setRemoveCheckedList] = React.useState<string[]>(
    []
  );
  const [message, setMessage] = React.useState<string>('');
  const roles: IRoleDetailsStructure[] = useSelector((state: RootState) =>
    get(state.ROLE, 'payload')
  );
  const isLoading: any = useSelector((state: RootState) =>
    get(state.ANNOUNCEMENT, 'requestIsLoading')
  );

  const schema = yup
    .object()
    .shape({
      title: yup
        .string()
        .trim()
        .required(t('message.announcementTitleRequired')),
      content: yup
        .string()
        .trim()
        .required(t('message.announcementContentRequired')),
    })
    .required();

  const payload: IUserInformationDetailsStructure[] = useSelector(
    (state: RootState) => get(state.USER, 'payload')
  );
  const details: IAnnouncementDetails = useSelector((state: RootState) =>
    get(state.ANNOUNCEMENT, 'details')
  );
  const isAcceptUpdateAnnouncement = Utils.isValidPermission(
    MODULE_API.ANNOUNCEMENT.UPDATE_ANNOUNCEMENT
  );
  const isAcceptFetchRoles = Utils.isValidPermission(
    MODULE_API.ROLE_PERMISSION.FETCH_ROLES
  );

  React.useEffect(() => {
    if (id && uuidValidate(id)) dispatch(getAnnouncementsById(id));
  }, [id]);

  useEffect(() => {
    if (isAcceptFetchRoles) dispatch(fetchRoles());
    dispatch(fetchUser({ page: 1, limit: 0, status: 'active' }));
    return () => {
      dispatch(resetUserReducer());
      dispatch(clearAnnouncement());
    };
  }, []);

  const handleFilterRole = (value: string) => {
    let filterParams = { page: 1, limit: 0, roleCode: value, status: 'active' };
    if (value === 'all') filterParams = omit(filterParams, 'roleCode');
    dispatch(fetchUser(filterParams));
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    // setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      content: '',
    },
  });

  React.useEffect(() => {
    if (details?.id) {
      const { title, content } = details;
      setValue('title', title);
      setValue('content', content);
    }
  }, [details]);

  // Resolve checked states
  const resolveCheckedStates = (checkIds: string[], type: 'add' | 'remove') => {
    let newRemoveListIds: string[] = [...removeCheckedList];
    let newAddListIds: string[] = [...newCheckedList];
    switch (type) {
      case 'add': {
        forEach(checkIds, (checkId: string) => {
          if (!includes(newCheckedList, checkIds)) newAddListIds.push(checkId);
          else
            newAddListIds = filter(
              newAddListIds,
              (nId: string) => nId !== checkId
            );
          if (includes(removeCheckedList, checkId))
            newRemoveListIds = filter(
              newRemoveListIds,
              (rId: string) => rId !== checkId
            );
        });
        break;
      }
      default: {
        forEach(checkIds, (checkId: string) => {
          if (!includes(newRemoveListIds, checkId))
            newRemoveListIds.push(checkId);
          else
            newRemoveListIds = filter(
              newRemoveListIds,
              (nId: string) => nId !== checkId
            );
          if (includes(newAddListIds, checkId))
            newAddListIds = filter(
              newAddListIds,
              (rId: string) => rId !== checkId
            );
        });
        break;
      }
    }
    setNewCheckedList(newAddListIds);
    setRemoveCheckedList(newRemoveListIds);
  };

  const onSubmit = (data: { title: string; content: string }) => {
    const recevier = map(
      details?.receivers,
      (element: any) => element.receiver
    );
    const checkNewRecevier = filter(
      newCheckedList,
      (item: any) => !find(recevier, ['id', item])
    );
    const checkRemoveRecevier = filter(removeCheckedList, (item: any) =>
      find(recevier, ['id', item])
    );
    if (
      isEmpty(checkNewRecevier) &&
      checkRemoveRecevier?.length === recevier?.length
    )
      setMessage(t('message.announcementContentRequired'));
    else {
      dispatch(resetUserReducer());
      dispatch(
        updateAnnouncements({
          ...data,
          id: details.id,
          removeReceiverIds: checkRemoveRecevier,
          newReceiverIds: checkNewRecevier,
        })
      );
    }
  };

  const _renderTopSection = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Typography variant="h2" ml={2}>
        {t('title.updateAnnouncement')}
      </Typography>
    </Stack>
  );

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Stack direction="column" mb={1}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('label.title')}
                  placeholder="IDRA Website"
                  message={errors.title?.message}
                  required
                  style={{ width: '100%' }}
                />
              )}
            />
            <Controller
              name="content"
              control={control}
              render={({ field }) => (
                <Textarea
                  label={t('label.content')}
                  placeholder={t('label.content')}
                  message={errors.content?.message}
                  sx={{ mb: 1 }}
                  value={field.value}
                  onChange={(e: any) => field.onChange(e.target.value)}
                  required
                />
              )}
            />
            <Stack>
              <InputLabel sx={{ fontWeight: '500', color: 'black', mb: 0.5 }}>
                {t('label.receiver')}{' '}
                <Typography component="span" color="error">
                  (*)
                </Typography>
              </InputLabel>
              {isAcceptFetchRoles && (
                <Stack my={1}>
                  <DropDownMui
                    defaultValue="all"
                    label="Search roles"
                    select
                    sx={{ width: 250 }}
                    onChange={(e) => handleFilterRole(e.target.value)}
                  >
                    <MenuItem key="All" value="all">
                      All
                    </MenuItem>
                    {map(roles, (option: any, index: number) => {
                      return (
                        <MenuItem
                          key={`${option.name}-${index}`}
                          value={option.roleCode}
                        >
                          {option.name}
                        </MenuItem>
                      );
                    })}
                  </DropDownMui>
                </Stack>
              )}
              <TransferList
                payload={payload}
                details={details}
                onAddNewIds={(ids) => {
                  setMessage('');
                  resolveCheckedStates(ids, 'add');
                }}
                onAddRemoveIds={(ids) => resolveCheckedStates(ids, 'remove')}
                message={message}
              />
              <Typography color="#d32f2f" fontSize="0.75rem">
                {message}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              sx={{ mt: '50px' }}
            >
              <LoadingButton
                onClick={() => Utils.redirect(ROUTERS.ANNOUNCEMENT)}
                sx={{ mr: 1 }}
                variant="outlined"
                loading={isLoading}
              >
                {t('button.back')}
              </LoadingButton>
              {isAcceptUpdateAnnouncement && (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  {t('button.save')}
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        </Box>
      </RoundedContainer>
    );
  };

  return (
    <Box>
      {isLoading ? (
        <AnnouncementDetailsSkeleton />
      ) : (
        <>
          {_renderTopSection()}
          {_renderBottomSection()}
        </>
      )}
    </Box>
  );
};

export default UpdateAnnouncement;
