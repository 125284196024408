import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import { validate as uuidValidate } from 'uuid';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Box, Stack, Checkbox, InputLabel } from '@mui/material';

import { useParams } from 'react-router';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { ProjectActions } from '@/Actions';
import { IProjectResponseStructure } from '@/Interfaces/Project.interface';
import {
  DatePicker,
  RoundedContainer,
  Textarea,
  TextField,
  Select,
} from '@/Components/Common';
import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import CONSTANTS from '@/Constants';
import { Alert } from '@/Widgets';

const { ROUTERS, ENUMS, MODULE_API } = CONSTANTS;

const { getProjectById, updateProject } = ProjectActions;

const statusList = [
  { value: ENUMS.ProjectStatus.PROPOSAL, label: 'proposal' },
  { value: ENUMS.ProjectStatus.PENDING, label: 'pending' },
  { value: ENUMS.ProjectStatus.IN_PROGRESS, label: 'inProgress' },
  { value: ENUMS.ProjectStatus.DONE, label: 'done' },
];

const EditProject: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const { id } = useParams();
  const details: IProjectResponseStructure = useSelector((state: RootState) =>
    get(state.PROJECT, 'details')
  );
  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.PROJECT, 'requestIsLoading')
  );

  const isAcceptUpdate = Utils.isValidPermission(
    MODULE_API.PROJECT.UPDATE_PROJECT
  );

  const [isDisabledEndDate, setIsDisabledEndDate] =
    React.useState<boolean>(false);

  const schema = yup.object().shape(
    {
      name: yup.string().trim().required(t('message.projectNameRequired')),
      projectCode: yup
        .string()
        .trim()
        .required(t('message.projectCodeRequired')),
      status: yup.string().trim().required(t('message.statusRequired')),
      description: yup
        .string()
        .trim()
        .required(t('message.projectDescriptionRequired')),
      startDate: yup
        .string()
        .when(['status', 'endDate'], ([status, endDate]) => {
          if (endDate) {
            const isProposal = status === ENUMS.ProjectStatus.PROPOSAL;
            const isValidDate = dayjs(endDate).isValid();

            if (!isValidDate)
              return yup
                .date()
                .typeError(t('message.startDateInvalidFormat'))
                .required(t('message.startDateRequired'));
            if (isProposal)
              return yup
                .date()
                .typeError(t('message.startDateInvalidFormat'))
                .max(
                  new Date(endDate),
                  t('message.startDateMustLessThanEndDate')
                )
                .required(t('message.startDateRequired'));
          }
          return yup
            .date()
            .typeError(t('message.startDateInvalidFormat'))
            .required(t('message.startDateRequired'));
        }),
      isWorkingCurrently: yup.boolean(),
      endDate: yup
        .string()
        .when(['status', 'startDate'], ([status, startDate]) => {
          const isInProgress = status === ENUMS.ProjectStatus.IN_PROGRESS;
          const isProposal = status === ENUMS.ProjectStatus.PROPOSAL;
          const isDone = status === ENUMS.ProjectStatus.DONE;
          const isPending = status === ENUMS.ProjectStatus.PENDING;
          if (isInProgress || isPending) return yup.string();

          const isValidDate = dayjs(startDate).isValid();

          if (startDate) {
            if (!isValidDate)
              return yup
                .date()
                .typeError(t('message.endDateInvalidFormat'))
                .required(t('message.endDateRequired'));
            else if (isDone)
              return yup
                .date()
                .typeError(t('message.endDateInvalidFormat'))
                .max(new Date(), t('message.endDateMustBeLessThanNow'))
                .required(t('message.endDateRequired'));
            else if (isProposal)
              return yup
                .date()
                .typeError(t('message.endDateInvalidFormat'))
                .min(new Date(), t('message.endDateMustBeGreaterThanStartDate'))
                .required(t('message.endDateRequired'));
            else
              return yup
                .date()
                .typeError(t('message.endDateInvalidFormat'))
                .min(startDate, t('message.endDateMustBeGreaterThanStartDate'))
                .required(t('message.endDateRequired'));
          }

          return yup
            .date()
            .typeError(t('message.endDateInvalidFormat'))
            .required(t('message.endDateRequired'));
        }),
    },
    [
      ['status', 'startDate'],
      ['status', 'endDate'],
      ['startDate', 'endDate'],
    ]
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      status: '',
      projectCode: '',
      name: '',
      description: '',
      startDate: '',
      endDate: '',
      isWorkingCurrently: false,
      documentation: '',
    },
  });

  React.useEffect(() => {
    if (!isAcceptUpdate) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizeForEditProject'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else if (id && uuidValidate(id)) dispatch(getProjectById(id));
  }, []);

  const onChangeStatus = (status: string, isGenerate?: boolean) => {
    const isInProgress = status === ENUMS.ProjectStatus.IN_PROGRESS;
    const isPending = status === ENUMS.ProjectStatus.PENDING;
    setValue('isWorkingCurrently', isInProgress || isPending);
    setIsDisabledEndDate(isInProgress || isPending);
    if (isInProgress || isPending || !isGenerate) setValue('endDate', '');
  };

  React.useEffect(() => {
    if (details?.id) {
      const { description, isWorkingCurrently, name, status, projectCode } =
        details;
      setValue('description', description);
      setValue('startDate', `${details.startDate}`);
      setValue('endDate', `${details.endDate}`);
      setValue('isWorkingCurrently', isWorkingCurrently);
      setValue('name', name);
      setValue('status', status);
      setValue('projectCode', projectCode);
      onChangeStatus(status, true);
    }
  }, [details]);

  const onSubmit = (data: {
    status: string;
    name: string;
    projectCode: string;
    description: string;
    startDate: string;
    endDate: string;
    isWorkingCurrently: boolean;
    documentation: string;
  }) => {
    dispatch(updateProject({ ...data, id: details?.id }));
  };

  const statusOptions = React.useMemo(() => {
    const resolveOptions: any[] = [];

    for (const option of statusList) {
      const resolveLabel = t(`menu.${option.label}`);
      resolveOptions.push({
        label: resolveLabel,
        value: option.value,
      });
    }

    return resolveOptions;
  }, [statusList]);

  const _renderBottomSection = () => {
    return (
      <RoundedContainer>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Stack direction="row" mb={1}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={(e: any) => field.onChange(e.target.value)}
                  label={t('label.projectName')}
                  placeholder="IDRA Website"
                  message={errors.name?.message}
                  required
                  style={{ mr: 1 }}
                />
              )}
            />
            <Controller
              name="projectCode"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={(e: any) => field.onChange(e.target.value)}
                  label={t('label.projectCode')}
                  placeholder="VFIW"
                  message={errors.projectCode?.message}
                  required
                  style={{ mr: 1 }}
                />
              )}
            />
          </Stack>
          <Stack direction="row" mb={1}>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  label={t('label.status')}
                  value={field.value}
                  message={errors.status?.message}
                  options={statusOptions}
                  onChange={(e: any) => {
                    onChangeStatus(e);
                    field.onChange(e);
                  }}
                  required
                />
              )}
            />
          </Stack>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Textarea
                value={field.value}
                message={errors.description?.message}
                label={t('label.description')}
                sx={{ mb: 1 }}
                onChange={(e: any) => field.onChange(e.target.value)}
                maxLength={500}
                required
              />
            )}
          />
          <Stack direction="row" mb={1}>
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label={t('label.startDate')}
                  value={field.value}
                  message={errors.startDate?.message}
                  sx={{ mr: 1 }}
                  onChange={(e: any) => field.onChange(e)}
                  required
                />
              )}
            />
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label={t('label.endDate')}
                  value={field.value}
                  message={errors.endDate?.message}
                  onChange={(e: any) => field.onChange(e)}
                  disabled={isDisabledEndDate}
                  required={!isDisabledEndDate}
                />
              )}
            />
          </Stack>

          <Stack direction="row" sx={{ mb: 1, alignItems: 'center' }}>
            <InputLabel sx={{ fontWeight: '500', color: 'black' }}>
              {t('label.workingCurrently')}{' '}
            </InputLabel>
            <Controller
              name="isWorkingCurrently"
              control={control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  disabled
                  onClick={(e: any) => {
                    const isChecked = e.target.checked;
                    field.onChange(isChecked);
                  }}
                />
              )}
            />
          </Stack>

          <Stack direction="row" justifyContent="flex-end" sx={{ mt: '50px' }}>
            <LoadingButton
              onClick={() => Utils.redirect(ROUTERS.PROJECT_LIST)}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              {t('button.cancel')}
            </LoadingButton>
            {isAcceptUpdate && (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                {t('button.submit')}
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </RoundedContainer>
    );
  };

  return _renderBottomSection();
};

export default EditProject;
