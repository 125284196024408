export default {
  mainBackground: 'linear-gradient(60deg, #29323c 0%, #485563 100%)',
  mainColor: 'rgb(52, 71, 103)',
  burntSienna: '#E87844',
  bismark: '#517284',
  pickledBluewood: '#324A58',
  loblolly: '#C2D2D6',
  oceanGreen: '#4BB180',
  fuzzyWuzzyBrown: '#C95454',
  diSerria: '#DA9555',
  mystic: '#E7ECEF',
  astronaut: '#274C77',
  hippieBlue: '#6096BA',
  perano: '#A3CEF1',
  lemonGrass: '#8B8C89',
  grey: '#757575',
  denim: '#1976D2',
  blueRibbon: '#0240F9',
  shuttleGray: '#57606A',
  RredBerry: '#990000',
  blue: '#3300FF',
  shark: '#24292f',
  AazureRadiance: '#007FFF',
  jewel: '#1a7f37',
  cardinal: '#CF222E',
  ebonyClay: '#212B36',
  scienceBlue: '#0268C6',
  cornflower: '#637381',
  concrete: '#f2f2f2',
};
