import { keyframes, styled } from '@mui/system';
import { CommonColors } from '@/Themes';

const Slide = keyframes`
  0% {
    opacity: 0;
    left: -450px;
  }

  10% {
    opacity: 1;
    left: 10px;
  }

  90% {
    opacity: 1;
    left: 10px;
  }

  100% {
    opacity: 0;
    left: -450px;
  }
`;

const SuccessPulse = keyframes`
  0% {
    background-color: ${CommonColors.oceanGreen};
    transform: scale(1);
    opacity: 0;
  }

  30% {
    background-color: ${CommonColors.oceanGreen};
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    background-color: ${CommonColors.oceanGreen};
    transform: scale(1.5);
    opacity: 0;
  }
`;

const ScaleIcon = keyframes`
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
`;

const WarningPulseWarning = keyframes`
  0% {
    background-color: ${CommonColors.diSerria};
    transform: scale(1);
    opacity: 0;
  }

  30% {
    background-color: ${CommonColors.diSerria};
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    background-color: ${CommonColors.diSerria};
    transform: scale(2);
    opacity: 0;
  }
`;

const ErrorPulse = keyframes`
  0% {
    background-color: ${CommonColors.fuzzyWuzzyBrown};
    transform: scale(1);
    opacity: 0;
  }

  30% {
    background-color: ${CommonColors.fuzzyWuzzyBrown};
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    background-color: ${CommonColors.fuzzyWuzzyBrown};
    transform: scale(2);
    opacity: 0;
  }
`;

const NotificationItem = styled('div')`
  display: flex;
  flex-direction: row;
  width: 370px;
  min-height: 55px;
  height: auto;
  background: ${CommonColors.pickledBluewood};
  padding: 1em;
  align-items: space-between;
  border-radius: 5px;
  user-select: none;
  margin-top: 0.5em;
  position: fixed;
  opacity: 0;
  bottom: 10px;
  left: -370px;
  z-index: 10;
  animation: ${Slide} 4s ease-in;
  box-sizing: border-box;

  &:hover {
    opacity: 1;
  }
  & .label {
    margin-bottom: 0.5em;
    font-weight: bold;
  }
  & .message {
    line-height: 1.4em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  & .icon {
    margin-right: 0.8em;
    i {
      font-size: 2.5em;
    }
  }
`;

// RENDER ICONS

const IconWrapper = styled('div')`
  width: 40px;
  margin-right: 1em;
`;

const Icon = styled('div')`
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
`;

const SuccessIcon = styled(Icon)`
  border: 4px solid ${CommonColors.oceanGreen};
  animation: ${ScaleIcon} 0.75s infinite alternate;

  &::after {
    top: 0;
    left: 30px;
    width: 30px;
    transform-origin: 0 50%;
    border-radius: 0 100px 100px 0;
    animation: ${SuccessPulse} 4.25s ease-in;
  }

  & .success_icon_line {
    height: 5px;
    background-color: ${CommonColors.oceanGreen};
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
  }

  & .success_line_tip {
    top: 14px;
    left: 6px;
    width: 10px;
    transform: rotate(45deg);
  }

  & .success_line_long {
    top: 13px;
    right: 4px;
    width: 16px;
    transform: rotate(-45deg);
  }

  & .success_icon_circle {
    top: -4px;
    left: -4px;
    z-index: 10;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    box-sizing: content-box;
    border: 4px solid rgba(75, 177, 128, 0.5);
    animation: ${SuccessPulse} 2s linear infinite;
  }
`;

const ErrorIcon = styled(Icon)`
  border: 4px solid ${CommonColors.fuzzyWuzzyBrown};
  animation: ${ScaleIcon} 0.75s infinite alternate;

  &:before {
    animation: ${ErrorPulse} 2s linear infinite;
    background-color: transparent;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  & .error_x_mark {
    display: block;
    position: relative;
    z-index: 2;
  }

  & .error_line {
    border-radius: 2px;
    display: block;
    height: 5px;
    position: absolute;
    z-index: 2;
    background-color: ${CommonColors.fuzzyWuzzyBrown};
    top: 13px;
    width: 22px;
  }

  & .error_left {
    left: 4px;
    transform: rotate(45deg);
  }

  & .error_right {
    right: 4px;
    transform: rotate(-45deg);
  }
`;

const WarningIcon = styled(Icon)`
  border: 4px solid ${CommonColors.mainColor};
  animation: ${ScaleIcon} 0.75s infinite alternate;

  &:before {
    animation: ${WarningPulseWarning} 2s linear infinite;
    background-color: transparent;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  & .warning_body {
    background-color: ${CommonColors.diSerria};
    border-radius: 2px;
    height: 13px;
    left: calc(50% - 2px);
    position: absolute;
    top: 5px;
    width: 5px;
    z-index: 2;
  }

  & .warning_dot {
    background-color: ${CommonColors.diSerria};
    border-radius: 50%;
    bottom: 4px;
    height: 5px;
    left: calc(50% - 2px);
    position: absolute;
    width: 5px;
    z-index: 2;
  }
`;

export { NotificationItem, IconWrapper, SuccessIcon, ErrorIcon, WarningIcon };
