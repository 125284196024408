import CONSTANT from '@/Constants';

const { ACTION_TYPES } = CONSTANT;

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 1,
  itemsPerPage: 10,
  totalItems: 1,
  totalPages: 1,
};

const initialState = {
  requestIsLoading: false,
  requestHasError: false,
  requestIsSuccess: false,
  payload: [],
  details: {},
  step: {},
  meta: DEFAULT_META,
  createWorkProgressIsSuccess: false,
  createStepWorkProgressIsSuccess: false,
  updateStepWorkProgressIsSuccess: false,
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.WORK_PROGRESS_IS_REQUEST:
      return {
        ...state,
        requestIsLoading: true,
        requestHasError: false,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.SET_META_WORK_PROGRESS:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_DEFAULT_REDUCER_WORK_PROGRESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: false,
        payload: [],
        details: {},
        step: {},
        meta: DEFAULT_META,
        createWorkProgressIsSuccess: false,
        createStepWorkProgressIsSuccess: false,
        updateStepWorkProgressIsSuccess: false,
      };
    case ACTION_TYPES.CLEAR_STATUS_WORK_PROGRESS:
      return {
        ...state,
        createWorkProgressIsSuccess: false,
        createStepWorkProgressIsSuccess: false,
        updateStepWorkProgressIsSuccess: false,
      };

    case ACTION_TYPES.FETCH_WORK_PROGRESS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        payload,
      };
    case ACTION_TYPES.FETCH_WORK_PROGRESS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        payload: [],
      };

    case ACTION_TYPES.GET_WORK_PROGRESS_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        details: payload,
      };
    case ACTION_TYPES.GET_WORK_PROGRESS_BY_ID_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        details: [],
      };

    case ACTION_TYPES.CREATE_WORK_PROGRESS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        createWorkProgressIsSuccess: true,
      };
    case ACTION_TYPES.CREATE_WORK_PROGRESS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        createWorkProgressIsSuccess: false,
      };

    case ACTION_TYPES.UPDATE_WORK_PROGRESS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.UPDATE_WORK_PROGRESS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.DELETE_WORK_PROGRESS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.DELETE_WORK_PROGRESS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.CREATE_STEP_WORK_PROGRESS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        createStepWorkProgressIsSuccess: true,
      };
    case ACTION_TYPES.CREATE_STEP_WORK_PROGRESS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.UPDATE_STEP_WORK_PROGRESS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        updateStepWorkProgressIsSuccess: true,
      };
    case ACTION_TYPES.UPDATE_STEP_WORK_PROGRESS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    case ACTION_TYPES.GET_STEP_WORK_PROGRESS_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
        step: payload,
      };
    case ACTION_TYPES.GET_STEP_WORK_PROGRESS_BY_ID_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
        step: {},
      };
    case ACTION_TYPES.DELETE_STEP_WORK_PROGRESS_SUCCESS:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: false,
        requestIsSuccess: true,
      };
    case ACTION_TYPES.DELETE_STEP_WORK_PROGRESS_FAILURE:
      return {
        ...state,
        requestIsLoading: false,
        requestHasError: true,
        requestIsSuccess: false,
      };

    default:
      return state;
  }
};
