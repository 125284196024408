import { toast } from 'react-toastify';

import CONSTANTS from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';
import { INewsStructure } from '@/Interfaces/News.interface';

const { ACTION_TYPES, ROUTERS } = CONSTANTS;

// SINGLE ACTIONS
const porfoliosIsRequest = () => {
  return {
    type: ACTION_TYPES.PORFOLIO_IS_REQUEST,
  };
};

const setPorfoliosMeta = (payload: any) => {
  return {
    type: ACTION_TYPES.SET_PORFOLIO_META,
    payload,
  };
};

const setDefaultPorfoliosReducer = () => {
  return {
    type: ACTION_TYPES.SET_DEFAULT_PORFOLIO_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_PORFOLIOS_SUCCESS,
    payload,
  };
};

const fetchFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_PORFOLIOS_FAILURE,
  };
};

const fetchAdminPorfolios = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(porfoliosIsRequest());
    await API.fetchPorfolios(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFailure());
        else {
          const resolveResult: { items: any; meta: any } = result as {
            items: any;
            meta: any;
          };
          dispatch(setPorfoliosMeta(resolveResult.meta));
          dispatch(fetchSuccess(resolveResult.items));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(fetchFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const createSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_PORFOLIO_SUCCESS,
  };
};

const createFailure = () => {
  return {
    type: ACTION_TYPES.CREATE_PORFOLIO_FAILURE,
  };
};

const createPorfolio = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(porfoliosIsRequest());
    await API.createPorfolio(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(createFailure());
        else {
          dispatch(createSuccess());
          await Utils.redirect({
            pathname: ROUTERS.ADMIN_PORTFOLIO,
          });
          await toast.success(response?.data?.message);
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(createFailure());
        await toast.error(error?.message);
        await Utils.resolveFailureResponse(error);
      });
  };
};

const getByIdSuccess = (payload: INewsStructure) => {
  return {
    type: ACTION_TYPES.GET_PORFOLIO_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFailure = () => {
  return {
    type: ACTION_TYPES.GET_PORFOLIO_BY_ID_FAILURE,
  };
};

const getPorfolioById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(porfoliosIsRequest());
    await API.getPorfolioById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFailure());
        else {
          const resolveResult: INewsStructure = result as INewsStructure;
          dispatch(getByIdSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(getByIdFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const updateSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_PORFOLIO_SUCCESS,
  };
};

const updateFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_PORFOLIO_FAILURE,
  };
};

const updatePorfolio = (payload: any, id: string) => {
  return async (dispatch: any) => {
    dispatch(porfoliosIsRequest());
    await API.updatePorfolio(payload, id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        const message = response?.data?.message;
        if (!result) {
          toast.error(message);
          await dispatch(updateFailure());
        } else {
          await dispatch(updateSuccess());
          toast.success(message);
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFailure());
      });
  };
};

const updateStatusSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_STATUS_PORFOLIO_SUCCESS,
  };
};

const updateStatusFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_STATUS_PORFOLIO_FAILURE,
  };
};

const updateStatusPorfolio = (status: string, id: string, filters: any) => {
  return async (dispatch: any) => {
    dispatch(porfoliosIsRequest());
    await API.updateStatusPorfolio(status, id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(updateStatusFailure());
        else {
          dispatch(updateStatusSuccess());
          dispatch(fetchAdminPorfolios(filters));
          toast.success(response?.data?.message);
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(updateStatusFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

// USER PORTAL
const userPorfoliosIsRequest = () => {
  return {
    type: ACTION_TYPES.USER_PORFOLIO_IS_REQUEST,
  };
};

const setUserPorfoliosMeta = (payload: any) => {
  return {
    type: ACTION_TYPES.SET_USER_PORFOLIO_META,
    payload,
  };
};

const setDefaultUserPorfoliosReducer = () => {
  return {
    type: ACTION_TYPES.SET_USER_DEFAULT_PORFOLIO_REDUCER,
  };
};

const getByIdUserSuccess = (payload: INewsStructure) => {
  return {
    type: ACTION_TYPES.GET_USER_PORFOLIO_BY_ID_SUCCESS,
    payload,
  };
};

const getByIUserdFailure = () => {
  return {
    type: ACTION_TYPES.GET_USER_PORFOLIO_BY_ID_FAILURE,
  };
};

const getUserPorfolioById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(userPorfoliosIsRequest());
    await API.getPorfolioById(id)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIUserdFailure());
        else {
          const resolveResult: INewsStructure = result as INewsStructure;
          dispatch(getByIdUserSuccess(resolveResult));
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(getByIUserdFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const fetchUserSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_USER_PORTFOLIO_SUCCESS,
    payload,
  };
};

const fetchUserFailure = () => {
  return {
    type: ACTION_TYPES.FETCH_USER_PORTFOLIO_FAILURE,
  };
};

const fetchUserPorfolios = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(userPorfoliosIsRequest());
    await API.fetchPorfolios(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchUserFailure());
        else {
          const resolveResult: { items: any; meta: any } = result as {
            items: any;
            meta: any;
          };
          dispatch(setUserPorfoliosMeta(resolveResult.meta));
          dispatch(fetchUserSuccess(resolveResult.items));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(fetchUserFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const calculateSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.CALCULATE_PORTFOLIO_HOMEPAGE_SUCCESS,
    payload,
  };
};

const calculateFailure = () => {
  return {
    type: ACTION_TYPES.CALCULATE_PORTFOLIO_HOMEPAGE_FAILURE,
  };
};

const calculatePorfolios = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(userPorfoliosIsRequest());
    await API.getPortfolioForDashboard(payload)
      .then(async (response: any) => {
        const result: unknown = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(calculateFailure());
        else {
          dispatch(calculateSuccess(result));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(calculateFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

const getFeatureSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_FEATURED_PORTFOLIO_SUCCESS,
    payload,
  };
};

const getFeatureFailure = () => {
  return {
    type: ACTION_TYPES.GET_FEATURED_PORTFOLIO_FAILURE,
  };
};

const getFeaturePorfolios = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(userPorfoliosIsRequest());
    await API.fetchPorfolios(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getFeatureFailure());
        else {
          dispatch(getFeatureSuccess(result?.items || []));
        }
        return true;
      })
      .catch(async (error) => {
        await dispatch(getFeatureFailure());
        await Utils.resolveFailureResponse(error);
      });
  };
};

export default {
  fetchAdminPorfolios,
  createPorfolio,
  getPorfolioById,
  updatePorfolio,
  updateStatusPorfolio,
  setDefaultPorfoliosReducer,
  // USER
  getUserPorfolioById,
  fetchUserPorfolios,
  setDefaultUserPorfoliosReducer,
  calculatePorfolios,
  getFeaturePorfolios,
};
