import React, { useState } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';

import { Alert } from '@/Widgets';
import Constants from '@/Constants';
import Utils from '@/Utils';
import { ToolActions } from '@/Actions';

import { RootState, useTypedDispatch } from '@/store';
import { RoundedContainer } from '@/Components/Common';
import { ToolListSkeleton } from '@/Components/Common/Skeleton';
import { ITool } from '@/Interfaces/Tool.interface';
import DialogTools from './dialog';

const { ROUTERS, MODULE_API } = Constants;
const { fetchTools, deleteTool } = ToolActions;

const Tools: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [toolDetail, setToolDetail] = useState<ITool>();

  const isAcceptFetch = Utils.isValidPermission(MODULE_API.TOOL.FETCH_TOOLS);
  const isAcceptCreate = Utils.isValidPermission(MODULE_API.TOOL.CREATE_TOOL);
  const isAcceptEdit = Utils.isValidPermission(MODULE_API.TOOL.UPDATE_TOOL);
  const isAcceptDelete = Utils.isValidPermission(MODULE_API.TOOL.DELETE_TOOL);

  const payload: ITool[] = useSelector((state: RootState) =>
    get(state.TOOL, 'payload')
  );

  const isLoading: boolean = useSelector((state: RootState) =>
    get(state.TOOL, 'requestIsLoading')
  );

  const [keyword, setKeyword] = React.useState<string>('');

  React.useEffect(() => {
    if (!isAcceptFetch) {
      Alert({
        type: 'ERROR',
        message: t('popup.notAuthorizedToFetchTool'),
      });
      Utils.redirect(ROUTERS.DASHBOARD);
    } else {
      dispatch(fetchTools());
    }
    return () => {};
  }, []);

  const onAction = async (item: ITool, action: 'edit' | 'delete') => {
    if (action === 'edit')
      Utils.redirect({
        pathname: ROUTERS.TOOL_DETAILS,
        id: item.id,
      });

    if (action === 'delete') {
      const isAgree = await Alert({
        type: 'WARNING',
        message: t('popup.warningBeforeDeleteTool'),
      });
      if (isAgree === 'ok') dispatch(deleteTool(item.id));
    }
  };

  const onSearch = () => {
    dispatch(fetchTools({ keyword }));
  };

  const handleCloseComment = () => {
    setOpenDialog(false);
  };

  const handleDialogTools = (idTool: string) => {
    setOpenDialog(true);
    if (idTool) {
      const newDialog = _.filter(payload, (item: any) => item.id === idTool);
      setToolDetail(newDialog[0]);
    }
  };

  const _renderTopSection = () => (
    <Stack direction="column" mb={2}>
      <Typography variant="h2">{t('title.tools')}</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row">
          <TextField
            value={keyword}
            size="small"
            onChange={(e: any) => setKeyword(e.target.value)}
            placeholder={t('placeholder.keyword')}
            sx={{ mr: 1, maxWidth: '190px' }}
          />
          <Button sx={{ mr: 1 }} onClick={() => onSearch()} variant="contained">
            {t('button.filter')}
          </Button>
          <Button
            onClick={() => {
              dispatch(fetchTools());
              setKeyword('');
            }}
            variant="outlined"
          >
            {t('button.reset')}
          </Button>
        </Stack>
        {isAcceptCreate ? (
          <Button
            variant="contained"
            onClick={() => Utils.redirect(ROUTERS.TOOL_CREATE)}
          >
            {t('button.create')}
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );

  const _renderCards = () => {
    if (!payload || payload.length === 0)
      return <Typography>{t('tooltip.noDataWereFound')}</Typography>;
    const cards = payload.map((item: ITool) => (
      <Grid
        item
        key={`tool-${item.id}`}
        xs={12}
        sm={12}
        md={6}
        lg={4}
        xl={3}
        sx={{ height: 'auto' }}
      >
        <Card
          sx={{
            width: '100%',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            height: '100%',
            maxHeight: '450px',
          }}
        >
          <CardMedia
            sx={{
              aspectRatio: '16/9',
              height: 130,
              objectFit: 'cover',
            }}
            image={item.thumbnail.path}
            title="green iguana"
          />
          <CardContent>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <Typography
                variant="h4"
                component="h3"
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  flex: 1,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  overflow: 'hidden',
                  '&:hover': { cursor: 'pointer' },
                }}
                onClick={() => handleDialogTools(item.id)}
              >
                {item.title}
              </Typography>
              <CardActions>
                {isAcceptEdit ? (
                  <Tooltip title={t('tooltip.clickToEditThisTool')}>
                    <IconButton onClick={() => onAction(item, 'edit')}>
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {isAcceptDelete ? (
                  <Tooltip title={t('tooltip.clickToDeleteThisTool')}>
                    <IconButton onClick={() => onAction(item, 'delete')}>
                      <DeleteOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </CardActions>
            </Stack>
            <Link
              sx={{
                wordBreak: 'break-all',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              href={item.url}
              target="_blank"
            >
              {item.url}
            </Link>
            <Typography
              variant="body2"
              component="p"
              sx={{
                fontSize: '14px',
                marginTop: '5px',
                display: '-webkit-box',
                textOverflow: 'ellipse',
                WebkitLineClamp: 5,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={() => handleDialogTools(item.id)}
            >
              {item.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ));
    return (
      <Grid container spacing={3}>
        {cards}
      </Grid>
    );
  };

  const _renderBottomSection = () => (
    <RoundedContainer>
      {isLoading ? <ToolListSkeleton /> : _renderCards()}
      <DialogTools
        open={openDialog}
        onClose={handleCloseComment}
        data={toolDetail}
      />
    </RoundedContainer>
  );

  return (
    <>
      {_renderTopSection()}
      {_renderBottomSection()}
    </>
  );
};

export default Tools;
